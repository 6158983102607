import { HttpServiceTransport } from './HttpServiceTransport.js';
import { ServiceDefBase, ServiceTypeOf } from './ServiceDef.js';

export function makeServiceClient<Def extends ServiceDefBase>(
  serviceName: string,
  def: Def,
  transport: HttpServiceTransport,
): ServiceTypeOf<Def> {
  return Object.fromEntries(
    Object.entries(def).map(([method, methodDef]) => [
      method,
      transport.method(serviceName, method, methodDef),
    ]),
  ) as ServiceTypeOf<Def>;
}
