import { Box, SxProps } from '@mui/system';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { FilePickerChildProps } from './FilePicker.js';

export interface FileDropProps extends FilePickerChildProps {
  children?: React.ReactNode;
  translations: string;
}

const fileDropStyle: SxProps = {
  borderColor: 'grey.500',
  borderStyle: 'dashed',
  bgcolor: 'background.default',
  borderRadius: 2,
  borderWidth: 1,
  color: 'grey.500',
  cursor: 'pointer',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  height: '6rem',
  px: 1,
  py: 4,
  textAlign: 'center',
  '&.unacceptable': {
    borderColor: 'error.main',
    color: 'error.main',
  },
  '&.active': {
    borderColor: 'success.main',
    color: 'success.main',
  },
  '&.selected': {
    borderStyle: 'dashed',
    display: 'flex',
    px: 1,
    py: 4,
    textAlign: 'center',
  },
};

export function FileDrop({
  active,
  children,
  selectedFiles,
  translations,
  unacceptable,
  multiple,
}: FileDropProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <Box
      className={[
        active && 'active',
        !!selectedFiles?.length && 'selected',
        unacceptable && 'unacceptable',
      ].join(' ')}
      sx={fileDropStyle}
    >
      <div>{children}</div>
      {unacceptable
        ? t(
            `${translations}.${
              multiple
                ? 'fileDirectionUnacceptableMultiple'
                : 'fileDirectionUnacceptable'
            }`,
          )
        : active
        ? t(
            `${translations}.${
              multiple ? 'fileDirectionActiveMultiple' : 'fileDirectionActive'
            }`,
          )
        : selectedFiles?.length === 1
        ? selectedFiles[0].name
        : t(
            `${translations}.${
              multiple ? 'fileDirectionMultiple' : 'fileDirection'
            }`,
          )}
    </Box>
  );
}
