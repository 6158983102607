import { ExpandMore, MoreVert } from '@mui/icons-material';
import {
  Avatar,
  Collapse,
  Grid,
  IconButton,
  Link,
  Menu,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { DateTime } from 'luxon';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  OfferActionStatus,
  OfferComment as OfferCommentType,
  OfferRemarketingAction,
} from '../../../../../../api/util/RemarketingAction.js';
import { CurrentOffer } from '../../../../../../api/util/RemarketingVehicle.js';
import { Label } from '../../../../../common-ui/index.js';
import { useCurrentUser } from '../../../../../hooks/index.js';
import { formatDateTime } from '../../../../../util/dates.js';
import { formatPrice } from '../../../../../util/formatters.js';
import {
  offerStatusColours,
  remarketingActionTypeColours,
  sx,
} from '../../../../../util/index.js';

const styles = sx({
  avatar: {
    width: 24,
    height: 24,
    fontSize: 16,
  },
  comments: {
    bgcolor: 'grey.100',
    padding: (theme) => theme.spacing(2),
  },
  expandLess: {
    transition: 'transform 300ms',
    transform: 'rotate(-180deg)',
  },
  expandMore: {
    transition: 'transform 300ms',
  },
});

const OfferComment = ({ comment }: { comment: OfferCommentType }) => {
  const { i18n } = useTranslation();
  return (
    <Stack spacing={1}>
      <Stack
        alignItems={{ xs: 'auto', sm: 'center' }}
        direction={{ xs: 'column', sm: 'row' }}
        spacing={1}
      >
        <Stack alignItems="center" direction="row" spacing={1}>
          <Avatar sx={styles.avatar}>{comment.userName[0]}</Avatar>
          <Typography variant="subtitle2">{comment.userName}</Typography>
        </Stack>
        <Typography variant="subtitle2">{comment.userEmail}</Typography>
        <Typography variant="subtitle2">
          {DateTime.fromMillis(comment.timestamp, {
            locale: i18n.language,
          }).toRelative()}
        </Typography>
      </Stack>
      <Typography pl={4}>{comment.comment}</Typography>
    </Stack>
  );
};

export const OfferItem = ({
  item,
  currency,
  highlight,
  actions,
  menuItems,
}: {
  item: OfferRemarketingAction | CurrentOffer;
  currency: string;
  highlight?: boolean;
  actions?: React.ReactNode;
  menuItems?: React.ReactNode;
}) => {
  const { t } = useTranslation();
  const fontWeight = highlight ? 'fontWeightBold' : undefined;
  const { value: user } = useCurrentUser();
  const userId = user?.principalId;
  const [showComments, setShowComments] = useState(false);
  const [menuAnchorEl, setMenuAnchorEl] = useState<null | HTMLElement>(null);

  const closeMenu = () => setMenuAnchorEl(null);

  const hasComments =
    ('userComment' in item && item.userComment) ||
    ('adminComment' in item && item.adminComment);

  const isOffer = 'actionType' in item;
  const status = 'status' in item ? item.status : undefined;

  return (
    <>
      <Grid alignItems="center" container>
        <Grid item xs>
          <Typography color="grey.600" fontWeight={fontWeight} variant="body2">
            {formatDateTime(item.timestamp)}
          </Typography>
        </Grid>
        <Grid container item xs>
          <Grid item xs>
            <Typography color="#172B4D" fontWeight={fontWeight} variant="body2">
              {formatPrice(item.value, currency)}
            </Typography>
          </Grid>
        </Grid>
        <Grid container item xs>
          <Grid item xs>
            <Stack direction="row" spacing={1}>
              <Label
                bgcolor={
                  isOffer
                    ? remarketingActionTypeColours.OFFER.background
                    : remarketingActionTypeColours.BUY_NOW.background
                }
              >
                <Typography
                  color={
                    isOffer
                      ? remarketingActionTypeColours.OFFER.color
                      : remarketingActionTypeColours.BUY_NOW.color
                  }
                  fontWeight="fontWeightBold"
                  textTransform="uppercase"
                  variant="caption"
                >
                  {isOffer
                    ? t(`remarketingActionType.OFFER`)
                    : t('remarketingActiontype.BUY_NOW')}
                </Typography>
              </Label>
              {status && (
                <Label bgcolor={offerStatusColours[status].background}>
                  <Typography
                    color={offerStatusColours[status].color}
                    fontWeight="fontWeightBold"
                    textTransform="uppercase"
                    variant="caption"
                  >
                    {status === OfferActionStatus.ACCEPTED
                      ? t(`accepted`)
                      : t('rejected')}
                  </Typography>
                </Label>
              )}
            </Stack>
          </Grid>
        </Grid>
        <Grid item xs>
          <Typography color="#172B4D" fontWeight={fontWeight} variant="body2">
            {'userId' in item && item.userId === userId
              ? t('you')
              : item.userName}
          </Typography>
        </Grid>
        <Grid item xs>
          <Typography color="#172B4D" fontWeight={fontWeight} variant="body2">
            {item.dealerName}
          </Typography>
        </Grid>
        <Grid item xs>
          <Stack
            alignItems="center"
            direction="row"
            justifyContent="end"
            spacing={1}
          >
            <Link
              color="primary"
              component="span"
              fontWeight="bold"
              onClick={() => setShowComments((prev) => !prev)}
              underline="always"
              variant="body2"
            >
              {hasComments && (
                <Stack alignItems="center" direction="row">
                  {t('comments')}
                  <ExpandMore
                    sx={showComments ? styles.expandLess : styles.expandMore}
                  />
                </Stack>
              )}
            </Link>
            {menuItems && (
              <>
                <IconButton
                  onClick={(e) => setMenuAnchorEl(e.currentTarget)}
                  size="small"
                >
                  <MoreVert />
                </IconButton>
                <Menu
                  anchorEl={menuAnchorEl}
                  onClick={closeMenu}
                  onClose={closeMenu}
                  open={!!menuAnchorEl}
                >
                  {menuItems}
                </Menu>
              </>
            )}
          </Stack>
        </Grid>
      </Grid>
      <Stack>
        <Collapse in={showComments}>
          <Paper sx={styles.comments}>
            <Stack spacing={1}>
              {'userComment' in item && item.userComment && (
                <OfferComment comment={item.userComment} />
              )}
              {actions}
              {'adminComment' in item && item.adminComment && (
                <OfferComment comment={item.adminComment} />
              )}
            </Stack>
          </Paper>
        </Collapse>
      </Stack>
    </>
  );
};
