import {
  choose,
  enumValue,
  is,
  maybe,
  number,
  object,
  string,
  text,
} from '@fmtk/decoders';
import {
  AddRemarketingVehicleRequest,
  decodeAddRemarketingVehicleRequest,
} from '../RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import { AvailableBrands } from './AvailableBrands.js';
import {
  RemarketingActionType,
  RemarketingVehicleStatus,
} from './RemarketingVehicle.js';

export interface RemarketingActionBase {
  id: string;
  remarketingVehicleId: string;
  brand: AvailableBrands;
  userId: string;
  userName: string;
  userEmail: string;
  dealerName?: string; // dealer detail will not always will be available, for example not for admin
  dealerId?: number;
  timestamp: number; // dynamo does not use date type
}

export enum BidActionResult {
  WON = 'WON',
  LOST = 'LOST',
}

export enum RelistRemarketingActionStatus {
  Creating = 'Creating',
  Error = 'Error',
  Complete = 'Complete',
}

export enum OfferActionStatus {
  ACCEPTED = 'ACCEPTED',
  REJECTED = 'REJECTED',
}

export interface BidRemarketingAction extends RemarketingActionBase {
  bid: number;
  actionType: RemarketingActionType.BID;
  result?: BidActionResult;
  dealerId: number;
  dealerName: string;
}

export interface OfferComment {
  id: string;
  timestamp: number;
  userId: string;
  userName: string;
  userEmail: string;
  comment: string;
}
export interface OfferRemarketingAction extends RemarketingActionBase {
  value: number;
  actionType: RemarketingActionType.OFFER;
  userComment?: OfferComment;
  status?: OfferActionStatus;
  adminComment?: OfferComment;
  dealerId: number;
  dealerName: string;
}

export interface CommentRemarketingAction extends RemarketingActionBase {
  comment: string;
  actionType: RemarketingActionType.COMMENT;
}

export interface CancelRemarketingAction extends RemarketingActionBase {
  status: RemarketingVehicleStatus.CANCELLED;
  actionType: RemarketingActionType.CANCEL;
}

export interface AddToWatchlistAction extends RemarketingActionBase {
  actionType: RemarketingActionType.ADD_TO_WATCHLIST;
}

export interface DeleteFromWatchlistAction extends RemarketingActionBase {
  actionType: RemarketingActionType.DELETE_FROM_WATCHLIST;
}
export interface RelistRemarketingAction extends RemarketingActionBase {
  actionType: RemarketingActionType.RELIST;
  status: RelistRemarketingActionStatus;
  _expireAt?: number;
  addRemarketingVehicleRequest: AddRemarketingVehicleRequest<number>;
}
export interface ArchiveRemarketingAction extends RemarketingActionBase {
  actionType: RemarketingActionType.ARCHIVE;
}

export interface BuyNowRemarketingAction extends RemarketingActionBase {
  actionType: RemarketingActionType.BUY_NOW;
}
export interface TransferRemarketingAction extends RemarketingActionBase {
  actionType: RemarketingActionType.TRANSFER;
}

export type RemarketingAction =
  | BidRemarketingAction
  | OfferRemarketingAction
  | CommentRemarketingAction
  | CancelRemarketingAction
  | AddToWatchlistAction
  | DeleteFromWatchlistAction
  | RelistRemarketingAction
  | ArchiveRemarketingAction
  | BuyNowRemarketingAction
  | TransferRemarketingAction;

export const remarketingActionBaseDecoders = {
  id: text,
  remarketingVehicleId: text,
  brand: enumValue(AvailableBrands),
  actionType: enumValue(RemarketingActionType),
  userId: text,
  userName: text,
  userEmail: text,
  dealerName: maybe(text),
  dealerId: maybe(number),
  timestamp: number,
};

export const decodeRemarketingActionBase = object<RemarketingActionBase>(
  remarketingActionBaseDecoders,
);

export const decodeBidRemarketingAction = object<BidRemarketingAction>({
  ...remarketingActionBaseDecoders,
  bid: number,
  actionType: is(RemarketingActionType.BID),
  result: maybe(enumValue(BidActionResult)),
  dealerId: number,
  dealerName: text,
});

export const decodeOfferComment = object<OfferComment>({
  comment: text,
  id: string,
  timestamp: number,
  userEmail: string,
  userId: string,
  userName: string,
});

export const decodeOfferRemarketingAction = object<OfferRemarketingAction>({
  ...remarketingActionBaseDecoders,
  value: number,
  actionType: is(RemarketingActionType.OFFER),
  status: maybe(enumValue(OfferActionStatus)),
  userComment: maybe(decodeOfferComment),
  adminComment: maybe(decodeOfferComment),
  dealerId: number,
  dealerName: text,
});

export const decodeCommentRemarketingAction = object<CommentRemarketingAction>({
  ...remarketingActionBaseDecoders,
  comment: text,
  actionType: is(RemarketingActionType.COMMENT),
});

export const decodeCancelRemarketingAction = object<CancelRemarketingAction>({
  ...remarketingActionBaseDecoders,
  status: is(RemarketingVehicleStatus.CANCELLED),
  actionType: is(RemarketingActionType.CANCEL),
});

export const decodeAddToWatchlistAction = object<AddToWatchlistAction>({
  ...remarketingActionBaseDecoders,
  actionType: is(RemarketingActionType.ADD_TO_WATCHLIST),
});

export const decodeDeleteFromWatchlistAction =
  object<DeleteFromWatchlistAction>({
    ...remarketingActionBaseDecoders,
    actionType: is(RemarketingActionType.DELETE_FROM_WATCHLIST),
  });
export const decodeRelistRemarketingAction = object<RelistRemarketingAction>({
  ...remarketingActionBaseDecoders,
  status: enumValue(RelistRemarketingActionStatus),
  addRemarketingVehicleRequest: decodeAddRemarketingVehicleRequest(number),
  actionType: is(RemarketingActionType.RELIST),
  _expireAt: maybe(number),
});

export const decodeArchiveRemarketingAction = object<ArchiveRemarketingAction>({
  ...remarketingActionBaseDecoders,
  actionType: is(RemarketingActionType.ARCHIVE),
});

export const decodeBuyNowRemarketingAction = object<BuyNowRemarketingAction>({
  ...remarketingActionBaseDecoders,
  actionType: is(RemarketingActionType.BUY_NOW),
});

export const decodeTransferRemarketingAction =
  object<TransferRemarketingAction>({
    ...remarketingActionBaseDecoders,
    actionType: is(RemarketingActionType.TRANSFER),
  });

export const decodeRemarketingAction = choose(
  decodeBidRemarketingAction,
  decodeOfferRemarketingAction,
  decodeCommentRemarketingAction,
  decodeCancelRemarketingAction,
  decodeAddToWatchlistAction,
  decodeDeleteFromWatchlistAction,
  decodeRelistRemarketingAction,
  decodeArchiveRemarketingAction,
  decodeBuyNowRemarketingAction,
  decodeTransferRemarketingAction,
);
