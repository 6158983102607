import { enumValue, maybe, number, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import {
  UserDocument,
  UserDocumentScope,
  UserDocumentSource,
} from '../../util/UserDocument.js';

export type AddUserDocumentRequest = Omit<
  UserDocument,
  'id' | 'createdAt' | 'uploadedBy'
>;

export const decodeAddUserDocumentRequest = object<AddUserDocumentRequest>({
  brand: enumValue(AvailableBrands),
  contentType: maybe(string),
  name: string,
  reference: string,
  scope: enumValue(UserDocumentScope),
  size: maybe(number),
  source: enumValue(UserDocumentSource),
  url: string,
});
