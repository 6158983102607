import { t } from 'i18next';
import React, { useCallback, useEffect } from 'react';
import { GetCurrentIdentityResponse } from '../../api/UserService/getCurrentIdentity/GetCurrentIdentity.js';
import { ClientError } from '../../api/util/ClientError.js';
import { UserPermissions } from '../../api/util/UserPermissions.js';
import { useMessageStore } from '../common-ui/index.js';
import { useApiClient } from './useApiClient.js';
import { useAsyncState } from './useAsyncState.js';
import { useContextWithError } from './useContextWithError.js';

export interface CurrentIdentity {
  error?: unknown;
  loadCurrentIdentity: () => void;
  hasPermission: (policy: UserPermissions, value?: boolean | string) => boolean;
  hasAnyPermission: (policy: UserPermissions) => boolean;
  loading?: boolean;
  value?: GetCurrentIdentityResponse;
  isSupport?: boolean;
  isRemarketingAdmin?: boolean;
}

const currentUserContext = React.createContext<CurrentIdentity | undefined>(
  undefined,
);

export function CurrentUserProvider({
  children,
}: {
  children?: React.ReactNode;
}): JSX.Element {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const [state, setState] = useAsyncState<GetCurrentIdentityResponse>();

  const loadCurrentIdentity = useCallback(() => {
    setState(async () => {
      const result = await api.user.getCurrentIdentity();
      return result;
    });
  }, [api, setState]);

  const hasPermission = useCallback(
    (permission: UserPermissions, resource?: boolean | string): boolean => {
      let userHasPolicy = false;
      if (!state.value) {
        return userHasPolicy;
      }
      const user = state.value;

      if (!resource || typeof resource === 'boolean') {
        userHasPolicy = Boolean(user.policy[permission]);
      } else {
        userHasPolicy = ((user.policy[permission] as string[]) || []).includes(
          resource,
        );
      }

      return userHasPolicy;
    },
    [state],
  );

  const hasAnyPermission = useCallback(
    (permission: UserPermissions): boolean => {
      const user = state.value;
      return (
        Array.isArray(user?.policy[permission]) &&
        (user?.policy[permission] as string[]).length > 0
      );
    },
    [state],
  );

  useEffect(() => {
    if (
      !!state.error &&
      state.error instanceof ClientError &&
      state.error.httpStatus === 409
    ) {
      showMessage({
        severity: 'error',
        text: t(`validation.${state.error.error}`, {
          defaultValue: state.error.message,
        }),
        dismissible: true,
      });
    }
  }, [state.error]);

  const isSupport = hasPermission('Support');
  const isRemarketingAdmin =
    hasAnyPermission('RemarketingAdminBrands') ||
    hasAnyPermission('RemarketingReadOnlyBrands');

  return React.createElement(currentUserContext.Provider, {
    children,
    value: {
      error: state.error,
      loadCurrentIdentity,
      hasPermission,
      hasAnyPermission,
      loading: state.loading,
      value: state.value,
      isSupport,
      isRemarketingAdmin,
    },
  });
}

export function useCurrentUser(): CurrentIdentity {
  return useContextWithError(currentUserContext, 'currentUser');
}
