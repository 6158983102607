import { Box } from '@mui/material';
import { ErrorState } from '../../common-ui/index.js';

const ErrorPage = (): JSX.Element => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <ErrorState
        alertTitle="Contact Support"
        error="There has been an issue. Please contact support"
      />
    </Box>
  );
};

export default ErrorPage;
