import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CellTextInput } from '../../../../common-ui/index.js';
import { Section } from './types.js';

const Emission: FunctionComponent<Omit<Section, 'vehicleListing'>> = ({
  title,
  sectionItemsProps,
  readonly,
}) => {
  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            <CellTextInput
              label="nedcUrbanFuelEconomy"
              name="nedcUrbanFuelEconomy"
              readonly={readonly}
            />
            <CellTextInput
              label="nedcExtraUrbanFuelEconomy"
              name="nedcExtraUrbanFuelEconomy"
              readonly={readonly}
            />
          </Stack>
          <Stack flex={1}>
            <CellTextInput
              label="nedcCombinedFuelEconomy"
              name="nedcCombinedFuelEconomy"
              readonly={readonly}
            />
            <CellTextInput
              label="nedcCo2Emissions"
              name="nedcCo2Emissions"
              readonly={readonly}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Emission;
