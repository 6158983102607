import { throttle } from 'lodash';
import React, { useEffect, useState } from 'react';

// File copied from MIT-Licensed
// https://github.com/Purii/react-use-scrollspy/blob/be27ac60195ac00ee0c01cc0828c0bcc4127f334/src/index.ts.
// The types there don't work because the author hasn't set "type": "module" in
// the package.json. The function is just vendored here because there's not much
// of it and the package is largely unmaintained.

export interface UseScrollSpyParams {
  activeSectionDefault?: number;
  offsetPx?: number;
  sectionElementRefs: React.RefObject<HTMLElement>[];
  throttleMs?: number;
  scrollingElement?: React.RefObject<HTMLElement>;
}

// TODO: refactor to use Intersection Observer API
export function useScrollSpy({
  activeSectionDefault = 0,
  offsetPx = 0,
  scrollingElement,
  sectionElementRefs = [],
  throttleMs = 100,
}: UseScrollSpyParams): number | undefined {
  const [activeSection, setActiveSection] = useState(activeSectionDefault);

  const handle = throttle(() => {
    let currentSectionId = activeSection;

    for (let i = 0; i < sectionElementRefs.length; i += 1) {
      const section = sectionElementRefs[i].current;
      // Needs to be a valid DOM Element
      if (!section || !(section instanceof Element)) {
        continue;
      }
      // GetBoundingClientRect returns values relative to viewport
      if (section.getBoundingClientRect().top + offsetPx < 0) {
        currentSectionId = i;
        continue;
      }
      // No need to continue loop, if last element has been detected
      break;
    }

    setActiveSection(currentSectionId);
  }, throttleMs);

  useEffect(() => {
    const scrollable = scrollingElement?.current ?? window;
    scrollable.addEventListener('scroll', handle);

    // Run initially
    handle();

    return () => {
      scrollable.removeEventListener('scroll', handle);
    };
  }, [sectionElementRefs, offsetPx, scrollingElement, handle]);

  return activeSection;
}
