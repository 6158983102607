import { useMemo } from 'react';
import {
  ChannelAuthorizationHandler,
  Pusher,
  UserAuthenticationHandler,
} from '../../shims/pusher-client.browser.js';
import { useAppConfig } from './useAppConfig.js';

export function usePusher(options?: {
  userAuthenticationHandler?: UserAuthenticationHandler;
  channelAuthorizationHandler?: ChannelAuthorizationHandler;
}): Pusher {
  const {
    pusher: { appKey },
  } = useAppConfig();

  const pusher = useMemo(
    () =>
      new Pusher(appKey, {
        cluster: 'eu',
        userAuthentication: options?.userAuthenticationHandler
          ? {
              customHandler: options.userAuthenticationHandler,
            }
          : undefined,
        channelAuthorization: options?.channelAuthorizationHandler
          ? {
              customHandler: options.channelAuthorizationHandler,
            }
          : undefined,
        forceTLS: true,
      }),
    [options?.userAuthenticationHandler, options?.channelAuthorizationHandler],
  );

  return pusher;
}
