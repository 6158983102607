import { LoadingButton } from '@mui/lab';
import { Tooltip, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AdminListRemarketingActionsResponse } from '../../../../api/RemarketingService/adminListRemarketingActions/AdminListRemarketingActionsResponse.js';
import { RelistRemarketingActionStatus } from '../../../../api/util/RemarketingAction.js';
import {
  RemarketingActionType,
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import { ConfirmModal, ErrorState } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useToggle } from '../../../hooks/useToggle.js';

interface RelistButtonProps {
  remarketingVehicle: RemarketingVehicle;
}

const RelistButton: FunctionComponent<RelistButtonProps> = ({
  remarketingVehicle,
}) => {
  const api = useApiClient();
  const { t } = useTranslation();
  const [relistAction, setRelistAction] =
    useAsyncState<AdminListRemarketingActionsResponse>();
  const [isConfirmOpen, toggleConfirmOpen] = useToggle();
  const navigate = useNavigate();

  const handleRelist = useCallback(() => {
    navigate(`../relist/${remarketingVehicle.id}/details`);
  }, [navigate, remarketingVehicle.id]);

  const { title, disabled } = useMemo(() => {
    const lastRelistAction = relistAction.value?.items.sort((a, b) => {
      return b.timestamp - a.timestamp;
    })[0];

    const status =
      lastRelistAction && 'status' in lastRelistAction
        ? lastRelistAction.status
        : undefined;

    const title = remarketingVehicle.archived
      ? t('pages.remarketingVehiclePage.header.disabledRelist')
      : status === RelistRemarketingActionStatus.Creating
      ? t('pages.remarketingVehiclePage.header.creatingRelist')
      : '';

    const disabled =
      remarketingVehicle.archived ||
      (status && status !== RelistRemarketingActionStatus.Error);

    return {
      title: title,
      disabled: disabled,
    };
  }, [relistAction.value?.items, remarketingVehicle.archived, t]);

  useEffect(() => {
    setRelistAction(
      async () =>
        await api.remarketing.adminListRemarketingActions({
          brand: remarketingVehicle.vehicle.brand,
          remarketingVehicleId: remarketingVehicle.id,
          actionType: RemarketingActionType.RELIST,
        }),
    );
  }, [
    api.remarketing,
    remarketingVehicle.id,
    remarketingVehicle.vehicle.brand,
    setRelistAction,
  ]);

  if (relistAction.error) {
    return <ErrorState error={t('errorOccuredTitle')} hideTitle />;
  }

  return (
    <>
      {[
        RemarketingVehicleStatus.CLOSED,
        RemarketingVehicleStatus.CANCELLED,
      ].includes(remarketingVehicle.status) ? (
        <Tooltip arrow title={title}>
          <span>
            <LoadingButton
              color="primary"
              disabled={disabled}
              loading={relistAction.loading}
              onClick={toggleConfirmOpen}
              type="button"
              variant="contained"
            >
              {t('pages.remarketingVehiclePage.header.relistVehicle')}
            </LoadingButton>
          </span>
        </Tooltip>
      ) : null}
      <ConfirmModal
        cancelText={t('back')}
        confirmText={t('pages.remarketingVehiclePage.header.relistVehicle')}
        fullWidth
        maxWidth="xs"
        onClose={toggleConfirmOpen}
        onConfirm={handleRelist}
        open={isConfirmOpen}
        title={t('pages.remarketingVehiclePage.header.relistVehicle')}
      >
        <Typography variant="body2">
          {t('pages.remarketingVehiclePage.header.confirmRelistText')}
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default RelistButton;
