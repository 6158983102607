import { useCallback, useState } from 'react';

interface ToggleFunction {
  (): void;
  (newValue: boolean): void;
}

export function useToggle(initValue?: boolean): [boolean, ToggleFunction] {
  const [value, setValue] = useState(!!initValue);

  const toggle: ToggleFunction = useCallback(
    (newValue?: unknown) => {
      setValue(typeof newValue === 'boolean' ? newValue : !value);
    },
    [value],
  );

  return [value, toggle];
}
