import { RemarketingVehicleStatus } from '../../api/util/RemarketingVehicle.js';

type FieldsToUpdateByStatus = Partial<
  Record<RemarketingVehicleStatus, string[]>
>;

const fieldsToUpdateByStatus: FieldsToUpdateByStatus = {
  [RemarketingVehicleStatus.ACTIVE]: [
    'currentBid',
    'currentOffer',
    'status',
    'bidders',
  ],
};

export const shouldAnimateFieldChange = (
  status: RemarketingVehicleStatus,
  changes?: string[],
): boolean => {
  return (
    (!!fieldsToUpdateByStatus &&
      fieldsToUpdateByStatus[status]?.some((i) => changes?.includes(i))) ??
    false
  );
};
