import { array, number, object, string } from '@fmtk/decoders';

export interface RegionModelStats {
  amountOfVehicles: number;
  model: string;
  regionName: string;
}

export const decodeRegionModelStats = object<RegionModelStats>({
  amountOfVehicles: number,
  model: string,
  regionName: string,
});

export const decodeListRegionModelStatsResponse = array(decodeRegionModelStats);
