import { enumValue, number, object, optional, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface DownloadInventoryPricingCsvRequest {
  ageLimit: number;
  brand: AvailableBrands;
  currency: string;
  region?: string;
}

export const decodeDownloadInventoryPricingCsvRequest =
  object<DownloadInventoryPricingCsvRequest>({
    ageLimit: number,
    brand: enumValue(AvailableBrands),
    currency: string,
    region: optional(string),
  });
