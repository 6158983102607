import { Stack } from '@mui/system';
import { DateTime } from 'luxon';
import { FunctionComponent, useMemo } from 'react';
import { odometerUnitOptions } from '../../../../../api/util/OdometerUnit.js';
import { RemarketingType } from '../../../../../api/util/RemarketingVehicle.js';
import CellDateTimeInput from '../../../../common-ui/components/Cell/CellDateTimeInput.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import {
  CellMoneyInput,
  CellSelectInput,
  CellTextInput,
  useForm,
} from '../../../../common-ui/index.js';
import { useCurrency } from '../../../../hooks/useCurrency.js';
import { useRemarketingConfig } from '../../../../hooks/useRemarketingConfig.js';
import { VehicleListingFields } from '../../../../util/VehicleListingFields.js';
import { YesNoOptions } from '../../Vehicle/Sections/types.js';
import { Section, dateTimeFormat } from '../types.js';

type BidSectionProps = Section & { type: RemarketingType };

const BidSection: FunctionComponent<BidSectionProps> = ({
  title,
  sectionItemsProps,
  readonly,
  readonlyDepStatus,
  type,
}) => {
  const [formState] = useForm();
  const { currencyOptions } = useCurrency();
  const { brandSyndicationTypes, showVehicleListingField } =
    useRemarketingConfig();
  const selectedCurrency = useMemo(
    () => formState.values.currency,
    [formState.values.currency],
  );

  const minClosingDate = formState.values?.startingDate
    ? DateTime.fromFormat(formState.values['startingDate'], dateTimeFormat)
        .plus({ hours: 1 })
        .toFormat(dateTimeFormat)
    : '';

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            <CellDateTimeInput
              inputProps={{
                max: formState.values['startingDate'],
              }}
              label="visibilityDate"
              name="visibilityDate"
              readonly={readonly}
            />
            <CellDateTimeInput
              inputProps={{
                max: formState.values['closingDate'],
              }}
              label="startingDate"
              name="startingDate"
              readonly={readonly}
            />
            <CellDateTimeInput
              inputProps={{
                min: minClosingDate,
              }}
              label="closingDate"
              name="closingDate"
              readonly={readonly}
            />
            <CellTextInput
              label="odometer"
              name="odometer"
              readonly={readonly}
            />
            <CellSelectInput
              label="odometerUnit"
              name="odometerUnit"
              options={odometerUnitOptions}
              readonly={readonly}
            />
            <CellTextInput
              label="shortVin"
              name="shortVin"
              readonly={readonly}
            />
            <CellTextInput
              label="regPlate"
              name="registrationPlate"
              readonly={readonly}
            />
          </Stack>
          <Stack flex={1}>
            {showVehicleListingField(VehicleListingFields.SyndicationType) && (
              <CellSelectInput
                label="syndicationType"
                name="syndicationType"
                options={brandSyndicationTypes || []}
                readonly={readonly}
              />
            )}
            <CellSelectInput
              label="Currency"
              name="currency"
              options={currencyOptions}
              readonly={readonly}
            />
            {type === RemarketingType.AUCTION && (
              <>
                <CellMoneyInput
                  currency={selectedCurrency}
                  label="startPrice"
                  name="startPrice"
                  readonly={readonlyDepStatus}
                />
                <CellMoneyInput
                  currency={selectedCurrency}
                  label="reservePrice"
                  name="reservePrice"
                  readonly={readonlyDepStatus}
                />
              </>
            )}
            {type === RemarketingType.FIXED_PRICE && (
              <>
                <CellMoneyInput
                  currency={selectedCurrency}
                  label="price"
                  name="price"
                  readonly={readonly}
                />
                <CellSelectInput
                  label="acceptsOffers"
                  name="acceptsOffers"
                  options={YesNoOptions}
                  readonly={readonly}
                />
              </>
            )}
            <CellMoneyInput
              currency={selectedCurrency}
              label="recommendedRetailPrice"
              name="recommendedRetailPrice"
              readonly={readonly}
            />
            {type === RemarketingType.AUCTION && (
              <>
                <CellTextInput
                  highlightChanges
                  label="leadingBid"
                  name="leadingBid"
                />
                <CellTextInput
                  highlightChanges
                  label="bidders"
                  name="bidders"
                />
              </>
            )}
          </Stack>
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default BidSection;
