import { object, text } from '@fmtk/decoders';

export interface ListVehicleListingLogsRequest {
  vinOrId: string;
}

export const decodeListVehicleListingLogsRequest =
  object<ListVehicleListingLogsRequest>({
    vinOrId: text,
  });
