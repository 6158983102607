import { array, boolean, maybe, number, object, text } from '@fmtk/decoders';
import { TaxonomyOption } from '../../../core/DbService/model/TaxonomyOption.js';

export type ColourOption = {
  description: string;
  metaColour?: string;
};

export type EngineOption = TaxonomyOption & {
  metaEngine?: string;
};
export type TransmissionOption = TaxonomyOption & {
  metaTransmission?: string;
};
export type BodyStyleOption = TaxonomyOption & {
  metaBodyStyle?: string;
};

export type ModelOption = Omit<TaxonomyOption, 'enabled'> & {
  class?: string;
  groupName?: string;
};

export type ListTaxonomyOptionsResponse = {
  colourOptions?: ColourOption[];
  interiorColourOptions?: ColourOption[];
  bodyStyleOptions?: BodyStyleOption[];
  models?: ModelOption[];
  engineOptions?: EngineOption[];
  transmissionOptions?: TransmissionOption[];
};

export const decodeMetaColour = object<ColourOption>({
  metaColour: maybe(text),
  description: text,
});

export const decodeEngineOption = object<EngineOption>({
  id: number,
  enabled: boolean,
  listorder: maybe(number),
  metaEngine: maybe(text),
  name: text,
});

export const decodeTransmissionOption = object<TransmissionOption>({
  id: number,
  enabled: boolean,
  listorder: maybe(number),
  metaTransmission: maybe(text),
  name: text,
});

export const decodeBodyStyleOption = object<BodyStyleOption>({
  id: number,
  enabled: boolean,
  listorder: maybe(number),
  metaBodyStyle: maybe(text),
  name: text,
});

export const decodeModelOption = object<ModelOption>({
  class: maybe(text),
  id: number,
  listorder: maybe(number),
  groupName: maybe(text),
  name: text,
});

export const decodeListTaxonomyOptionsResponse =
  object<ListTaxonomyOptionsResponse>({
    colourOptions: maybe(array(decodeMetaColour)),
    interiorColourOptions: maybe(array(decodeMetaColour)),
    bodyStyleOptions: maybe(array(decodeBodyStyleOption)),
    models: maybe(array(decodeModelOption)),
    engineOptions: maybe(array(decodeEngineOption)),
    transmissionOptions: maybe(array(decodeEngineOption)),
  });
