import { chain, Decoder, invalid, number } from '@fmtk/decoders';

const InvalidNumber = 'INVALID_MIN_NUMBER';

export function minNumber(opts: {
  min: number;
  error?: string;
}): Decoder<number> {
  return chain(number, (value) => {
    if (value < opts.min) {
      return invalid(
        InvalidNumber,
        opts.error || `Invalid value, should be higher than ${opts.min}`,
      );
    }

    return {
      ok: true,
      value,
    };
  });
}
