import { enumValue, number, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import {
  DealerUser,
  decodeDealerUser,
} from '../listUsersByDealer/ListDealerUsersResponse.js';

export interface UpdateUserDealerRequest {
  dealerId: number;
  brand: AvailableBrands;
  user: DealerUser;
}

export const decodeUpdateDealerUserRequest = object<UpdateUserDealerRequest>({
  dealerId: number,
  brand: enumValue(AvailableBrands),
  user: decodeDealerUser,
});
