import { pino, Logger as PinoLogger } from 'pino';

export interface StructuredLogMessage {
  event: string;
  msg?: string;
  [key: string]: any;
}

export interface StructuredLogMessageWithTrace extends StructuredLogMessage {
  trace: any;
}

export class Logger {
  private static _current: Logger | undefined;

  public static get current(): Logger {
    if (!this._current) {
      this._current = new Logger(
        pino({
          level: process.env.LOG_LEVEL || 'silent',
        }),
      );
    }
    return this._current;
  }
  public static set current(value: Logger) {
    this._current = value;
  }

  public get traceEnabled(): boolean {
    return this._traceEnabled;
  }
  private readonly _traceEnabled: boolean;

  constructor(private readonly logger: PinoLogger) {
    this._traceEnabled = this.logger.isLevelEnabled('trace');
  }

  public error = (msg: StructuredLogMessage): void => {
    this.logger.error(msg);
  };

  public info = (msg: StructuredLogMessage): void => {
    this.logger.info(msg);
  };

  public infoTrace = ({
    trace,
    ...msg
  }: StructuredLogMessageWithTrace): void => {
    if (this.traceEnabled) {
      this.trace({ ...msg, trace });
    } else {
      this.info(msg);
    }
  };

  public trace = (msg: StructuredLogMessage): void => {
    this.logger.trace(msg);
  };

  public warn = (msg: StructuredLogMessage): void => {
    this.logger.warn(msg);
  };
}
