import { Chart, ChartOptions, LegendItem } from 'chart.js';
import { t } from 'i18next';

export const options: ChartOptions<'bar'> = {
  responsive: true,
  plugins: {
    legend: {
      labels: {
        generateLabels: (chart: Chart) => {
          // Object that contains base properties for LegendItem type
          const baseProperties: LegendItem = { text: '', lineWidth: 0 };

          // Gets objects with different STACK property to build legends
          return chart.data.datasets.reduce(
            (acc: LegendItem[], current: any) => {
              const stackFound = acc.find(
                (item) => item.text === current.stack,
              );
              if (stackFound) {
                return acc;
              }

              return acc.concat([
                {
                  ...baseProperties,
                  text: current.stack,
                  fillStyle: current.backgroundColor,
                },
              ]);
            },
            [],
          );
        },
      },
      // By default, chart filters dataset when user clicks on legends
      // Since we are building custom legends, we need to overide onClick event.
      onClick: () => null,
    },
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.region'),
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.noOfVehicles'),
      },
    },
  },
};
