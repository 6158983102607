import { enumValue, maybe, number, object, string, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export type UserSubmitOfferRequest = {
  remarketingVehicleId: string;
  brand: AvailableBrands;
  value: number;
  dealerId: number;
  comments?: string;
};

export const decodeUserSubmitOfferRequest = object<UserSubmitOfferRequest>({
  remarketingVehicleId: text,
  brand: enumValue(AvailableBrands),
  value: number,
  dealerId: number,
  comments: maybe(string),
});
