import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { ListUserNotificationsResponse } from '../../../../api/NotificationsService/listUserNotifications/ListUserNotificationsResponse.js';
import { InAppNotification } from '../../../../api/util/InAppNotification.js';
import { WsUserEvent } from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { QueryKeys } from './QueryKeys.js';

export const makeListInAppNotificationsQueryKey = (
  unreadOnly?: boolean,
): QueryKey =>
  filterFalsey([
    QueryKeys.InAppNotifications,
    unreadOnly !== undefined && { unreadOnly },
  ]);

export type ListInAppNotificationQueryData =
  InfiniteData<ListUserNotificationsResponse>;

export const useListInAppNotifications = (
  unreadOnly: boolean,
): UseInfiniteQueryResult<ListInAppNotificationQueryData> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const newEventIncoming = useWsChannelEvent<InAppNotification>(
    WsUserEvent.NewNotification,
  );

  useEffect(() => {
    if (newEventIncoming) {
      queryClient.setQueriesData<ListInAppNotificationQueryData>(
        { queryKey: makeListInAppNotificationsQueryKey() },
        (prev) => {
          if (prev?.pages.length) {
            const [firstPage, ...pages] = prev.pages;
            firstPage.items.unshift(newEventIncoming);
            firstPage.total += 1;
            firstPage.unreadCount += 1;
            return {
              ...prev,
              pages: [firstPage, ...pages],
            };
          } else {
            return {
              ...prev,
              pageParams: [],
              pages: [
                {
                  items: [newEventIncoming],
                  total: 1,
                  unreadCount: 1,
                },
              ],
            };
          }
        },
      );
    }
  }, [newEventIncoming]);

  return useInfiniteQuery({
    queryKey: makeListInAppNotificationsQueryKey(unreadOnly),
    queryFn: async ({ pageParam }) =>
      await api.inAppNotifications.listUserNotifications({
        nextToken: pageParam,
        unreadOnly,
      }),
    getNextPageParam: (lastPage) => lastPage?.nextToken,
    initialPageParam: undefined as string | undefined,
  });
};
