import { Option } from '../util/Option.js';
import { VehicleListingFields } from '../util/VehicleListingFields.js';
import { useBrand } from './useBrand.js';
import { useSyndicationTypeOptions } from './useSyndicationTypeOptions.js';

export type RemarketingConfig = {
  brandSyndicationTypes: Option[];
  showVehicleListingField: (field: VehicleListingFields) => boolean;
};

export const useRemarketingConfig = (): RemarketingConfig => {
  const { brandConfig } = useBrand();
  const brandSyndicationTypes = useSyndicationTypeOptions({
    remarketing: true,
  });

  return {
    showVehicleListingField: (field: VehicleListingFields) =>
      !brandConfig?.remarketing?.hiddenFields?.includes(field),
    brandSyndicationTypes,
  };
};
