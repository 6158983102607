import { Container, ContainerProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { sx } from '../../util/sx.js';

const styles = sx({
  main: {
    px: 2,
    pt: {
      sm: 0,
      lg: 4,
    },
    pb: 10,
  },
});

export const PageContainer: FunctionComponent<ContainerProps> = ({
  maxWidth = 'xl',
  ...props
}) => {
  return <Container maxWidth={maxWidth} sx={styles.main} {...props} />;
};

export default PageContainer;
