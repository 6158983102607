import { NavigateBefore, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Button, Stack } from '@mui/material';
import { Box } from '@mui/system';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { FunctionComponent, useCallback, useEffect, useRef } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { SimpleImage } from '../../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import { AddRemarketingVehicleRequest } from '../../../../../api/RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import { RelistRemarketingErrors } from '../../../../../api/RemarketingService/common.js';
import { HttpServiceTransportError } from '../../../../../api/util/HttpServiceTransportError.js';
import { RemarketingVehicle } from '../../../../../api/util/RemarketingVehicle.js';
import { ImageTags } from '../../../../../api/util/VehicleMedia.js';
import TableOfContents, {
  ContentItem,
} from '../../../../common-ui/components/TableOfContents.js';
import { useMessageStore } from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { filterFalsey } from '../../../../util/filterFalsey.js';
import { sx } from '../../../../util/sx.js';
import { MAIN_CONTAINER_ID } from '../../../layout/MainLayout.js';
import { addRemarketingVehicleAtom } from '../../../state/atoms.js';
import Section from '../../Section.js';
import ImageAccordion from '../../VehicleMedia/ImageAccordion.js';

const styles = sx({
  containerStyle: {
    justifyContent: 'space-between',
    bgcolor: 'background.paper',
    borderTop: '1px solid #C4CDD5',
    bottom: 0,
    padding: 1,
    position: 'sticky',
    zIndex: 'mobileStepper',
  },
});
interface MediaStepProps {
  readonly?: boolean;
}

const MediaStep: FunctionComponent<MediaStepProps> = ({ readonly }) => {
  const [state, setState] = useAtom(addRemarketingVehicleAtom);
  const { currentBrand } = useBrand();
  const api = useApiClient();
  const [saveState, setSaveState] = useAsyncState<RemarketingVehicle>();
  const navigate = useNavigate();
  const { showMessage } = useMessageStore();
  const { remarketingId } = useParams();

  const contentItems: ContentItem[] = [
    {
      text: t('photos'),
      hash: 'photos',
      sectionElementRef: useRef(null),
    },
  ];

  const handleChange = useCallback(
    (simpleImages?: SimpleImage[]) => {
      const newMedia = filterFalsey(
        simpleImages?.map((simpleImage, index) => {
          const { media, isPlaceholder } = simpleImage;
          if (!media || isPlaceholder) {
            return undefined;
          }
          return {
            ...media,
            position: index + 1,
          };
        }) || [],
      );

      setState((prevState) => {
        return {
          ...prevState,
          currentRemarketingVehicle: {
            ...prevState.currentRemarketingVehicle,
            vehicleMedia: newMedia,
          } as AddRemarketingVehicleRequest,
        };
      });
    },
    [setState],
  );

  const handleSave = useCallback(() => {
    setSaveState(async () => {
      if (!state.currentRemarketingVehicle || !currentBrand) {
        return;
      }

      let response;

      const { vehicleMedia, ...newVehicle } = state.currentRemarketingVehicle;

      try {
        if (!remarketingId) {
          response = await api.remarketing.adminAddRemarketingVehicle({
            ...newVehicle,
            vehicleMedia: vehicleMedia ?? [],
          } as AddRemarketingVehicleRequest);
          showMessage({
            text: 'The remarketing has been created.',
            severity: 'success',
            dismissible: true,
            duration: 1000 * 10,
          });
        } else {
          await api.remarketing.adminRelistRemarketingVehicle({
            addRemarketingVehicleRequest: {
              ...newVehicle,
              vehicleMedia: vehicleMedia ?? [],
            } as AddRemarketingVehicleRequest,
            idToRelist: remarketingId,
          });

          showMessage({
            text: t('pages.relistRemarketingVehicle.relistSuccess'),
            severity: 'success',
            dismissible: true,
          });
        }

        setState(RESET);
        navigate('../../');

        return response;
      } catch (error) {
        console.error(error);

        if (
          HttpServiceTransportError.is(
            error,
            RelistRemarketingErrors.RELIST_INVALID_STATUS,
          )
        ) {
          showMessage({
            severity: 'error',
            text: t(
              `pages.relistRemarketingVehicle.${RelistRemarketingErrors.RELIST_INVALID_STATUS}`,
              {
                status: t(`remarketingVehicleStatus.${error.details}`),
              },
            ),
            dismissible: true,
          });
          return;
        }

        showMessage({
          severity: 'error',
          text: t('errorOccurredMessage'),
          dismissible: true,
        });
        return response;
      }
    });
  }, [
    setSaveState,
    state.currentRemarketingVehicle,
    currentBrand,
    remarketingId,
    showMessage,
    setState,
    navigate,
    api.remarketing,
  ]);

  useEffect(() => {
    if (!state.currentRemarketingVehicle) {
      //clear state and go to step 1
      setState(RESET);
      navigate('../');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, state.currentRemarketingVehicle]);

  return (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section title={t('media')}>
          <Stack spacing={3}>
            <div
              id={contentItems[0].hash}
              ref={
                contentItems[0]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <ImageAccordion
                accordionProps={{ disableGutters: true }}
                defaultExpanded
                hideSaveButton
                imageTags={[ImageTags.Remarketing]}
                loading={saveState.loading}
                onChange={handleChange}
                readonly={readonly}
                vehicle={state.currentRemarketingVehicle?.vehicle}
                vehicleMedia={state.currentRemarketingVehicle?.vehicleMedia}
              />
            </div>
          </Stack>
          <Box sx={styles.containerStyle}>
            <Stack direction="row" justifyContent="space-between" m={1}>
              <Button
                disabled={saveState.loading}
                onClick={() => {
                  navigate('../features');
                }}
                startIcon={<NavigateBefore />}
                variant="outlined"
              >
                {t('features')}
              </Button>
              <LoadingButton
                endIcon={<Save />}
                loading={saveState.loading}
                loadingPosition="end"
                onClick={handleSave}
                variant="contained"
              >
                {t('saveAndFinish')}
              </LoadingButton>
            </Stack>
          </Box>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
    </Stack>
  );
};

export default MediaStep;
