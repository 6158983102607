import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  ListInAppNotificationQueryData,
  makeListInAppNotificationsQueryKey,
} from '../queries/useListInappNotifications.js';

export const useDeleteAllInAppNotifications = (): UseMutationResult => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      await api.inAppNotifications.deleteAllUserNotifications();
    },
    onSuccess: async () => {
      await queryClient.setQueriesData<ListInAppNotificationQueryData>(
        {
          queryKey: makeListInAppNotificationsQueryKey(),
        },
        () => ({
          pages: [],
          pageParams: [],
        }),
      );
    },
  });
};
