import { LoadingButton } from '@mui/lab';
import { Divider, Grid, Typography, colors } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import {
  UserBiddingHistory,
  UserListBiddingHistoryResponse,
} from '../../../../../api/RemarketingService/userListBiddingHistory/UserListBiddingHistoryResponse.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import { EmptyState, TIMEOUT } from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { formatDateTime } from '../../../../util/dates.js';
import { formatPrice } from '../../../../util/formatters.js';
import { sx } from '../../../../util/sx.js';
import { RemarketingVehicleType, Section } from '../types.js';

const styles = sx({
  divider: {
    height: 2,
  },
  lost: {
    textDecoration: 'line-through',
  },
  item: {
    '@keyframes blink': {
      '0%': {
        bgcolor: colors.yellow[50],
      },
      '50%': {
        bgcolor: colors.yellow[400],
      },
      '100%': {
        bgcolor: colors.yellow[50],
      },
    },
    '&.item-enter': {
      animation: 'blink 1s infinite',
      fontWeight: 'fontWeightBold',
    },
  },
});

type BiddingHistoryProps = Omit<Section, 'vehicle'> & {
  currency: string;
  remarketingVehicle: RemarketingVehicleType;
};

const HistoryItem = ({
  item,
  currency,
  highlight,
}: {
  item: UserBiddingHistory;
  currency: string;
  highlight: boolean;
}) => {
  const { t } = useTranslation();
  const fontWeight = highlight ? 'fontWeightBold' : undefined;
  return (
    <Grid container sx={styles.item}>
      <Grid item xs>
        <Typography color="grey.600" fontWeight={fontWeight} variant="body2">
          {formatDateTime(item.timestamp)}
        </Typography>
      </Grid>
      <Grid container item xs>
        <Grid item xs>
          <Typography color="#172B4D" fontWeight={fontWeight} variant="body2">
            {formatPrice(item.bid, currency)}
          </Typography>
        </Grid>
      </Grid>
      <Grid item xs>
        <Typography color="#172B4D" fontWeight={fontWeight} variant="body2">
          {item.sameUser ? t('you') : item.userName}
        </Typography>
      </Grid>
      <Grid item xs>
        <Typography color="#172B4D" fontWeight={fontWeight} variant="body2">
          {item.dealerName}
        </Typography>
      </Grid>
    </Grid>
  );
};

const BiddingHistory: FunctionComponent<BiddingHistoryProps> = ({
  title,
  currency,
  remarketingVehicle,
  sectionItemsProps,
}) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const { dealer } = useParams();

  const [history, setHistory] = useAsyncState<UserListBiddingHistoryResponse>();

  useEffect(() => {
    if (!currentBrand || !dealer) {
      return;
    }
    setHistory(
      async () =>
        await api.remarketing.userListBiddingHistory({
          brand: currentBrand,
          dealerId: Number(dealer),
          remarketingVehicleId: remarketingVehicle.id,
        }),
    );
  }, [remarketingVehicle, currentBrand, setHistory, dealer, api]);

  const loadMore = () => {
    if (!history.value?.nextToken || !currentBrand || !dealer) {
      return;
    }
    setHistory(async (prev) => {
      const { items, ...rest } = await api.remarketing.userListBiddingHistory({
        brand: currentBrand,
        dealerId: Number(dealer),
        remarketingVehicleId: remarketingVehicle.id,
        nextToken: history.value?.nextToken,
      });

      const prevResult = await prev;

      return {
        ...rest,
        items: [...(prevResult?.items || []), ...items],
      };
    });
  };

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      {history.value?.items.length === 0 ? (
        <EmptyState>{t(`currentBidStatus.NoBid`)}</EmptyState>
      ) : (
        <Stack spacing={2}>
          <Stack
            divider={<Divider color="#F7F8F9" sx={styles.divider} />}
            spacing={1}
          >
            <Grid container>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.dateTime',
                  )}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.bidAmount',
                  )}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.bidder',
                  )}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.dealer',
                  )}
                </Typography>
              </Grid>
            </Grid>
            {history.value?.items.map((item, idx) =>
              item.leading ? (
                <SwitchTransition key={idx} mode="out-in">
                  <CSSTransition
                    appear={false}
                    classNames="item"
                    enter
                    exit={false}
                    in={item.leading}
                    key={item.id}
                    timeout={TIMEOUT}
                  >
                    <HistoryItem
                      currency={currency}
                      highlight={item.leading}
                      item={item}
                    />
                  </CSSTransition>
                </SwitchTransition>
              ) : (
                <HistoryItem
                  currency={currency}
                  highlight={false}
                  item={item}
                  key={item.id}
                />
              ),
            )}
          </Stack>
          {history.value?.nextToken && (
            <div>
              <LoadingButton
                loading={history.loading}
                onClick={loadMore}
                variant="contained"
              >
                {t('loadMore')}
              </LoadingButton>
            </div>
          )}
        </Stack>
      )}
    </SectionItem>
  );
};

export default BiddingHistory;
