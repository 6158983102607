import { UniqueIdentifier, useDroppable } from '@dnd-kit/core';
import { PropsWithChildren } from 'react';

export function Droppable(
  props: PropsWithChildren & { id: UniqueIdentifier; disabled?: boolean },
) {
  const { isOver, setNodeRef } = useDroppable({
    id: props.id,
    disabled: props.disabled,
  });
  const style = {
    color: isOver ? 'green' : undefined,
  };

  return (
    <>
      <div ref={setNodeRef} style={style}>
        {props.children}
      </div>
    </>
  );
}
