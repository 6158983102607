export const VehicleListingServiceName = 'VehicleListingService';

export enum VehicleListingSort {
  createdDate = 'createdDate',
  price = 'price.retail',
  timestamp = 'timestamp',
}

export enum VehicleListingErrorCode {
  Archived = 'Archived',
  NotFound = 'NotFound',
  AlreadyExits = 'AlreadyExits',
  RelistPending = 'RelistPending',
}
