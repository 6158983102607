import {
  chain,
  enumValue,
  integer,
  is,
  maybe,
  object,
  string,
} from '@fmtk/decoders';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../util/VehicleMedia.js';
import { isUrl } from '../../util/isUrl.js';

export interface AddVehicleMediaRequest {
  cloudinaryId?: string;
  etag?: string;
  file?: string;
  legacyUrl?: string;
  position: number;
  signature?: string;
  userEmail?: string;
  vehicleMediaStatus: VehicleMediaStatus.Approved;
  vehicleMediaType: VehicleMediaType;
  vin: string;
}

export const decodeAddVehicleMediaRequest = object<AddVehicleMediaRequest>({
  cloudinaryId: maybe(string),
  etag: maybe(string),
  file: maybe(chain(string, isUrl)),
  legacyUrl: maybe(string),
  position: integer,
  signature: maybe(string),
  userEmail: maybe(string),
  vehicleMediaStatus: is(VehicleMediaStatus.Approved),
  vehicleMediaType: enumValue(VehicleMediaType),
  vin: string,
});
