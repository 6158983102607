import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import { vehiclePhotoUrls } from '../../../../../util/vehicleMediaHelper.js';
import {
  AstonMartinFlare,
  AstonMartinSans,
} from '../../../../common-ui/fonts/fonts.js';
import { DealerSelectionType } from '../../../../hooks/useDealer.js';
import { VehicleHandDrive } from '../../../../util/HandDrive.js';
import { calculateBalancedDistribution } from '../../../../util/calculateBalancedDistribution.js';
import { dashIfUndefined } from '../../../../util/dashIfUndefined.js';
import { formatDate } from '../../../../util/dates.js';
import {
  formatAcceleration,
  formatMaxSpeed,
  formatPrice,
} from '../../../../util/formatters.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';
import HeaderLogo from './HeaderLogo.js';

const MAX_FEATURE_ITEMS = 35;
export interface DetailPortraitPdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
  dealer?: DealerSelectionType;
}
Font.register(AstonMartinSans);
Font.register(AstonMartinFlare);

const styles = StyleSheet.create({
  page: {
    padding: 15,
    fontFamily: 'AstonMartinSans',
    fontSize: 12,
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 16,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  divider: {
    width: '100%',
    padding: 16,
    borderBottom: '6px solid #000',
  },
  detailsContainer: {
    fontSize: 10,
    marginBottom: 16,
  },
  section: {
    flexDirection: 'row',
  },
  fifty: {
    width: '50%',
  },
  column: {
    width: '50%',
    flexDirection: 'column',
    flexGrow: 1,
  },
  maxHeight: {
    maxHeight: 305,
  },
  flexRow: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flexColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  padding: {
    padding: 4,
  },
  sectionItem: {
    flexGrow: 1,
    flexDirection: 'row',
    paddingVertical: 1,
    flexWrap: 'wrap',
  },
  label: {
    width: '40%',
    fontSize: 8,
    fontWeight: 'light',
  },
  value: {
    paddingHorizontal: 4,
    fontSize: 8,
    flex: 1,
    fontWeight: 'bold',
  },
  title: {
    color: '#58595b',
    paddingBottom: 4,
    letterSpacing: 0.5,
    fontWeight: 'bold',
    fontSize: 11,
    width: '100%',
  },
  lightDivider: {
    width: '100%',
    marginVertical: 10,
    borderBottom: '1px solid #cecece',
  },
  features: {
    flexDirection: 'column',
    paddingVertical: 1,
    flexWrap: 'wrap',
  },
  feature: {
    paddingVertical: 1,
    fontSize: 8,
    fontWeight: 'light',
    width: '50%',
  },
  dealerName: {
    fontSize: 10,
    fontWeight: 'bold',
    color: '#58595b',
  },
  dealerAddress: {
    fontSize: 8,
    color: '#58595b',
    fontWeight: 'light',
  },
  dealerPone: {
    fontSize: 8,
    fontWeight: 'bold',
    color: '#58595b',
  },
  dealerSection: {
    flexDirection: 'row',
    paddingVertical: 16,
    justifyContent: 'space-between',
    alignItems: 'center',
    borderTop: '2px solid #000',
    borderBottom: '2px solid #000',
  },
  certifiedImage: {
    height: 30,
  },
  footer: {
    paddingTop: 16,
    fontSize: 7,
    fontWeight: 'light',
  },
  featureColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  featureGroup: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    marginBottom: 4,
    justifyContent: 'space-between',
  },
});

interface SectionItemProps {
  label: string;
  value?: string;
}

const SectionItem = ({ label, value = '-' }: SectionItemProps): JSX.Element => {
  return (
    <View style={styles.sectionItem}>
      <Text style={styles.label}>{label}</Text>
      <Text style={styles.value}>{value}</Text>
    </View>
  );
};

interface SectionProps {
  items: SectionItemProps[];
  title: string;
}

const Section = ({ items, title }: SectionProps): JSX.Element => {
  return (
    <View style={styles.detailsContainer}>
      <Text style={styles.title}>{title}</Text>
      {items.map((item) => (
        <SectionItem key={item.label} label={item.label} value={item.value} />
      ))}
    </View>
  );
};

const Features = ({
  features,
  othersLabel,
}: {
  features?: VehicleFeatures;
  othersLabel?: string;
}): JSX.Element => {
  if (!features) {
    return <></>;
  }

  return (
    <View style={styles.featureColumn}>
      {Object.entries(features).map(([key, value]) => (
        <View style={styles.featureGroup}>
          <Text style={styles.title}>{key}</Text>
          {value?.map((item) => (
            <Text
              style={{
                ...styles.feature,
                fontWeight:
                  othersLabel === item ? 'bold' : styles.feature.fontWeight,
              }}
            >
              {item}
            </Text>
          ))}
        </View>
      ))}
    </View>
  );
};

const Dealer = ({ dealer }: { dealer?: DealerSelectionType }): JSX.Element => {
  return (
    <View style={styles.fifty}>
      <Text style={styles.dealerName}>{dealer?.name || ''}</Text>
      <Text style={styles.dealerAddress}>{dealer?.address || ''}</Text>
      <Text style={styles.dealerPone}>{dealer?.phone || ''}</Text>
    </View>
  );
};

export const DetailPortraitPdf: FunctionComponent<DetailPortraitPdfProps> = ({
  vehicle,
  pdfConfig,
  dealer,
}) => {
  const { noVehicleImage, othersLabel } = pdfConfig;
  const vehicleMedia = vehiclePhotoUrls(
    vehicle.vehicleMedia,
    't_base_vehicle_transformation/f_jpg',
  );

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.header}>
          <HeaderLogo
            brand={AvailableBrands.AstonMartin}
            logo="https://res.cloudinary.com/motortrak/h_50,c_scale/aston-martin/global/aston-logo-black.jpg"
          />
          <Text style={styles.name}>{vehicle.vehicle.model.name}</Text>
        </View>
        <View style={styles.divider} />
        <View style={styles.section}>
          <View style={[styles.column, styles.maxHeight]}>
            <Image src={vehicleMedia[0] || noVehicleImage} />
            <View style={styles.flexRow}>
              <View style={styles.column}>
                <Image src={vehicleMedia[1] || noVehicleImage} />
              </View>
              <View style={styles.column}>
                <Image src={vehicleMedia[2] || noVehicleImage} />
              </View>
            </View>
          </View>
          <View style={[styles.column, styles.padding]}>
            <Section
              items={[
                {
                  label: 'Price',
                  value: formatPrice(
                    vehicle.price?.retail,
                    vehicle.currency?.code,
                  ),
                },
                {
                  label: 'Registration Date',
                  value: dashIfUndefined(
                    vehicle.vehicle.registrationDate
                      ? formatDate(vehicle.vehicle.registrationDate)
                      : undefined,
                  ),
                },
                {
                  label: 'Model Year',
                  value: vehicle.vehicle.modelYear?.toString(),
                },
                {
                  label: 'Mileage',
                  value: `${vehicle.odometer?.value} ${vehicle.odometer?.units}`,
                },
              ]}
              title="Key Details"
            />
            <Section
              items={[
                {
                  label: 'Transmission',
                  value: vehicle.vehicle.transmission?.name,
                },
                {
                  label: 'Power',
                  value: dashIfUndefined(
                    vehicle.vehicle.engine?.bhp?.toString(),
                  ),
                },
                {
                  label: 'Acceleration',
                  value: formatAcceleration(
                    vehicle.vehicle.techSpecs?.acceleration060Mph,
                    vehicle.vehicle.techSpecs?.acceleration0100Kph,
                  ),
                },
                {
                  label: 'Maximum Speed',
                  value: formatMaxSpeed(
                    vehicle.vehicle.techSpecs?.maxSpeedMph,
                    vehicle.vehicle.techSpecs?.maxSpeedKph,
                    { style: 'MPH(KPH)' },
                  ),
                },
                {
                  label: 'Hand Of Drive',
                  value: dashIfUndefined(
                    vehicle.vehicle.handDrive
                      ? VehicleHandDrive[vehicle.vehicle.handDrive]
                      : undefined,
                  ),
                },
                {
                  label: 'Engine',
                  value: dashIfUndefined(vehicle.vehicle.engine?.description),
                },
              ]}
              title="Technical Specification"
            />
            <Section
              items={[
                {
                  label: 'Exterior Colour',
                  value:
                    vehicle.vehicle.appearanceOptions?.exteriorColour || '-',
                },
                {
                  label: 'Interior Colour',
                  value:
                    vehicle.vehicle.appearanceOptions?.interiorColour || '-',
                },
                {
                  label: 'Headlining',
                  value:
                    vehicle.vehicle.appearanceOptions?.headliningColour || '-',
                },
                {
                  label: 'Carpet',
                  value: vehicle.vehicle.appearanceOptions?.carpetColour || '-',
                },
                {
                  label: 'Seat Colour',
                  value: vehicle.vehicle.appearanceOptions?.seatColour || '-',
                },
              ]}
              title="Colour and Trim"
            />
          </View>
        </View>
        <View style={styles.lightDivider} />
        <View style={styles.section}>
          <View style={styles.flexRow}>
            <Features
              features={calculateBalancedDistribution(
                vehicle.vehicle.features || {},
                MAX_FEATURE_ITEMS,
                othersLabel,
              )}
              othersLabel={othersLabel}
            />
          </View>
        </View>
        <View style={styles.dealerSection}>
          {pdfConfig.certifiedLogo && (
            <Image
              src={pdfConfig.certifiedLogo}
              style={styles.certifiedImage}
            />
          )}
          <Dealer dealer={dealer} />
        </View>
        <Text style={styles.footer}>
          Every effort has been made to ensure the accuracy of the information
          above, however, errors may occur. Do not rely entirely on this
          information but check with your centre about items which may affect
          your decision to purchase.
        </Text>
      </Page>
    </Document>
  );
};

export default DetailPortraitPdf;
