import { enumValue, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface UserGetRemarketingVehicleRequest {
  brand: AvailableBrands;
  id: string;
  dealerId: number;
}

export const decodeUserGetRemarketingVehicleRequest =
  object<UserGetRemarketingVehicleRequest>({
    brand: enumValue(AvailableBrands),
    id: text,
    dealerId: number,
  });
