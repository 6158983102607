import { Delete, Edit, Key, MoreVert, Send } from '@mui/icons-material';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Typography,
} from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FunctionComponent, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserErrorCode } from '../../../../api/UserService/common.js';
import { HttpServiceTransportError, None } from '../../../../api/util/index.js';
import {
  ConfirmModal,
  ErrorState,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useDealer } from '../../../hooks/useDealer.js';
import { sx } from '../../../util/sx.js';

const styles = sx({
  menuPaper: {
    overflow: 'visible',
    filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
    mt: 1,
    '&:before': {
      content: '""',
      display: 'block',
      position: 'absolute',
      top: 0,
      right: 14,
      width: 10,
      height: 10,
      bgcolor: 'background.paper',
      transform: 'translateY(-50%) rotate(45deg)',
      zIndex: 0,
    },
  },
});

interface MenuColumnProps extends GridRenderCellParams {
  onDelete: (principalId: string) => void;
  onEdit: (principalId: string) => void;
}

const MenuColumn: FunctionComponent<MenuColumnProps> = ({
  row,
  onDelete,
  onEdit,
}): JSX.Element => {
  const { principalId, email } = row;
  const { currentDealer } = useDealer();
  const api = useApiClient();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const { t } = useTranslation();
  const open = Boolean(anchorEl);
  const openMenu = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const [confirm, setConfirm] = useState(false);
  const [resetConfirm, setResetConfirm] = useState(false);
  const [confirmationSendInvite, setSendInviteConfirm] = useState(false);
  const [deleteState, setDeleteSate] = useAsyncState<None>();
  const [resetState, setResetSate] = useAsyncState<boolean>();
  const [inviteState, setInviteSate] = useAsyncState<None>();
  const { showMessage } = useMessageStore();

  const closeMenu = () => {
    setAnchorEl(null);
  };

  const handleEdit = () => {
    closeMenu();
    onEdit(principalId);
  };

  const handleDelete = () => {
    if (!currentDealer) {
      return;
    }

    setDeleteSate(async () => {
      const response = await api.user.removeAccessToDealer({
        dealerId: currentDealer.id,
        principalId: principalId,
        email: email,
      });

      onDelete(principalId);
      toggleConfirm();

      return response;
    });
  };

  const toggleConfirm = () => {
    setConfirm((state) => !state);
  };

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const toggleSendInviteConfirm = () => {
    setSendInviteConfirm((state) => !state);
  };

  const handleResetPassword = () => {
    if (!currentDealer) {
      return;
    }
    setResetSate(async () => {
      const result = await api.user.resetPassword({
        dealerId: currentDealer.id,
        email,
        principalId,
      });

      if (result) {
        showMessage({
          text: t('pages.users.resetPasswordSuccessfully'),
          severity: 'success',
          dismissible: true,
        });
      } else {
        showMessage({
          text: t('pages.users.resetPasswordError'),
          severity: 'error',
          dismissible: true,
        });
      }
      closeMenu();
      toggleResetConfirm();
      return result;
    });
  };

  const handleSendInvite = () => {
    setInviteSate(async () => {
      try {
        await api.user.sendInvite({
          principalId,
          dealerId: currentDealer?.id,
        });
        showMessage({
          text: t('pages.users.sendInviteSuccessfully'),
          severity: 'success',
          dismissible: true,
        });
      } catch (error) {
        if (
          HttpServiceTransportError.is(error, UserErrorCode.Auth0UserExists) ||
          HttpServiceTransportError.is(error, UserErrorCode.UserNotExists)
        ) {
          showMessage({
            text: t(error.code),
            severity: 'error',
            dismissible: true,
          });
        }
      } finally {
        closeMenu();
        toggleSendInviteConfirm();
      }
    });
  };

  return (
    <div>
      <IconButton onClick={openMenu}>
        <MoreVert />
      </IconButton>
      <Menu
        MenuListProps={{
          sx: {
            p: 1,
          },
        }}
        PaperProps={{
          sx: styles.menuPaper,
        }}
        anchorEl={anchorEl}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={closeMenu}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
      >
        <MenuItem dense onClick={handleEdit}>
          <ListItemIcon>
            <Edit fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('edit')}</ListItemText>
        </MenuItem>
        <MenuItem
          dense
          onClick={() => {
            closeMenu();
            toggleResetConfirm();
          }}
        >
          <ListItemIcon>
            <Key />
          </ListItemIcon>
          <ListItemText>{t('passwordReset')}</ListItemText>
        </MenuItem>
        {principalId === email && (
          <MenuItem
            dense
            onClick={() => {
              closeMenu();
              toggleSendInviteConfirm();
            }}
          >
            <ListItemIcon>
              <Send />
            </ListItemIcon>
            <ListItemText>{t('sendInvite')}</ListItemText>
          </MenuItem>
        )}
        <MenuItem
          dense
          onClick={() => {
            closeMenu();
            toggleConfirm();
          }}
          sx={{
            color: 'error.main',
          }}
        >
          <ListItemIcon>
            <Delete color="error" fontSize="small" />
          </ListItemIcon>
          <ListItemText>{t('delete')}</ListItemText>
        </MenuItem>
      </Menu>
      <ConfirmModal
        busy={deleteState.loading}
        cancelText={t('cancel')}
        confirmColor="error"
        confirmText={t('delete')}
        loadingIndicator={`${t('removing')}...`}
        onClose={toggleConfirm}
        onConfirm={handleDelete}
        open={confirm}
        title={t('pages.users.delete')}
      >
        <Stack spacing={1}>
          <>
            <Typography variant="body1">
              {t('pages.users.confirmationDelete')}
            </Typography>
            {deleteState.error && <ErrorState />}
          </>
        </Stack>
      </ConfirmModal>
      <ConfirmModal
        busy={resetState.loading}
        cancelText={t('cancel')}
        confirmText={t('pages.users.sendResetLink')}
        loadingIndicator={`${t('sending')}...`}
        onClose={toggleResetConfirm}
        onConfirm={handleResetPassword}
        open={resetConfirm}
        title={t('pages.users.resetPassword')}
      >
        <Stack spacing={1}>
          <>
            <Typography variant="body1">
              {t('pages.users.confirmationResetPassword', { value: email })}
            </Typography>
            {resetState.error && <ErrorState />}
          </>
        </Stack>
      </ConfirmModal>
      <ConfirmModal
        busy={inviteState.loading}
        cancelText={t('cancel')}
        confirmText={t('pages.users.sendInvite')}
        loadingIndicator={`${t('sending')}...`}
        onClose={toggleSendInviteConfirm}
        onConfirm={handleSendInvite}
        open={confirmationSendInvite}
        title={t('pages.users.invite')}
      >
        <Stack spacing={1}>
          <>
            <Typography variant="body1">
              {t('pages.users.confirmationSendInvite', { value: email })}
            </Typography>
          </>
        </Stack>
      </ConfirmModal>
    </div>
  );
};

export default MenuColumn;
