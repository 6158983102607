import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { AdminListRemarketingActionsRequest } from '../../../../api/RemarketingService/adminListRemarketingActions/AdminListRemarketingActionsRequest.js';
import { AdminListRemarketingActionsResponse } from '../../../../api/RemarketingService/adminListRemarketingActions/AdminListRemarketingActionsResponse.js';
import {
  OfferActionStatus,
  RemarketingAction,
} from '../../../../api/util/RemarketingAction.js';
import { RemarketingActionType } from '../../../../api/util/RemarketingVehicle.js';
import {
  WsChannelType,
  WsRemarketingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { QueryKeys } from './QueryKeys.js';

export const makeListAdminOffersQueryKey = (
  req?: AdminListRemarketingActionsRequest,
): QueryKey => {
  return !req
    ? []
    : [QueryKeys.RemarketingOfferAdmin, req.brand, req.remarketingVehicleId];
};

export type ListAdminOfferQueryData =
  InfiniteData<AdminListRemarketingActionsResponse>;

export const useListAdminOffers = (
  req?: AdminListRemarketingActionsRequest,
): UseInfiniteQueryResult<ListAdminOfferQueryData> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const newOfferIncoming = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.newOffer,
    WsChannelType.RemarketingAdmin,
  );

  const buyNow = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.buyNow,
    WsChannelType.RemarketingAdmin,
  );

  const offerRejected = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.offerRejected,
    WsChannelType.RemarketingAdmin,
  );

  useEffect(() => {
    if (
      newOfferIncoming &&
      newOfferIncoming.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListAdminOfferQueryData>(
        { queryKey: makeListAdminOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            const [firstPage, ...pages] = prev.pages;
            firstPage.items.unshift(newOfferIncoming);

            return {
              ...prev,
              pages: [firstPage, ...pages],
            };
          }
        },
      );
    }
  }, [newOfferIncoming]);

  useEffect(() => {
    if (
      !!buyNow &&
      buyNow?.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListAdminOfferQueryData>(
        { queryKey: makeListAdminOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.map((offer) => {
                  if (
                    offer.actionType !== RemarketingActionType.OFFER ||
                    offer.id === buyNow.id
                  ) {
                    return offer;
                  }

                  if (!offer.status) {
                    return {
                      ...offer,
                      status: OfferActionStatus.REJECTED,
                    };
                  }

                  return offer;
                }),
              })),
            };
          }
        },
      );
    }
  }, [buyNow]);

  useEffect(() => {
    if (
      !!offerRejected &&
      offerRejected?.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListAdminOfferQueryData>(
        { queryKey: makeListAdminOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.map((offer) => {
                  if (
                    offer.id === offerRejected.id &&
                    offer.actionType === RemarketingActionType.OFFER
                  ) {
                    return {
                      ...offer,
                      ...offerRejected,
                    };
                  }

                  return offer;
                }),
              })),
            };
          }
        },
      );
    }
  }, [offerRejected]);

  return useInfiniteQuery({
    queryKey: makeListAdminOffersQueryKey(req),
    queryFn: async ({ pageParam }) => {
      if (!req) {
        return;
      }
      return await api.remarketing.adminListRemarketingActions({
        ...req,
        nextToken: pageParam,
      });
    },
    getNextPageParam: (lastPage) => lastPage?.nextToken,
    initialPageParam: undefined as string | undefined,
    enabled: !!req,
  });
};
