import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { DeleteMediaRequest } from '../../../api/MediaService/deleteMedia/DeleteMediaRequest.js';
import { None } from '../../../api/util/none.js';
import { useMessageStore } from '../../common-ui/index.js';
import { useApiClient } from '../useApiClient.js';

export const useDeleteMedia = (): UseMutationResult<
  None,
  unknown,
  DeleteMediaRequest
> => {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (req) => {
      return await api.media.deleteMedia(req);
    },
    onSuccess: () => {
      showMessage({
        text: t('successMessage'),
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });
    },
    onError: () => {
      showMessage({
        severity: 'error',
        dismissible: true,
        text: t(`errorOccurredMessage`),
      });
    },
  });
};
