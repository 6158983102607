import { ApiDef } from './ApiDef.js';
import { CredentialProvider } from './util/CredentialProvider.js';
import { HttpClient, HttpClientOptions } from './util/HttpClient.js';
import { HttpServiceTransport } from './util/HttpServiceTransport.js';
import { makeServiceClient } from './util/makeServiceClient.js';
import { ApiServiceTypeOf } from './util/ServiceDef.js';

export type ApiClient = ApiServiceTypeOf<ApiDef>;

export interface ApiClientConfig {
  credentials?: CredentialProvider;
  endpoint: string;
  httpClient?: HttpClient;
  httpClientOptions?: HttpClientOptions;
}

export function makeApiClient(cfg: ApiClientConfig): ApiClient {
  const transport = new HttpServiceTransport({
    credentials: cfg.credentials,
    endpoint: cfg.endpoint,
    httpClient: cfg.httpClient,
    httpClientOptions: cfg.httpClientOptions,
  });

  return Object.fromEntries(
    Object.entries(ApiDef).map(([service, def]) => [
      service,
      makeServiceClient(service, def, transport),
    ]),
  ) as ApiClient;
}
