import { StyleSheet, Text, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import HeaderLogo from './HeaderLogo.js';

interface OptionsHeaderProps {
  brand: AvailableBrands;
  logo: string;
  name: string;
  modelYear?: string;
}

const styles = StyleSheet.create({
  header: {
    height: 60,
    flexDirection: 'row',
    justifyContent: 'space-between',
    alignItems: 'flex-end',
    marginBottom: 16,
  },
  headerImage: {
    height: 20,
    width: 85,
  },
  name: {
    fontSize: 22,
    fontWeight: 'bold',
  },
  modelYear: {
    fontSize: 16,
    fontWeight: 'bold',
  },
  modelName: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'flex-start',
  },
  logo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brand: {
    textAlign: 'center',
    fontSize: 16,
  },
});

const OptionsHeader: FunctionComponent<OptionsHeaderProps> = ({
  brand,
  modelYear,
  name,
  logo,
}) => {
  return (
    <View style={styles.header}>
      <View style={styles.modelName}>
        <Text style={styles.name}>{name}</Text>
        <Text style={styles.modelYear}>{`${modelYear || '-'} Model Year`}</Text>
      </View>
      <HeaderLogo brand={brand} logo={logo} />
    </View>
  );
};

export default OptionsHeader;
