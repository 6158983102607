import { Decoder, predicate } from '@fmtk/decoders';

/**
 * Error identifier returned when [[hasMaxLength]] fails.
 */
export const ExpectedArrayMaxLength = 'ARRAY_MAX_LENGTH';

/**
 * Creates a predicate which requires a string to be less than the given max
 * length.
 *
 * @param max The maximum length allowed for the input value.
 */
export function hasMaxLength<T>(max: number): Decoder<Array<T>, Array<T>> {
  return predicate(
    (value) => value.length <= max,
    `must be ${max} items or less`,
    ExpectedArrayMaxLength,
    { maxLength: max },
  );
}
