import {
  Decoder,
  enumValue,
  maybe,
  number,
  object,
  string,
  unknown,
} from '@fmtk/decoders';

export const ExternalServiceName = 'ExternalService';

export enum ExternalErrorCodes {
  'DealerNotFound' = 'DEALER_NOT_FOUND',
  'VinNotFound' = 'VIN_NOT_FOUND',
}

export interface ExternalApiResponse<T> {
  data: T;
  rowCount?: number;
  error?: {
    code: ExternalErrorCodes;
    message: string;
  };
}

export function decodeExternalApiResponse<Payload>(
  payload = unknown as Decoder<Payload>,
): Decoder<ExternalApiResponse<Payload>> {
  return object({
    data: payload,
    rowCount: maybe(number),
    error: maybe(
      object({
        code: enumValue(ExternalErrorCodes),
        message: string,
      }),
    ),
  });
}
