export function downloadFile(
  blob: Blob,
  filename: string,
  extension: string,
): void {
  const fullName = `${filename}.${extension}`;
  const link = document.createElement('a');
  if (link.download !== undefined) {
    // feature detection
    // Browsers that support HTML5 download attribute
    const url = URL.createObjectURL(blob);
    link.setAttribute('href', url);
    link.setAttribute('download', fullName);
    link.style.visibility = 'hidden';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  }
}

export function downloadAsCsv(csvContent: string, filename: string): void {
  const blob = new Blob([`\uFEFF${csvContent}`], {
    type: 'text/csv;charset=utf-8;',
  });
  downloadFile(blob, filename, 'csv');
}

export function downloadAsImg(
  imgSrc: string,
  filename: string,
  extension?: string,
): void {
  const img = new Image();
  img.crossOrigin = 'anonymous';

  img.onload = () => {
    const canvas = document.createElement('canvas');
    const context = canvas.getContext('2d');
    canvas.width = img.width;
    canvas.height = img.height;
    if (context) {
      context.drawImage(img, 0, 0, img.width, img.height);
      canvas.toBlob((blob) => {
        if (blob) {
          downloadFile(blob, filename, extension || 'png');
        }
      });
    }
  };

  img.onerror = (error) => {
    console.error('Error loading image:', error);
  };

  img.src = imgSrc;
}

export async function downloadFromUrl(
  fileUrl: string,
  filename: string,
  extension: string,
): Promise<void> {
  try {
    const response = await fetch(fileUrl, { mode: 'cors' });
    if (!response.ok) {
      throw new Error(`Error fetching file: ${response.statusText}`);
    }
    const blob = await response.blob();
    downloadFile(blob, filename, extension);
  } catch (error) {
    console.error('Error downloading file:', error);
  }
}
