import { Search } from '@mui/icons-material';
import {
  Box,
  CircularProgress,
  InputLabel,
  Link,
  TextField,
  TextFieldProps,
} from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { sx } from '../../../util/sx.js';

export type SearchInputProps = TextFieldProps & {
  loading?: boolean;
  label?: string;
  onResetClick?: () => void;
  resetText?: string;
};

const styles = sx({
  searchIcon: {
    mr: 1,
    color: '#44546F',
    height: 22,
    width: 22,
  },
  progress: {
    mr: 1,
  },
});

const SearchInput: FunctionComponent<SearchInputProps> = ({
  onChange,
  onKeyDown,
  value,
  loading,
  error,
  label,
  InputProps,
  onResetClick,
  resetText,
  ...props
}) => {
  const { t } = useTranslation();
  const [searchValue, setSearchValue] = useState<unknown>('');
  const handleReset = useCallback(() => {
    setSearchValue('');
    onResetClick && onResetClick();
  }, [onResetClick]);

  useEffect(() => {
    setSearchValue(value);
  }, [value]);

  return (
    <>
      <InputLabel htmlFor="search-input">{label}</InputLabel>
      <TextField
        InputProps={{
          autoFocus: true,
          startAdornment: <Search sx={styles.searchIcon} />,
          endAdornment: (
            <Box display="flex">
              {loading ? (
                <CircularProgress size={20} sx={styles.progress} />
              ) : (
                <>
                  {searchValue && (
                    <Link
                      color="primary"
                      fontSize="small"
                      onClick={handleReset}
                      role="button"
                      underline="always"
                    >
                      {resetText || t('clear')}
                    </Link>
                  )}
                </>
              )}
            </Box>
          ),
          autoComplete: 'off',
          ...InputProps,
        }}
        error={error}
        fullWidth
        id="search-input"
        onChange={onChange}
        onKeyDown={onKeyDown}
        value={searchValue}
        {...props}
      />
    </>
  );
};

export default SearchInput;
