import { InfoOutlined } from '@mui/icons-material';
import { Stack, Tooltip } from '@mui/material';
import { SxProps } from '@mui/system';
import { uniqueId } from 'lodash-es';
import React, { useMemo } from 'react';
import { useConst } from '../../hooks/index.js';
import { useForm } from '../context/FormContext.js';
import { hasError } from './FieldError.js';
import { FormFieldError } from './FormFieldError.js';
import { FormFieldLabel } from './FormFieldLabel.js';
import { TextInput, TextInputProps } from './TextInput.js';

export interface FormTextFieldProps
  extends Omit<TextInputProps, 'label' | 'onValueChange' | 'value'> {
  condensed?: boolean;
  fullWidth?: boolean;
  hideErrorMessage?: boolean;
  inputRef?: React.Ref<HTMLInputElement>;
  name: string;
  label?: string;
  sx?: SxProps;
  placeholder?: string;
  helperText?: string;
  noLabel?: boolean;
}

export function FormTextField({
  condensed,
  error,
  fullWidth,
  hideErrorMessage,
  inputRef,
  name,
  label,
  readOnly,
  noLabel,
  placeholder,
  helperText,
  sx,
  ...inputProps
}: FormTextFieldProps): JSX.Element {
  const ref = useConst(() => uniqueId('id'));
  const [{ busy, errors, values }, formBind] = useForm();
  const rootStyles = useMemo(
    () => ({
      mb: condensed ? 1 : undefined,
      width: fullWidth ? '100%' : 'auto',
      ...sx,
    }),
    [condensed, fullWidth, sx],
  );

  return (
    <Stack direction="column" sx={rootStyles}>
      {!noLabel && (
        <Stack alignItems="center" direction="row" pb={0.5} spacing={0.5}>
          <FormFieldLabel htmlFor={ref} name={name} text={label || name} />
          {helperText && (
            <Tooltip arrow title={helperText}>
              <InfoOutlined
                sx={{
                  fontSize: 16,
                }}
              />
            </Tooltip>
          )}
        </Stack>
      )}
      <TextInput
        id={ref}
        ref={inputRef}
        {...inputProps}
        error={error || hasError(errors[name])}
        onValueChange={formBind.handleChange(name)}
        placeholder={placeholder}
        readOnly={readOnly ?? busy}
        value={values[name]}
      />
      {!hideErrorMessage && <FormFieldError name={name} />}
    </Stack>
  );
}
