export enum TranslationLocale {
  cs_CZ = 'cs_CZ',
  de_AT = 'de_AT',
  de_CH = 'de_CH',
  de_DE = 'de_DE',
  en_GB = 'en_GB',
  en_US = 'en_US',
  es_ES = 'es_ES',
  fr_BE = 'fr_BE',
  fr_CA = 'fr_CA',
  fr_CH = 'fr_CH',
  fr_FR = 'fr_FR',
  it_IT = 'it_IT',
  ja_JP = 'ja_JP',
  ko_KR = 'ko_KR',
  nl_BE = 'nl_BE',
  nl_NL = 'nl_NL',
  pl_PL = 'pl_PL',
  ru_RU = 'ru_RU',
  sk_SK = 'sk_SK',
  zh_CN = 'zh_CN',
}
