import { enumValue, object } from '@fmtk/decoders';
import { decodeUser, User } from '../../util/User.js';

export enum RegisterUserCode {
  AlreadyExists = 'ALREADY_EXISTS',
  Imported = 'IMPORTED',
  New = 'NEW',
  AlreadyExistsInDealer = 'ALREADY_EXITS_IN_DEALER',
}

export type RegisterUserResponse = {
  user: User;
  code: RegisterUserCode;
};

export const decodeRegisterUserResponse = object<RegisterUserResponse>({
  user: decodeUser,
  code: enumValue(RegisterUserCode),
});
