import {
  choose,
  enumValue,
  is,
  jsonDate,
  maybe,
  object,
  text,
} from '@fmtk/decoders';
import { VehicleListing, decodeVehicleListing } from '../../util/Vehicle.js';

export enum TransferResponseCode {
  Transferred = 'TRANSFERRED',
  AlreadyExistsInDealer = 'ALREADY_EXISTS_IN_DEALER',
  AlreadyExistsInDifferentDealer = 'ALREADY_EXISTS_IN_DIFFERENT_DEALER',
}

export type ResponseDataBase = {
  code: TransferResponseCode;
};

export interface TransferredData extends ResponseDataBase {
  code: TransferResponseCode.Transferred;
}
export interface AlreadyInDealerData extends ResponseDataBase {
  code: TransferResponseCode.AlreadyExistsInDealer;
}
export interface AlreadyInDifferentDealerData extends ResponseDataBase {
  code: TransferResponseCode.AlreadyExistsInDifferentDealer;
  dealerName?: string;
}

export type ResponseData =
  | TransferredData
  | AlreadyInDealerData
  | AlreadyInDifferentDealerData;

export type AdminTransferRemarketingVehicleResponse = {
  vehicleListing?: VehicleListing;
  responseData: ResponseData;
};

const decodeResponseDataBase = {
  code: enumValue(TransferResponseCode),
};

const decodeTransferredData = object<TransferredData>({
  ...decodeResponseDataBase,
  code: is(TransferResponseCode.Transferred),
});
const decodeAlreadyInDealerData = object<AlreadyInDealerData>({
  ...decodeResponseDataBase,
  code: is(TransferResponseCode.AlreadyExistsInDealer),
});
const decodeAlreadyInDifferentDealerData = object<AlreadyInDifferentDealerData>(
  {
    ...decodeResponseDataBase,
    code: is(TransferResponseCode.AlreadyExistsInDifferentDealer),
    dealerName: maybe(text),
  },
);

export const decodeResponseData = choose(
  decodeTransferredData,
  decodeAlreadyInDealerData,
  decodeAlreadyInDifferentDealerData,
);

export const decodeAdminTransferRemarketingVehicleResponse =
  object<AdminTransferRemarketingVehicleResponse>({
    vehicleListing: maybe(decodeVehicleListing(jsonDate)),
    responseData: decodeResponseData,
  });
