import {
  Button,
  Grid,
  Stack,
  Tab,
  Tabs,
  Typography,
  tabsClasses,
} from '@mui/material';
import { Box } from '@mui/system';
import { useSetAtom } from 'jotai';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useNavigate, useSearchParams } from 'react-router-dom';
import { ArchiveOptions } from '../../../api/util/ArchiveOptions.js';
import { RemarketingVehicleStatus } from '../../../api/util/RemarketingVehicle.js';

import { useInView } from 'react-intersection-observer';
import { RemarketingVehicleSort } from '../../../api/RemarketingService/common.js';
import SummaryCard from '../../common-ui/components/SummaryCard.js';
import { PageContainer } from '../../common-ui/index.js';
import { useDebounce, useMobile } from '../../hooks/index.js';
import { sx } from '../../util/sx.js';
import Section from '../components/Section.js';
import RemarketingVehicleTable, {
  DefaultSort,
  Sort,
} from '../components/VehicleTable/RemarketingVehicleTable.js';
import { useCountRemarketingVehiclesForAdmin } from '../hooks/queries/useCountRemarketingVehiclesForAdmin.js';
import { useListRemarketingVehiclesForAdmin } from '../hooks/queries/useListRemarketingVehiclesForAdmin.js';
import { useRemarketingEditor } from '../hooks/useRemarketingEditor.js';
import { remarketingFiltersAtom } from '../state/atoms.js';

enum TabOptions {
  Live = 'Live',
  Archive = 'Archive',
}

const styles = sx({
  scrollObserver: {
    position: 'absolute',
    bottom: 0,
    paddingTop: '100vh', // distance from the bottom of the list to start fetching more data
    zIndex: -1,
  },
});

const RemarketingDashboard: FunctionComponent<{
  archivedMode?: boolean;
}> = ({ archivedMode }) => {
  const { t } = useTranslation();

  const [tileSelected, setTileSelected] = useState<RemarketingVehicleStatus>();
  const [searchTerm, setSearchTerm] = useState('');
  const [sort, setSort] = useState<Sort>(DefaultSort);
  const debouncedSearchTerm = useDebounce(searchTerm, 800);
  const mobile = useMobile();
  const [currentTab, setCurrentTab] = useState<TabOptions>(TabOptions.Live);
  const navigate = useNavigate();
  const [searchParams, setSearchParams] = useSearchParams();
  const setQueryFilters = useSetAtom(remarketingFiltersAtom);

  const isArchivedView = currentTab === TabOptions.Archive;

  const isEditor = useRemarketingEditor();

  const { data: counts, isLoading: loadingCounts } =
    useCountRemarketingVehiclesForAdmin();

  const {
    data,
    isError,
    hasNextPage,
    fetchNextPage,
    isFetchingNextPage,
    isLoading,
  } = useListRemarketingVehiclesForAdmin({
    order: sort.order,
    searchTerm: debouncedSearchTerm,
    sort: sort.sort,
    archiveOption: isArchivedView ? ArchiveOptions.onlyArchived : undefined,
    status: isArchivedView ? undefined : tileSelected,
  });

  const { ref, inView } = useInView({
    threshold: 0,
  });

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage, inView]);

  const resetFilters = () => {
    setTileSelected(RemarketingVehicleStatus.ACTIVE);
    setSort(DefaultSort);
    setSearchTerm('');
  };

  const handleChange = (_: React.SyntheticEvent, newValue: TabOptions) => {
    setCurrentTab(newValue);
    let path = '../';

    if (newValue === TabOptions.Archive) {
      path = './archived';
    }

    navigate(path, {
      relative: 'path',
    });

    resetFilters();
  };

  useEffect(() => {
    if (archivedMode) {
      setCurrentTab(TabOptions.Archive);
    } else {
      setCurrentTab(TabOptions.Live);
    }
  }, [archivedMode]);

  useEffect(() => {
    setQueryFilters((state) => ({ ...state, searchParams: searchParams }));
  }, [searchParams, setQueryFilters]);

  useEffect(() => {
    const q = searchParams.get('q');
    const o = searchParams.get('o');
    const s = searchParams.get('s');
    const t = searchParams.get('t');

    const tile = Object.values(RemarketingVehicleStatus).includes(
      t as RemarketingVehicleStatus,
    )
      ? (t as RemarketingVehicleStatus)
      : RemarketingVehicleStatus.ACTIVE;

    setTileSelected(tile);

    setSort({
      order:
        o && ['asc', 'desc'].includes(o)
          ? (searchParams.get('o') as 'asc' | 'desc')
          : DefaultSort.order,
      sort:
        s &&
        Object.values(RemarketingVehicleSort).includes(
          s as RemarketingVehicleSort,
        )
          ? (s as RemarketingVehicleSort)
          : DefaultSort.sort,
    });

    setSearchTerm(q ?? '');

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const vehicles = data?.pages.flatMap((p) => p.items);
  const totalResults = data?.pages[0]?.count;

  const handleInputChange = (value: string) => {
    setSearchTerm(value);
    setSearchParams((state) => {
      const newState = new URLSearchParams(state);
      newState.set('q', value);
      return newState;
    });
  };

  const handleSortOptionChange = useCallback(
    (options: Sort) => {
      setSort(options);
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        newState.set('o', options.order);
        newState.set('s', options.sort);

        return newState;
      });
    },
    [setSearchParams],
  );

  const handleTileChange = useCallback(
    (tile: RemarketingVehicleStatus) => {
      setTileSelected(tile as RemarketingVehicleStatus);
      setSearchParams((state) => {
        const newState = new URLSearchParams(state);
        newState.set('t', tile);

        return newState;
      });
    },
    [setSearchParams],
  );

  return (
    <PageContainer>
      <Section
        title={
          <Stack direction="row" justifyContent="space-between">
            <Typography variant="h4">
              {t('pages.remarketingAdmin.title')}
            </Typography>
            <div>
              {isEditor && (
                <Button
                  component={Link}
                  size="small"
                  to={archivedMode ? '../add' : 'add'}
                  variant="contained"
                >
                  {t('addVehicle')}
                </Button>
              )}
            </div>
          </Stack>
        }
      >
        <Stack spacing={2}>
          <div>
            <Tabs
              onChange={handleChange}
              scrollButtons
              sx={{
                [`& .${tabsClasses.scrollButtons}`]: {
                  '&.Mui-disabled': { opacity: 0.3 },
                },
              }}
              value={currentTab}
              variant={mobile ? 'scrollable' : undefined}
            >
              <Tab
                id="live-tab"
                label={<Typography variant="subtitle1">{t('live')}</Typography>}
                value={TabOptions.Live}
              />
              <Tab
                id="archive-tab"
                label={
                  <Typography variant="subtitle1">{t('archived')}</Typography>
                }
                value={TabOptions.Archive}
              />
            </Tabs>
          </div>
          {currentTab === TabOptions.Live && (
            <div>
              <Grid container spacing={{ xs: 0, sm: 3 }}>
                {Object.keys(RemarketingVehicleStatus).map((tile) => {
                  const selected = tileSelected === tile;
                  const count = counts?.[tile as RemarketingVehicleStatus] ?? 0;
                  return (
                    <Grid item key={tile} lg={2} md={4} sm={6} xs={12}>
                      <SummaryCard
                        label={t(`remarketingVehicleStatus.${tile}`)}
                        linkText={t('view')}
                        loading={loadingCounts}
                        onClick={() =>
                          handleTileChange(tile as RemarketingVehicleStatus)
                        }
                        selected={selected}
                        value={count}
                      />
                    </Grid>
                  );
                })}
              </Grid>
            </div>
          )}
        </Stack>
        <Box my={2} position="relative">
          <RemarketingVehicleTable
            count={totalResults ?? 0}
            error={isError}
            inputSearchValue={searchTerm}
            isAdminView
            loading={isLoading}
            onSearchTermChange={handleInputChange}
            onSortChange={handleSortOptionChange}
            sortOptions={sort}
            vehicles={vehicles || []}
          />
          <Box ref={ref} sx={styles.scrollObserver} />
        </Box>
      </Section>
    </PageContainer>
  );
};

export default RemarketingDashboard;
