import { enumValue, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface ListVehicleFeaturesRequest {
  brand: AvailableBrands;
  vehicleId: string;
  dealer: string;
}

export const decodeListVehicleFeaturesRequest =
  object<ListVehicleFeaturesRequest>({
    brand: enumValue(AvailableBrands),
    vehicleId: string,
    dealer: string,
  });
