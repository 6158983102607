import { enumValue, maybe, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface AdminListBiddingHistoryRequest {
  brand: AvailableBrands;
  remarketingVehicleId: string;
  nextToken?: string;
}

export const decodeAdminListBiddingHistoryRequest =
  object<AdminListBiddingHistoryRequest>({
    brand: enumValue(AvailableBrands),
    nextToken: maybe(text),
    remarketingVehicleId: text,
  });
