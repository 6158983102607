import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import { addVehicleStepperAtom } from '../../../state/atoms.js';
import VehicleFeaturesEditor from '../../Vehicle/VehicleFeaturesEditor.js';

const FeaturesStep: FunctionComponent = () => {
  const navigate = useNavigate();
  const [addState, setAddSate] = useAtom(addVehicleStepperAtom);
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    navigate('../details');
  }, [navigate]);

  const handleChange = useCallback(
    (features?: VehicleFeatures) => {
      setAddSate((state) => {
        if (!state.currentListing || !state.currentListing.dealer.id) {
          return state;
        }
        return {
          ...state,
          currentListing: {
            ...state.currentListing,
            vehicle: {
              ...state.currentListing.vehicle,
              features: features,
            },
          },
        };
      });
    },
    [setAddSate],
  );

  const options = useMemo(() => {
    return {
      buttons: {
        containerStyle: {
          justifyContent: 'space-between',
          bgcolor: 'background.paper',
          borderTop: '1px solid #C4CDD5',
          bottom: 0,
          padding: 1,
          position: 'sticky',
        },

        cancelButtonProps: {
          label: t('additionalDetails'),
          startIcon: <NavigateBefore />,
        },
        acceptButtonProps: {
          label: t('media'),
          endIcon: <NavigateNext />,
        },
      },
    };
  }, [t]);

  useEffect(() => {
    if (!addState.currentListing) {
      //clear state and go to step 1
      setAddSate(RESET);
      navigate('..', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, addState.currentListing]);

  return (
    <Box>
      <VehicleFeaturesEditor
        features={addState.currentListing?.vehicle.features}
        onCancel={handleCancel}
        onChange={handleChange}
        onSave={() => {
          navigate(`../media`);
          return;
        }}
        options={options}
      />
    </Box>
  );
};

export default FeaturesStep;
