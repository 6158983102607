import { Card, Link, Skeleton, Typography } from '@mui/material';
import { Box, Stack, SxProps } from '@mui/system';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { UserDocumentSource } from '../../../../api/util/UserDocument.js';
import { useMobile } from '../../../hooks/useMobile.js';
import {
  userDocumentSelectionStatusColours,
  userDocumentSourceColours,
} from '../../../util/colors.js';
import { Label } from '../../index.js';
import { formatBytes } from '../../util/fileSize.js';
import { UserDocumentItem } from './CreateUserDocumentModal.js';

const styles: Record<string, SxProps> = {
  documentTitle: {
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
    overflowWrap: 'anywhere',
    marginRight: 2,
  },
  root: {
    backgroundColor: 'background.default',
    p: 0,
    border: 1,
    borderColor: 'divider',
  },
};

export interface DocumentRowProps {
  id?: string;
  createdAt?: number;
  document: UserDocumentItem;
  busy?: boolean;
  onClick?: () => void;
  onToggleSelect?: () => void;
  onDelete?: () => void;
  readonly?: boolean;
  selected?: boolean;
}

export const DocumentSkeletonRow = () => (
  <Card sx={styles.root}>
    <Box px={2} py={1}>
      <Skeleton height={48} width="100%" />
    </Box>
  </Card>
);

export const DocumentRow: FunctionComponent<DocumentRowProps> = ({
  document,
  onDelete,
  onClick,
  onToggleSelect,
  busy,
  id,
  readonly,
  selected,
}) => {
  const { t } = useTranslation();
  const mobile = useMobile();

  return (
    <Card sx={styles.root}>
      <Stack
        alignContent="center"
        alignItems={mobile ? 'flex-start' : 'center'}
        direction="row"
        justifyContent="space-between"
        px={2}
        py={1}
      >
        <Stack
          direction={mobile ? 'column' : 'row'}
          flex={1}
          justifyContent="space-between"
        >
          <Stack spacing={0.5}>
            <Link
              color="primary"
              onClick={onClick}
              underline="hover"
              variant="button"
            >
              <Typography
                sx={styles.documentTitle}
                variant="body2"
              >{`${document.name}`}</Typography>
            </Link>
            <Stack
              alignItems={mobile ? 'flex-start' : 'center'}
              direction={mobile ? 'column' : 'row'}
              flex={1}
              spacing={1}
            >
              <Stack alignItems="center" direction="row" spacing={1}>
                <Label
                  bgcolor={
                    userDocumentSourceColours[document.source].background
                  }
                >
                  <Typography
                    color={userDocumentSourceColours[document.source].color}
                    fontWeight="fontWeightBold"
                    textTransform="uppercase"
                    variant="caption"
                  >
                    {document.source === UserDocumentSource.Link
                      ? t('documents.link')
                      : t(`contentType.${document.contentType}`)}
                  </Typography>
                </Label>

                {document.source === UserDocumentSource.S3 && (
                  <Typography variant="caption">
                    {formatBytes(document.size || 0)}
                  </Typography>
                )}
              </Stack>
              <Stack direction="row" spacing={1}>
                {!id && (
                  <Typography color="info.main" variant="caption">
                    {t('documents.pending')}
                  </Typography>
                )}
              </Stack>
            </Stack>
          </Stack>
          {selected && (
            <Stack
              alignItems="center"
              direction="row"
              spacing={1}
              marginRight={2}
            >
              <Label
                bgcolor={userDocumentSelectionStatusColours.selected.background}
              >
                <Typography
                  color={userDocumentSelectionStatusColours.selected.color}
                  fontWeight="fontWeightBold"
                  textTransform="uppercase"
                  variant="caption"
                >
                  {t('documents.selected')}
                </Typography>
              </Label>
            </Stack>
          )}
        </Stack>
        {!readonly && onDelete && (
          <Link
            color="secondary"
            component="button"
            disabled={busy}
            onClick={(e) => {
              e.preventDefault();
              onDelete();
            }}
            variant="button"
          >
            {busy ? t('documents.deleting') : t('action.delete')}
          </Link>
        )}
        {onToggleSelect && (
          <Link
            color="secondary"
            component="button"
            onClick={(e) => {
              e.preventDefault();
              onToggleSelect();
            }}
            variant="button"
          >
            {selected ? t('documents.deselect') : t('documents.select')}
          </Link>
        )}
      </Stack>
    </Card>
  );
};
