import type {} from '@mui/lab/themeAugmentation';
import { createTheme, Theme } from '@mui/material';
import { deepmerge } from '@mui/utils';
import { AvailableBrands } from '../../../api/util/index.js';
import astonMartinTheme from './astonMartinTheme.js';
import defaultTheme from './default.js';

export const makeTheme = (brand?: AvailableBrands): Theme => {
  let theme;
  switch (brand) {
    case AvailableBrands.AstonMartin:
      theme = createTheme(deepmerge(defaultTheme, astonMartinTheme));
      break;

    default:
      theme = createTheme(defaultTheme);
  }

  return theme;
};
