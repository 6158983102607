import { AccountBox } from '@mui/icons-material';
import { Box, Grid, Link, Slider, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { t } from 'i18next';
import React, {
  ReactNode,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import AvatarEditor, { AvatarEditorProps } from 'react-avatar-editor';
import { v4 as uuid } from 'uuid';
import { sx } from '../../util/sx.js';

const IMG_MAX_SIZE = 1024 * 1024 * 5;

export interface AvatarPickerProps {
  onSelectFile: (file?: string) => void;
  avatarFallbackComponent?: ReactNode;
  disabled?: boolean;
  source?: string;
  avatarOptions?: Pick<
    AvatarEditorProps,
    'width' | 'height' | 'border' | 'borderRadius' | 'color'
  >;
}

const styles = sx({
  avatarImg: { borderRadius: 5 },
  noAvatar: {
    fontSize: 140,
    color: 'grey.20',
  },
});

const Input = styled('input')(() => ({
  display: 'none',
}));

export function AvatarPicker({
  onSelectFile,
  avatarOptions,
  source,
  avatarFallbackComponent,
}: AvatarPickerProps) {
  const [scale, setScale] = useState(1.2);
  const [isEditing, setIsEditing] = useState(false);
  const ref = useRef<AvatarEditor>(null);
  const inputId = uuid();
  const [file, setFile] = useState<File | undefined>();
  const [fileUrl, setFileUrl] = useState<string | null>();

  const handleScaleChange = (_: Event, value: number | number[]) => {
    setScale(value as number);
  };

  const handleAccept = useCallback(() => {
    if (!ref.current) {
      return;
    }
    const canvasScaled = ref.current.getImageScaledToCanvas();

    setFileUrl(canvasScaled.toDataURL());
    onSelectFile(canvasScaled.toDataURL());
    setIsEditing(false);
  }, [onSelectFile]);

  const handleCancel = useCallback(() => {
    setFile(undefined);
    setIsEditing(false);
  }, []);

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const inputFile = event.target.files?.item(0) || undefined;
    if (inputFile && inputFile?.size > IMG_MAX_SIZE) {
      return;
    }

    setFile(inputFile);
    setIsEditing(true);
  };

  useEffect(() => {
    setFileUrl(source);
  }, [source]);

  return (
    <Stack alignItems={isEditing ? 'center' : 'inherit'}>
      {!isEditing && fileUrl ? (
        <img
          alt="img-avatar"
          height={avatarOptions?.height}
          src={fileUrl}
          style={styles.avatarImg}
          width={avatarOptions?.width}
        />
      ) : (
        <>
          {!file ? (
            avatarFallbackComponent ? (
              avatarFallbackComponent
            ) : (
              <AccountBox sx={styles.noAvatar} />
            )
          ) : (
            <>
              <AvatarEditor
                crossOrigin="anonymous"
                disableBoundaryChecks={false}
                image={file}
                ref={ref}
                scale={scale}
                {...avatarOptions}
              />
              <Box mt={0.5} width="100%">
                <Stack
                  alignItems="center"
                  direction="row"
                  spacing={2}
                  sx={{ mb: 1 }}
                >
                  <Typography fontSize="small">Scale</Typography>
                  <Slider
                    aria-labelledby="continuous-slider"
                    max={2}
                    min={1}
                    onChange={handleScaleChange}
                    size="small"
                    step={0.1}
                    value={scale}
                  />
                </Stack>
                <Stack flexDirection="row" justifyContent="space-evenly">
                  <Link color="grey" onClick={handleCancel}>
                    Cancel
                  </Link>
                  <Link color="grey" onClick={handleAccept}>
                    Done
                  </Link>
                </Stack>
              </Box>
            </>
          )}
        </>
      )}
      {(!isEditing || !file) && (
        <Grid container mt={0.5}>
          <Input
            accept="image/png, image/jpeg"
            id={inputId}
            onChange={handleChange}
            type="file"
          />
          <label htmlFor={inputId}>
            <Link color="gray">{t('pages.users.changePhoto')}</Link>
          </label>
        </Grid>
      )}
    </Stack>
  );
}
