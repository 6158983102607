import { enumValue, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export type UserSubmitCommentRequest = {
  remarketingVehicleId: string;
  brand: AvailableBrands;
  comment: string;
  dealerId: number;
};

export const decodeUserSubmitCommentRequest = object<UserSubmitCommentRequest>({
  remarketingVehicleId: text,
  brand: enumValue(AvailableBrands),
  comment: text,
  dealerId: number,
});
