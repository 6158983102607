import { AvailableBrands } from '../../api/util/AvailableBrands.js';
import { Dealer } from '../../api/util/Dealer.js';
import { joinUrl } from '../../api/util/joinUrl.js';
import { SyndicationType } from '../../api/util/SyndicationType.js';
import { VehicleListingForm } from '../../api/util/Vehicle.js';
import { BrandConfig } from './BrandsConfig.js';

export enum CommonMarketType {
  Global = 'Global',
}

export type VehicleInfo = Pick<
  VehicleListingForm,
  'dealer' | 'syndicationType'
>;

export const normaliseDealerForUrl = (
  id: string | number,
  name: string,
): string => {
  return `${id}_${name.replace(/[^\p{L}0-9]+/gu, '_')}`;
};

export const getLocatorBaseUrl = (
  brandConfig: BrandConfig,
  brand: AvailableBrands,
  vehicle: VehicleInfo,
): string | undefined => {
  const { locatorUrl } = brandConfig;

  if (!locatorUrl) {
    return;
  }
  switch (brand) {
    case AvailableBrands.AstonMartin:
      return locatorUrl.avl && locatorUrl.avl[CommonMarketType.Global];
    case AvailableBrands.RollsRoyce:
      return vehicle.syndicationType === SyndicationType.New
        ? locatorUrl.nvl?.[vehicle.dealer.countryCode || ''] ||
            locatorUrl.nvl?.[CommonMarketType.Global]
        : locatorUrl.avl?.[vehicle.dealer.countryCode || ''] ||
            locatorUrl.avl?.[CommonMarketType.Global];
    case AvailableBrands.Bentley:
      return vehicle.syndicationType === SyndicationType.New
        ? locatorUrl.nvl && locatorUrl.nvl[CommonMarketType.Global]
        : locatorUrl.avl && locatorUrl.avl[CommonMarketType.Global];
    case AvailableBrands.Genesis:
      return (
        locatorUrl.avl &&
        locatorUrl.avl[vehicle.dealer.region?.name || CommonMarketType.Global]
      );
    case AvailableBrands.Lamborghini:
      const market =
        vehicle.dealer.countryCode === 'US' ? 'US' : CommonMarketType.Global;
      return vehicle.syndicationType === SyndicationType.New
        ? locatorUrl.nvl && locatorUrl.nvl[market]
        : locatorUrl.avl && locatorUrl.avl[market];
    case AvailableBrands.Kia:
      return (
        locatorUrl.avl &&
        locatorUrl.avl[vehicle.dealer.countryCode || CommonMarketType.Global]
      );
    default:
      return undefined;
  }
};

export const getLocatorVPDUrl = (
  brandConfig: BrandConfig,
  brand: AvailableBrands,
  vehicle: VehicleInfo & { id: string },
): string | undefined => {
  const baseUrl = getLocatorBaseUrl(brandConfig, brand, vehicle);
  if (!baseUrl) {
    return;
  }

  switch (brand) {
    case AvailableBrands.Kia:
      return `${baseUrl}/vdp/${vehicle.id}-`;

    default:
      return joinUrl(baseUrl, 'vdp', String(vehicle.id) + '-');
  }
};

export const getLocatorDealerUrl = (
  brandConfig: BrandConfig,
  brand: AvailableBrands,
  dealer: Pick<Dealer, 'id' | 'name'>,
): string | undefined => {
  const { locatorUrl } = brandConfig;
  if (!locatorUrl) {
    return;
  }

  switch (brand) {
    case AvailableBrands.AstonMartin:
      return (
        locatorUrl.avl &&
        dealer.name &&
        joinUrl(
          locatorUrl.avl[CommonMarketType.Global],
          `dealer_${normaliseDealerForUrl(
            dealer.id,
            dealer.name,
          )}/searchresults`,
        )
      );
    case AvailableBrands.Bentley:
      return (
        locatorUrl.avl &&
        dealer.name &&
        joinUrl(
          locatorUrl.avl[CommonMarketType.Global],
          `dealer_${normaliseDealerForUrl(
            dealer.id,
            dealer.name,
          )}/searchresults`,
        )
      );

    default:
      return (
        locatorUrl.avl &&
        dealer.name &&
        joinUrl(
          locatorUrl.avl[CommonMarketType.Global],
          `dealer_${normaliseDealerForUrl(
            dealer.id,
            dealer.name,
          )}/searchresults`,
        )
      );
  }
};
