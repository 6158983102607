import { Add, Close, ExpandMore } from '@mui/icons-material';
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Checkbox,
  Chip,
  FormControlLabel,
  Stack,
  Typography,
  lighten,
} from '@mui/material';
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { sx } from '../../../util/sx.js';
import { Country } from './RegionSelector.js';

const styles = sx({
  selectedCountry: {
    bgcolor: (theme) => lighten(theme.palette.primary.main, 0.9),
    color: 'primary.dark',
    fontSize: '12px',
    fontWeight: 'fontWeightBold',
  },
  country: {
    bgcolor: '#E4EAF1',
    textTransform: 'uppercase',
  },
  accordion: {
    borderTop: `2px solid #F7F8F9`,
    borderBottom: `2px solid #F7F8F9`,
    '&:not(:last-child)': {
      borderBottom: 0,
    },
    '&:before': {
      display: 'none',
    },
    '&.Mui-disabled': {
      borderTop: `1px solid #e4eaf1`,
      borderBottom: `1px solid #e4eaf1`,
    },
  },
  accordionDetails: {
    border: ' 2px solid #BDC7D3',
    borderRadius: 1,
    padding: '12px 8px',
  },
  accordionSummary: {
    px: 1,
    m: 0,
    '&.Mui-disabled': {
      opacity: 1,
      bgcolor: '#F9FAFB',
    },
  },
  regionCheckbox: {
    p: 0.5,
  },
});

interface RegionAccordionProps {
  region: string;
  selectedCountries: Country[];
  deselectedCountries: Country[];
  onCountryChipDelete: (country: Country, selected?: boolean) => void;
  onChange?: (
    event: React.ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  disabled?: boolean;
}

const RegionAccordion: React.FC<RegionAccordionProps> = ({
  region,
  selectedCountries,
  deselectedCountries,
  onChange,
  onCountryChipDelete,
  disabled,
}) => {
  const [regionSelected, setRegionSelected] = useState<boolean>(false);

  const handleRegionSelected = useCallback(
    (event: React.ChangeEvent<HTMLInputElement>, checked: boolean) => {
      setRegionSelected((state) => !state);
      onChange && onChange(event, checked);
    },
    [onChange],
  );

  const isIndeterminateSelection = useMemo(
    () => selectedCountries.length > 0 && deselectedCountries.length > 0,
    [deselectedCountries.length, selectedCountries.length],
  );

  useEffect(() => {
    setRegionSelected(
      selectedCountries.length > 0 && deselectedCountries.length === 0,
    );
  }, [selectedCountries, deselectedCountries]);

  return (
    <Accordion
      TransitionProps={{ unmountOnExit: true }}
      disableGutters
      square
      sx={styles.accordion}
    >
      <AccordionSummary
        disableRipple
        expandIcon={<ExpandMore />}
        sx={styles.accordionSummary}
      >
        <Stack
          direction={{
            xs: 'column',
            sm: 'row',
          }}
          onClick={(e) => e.stopPropagation()}
          spacing={2}
        >
          <div>
            <FormControlLabel
              control={
                <Checkbox
                  checked={regionSelected}
                  color={
                    isIndeterminateSelection || disabled ? 'default' : 'primary'
                  }
                  disableRipple
                  disabled={disabled}
                  indeterminate={isIndeterminateSelection}
                  onChange={handleRegionSelected}
                  size="small"
                  sx={styles.regionCheckbox}
                />
              }
              label={
                <Typography
                  color={regionSelected && !disabled ? 'primary' : 'default'}
                  component="span"
                  fontWeight={
                    regionSelected || disabled ? 'fontWeightBold' : undefined
                  }
                  variant="body2"
                  whiteSpace="nowrap"
                >
                  {`${region} (${selectedCountries.length})`}
                </Typography>
              }
            />
          </div>
        </Stack>
      </AccordionSummary>
      <AccordionDetails sx={styles.accordionDetails}>
        <Stack rowGap={2}>
          {selectedCountries.length > 0 && (
            <Stack direction="row" flexWrap="wrap" gap={0.75}>
              {selectedCountries.map((country) => (
                <Chip
                  color="primary"
                  deleteIcon={<Close />}
                  key={country.countryCode}
                  label={country.countryName}
                  onDelete={
                    disabled ? undefined : () => onCountryChipDelete(country)
                  }
                  size="small"
                  sx={styles.selectedCountry}
                  variant="outlined"
                />
              ))}
            </Stack>
          )}
          {deselectedCountries.length > 0 && (
            <Stack direction="row" flexWrap="wrap" gap={0.75}>
              {deselectedCountries.map((country) => (
                <Chip
                  deleteIcon={<Add />}
                  disabled={disabled}
                  key={country.countryCode}
                  label={country.countryName}
                  onDelete={() => onCountryChipDelete(country)}
                  size="small"
                  sx={styles.country}
                />
              ))}
            </Stack>
          )}
        </Stack>
      </AccordionDetails>
    </Accordion>
  );
};

export default RegionAccordion;
