import { object, text } from '@fmtk/decoders';
import { assertWithDetailedError } from '../util/index.js';

export interface LoggingConfig {
  LogLevel: string;
}

export const decodeLoggingConfig = object<LoggingConfig>({
  LogLevel: text,
});

export const LoggingConfigVars = {
  LogLevel: 'LOG_LEVEL',
};

export function loadLoggingConfig(defaults?: LoggingConfig): LoggingConfig {
  return assertWithDetailedError(decodeLoggingConfig, {
    LogLevel:
      process.env[LoggingConfigVars.LogLevel] || defaults?.LogLevel || 'info',
  });
}
