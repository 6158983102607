import { SxProps } from '@mui/system';
import { uniqueId } from 'lodash-es';
import { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../../hooks/useConst.js';
import { Cell, CellInput, useForm } from '../../index.js';

export interface CellTextInputProps {
  label: string;
  name: string;
  readonly?: boolean;
  formattedValue?: string;
  maxLength?: number;
  cellStyleProps?: SxProps;
  required?: boolean;
  highlightChanges?: boolean;
  value?: unknown;
  onChange?: (value: unknown) => void;
  endAdornment?: ReactNode;
}

export const CellTextInput: FunctionComponent<CellTextInputProps> = ({
  name,
  label,
  readonly,
  formattedValue,
  maxLength,
  cellStyleProps,
  required,
  highlightChanges,
  value,
  onChange,
  endAdornment,
}) => {
  const [{ values }, formBind, { translations }] = useForm();
  const ref = useConst(() => uniqueId('id'));
  const { t } = useTranslation();
  const [internalValue, setInternalValue] = useState(values[name]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = e.target.value;

    if (onChange) {
      onChange(newValue);
    } else {
      setInternalValue(newValue);
      formBind.setValue(name, newValue);
    }
  };

  useEffect(() => {
    if (value === undefined) {
      setInternalValue(values[name]);
    }
  }, [name, value, values]);

  return (
    <Cell
      endAdornment={endAdornment}
      formattedValue={formattedValue}
      highlightChanges={highlightChanges}
      htmlFor={ref}
      label={
        translations
          ? t([`${translations}.labels.${name}`, label], {
              defaultValue: label,
            })
          : t(label)
      }
      name={name}
      readonly={readonly}
      required={required}
      sx={cellStyleProps}
    >
      <CellInput
        id={ref}
        inputProps={{
          maxLength: maxLength,
        }}
        onChange={handleChange}
        tabIndex={1}
        value={value !== undefined ? value : internalValue}
      />
    </Cell>
  );
};

export default CellTextInput;
