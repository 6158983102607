import {
  array,
  assert,
  boolean,
  choose,
  integer,
  object,
  optional,
  string,
} from '@fmtk/decoders';
import {
  Auth0ImsAppMetadata,
  decodeAuth0ImsAppMetadata,
} from './Auth0ImsAppMetadata.js';

export interface AccessToken {
  aud: string | string[];
  auth_time?: number;
  azp: string;
  exp: number;
  gty?: string;
  iat: number;
  iss: string;
  jti?: string;
  nbf?: number;
  scope: string;
  sub: string;
  given_name?: string;
  family_name?: string;
  organization?: string;
  app_metadata?: Auth0ImsAppMetadata;
  contractkey?: string;
  email?: string;
  emailVerified?: boolean;
  //for claims [namespace/claimId]
  [claimId: string]: unknown;
}

export const decodeAccessToken = object<AccessToken>({
  aud: choose(array(string), string),
  auth_time: optional(integer),
  azp: string,
  exp: integer,
  gty: optional(string),
  iat: integer,
  iss: string,
  jti: optional(string),
  nbf: optional(integer),
  scope: string,
  sub: string,
  given_name: optional(string),
  family_name: optional(string),
  organization: optional(string),
  app_metadata: optional(decodeAuth0ImsAppMetadata),
  contractkey: optional(string),
  email: optional(string),
  emailVerified: optional(boolean),
});

export function makeAccessToken(value: AccessToken): AccessToken {
  // this function is a type helper for intellisense
  return parseAccessToken(value);
}

export function parseAccessToken(value: unknown): AccessToken {
  return assert(decodeAccessToken, value);
}

export function tryParseAccessToken(value: unknown): AccessToken | undefined {
  const result = decodeAccessToken(value);
  return result.ok ? result.value : undefined;
}
