import { enumValue, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface IncreaseRemarketingVehicleViewsRequest {
  brand: AvailableBrands;
  id: string;
}

export const decodeIncreaseRemarketingVehicleViewsRequest =
  object<IncreaseRemarketingVehicleViewsRequest>({
    brand: enumValue(AvailableBrands),
    id: text,
  });
