import {
  ExtraFields,
  choose,
  enumValue,
  maybe,
  number,
  object,
  string,
} from '@fmtk/decoders';
import { AnalyticsEventType } from '../../util/index.js';

export interface RecordEventRequest {
  vehicleId: string | number;
  eventType: AnalyticsEventType;
  hash?: string;
}

export const decodeRecordEventRequest = object<RecordEventRequest>(
  {
    vehicleId: choose(string, number),
    eventType: enumValue(AnalyticsEventType),
    hash: maybe(string),
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);
