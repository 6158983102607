import { enumValue, jsonDate, number, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface listLiveStatusChangesRequest {
  brand: AvailableBrands;
  startDate: Date;
  endDate: Date;
  vin: string;
  amountOfRows: number;
  pageNumber: number;
}

export const decodeListLiveStatusChangesRequest =
  object<listLiveStatusChangesRequest>({
    brand: enumValue(AvailableBrands),
    vin: string,
    startDate: jsonDate,
    endDate: jsonDate,
    amountOfRows: number,
    pageNumber: number,
  });
