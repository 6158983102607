import { Close } from '@mui/icons-material';
import {
  alpha,
  Drawer,
  DrawerProps,
  IconButton,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { sx } from '../../util/index.js';

type MainDrawerProps = PropsWithChildren & {
  title: string;
  onClose: () => void;
  disableBackdropClick?: boolean;
  containerProps?: SxProps;
} & Omit<DrawerProps, 'OnClose'>;

const styles = sx({
  drawer: (theme) => ({
    width: '80%',
    bgcolor: 'background.default',
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  }),
  drawerContainer: {
    p: 2,
  },
  drawerHeader: {
    bgcolor: (theme) =>
      alpha(theme.palette.primary.main, theme.palette.action.selectedOpacity),
    color: 'primary.dark',
    py: 1.5,
    px: 2,
  },
});

const MainDrawer: FunctionComponent<MainDrawerProps> = ({
  title,
  children,
  PaperProps,
  disableBackdropClick,
  containerProps,
  onClose,
  ...props
}): JSX.Element => {
  return (
    <Drawer
      anchor="right"
      onClose={(_, reason) => {
        if (disableBackdropClick && reason === 'backdropClick') {
          onClose();
        }
      }}
      PaperProps={{
        ...PaperProps,
        sx: [
          styles.drawer,
          ...(PaperProps?.sx
            ? Array.isArray(PaperProps.sx)
              ? PaperProps.sx
              : [PaperProps.sx]
            : []),
        ],
      }}
      {...props}
    >
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        sx={styles.drawerHeader}
      >
        <Typography variant="h4">{title}</Typography>
        <IconButton onClick={onClose}>
          <Close
            sx={{
              color: 'primary.dark',
            }}
          />
        </IconButton>
      </Stack>
      <Stack
        sx={{
          ...styles.drawerContainer,
          ...containerProps,
        }}
      >
        {children}
      </Stack>
    </Drawer>
  );
};

export default MainDrawer;
