import { Button, Typography } from '@mui/material';
import { Box } from '@mui/system';
import { FunctionComponent } from 'react';
import { Link } from 'react-router-dom';

const NotFoundPage: FunctionComponent = () => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        flexDirection: 'column',
        gap: 2,
      }}
    >
      <Typography
        sx={{
          color: 'primary.main',
          fontWeight: 700,
          lineHeight: '121px',
          fontSize: 100,
        }}
      >
        404
      </Typography>
      <Typography color="text.secondary" pt={8} variant="h5">
        It looks like the page you are looking for doesn't exist or has been
        moved
      </Typography>
      <div>
        <Button component={Link} to="/" variant="contained" disableElevation>
          Return to dashboard
        </Button>
      </div>
    </Box>
  );
};

export default NotFoundPage;
