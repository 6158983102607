import { GridColDef } from '@mui/x-data-grid';
import { t } from 'i18next';
import { AvatarColumn, Tag } from '../../../common-ui/index.js';
import { formatDateTime } from '../../../util/dates.js';
import MenuColumn from './MenuColumn.js';

interface BuildColumnsProps {
  onDelete: (principalId: string) => void;
  onEdit: (principalId: string) => void;
}

export const renderColumns = ({
  onDelete,
  onEdit,
}: BuildColumnsProps): GridColDef[] => [
  {
    field: 'avatarUrl',
    headerName: '',
    hideSortIcons: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (params) => <AvatarColumn {...params} />,
    width: 60,
  },
  {
    field: 'fullName',
    headerName: t('pages.users.name'),
    minWidth: 200,
    maxWidth: 300,
    flex: 1,
  },
  {
    field: 'email',
    headerName: t('pages.users.email'),
    flex: 1,
    minWidth: 250,
  },
  {
    field: 'lastLogin',
    headerName: t('pages.users.lastLogin'),
    valueFormatter: ({ value }) => (value ? formatDateTime(value) : ''),
    flex: 1,
  },
  {
    field: 'createDate',
    headerName: t('pages.users.created'),
    valueFormatter: ({ value }) => (value ? formatDateTime(value) : ''),
    flex: 1,
  },

  {
    field: 'isAdmin',
    headerName: t('pages.users.admin'),
    renderCell: ({ value }) =>
      value ? <Tag>{t('pages.users.admin')}</Tag> : <></>,
  },
  {
    field: 'hasRemarketing',
    headerName: t('pages.users.remarketing'),
    renderCell: ({ value }) =>
      value ? <Tag color="info">{t('pages.users.remarketing')}</Tag> : <></>,
  },
  {
    field: 'principalId',
    headerName: '',
    hideSortIcons: true,
    disableColumnMenu: true,
    disableReorder: true,
    sortable: false,
    renderCell: (params) => (
      <MenuColumn {...params} onDelete={onDelete} onEdit={onEdit} />
    ),
    width: 60,
  },
];
