import {
  enumValue,
  is,
  maybe,
  number,
  object,
  optional,
  string,
  text,
} from '@fmtk/decoders';
import { RemarketingActionType } from '../../util/RemarketingVehicle.js';
import { AvailableBrands } from '../../util/index.js';

export interface AdminListRemarketingActionsRequest {
  brand: AvailableBrands;
  remarketingVehicleId: string;
  actionType?: RemarketingActionType;
  userId?: string;
  timestamp?: number;
  nextToken?: string;
  order?: 'asc' | 'desc';
}

export const decodeAdminListRemarketingActionsRequest =
  object<AdminListRemarketingActionsRequest>({
    brand: enumValue(AvailableBrands),
    remarketingVehicleId: text,
    actionType: maybe(enumValue(RemarketingActionType)),
    userId: maybe(text),
    timestamp: maybe(number),
    nextToken: optional(string),
    order: maybe(is('asc', 'desc')),
  });
