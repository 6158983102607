import { object, string } from '@fmtk/decoders';

export interface RelistVehicleListingRequest {
  id: string;
}

export const decodeRelistVehicleListingRequest =
  object<RelistVehicleListingRequest>({
    id: string,
  });
