import { Decoder, invalid, ok } from '@fmtk/decoders';

const ExpectedUrl = 'EXPECTED_URL';

export const isUrl: Decoder<string, string> = (value) => {
  try {
    new URL(value);
    return ok(value);
  } catch {
    return invalid(ExpectedUrl, 'expected a valid URL');
  }
};
