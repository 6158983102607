import { useAuth0 } from '@auth0/auth0-react';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, useCallback } from 'react';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';

const VerifyEmailPage: FunctionComponent = (): JSX.Element => {
  const api = useApiClient();
  const { logout } = useAuth0();
  const [sendEmailState, setSendEmailState] = useAsyncState();

  const handleSendVerificationEmail = useCallback(() => {
    setSendEmailState(async () => {
      await api.user.sendEmailVerification();
    });
  }, [api.user, setSendEmailState]);

  return (
    <Stack
      alignContent="center"
      bgcolor="#effaff"
      direction="column"
      display="flex"
      height="100vh"
      justifyContent="center"
      p={{ xs: 2, sm: 5 }}
      textAlign="center"
    >
      <Box alignContent="center" display="flex" justifyContent="center" mb={2}>
        <img
          alt="logo"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/connect-auto-branding/logo"
          style={{ marginRight: 20 }}
          width={50}
        />
        <img
          alt="logo"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/connect-auto-branding/full-logo"
          width={400}
        />
      </Box>
      <Typography color="#333" variant="h3">
        Please verify your email address
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: 5 }}>
        <img
          alt="unauthorized"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/icons/onboarding-state-feedback.svg"
          width={400}
        />
      </Box>
      <Container maxWidth="sm">
        <Typography color="#333" variant="body1">
          Check your inbox for a verification email from support@connect.auto
        </Typography>
      </Container>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'center',
          margin: 5,
        }}
      >
        <LoadingButton
          loading={sendEmailState.loading}
          onClick={handleSendVerificationEmail}
          sx={{ width: 150 }}
          variant="contained"
        >
          Resend Email
        </LoadingButton>

        {logout && (
          <Button
            onClick={() => logout()}
            sx={{ width: 150, marginLeft: 10 }}
            variant="outlined"
          >
            Log out
          </Button>
        )}
      </Box>
    </Stack>
  );
};
export default VerifyEmailPage;
