import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { InAppNotification } from '../../../../api/util/InAppNotification.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  ListInAppNotificationQueryData,
  makeListInAppNotificationsQueryKey,
} from '../queries/useListInappNotifications.js';

export const useDeleteInAppNotification = (): UseMutationResult<
  InAppNotification,
  unknown,
  { notificationId: string }
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ notificationId }) => {
      return await api.inAppNotifications.deleteUserNotification({
        notificationId,
      });
    },
    onSuccess: (deleted, { notificationId }) => {
      queryClient.setQueriesData<ListInAppNotificationQueryData>(
        { queryKey: makeListInAppNotificationsQueryKey() },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.filter((n) => n.id !== notificationId),
                total: Math.max(p.total - 1, 0),
                unreadCount: deleted.read ? p.unreadCount : p.unreadCount - 1,
              })),
            };
          }
        },
      );
    },
  });
};
