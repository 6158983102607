import { enumValue, maybe, object } from '@fmtk/decoders';
import { ArchiveOptions } from '../../util/ArchiveOptions.js';
import { AvailableBrands } from '../../util/index.js';

export interface AdminCountRemarketingVehiclesRequest {
  brand: AvailableBrands;
  archiveOption?: ArchiveOptions;
}

export const decodeAdminCountRemarketingVehiclesRequest =
  object<AdminCountRemarketingVehiclesRequest>({
    brand: enumValue(AvailableBrands),
    archiveOption: maybe(enumValue(ArchiveOptions)),
  });
