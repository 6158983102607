import {
  array,
  choose,
  enumValue,
  is,
  number,
  object,
  text,
} from '@fmtk/decoders';
import { AvailableBrands } from '../../api/util/AvailableBrands.js';
import { SyndicationType } from '../../api/util/SyndicationType.js';

export interface SaveVehicleRuleKia {
  maxOdometer: number;
  maxYearRegistration: number;
  dealerCountryCode: string[];
  syndicationTypeToCheck: SyndicationType;
  type: AvailableBrands.Kia;
}

export const decodeSaveVehicleRuleKia = object<SaveVehicleRuleKia>({
  syndicationTypeToCheck: enumValue(SyndicationType),
  dealerCountryCode: array(text),
  maxOdometer: number,
  maxYearRegistration: number,
  type: is(AvailableBrands.Kia),
});

export interface SaveVehicleRuleAstonMartin {
  maxYearRegistration: number;
  syndicationTypeToCheck: SyndicationType;
  type: AvailableBrands.AstonMartin;
}

export const decodeSaveVehicleRuleAstonMartin =
  object<SaveVehicleRuleAstonMartin>({
    syndicationTypeToCheck: enumValue(SyndicationType),
    maxYearRegistration: number,
    type: is(AvailableBrands.AstonMartin),
  });

export type SaveVehicleRule = SaveVehicleRuleKia | SaveVehicleRuleAstonMartin;

export const decodeSaveVehicleRule = choose(
  decodeSaveVehicleRuleKia,
  decodeSaveVehicleRuleAstonMartin,
);
