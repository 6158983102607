import {
  array,
  boolean,
  enumValue,
  jsonDate,
  maybe,
  object,
  string,
  text,
} from '@fmtk/decoders';
import {
  VehicleMedia,
  VehicleMediaStatus,
  decodeVehicleMedia,
} from '../../util/VehicleMedia.js';

export interface SimpleImage {
  hasApproved?: boolean;
  hasError?: boolean;
  hasPending?: boolean;
  hasProcessing?: boolean;
  hasRejected?: boolean;
  id: string;
  isPlaceholder?: boolean;
  label?: string;
  media?: VehicleMedia;
  src?: string;
  status?: VehicleMediaStatus;
}

export const decodeSimpleImage = object<SimpleImage>({
  id: text,
  src: maybe(text),
  label: maybe(text),
  isPlaceholder: maybe(boolean),
  media: maybe(decodeVehicleMedia(jsonDate)),
  hasApproved: maybe(boolean),
  hasError: maybe(boolean),
  hasPending: maybe(boolean),
  hasProcessing: maybe(boolean),
  hasRejected: maybe(boolean),
  status: maybe(enumValue(VehicleMediaStatus)),
});

export interface ReorderMediaRequest {
  reorderedImages: SimpleImage[];
  vehicleId: string;
}

export const decodeReorderMediaRequest = object<ReorderMediaRequest>({
  reorderedImages: array(decodeSimpleImage),
  vehicleId: string,
});
