import { KeyboardArrowDown } from '@mui/icons-material';
import {
  Button,
  Menu,
  MenuItem,
  MenuItemProps,
  Stack,
  styled,
  SxProps,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FileDrop } from '../FileDrop.js';
import { FilePicker } from '../FilePicker.js';

const styles: Record<string, SxProps> = {
  filePicker: {
    cursor: 'pointer',
    p: 6,
    bgcolor: 'background.default',
    border: 2,
    borderRadius: 2,
    borderStyle: 'dashed',
    borderColor: 'grey.500',
    color: 'grey.500',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  menuItem: {
    minWidth: 100,
  },
};

const HiddenInput = styled('input')({ display: 'none' });

export enum OptionType {
  PDF = 'PDF',
  Link = 'Link',
}
export interface OnChangeParams {
  option: OptionType;
  file: File | null;
}

export interface DocumentPickerProps {
  accept?: string;
  menuOptions?: MenuItemProps[];
  onChange?: (params: OnChangeParams) => void;
  options: OptionType[];
}

export function DocumentPicker({
  accept,
  onChange,
  options: types,
}: DocumentPickerProps): JSX.Element {
  const { t } = useTranslation();
  const [internalAccept, setAccept] = useState<string | undefined>(accept);
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const fileChange = useCallback(
    (file: File[] | null) => {
      setSelectedFile(file && file.length ? file[0] : null);
    },
    [setSelectedFile],
  );
  const [anchorElement, setAnchorEl] = useState<null | HTMLElement>(null);

  const closeFileMenu = useCallback(() => {
    setAnchorEl(null);
    setSelectedFile(null);
  }, []);

  const handleFileInputChange = useCallback(
    (e?: React.ChangeEvent<HTMLInputElement>) => {
      const file = e?.target?.files?.length ? e?.target.files[0] : null;

      setSelectedFile(file);
    },
    [],
  );

  const openFileMenu = useCallback((event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleLinkClick = useCallback(() => {
    fileChange(null);
    onChange && onChange({ option: OptionType.Link, file: null });
    closeFileMenu();
  }, [closeFileMenu, fileChange, onChange]);

  useEffect(() => {
    if (selectedFile) {
      onChange && onChange({ option: OptionType.PDF, file: selectedFile });
      closeFileMenu();
    }
  }, [closeFileMenu, onChange, selectedFile]);

  const handleAccept = useCallback((option: OptionType) => {
    setAccept(option === OptionType.PDF ? 'application/pdf' : undefined);
  }, []);

  const showFileMenu = Boolean(anchorElement);
  return (
    <>
      <Menu
        anchorEl={anchorElement}
        onClose={closeFileMenu}
        open={showFileMenu}
      >
        {types.map((type: OptionType): JSX.Element => {
          switch (type) {
            case OptionType.PDF: {
              return (
                <MenuItem
                  data-test="document.type.pdf"
                  divider
                  key={type}
                  sx={styles.menuItem}
                >
                  <label htmlFor="filepicker-input">
                    <Typography sx={styles.menuItem}>
                      {t('documents.PDF')}
                    </Typography>
                  </label>
                  <HiddenInput
                    accept="application/pdf"
                    id="filepicker-input"
                    onChange={handleFileInputChange}
                    onClick={() => handleAccept(OptionType.PDF)}
                    sx={styles.menuItem}
                    type="file"
                  />
                </MenuItem>
              );
            }
            case OptionType.Link: {
              return (
                <MenuItem
                  key={OptionType.Link}
                  onClick={handleLinkClick}
                  sx={styles.menuItem}
                >
                  <Typography>{t('documents.link')}</Typography>
                </MenuItem>
              );
            }
            default:
              return <></>;
          }
        })}
      </Menu>
      <Stack marginY={2}>
        <FilePicker
          accept={internalAccept}
          multiple={false}
          onFileChange={fileChange}
          selectedFiles={selectedFile ? [selectedFile] : []}
        >
          {(props) => (
            <Stack spacing={2}>
              <FileDrop {...props} translations="documents.dropZone">
                <Button endIcon={<KeyboardArrowDown />} onClick={openFileMenu}>
                  {t('documents.selectFile')}
                </Button>
              </FileDrop>
            </Stack>
          )}
        </FilePicker>
      </Stack>
    </>
  );
}
