import {
  array,
  maybe,
  number,
  object,
  record,
  string,
  text,
} from '@fmtk/decoders';

export type BentleyVinLookupFeature = {
  code: string;
  description: string;
};

export type BentleyVinLookupFeatures = Record<
  string,
  BentleyVinLookupFeature[] | undefined
>;

export type BentleyVinLookupResponse = {
  vin: string;
  modelYear?: number;
  model: string;
  bodyStyle?: string;
  primaryColour?: string;
  interiorColour?: string;
  fuelType?: string;
  transmission?: string;
  paintType?: string;
  engineOutput?: string;
  engineCapacity?: number;
  features?: BentleyVinLookupFeatures;
  efficiencyClass?: string;
  co2Class?: string;
  co2ClassDischargedBattery?: string;
  co2ClassWeightedCombined?: string;
  nedcConsumptionUrban?: string;
  nedcConsumptionExtraUrban?: string;
  nedcConsumptionCombined?: string;
  nedcCo2Emission?: string;
  wltpConsumptionLow?: string;
  wltpConsumptionMid?: string;
  wltpConsumptionHigh?: string;
  wltpConsumptionExtraHigh?: string;
  wltpConsumptionCombined?: string;
  wltpEmissionsLow?: string;
  wltpEmissionsMid?: string;
  wltpEmissionsHigh?: string;
  wltpEmissionsExtraHigh?: string;
  wltpEmissionsCombined?: string;
  environmentalClass?: string;
};

export const decodeBentleyVinLookupFeature = object<BentleyVinLookupFeature>({
  code: text,
  description: text,
});

export const decodeBentleyVinLookupResponse = object<BentleyVinLookupResponse>({
  vin: text,
  modelYear: maybe(number),
  model: text,
  bodyStyle: maybe(text),
  primaryColour: maybe(text),
  interiorColour: maybe(text),
  fuelType: maybe(text),
  transmission: maybe(text),
  paintType: maybe(text),
  engineOutput: maybe(text),
  engineCapacity: maybe(number),
  features: maybe(record(string, maybe(array(decodeBentleyVinLookupFeature)))),
  efficiencyClass: maybe(text),
  co2Class: maybe(text),
  co2ClassDischargedBattery: maybe(text),
  co2ClassWeightedCombined: maybe(text),
  nedcConsumptionUrban: maybe(text),
  nedcConsumptionExtraUrban: maybe(text),
  nedcConsumptionCombined: maybe(text),
  nedcCo2Emission: maybe(text),
  wltpConsumptionLow: maybe(text),
  wltpConsumptionMid: maybe(text),
  wltpConsumptionHigh: maybe(text),
  wltpConsumptionExtraHigh: maybe(text),
  wltpConsumptionCombined: maybe(text),
  wltpEmissionsLow: maybe(text),
  wltpEmissionsMid: maybe(text),
  wltpEmissionsHigh: maybe(text),
  wltpEmissionsExtraHigh: maybe(text),
  wltpEmissionsCombined: maybe(text),
  environmentalClass: maybe(text),
});
