import { number, object, optional, text } from '@fmtk/decoders';

export interface SendInviteRequest {
  principalId: string;
  dealerId?: number;
}

export const decodeSendInviteRequest = object<SendInviteRequest>({
  principalId: text,
  dealerId: optional(number),
});
