import { jsonDate, object, text } from '@fmtk/decoders';
import {
  AddRemarketingVehicleRequest,
  decodeAddRemarketingVehicleRequest,
} from '../addRemarketingVehicle/AddRemarketingVehicleRequest.js';

export type AdminRelistRemarketingVehicleRequest = {
  addRemarketingVehicleRequest: AddRemarketingVehicleRequest;
  idToRelist: string;
};

export const decodeAdminRelistRemarketingVehicleRequest =
  object<AdminRelistRemarketingVehicleRequest>({
    addRemarketingVehicleRequest: decodeAddRemarketingVehicleRequest(jsonDate),
    idToRelist: text,
  });
