import { QueryDslQueryContainer } from '@opensearch-project/opensearch/api/types';
import { filterFalsey } from '../../apps/util/filterFalsey.js';

export enum ArchiveOptions {
  includeArchived = 'includeArchived',
  excludeArchived = 'excludeArchived',
  onlyArchived = 'onlyArchived',
}

export const resolveMustNotArchivedInput = (
  archiveOption: ArchiveOptions,
): QueryDslQueryContainer | QueryDslQueryContainer[] | undefined => {
  return filterFalsey([
    archiveOption === ArchiveOptions.includeArchived
      ? undefined
      : {
          term: {
            archived: archiveOption === ArchiveOptions.excludeArchived,
          },
        },
  ]);
};
