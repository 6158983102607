import { enumValue, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetArchiveVehicleListingProcessRequest {
  vehicleListingId: string;
  brand: AvailableBrands;
  dealerId: number;
}

export const decodeGetArchiveVehicleListingProcessRequest =
  object<GetArchiveVehicleListingProcessRequest>({
    vehicleListingId: text,
    brand: enumValue(AvailableBrands),
    dealerId: number,
  });
