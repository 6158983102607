import {
  DataGrid as MuiDataGrid,
  DataGridProps as MuiDataGridProps,
} from '@mui/x-data-grid';
import { FunctionComponent } from 'react';
import { sx } from '../../util/sx.js';

// eslint-disable-next-line @typescript-eslint/ban-types
type DataGridProps = MuiDataGridProps & {};

const styles = sx({
  grid: {
    border: 0,
    '.MuiDataGrid-root': {
      border: 0,
    },
    '.MuiDataGrid-columnHeaders': {
      backgroundColor: (theme) => theme.palette.grey[200],
      color: 'text.secondary',
      borderRadius: 0,
      border: 0,
    },
    '.MuiDataGrid-columnSeparator': {
      display: 'none',
    },
    '.MuiDataGrid-cell': {
      outline: 'none',
      ':focus': {
        outline: 'none',
      },
    },
    '.MuiDataGrid-columnHeader': {
      ':focus': {
        outline: 'none',
      },
    },
  },
});

export const DataGrid: FunctionComponent<DataGridProps> = ({
  sx,
  ...props
}) => {
  return <MuiDataGrid sx={{ ...styles.grid, ...sx }} {...props} />;
};

export default DataGrid;
