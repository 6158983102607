import { ArrowBack } from '@mui/icons-material';
import { Button, Divider, Tooltip, Typography, styled } from '@mui/material';
import { Stack } from '@mui/system';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import React, {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import {
  AdminTransferRemarketingVehicleResponse,
  TransferResponseCode,
} from '../../../../api/RemarketingService/adminTransferRemarketingVehicle/AdminTransferRemarketingVehicleResponse.js';
import {
  AuctionRemarketingVehicle,
  FixedPriceRemarketingVehicle,
  RemarketingType,
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import { getRelevantMediaFromVehicleMedia } from '../../../../util/vehicleMediaHelper.js';
import {
  ConfirmModal,
  Label,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useBrand } from '../../../hooks/useBrand.js';
import useCarouselMedia from '../../../hooks/useCarouselMedia.js';
import useCountdown from '../../../hooks/useCountDown.js';
import { useToggle } from '../../../hooks/useToggle.js';
import {
  archivedColour,
  remarketingStatusColours,
} from '../../../util/colors.js';
import { formatDateTime } from '../../../util/dates.js';
import { formatPrice } from '../../../util/formatters.js';
import { useArchiveVehicle } from '../../hooks/mutations/useArchiveVehicle.js';
import { useRemarketingEditor } from '../../hooks/useRemarketingEditor.js';
import { remarketingFiltersAtom } from '../../state/atoms.js';
import ImageCarousel from '../VehicleMedia/ImageCarousel.js';
import RelistButton from './RelistButton.js';

const afterActiveStatuses = [
  RemarketingVehicleStatus.CLOSED,
  RemarketingVehicleStatus.TRANSFERRED,
  RemarketingVehicleStatus.CANCELLED,
];

interface AdminRemarketingVehicleHeaderProps {
  remarketingVehicle: RemarketingVehicle;
}

const Img = styled('img')(({ theme }) => ({
  aspectRatio: '4/3',
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: 'contain',
  [theme.breakpoints.up('sm')]: {
    minWidth: 138,
    width: 240,
  },
}));

const Details = ({
  vehicle,
}: {
  vehicle: AuctionRemarketingVehicle | FixedPriceRemarketingVehicle;
}) => {
  const { t, i18n } = useTranslation();
  const closingDate = vehicle.closingDate
    ? DateTime.fromMillis(vehicle.closingDate, {
        locale: i18n.language,
      })
    : undefined;

  const { duration, inThePast, timeLeft } =
    useCountdown(closingDate, {
      stopOnZero: true,
      toRelativeOptions: {
        locale: i18n.language,
        unit: ['years', 'months', 'days', 'hours', 'minutes'],
      },
    }) || {};
  const showEndDate =
    vehicle.status !== RemarketingVehicleStatus.ACTIVE || inThePast;

  if (!closingDate || !duration) {
    return null;
  }

  return (
    <>
      <Typography
        color="grey.600"
        fontWeight="fontWeightMedium"
        variant="body2"
      >
        {showEndDate ? t('endDate') : t('timeLeft')}
      </Typography>
      <Typography
        color={showEndDate ? 'grey.800' : 'error.dark'}
        fontWeight="fontWeightMedium"
        variant="body2"
      >
        {showEndDate
          ? formatDateTime(closingDate.toJSDate())
          : `${timeLeft} | ${formatDateTime(closingDate.toJSDate())}`}
      </Typography>
    </>
  );
};

const AdminRemarketingVehicleHeader: FunctionComponent<
  AdminRemarketingVehicleHeaderProps
> = ({ remarketingVehicle }) => {
  const { t } = useTranslation();
  const { showMessage } = useMessageStore();
  const [isTransferConfirmOpen, toggleTransferConfirmOpen] = useToggle();
  const [isArchiveConfirmOpen, toggleArchiveConfirmOpen] = useToggle();
  const [transferState, setTransferState] =
    useAsyncState<AdminTransferRemarketingVehicleResponse>();
  const { currentBrand, brandConfig } = useBrand();
  const [showCarousel, setShowCarousel] = useState(false);
  const navigate = useNavigate();
  const api = useApiClient();
  const searchParams = useAtomValue(remarketingFiltersAtom);
  const { getFromVehicleMedia } = useCarouselMedia();
  const isEditor = useRemarketingEditor();

  const { mutateAsync: update, isPending } = useArchiveVehicle();

  const {
    image,
    title,
    price,
    otherPrices,
    detailsItem,
    startingDate,
    carouselMedia,
    archived,
  } = useMemo(() => {
    const { vehicleMedia } = remarketingVehicle;

    const media = getRelevantMediaFromVehicleMedia({
      vehicleMedia: vehicleMedia || [],
      options: {
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved, VehicleMediaStatus.Pending],
        transformation: 't_vehicle_thumbnail',
      },
    });

    const carouselMedia = getFromVehicleMedia(vehicleMedia);

    return {
      image: media?.mediaUrl || brandConfig?.noVehicleImage,
      title: `${
        remarketingVehicle.vehicle.modelYear ||
        remarketingVehicle.vehicle.registrationYear
      } ${remarketingVehicle.vehicle.model.name || ''}`,
      startingDate:
        remarketingVehicle.type === RemarketingType.AUCTION
          ? `${t('start')} ${formatDateTime(remarketingVehicle.startingDate)}`
          : undefined,
      price:
        'startPrice' in remarketingVehicle
          ? `${t(
              'pages.addRemarketingVehicle.details.form.labels.startPrice',
            )} ${formatPrice(
              remarketingVehicle.startPrice,
              remarketingVehicle.currency,
            )}`
          : '',
      otherPrices: [
        'reservePrice' in remarketingVehicle && remarketingVehicle.reservePrice
          ? `${t(
              'pages.addRemarketingVehicle.details.form.labels.reservePrice',
            )} ${formatPrice(
              remarketingVehicle.reservePrice,
              remarketingVehicle.currency,
            )}`
          : undefined,
      ].filter(Boolean) as string[],
      detailsItem: [], // TODO: add bids, offers, and more counters
      carouselMedia,
      archived: remarketingVehicle.archived,
    };
  }, [remarketingVehicle, getFromVehicleMedia, brandConfig?.noVehicleImage, t]);

  const transferRemarketing = useCallback(() => {
    if (!currentBrand) {
      return;
    }
    setTransferState(async () => {
      const response = await api.remarketing.adminTransferRemarketingVehicle({
        brand: currentBrand,
        remarketingVehicleId: remarketingVehicle.id,
      });

      toggleTransferConfirmOpen(false);
      if (response?.responseData.code === TransferResponseCode.Transferred) {
        showMessage({
          text: t('pages.remarketingVehiclePage.header.transferSuccess'),
          severity: 'success',
          dismissible: true,
          duration: 3000,
        });
        navigate(`../`);
      }
      if (
        response?.responseData.code ===
        TransferResponseCode.AlreadyExistsInDealer
      ) {
        showMessage({
          text: t(
            `pages.remarketingVehiclePage.header.${TransferResponseCode.AlreadyExistsInDealer}`,
            {
              value: t(
                `remarketingVehicleStatus.${RemarketingVehicleStatus.TRANSFERRED}`,
              ),
            },
          ),
          severity: 'error',
          dismissible: true,
        });
        navigate(`../`);
      }
      if (
        response?.responseData.code ===
        TransferResponseCode.AlreadyExistsInDifferentDealer
      ) {
        showMessage({
          text: t(
            `pages.remarketingVehiclePage.header.${TransferResponseCode.AlreadyExistsInDifferentDealer}`,
            {
              value: response?.responseData.dealerName,
            },
          ),
          severity: 'error',
          dismissible: true,
        });
      }

      return response;
    });
  }, [
    currentBrand,
    setTransferState,
    api.remarketing,
    remarketingVehicle.id,
    toggleTransferConfirmOpen,
    showMessage,
    t,
    navigate,
  ]);

  const archiveVehicle = useCallback(async () => {
    if (!currentBrand) {
      return;
    }
    await update({
      brand: currentBrand,
      remarketingVehicleId: remarketingVehicle.id,
    });

    showMessage({
      text: t('pages.remarketingVehiclePage.header.archiveSuccess'),
      severity: 'success',
      dismissible: true,
      duration: 3000,
    });
    toggleArchiveConfirmOpen();
  }, [
    currentBrand,
    remarketingVehicle,
    showMessage,
    t,
    toggleArchiveConfirmOpen,
    update,
  ]);

  const showTransferButton =
    remarketingVehicle.status === RemarketingVehicleStatus.CLOSED &&
    ((remarketingVehicle.type === RemarketingType.AUCTION &&
      !!remarketingVehicle.currentBid?.currentPrice) ||
      (remarketingVehicle.type === RemarketingType.FIXED_PRICE &&
        !!remarketingVehicle.currentOffer?.value));

  const showArchiveButton =
    !archived && afterActiveStatuses.includes(remarketingVehicle.status);

  const showTransferModal = useCallback(
    () => toggleTransferConfirmOpen(true),
    [toggleTransferConfirmOpen],
  );

  const showArchiveModal = useCallback(
    () => toggleArchiveConfirmOpen(true),
    [toggleArchiveConfirmOpen],
  );

  useEffect(() => {
    if (transferState.error) {
      toggleTransferConfirmOpen(false);
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [transferState.error, showMessage, t, toggleTransferConfirmOpen]);

  return (
    <Stack>
      <div>
        <Button
          disabled={isPending}
          onClick={() => {
            navigate(
              {
                pathname: `${archived ? '../archived' : '../'}`,
                search: searchParams.searchParams?.toString(),
              },
              {
                state: { remarketingId: remarketingVehicle.id },
              },
            );
          }}
          size="small"
          startIcon={<ArrowBack />}
          variant="text"
        >
          {t('back')}
        </Button>
      </div>
      <Stack
        direction={{
          xs: 'column-reverse',
          sm: 'row',
        }}
        justifyContent="space-between"
        spacing={2}
      >
        <Stack justifyContent="space-between" spacing={2}>
          <div>
            <Stack alignItems="center" direction="row" spacing={2}>
              <Typography variant="title">{title}</Typography>
              <Label
                bgcolor={
                  archived
                    ? archivedColour.background
                    : remarketingStatusColours[remarketingVehicle.status]
                        .background
                }
              >
                <Typography
                  color={
                    archived
                      ? archivedColour.color
                      : remarketingStatusColours[remarketingVehicle.status]
                          .color
                  }
                  fontWeight="fontWeightBold"
                  textTransform="uppercase"
                  variant="caption"
                >
                  {t(`remarketingVehicleStatus.${remarketingVehicle.status}`)}
                </Typography>
              </Label>
            </Stack>
            <Typography variant="subtitle1">{startingDate}</Typography>
          </div>
          <Stack>
            <Typography variant="subtitle3">{price}</Typography>
            <Typography color="grey.600" variant="body2">{`${(
              otherPrices || []
            ).join(' | ')}`}</Typography>
            <Stack
              direction={{
                xs: 'column',
                md: 'row',
              }}
              justifyContent="space-between"
            >
              <Stack
                direction="row"
                divider={
                  <Divider
                    color="#BDC7D3"
                    orientation="vertical"
                    sx={{
                      width: 2,
                    }}
                  />
                }
                spacing={1}
              >
                {(remarketingVehicle.type === RemarketingType.AUCTION ||
                  remarketingVehicle.type === RemarketingType.FIXED_PRICE) && (
                  <Details vehicle={remarketingVehicle} />
                )}

                {detailsItem.map(({ label, value }, idx) => {
                  return (
                    <React.Fragment key={idx}>
                      <Typography
                        color="grey.600"
                        fontWeight="fontWeightMedium"
                        variant="body2"
                      >
                        {label}
                      </Typography>
                      <Typography
                        color="error.dark"
                        fontWeight="fontWeightMedium"
                        variant="body2"
                      >
                        {value}
                      </Typography>
                    </React.Fragment>
                  );
                })}
              </Stack>
            </Stack>
          </Stack>
          {isEditor && (
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              minHeight={40}
              spacing={2}
            >
              {showTransferButton && (
                <Tooltip
                  arrow
                  title={
                    archived
                      ? t(
                          'pages.remarketingVehiclePage.header.disabledTransfer',
                        )
                      : ''
                  }
                >
                  <span>
                    <Button
                      color="primary"
                      disabled={archived}
                      onClick={showTransferModal}
                      type="button"
                      variant="outlined"
                    >
                      {t('pages.remarketingVehiclePage.header.transferVehicle')}
                    </Button>
                  </span>
                </Tooltip>
              )}
              {showArchiveButton && (
                <span>
                  <Button
                    color="inherit"
                    onClick={showArchiveModal}
                    type="button"
                    variant="outlined"
                  >
                    {t('pages.remarketingVehiclePage.header.archiveVehicle')}
                  </Button>
                </span>
              )}
              <RelistButton remarketingVehicle={remarketingVehicle} />
            </Stack>
          )}
        </Stack>
        <Img
          onClick={() => setShowCarousel(true)}
          src={image}
          sx={{
            cursor: carouselMedia.length ? 'pointer' : 'default',
          }}
        />
      </Stack>
      <ConfirmModal
        busy={transferState.loading}
        cancelText={t('close')}
        fullWidth
        maxWidth="xs"
        onClose={toggleTransferConfirmOpen}
        onConfirm={transferRemarketing}
        open={isTransferConfirmOpen}
        title={t('pages.remarketingVehiclePage.header.transferVehicle')}
      >
        <Typography variant="body2">
          {t('pages.remarketingVehiclePage.header.transferWarning')}
        </Typography>
      </ConfirmModal>
      {!!carouselMedia.length && (
        <ImageCarousel
          media={carouselMedia}
          onClose={() => setShowCarousel(false)}
          open={showCarousel}
        />
      )}
      <ConfirmModal
        busy={isPending}
        cancelText={t('close')}
        fullWidth
        maxWidth="xs"
        onClose={toggleArchiveConfirmOpen}
        onConfirm={archiveVehicle}
        open={isArchiveConfirmOpen}
        title={t('pages.remarketingVehiclePage.header.archiveVehicle')}
      >
        <Typography variant="body2">
          {t('pages.remarketingVehiclePage.header.archiveWarning')}
        </Typography>
      </ConfirmModal>
      {!!carouselMedia.length && (
        <ImageCarousel
          media={carouselMedia}
          onClose={() => setShowCarousel(false)}
          open={showCarousel}
        />
      )}
    </Stack>
  );
};

export default AdminRemarketingVehicleHeader;
