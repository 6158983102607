import {
  Box,
  Container,
  MenuItem,
  Select,
  Stack,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ListRegionCountryResponse } from '../../../api/BrandService/listRegionCountry/ListRegionCountryResponse.js';
import {
  editableStates,
  RemarketingType,
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../api/util/RemarketingVehicle.js';
import DetailsSkeleton from '../../common-ui/components/DetailsSkeleton.js';
import {
  ErrorState,
  FormFieldLabel,
  useMessageStore,
} from '../../common-ui/index.js';
import {
  useApiClient,
  useAsyncState,
  useCurrentUser,
  useMobile,
} from '../../hooks/index.js';
import { useBrand } from '../../hooks/useBrand.js';
import AdminRemarketingVehicleHeader from '../components/RemarketingVehicle/AdminRemarketingVehicleHeader.js';
import Auction from '../components/RemarketingVehicle/Auction.js';
import RemarketingFeatures from '../components/RemarketingVehicle/RemarketingFeatures.js';
import { RemarketingVehicleType } from '../components/RemarketingVehicle/types.js';
import Unassigned from '../components/RemarketingVehicle/Unassigned.js';
import VehicleDetails from '../components/RemarketingVehicle/VehicleDetails.js';
import VehicleMediaSection from '../components/RemarketingVehicle/VehicleMedia.js';
import Section from '../components/Section.js';
import HeaderSkeleton from '../components/Vehicle/HeaderSkeleton.js';
import {
  makeRemarketingVehicleAdminQueryKey,
  useRemarketingVehicleAdmin,
} from '../hooks/queries/useRemarketingVehicleAdmin.js';
import { useRemarketingEditor } from '../hooks/useRemarketingEditor.js';
import { regionCountryOptionsAtom } from '../state/atoms.js';

enum TabOptions {
  Listing = 'Listing',
  Details = 'Details',
  Features = 'Features',
  Media = 'Media',
}

const refreshStatus = [
  RemarketingVehicleStatus.ACTIVE,
  RemarketingVehicleStatus.CLOSED,
  RemarketingVehicleStatus.CANCELLED,
  RemarketingVehicleStatus.TRANSFERRED,
];

const types = [
  RemarketingType.UNASSIGNED,
  RemarketingType.AUCTION,
  RemarketingType.FIXED_PRICE,
];

const RemarketingVehiclePage: FunctionComponent = (): JSX.Element => {
  const { remarketingId, brand, tab } = useParams();
  const { currentBrand, isLoading } = useBrand();
  const { showMessage } = useMessageStore();
  const mobile = useMobile();
  const api = useApiClient();
  const queryClient = useQueryClient();

  const {
    data: vehicleData,
    isError,
    isLoading: loading,
  } = useRemarketingVehicleAdmin(
    currentBrand && remarketingId
      ? {
          brand: currentBrand,
          remarketingVehicleId: remarketingId,
        }
      : undefined,
    { refreshStatus },
  );

  const [currentTab, setCurrentTab] = useState<TabOptions>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [regions, setRegions] = useAsyncState<ListRegionCountryResponse>();
  const { value: currentUser } = useCurrentUser();
  const setRegionOptions = useSetAtom(regionCountryOptionsAtom);
  const isEditor = useRemarketingEditor();
  const readonly =
    !isEditor ||
    !(vehicleData?.status && editableStates.includes(vehicleData?.status));

  const [type, setType] = useState(vehicleData?.type);

  const handleChange = (_: React.SyntheticEvent, newValue: TabOptions) => {
    navigate(`../${newValue}`, { relative: 'path' });
    setCurrentTab(newValue);
  };

  const handleUpdate = useCallback(
    (remarketingVehicle: RemarketingVehicleType) => {
      queryClient.setQueryData<RemarketingVehicle>(
        makeRemarketingVehicleAdminQueryKey({
          brand: remarketingVehicle.vehicle.brand,
          remarketingVehicleId: remarketingVehicle.id,
        }),
        (prev) => {
          if (!prev) {
            return;
          }

          if (prev?.id === remarketingVehicle.id) {
            return {
              ...prev,
              ...(remarketingVehicle as RemarketingVehicle),
            };
          }
        },
      );
      showMessage({
        text: t('pages.remarketingVehiclePage.updatedSuccess'),
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });
    },
    [queryClient, showMessage, t],
  );

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setRegions(async () => {
      const listRegionCountry = await api.brand.listRegionCountry({
        brand: currentBrand,
        regions: currentUser?.policy.RemarketingAdmin as string[],
      });

      setRegionOptions(listRegionCountry);

      return listRegionCountry;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentBrand, currentUser]);

  useEffect(() => {
    const current = Object.values(TabOptions).find(
      (x: string) => x.toLowerCase() === tab?.toLowerCase(),
    );

    if (!current) {
      navigate(`./${TabOptions.Listing}`, { relative: 'path', replace: true });
    }

    setCurrentTab(current);
  }, [navigate, tab]);

  useEffect(() => {
    if (vehicleData?.type) {
      setType(vehicleData.type);
    }
  }, [vehicleData?.type]);

  if (isError) {
    return (
      <Stack alignItems="center" height="100vh" justifyContent="center">
        <ErrorState />
      </Stack>
    );
  }

  if ((loading || regions.loading || isLoading) && !vehicleData) {
    return (
      <Stack gap={10}>
        <HeaderSkeleton />
        <DetailsSkeleton />
      </Stack>
    );
  }

  if (isError || !brand) {
    return (
      <Stack alignItems="center" height="100vh" justifyContent="center">
        <ErrorState />
      </Stack>
    );
  }

  return (
    <div>
      {vehicleData && (
        <div>
          <Stack
            alignItems="center"
            bgcolor="background.paper"
            pt={2}
            spacing={2}
          >
            <Container maxWidth="xl">
              <AdminRemarketingVehicleHeader remarketingVehicle={vehicleData} />
              {/* Tabs */}
              <Box mt={5}>
                <Tabs
                  allowScrollButtonsMobile
                  onChange={handleChange}
                  scrollButtons
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                  value={currentTab}
                  variant={mobile ? 'scrollable' : undefined}
                >
                  <Tab
                    id="remarketing-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t(`listing`)}
                      </Typography>
                    }
                    value={TabOptions.Listing}
                  />
                  <Tab
                    id="details-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('details')}
                      </Typography>
                    }
                    value={TabOptions.Details}
                  />
                  <Tab
                    id="features-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('features')}
                      </Typography>
                    }
                    value={TabOptions.Features}
                  />
                  <Tab
                    id="media-tab"
                    label={
                      <Typography variant="subtitle1">{t('media')}</Typography>
                    }
                    value={TabOptions.Media}
                  />
                </Tabs>
              </Box>
            </Container>
          </Stack>
          {vehicleData && (
            <Container maxWidth="xl">
              <Box mt={3}>
                {currentTab === TabOptions.Listing && (
                  <>
                    <Stack spacing={3}>
                      {!!type && (
                        <Section>
                          <Stack maxWidth={300} spacing={1}>
                            <FormFieldLabel name="type" text={t('type')} />
                            <Select
                              onChange={(e) => {
                                setType(e.target.value as RemarketingType);
                              }}
                              readOnly={readonly}
                              size="small"
                              value={type}
                            >
                              {types.map((type) => (
                                <MenuItem key={type} value={type}>
                                  {t(`remarketingTypes.${type}`)}
                                </MenuItem>
                              ))}
                            </Select>
                          </Stack>
                        </Section>
                      )}
                      {(type === RemarketingType.AUCTION ||
                        type === RemarketingType.FIXED_PRICE) && (
                        <Auction
                          onUpdate={handleUpdate}
                          readonly={readonly}
                          remarketingVehicle={vehicleData}
                          type={type}
                        />
                      )}
                      {type === RemarketingType.UNASSIGNED && (
                        <Unassigned
                          onUpdate={handleUpdate}
                          readonly={readonly}
                          remarketingVehicle={vehicleData}
                        />
                      )}
                    </Stack>
                  </>
                )}
              </Box>
              <Box mt={3}>
                {currentTab === TabOptions.Details && (
                  <VehicleDetails readonly remarketingVehicle={vehicleData} />
                )}
              </Box>
              <Box mt={3}>
                {currentTab === TabOptions.Features && (
                  <RemarketingFeatures
                    onSave={handleUpdate}
                    readonly={readonly}
                    remarketingVehicle={vehicleData}
                  />
                )}
              </Box>
              <Box mt={3}>
                {currentTab === TabOptions.Media && (
                  <VehicleMediaSection
                    onSave={handleUpdate}
                    readonly={readonly}
                    remarketingVehicle={vehicleData}
                  />
                )}
              </Box>
            </Container>
          )}
        </div>
      )}
    </div>
  );
};

export default RemarketingVehiclePage;
