import { Inventory } from '@mui/icons-material';
import {
  Card,
  CardContent,
  CardHeader,
  Grid,
  Paper,
  Stack,
  Typography,
} from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { RegionModelStats } from '../../../api/ReportingService/listRegionModelStats/ListRegionModelStatsResponse.js';
import { sx } from '../../util/index.js';
import MainDrawer from './MainDrawer.js';
import SummaryCard from './SummaryCard.js';
import SummaryModelCardSkeleton from './SummaryModelCardSkeleton.js';

export interface LiveInventoryCardProps {
  data: RegionModelStats[];
  loading?: boolean;
}
interface EntryMapModel {
  model: string;
  [x: string]: string | number;
  total: number;
}

export const styles = sx({
  summaryCard: {
    cursor: 'pointer',
  },
  totalCard: {
    boxShadow: 0,
  },
  summaryPaper: {
    p: 1,
    textAlign: 'center',
    flex: 1,
  },
  carsCount: {
    color: 'text.secondary',
  },
  dataGrid: {
    bgcolor: 'background.paper',
  },
});

const LiveInventoryCard: FunctionComponent<LiveInventoryCardProps> = ({
  data,
  loading,
}) => {
  const { t } = useTranslation();
  const [open, setOpen] = useState(false);
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const [pageSize, setPageSize] = useState<number>(5);

  const [rows, columns, regions] = useMemo(() => {
    //Transform the data: Group by model/region and map regions like properties
    const [rows, regions] = data.reduce(
      ([entryMapModel, entryMapRegions], e) => {
        if (entryMapModel.has(e.model)) {
          const element = entryMapModel.get(e.model);
          entryMapModel.set(e.model, {
            ...element,
            model: e.model,
            [e.regionName]: e.amountOfVehicles,
            total: (element?.total || 0) + e.amountOfVehicles,
          });
        } else {
          entryMapModel.set(e.model, {
            model: e.model,
            [e.regionName]: e.amountOfVehicles,
            total: e.amountOfVehicles,
          });
        }

        if (entryMapRegions.has(e.regionName)) {
          const regionCount = entryMapRegions.get(e.regionName);
          entryMapRegions.set(
            e.regionName,
            (regionCount || 0) + e.amountOfVehicles,
          );
        } else {
          entryMapRegions.set(e.regionName, e.amountOfVehicles);
        }

        return [entryMapModel, entryMapRegions];
      },
      [new Map<string, EntryMapModel>(), new Map<string, number>()],
    );

    const dataGridColumns: GridColDef[] = [
      {
        field: 'model',
        headerName: t('model'),
        flex: 2,
      },
      //Transforms regions to columns
      ...Array.from(new Set(data.map((item) => item.regionName))).map(
        (c): GridColDef => ({
          field: c,
          flex: 1,
          headerAlign: 'center',
          align: 'center',
          description: `${c} (${regions.get(c)})`,
        }),
      ),
      {
        field: 'total',
        headerName: t('total'),
        headerAlign: 'center',
        align: 'center',
        flex: 1,
      },
    ];

    return [Array.from(rows.values()), dataGridColumns, regions];
  }, [data]);

  const handleChangePage = useCallback((newPageSize: number) => {
    setPageSize(newPageSize);
  }, []);

  const subtitle = t('reports.liveInventory.title');
  const title = t('reports.liveInventory.subtitle');

  return (
    <>
      <SummaryCard
        color="info"
        icon={<Inventory />}
        loading={loading}
        onClick={handleOpen}
        subtitle={subtitle}
        sx={styles.summaryCard}
        title={title}
      />
      <MainDrawer onClose={handleClose} open={open} title={subtitle}>
        <Stack spacing={4}>
          <Card sx={styles.totalCard} variant="outlined">
            <CardHeader
              subheader={t('reports.liveInventory.subheader')}
              title={t('summary')}
            />
            <CardContent>
              <Grid container spacing={2}>
                {loading
                  ? Array(4)
                      .fill(0)
                      .map((_, index) => (
                        <Grid item key={index} md={4} sm={6} xl xs={12}>
                          <SummaryModelCardSkeleton />
                        </Grid>
                      ))
                  : Array.from(regions).map(([region, cars]) => (
                      <Grid item key={region} md={4} sm={6} xl xs={12}>
                        <Paper sx={styles.summaryPaper} variant="outlined">
                          <Typography variant="h6" whiteSpace="nowrap">
                            {region}
                          </Typography>
                          <Typography sx={styles.carsCount} variant="h6">
                            {cars}
                          </Typography>
                        </Paper>
                      </Grid>
                    ))}
              </Grid>
            </CardContent>
          </Card>
          <DataGrid
            autoHeight
            columns={columns}
            disableSelectionOnClick
            getRowId={(r) => r.model}
            loading={loading}
            onPageSizeChange={handleChangePage}
            pageSize={pageSize}
            rows={rows}
            rowsPerPageOptions={[5, 10, 25]}
            sx={styles.dataGrid}
          />
        </Stack>
      </MainDrawer>
    </>
  );
};

export default LiveInventoryCard;
