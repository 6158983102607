import {
  QueryKey,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { AdminGetRemarketingVehicleRequest } from '../../../../api/RemarketingService/adminGetRemarketingVehicle/AdminGetRemarketingVehicleRequest.js';
import { AdminGetRemarketingVehicleResponse } from '../../../../api/RemarketingService/adminGetRemarketingVehicle/AdminGetRemarketingVehicleResponse.js';
import { RemarketingVehicleStatus } from '../../../../api/util/RemarketingVehicle.js';
import { RemarketingVehicleEvent } from '../../../../api/util/RemarketingVehicleEvent.js';
import {
  WsChannelType,
  WsRemarketingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { QueryKeys } from './QueryKeys.js';
import { useUpdateRemarketingAdminListItem } from './updateRemarketingAdminListItem.js';

export const makeRemarketingVehicleAdminQueryKey = (
  req?: AdminGetRemarketingVehicleRequest,
): QueryKey =>
  !req ? [] : [QueryKeys.RemarketingVehiclesAdmin, req.remarketingVehicleId];

export function useRemarketingVehicleAdmin(
  req?: AdminGetRemarketingVehicleRequest,
  options?: { refreshStatus?: RemarketingVehicleStatus[] },
): UseQueryResult<AdminGetRemarketingVehicleResponse> {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const updateState = useUpdateRemarketingAdminListItem();

  const newEventIncoming = useWsChannelEvent<RemarketingVehicleEvent>(
    WsRemarketingEvent.remarketingChange,
    WsChannelType.RemarketingAdmin,
  );

  useEffect(() => {
    if (
      !newEventIncoming ||
      !options?.refreshStatus?.includes(newEventIncoming?.status) ||
      !req
    ) {
      return;
    }

    queryClient.setQueryData<RemarketingVehicleEvent>(
      makeRemarketingVehicleAdminQueryKey(req),
      (prev) => {
        if (!prev) {
          return;
        }

        if (prev?.id === newEventIncoming?.id) {
          return {
            ...prev,
            ...newEventIncoming,
          };
        }
      },
    );

    updateState({ newEventIncoming });
  }, [newEventIncoming]);

  return useQuery({
    queryKey: makeRemarketingVehicleAdminQueryKey(req),
    queryFn: async () => {
      if (!req) {
        return;
      }
      return await api.remarketing.adminGetRemarketingVehicle(req);
    },
    enabled: !!req,
    refetchOnWindowFocus: false,
  });
}
