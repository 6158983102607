import { jsonDate, maybe } from '@fmtk/decoders';
import {
  decodeBuyNowRemarketingAction,
  decodeOfferRemarketingAction,
  decodeRemarketingAction,
} from '../util/RemarketingAction.js';
import { decodeRemarketingVehicle } from '../util/RemarketingVehicle.js';
import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { none } from '../util/none.js';
import { decodeAddRemarketingVehicleRequest } from './addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import { decodeAdminAcceptRejectOfferRequest } from './adminAcceptRejectOffer/adminAcceptOfferRequest.js';
import { decodeAdminArchiveRemarketingVehicleRequest } from './adminArchiveRemarketingVehicle/AdminArchiveRemarketingVehicle.js';
import { decodeAdminCancelRemarketingRequest } from './adminCancelRemarketing/AdminCancelRemarketingRequest.js';
import { decodeAdminCountRemarketingVehiclesRequest } from './adminCountRemarketingVehicles/AdminCountRemarketingVehiclesRequest.js';
import { decodeAdminCountRemarketingVehiclesResponse } from './adminCountRemarketingVehicles/AdminCountRemarketingVehiclesResponse.js';
import { decodeAdminGetRemarketingVehicleRequest } from './adminGetRemarketingVehicle/AdminGetRemarketingVehicleRequest.js';
import { decodeAdminGetRemarketingVehicleResponse } from './adminGetRemarketingVehicle/AdminGetRemarketingVehicleResponse.js';
import { decodeAdminListBiddingHistoryRequest } from './adminListBiddingHistory/AdminListBiddingHistoryRequest.js';
import { decodeAdminListBiddingHistoryResponse } from './adminListBiddingHistory/AdminListBiddingHistoryResponse.js';
import { decodeAdminListRemarketingActionsRequest } from './adminListRemarketingActions/AdminListRemarketingActionsRequest.js';
import { decodeAdminListRemarketingActionsResponse } from './adminListRemarketingActions/AdminListRemarketingActionsResponse.js';
import { decodeAdminListRemarketingVehiclesRequest } from './adminListRemarketingVehicles/AdminListRemarketingVehiclesRequest.js';
import { decodeAdminListRemarketingVehiclesResponse } from './adminListRemarketingVehicles/AdminListRemarketingVehiclesResponse.js';
import { decodeAdminRelistRemarketingVehicleRequest } from './adminRelistRemarketingVehicle/AdminRelistRemarketingVehicleRequest.js';
import { decodeAdminTransferRemarketingVehicleRequest } from './adminTransferRemarketingVehicle/AdminTransferRemarketingVehicleRequest.js';
import { decodeAdminTransferRemarketingVehicleResponse } from './adminTransferRemarketingVehicle/AdminTransferRemarketingVehicleResponse.js';
import { decodeUpdateRemarketingVehicleRequest } from './adminUpdateRemarketingVehicle/UpdateRemarketingVehicleRequest.js';
import { decodeGetAuctionVehicleSummaryRequest } from './getRemarketingVehicleSummary/GetAuctionVehicleSummaryRequest.js';
import { decodeGetAuctionVehicleSummaryResponse } from './getRemarketingVehicleSummary/GetAuctionVehicleSummaryResponse.js';
import { decodeGetVehicleForRemarketingRequest } from './getVehicleForRemarketing/GetVehicleForRemarketingRequest.js';
import { decodeGetVehicleForRemarketingResponse } from './getVehicleForRemarketing/GetVehicleForRemarketingResponse.js';
import { decodeIncreaseRemarketingVehicleViewsRequest } from './increaseRemarketingVehicleViews/IncreaseRemarketingVehicleViewsRequest.js';
import { decodeUserAddToWatchlistRequest } from './userAddToWatchlist/UserAddToWatchlistRequest.js';
import { decodeUserBuyNowRequest } from './userBuyNow/UserBuyNowRequest.js';
import { decodeUserCountRemarketingVehiclesRequest } from './userCountRemarketingVehicles/UserCountRemarketingVehiclesRequest.js';
import { decodeUserCountRemarketingVehiclesResponse } from './userCountRemarketingVehicles/UserCountRemarketingVehiclesResponse.js';
import { decodeUserDeleteFromWatchlistRequest } from './userDeleteFromWatchlist/UserDeleteFromWatchlistRequest.js';
import { decodeUserGetRemarketingActionsRequest } from './userGetRemarketingAction/UserGetRemarketingActionRequest.js';
import { decodeUserGetRemarketingActionsResponse } from './userGetRemarketingAction/UserGetRemarketingActionResponse.js';
import { decodeUserGetRemarketingVehicleRequest } from './userGetRemarketingVehicle/UserGetRemarketingVehicleRequest.js';
import { decodeUserGetRemarketingVehicleResponse } from './userGetRemarketingVehicle/UserGetRemarketingVehicleResponse.js';
import { decodeUserListBiddingHistoryRequest } from './userListBiddingHistory/UserListBiddingHistoryRequest.js';
import { decodeUserListBiddingHistoryResponse } from './userListBiddingHistory/UserListBiddingHistoryResponse.js';
import { decodeUserListRemarketingActionsRequest } from './userListRemarketingActions/UserListRemarketingActionsRequest.js';
import { decodeUserListRemarketingActionsResponse } from './userListRemarketingActions/UserListRemarketingActionsResponse.js';
import { decodeUserListRemarketingVehiclesRequest } from './userListRemarketingVehicles/UserListRemarketingVehiclesRequest.js';
import { decodeUserListRemarketingVehiclesResponse } from './userListRemarketingVehicles/UserListRemarketingVehiclesResponse.js';
import { decodeUserSubmitAuctionBidRequest } from './userSubmitAuctionBid/UserSubmitAuctionBidRequest.js';
import { decodeUserSubmitCommentRequest } from './userSubmitComment/UserSubmitCommentRequest.js';
import { decodeUserSubmitOfferRequest } from './userSubmitOffer/userSubmitOfferRequest.js';

export const RemarketingServiceDef = service({
  adminAddRemarketingVehicle: method(
    decodeAddRemarketingVehicleRequest(jsonDate),
    decodeRemarketingVehicle,
    [UserPermissions.Basic],
  ),
  adminRelistRemarketingVehicle: method(
    decodeAdminRelistRemarketingVehicleRequest,
    none,
    [UserPermissions.Basic],
  ),
  adminUpdateRemarketingVehicle: method(
    decodeUpdateRemarketingVehicleRequest,
    decodeRemarketingVehicle,
    [UserPermissions.Basic],
  ),
  adminGetRemarketingVehicle: method(
    decodeAdminGetRemarketingVehicleRequest,
    decodeAdminGetRemarketingVehicleResponse,
    [UserPermissions.Basic],
  ),
  adminTransferRemarketingVehicle: method(
    decodeAdminTransferRemarketingVehicleRequest,
    maybe(decodeAdminTransferRemarketingVehicleResponse),
    [UserPermissions.Basic, UserPermissions.RemarketingAdmin],
  ),
  userListRemarketingVehicles: method(
    decodeUserListRemarketingVehiclesRequest,
    decodeUserListRemarketingVehiclesResponse,
    [UserPermissions.Basic],
  ),
  userGetRemarketingAction: method(
    decodeUserGetRemarketingActionsRequest,
    decodeUserGetRemarketingActionsResponse,
    [UserPermissions.Basic],
  ),
  userListRemarketingActions: method(
    decodeUserListRemarketingActionsRequest,
    decodeUserListRemarketingActionsResponse,
    [UserPermissions.Basic],
  ),
  userGetRemarketingVehicle: method(
    decodeUserGetRemarketingVehicleRequest,
    decodeUserGetRemarketingVehicleResponse,
    [UserPermissions.Basic],
  ),
  userSubmitAuctionBid: method(
    decodeUserSubmitAuctionBidRequest,
    decodeRemarketingAction,
    [UserPermissions.Basic],
  ),
  userSubmitComment: method(
    decodeUserSubmitCommentRequest,
    decodeRemarketingAction,
    [UserPermissions.Basic],
  ),
  userAddToWatchlist: method(
    decodeUserAddToWatchlistRequest,
    decodeRemarketingAction,
    [UserPermissions.Basic],
  ),
  userDeleteFromWatchlist: method(
    decodeUserDeleteFromWatchlistRequest,
    decodeRemarketingAction,
    [UserPermissions.Basic],
  ),
  adminCancelRemarketing: method(
    decodeAdminCancelRemarketingRequest,
    decodeRemarketingAction,
    [UserPermissions.Basic],
  ),
  userCountRemarketingVehicles: method(
    decodeUserCountRemarketingVehiclesRequest,
    decodeUserCountRemarketingVehiclesResponse,
    [UserPermissions.Basic, UserPermissions.RemarketingDealer],
  ),
  adminCountRemarketingVehicles: method(
    decodeAdminCountRemarketingVehiclesRequest,
    decodeAdminCountRemarketingVehiclesResponse,
    [UserPermissions.Basic, UserPermissions.RemarketingAdmin],
  ),
  adminListRemarketingActions: method(
    decodeAdminListRemarketingActionsRequest,
    decodeAdminListRemarketingActionsResponse,
    [UserPermissions.Basic],
  ),
  getVehicleForRemarketing: method(
    decodeGetVehicleForRemarketingRequest,
    decodeGetVehicleForRemarketingResponse,
  ),
  listRemarketingVehiclesForAdmin: method(
    decodeAdminListRemarketingVehiclesRequest,
    decodeAdminListRemarketingVehiclesResponse,
    [UserPermissions.Basic],
  ),
  getAuctionVehicleSummary: method(
    decodeGetAuctionVehicleSummaryRequest,
    decodeGetAuctionVehicleSummaryResponse,
    [UserPermissions.Basic],
  ),
  userListBiddingHistory: method(
    decodeUserListBiddingHistoryRequest,
    decodeUserListBiddingHistoryResponse,
    [UserPermissions.Basic],
  ),
  adminListBiddingHistory: method(
    decodeAdminListBiddingHistoryRequest,
    decodeAdminListBiddingHistoryResponse,
    [UserPermissions.Basic],
  ),
  increaseRemarketingVehicleViews: method(
    decodeIncreaseRemarketingVehicleViewsRequest,
    none,
    [UserPermissions.Basic, UserPermissions.RemarketingDealer],
  ),
  userBuyNow: method(decodeUserBuyNowRequest, decodeBuyNowRemarketingAction, [
    UserPermissions.Basic,
  ]),
  userSubmitOffer: method(
    decodeUserSubmitOfferRequest,
    decodeOfferRemarketingAction,
    [UserPermissions.Basic],
  ),
  adminAcceptRejectOffer: method(decodeAdminAcceptRejectOfferRequest, none, [
    UserPermissions.Basic,
  ]),
  adminArchiveRemarketingVehicle: method(
    decodeAdminArchiveRemarketingVehicleRequest,
    decodeRemarketingVehicle,
    [UserPermissions.Basic],
  ),
});

export type RemarketingServiceDef = typeof RemarketingServiceDef;
