import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { vehiclePhotoUrls } from '../../../../../util/vehicleMediaHelper.js';
import { DealerSelectionType } from '../../../../hooks/useDealer.js';
import { dashIfUndefined } from '../../../../util/dashIfUndefined.js';
import { formatDate } from '../../../../util/dates.js';
import {
  formatMaxSpeed,
  formatPrice,
  fromMpgToLitresPer100,
} from '../../../../util/formatters.js';
import { VehicleHandDrive } from '../../../../util/HandDrive.js';
import { formatNumber } from '../../../../util/numbers.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';
import TruncatedText from '../TruncatedText.js';
import { Bentley } from './fonts.js';
import PdfHeader from './PdfHeader.js';

export interface DetailPortraitPdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
  dealer?: DealerSelectionType;
}
Font.register(Bentley);

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 50,
    paddingVertical: 10,
    fontFamily: 'Bentley',
    fontSize: 12,
    color: '#000000',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 18,
    flexDirection: 'row',
    textTransform: 'uppercase',
    justifyContent: 'space-between',
    fontWeight: 'light',
    paddingTop: 20,
    paddingBottom: 5,
  },
  detailsContainer: {
    fontSize: 9,
    marginBottom: 12,
  },
  marginRight10: {
    marginRight: 10,
  },
  section: {
    flexDirection: 'row',
  },
  fifty: {
    width: '50%',
  },
  column: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  flexRow: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flexColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  padding: {
    padding: 4,
  },
  sectionItem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontWeight: 'light',
  },
  sectionTitle: {
    color: '#000000',
    paddingBottom: 4,
    letterSpacing: 0.5,
    fontWeight: 400,
    fontSize: 15,
  },
  sectionItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  sectionItemsLeftColumn: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
  },
  sectionItemsMiddleColumn: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
    padding: '0 20px',
  },
  sectionItemsRightColumn: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
  },
  lightDivider: {
    width: '100%',
    marginVertical: 16,
    borderBottom: '1px solid #cecece',
  },
  features: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  featureItem: {
    width: '23%',
    marginRight: 8,
    marginBottom: 2,
    fontWeight: 'light',
  },
  certifiedImage: {
    height: 30,
    width: 'auto',
  },
  image: {
    height: 'auto',
    width: 'auto',
    maxWidth: 124,
    flexGrow: 1,
  },
  sectionFifty: {
    width: '50%',
  },
  legend: {
    fontSize: 15,
    marginBottom: 8,
    fontWeight: 'light',
  },
  note: {
    fontSize: 9,
    color: '#222222',
  },
  dealerComments: {
    color: '#222222',
    fontWeight: 'light',
  },
});

interface SectionItemProps {
  label: string;
  value?: string;
}

const SectionItem = ({ label, value = '-' }: SectionItemProps): JSX.Element => (
  <View style={styles.sectionItem}>
    <Text>{label}:</Text>
    <Text>{value}</Text>
  </View>
);

interface SectionProps {
  children: React.ReactElement;
  title: string;
}

const Section = ({ children, title }: SectionProps): JSX.Element => (
  <View style={styles.detailsContainer}>
    <Text style={styles.sectionTitle}>{title}</Text>
    {children}
  </View>
);

const Features = ({ features }: { features: string[] }): JSX.Element => (
  <View style={styles.features}>
    {features.map((feature, i) => (
      <View key={i} style={styles.featureItem}>
        <Text>{i > 10 ? 'others...' : feature}</Text>
      </View>
    ))}
  </View>
);

export const DetailPortraitPdf: FunctionComponent<DetailPortraitPdfProps> = ({
  vehicle,
  pdfConfig,
  dealer,
}) => {
  const { noVehicleImage } = pdfConfig;

  const vehicleMedia = vehiclePhotoUrls(
    vehicle.vehicleMedia,
    't_base_vehicle_transformation/f_jpg',
  );

  const features = vehicle.vehicle.features
    ? (
        Object.values(vehicle.vehicle.features)
          .flat()
          .filter(Boolean) as string[]
      )?.slice(0, 12)
    : [];

  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <PdfHeader dealer={dealer} />

        {/* VEHICLE IMAGES */}
        <View style={styles.section}>
          <View style={{ height: 279, flexDirection: 'row' }}>
            <View style={{ maxWidth: 370, flexGrow: 1 }}>
              <Image
                src={vehicleMedia[0] || noVehicleImage}
                style={{
                  width: 'auto',
                  height: 279,
                  maxWidth: 370,
                }}
              />
            </View>
            <View style={{ width: 124, flexDirection: 'column' }}>
              <View style={styles.image}>
                <Image src={vehicleMedia[1] || noVehicleImage} />
              </View>
              <View style={styles.image}>
                <Image src={vehicleMedia[2] || noVehicleImage} />
              </View>
              <View style={styles.image}>
                <Image src={vehicleMedia[3] || noVehicleImage} />
              </View>
            </View>
          </View>
        </View>

        {/* VEHICLE NAME  */}
        <View style={styles.name}>
          <View style={{ width: '80%' }}>
            <Text>{`${vehicle.vehicle.modelYear} ${vehicle.vehicle.model.name}`}</Text>
          </View>

          <View
            style={{
              flexDirection: 'column',
              alignItems: 'flex-end',
              width: '20%',
            }}
          >
            <Text>
              {formatPrice(vehicle.price?.retail, vehicle.currency?.code)}
            </Text>
            {pdfConfig.certifiedLogo &&
              vehicle.syndicationType === SyndicationType.Certified && (
                <Image
                  src={pdfConfig.certifiedLogo}
                  style={styles.certifiedImage}
                />
              )}
          </View>
        </View>

        {/* SUMMARY INFO */}
        <Section title="Summary Info">
          <View style={styles.sectionItemsContainer}>
            <View style={styles.sectionItemsLeftColumn}>
              {[
                {
                  label: 'Exterior Paint',
                  value: dashIfUndefined(
                    vehicle.vehicle.appearanceOptions?.exteriorColour,
                  ),
                },
                {
                  label: 'Veneer',
                  value: dashIfUndefined(
                    vehicle.vehicle.appearanceOptions?.veneer,
                  ),
                },
                {
                  label: 'Engine',
                  value: dashIfUndefined(vehicle.vehicle.engine?.cylinders),
                },
                {
                  label: 'Drive Position',
                  value: dashIfUndefined(
                    vehicle.vehicle.handDrive
                      ? VehicleHandDrive[vehicle.vehicle.handDrive]
                      : undefined,
                  ),
                },
              ].map((item) => (
                <SectionItem
                  key={item.label}
                  label={item.label}
                  value={item.value.toString()}
                />
              ))}
            </View>
            <View style={styles.sectionItemsMiddleColumn}>
              {[
                {
                  label: 'Main Hide',
                  value: dashIfUndefined(
                    vehicle.vehicle.appearanceOptions?.interiorColour,
                  ),
                },
                {
                  label: 'Power',
                  value: dashIfUndefined(vehicle.vehicle.engine?.bhp),
                },
                {
                  label: 'Odometer',
                  value: `${dashIfUndefined(
                    vehicle.odometer?.value
                      ? formatNumber(vehicle.odometer.value)
                      : undefined,
                  )} ${vehicle.odometer?.units}`,
                },
                {
                  label: 'Registration Date',
                  value: dashIfUndefined(
                    vehicle.vehicle.registrationDate
                      ? formatDate(vehicle.vehicle.registrationDate)
                      : undefined,
                  ),
                },
              ].map((item) => (
                <SectionItem
                  key={item.label}
                  label={item.label}
                  value={String(item.value)}
                />
              ))}
            </View>
            <View style={styles.sectionItemsRightColumn}>
              {[
                {
                  label: 'Secondary Hide',
                  value: dashIfUndefined(
                    vehicle.vehicle.appearanceOptions?.secondaryInteriorColour,
                  ),
                },
                {
                  label: 'Maximum Speed',
                  value: formatMaxSpeed(
                    vehicle.vehicle.techSpecs?.maxSpeedMph,
                    vehicle.vehicle.techSpecs?.maxSpeedKph,
                    {
                      style: 'MPH(KPH)',
                    },
                  ),
                },
                {
                  label: 'Body Style',
                  value: dashIfUndefined(vehicle.vehicle.bodyStyle?.name),
                },
                { label: 'VIN', value: vehicle.vehicle.vin },
              ].map((item) => (
                <SectionItem
                  key={item.label}
                  label={item.label}
                  value={item.value}
                />
              ))}
            </View>
          </View>
        </Section>
        <Section title="Additional Features">
          <Features features={features} />
        </Section>
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.sectionFifty}>
            <Section title="Consumption">
              <View style={[styles.flexColumn, styles.marginRight10]}>
                {[
                  {
                    label: 'Fuel consumption',
                    value: `${fromMpgToLitresPer100(
                      vehicle.vehicle.wltpTest?.consumptionCombined || 0,
                    )} 1/100km (WLTP)`,
                  },
                  {
                    label: 'CO2 emissions',
                    value: `${
                      vehicle.vehicle.wltpTest?.co2Emissions || 0
                    } g/km (WLTP)`,
                  },
                ].map((item) => (
                  <SectionItem
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </View>
            </Section>
          </View>

          <View style={styles.sectionFifty}>
            {vehicle.publicComments && (
              <Section title="Dealer Comments">
                <TruncatedText
                  maxLength={350}
                  style={styles.dealerComments}
                  text={vehicle.publicComments || 'No comments added.'}
                />
              </Section>
            )}
          </View>
        </View>
        <View style={styles.legend}>
          <Text>
            We obsess over every detail. Because we know you will too.
          </Text>
        </View>
        <View style={styles.note}>
          <Text>
            Only your Bentley Pre-Owned Retailer can offer you the confidence
            that your car is exactly as was intended. Inspected and tested by
            Bentley trained technicians, we’ll provide you with a fully
            validated service history and check it against the original build
            specification to ensure authenticity.
          </Text>
        </View>
      </Page>
    </Document>
  );
};

export default DetailPortraitPdf;
