import { array, enumValue, object, string } from '@fmtk/decoders';
import {
  MediaTarget,
  VehicleMediaType,
  decodeMediaTarget,
} from '../../util/VehicleMedia.js';

export interface RejectMediaRequest {
  vinMd5Hash: string;
  targets: MediaTarget[];
  rejectionReason: string;
  vehicleMediaType: VehicleMediaType;
}

export const decodeRejectMediaRequest = object<RejectMediaRequest>({
  vinMd5Hash: string,
  targets: array(decodeMediaTarget),
  rejectionReason: string,
  vehicleMediaType: enumValue(VehicleMediaType),
});
