import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { BuyNowRemarketingAction } from '../../../../../../api/util/RemarketingAction.js';
import { RemarketingVehicleStatus } from '../../../../../../api/util/RemarketingVehicle.js';
import { UserFixedPriceRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { AvailableBrands } from '../../../../../../api/util/index.js';
import { useMessageStore } from '../../../../../common-ui/index.js';
import { useApiClient, useAsyncState } from '../../../../../hooks/index.js';
import { useDownloadDocument } from '../../../../../hooks/useDownloadDocument.js';
import { sx } from '../../../../../util/sx.js';

interface BuyNowModalProps {
  open: boolean;
  remarketingVehicle: UserFixedPriceRemarketingVehicle;
  dealerId: number;
  brand: AvailableBrands;
  onClose: () => void;
  onSubmit?: (action: BuyNowRemarketingAction) => void;
  fullScreen?: boolean;
  isLoading: boolean;
}

const styles = sx({
  content: {
    minWidth: {
      xs: 'auto',
      sm: 250,
    },
  },
});

const BidModal: FunctionComponent<BuyNowModalProps> = ({
  open,
  remarketingVehicle,
  dealerId,
  brand,
  onClose,
  onSubmit,
  fullScreen,
  isLoading,
}) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const [newAction, setNewAction] = useAsyncState<BuyNowRemarketingAction>();
  const [, downloadDocument] = useDownloadDocument();

  const onClick = () => {
    setNewAction(async () => {
      const result = await api.remarketing.userBuyNow({
        brand,
        dealerId,
        remarketingVehicleId: remarketingVehicle.id,
      });

      showMessage({
        text: t('pages.remarketingVehiclePage.fixedPrice.buyNowSuccess'),
        severity: 'success',
        duration: 8000,
      });
      onSubmit && onSubmit(result);
      return result;
    });
  };

  const loading = isLoading || !!newAction.loading;

  return (
    <Dialog
      disableEnforceFocus
      disableEscapeKeyDown
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          pr: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <DialogTitle>{t('buyNow')}</DialogTitle>
        <IconButton
          aria-label="close"
          disabled={loading}
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent sx={styles.content}>
        <Stack spacing={3}>
          {remarketingVehicle.termsAndConditions && (
            <Typography variant="body2">
              <Trans
                i18nKey="pages.remarketingVehiclePage.fixedPrice.userTermsAndConditions"
                t={t}
              >
                x
                <Link
                  color="primary"
                  onClick={() => {
                    if (!remarketingVehicle.termsAndConditions) {
                      return;
                    }
                    downloadDocument(remarketingVehicle.termsAndConditions);
                  }}
                  underline="hover"
                  variant="button"
                ></Link>
              </Trans>
            </Typography>
          )}
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            spacing={2}
          >
            <Button
              color="inherit"
              disabled={loading}
              fullWidth
              onClick={onClose}
              variant="outlined"
            >
              {t('cancel')}
            </Button>
            <LoadingButton
              disabled={
                remarketingVehicle.status !== RemarketingVehicleStatus.ACTIVE
              }
              fullWidth
              loading={loading}
              onClick={onClick}
              variant="contained"
            >
              {t('buyNow')}
            </LoadingButton>
          </Stack>
        </Stack>
      </DialogContent>
    </Dialog>
  );
};

export default BidModal;
