import { Notifications } from '@mui/icons-material';
import {
  Badge,
  ClickAwayListener,
  IconButton,
  Paper,
  Popper,
  Stack,
  styled,
} from '@mui/material';
import { FunctionComponent, useState } from 'react';
import { sx } from '../../../util/sx.js';
import { useListInAppNotifications } from '../../hooks/queries/useListInappNotifications.js';
import NotificationSettings from './NotificationSettings.js';
import { NotificationsList } from './NotificationsList.js';

const styles = sx({
  container: {
    p: 2,
    mr: 4,
    pr: 0,
  },
  modal: {
    zIndex: (theme) => theme.zIndex.appBar + 1,
  },
});

const UnreadBadge = styled(Badge)({
  '& .MuiBadge-badge': {
    right: 2,
    top: 8,
  },
});

const NotificationsContainer: FunctionComponent = () => {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openSettings, setOpenSettings] = useState(false);

  const { data } = useListInAppNotifications(false);

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const onClickAway = () => {
    setAnchorEl(null);
  };
  const onNotificationSettingsClick = () => {
    setOpenSettings((state) => !state);
  };

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Stack direction="row" justifyContent="end" p={2} spacing={1}>
        <UnreadBadge
          badgeContent={data?.pages[0]?.unreadCount}
          color="error"
          max={99}
        >
          <IconButton onClick={onClick}>
            <Notifications />
          </IconButton>
        </UnreadBadge>
        <Popper anchorEl={anchorEl} open={!!anchorEl} sx={styles.modal}>
          <Paper elevation={3} sx={styles.container}>
            {openSettings ? (
              <NotificationSettings onCancel={onNotificationSettingsClick} />
            ) : (
              <NotificationsList
                onNotificationClick={onClickAway}
                onSettingsClick={onNotificationSettingsClick}
              />
            )}
          </Paper>
        </Popper>
      </Stack>
    </ClickAwayListener>
  );
};

export default NotificationsContainer;
