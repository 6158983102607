import { useSortable } from '@dnd-kit/sortable';
import { Cameraswitch } from '@mui/icons-material';
import { Box, styled } from '@mui/material';
import { PropsWithChildren } from 'react';

const Overlay = styled('div')({
  position: 'absolute',
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgba(119, 221, 119, 0.5)', // green with 50% opacity
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  zIndex: 1,
});

export function SortableItem(
  props: PropsWithChildren & {
    id: string;
    disabled?: boolean;
    insertPosition?: 'before' | 'after';
    isPrimary?: boolean;
  },
) {
  const {
    attributes,
    listeners,
    setNodeRef,
    transition,
    index,
    activeIndex,
    over,
    isDragging,
    isOver,
  } = useSortable({
    id: props.id,
    disabled: props.disabled,
    transition: {
      duration: 500, // milliseconds
      easing: 'cubic-bezier(0.25, 1, 0.5, 1)',
    },
  });

  const styles = {
    transition: transition ?? '',
    position: 'relative',
    boxShadow:
      'rgb(0 0 0 / 20%) 0 3px 3px -2px, rgb(0 0 0 / 14%) 0 3px 4px 0, rgb(0 0 0 / 12%) 0 1px 8px 0',
    ...(over?.id === props.id && !isDragging && !props.isPrimary
      ? index > activeIndex
        ? {
            borderRight: '4px solid blue',
            paddingRight: '17px',
          }
        : {
            borderLeft: '4px solid blue',
            paddingLeft: '17px',
          }
      : {}),
  };

  return (
    <Box ref={setNodeRef} {...attributes} {...listeners} sx={styles}>
      {props.isPrimary && isOver && (
        <Overlay>
          <Cameraswitch fontSize="large" />
        </Overlay>
      )}
      {props.children}
    </Box>
  );
}
