import { ChartOptions } from 'chart.js';
import { t } from 'i18next';

export const options: ChartOptions<'bar'> = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.region'),
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.noOfVehicles'),
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any): string | string[] =>
          `${context.dataset.label}: ${context.formattedValue}`,
      },
    },
  },
};
