import {
  boolean,
  enumValue,
  maybe,
  object,
  string,
  text,
} from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface AdminAcceptRejectOfferRequest {
  brand: AvailableBrands;
  comment?: string;
  remarketingVehicleId: string;
  actionId: string;
  acceptOffer: boolean;
}

export const decodeAdminAcceptRejectOfferRequest =
  object<AdminAcceptRejectOfferRequest>({
    brand: enumValue(AvailableBrands),
    comment: maybe(string),
    remarketingVehicleId: text,
    actionId: text,
    acceptOffer: boolean,
  });
