import { Document, Font, Image, Page, Text, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { vehiclePhotoUrls } from '../../../../../util/vehicleMediaHelper.js';
import { DealerSelectionType } from '../../../../hooks/useDealer.js';
import { filterFalsey } from '../../../../util/filterFalsey.js';
import {
  formatEngineCapacity,
  formatPrice,
} from '../../../../util/formatters.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import MosaicGallery from '../MosaicGallery.js';
import { ReportTypeBase } from '../makePDF.js';
import getStyles from './DetailPortraitStyles.js';
import HeaderLogo from './HeaderLogo.js';
import ModelLogo from './ModelLogo.js';
import { Jost, RivieraNights, ZCool } from './fonts.js';

export interface DetailPortraitPdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
  dealer?: DealerSelectionType;
  DealerQrCode?: string;
}
Font.register(RivieraNights);
Font.register(Jost);
Font.register(ZCool);

export const DetailPortraitPdf: FunctionComponent<DetailPortraitPdfProps> = ({
  vehicle,
  DealerQrCode,
  t,
  pdfConfig,
}) => {
  const vehicleMedia = vehiclePhotoUrls(
    vehicle.vehicleMedia,
    'ar_4:3,b_auto:border_gradient:4,c_auto_pad,g_auto,f_jpg',
  );

  const highlightedFeaturesCol1 =
    vehicle.vehicle.features &&
    vehicle.vehicle.features['Bespoke Features'] &&
    vehicle.vehicle.features['Bespoke Features'].slice(0, 5);
  const highlightedFeaturesCol2 =
    vehicle.vehicle.features &&
    vehicle.vehicle.features['Bespoke Features'] &&
    vehicle.vehicle.features['Bespoke Features'].slice(5, 10);
  const highlightedFeaturesCol3 =
    vehicle.vehicle.features &&
    vehicle.vehicle.features['Bespoke Features'] &&
    vehicle.vehicle.features['Bespoke Features'].slice(10, 14);

  const dealerEmailAddress = vehicle.dealer.email;

  const styles = getStyles(vehicle.dealer);

  const galleryMedia = filterFalsey(vehicleMedia).slice(0, 40);

  return (
    <Document>
      <Page size={[595, 842]}>
        <View style={styles.header}>
          <HeaderLogo logo="https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1598353351/rolls-royce/RR_Monogram_RGB_POS_.jpeg" />
        </View>
        <View style={styles.header}>
          <ModelLogo model={vehicle.vehicle.model?.name} />
        </View>
        <View style={styles.main}>
          <View style={styles.priceImageContainer}>
            <View style={styles.priceContainer}>
              <Text style={styles.headingThree}>PRICE INCLUDING OPTIONS</Text>
              <View style={styles.price}>
                <Text>
                  {formatPrice(vehicle.price?.retail, vehicle.currency?.code) ||
                    'POA'}
                </Text>
                {vehicle.price?.netPrice && (
                  <Text>
                    {t('terms.netPrice', {
                      value: formatPrice(
                        vehicle.price?.netPrice,
                        vehicle.currency?.code,
                      ),
                    })}
                  </Text>
                )}
                {vehicle.price?.german25aTaxSalesDisclaimer && (
                  <Text>{t('25aTaxSalesDisclaimer')}</Text>
                )}
              </View>
            </View>
            <View style={styles.imagesContainer}>
              {vehicleMedia[0] && (
                <Image src={vehicleMedia[0]} style={styles.mainImage} />
              )}
              <View style={styles.smallerImagesContainer}>
                {vehicleMedia[1] && (
                  <Image src={vehicleMedia[1]} style={styles.smallerImages} />
                )}
                {vehicleMedia[2] && (
                  <Image src={vehicleMedia[2]} style={styles.smallerImages} />
                )}
                {vehicleMedia[3] && (
                  <Image src={vehicleMedia[3]} style={styles.smallerImages} />
                )}
              </View>
            </View>
          </View>
          <View style={styles.designSectionContainer}>
            <View style={styles.designSectionColumn}>
              <Text style={styles.headingThree}>EXTERIOR</Text>
              <Text style={styles.designDetails}>
                {vehicle.vehicle.appearanceOptions?.exteriorColour || ''}
              </Text>
            </View>
            <View style={styles.designSectionColumn}>
              <Text style={styles.headingThree}>INTERIOR</Text>
              <Text style={styles.designDetails}>
                {vehicle.vehicle.appearanceOptions?.interiorColour || ''}
              </Text>
            </View>
            <View style={styles.designSectionColumn}>
              <Text style={styles.headingThree}>VENEER</Text>
              <Text style={styles.designDetails}>
                {vehicle.vehicle.appearanceOptions?.veneer || ''}
              </Text>
            </View>
          </View>
          <View style={styles.highlightedFeaturesContainer}>
            <Text style={styles.headingThree}>HIGHLIGHTED FEATURES</Text>
            <View style={styles.listContainer}>
              <View style={styles.list}>
                {highlightedFeaturesCol1 &&
                  highlightedFeaturesCol1.map((feature, index) => (
                    <Text key={index} style={styles.listItem}>
                      {feature}
                    </Text>
                  ))}
              </View>
              <View style={styles.list}>
                {highlightedFeaturesCol2 &&
                  highlightedFeaturesCol2.map((feature, index) => (
                    <Text key={index} style={styles.listItem}>
                      {feature}
                    </Text>
                  ))}
              </View>
              <View style={styles.list}>
                {highlightedFeaturesCol3 &&
                  highlightedFeaturesCol3.map((feature, index) => (
                    <Text key={index} style={styles.listItem}>
                      {feature}
                    </Text>
                  ))}
              </View>
            </View>
          </View>
        </View>
        <View style={styles.specsAndDealerContainer}>
          <View style={styles.specAndDealerContent}>
            <View style={{ ...styles.specAndDealerCol, marginRight: '10px' }}>
              <Text style={styles.headingThree}>SPECIFICATION</Text>
              <View style={styles.specsRow}>
                <Text style={styles.specItemMedium}>Odometer</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemMedium}>
                  {vehicle.odometer
                    ? `${vehicle.odometer.value} ${vehicle.odometer.units}`
                    : ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemMedium}>Model Year</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemMedium}>
                  {vehicle.vehicle.modelYear || ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemLight}>Registration Date</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemLight}>
                  {vehicle.vehicle.registrationYear || ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemLight}>Body Style</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemLight}>
                  {vehicle.vehicle.bodyStyle?.name || ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemLight}>Capacity</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemLight}>
                  {vehicle.vehicle.engine?.capacity
                    ? formatEngineCapacity(vehicle.vehicle.engine.capacity)
                    : ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemLight}>Engine</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemLight}>
                  {vehicle.vehicle.engine?.fuelType || ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemLight}>Transmission</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemLight}>
                  {vehicle.vehicle.transmission?.name || ''}
                </Text>
              </View>
              <View style={styles.specsRow}>
                <Text style={styles.specItemLight}>LHD / RHD</Text>
                <View style={styles.specsSpacing}></View>
                <Text style={styles.specItemLight}>
                  {vehicle.vehicle.handDrive || ''}
                </Text>
              </View>
            </View>
            <View style={{ ...styles.specAndDealerCol, marginLeft: '10px' }}>
              <Text style={styles.headingThree}>DEALER INFORMATION</Text>
              <View style={styles.dealerRow}>
                <View style={styles.dealerDetails}>
                  <Text
                    style={[styles.specItemMedium, styles.fallBackFontFamily]}
                  >
                    {vehicle.dealer.name || ''}
                  </Text>
                  <Text style={styles.specItemLight}>
                    {vehicle.dealer.website || ''}
                  </Text>
                  {vehicle.dealer.address ? (
                    <Text
                      id="dealer-address"
                      style={[styles.specItemLight, styles.fallBackFontFamily]}
                    >
                      {vehicle.dealer.address}
                    </Text>
                  ) : (
                    ''
                  )}
                  <Text style={styles.specItemLight}>
                    {vehicle.dealer.phone || ''}
                  </Text>
                  <Text style={styles.specItemLight}>
                    {dealerEmailAddress?.[0] || ''}
                  </Text>
                </View>
                {vehicle.dealer.website && (
                  <Image
                    src={DealerQrCode}
                    style={{ width: '45px', height: '45px' }}
                  ></Image>
                )}
              </View>
            </View>
          </View>
        </View>
        {vehicle.syndicationType === SyndicationType.Certified && (
          <View style={styles.provenanceContainer}>
            <Image
              src="https://res.cloudinary.com/motortrak/rolls-royce/RR_Provenance_PDF_LOGO.jpg"
              style={styles.provenanceLogo}
            ></Image>
            <Text style={styles.provenance}>
              Our legendary and unrivalled attention to detail is no different
              for a previously-owned Rolls-Royce, than it is for the motor cars
              leaving Goodwood today. It begins with the selection of the
              Provenance motor car itself. We only feature those that have been
              meticulously cared for and maintained at the highest level, with
              an exhaustive written record to prove it.
            </Text>
          </View>
        )}
      </Page>
      {pdfConfig.showGallery && galleryMedia.length > 0 && (
        <MosaicGallery images={galleryMedia} />
      )}
    </Document>
  );
};

export default DetailPortraitPdf;
