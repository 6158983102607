import { InputLabel } from '@mui/material';

export interface FormFieldLabelProps {
  className?: string;
  htmlFor?: string;
  text: string;
  name: string;
}

export function FormFieldLabel({
  className,
  htmlFor,
  text,
  name,
}: FormFieldLabelProps): JSX.Element {
  return (
    <InputLabel className={className} htmlFor={htmlFor}>
      {text}
    </InputLabel>
  );
}
