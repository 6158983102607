import { boolean, enumValue, maybe, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface ListRegionsRequest {
  brand: AvailableBrands;
  listSubregions?: boolean;
}

export const decodeListRegionsRequest = object<ListRegionsRequest>({
  brand: enumValue(AvailableBrands),
  listSubregions: maybe(boolean),
});
