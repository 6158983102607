import {
  array,
  enumValue,
  maybe,
  number,
  object,
  string,
} from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { MediaTarget, decodeMediaTarget } from '../../util/VehicleMedia.js';

export interface SendToBgReplacementRequest {
  vinMd5Hash: string;
  vin: string;
  dealerId: number;
  brand: AvailableBrands;
  targets: MediaTarget[];
  configurationOverride?: string;
  vehicleListingId: string;
}

export const decodeSendToBgReplacementRequest =
  object<SendToBgReplacementRequest>({
    vinMd5Hash: string,
    vin: string,
    dealerId: number,
    brand: enumValue(AvailableBrands),
    targets: array(decodeMediaTarget),
    configurationOverride: maybe(string),
    vehicleListingId: string,
  });
