import { method, service, UserPermissions } from '../util/index.js';
import { decodeChannelAuthRequest } from './channelAuth/ChannelAuthRequest.js';
import { decodeChannelAuthResponse } from './channelAuth/ChannelAuthResponse.js';
import { decodeImageQcPresenceAuthRequest } from './imageQcPresenceAuth/ImageQcPresenceAuthRequest.js';
import { decodeUserAuthRequest } from './userAuth/UserAuthRequest.js';
import { decodeUserAuthResponse } from './userAuth/UserAuthResponse.js';

export const RealTimeServiceDef = service({
  userAuth: method(decodeUserAuthRequest, decodeUserAuthResponse, [
    UserPermissions.Basic,
  ]),
  channelAuth: method(decodeChannelAuthRequest, decodeChannelAuthResponse, [
    UserPermissions.Basic,
  ]),
  imageQcPresenceAuth: method(
    decodeImageQcPresenceAuthRequest,
    decodeChannelAuthResponse,
    [UserPermissions.Support],
  ),
});

export type RealTimeServiceDef = typeof RealTimeServiceDef;
