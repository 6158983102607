import { Paper, PaperProps, Stack, Typography } from '@mui/material';
import { StackProps } from '@mui/system';
import { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { sx } from '../../util/sx.js';
import { APP_BAR_MOBILE } from '../layout/MainLayout.js';

const styles = sx({
  stickyContents: {
    top: {
      xs: 0,
      sm: -APP_BAR_MOBILE + 16,
      lg: 16,
    },
    display: {
      xs: 'none',
      lg: 'block',
    },
    position: 'sticky',
  },
});

interface SectionProps extends PropsWithChildren {
  title?: string | ReactNode;
  paperProps?: PaperProps;
  isSticky?: boolean;
  spacing?: StackProps['spacing'];
}

const Section: FunctionComponent<SectionProps> = ({
  title,
  children,
  paperProps,
  isSticky,
  spacing = 1,
}) => {
  return (
    <Paper
      {...paperProps}
      sx={
        isSticky
          ? {
              ...paperProps?.sx,
              ...styles.stickyContents,
            }
          : paperProps?.sx
      }
    >
      <Stack p={{ xs: 2, sm: 3 }} spacing={spacing}>
        {typeof title === 'string' ? (
          <Typography textTransform="uppercase" variant="subtitle1">
            {title}
          </Typography>
        ) : (
          title
        )}
        <Stack>{children}</Stack>
      </Stack>
    </Paper>
  );
};

export default Section;
