import { enumValue, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export type UserBuyNowRequest = {
  remarketingVehicleId: string;
  brand: AvailableBrands;
  dealerId: number;
};

export const decodeUserBuyNowRequest = object<UserBuyNowRequest>({
  remarketingVehicleId: text,
  brand: enumValue(AvailableBrands),
  dealerId: number,
});
