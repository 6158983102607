import { array, number, object, string } from '@fmtk/decoders';

export interface InventoryPrice {
  rowId: string;
  model: string;
  modelYear: string;
  age: number;
  price: string;
  currency: string;
  count: number;
}

export type ListModelPricingTrendsResponse = InventoryPrice[];

export const decodeListModelPricingTrendsResponse = array(
  object<InventoryPrice>({
    rowId: string,
    model: string,
    modelYear: string,
    age: number,
    price: string,
    currency: string,
    count: number,
  }),
);
