import { useAuth0 } from '@auth0/auth0-react';
import { debug } from 'debug';
import React from 'react';
import { ApiClient, makeApiClient } from '../../api/makeApiClient.js';
import { ContainerProps } from '../util/index.js';
import { useAppConfig } from './useAppConfig.js';
import { useConst } from './useConst.js';
import { useContextWithError } from './useContextWithError.js';

const apiClientContext = React.createContext<ApiClient | undefined>(undefined);

export function ApiClientProvider({ children }: ContainerProps): JSX.Element {
  const { api } = useAppConfig();
  const { getAccessTokenSilently, logout } = useAuth0();
  const trace = debug('hooks:useApiClient');
  const apiOrigin = new URL(api).origin;

  const value = useConst(() =>
    makeApiClient({
      credentials: {
        getAccessToken: async () => {
          let token: string | undefined;
          try {
            token = await getAccessTokenSilently({
              audience: apiOrigin,
            });
          } catch (err) {
            trace('getAccessToken error %o', err);
            logout({ returnTo: window.location.origin });
            throw err;
          }
          trace('getAccessToken %o', token);
          return token;
        },
      },
      endpoint: api,

      httpClientOptions: {
        requestTimeout:
          window.location.hostname === 'localhost' ? 3600000 : 20000,
      },
    }),
  );
  return React.createElement(apiClientContext.Provider, { children, value });
}

export function useApiClient(): ApiClient {
  return useContextWithError(apiClientContext, 'ApiClient');
}
