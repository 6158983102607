export enum EfficiencyClass {
  A = 'A',
  'A+' = 'A+',
  'A++' = 'A++',
  'A+++' = 'A+++',
  B = 'B',
  C = 'C',
  D = 'D',
  E = 'E',
  F = 'F',
  G = 'G',
}

export enum EuroEmissionClass {
  Euro1 = 'Euro 1',
  Euro2 = 'Euro 2',
  Euro3 = 'Euro 3',
  Euro4 = 'Euro 4',
  Euro5 = 'Euro 5',
  Euro6 = 'Euro 6',
  Euro7 = 'Euro 7',
}
