import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ListingsCounts } from '../../../../api/VehicleListingService/getVehicleListingCounts/GetCountVehicleListingResponse.js';
import { SyndicationType } from '../../../../api/util/SyndicationType.js';
import FilterMultiselect from '../../../common-ui/components/Filters/FilterMultiSelect.js';
import FiltersPanel from '../../../common-ui/components/Filters/FiltersPanel.js';
import { useSyndicationTypeOptions } from '../../../hooks/useSyndicationTypeOptions.js';
import { Option } from '../../../util/Option.js';

export interface FilterContainerProps {
  value: SelectedFilters;
  onChange: (selectedFilters: SelectedFilters) => void;
  counts?: {
    dealer: ListingsCounts;
    liveStatus: ListingsCounts;
  };
}

export enum SimpleLiveStatus {
  ALL = 'all',
  LIVE = 'live',
  NON_LIVE = 'nonLive',
}

const liveStatusOptions: Option[] = [
  {
    text: 'all',
    value: SimpleLiveStatus.ALL,
  },
  {
    text: 'live',
    value: SimpleLiveStatus.LIVE,
  },
  {
    text: 'nonLive',
    value: SimpleLiveStatus.NON_LIVE,
  },
];

export interface SelectedFilters {
  syndicationTypes: Set<SyndicationType>;
  isLive: boolean | undefined;
}

const FilterContainer: FunctionComponent<FilterContainerProps> = ({
  value,
  counts,
  onChange,
}) => {
  const { t } = useTranslation();
  const brandSyndicationTypes = useSyndicationTypeOptions();
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({
    syndicationTypes: new Set<SyndicationType>(),
    isLive: undefined,
  });
  const [filterCounts, setFilterCounts] = useState(0);

  const syndicationOptions = brandSyndicationTypes.map((s) => ({
    ...s,
    text: `${s.text} (${
      (counts?.dealer.syndicationTypeCounts || {})[
        s.value as SyndicationType
      ] || 0
    })`,
  }));

  const liveCounts = {
    [SimpleLiveStatus.ALL]:
      (counts?.dealer.isLiveCounts?.live || 0) +
      (counts?.dealer.isLiveCounts?.nonLive || 0),
    [SimpleLiveStatus.LIVE]: counts?.dealer.isLiveCounts?.live,
    [SimpleLiveStatus.NON_LIVE]: counts?.dealer.isLiveCounts?.nonLive,
  };

  const liveFilterSelected =
    value.isLive === undefined
      ? SimpleLiveStatus.ALL
      : value.isLive
      ? SimpleLiveStatus.LIVE
      : SimpleLiveStatus.NON_LIVE;

  const handleSyndicationTypeChange = useCallback(
    (filter: SyndicationType) => {
      setSelectedFilters((prev) => {
        const newSet = new Set(prev.syndicationTypes);
        if (newSet.has(filter)) {
          newSet.delete(filter);
        } else {
          newSet.add(filter);
        }
        const newState = { ...prev, syndicationTypes: newSet };
        onChange(newState);
        setFilterCounts(
          (newState.isLive !== undefined ? 1 : 0) +
            newState.syndicationTypes.size,
        );
        return newState;
      });
    },
    [onChange],
  );

  const handleLiveStatusChange = useCallback(
    (filter: SimpleLiveStatus) => {
      setSelectedFilters((prev) => {
        const newState = {
          ...prev,
          isLive:
            filter === SimpleLiveStatus.LIVE
              ? true
              : filter === SimpleLiveStatus.NON_LIVE
              ? false
              : undefined,
        };
        onChange(newState);
        setFilterCounts(
          (newState.isLive !== undefined ? 1 : 0) +
            newState.syndicationTypes.size,
        );
        return newState;
      });
    },
    [onChange],
  );

  const handleReset = useCallback(() => {
    const resetState = {
      syndicationTypes: new Set<SyndicationType>(),
      isLive: undefined,
    };
    setSelectedFilters(resetState);
    onChange(resetState);
  }, [onChange]);

  useEffect(() => {
    if (value) {
      setSelectedFilters(value);
      setFilterCounts(
        (value.isLive !== undefined ? 1 : 0) + value.syndicationTypes.size,
      );
    }
    //eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <FiltersPanel filterCounts={filterCounts} onReset={handleReset}>
      <FilterMultiselect
        counts={liveCounts}
        onChange={handleLiveStatusChange}
        options={liveStatusOptions}
        selected={liveFilterSelected}
        title={t('liveStatus')}
      />
      <FilterMultiselect
        counts={counts?.liveStatus.syndicationTypeCounts || {}}
        onChange={handleSyndicationTypeChange}
        options={syndicationOptions}
        selected={selectedFilters.syndicationTypes}
        title={t('type')}
      />
    </FiltersPanel>
  );
};

export default FilterContainer;
