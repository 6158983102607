import {
  array,
  boolean,
  choose,
  Decoder,
  enumValue,
  optional,
  record,
  text,
} from '@fmtk/decoders';
import { UserPermissions } from './UserPermissions.js';

export type Policy = Partial<Record<UserPermissions, boolean | string[]>>;

export const decodePolicy = record(
  enumValue(UserPermissions),
  optional(choose(boolean, array(text))),
) as Decoder<Policy>;
