import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { ErrorState, PageContainer } from '../../common-ui/index.js';
import { useBrand } from '../../hooks/index.js';
import ResponsiveIframe from '../components/LocatorAnalytics/LocatorAnalytics.js';

const LocatorAnalyticsPage: FunctionComponent = (): JSX.Element => {
  const { brandConfig } = useBrand();

  return (
    <PageContainer>
      <Typography sx={{ mb: 5 }} variant="h4">
        Customer Analytics
      </Typography>
      {brandConfig?.locatorAnalyticsUrl ? (
        <ResponsiveIframe frameSrc={brandConfig.locatorAnalyticsUrl} />
      ) : (
        <ErrorState error="Customer Analytics URL has not been configured" />
      )}
    </PageContainer>
  );
};
export default LocatorAnalyticsPage;
