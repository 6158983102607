import { Auth0Provider, useAuth0 } from '@auth0/auth0-react';
import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Container,
  CssBaseline,
  ThemeProvider,
} from '@mui/material';
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';
import i18next from 'i18next';
import Cookies from 'js-cookie';
import React, { useEffect } from 'react';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import ReactDOM from 'react-dom/client';
import { I18nextProvider } from 'react-i18next';
import {
  Outlet,
  RouterProvider,
  ScrollRestoration,
  createBrowserRouter,
  useNavigate,
} from 'react-router-dom';
import { ClientErrorCode } from '../../api/util/ClientError.js';
import { HttpServiceTransportError } from '../../api/util/HttpServiceTransportError.js';
import { ErrorState, MessageStoreProvider } from '../common-ui/index.js';
import {
  ApiClientProvider,
  BrandProvider,
  CurrentUserProvider,
  DealerProvider,
  useAppConfig,
  useAuth0Config,
  useBrand,
  useCurrentUser,
} from '../hooks/index.js';
import { WsChannelClientProvider } from '../hooks/useWsChannel.js';
import { initialiseTranslations } from '../translations/index.js';
import FirewallBlockPage from './components/ErrorPages/FirewallBlockPage.js';
import UnauthorizedPage from './components/ErrorPages/UnauthorisedPage.js';
import VerifyEmailPage from './components/ErrorPages/VerifyEmailPage.js';
import ErrorPage from './pages/ErrorPage.js';
import NotFoundPage from './pages/NotFoundPage.js';
import { routes } from './routes.js';
import { makeTheme } from './theme/makeTheme.js';
import { imsAppTranslations } from './translations/index.js';

export default function renderImsApp(el: HTMLElement) {
  const root = ReactDOM.createRoot(el);
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { clientId, domain } = useAuth0Config();
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const { api } = useAppConfig();
  const apiOrigin = new URL(api).origin;

  void initialiseTranslations(['ims'], [imsAppTranslations]);

  const queryClient = new QueryClient();

  const Root = () => (
    <I18nextProvider i18n={i18next}>
      <Auth0Provider
        audience={apiOrigin}
        clientId={clientId}
        domain={domain}
        onRedirectCallback={(appState) => {
          const redirectTo = appState?.returnTo;
          if (redirectTo) {
            Cookies.set('redirectTo', redirectTo);
          }
        }}
        redirectUri={window.location.origin}
      >
        <ApiClientProvider>
          <QueryClientProvider client={queryClient}>
            <MessageStoreProvider>
              <CurrentUserProvider>
                <BrandProvider>
                  <DealerProvider>
                    <WsChannelClientProvider>
                      <DndProvider backend={HTML5Backend}>
                        <App />
                        <ScrollRestoration />
                      </DndProvider>
                    </WsChannelClientProvider>
                  </DealerProvider>
                </BrandProvider>
              </CurrentUserProvider>
            </MessageStoreProvider>
          </QueryClientProvider>
        </ApiClientProvider>
      </Auth0Provider>
    </I18nextProvider>
  );

  const router = createBrowserRouter([
    {
      path: '/',
      element: <Root />,
      errorElement: <NotFoundPage />,
      children: routes,
    },
  ]);

  root.render(
    <React.StrictMode>
      <RouterProvider router={router} />
    </React.StrictMode>,
  );

  const splash = document.getElementById('loadingsplash');
  if (splash) {
    splash.style.display = 'none';
  }
}

function App() {
  const user = useCurrentUser();
  const { currentBrand } = useBrand();
  const navigate = useNavigate();
  const theme = makeTheme(currentBrand);
  const { isLoading, isAuthenticated, loginWithRedirect, error, logout } =
    useAuth0();

  const redirectTo = Cookies.get('redirectTo');

  useEffect(() => {
    if (!isLoading && !isAuthenticated) {
      const params = new URLSearchParams(window.location.search);
      const loginHint = params.get('login_hint');
      const loginScreen = params.get('login_screen');
      const organization = params.get('org');
      void loginWithRedirect({
        login_hint: loginHint || undefined,
        screen_hint: loginScreen || undefined,
        organization: organization || undefined,
        scope: 'openid profile email',
        prompt: organization ? 'login' : undefined,
        appState: { returnTo: window.location.pathname },
      });
    }
  }, [isAuthenticated, loginWithRedirect, isLoading]);

  useEffect(() => {
    if (isAuthenticated) {
      user.loadCurrentIdentity();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAuthenticated]);

  useEffect(() => {
    if (redirectTo) {
      Cookies.remove('redirectTo');
      navigate(redirectTo);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [redirectTo]);

  if (error) {
    return (
      <Alert severity="error">
        There has been an error please contact support
      </Alert>
    );
  }

  if (user.error) {
    if (user.error instanceof HttpServiceTransportError) {
      if (user.error.code === ClientErrorCode.Unauthorized) {
        return (
          <ThemeProvider theme={theme}>
            <UnauthorizedPage />
          </ThemeProvider>
        );
      }

      if (user.error.code === ClientErrorCode.Network) {
        return (
          <ThemeProvider theme={theme}>
            <FirewallBlockPage />
          </ThemeProvider>
        );
      }

      if (user.error.code === ClientErrorCode.EmailNotVerified) {
        return (
          <ThemeProvider theme={theme}>
            <VerifyEmailPage />
          </ThemeProvider>
        );
      }
    }

    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Container maxWidth="sm">
          <Box
            sx={{
              height: '100vh',
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <ErrorState
              alertTitle="Contact Support"
              error={`
              There has been an issue loading your user information. 
              This could be caused by a number of different reasons, including your connection speed, 
              a firewall or an antivirus preventing this connection.
              Try reloading this page, or contact support`}
            />
          </Box>
          <Button
            onClick={() => {
              logout();
            }}
          >
            Log out
          </Button>
        </Container>
      </ThemeProvider>
    );
  }

  if (!isAuthenticated || user.loading || !user.value) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <CircularProgress size={60} />
      </Box>
    );
  } else if (user.value) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Box display="flex" flexDirection="column" height="100vh">
          {currentBrand ? (
            <>
              <Outlet />
            </>
          ) : (
            <>
              <Box
                sx={{
                  height: '100vh',
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  alignItems: 'center',
                }}
              >
                <ErrorState
                  error="You don't have a brand associated to your user, please contact support"
                  alertTitle="Contact Support"
                />
              </Box>
              <Button
                onClick={() => {
                  logout();
                }}
              >
                Log out
              </Button>
            </>
          )}
        </Box>
      </ThemeProvider>
    );
  }
  return <ErrorPage />;
}
