interface PolicyMap<V> {
  [key: string]: V;
}

export type UserPolicyInit = PolicyMap<boolean | string[] | Set<string>>;

export interface PolicyPermission {
  name: string;
  resource?: string;
}

export type PolicyPermissionLike = PolicyPermission | string;

export enum PolicyElementMatchType {
  None,
  Partial,
  Full,
}

class PolicyElement {
  private value: boolean | Set<string> | undefined;

  constructor(value?: boolean | string | Iterable<string>) {
    if (value !== undefined) {
      if (typeof value === 'boolean') {
        this.value = value;
      } else if (typeof value === 'string') {
        this.value = new Set([value]);
      } else {
        this.value = new Set(value);
      }
    }
  }

  public add(resource?: string): this {
    if (resource !== undefined) {
      if (typeof this.value === 'boolean') {
        throw new Error(`can't add a resource to a boolean permission`);
      }
      if (this.value) {
        this.value.add(resource);
      } else {
        this.value = new Set([resource]);
      }
    } else {
      if (this.value && typeof this.value !== 'boolean') {
        throw new Error(`can't change a set permission to a boolean`);
      }
      this.value = true;
    }
    return this;
  }

  public match(resource?: string): boolean {
    return this.getMatchType(resource) !== PolicyElementMatchType.None;
  }

  public getMatchType(resource?: string): PolicyElementMatchType {
    const anyResourceWildcard = '*';

    if (this.value === undefined) {
      return PolicyElementMatchType.None;
    }
    if (typeof this.value === 'boolean') {
      return this.value
        ? PolicyElementMatchType.Full
        : PolicyElementMatchType.None;
    }
    if (resource === anyResourceWildcard) {
      return this.value.size
        ? PolicyElementMatchType.Full
        : PolicyElementMatchType.None;
    }
    if (resource) {
      return this.value.has(resource)
        ? PolicyElementMatchType.Full
        : PolicyElementMatchType.None;
    }
    return this.value.size
      ? PolicyElementMatchType.Partial
      : PolicyElementMatchType.None;
  }

  public getValue(): boolean | Set<string> | undefined {
    return this.value;
  }
}

export class PolicyDocument {
  private readonly partialMatches = new Set<string>();
  private readonly permissionCount: number;
  private readonly policy: PolicyMap<PolicyElement>;

  constructor(policy: UserPolicyInit = {}) {
    this.policy = {};
    this.permissionCount = policy ? Object.keys(policy).length : 0;

    for (const [key, value] of Object.entries(policy)) {
      this.policy[key] = new PolicyElement(value);
    }
  }

  public addPermission(permission: string, resource?: string): void {
    let entity = this.policy[permission];
    if (!entity) {
      this.policy[permission] = entity = new PolicyElement();
    }
    entity.add(resource);
  }

  public getPartialMatches(): string[] {
    return [...this.partialMatches.values()];
  }

  public hasPermission(permission: string, resource?: string): boolean {
    if (!this.policy) {
      return false;
    }
    const matchType = this.policy[permission]?.getMatchType(resource);
    if (matchType === PolicyElementMatchType.Partial) {
      this.partialMatches.add(permission);
    } else if (matchType === PolicyElementMatchType.Full) {
      this.partialMatches.delete(permission);
    }
    return (
      matchType === PolicyElementMatchType.Partial ||
      matchType === PolicyElementMatchType.Full
    );
  }

  public hasAnyPermission(...permissions: PolicyPermissionLike[]): boolean {
    if (!this.policy || this.permissionCount === 0) {
      return false;
    }
    if (permissions.length === 0) {
      return true;
    }
    for (const p of permissions) {
      const name = typeof p === 'string' ? p : p.name;
      const resource = typeof p === 'string' ? undefined : p.resource;

      if (this.hasPermission(name as any, resource)) {
        return true;
      }
    }
    return false;
  }

  public getResources(name: string): Set<string> | undefined {
    if (!this.policy || this.permissionCount === 0) {
      return;
    }
    const element = this.policy[name];
    if (!element || !element.match()) {
      return;
    }

    const value = element.getValue();
    if (typeof value !== 'boolean') {
      return value;
    }
  }
}
