import { GridColDef, GridRowsProp } from '@mui/x-data-grid';
import { FunctionComponent } from 'react';
import { DealerUser } from '../../../api/UserService/listUsersByDealer/ListDealerUsersResponse.js';
import { User } from '../../../api/util/User.js';
import { DataGrid } from '../index.js';

export type UserTableProps = {
  columns: GridColDef[];
  loading?: boolean;
  rows: GridRowsProp<User | DealerUser>;
};

export const UserTable: FunctionComponent<UserTableProps> = ({
  loading,
  columns,
  rows,
}): JSX.Element => {
  return (
    <DataGrid
      autoHeight
      columns={columns}
      disableSelectionOnClick
      getRowId={(user) => user.principalId}
      loading={loading}
      pageSize={25}
      rowHeight={50}
      rows={rows}
    />
  );
};

export default UserTable;
