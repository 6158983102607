export enum AvailableBrands {
  AstonMartin = 'aston-martin',
  Bentley = 'bentley',
  Genesis = 'genesis',
  Kia = 'kia',
  Lamborghini = 'lamborghini',
  RollsRoyce = 'rolls-royce',
}

const brandDisplayNameMap = {
  ['ASTON MARTIN']: AvailableBrands.AstonMartin,
  BENTLEY: AvailableBrands.Bentley,
  GENESIS: AvailableBrands.Genesis,
  KIA: AvailableBrands.Kia,
  LAMBORGHINI: AvailableBrands.Lamborghini,
  ['ROLLS-ROYCE']: AvailableBrands.RollsRoyce,
};

export const brandDisplayName: Record<AvailableBrands, string> = {
  [AvailableBrands.AstonMartin]: 'Aston Martin',
  [AvailableBrands.Bentley]: 'Bentley',
  [AvailableBrands.Genesis]: 'Genesis',
  [AvailableBrands.Kia]: 'Kia',
  [AvailableBrands.Lamborghini]: 'Lamborghini',
  [AvailableBrands.RollsRoyce]: 'Rolls-Royce',
};

export const getBrandDisplayName = (brand: AvailableBrands): string =>
  brandDisplayName[brand];

export const getAvailableBrandFromDisplayName = (
  displayName: string,
): AvailableBrands => {
  return brandDisplayNameMap[
    displayName.toUpperCase() as keyof typeof brandDisplayNameMap
  ];
};

export const isBrandDisplayName = (displayName: string): boolean =>
  Object.values(brandDisplayName).includes(displayName);
