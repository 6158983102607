import { Palette, PaletteColor, Shadows, ThemeOptions } from '@mui/material';
import palette from './palette.js';
import shadows from './shadows.js';
import typography from './typography.js';

// "primary" | "secondary" | "error" | "warning" | "info" | "success"
export type PaletteKey = keyof {
  [Key in keyof Palette as Palette[Key] extends PaletteColor
    ? Key
    : never]: true;
};

const defaultTheme: ThemeOptions = {
  palette: palette,
  typography: typography,
  shape: { borderRadius: 8 },
  shadows: shadows as Shadows,
  components: {
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: 4,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          boxShadow: shadows[2],
          borderRadius: 16,
          position: 'relative',
          zIndex: 0, // Fix Safari overflow: hidden with border radius
        },
      },
    },
    MuiLink: {
      styleOverrides: {
        root: {
          cursor: 'pointer',
        },
      },
      defaultProps: {
        color: 'inherit',
        underline: 'hover',
      },
    },
    MuiTypography: {
      variants: [
        {
          props: {
            variant: 'title',
          },
          style: {
            color: '#172B4D',
          },
        },
        {
          props: {
            variant: 'subtitle3',
          },
          style: {
            color: '#44546F',
          },
        },
        {
          props: {
            variant: 'status',
          },
          style: {
            textTransform: 'uppercase',
            color: '#FFF',
          },
        },
      ],
    },
    MuiPaper: {
      defaultProps: {
        elevation: 0,
      },
    },
    MuiStepLabel: {
      styleOverrides: {
        label: {
          fontSize: 14,
          color: '#637381',
          fontStyle: 'normal',
          lineHeight: '17px',
          fontWeight: 700,
          '&.Mui-active': {
            fontWeight: 700,
            color: '#637381',
          },
          '&.Mui-completed': {
            fontWeight: 700,
            color: '#637381',
          },
        },
      },
    },
    MuiStepConnector: {
      styleOverrides: {
        lineHorizontal: {
          borderRadius: 4,
          borderTop: '3px solid #637381',
        },
        lineVertical: {
          borderRadius: 4,
          borderLeft: '3px solid #637381',
          maxWidth: 3,
        },
        root: {
          '&.Mui-completed span': {
            borderTop: '3px solid #519D67',
            borderLeft: '3px solid #519D67',
          },
        },
      },
    },
    MuiStepIcon: {
      styleOverrides: {
        root: {
          overflow: 'visible',
          color: 'transparent',
          '&.MuiSvgIcon-root circle': {
            stroke: '#637381',
            strokeWidth: '3px',
            strokeLinejoin: 'round',
          },
          '&.Mui-active circle': {
            stroke: '#2065D1',
            color: '#2065D1',
          },
          '&.Mui-active text': {
            fill: '#FFF',
          },
          '&.Mui-completed': {
            color: '#519D67',
            width: 26,
            height: 26,
          },
        },
        text: {
          fontSize: 14,
          fontStyle: 'normal',
          lineHeight: '17px',
          fontWeight: 700,
          fill: '#637381',
        },
      },
    },
    MuiInputLabel: {
      styleOverrides: {
        root: {
          fontWeight: 700,
          fontSize: 14,
          lineHeight: '17px',
          color: '#637381',
        },
      },
    },
    MuiTextField: {
      styleOverrides: {
        root: {
          '& .MuiOutlinedInput-root': {
            borderRadius: '4px',
            '& fieldset': {
              border: '2px solid #BDC7D3',
            },
            '&:hover fieldset': {
              borderColor: '#919EAB',
            },
            '&.Mui-focused fieldset': {
              borderColor: '#919EAB',
            },
          },
        },
      },
      defaultProps: {
        inputProps: {
          sx: {
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 17,
            color: '#637381',
            '&::placeholder': {
              opacity: 1, // otherwise firefox shows a lighter color
            },
          },
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            padding: '8px 10px',
            color: '#637381',
          },
          '& fieldset': {
            border: '2px solid #BDC7D3',
            borderRadius: '4px',
          },
        },
      },
    },
    MuiSelect: {
      styleOverrides: {
        root: {
          borderRadius: '4px',
          '& fieldset': {
            border: '2px solid #BDC7D3',
          },
        },
      },
      defaultProps: {
        MenuProps: {
          disablePortal: true,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        },
        inputProps: {
          sx: {
            fontWeight: 600,
            fontSize: 14,
            color: '#637381',
            '&::placeholder': {
              opacity: 1, // otherwise firefox shows a lighter color
            },
          },
        },
      },
    },
  },
};

export default defaultTheme;
