import { Button, Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import {
  Body,
  ConfirmModal,
  useMessageStore,
} from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { useToggle } from '../../../../hooks/useToggle.js';
import { Section } from '../types.js';

type CancelRemarketingSectionProps = Omit<Section, 'vehicle'> & {
  readonly?: boolean;
  remarketingVehicleId: string;
};

const CancelRemarketingSection: FunctionComponent<
  CancelRemarketingSectionProps
> = ({
  title,
  sectionItemsProps,
  isLoading,
  readonly,
  remarketingVehicleId,
}) => {
  const [isCancelConfirmOpen, toggleCancelConfirmOpen] = useToggle(false);
  const { showMessage } = useMessageStore();
  const [cancelState, setCancelState] = useAsyncState<void>();
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const { t } = useTranslation();
  const navigate = useNavigate();

  const cancelRemarketing = useCallback(() => {
    if (!currentBrand) {
      return;
    }
    setCancelState(async () => {
      await api.remarketing.adminCancelRemarketing({
        brand: currentBrand,
        remarketingVehicleId,
      });

      toggleCancelConfirmOpen(false);
      showMessage({
        text: t('pages.remarketingVehiclePage.cancel.cancelSuccess'),
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });
      navigate(`../`);
      return;
    });
  }, [
    api.remarketing,
    currentBrand,
    navigate,
    remarketingVehicleId,
    setCancelState,
    showMessage,
    t,
    toggleCancelConfirmOpen,
  ]);

  useEffect(() => {
    if (cancelState.error) {
      toggleCancelConfirmOpen(false);
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [cancelState.error, showMessage, t, toggleCancelConfirmOpen]);

  return (
    <>
      <SectionItem title={title} {...sectionItemsProps}>
        <Stack spacing={4}>
          <Stack sx={sectionStyles.sectionContent}>
            <Stack>
              <Button
                color="error"
                disabled={isLoading || readonly}
                onClick={() => toggleCancelConfirmOpen(true)}
                sx={{ minWidth: '200px' }}
                variant="contained"
              >
                {t('pages.remarketingVehiclePage.cancel.cancelVehicle')}
              </Button>
            </Stack>
          </Stack>
        </Stack>
      </SectionItem>

      {isCancelConfirmOpen && (
        <ConfirmModal
          busy={cancelState.loading}
          cancelText={t('close')}
          fullWidth
          maxWidth="xs"
          onClose={toggleCancelConfirmOpen}
          onConfirm={cancelRemarketing}
          open
          title={t('pages.remarketingVehiclePage.cancel.cancelSectionTitle')}
        >
          <Body size="small">
            {t('pages.remarketingVehiclePage.cancel.cancelWarning')}
          </Body>
        </ConfirmModal>
      )}
    </>
  );
};

export default CancelRemarketingSection;
