import { maybe, number, object } from '@fmtk/decoders';
import { RemarketingVehicleStatus } from '../../util/RemarketingVehicle.js';

export type AdminCountRemarketingVehiclesResponse = Partial<
  Record<RemarketingVehicleStatus, number>
>;

export const decodeAdminCountRemarketingVehiclesResponse =
  object<AdminCountRemarketingVehiclesResponse>({
    [RemarketingVehicleStatus.ACTIVE]: maybe(number),
    [RemarketingVehicleStatus.CANCELLED]: maybe(number),
    [RemarketingVehicleStatus.CLOSED]: maybe(number),
    [RemarketingVehicleStatus.PIPELINE]: maybe(number),
    [RemarketingVehicleStatus.TRANSFERRED]: maybe(number),
    [RemarketingVehicleStatus.VISIBLE]: maybe(number),
  });
