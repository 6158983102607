import { Close } from '@mui/icons-material';
import {
  Box,
  ListItem,
  Skeleton,
  Stack,
  Typography,
  alpha,
  styled,
} from '@mui/material';
import i18next from 'i18next';
import { DateTime } from 'luxon';
import { useTranslation } from 'react-i18next';
import { InAppNotification } from '../../../../api/util/InAppNotification.js';
import { StringOrTranslation } from '../../../../util/StringOrTranslation.js';
import { sx } from '../../../util/sx.js';

const resolveStringOrTranslation = (value: StringOrTranslation) =>
  typeof value === 'string' ? value : i18next.t(value.key, { ...value.values });

const styles = sx({
  listItem: {
    border: `1px solid`,
    borderColor: 'grey.400',
    borderRadius: 0.5,
    position: 'relative',
    '&:hover .delete': {
      display: 'block',
    },
    overflowX: 'visible',
    my: 1,
  },
  listItemUnread: {
    borderWidth: 2,
    borderColor: 'primary.main',
    backgroundColor: (theme) => alpha(theme.palette.primary.main, 0.1),
  },
  itemImg: {
    width: 80,
    height: 60,
    objectFit: 'contain',
    mt: 1,
  },
});

const DeleteButton = styled(Close)(({ theme }) => ({
  width: 16,
  height: 16,
  borderRadius: 8,
  position: 'absolute',
  right: -8,
  top: -8,
  display: 'none',
  cursor: 'pointer',
  backgroundColor: theme.palette.grey[700],
  fill: theme.palette.common.white,
}));

export const NotificationSkeleton = () => (
  <Stack bgcolor="grey.200" p={2} spacing={1}>
    <Skeleton variant="text" width="100%" />
    <Stack alignItems="center" direction="row" spacing={2}>
      <Skeleton height={60} variant="rectangular" width={80} />
      <Stack flex={1}>
        <Skeleton variant="text" width="100%" />
        <Skeleton variant="text" width="90%" />
        <Skeleton variant="text" width="30%" />
      </Stack>
    </Stack>
  </Stack>
);

export const NotificationItem = ({
  notification,
  onDeleteClick,
}: {
  notification: InAppNotification;
  onDeleteClick: () => void;
}) => {
  const { i18n } = useTranslation();

  return (
    <ListItem
      key={notification.id}
      sx={[styles.listItem, !notification.read && styles.listItemUnread]}
    >
      <DeleteButton
        className="delete"
        onClick={(e) => {
          e.stopPropagation();
          onDeleteClick();
        }}
      />
      <Stack>
        <Stack direction="row" justifyContent="space-between" spacing={1}>
          <Typography variant="h6">
            {resolveStringOrTranslation(notification.title)}
          </Typography>
          <Typography variant="body2">
            {DateTime.fromMillis(notification.timestamp, {
              locale: i18n.language,
            }).toRelative()}
          </Typography>
        </Stack>
        <Stack alignItems="center" direction="row" spacing={2}>
          <Box
            component="img"
            src={notification.imageSrc}
            sx={styles.itemImg}
          />
          <Stack>
            <Typography color="grey.600" variant="h6">
              {resolveStringOrTranslation(notification.subtitle)}
            </Typography>
            <Typography variant="body2">
              {resolveStringOrTranslation(notification.body)}
            </Typography>
          </Stack>
        </Stack>
      </Stack>
    </ListItem>
  );
};
