import { Stack } from '@mui/material';
import { FunctionComponent, useEffect, useMemo } from 'react';
import {
  ListingStatus,
  VehicleListing,
} from '../../../../../api/util/Vehicle.js';
import CellCheckboxInput from '../../../../common-ui/components/Cell/CellCheckboxInput.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import {
  CellMoneyInput,
  CellSelectInput,
  useForm,
} from '../../../../common-ui/index.js';
import { useCurrency } from '../../../../hooks/useCurrency.js';
import { useDealer } from '../../../../hooks/useDealer.js';
import { usePrice } from '../../../../hooks/usePrice.js';
import { Section, YesNo, YesNoOptions } from './types.js';

interface PriceProps extends Omit<Section, 'vehicleListing' | 'actionType'> {
  currency?: VehicleListing['currency'];
}

const Price: FunctionComponent<PriceProps> = ({
  title,
  currency,
  readonly,
}) => {
  const { currentDealer } = useDealer();
  const [formState, formBind] = useForm();
  const { currencyOptions } = useCurrency();
  const priceVisibility = usePrice();

  const selectedCurrency = useMemo(
    () => formState.values.currency || currency?.code,
    [formState.values.currency, currency?.code],
  );

  const isGermanDealer = useMemo(
    () =>
      currentDealer?.countryCode &&
      currentDealer.countryCode.toUpperCase() === 'DE',
    [currentDealer],
  );

  const isJapaneseDealer = useMemo(
    () =>
      currentDealer?.countryCode &&
      currentDealer.countryCode.toUpperCase() === 'JP',
    [currentDealer],
  );

  const disabled = useMemo(() => {
    return (
      formState.values.listingStatus !== ListingStatus.ForSale ||
      !!formState.values.priceOnApplication
    );
  }, [formState.values.listingStatus, formState.values.priceOnApplication]);

  useEffect(() => {
    if (formState.values.listingStatus !== ListingStatus.ForSale) {
      formBind.setValue('priceOnApplication', false);
    }
  }, [formBind, formState.values.listingStatus]);

  return (
    <SectionItem title={title}>
      <Stack sx={sectionStyles.sectionContent}>
        <Stack flex={1}>
          {priceVisibility?.priceOnApplication && (
            <CellCheckboxInput
              formattedValue={
                formState.values.priceOnApplication ? YesNo.Yes : YesNo.No
              }
              label="priceOnApplication"
              name="priceOnApplication"
              readonly={
                (disabled && !formState.values.priceOnApplication) || readonly
              }
            />
          )}
          <CellSelectInput
            formattedValue={disabled ? '-' : undefined}
            label="Currency"
            name="currency"
            options={currencyOptions}
            readonly={disabled || readonly}
          />
          <CellMoneyInput
            currency={selectedCurrency}
            formattedValue={disabled ? '-' : undefined}
            label="retailPrice"
            name="price"
            readonly={disabled || readonly}
            required={!formState.values.priceOnApplication}
          />
          {priceVisibility?.trade && (
            <CellMoneyInput
              currency={selectedCurrency}
              formattedValue={disabled ? '-' : undefined}
              label="trade"
              name="trade"
              readonly={disabled || readonly}
            />
          )}
        </Stack>
        <Stack flex={1}>
          {priceVisibility?.standInValue && (
            <CellMoneyInput
              currency={selectedCurrency}
              formattedValue={disabled ? '-' : undefined}
              label="standInValue"
              name="standInValue"
              readonly={disabled || readonly}
            />
          )}
          {priceVisibility?.lessTaxes && (
            <CellMoneyInput
              currency={selectedCurrency}
              formattedValue={disabled ? '-' : undefined}
              label="priceLessTaxes"
              name="priceLessTaxes"
              readonly={disabled || readonly}
            />
          )}
          {priceVisibility?.retailPlusTax && (
            <CellMoneyInput
              currency={selectedCurrency}
              formattedValue={disabled ? '-' : undefined}
              label="retailPricePlusTax"
              name="retailPricePlusTax"
              readonly={disabled || readonly}
            />
          )}
          {priceVisibility?.netPrice && (
            <CellMoneyInput
              currency={selectedCurrency}
              formattedValue={disabled ? '-' : undefined}
              label="netPrice"
              name="netPrice"
              readonly={disabled || readonly}
            />
          )}
          {isGermanDealer && (
            <CellSelectInput
              formattedValue={
                disabled
                  ? '-'
                  : formState.values.german25aTaxSalesDisclaimer === YesNo.Yes
                  ? YesNo.Yes
                  : YesNo.No
              }
              label="german25aTaxSalesDisclaimer"
              name="german25aTaxSalesDisclaimer"
              options={YesNoOptions}
              readonly={disabled || readonly}
            />
          )}
          {isJapaneseDealer && priceVisibility?.basePrice && (
            <CellMoneyInput
              currency={selectedCurrency}
              formattedValue={disabled ? '-' : undefined}
              label="basePrice"
              name="basePrice"
              readonly={disabled || readonly}
            />
          )}
          {priceVisibility?.vatQualifying && (
            <CellSelectInput
              formattedValue={
                disabled
                  ? '-'
                  : formState.values.vatQualifying === YesNo.Yes
                  ? YesNo.Yes
                  : YesNo.No
              }
              label="vatQualifying"
              name="vatQualifying"
              options={YesNoOptions}
              readonly={disabled || readonly}
            />
          )}
          {priceVisibility?.priceExcludesVat && (
            <CellSelectInput
              formattedValue={
                disabled
                  ? '-'
                  : formState.values.priceExcludesVat === YesNo.Yes
                  ? YesNo.Yes
                  : YesNo.No
              }
              label="priceExcludesVat"
              name="priceExcludesVat"
              options={YesNoOptions}
              readonly={disabled || readonly}
            />
          )}
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Price;
