export function normalisePath(
  absolute: boolean,
  ...path: (string | string[])[]
): string {
  const parts = path
    .reduce((a: string[], x) => a.concat(x), [])
    .join('/')
    .split('/');
  const result: string[] = [];

  for (const part of parts) {
    if (part && part !== '.') {
      if (part === '..') {
        if (result.length && result[result.length - 1] !== '..') {
          result.pop();
        } else if (!absolute) {
          result.push('..');
        }
      } else {
        result.push(part);
      }
    }
  }

  const norm = result.join('/');
  return absolute ? '/' + norm : norm;
}
