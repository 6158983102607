import {
  chain,
  enumValue,
  integer,
  is,
  maybe,
  object,
  string,
} from '@fmtk/decoders';
import { isUrl } from '../../util/isUrl.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../util/VehicleMedia.js';

export interface UploadMediaRequest {
  cloudinaryId?: string;
  etag?: string;
  file?: string;
  legacyUrl?: string;
  position: number;
  signature?: string;
  userEmail?: string;
  vehicleMediaStatus?: VehicleMediaStatus.Approved | VehicleMediaStatus.Pending;
  vehicleMediaType: VehicleMediaType;
  vinMd5Hash: string;
}

export const decodeUploadMediaRequest = object<UploadMediaRequest>({
  cloudinaryId: maybe(string),
  etag: maybe(string),
  file: maybe(chain(string, isUrl)),
  legacyUrl: maybe(string),
  position: integer,
  signature: maybe(string),
  userEmail: maybe(string),
  vehicleMediaStatus: maybe(
    is(VehicleMediaStatus.Approved, VehicleMediaStatus.Pending),
  ),
  vehicleMediaType: enumValue(VehicleMediaType),
  vinMd5Hash: string,
});
