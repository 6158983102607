import {
  MutationOptions,
  UseMutationResult,
  useMutation,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { UploadMediaRequest } from '../../../../api/MediaService/uploadMedia/UploadMediaRequest.js';
import { None } from '../../../../api/util/none.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';

type UseUploadMediaProps = MutationOptions<None, unknown, UploadMediaRequest>;
type UseUploadMediaResult = UseMutationResult<
  None,
  unknown,
  UploadMediaRequest
>;

export const useUploadMedia = (
  props?: UseUploadMediaProps,
): UseUploadMediaResult => {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (req) => {
      await api.media.uploadMedia(req);
    },
    onSuccess: () => {
      showMessage({
        text: t('pages.imageQC.restoreImageSuccess'),
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });
    },
    onError: () => {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    },
    ...props,
  });
};
