import { object, record, string, text } from '@fmtk/decoders';

export interface GetDocumentUploadUrlResponse {
  headers: Record<string, string>;
  key: string;
  url: string;
}

export const decodeGetDocumentUploadUrlResponse =
  object<GetDocumentUploadUrlResponse>({
    headers: record(string, string),
    key: text,
    url: text,
  });
