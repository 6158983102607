import { object, text } from '@fmtk/decoders';

export interface ArchiveVehicleListingRequest {
  id: string;
}

export const decodeArchiveVehicleListingRequest =
  object<ArchiveVehicleListingRequest>({
    id: text,
  });
