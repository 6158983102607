import {
  array,
  boolean,
  choose,
  enumValue,
  is,
  maybe,
  number,
  object,
  optional,
} from '@fmtk/decoders';
import { IndexName } from '../../../core/SearchIndexService/indexes.js';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetCountVehicleListingRequest {
  brand: AvailableBrands | AvailableBrands[];
  dealer?: number | number[];
  options?: {
    isLive?: boolean;
    indexName?: IndexName.VehicleListings | IndexName.ArchivedVehicleListings;
  };
}

export const decodeGetCountVehicleListingRequest =
  object<GetCountVehicleListingRequest>({
    brand: choose(
      array(enumValue(AvailableBrands)),
      enumValue(AvailableBrands),
    ),
    dealer: maybe(choose(number, array(number))),
    options: optional(
      object({
        isLive: optional(boolean),
        indexName: maybe(
          is(IndexName.ArchivedVehicleListings, IndexName.VehicleListings),
        ),
      }),
    ),
  });
