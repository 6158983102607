import { array, boolean, jsonDate, object, string } from '@fmtk/decoders';

export interface UnregisteredUser {
  email: string;
  registrationDate: Date;
  principalId: string;
  emailVerified: boolean;
}

export const decodeUnregisteredUser = object<UnregisteredUser>({
  email: string,
  registrationDate: jsonDate,
  principalId: string,
  emailVerified: boolean,
});

export type ListUnregisteredUsersResponse = UnregisteredUser[];

export const decodeListUnregisteredUsersResponse = array(
  decodeUnregisteredUser,
);
