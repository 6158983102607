import {
  alpha,
  Box,
  List,
  ListItemButton,
  ListItemButtonProps,
  ListItemIcon,
  ListItemText,
  ListSubheader,
  Skeleton,
  styled,
  useTheme,
} from '@mui/material';
import { t } from 'i18next';
import {
  matchPath,
  NavLink as RouterLink,
  useLocation,
} from 'react-router-dom';
import { UserPermissions } from '../../../api/util/index.js';
import { convertToString } from '../../../core/DbService/util/convertToString.js';
import { NavItemType, NavListType } from '../../common-ui/index.js';
import { useBrand, useCurrentUser, useDealer } from '../../hooks/index.js';
import { BrandConfig } from '../../util/index.js';

const ListItemStyle = styled((props: ListItemButtonProps) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: 'relative',
  textTransform: 'capitalize',
  color: theme.palette.text.secondary,
  borderRadius: theme.shape.borderRadius,
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  color: 'inherit',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
});

interface NavItemProps {
  item: NavItemType;
  active?: (path: string) => boolean;
}

const ItemContent = ({ item, active }: NavItemProps) => {
  const theme = useTheme();

  const isActiveRoot = active ? active(item.path) : false;

  const { title, icon } = item;

  const activeRootStyle = {
    color: 'primary.main',
    fontWeight: 'fontWeightMedium',

    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.selectedOpacity,
    ),
  };

  const text = typeof title === 'string' ? t(title) : title;

  return (
    <ListItemStyle
      sx={{
        textDecoration: 'none',
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={text} />
    </ListItemStyle>
  );
};

function NavItem({ item, active }: NavItemProps) {
  const { path, isExternal, isLoading } = item;

  return isExternal ? (
    <a href={path} style={{ textDecoration: 'none' }}>
      <ItemContent item={item} />
    </a>
  ) : isLoading ? (
    <Skeleton height={48} />
  ) : (
    <RouterLink style={{ textDecoration: 'none' }} to={path}>
      <ItemContent active={active} item={item} />
    </RouterLink>
  );
}

interface NavSectionProps {
  navConfig: NavListType[];
}

const checkBrandBasedConditional = (
  item: NavItemType,
  brandConfig?: BrandConfig,
) => {
  return item.brandBasedConditional
    ? item.brandBasedConditional(brandConfig)
    : true;
};

export default function NavSection({ navConfig }: NavSectionProps) {
  const { pathname } = useLocation();
  const { hasPermission } = useCurrentUser();
  const { brandConfig, currentBrand } = useBrand();
  const { currentDealer } = useDealer();

  const match = (path: string) =>
    path ? !!matchPath({ path, end: true }, pathname) : false;

  const isMenuOptionVisible = (item: NavItemType): boolean => {
    // Item without requiredPolicy means is a public link
    if (!item.requiredPolicy) {
      return checkBrandBasedConditional(item, brandConfig);
    }

    if (!currentBrand) {
      return false;
    }

    let userHasPolicy = false;

    switch (item.requiredPolicy) {
      case UserPermissions.GlobalReporting:
        userHasPolicy = hasPermission(
          UserPermissions.GlobalReporting as UserPermissions,
          currentBrand,
        );

        break;

      case UserPermissions.DealerReporting:
        userHasPolicy = hasPermission(
          UserPermissions.DealerReporting as UserPermissions,
          currentBrand,
        );

        break;

      case UserPermissions.Admin:
        const supportAccess = hasPermission(
          UserPermissions.Support as UserPermissions,
        );

        userHasPolicy =
          supportAccess ||
          //Dealer access
          (currentDealer &&
            hasPermission(
              UserPermissions.Admin as UserPermissions,
              convertToString(currentDealer.id),
            )) ||
          false;

        break;

      case UserPermissions.RemarketingAdmin:
        userHasPolicy =
          hasPermission(
            UserPermissions.RemarketingAdminBrands as UserPermissions,
            currentBrand,
          ) ||
          hasPermission(
            UserPermissions.RemarketingReadOnlyBrands as UserPermissions,
            currentBrand,
          );

        break;

      case UserPermissions.RemarketingDealer:
        userHasPolicy =
          (currentDealer &&
            hasPermission(
              UserPermissions.RemarketingDealer as UserPermissions,
              convertToString(currentDealer.id),
            )) ??
          false;

        break;
    }

    return userHasPolicy && checkBrandBasedConditional(item, brandConfig);
  };

  const isMenuHeaderVisible = (item: NavListType) =>
    item.links.some((item) => isMenuOptionVisible(item));

  return (
    <Box>
      {navConfig.map(
        (mainItem) =>
          isMenuHeaderVisible(mainItem) && (
            <List
              disablePadding
              key={t(mainItem.label)}
              subheader={
                <ListSubheader component="div" id="nested-list-subheader">
                  {t(mainItem.label)}
                </ListSubheader>
              }
              sx={{
                p: 1,
                textTransform: 'uppercase',
              }}
            >
              {mainItem.links.map(
                (item, idx) =>
                  isMenuOptionVisible(item) && (
                    <NavItem active={match} item={item} key={idx} />
                  ),
              )}
            </List>
          ),
      )}
    </Box>
  );
}
