import { array, object, string } from '@fmtk/decoders';

export interface RegionsList {
  zone: string;
}

export type ListRegionsResponse = RegionsList[];

export const decodeListRegionsResponse = array(
  object<RegionsList>({
    zone: string,
  }),
);
