import { useAuth0 } from '@auth0/auth0-react';
import { text } from '@fmtk/decoders';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Container, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import {
  Form,
  FormTextField,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { useToggle } from '../../../hooks/useToggle.js';

const UnauthorizedPage: FunctionComponent = (): JSX.Element => {
  const api = useApiClient();
  const { t } = useTranslation();
  const { logout } = useAuth0();
  const { showMessage } = useMessageStore();
  const [disableButton, toggleDisableButton] = useToggle(false);
  const form = useCreateForm(
    {
      givenName: text,
      familyName: text,
      dealerName: text,
      jobRole: text,
    },
    async (values) => {
      await api.user.accessRequest(values);
      showMessage({
        severity: 'success',
        text: "We have received your request and we'll get back to you soon.",
        dismissible: false,
      });
      toggleDisableButton(true);
    },
    undefined,
    [],
  );

  const [formState] = form;

  useEffect(() => {
    if (formState.submitError) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [formState.submitError, showMessage, t]);

  return (
    <Stack
      alignContent="center"
      bgcolor="#f3ebe9"
      direction="column"
      display="flex"
      height="100vh"
      justifyContent="center"
      p={{ xs: 2, sm: 5 }}
      textAlign="center"
    >
      <Box alignContent="center" display="flex" justifyContent="center" mb={2}>
        <img
          alt="logo"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/connect-auto-branding/logo"
          style={{ marginRight: 20 }}
          width={50}
        />
        <img
          alt="logo"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/connect-auto-branding/full-logo"
          width={400}
        />
      </Box>
      <Typography color="#333" variant="h3">
        Access to this page is restricted
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: 5 }}>
        <img
          alt="unauthorized"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/icons/data-security-threat.svg"
          width={200}
        />
      </Box>
      <Container maxWidth="sm">
        <Typography variant="body1">
          We can't find any information about you in our system. Please provide
          us with your details so we can grant you access.
        </Typography>
      </Container>
      <Container maxWidth="sm" sx={{ mt: 5 }}>
        <Form form={form}>
          <Stack spacing={1}>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              spacing={1}
            >
              <FormTextField
                disabled={formState.busy}
                fullWidth
                label="Given Name"
                name="givenName"
              />
              <FormTextField
                disabled={formState.busy}
                fullWidth
                label="Family Name"
                name="familyName"
              />
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row',
              }}
              spacing={1}
            >
              <FormTextField
                disabled={formState.busy}
                fullWidth
                label="Dealer Name"
                name="dealerName"
              />
              <FormTextField
                disabled={formState.busy}
                fullWidth
                label="Job Role"
                name="jobRole"
              />
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row-reverse',
              }}
              marginTop={2}
              spacing={2}
            >
              <LoadingButton
                disabled={disableButton}
                loading={formState.busy}
                type="submit"
                variant="contained"
              >
                {t('submit')}
              </LoadingButton>
              <Button onClick={() => logout()} variant="outlined">
                Log out
              </Button>
            </Stack>
          </Stack>
        </Form>
      </Container>
    </Stack>
  );
};
export default UnauthorizedPage;
