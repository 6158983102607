import {
  array,
  enumValue,
  maybe,
  number,
  object,
  string,
} from '@fmtk/decoders';
import { VehicleMediaType } from '../../util/VehicleMedia.js';

export interface GetMediaRequest {
  vinMd5Hash: string;
  positions?: number[];
  vehicleMediaType?: VehicleMediaType;
}

export const decodeGetMediaRequest = object<GetMediaRequest>({
  vinMd5Hash: string,
  positions: maybe(array(number)),
  vehicleMediaType: maybe(enumValue(VehicleMediaType)),
});
