import {
  array,
  boolean,
  choose,
  enumValue,
  is,
  maybe,
  number,
  object,
  text,
} from '@fmtk/decoders';
import {
  AuctionVehicleSummary,
  FixedPriceVehicleSummary,
  UnassignedVehicleSummary,
} from '../../../core/DynamoDbService/commands/GetRemarketingVehicleSummaryCommand.js';
import {
  RemarketingType,
  RemarketingVehicleStatus,
} from '../../util/RemarketingVehicle.js';

const decodeUnassignedVehicleSummary = object<UnassignedVehicleSummary>({
  status: enumValue(RemarketingVehicleStatus),
  type: is(RemarketingType.UNASSIGNED),
  countries: array(text),
});

const decodeAuctionVehicleSummary = object<AuctionVehicleSummary>({
  status: enumValue(RemarketingVehicleStatus),
  type: is(RemarketingType.AUCTION),
  closingDate: number,
  startingDate: number,
  startPrice: number,
  countries: array(text),
});

const decodeFixedPriceVehicleSummary = object<FixedPriceVehicleSummary>({
  status: enumValue(RemarketingVehicleStatus),
  type: is(RemarketingType.FIXED_PRICE),
  closingDate: maybe(number),
  startingDate: number,
  price: number,
  acceptsOffers: maybe(boolean),
  countries: array(text),
});

export const decodeGetAuctionVehicleSummaryResponse = choose(
  decodeUnassignedVehicleSummary,
  decodeAuctionVehicleSummary,
  decodeFixedPriceVehicleSummary,
);
