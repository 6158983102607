import { enumValue, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface AdminGetRemarketingVehicleRequest {
  remarketingVehicleId: string;
  brand: AvailableBrands;
}

export const decodeAdminGetRemarketingVehicleRequest =
  object<AdminGetRemarketingVehicleRequest>({
    remarketingVehicleId: text,
    brand: enumValue(AvailableBrands),
  });
