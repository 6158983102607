/* eslint-disable @typescript-eslint/no-explicit-any */
import { Decoder } from '@fmtk/decoders';

export const MethodScope = {
  Any: null,
  NoAuth: false,
} as const;

// eslint-disable-next-line @typescript-eslint/no-redeclare
export type MethodScope = typeof MethodScope;

export interface ServiceMethodDef<Req, Res> {
  request: Decoder<Req>;
  response: Decoder<Res>;
  scopes: string[] | null | false;
}

export type RequestTypeOf<T> = T extends ServiceMethodDef<infer Req, any>
  ? Req
  : never;

export type ResponseTypeOf<T> = T extends ServiceMethodDef<any, infer Res>
  ? Res
  : never;

export type ServiceDefBase = { [K in string]: ServiceMethodDef<any, any> };

export type ApiDefBase = { [K in string]: ServiceDefBase };

export type ApiServiceTypeOf<Def> = { [K in keyof Def]: ServiceTypeOf<Def[K]> };

export type ServiceMethod<Req, Res> = (req: Req) => PromiseLike<Res>;

export type ServiceMethodTypeOf<T> = T extends ServiceMethodDef<
  infer Req,
  infer Res
>
  ? ServiceMethod<Req, Res>
  : never;

export type ServiceMethodWithContext<T, Ctx> = T extends ServiceMethodDef<
  infer Req,
  infer Res
>
  ? (req: Req, ctx: Ctx) => PromiseLike<Res>
  : never;

export type ServiceTypeOf<T> = { [K in keyof T]: ServiceMethodTypeOf<T[K]> };

export type ServiceWithContext<T, Ctx> = {
  [K in keyof T]: ServiceMethodWithContext<T[K], Ctx>;
};

export function method<Req, Res>(
  request: Decoder<Req>,
  response: Decoder<Res>,
  scopes?: string[] | null | false,
): ServiceMethodDef<Req, Res> {
  return {
    request,
    response,
    scopes: scopes ?? null,
  };
}

export function service<T extends ServiceDefBase>(def: T): T {
  return def;
}
