import {
  Box,
  Container,
  Stack,
  Tab,
  Tabs,
  tabsClasses,
  Typography,
} from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import { useSetAtom } from 'jotai';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { ListRegionCountryResponse } from '../../../api/BrandService/listRegionCountry/ListRegionCountryResponse.js';
import { GetRemarketingErrors } from '../../../api/RemarketingService/common.js';
import { HttpServiceTransportError } from '../../../api/util/HttpServiceTransportError.js';
import { RemarketingVehicleStatus } from '../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicle } from '../../../api/util/UserRemarketingVehicle.js';
import { toNumber } from '../../../util/toNumber.js';
import DetailsSkeleton from '../../common-ui/components/DetailsSkeleton.js';
import {
  ErrorState,
  ErrorStateProps,
  useMessageStore,
} from '../../common-ui/index.js';
import {
  useApiClient,
  useAsyncState,
  useCurrentUser,
  useDealer,
  useMobile,
} from '../../hooks/index.js';
import { useBrand } from '../../hooks/useBrand.js';
import RemarketingFeatures from '../components/RemarketingVehicle/RemarketingFeatures.js';
import UserRemarketingDetails from '../components/RemarketingVehicle/UserRemarketingDetails.js';
import UserRemarketingVehicleHeader from '../components/RemarketingVehicle/UserRemarketingVehicleHeader.js';
import VehicleDetails from '../components/RemarketingVehicle/VehicleDetails.js';
import VehicleMediaSection from '../components/RemarketingVehicle/VehicleMedia.js';
import HeaderSkeleton from '../components/Vehicle/HeaderSkeleton.js';
import {
  makeRemarketingVehicleUserQueryKey,
  useRemarketingVehicleUser,
} from '../hooks/queries/useRemarketingVehicleUser.js';
import { regionCountryOptionsAtom } from '../state/atoms.js';

enum TabOptions {
  Remarketing = 'Remarketing',
  Details = 'Details',
  Features = 'Features',
  Media = 'Media',
}

const refreshStatus = [
  RemarketingVehicleStatus.ACTIVE,
  RemarketingVehicleStatus.CLOSED,
  RemarketingVehicleStatus.CANCELLED,
  RemarketingVehicleStatus.TRANSFERRED,
];

const UserRemarketingVehiclePage: FunctionComponent = (): JSX.Element => {
  const { remarketingId, tab } = useParams();
  const { currentBrand } = useBrand();
  const { hasPermission } = useCurrentUser();
  const { currentDealer } = useDealer();
  const mobile = useMobile();
  const api = useApiClient();
  const [currentTab, setCurrentTab] = useState<TabOptions>();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const [regions, setRegions] = useAsyncState<ListRegionCountryResponse>();
  const { value: currentUser, isSupport } = useCurrentUser();
  const setRegionOptions = useSetAtom(regionCountryOptionsAtom);
  const { showMessage } = useMessageStore();
  const queryClient = useQueryClient();
  const dealerId = toNumber(currentDealer?.id);

  const {
    data: remarketingVehicle,
    isLoading,
    isError,
    error,
  } = useRemarketingVehicleUser(
    currentBrand && remarketingId && dealerId
      ? {
          brand: currentBrand,
          dealerId: dealerId,
          id: remarketingId,
        }
      : undefined,
    { refreshStatus },
  );

  const handleChange = (_: React.SyntheticEvent, newValue: TabOptions) => {
    navigate(`../${newValue}`, { relative: 'path' });
    setCurrentTab(newValue);
  };

  const handleUpdate = useCallback(
    (vehicle?: UserRemarketingVehicle) => {
      if (!remarketingId || !currentDealer?.id || !vehicle || !currentBrand) {
        return;
      }
      queryClient.setQueryData<UserRemarketingVehicle>(
        makeRemarketingVehicleUserQueryKey({
          brand: currentBrand,
          id: remarketingId,
          dealerId: Number(currentDealer.id),
        }),
        (prev) => {
          if (!prev) {
            return;
          }

          if (prev?.id === vehicle?.id) {
            return {
              ...prev,
              ...vehicle,
            };
          }
        },
      );
      showMessage({
        text: t('pages.remarketingVehiclePage.updatedSuccess'),
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });
    },
    [
      currentBrand,
      currentDealer?.id,
      queryClient,
      remarketingId,
      showMessage,
      t,
    ],
  );

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setRegions(async () => {
      const listRegionCountry = await api.brand.listRegionCountry({
        brand: currentBrand,
        regions: currentUser?.policy.RemarketingAdmin as string[],
      });

      setRegionOptions(listRegionCountry);

      return listRegionCountry;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentBrand, currentUser]);

  useEffect(() => {
    const current = Object.values(TabOptions).find(
      (x: string) => x.toLowerCase() === tab?.toLowerCase(),
    );

    if (!current) {
      navigate(`./${TabOptions.Remarketing}`, {
        relative: 'path',
        replace: true,
      });
    }

    setCurrentTab(current);
  }, [navigate, tab]);

  const hasRemarketingDealer =
    isSupport ||
    (currentDealer &&
      hasPermission('RemarketingDealer', currentDealer.id.toString()));

  useEffect(() => {
    if (!remarketingId || !currentBrand) {
      return;
    }

    //count user views, no need to wait for response
    // eslint-disable-next-line @typescript-eslint/no-floating-promises
    api.remarketing.increaseRemarketingVehicleViews({
      brand: currentBrand,
      id: remarketingId,
    });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [remarketingId]);

  if (!hasRemarketingDealer || !currentDealer) {
    return <ErrorState />;
  }

  if (
    (isLoading && !remarketingVehicle) ||
    (regions.loading && !regions.value)
  ) {
    return (
      <Stack gap={10}>
        <HeaderSkeleton />
        <DetailsSkeleton />
      </Stack>
    );
  }

  if (isError) {
    const handledError: ErrorStateProps | undefined =
      HttpServiceTransportError.is(error, GetRemarketingErrors.NOT_ACCESSIBLE)
        ? {
            error: t(
              `pages.remarketingVehiclePage.errors.${GetRemarketingErrors.NOT_ACCESSIBLE}`,
            ),
            alertTitle: t('attention'),
            severity: 'warning',
          }
        : undefined;
    return (
      <Stack alignItems="center" height="100vh" justifyContent="center">
        <ErrorState {...handledError} />
      </Stack>
    );
  }

  return (
    <div>
      {remarketingVehicle && (
        <div>
          <Stack
            alignItems="center"
            bgcolor="background.paper"
            pt={{
              xs: 2,
              lg: 8,
            }}
            spacing={2}
          >
            <Container maxWidth="xl">
              <UserRemarketingVehicleHeader
                remarketingVehicle={remarketingVehicle}
              />
              {/* Tabs */}
              <Box mt={5}>
                <Tabs
                  allowScrollButtonsMobile
                  onChange={handleChange}
                  scrollButtons
                  sx={{
                    [`& .${tabsClasses.scrollButtons}`]: {
                      '&.Mui-disabled': { opacity: 0.3 },
                    },
                  }}
                  value={currentTab}
                  variant={mobile ? 'scrollable' : undefined}
                >
                  <Tab
                    id="remarketing-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('remarketing')}
                      </Typography>
                    }
                    value={TabOptions.Remarketing}
                  />
                  <Tab
                    id="details-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('details')}
                      </Typography>
                    }
                    value={TabOptions.Details}
                  />
                  <Tab
                    id="features-tab"
                    label={
                      <Typography variant="subtitle1">
                        {t('features')}
                      </Typography>
                    }
                    value={TabOptions.Features}
                  />
                  <Tab
                    id="media-tab"
                    label={
                      <Typography variant="subtitle1">{t('media')}</Typography>
                    }
                    value={TabOptions.Media}
                  />
                </Tabs>
              </Box>
            </Container>
          </Stack>
          <Container maxWidth="xl">
            <Box mt={3}>
              {currentTab === TabOptions.Remarketing && (
                <UserRemarketingDetails
                  isLoading={isLoading}
                  onUpdateData={handleUpdate}
                  remarketingVehicle={remarketingVehicle}
                />
              )}
            </Box>
            <Box mt={3}>
              {currentTab === TabOptions.Details && (
                <VehicleDetails
                  readonly
                  remarketingVehicle={remarketingVehicle}
                />
              )}
            </Box>
            <Box mt={3}>
              {currentTab === TabOptions.Features && (
                <RemarketingFeatures
                  readonly
                  remarketingVehicle={remarketingVehicle}
                />
              )}
            </Box>
            <Box mt={3}>
              {currentTab === TabOptions.Media && (
                <VehicleMediaSection
                  readonly
                  remarketingVehicle={remarketingVehicle}
                />
              )}
            </Box>
          </Container>
        </div>
      )}
    </div>
  );
};

export default UserRemarketingVehiclePage;
