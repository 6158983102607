import { Box, Container, Stack, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useLocation } from 'react-router-dom';

import { Provider } from 'jotai';
import { useBeforeUnload } from 'react-use';
import { ErrorState } from '../../common-ui/index.js';
import { useBrand } from '../../hooks/useBrand.js';
import ProgressBar, { Step } from '../components/ProgressBar.js';

const AddVehiclePage: FunctionComponent = () => {
  const { isEditor, brandConfig } = useBrand();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();

  const steps: Step[] = useMemo(() => {
    const baseRoute = '/:brand/dealers/:dealer/vehicles/add';

    return [
      { label: t('vinNumber'), to: baseRoute },
      {
        label: t('additionalDetails'),
        to: `${baseRoute}/details`,
      },
      ...(brandConfig?.addListing?.steps || []).map((step) => ({
        label: t(step),
        to: `${baseRoute}/${step}`,
      })),
      {
        label: t('media'),
        to: `${baseRoute}/media`,
      },
    ];
  }, [t, brandConfig]);

  useEffect(() => {
    const selected = steps.find((s) => {
      return matchPath({ path: s.to, end: true }, location.pathname);
    });

    if (selected) {
      setActiveStep(steps.indexOf(selected));
    }
  }, [location, steps]);

  useBeforeUnload(true, t('discard'));

  if (!isEditor) {
    return (
      <ErrorState
        alertTitle="Contact Support"
        error={t('insufficientPermissions')}
      />
    );
  }

  return (
    <div>
      <Box
        bgcolor="background.paper"
        py={{
          xs: 2,
          sm: 5,
        }}
      >
        <Container maxWidth="xl">
          <Box maxWidth={900}>
            <ProgressBar activeStep={activeStep} steps={steps} />
          </Box>
        </Container>
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          px: {
            sm: 2,
            xs: 0,
          },
        }}
      >
        <Stack
          display={{
            xs: 'none',
            sm: 'flex',
          }}
          py={{
            xs: 4,
            sm: 3,
          }}
          spacing={3}
        >
          <Typography fontSize={24} variant="title">
            {t('addNewVehicle')}
          </Typography>
        </Stack>
        <Provider>
          <Outlet />
        </Provider>
      </Container>
    </div>
  );
};

export default AddVehiclePage;
