import { lighten, Typography, TypographyProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { PaletteKey } from '../../ims/theme/default.js';
import { Label } from './Label.js';

export type TagProps = TypographyProps & {
  color?: PaletteKey;
};

export const Tag: FunctionComponent<TagProps> = (props) => {
  const {
    color = 'success',
    fontWeight = 'bold',
    variant = 'caption',
    ...rest
  } = props;
  return (
    <Label
      bgcolor={(theme) => lighten(theme.palette[color].main, 0.85)}
      sx={{
        borderRadius: 0.5,
        padding: '2px 6px;',
      }}
    >
      <Typography
        color={`${color}.dark`}
        fontWeight={fontWeight}
        variant={variant}
        {...rest}
      />
    </Label>
  );
};

export default Tag;
