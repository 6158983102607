import { GridColDef } from '@mui/x-data-grid';
import { ListRegionStockStatsResponse } from '../../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';

export const buildColumns = (
  data: ListRegionStockStatsResponse,
): GridColDef[] => {
  return [
    {
      field: 'title',
      headerName: '',
      flex: 1,
    },
    //Transforms regions to columns
    ...Array.from(new Set(data.map((item) => item.regionName))).map(
      (c): GridColDef => ({
        field: c,
        headerAlign: 'center',
        flex: 1,
        align: 'center',
      }),
    ),
    {
      field: 'total',
      headerName: 'Total',
      headerAlign: 'center',
      align: 'center',
    },
  ];
};
