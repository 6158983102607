export class HttpServiceTransportError<T = unknown> extends Error {
  public static readonly Name = 'HttpServiceTransportError';
  public static readonly Network = 'Network';
  public static readonly Unknown = 'Unknown';

  public static is(
    err: unknown,
    code?: string,
  ): err is HttpServiceTransportError {
    return (
      err instanceof HttpServiceTransportError &&
      (!err.code || err.code === code)
    );
  }

  constructor(
    public readonly httpStatus: number,
    public readonly code: string,
    public readonly details?: T,
  ) {
    super(`${HttpServiceTransportError.Name} (${httpStatus}): ${code}`);
    this.name = HttpServiceTransportError.Name;
  }
}
