import { DateTime } from 'luxon';

export function toNumber(
  value: string | number | Date | undefined | null,
): number | undefined {
  if (value === undefined) {
    return undefined;
  } else if (typeof value === 'number') {
    return value;
  } else if (typeof value === 'string') {
    const num = Number(value);
    if (!isNaN(num)) {
      return num;
    }
    const date = DateTime.fromISO(value);
    if (!date.invalidReason) {
      return date.toMillis();
    }
    return undefined;
  } else if (value instanceof Date) {
    const date = DateTime.fromJSDate(value, { zone: 'utc' });
    return date.isValid ? date.toMillis() : undefined;
  }
}
