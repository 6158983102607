import { merge } from 'lodash-es';

export interface Auth0Config {
  clientId: string;
  domain: string;
}

const config = merge(
  {
    auth: {
      clientId: process.env.REACT_APP_CONFIG_AUTH_CLIENT_ID,
      domain: process.env.REACT_APP_CONFIG_AUTH_DOMAIN,
    },
  },
  (window as any).app_config,
);

export function useAuth0Config(): Auth0Config {
  return config?.auth;
}
