import { enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface ListRegionModelStatsRequest {
  brand: AvailableBrands;
}

export const decodeListRegionModelStatsRequest =
  object<ListRegionModelStatsRequest>({
    brand: enumValue(AvailableBrands),
  });
