/** Converts a number to a string by using the current or specified locale.
 * @param value The value to be formatted must be between 0 and 1 - 0.55 ==> 55%.
 * @param locales A locale string, array of locale strings, Intl.Locale object, or array of Intl.Locale objects that contain one or more language or locale tags. If you include more than one locale string, list them in descending order of priority so that the first entry is the preferred locale. If you omit this parameter, the default locale of the JavaScript runtime is used.
 * @param options An object that contains one or more properties that specify comparison options.
 * @returns Formatted number to percent.
 */
export const formatPercentage = (
  value: number,
  options: Omit<Intl.NumberFormatOptions, 'style'> = {
    minimumFractionDigits: 0,
  },
  locales?: Intl.LocalesArgument,
): string => value.toLocaleString(locales, { style: 'percent', ...options });

/** Calculates the percentage of a number, given two values and always rounding down.
 * @param total total number.
 * @param value value to be calculated.
 * @returns Formatted percentage number with '%' symbol.
 * @example
 * 99.01 --> 99
 * 99.5 --> 99
 * 99.9 --> 99
 */
export const getPercentageRoundingDown = (
  total: number,
  value: number,
): string | undefined => {
  if (total <= 0) {
    console.error('getPercentageRoundingDown - "total" cannot be 0 or lower.');
    return;
  }

  if (value > total) {
    console.error(
      'getPercentageRoundingDown - "value" cannot be greater than "total".',
    );
    return;
  }

  const percentage = (value * 100) / total;
  return formatPercentage(Math.floor(percentage) / 100);
};

export function formatNumber(
  value: number,
  locale = navigator.language,
  options?: Intl.NumberFormatOptions,
): string {
  return new Intl.NumberFormat(locale, options).format(value);
}

export function getCurrencySymbol(
  currency: string,
  locale = navigator.language,
): string {
  const result = new Intl.NumberFormat(locale, {
    style: 'currency',
    currency: currency,
  }).formatToParts(1);

  return result.find((x) => x.type === 'currency')?.value || '';
}
