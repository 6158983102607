export const RemarketingServiceName = 'RemarketingService';

export enum RemarketingVehicleSort {
  START = 'startingDate',
  END = 'closingDate',
  MODEL = 'vehicle.model.name.keyword',
}

export enum RelistRemarketingErrors {
  RELIST_INVALID_STATUS = 'RELIST_INVALID_STATUS',
}

export enum GetRemarketingErrors {
  NOT_ACCESSIBLE = 'NOT_ACCESSIBLE',
}

export enum SubmitOfferErrors {
  NOT_ACTIVE = 'NOT_ACTIVE',
  NO_OFFERS = 'NO_OFFERS',
  INVALID_OFFER = 'INVALID_OFFER',
}
