import { Card, CardContent } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';
import { useTranslation } from 'react-i18next';
import { ErrorState } from '../../common-ui/index.js';
import ReportCardSkeleton from './ReportCardSkeleton.js';

export interface ReportCardWrapperProps extends PropsWithChildren {
  loading?: boolean;
  error?: string;
}

const ReportCardWrapper: FunctionComponent<ReportCardWrapperProps> = ({
  loading,
  children,
  error,
}) => {
  const { t } = useTranslation();
  if (error) {
    return (
      <Card>
        <CardContent>
          <ErrorState alertTitle={t('insufficientPermissions')} error={error} />
        </CardContent>
      </Card>
    );
  }
  return loading ? <ReportCardSkeleton /> : <>{children}</>;
};

export default ReportCardWrapper;
