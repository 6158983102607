import { SettingsOutlined } from '@mui/icons-material';
import {
  Collapse,
  Divider,
  Link,
  List,
  Stack,
  Typography,
} from '@mui/material';
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useInView } from 'react-intersection-observer';
import { useNavigate } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { InAppNotification } from '../../../../api/util/InAppNotification.js';
import { EmptyState, Switch } from '../../../common-ui/index.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { sx } from '../../../util/sx.js';
import { useDeleteAllInAppNotifications } from '../../hooks/mutations/useDeleteAllInAppNotifications.js';
import { useDeleteInAppNotification } from '../../hooks/mutations/useDeleteInAppNotification.js';
import { useMarkAllNotificationsAsRead } from '../../hooks/mutations/useMarkAllNotificationsAsRead.js';
import { useMarkInAppNotificationAsRead } from '../../hooks/mutations/useMarkInAppNotificationAsRead.js';
import { useListInAppNotifications } from '../../hooks/queries/useListInappNotifications.js';
import { NotificationItem, NotificationSkeleton } from './NotificationItem.js';

const styles = sx({
  container: {
    p: 2,
    mr: 4,
    pr: 0,
  },

  list: {
    maxHeight: '75vh',
    overflowY: 'auto',
    overflowX: 'visible',
    maxWidth: '80vw',
    width: 500,
    pr: 3,
    pt: 0,
    scrollbarGutter: 'stable both-edges',
    scrollPadding: 0,
    scrollMargin: 0,
    scrollSnapMargin: 0,
  },
  divider: {
    marginBottom: 2,
    marginTop: 1,
    marginLeft: 2,
    marginRight: 5,
  },
});

export interface NotificationsListNewProps {
  onDeleteNotificationClick?: (notificationId: string) => void;
  onNotificationClick?: (notification: InAppNotification) => void;
  onSettingsClick?: () => void;
}

export const NotificationsList = ({
  onSettingsClick,
  onNotificationClick,
  onDeleteNotificationClick,
}: NotificationsListNewProps) => {
  const { t } = useTranslation();
  const [unreadOnly, setUnreadOnly] = useState(false);
  const { data, isSuccess, fetchNextPage, hasNextPage, isFetchingNextPage } =
    useListInAppNotifications(unreadOnly);
  const navigate = useNavigate();
  const { isRemarketingAdmin } = useCurrentUser();

  const { mutate: deleteNotification } = useDeleteInAppNotification();
  const { mutate: deleteAllNotifications } = useDeleteAllInAppNotifications();
  const { mutate: markAllRead } = useMarkAllNotificationsAsRead();
  const { mutate: markAsRead } = useMarkInAppNotificationAsRead();
  const { ref, inView } = useInView();

  const onDeleteClick = (notificationId: string) => {
    deleteNotification({ notificationId });
    onDeleteNotificationClick && onDeleteNotificationClick(notificationId);
  };

  const handleNotificationClick = (notification: InAppNotification) => {
    if (notification.link) {
      const url = new URL(notification.link || '');
      navigate(url.pathname);
    }
    markAsRead({
      currentValue: notification.read,
      notificationId: notification.id,
    });
    onNotificationClick && onNotificationClick(notification);
  };

  useEffect(() => {
    if (inView && hasNextPage && !isFetchingNextPage) {
      void fetchNextPage();
    }
  }, [isFetchingNextPage, hasNextPage, fetchNextPage, inView]);

  const notifications = data?.pages.flatMap((p) => p.items);

  return (
    <Stack>
      <Stack direction="row" justifyContent="space-between" pl={2} pr={5}>
        <Typography gutterBottom variant="title">
          {t('notifications.title', {
            total: data?.pages[0]?.unreadCount || 0,
          })}
        </Typography>
        <Stack alignItems="center" direction="row" spacing={1}>
          <Switch
            onChange={(e) => setUnreadOnly(e.target.checked)}
            value={unreadOnly}
          />
          <Typography variant="body2">
            {t('notifications.unreadOnly')}
          </Typography>
        </Stack>
      </Stack>
      <List sx={styles.list}>
        <TransitionGroup>
          {notifications?.map((notification) => (
            <Collapse appear key={notification.id}>
              <Link onClick={() => handleNotificationClick(notification)}>
                <NotificationItem
                  notification={notification}
                  onDeleteClick={() => onDeleteClick(notification.id)}
                />
              </Link>
            </Collapse>
          ))}
        </TransitionGroup>
        {isSuccess && !notifications?.length && (
          <EmptyState>{t('notifications.empty')}</EmptyState>
        )}
        {isFetchingNextPage && <NotificationSkeleton />}
        <div ref={ref} />
      </List>
      <Divider sx={styles.divider} />
      <Stack
        direction="row"
        justifyContent="space-between"
        pl={2}
        pr={5}
        spacing={2}
      >
        {isRemarketingAdmin && (
          <Stack direction="row" spacing={1}>
            <SettingsOutlined />
            <Link
              component={Typography}
              onClick={onSettingsClick}
              variant="body2"
            >
              {t('notifications.settings')}
            </Link>
          </Stack>
        )}
        <Stack direction="row" flex={1} justifyContent="end" spacing={2}>
          <Link
            component={Typography}
            onClick={deleteAllNotifications}
            variant="body2"
          >
            {t('notifications.deleteAll')}
          </Link>
          <Link component={Typography} onClick={markAllRead} variant="body2">
            {t('notifications.markAllRead')}
          </Link>
        </Stack>
      </Stack>
    </Stack>
  );
};
