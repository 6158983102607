import ReactPDF, { Text } from '@react-pdf/renderer';

interface TruncatedTextProps extends ReactPDF.TextProps {
  text: string;
  maxLength: number;
}

export const TruncatedText = ({
  text,
  maxLength,
  style,
  ...props
}: TruncatedTextProps) => {
  const truncatedText =
    text.length > maxLength ? text.slice(0, maxLength) + '...' : text;

  return (
    <Text style={{ textOverflow: 'ellipsis', ...style }} {...props}>
      {truncatedText}
    </Text>
  );
};

export default TruncatedText;
