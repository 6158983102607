import { useMutation, UseMutationResult } from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { HttpServiceTransportError } from '../../../../api/util/HttpServiceTransportError.js';
import { VehicleListingErrorCode } from '../../../../api/VehicleListingService/common.js';
import { RelistVehicleListingRequest } from '../../../../api/VehicleListingService/relistVehicleListing/RelistVehicleListingRequest.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';

export const useRelistVehicleListing = (): UseMutationResult<
  void,
  unknown,
  RelistVehicleListingRequest
> => {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (request) => {
      await api.vehicleListing.relistVehicleListing(request);
    },
    onSuccess: () => {
      showMessage({
        text: t('pages.vehiclePage.header.creatingRelist'),
        severity: 'success',
        dismissible: true,
      });
    },
    onError: (error) => {
      if (
        HttpServiceTransportError.is(
          error,
          VehicleListingErrorCode.AlreadyExits,
        ) ||
        HttpServiceTransportError.is(
          error,
          VehicleListingErrorCode.RelistPending,
        )
      ) {
        showMessage({
          text: t(`VehicleListingErrorCode.${error.code}`),
          severity: 'error',
          dismissible: true,
        });
        return;
      }
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    },
  });
};
