import { Info } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ListRegionStockStatsResponse } from '../../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import { EmptyState } from '../../../common-ui/index.js';
import { sequenceColour } from '../../../util/index.js';
import { ChartDataType, IGridRow } from '../ChartReports.js';
import MainDrawer from '../MainDrawer.js';
import { options } from './chartConfig.js';
import { buildColumns } from './dataGridConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export interface ImagesProps {
  data: ListRegionStockStatsResponse;
}

const Images: FunctionComponent<ImagesProps> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const reportTitle = t('reports.images.title');

  const chartData: ChartDataType = useMemo(() => {
    return {
      labels: data.map((item) => item.regionName),
      datasets: [
        {
          label: t('reports.images.average'),
          data: data.map((region) => region.averagePhotosPerLiveListing || 0),
          backgroundColor: sequenceColour(0),
        },
      ],
    };
  }, [data, t]);

  const gridRows: IGridRow[] = useMemo(() => {
    const result = data.reduce(
      (acc, region) => {
        const globalAmountOfVehiclesLive =
          (acc.globalAmountOfVehiclesLive || 0) + region.amountOfVehiclesLive;
        const globalTotalPhotosOnLiveListings =
          (acc.globalTotalPhotosOnLiveListings || 0) +
          region.totalPhotosOnLiveListings;

        const item = {
          ...acc,
          [region.regionName]: region.averagePhotosPerLiveListing,
          globalAmountOfVehiclesLive,
          globalTotalPhotosOnLiveListings,
          total: Math.floor(
            globalTotalPhotosOnLiveListings / globalAmountOfVehiclesLive,
          ),
        };

        return item;
      },
      {
        globalTotalPhotosOnLiveListings: 0,
        globalAmountOfVehiclesLive: 0,
        total: 0,
      },
    );

    return [{ title: t('reports.images.average'), ...result }];
  }, [data, t]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleOpen}>
              <Info />
            </IconButton>
          }
          subheader={t('reports.images.subtitle')}
          title={reportTitle}
        />
        <CardContent>
          {data.length === 0 ? (
            <EmptyState />
          ) : (
            <Bar data={chartData} options={options} />
          )}
        </CardContent>
      </Card>
      <MainDrawer onClose={handleClose} open={open} title={reportTitle}>
        <DataGrid
          autoHeight
          columns={buildColumns(data)}
          getRowId={(r) => r.title}
          hideFooter
          rows={gridRows}
        />
      </MainDrawer>
    </>
  );
};

export default React.memo(Images);
