import { enumValue, is, maybe, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { RemarketingActionType } from '../../util/RemarketingVehicle.js';

export interface UserListRemarketingActionsRequest {
  brand: AvailableBrands;
  remarketingVehicleId: string;
  dealerId: number;
  actionType: RemarketingActionType;
  nextToken?: string;
  order?: 'asc' | 'desc';
}

export const decodeUserListRemarketingActionsRequest =
  object<UserListRemarketingActionsRequest>({
    brand: enumValue(AvailableBrands),
    remarketingVehicleId: text,
    actionType: enumValue(RemarketingActionType),
    dealerId: number,
    nextToken: maybe(text),
    order: maybe(is('asc', 'desc')),
  });
