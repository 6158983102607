import { useForm } from '../context/FormContext.js';
import { FieldError } from './FieldError.js';

export interface FormFieldErrorProps {
  className?: string;
  name: string;
}

export function FormFieldError({
  className,
  name,
}: FormFieldErrorProps): JSX.Element {
  const [state, , { translations }] = useForm();
  return (
    <FieldError
      className={className}
      error={state.errors[name]}
      translationBase={translations && `${translations}.errors`}
    />
  );
}
