import { sx } from '../../util/sx.js';

const sectionStyles = sx({
  sectionContent: {
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    gap: {
      xs: 3,
      sm: 6,
    },
  },
});

export default sectionStyles;
