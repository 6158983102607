import {
  array,
  boolean,
  maybe,
  number,
  object,
  string,
  text,
} from '@fmtk/decoders';

export interface AstonMartinDealerStock {
  vin: string;
  odometer: number | undefined;
  odometerUnit: string;
  registrationYear: number | undefined;
  price: number | undefined;
  currency: string;
  certified: boolean;
  model: string;
  standardFeatures: string[];
  optionalFeatures: string[];
  originalImages: string[];
  urlVehicleDetailPage: string | undefined;
}

export type ListAstonMartinDealerStockAPIResponse = {
  stock: AstonMartinDealerStock[];
  dealerStockURL: string;
};

export const decodeAstonMartinDealerStock = object<AstonMartinDealerStock>({
  vin: text,
  odometer: maybe(number),
  odometerUnit: text,
  registrationYear: maybe(number),
  price: maybe(number),
  currency: text,
  certified: boolean,
  model: text,
  standardFeatures: array(string),
  optionalFeatures: array(string),
  originalImages: array(string),
  urlVehicleDetailPage: string,
});

export const decodeListAstonMartinDealerStockAPIResponse =
  object<ListAstonMartinDealerStockAPIResponse>({
    dealerStockURL: string,
    stock: array(decodeAstonMartinDealerStock),
  });
