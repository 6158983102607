import { LoadingButton } from '@mui/lab';
import { Collapse, Divider, Grid, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { TransitionGroup } from 'react-transition-group';
import { OfferRemarketingAction } from '../../../../../api/util/RemarketingAction.js';
import { RemarketingActionType } from '../../../../../api/util/RemarketingVehicle.js';
import { UserFixedPriceRemarketingVehicle } from '../../../../../api/util/UserRemarketingVehicle.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import { EmptyState } from '../../../../common-ui/index.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { sx } from '../../../../util/sx.js';
import { useListUserOffers } from '../../../hooks/queries/useListUserOffers.js';
import { Section } from '../types.js';
import { OfferItem } from './Details/OfferHistoryItem.js';

const styles = sx({
  divider: {
    height: 2,
  },
  lost: {
    textDecoration: 'line-through',
  },
});

type OffersHistoryProps = Omit<Section, 'vehicle'> & {
  remarketingVehicle: UserFixedPriceRemarketingVehicle;
};

const OffersHistory: FunctionComponent<OffersHistoryProps> = ({
  title,
  remarketingVehicle,
  sectionItemsProps,
}) => {
  const { t } = useTranslation();
  const { currentBrand } = useBrand();
  const { dealer } = useParams();

  const {
    data: offers,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
    isLoading: loading,
  } = useListUserOffers(
    currentBrand && !!dealer
      ? {
          brand: currentBrand,
          dealerId: Number(dealer),
          remarketingVehicleId: remarketingVehicle.id,
          actionType: RemarketingActionType.OFFER,
          order: 'desc',
        }
      : undefined,
  );

  const loadMore = () => {
    if (!currentBrand || hasNextPage || isFetchingNextPage) {
      return;
    }
    void fetchNextPage();
  };

  const currency = remarketingVehicle.currency;

  const empty =
    offers?.pages[0]?.items?.length === 0 && !remarketingVehicle.currentOffer;

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      {empty ? (
        <EmptyState>
          {t(`pages.remarketingVehiclePage.fixedPrice.noOffersHistory`)}
        </EmptyState>
      ) : (
        <Stack spacing={2}>
          <Stack
            divider={<Divider color="#F7F8F9" sx={styles.divider} />}
            spacing={1}
          >
            <Grid container>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.dateTime',
                  )}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t('pages.remarketingVehiclePage.fixedPrice.offer')}
                </Typography>
              </Grid>
              <Grid item xs></Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t('pages.remarketingVehiclePage.fixedPrice.offerer')}
                </Typography>
              </Grid>
              <Grid item xs>
                <Typography fontWeight="fontWeightBold" variant="body2">
                  {t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.dealer',
                  )}
                </Typography>
              </Grid>
              <Grid item xs></Grid>
            </Grid>
            <TransitionGroup>
              {remarketingVehicle.currentOffer &&
                remarketingVehicle.currentOffer.type ===
                  RemarketingActionType.BUY_NOW && (
                  <Collapse>
                    <OfferItem
                      currency={currency}
                      highlight
                      item={remarketingVehicle.currentOffer}
                    />
                  </Collapse>
                )}
              {offers?.pages[0]?.items?.map((item) => (
                <Collapse key={item.id}>
                  <OfferItem
                    currency={currency}
                    item={item as OfferRemarketingAction} // safe cast as we are querying by action type
                    key={item.id}
                  />
                </Collapse>
              ))}
            </TransitionGroup>
          </Stack>
          {hasNextPage && (
            <div>
              <LoadingButton
                loading={loading}
                onClick={loadMore}
                variant="contained"
              >
                {t('loadMore')}
              </LoadingButton>
            </div>
          )}
        </Stack>
      )}
    </SectionItem>
  );
};

export default OffersHistory;
