import { array, maybe, object, text } from '@fmtk/decoders';
import { decodeUserDocument, UserDocument } from '../../util/UserDocument.js';

export interface ListUserDocumentsByReferenceResponse {
  items: UserDocument[];
  nextToken?: string;
}

export const decodeListUserDocumentsByReferenceResponse =
  object<ListUserDocumentsByReferenceResponse>({
    items: array(decodeUserDocument),
    nextToken: maybe(text),
  });
