import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import {
  EfficiencyClass,
  EuroEmissionClass,
} from '../../../../../api/util/Emission.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CellSelectInput } from '../../../../common-ui/index.js';
import { Section } from './types.js';

const Environmental: FunctionComponent<Omit<Section, 'vehicleListing'>> = ({
  title,
  sectionItemsProps,
  readonly,
}) => {
  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            <CellSelectInput
              label="environmentalLabel"
              name="environmentalLabel"
              options={Object.entries(EfficiencyClass).map(([k, v]) => ({
                text: v,
                value: k,
              }))}
              readonly={readonly}
            />
          </Stack>
          <Stack flex={1}>
            <CellSelectInput
              label="euroEmissionsClass"
              name="euroEmissionsClass"
              options={Object.entries(EuroEmissionClass).map(([k, v]) => ({
                text: v,
                value: k,
              }))}
              readonly={readonly}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Environmental;
