import { enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface DownloadInventoryRegionCsvRequest {
  brand: AvailableBrands;
}

export const decodeDownloadInventoryRegionCsvRequest =
  object<DownloadInventoryRegionCsvRequest>({
    brand: enumValue(AvailableBrands),
  });
