import { Image, StyleSheet, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';

interface LogoProps {
  model?: string;
}

interface logosProps {
  [key: string]: string;
}

const logos: logosProps = {
  Cullinan:
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738358/rolls-royce/CULLINAN_Logo.png',
  'Black Badge Cullinan':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1690988163/rolls-royce/BB_CULLINAN.png',
  Ghost:
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738358/rolls-royce/GHOST_Logo.png',
  'Black Badge Ghost':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1690988188/rolls-royce/BB_GHOST.png',
  'Ghost Extended':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738358/rolls-royce/GHOST_EXTENDED_Logo.png',
  Wraith:
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/WRAITH_Logo.png',
  'Black Badge Wraith':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1690988188/rolls-royce/BB_WRAITH.png',
  Dawn: 'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738358/rolls-royce/DAWN_Logo.png',
  'Black Badge Dawn':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1690988188/rolls-royce/BB_DAWN.png',
  Phantom:
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/PHANTOM-1_Logo.png',
  'Phantom VII':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/PHANTOM-1_Logo.png',
  'Phantom Extended':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/PHANTOM_EXTENDED_Logo.png',
  'Phantom VII Extended':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/PHANTOM_EXTENDED_Logo.png',
  'Phantom VII Coupe':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/PHANTOM_COUPE_Logo.png',
  'Phantom VII Drophead Coupe':
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/PHANTOM_Drophead_Coupe_Logo.png',
  Spectre:
    'https://res.cloudinary.com/motortrak/w_2.0,h_2.0,c_scale/v1688738356/rolls-royce/SPECTRE_Logo.png',
};

const styles = StyleSheet.create({
  headerImage: {
    marginTop: 30,
    width: '75%',
  },
  logo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '70%',
  },
});

const ModelLogo: FunctionComponent<LogoProps> = ({ model }) => {
  return (
    <View style={styles.logo}>
      {model && <Image src={logos[model]} style={styles.headerImage} />}
    </View>
  );
};

export default ModelLogo;
