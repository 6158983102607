export enum DamageStatus {
  DamageNotFixed = 'DamageNotFixed',
  DamageFixed = 'DamageFixed',
  NotRoadworthy = 'NotRoadworthy',
  Damaged = 'Damaged',
  AccidentFree = 'AccidentFree',
}

export const damageOptions = [
  {
    text: DamageStatus.DamageNotFixed,
    value: DamageStatus.DamageNotFixed,
  },
  {
    text: DamageStatus.DamageFixed,
    value: DamageStatus.DamageFixed,
  },
  {
    text: DamageStatus.NotRoadworthy,
    value: DamageStatus.NotRoadworthy,
  },
  {
    text: DamageStatus.Damaged,
    value: DamageStatus.Damaged,
  },
  {
    text: DamageStatus.AccidentFree,
    value: DamageStatus.AccidentFree,
  },
];
