import { AvailableBrands, joinUrl } from '../../../api/util/index.js';
import { isUrl } from '../../../api/util/isUrl.js';
import {
  VehicleMedia,
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../api/util/VehicleMedia.js';
import { loadCloudinaryConfig } from '../../../config/CloudinaryConfig.js';
import { assertCondition } from '../../../util/assertCondition.js';
import { RelevantImage } from '../../../util/vehicleMediaHelper.js';
import { GetBrandConfigCommand } from '../../DynamoDbService/commands/BrandConfig/GetBrandConfigCommand.js';
import { DatabaseClient } from '../../index.js';

const defaultEmailHeaderLogo =
  'https://res.cloudinary.com/motortrak/ims2/email-templates/connect_auto_logo.png';

export type ProcessMediaOptions = {
  prioritizeState?: VehicleMediaStatus.Approved | VehicleMediaStatus.Pending;
};

export type VehicleMediaStatusGrouped = {
  [status in VehicleMediaStatus]: VehicleMedia[];
};

// Function to check if vehicleMedia has a specific status and optional types
export function hasStatus<D extends Date | number>(
  vehicleMedia: VehicleMedia<D>[],
  status: VehicleMediaStatus,
  types?: VehicleMediaType[],
  position?: number,
): boolean {
  return vehicleMedia.some(
    (media) =>
      (!types || types.includes(media.type)) &&
      media.status === status &&
      (!position || media.position === position),
  );
}

export const getCloudinaryUrl = (
  cloudinaryId?: string,
  transformations?: string[] | string,
  config?: { cloudName: string; baseUrl: string },
): string | undefined => {
  if (!config || !cloudinaryId) {
    return;
  }

  if (isUrl(cloudinaryId).ok) {
    return cloudinaryId;
  }

  const { cloudName, baseUrl } = config;

  const path = transformations
    ? Array.isArray(transformations)
      ? transformations
      : [transformations]
    : '';

  return joinUrl(baseUrl, cloudName, ...path, cloudinaryId);
};

export function getRelevantMediaFromVehicleMediaFromConfig<
  M extends VehicleMedia<Date | number>,
>({
  vehicleMedia,
  config,
  options,
}: {
  vehicleMedia: M[];
  config?: { cloudName: string; baseUrl: string };
  options?: {
    displayProcessing?: boolean;
    mediaStatus?: VehicleMediaStatus[];
    mediaType?: VehicleMediaType[];
    position?: number;
    prioritizeState?: VehicleMediaStatus[];
    transformation?: string;
    ghostImage?: string;
  };
}): undefined | RelevantImage<M> {
  const {
    displayProcessing,
    mediaStatus,
    mediaType,
    position,
    prioritizeState = [VehicleMediaStatus.Approved],
    transformation,
    ghostImage,
  } = options || {};

  const hasApproved = hasStatus(
    vehicleMedia,
    VehicleMediaStatus.Approved,
    [VehicleMediaType.Image, VehicleMediaType.Legacy],
    position,
  );
  const hasPending = hasStatus(
    vehicleMedia,
    VehicleMediaStatus.Pending,
    [VehicleMediaType.Image, VehicleMediaType.Legacy],
    position,
  );
  const hasRejected = hasStatus(
    vehicleMedia,
    VehicleMediaStatus.Rejected,
    [VehicleMediaType.Image, VehicleMediaType.Legacy],
    position,
  );
  const hasProcessing = hasStatus(
    vehicleMedia,
    VehicleMediaStatus.Processing,
    [VehicleMediaType.Image, VehicleMediaType.Legacy],
    position,
  );
  const hasError = hasStatus(
    vehicleMedia,
    VehicleMediaStatus.Error,
    [VehicleMediaType.Image, VehicleMediaType.Legacy],
    position,
  );

  const relevantImages = vehicleMedia
    .filter(
      (image) =>
        (!position || image.position === position) &&
        (!mediaType || mediaType.includes(image.type)) &&
        (!mediaStatus || mediaStatus.includes(image.status)),
    )
    .sort(
      (a, b) =>
        (a.position || 0) - (b.position || 0) ||
        (a.uploadDate && b.uploadDate
          ? typeof b.uploadDate === 'number'
            ? b.uploadDate - (a.uploadDate as unknown as number)
            : b.uploadDate.getTime() - (a.uploadDate as Date).getTime()
          : 0),
    );

  if (!relevantImages || !relevantImages.length) {
    return ghostImage
      ? {
          hasRejected,
          hasPending,
          hasApproved,
          hasProcessing,
          hasError,
          isPlaceholder: true,
          mediaUrl: getCloudinaryUrl(ghostImage, undefined, config),
        }
      : undefined;
  }

  let relevantImage: M | undefined;

  // Loop through prioritizeState to find the first matching image
  for (const state of prioritizeState) {
    relevantImage = relevantImages.find(
      (image) =>
        (displayProcessing && image.status === VehicleMediaStatus.Processing) ||
        image.status === state,
    );
    if (relevantImage) {
      break;
    }
  }
  // If no image is found based on prioritizeState, use the first image in the array
  relevantImage = relevantImage || relevantImages[0];

  return {
    media: relevantImage,
    hasRejected,
    hasPending,
    hasApproved,
    hasProcessing,
    hasError,
    mediaUrl:
      getCloudinaryUrl(
        relevantImage?.cloudinaryId,
        transformation
          ? [...(relevantImage.transformations || []), transformation]
          : undefined,
        config,
      ) || relevantImage?.legacyUrl,
  };
}

export function getRelevantMediaFromVehicleMedia<
  M extends VehicleMedia<Date | number>,
>({
  vehicleMedia,
  options,
}: {
  vehicleMedia: M[];
  options?: {
    displayProcessing?: boolean;
    mediaStatus?: VehicleMediaStatus[];
    mediaType?: VehicleMediaType[];
    position?: number;
    prioritizeState?: VehicleMediaStatus[];
    transformation?: string;
    ghostImage?: string;
  };
}): undefined | RelevantImage<M> {
  const { cloudName, cloudinaryBaseUrl } = loadCloudinaryConfig();

  const config = {
    cloudName: cloudName,
    baseUrl: cloudinaryBaseUrl,
  };

  return getRelevantMediaFromVehicleMediaFromConfig({
    vehicleMedia,
    config,
    options,
  });
}

export type RelevantMedia<M extends VehicleMedia<Date | number>> = {
  emailHeaderLogo: string;
  media: undefined | RelevantImage<M>;
};

export async function getRelevantMedia<M extends VehicleMedia<Date | number>>(
  dep: { dynamoDb: DatabaseClient },
  brand: AvailableBrands,
  vehicleMedia: M[],
  transformation: string,
): Promise<RelevantMedia<M>> {
  const { Item: brandConfig } = await dep.dynamoDb.send(
    new GetBrandConfigCommand({
      Key: { brand },
    }),
  );

  assertCondition(brandConfig, `Brand config not found for ${brand}`);

  const media = getRelevantMediaFromVehicleMedia({
    vehicleMedia: vehicleMedia || [],
    options: {
      mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
      mediaStatus: [VehicleMediaStatus.Approved, VehicleMediaStatus.Pending],
      transformation,
      ghostImage: brandConfig.noVehicleImage,
    },
  });

  return {
    emailHeaderLogo: brandConfig.emailHeaderLogo || defaultEmailHeaderLogo,
    media,
  };
}
