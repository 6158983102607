/* eslint-disable no-restricted-imports */
import pusher from 'pusher-js';

/*
 * The pusher types are broken: the types are written as if the package is an
 * ESM package (using `export default`) but it is actually a CJS package. This
 * isn't compatible with the `Node16` setting for `module` in the tsconfig.json.
 *
 * There are two versions of this file (one for node and one for browser)
 * because the package also defines different entry points for these.
 */
export type Pusher = pusher.default;
export const Pusher = pusher as unknown as typeof pusher.default;

export * from 'pusher-js';
