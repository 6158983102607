import {
  Document,
  G,
  Image,
  Page,
  Path,
  StyleSheet,
  Svg,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';

import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { DealerSelectionType } from '../../../../hooks/useDealer.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';

const styles = StyleSheet.create({
  page: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  container: {
    border: `0.75px dashed #B3B3B3`,
    width: '298px',
    height: '421px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  border: {
    position: 'absolute',
    top: '-23px',
    left: '30px',
    zIndex: 1,
  },
  qrCode: {
    width: '91px',
    height: '91px',
    zIndex: -1,
  },
  logo: {
    width: '28px',
    height: '28px',
    position: 'absolute',
    top: '195.5px',
    zIndex: 0,
    backgroundColor: '#FFF',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  imageLogo: {
    width: '10px',
  },
});

const ScissorsSVG = () => (
  <Svg
    height={28}
    style={{ transform: 'rotate(60deg)' }}
    viewBox="0 0 32.44 32.44"
    width={48}
  >
    <G stroke-width="0" />
    <G
      stroke="#CCCCCC"
      stroke-linecap="round"
      stroke-linejoin="round"
      stroke-width="0.129756"
    ></G>
    <G>
      <Path
        d="M16.977,24.209c-0.342-0.307-0.734-0.529-1.155-0.677l1.994-7.005l0.524-0.122c0,0,13.814-1.547,13.545-8 c-0.331-0.444-12.238,4.35-12.238,4.35l-0.844,0.299l0.16-0.563c0,0,4.03-12.188,3.566-12.491 c-6.458,0.141-7.125,14.025-7.125,14.025l-0.042,0.249L8.797,16.6c-0.14-0.194-0.298-0.377-0.481-0.543 c-1.666-1.497-4.5-1.068-6.317,0.955c-1.817,2.022-1.941,4.885-0.275,6.383c1.666,1.496,4.5,1.067,6.317-0.955 c1.063-1.185,1.542-2.654,1.427-3.972l5.405-1.257l-1.028,6.16c-1.134,0.179-2.285,0.789-3.186,1.791 c-1.817,2.023-1.94,4.887-0.274,6.384s4.5,1.068,6.317-0.955C18.519,28.568,18.642,25.706,16.977,24.209z M7.071,21.568 c-1.337,1.488-3.345,1.871-4.475,0.855s-0.963-3.053,0.374-4.541c1.336-1.486,3.344-1.871,4.475-0.855 C8.575,18.043,8.407,20.08,7.071,21.568z M16.709,15.688c-0.377-0.338-0.408-0.919-0.069-1.296 c0.338-0.376,0.918-0.407,1.295-0.069c0.378,0.338,0.408,0.919,0.07,1.295C17.666,15.996,17.086,16.025,16.709,15.688z M11.257,30.576c-1.131-1.016-0.964-3.053,0.373-4.541s3.344-1.871,4.475-0.855c1.131,1.017,0.963,3.053-0.374,4.541 C14.394,31.208,12.387,31.592,11.257,30.576z"
        fill="#B3B3B3"
        stroke-linecap="round"
        stroke-linejoin="round"
      />
    </G>
  </Svg>
);

interface QRCodePOSProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
  dealer?: DealerSelectionType;
  DealerQrCode?: string;
}

const QRCodePOS: FunctionComponent<QRCodePOSProps> = ({ DealerQrCode }) => {
  return (
    <Document>
      <Page size="A4" style={styles.page}>
        <View style={styles.container}>
          <View style={styles.logo}>
            <Image
              src="https://res.cloudinary.com/motortrak/image/upload/v1726481563/rolls-royce/POS/Rolls-Royce-Logo.jpg"
              style={styles.imageLogo}
            />
          </View>
          <View style={styles.border}>
            <ScissorsSVG />
          </View>
          <Image src={DealerQrCode} style={styles.qrCode} />
        </View>
      </Page>
    </Document>
  );
};

export default QRCodePOS;
