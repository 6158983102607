import { enumValue, maybe, number, object, string } from '@fmtk/decoders';
import { AvailableBrands } from './AvailableBrands.js';

export enum UserDocumentSource {
  S3 = 'S3',
  Link = 'Link',
}

export enum UserDocumentScope {
  // deliberate choice of value to avoid conflict with entity types
  RemarketingAttachment = 'RMA',
  RemarketingTNCs = 'RMTNCS',
}

export interface UserDocument {
  brand: AvailableBrands;
  contentType?: string;
  createdAt: number;
  id: string;
  name: string;
  reference: string;
  size?: number;
  source: UserDocumentSource;
  uploadedBy: string;
  url: string;
  scope: UserDocumentScope;
}

export const decodeUserDocument = object<UserDocument>({
  brand: enumValue(AvailableBrands),
  contentType: maybe(string),
  createdAt: number,
  id: string,
  name: string,
  reference: string,
  scope: enumValue(UserDocumentScope),
  size: maybe(number),
  source: enumValue(UserDocumentSource),
  uploadedBy: string,
  url: string,
});
