import { enumValue, maybe, number, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { UserDocumentScope } from '../../util/UserDocument.js';

export interface GetUserDocumentRequest {
  brand: AvailableBrands;
  scope: UserDocumentScope;
  id: string;
  dealerId?: number;
}

export const decodeGetUserDocumentRequest = object<GetUserDocumentRequest>({
  brand: enumValue(AvailableBrands),
  scope: enumValue(UserDocumentScope),
  id: string,
  dealerId: maybe(number),
});
