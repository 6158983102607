import { ClientError } from './ClientError.js';

export class UnauthorizedError extends ClientError {
  constructor(service: string, public readonly method: string) {
    super(
      service,
      'Unauthorized',
      `need to be authenticated to access ${service}:${method}`,
      undefined,
      401,
    );
  }
}
