import { useCallback } from 'react';
import {
  UserDocument,
  UserDocumentSource,
} from '../../api/util/UserDocument.js';
import { useApiClient } from './useApiClient.js';
import { AsyncState, useAsyncState } from './useAsyncState.js';

export type UseDownloadDocumentController = (req: UserDocument) => void;

export function useDownloadDocument(): [
  AsyncState<string>,
  UseDownloadDocumentController,
] {
  const [fetchState, setFetchState] = useAsyncState<string>();
  const api = useApiClient();

  const request = useCallback(
    (doc: UserDocument) => {
      if (doc.source === UserDocumentSource.Link) {
        setFetchState(Promise.resolve(doc.url));
        window.open(doc.url, '_blank', 'noreferrer,noopener');
        return;
      }

      setFetchState(async () => {
        if (!doc.url) {
          return;
        }
        const { url } = await api.document.getDownloadDocumentUrl({
          key: doc.url,
        });
        await window.open(url, '_blank', 'noreferrer,noopener');
        return url;
      });
    },
    [fetch, setFetchState],
  );

  return [fetchState, request];
}
