import { InfoOutlined } from '@mui/icons-material';
import { Divider, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import { sx } from '../../../../util/sx.js';
import { Section } from '../types.js';

type StatusBoardProps = Omit<Section, 'vehicle'> & {
  items: {
    label: string;
    icon?: ReactNode;
    value?:
      | {
          text: string;
          path?: string;
          onClick?: () => void;
        }
      | (() => React.ReactNode);
  }[];
};

const styles = sx({
  body: {
    bgcolor: 'background.paper',
    border: 2,
    borderColor: '#BDC7D3',
    borderRadius: 0.5,
    flex: 1,
    mt: {
      xs: 0,
      md: 1,
    },
    p: 1,
  },
  divider: {
    height: 2,
  },
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

const StatusBoard: FunctionComponent<StatusBoardProps> = ({
  title,
  items,
  sectionItemsProps,
  isLoading,
}) => {
  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack
        divider={<Divider color="#F7F8F9" sx={styles.divider} />}
        justifyContent="space-between"
        spacing={1}
        sx={styles.body}
      >
        {items.map((item) => {
          return (
            <Stack
              direction="row"
              justifyContent="space-between"
              key={item.label}
            >
              <Stack direction="row" spacing={1}>
                {item.icon || <InfoOutlined htmlColor="#44546F" />}
                <Typography color="#44546F" variant="subtitle2">
                  {item.label}
                </Typography>
              </Stack>
              {typeof item.value === 'function'
                ? item.value()
                : item.value && (
                    <Typography
                      color="#44546F"
                      component={item.value.path ? Link : 'span'}
                      onClick={item.value.onClick}
                      sx={styles.link}
                      to={item.value.path}
                      variant="subtitle2"
                    >
                      {item.value.text}
                    </Typography>
                  )}
            </Stack>
          );
        })}
      </Stack>
    </SectionItem>
  );
};

export default StatusBoard;
