import { array, number, object, text } from '@fmtk/decoders';

export interface CountDealersByRegionResponse {
  regionName: string;
  dealers: number;
}

export const decoderCountDealersByRegionResponse = array(
  object<CountDealersByRegionResponse>({
    regionName: text,
    dealers: number,
  }),
);
