import { array, maybe, number, object, string } from '@fmtk/decoders';

export interface FeedSyncStatusRequest {
  inboundFeedId: string;
  vins: string[];
  dealerId?: number;
}

export const decodeFeedSyncStatusRequest = object<FeedSyncStatusRequest>({
  inboundFeedId: string,
  vins: array(string),
  dealerId: maybe(number),
});
