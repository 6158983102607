import { jsonDate, number, object } from '@fmtk/decoders';

export interface BatchUploadListingResponse {
  batchId: number;
  processStartedAt: Date;
}

export const decodeBatchUploadListingResponse =
  object<BatchUploadListingResponse>({
    batchId: number,
    processStartedAt: jsonDate,
  });
