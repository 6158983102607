import { array, enumValue, maybe, object, text } from '@fmtk/decoders';
import { WsChannelType } from '../../../core/PusherService/WsChannel.js';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface ChannelAuthRequest {
  socketId: string;
  channelName: string;
  brand: AvailableBrands;
  dealerId?: string;
  channelSpecific?: {
    [WsChannelType.ImageQC]?: {
      skip?: string[];
      brand?: AvailableBrands;
      vin?: string;
    };
  };
}

export const decodeChannelAuthRequest = object<ChannelAuthRequest>({
  socketId: text,
  channelName: text,
  brand: enumValue(AvailableBrands),
  dealerId: maybe(text),
  channelSpecific: maybe(
    object({
      [WsChannelType.ImageQC]: maybe(
        object({
          skip: maybe(array(text)),
          brand: maybe(enumValue(AvailableBrands)),
          vin: maybe(text),
        }),
      ),
    }),
  ),
});
