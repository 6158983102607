import { array, number, object, optional } from '@fmtk/decoders';
import { Dealer, decodeDealer } from '../../util/Dealer.js';

export type ListDealersResponse = {
  data: Dealer[];
  totalRows?: number;
};

export const decodeListDealersResponse = object<ListDealersResponse>({
  data: array(decodeDealer),
  totalRows: optional(number),
});
