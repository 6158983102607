import { Box, Container, Stack, Typography } from '@mui/material';
import { useAtom, useSetAtom } from 'jotai';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useLocation, useParams } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import { ListRegionCountryResponse } from '../../../api/BrandService/listRegionCountry/ListRegionCountryResponse.js';
import { AddRemarketingVehicleRequest } from '../../../api/RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import {
  RemarketingType,
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../api/util/RemarketingVehicle.js';
import DetailsSkeleton from '../../common-ui/components/DetailsSkeleton.js';
import { ErrorState } from '../../common-ui/index.js';
import { useApiClient } from '../../hooks/useApiClient.js';
import { useAsyncState } from '../../hooks/useAsyncState.js';
import { useBrand } from '../../hooks/useBrand.js';
import { useCurrentUser } from '../../hooks/useCurrentUser.js';
import ProgressBar, { Step } from '../components/ProgressBar.js';
import HeaderSkeleton from '../components/Vehicle/HeaderSkeleton.js';
import {
  addRemarketingVehicleAtom,
  regionCountryOptionsAtom,
} from '../state/atoms.js';

const RelistRemarketingVehicle: FunctionComponent = (): JSX.Element => {
  const { remarketingId } = useParams();
  const { currentBrand } = useBrand();
  const api = useApiClient();
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  const setRegionOptions = useSetAtom(regionCountryOptionsAtom);
  const [relistState, setRelistState] = useAtom(addRemarketingVehicleAtom);
  const [remarketingVehicle, setRemarketingVehicle] =
    useAsyncState<RemarketingVehicle>();
  const [regions, setRegions] = useAsyncState<ListRegionCountryResponse>();
  const currentUser = useCurrentUser();

  useBeforeUnload(true, t('discard'));

  const steps: Step[] = useMemo(() => {
    const baseRoute = '/:brand/remarketing/management/relist/:remarketingId';

    return [
      {
        label: t('additionalDetails'),
        to: `${baseRoute}/details`,
      },
      {
        label: t('features'),
        to: `${baseRoute}/features`,
      },
      {
        label: t('media'),
        to: `${baseRoute}/media`,
      },
    ];
  }, [t]);

  useEffect(() => {
    const selected = steps.find((s) => {
      return matchPath({ path: s.to, end: true }, location.pathname);
    });

    if (selected) {
      setActiveStep(steps.indexOf(selected));
    }
  }, [location, steps]);

  useEffect(() => {
    setRemarketingVehicle(async () => {
      if (!currentBrand || !remarketingId) {
        return;
      }
      const remarketing = await api.remarketing.adminGetRemarketingVehicle({
        brand: currentBrand,
        remarketingVehicleId: remarketingId,
      });

      return remarketing;
    });
  }, [
    remarketingId,
    setRemarketingVehicle,
    api.remarketing,
    currentBrand,
    setRelistState,
  ]);

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setRegions(async () => {
      const listRegionCountry = await api.brand.listRegionCountry({
        brand: currentBrand,
        regions: currentUser.value?.policy.RemarketingAdmin as string[],
      });

      setRegionOptions(listRegionCountry);

      return listRegionCountry;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [api, currentBrand, currentUser]);

  useEffect(() => {
    if (!currentBrand || !remarketingVehicle.value) {
      return;
    }

    setRelistState({
      disableReservePrice: undefined,
      currentRemarketingVehicle: {
        ...remarketingVehicle.value,
        brand: currentBrand,
        type: RemarketingType.UNASSIGNED,
      } as AddRemarketingVehicleRequest,
    });
  }, [currentBrand, remarketingVehicle.value, setRelistState]);

  if (remarketingVehicle.loading || regions.loading) {
    return (
      <Stack gap={10}>
        <HeaderSkeleton />
        <DetailsSkeleton />
      </Stack>
    );
  }

  if (
    remarketingVehicle.value &&
    ![
      RemarketingVehicleStatus.CANCELLED,
      RemarketingVehicleStatus.CLOSED,
    ].includes(remarketingVehicle.value?.status)
  ) {
    return (
      <Stack gap={10}>
        <ErrorState
          error={t('pages.relistRemarketingVehicle.wrongState', {
            status: t(
              `remarketingVehicleStatus.${remarketingVehicle.value?.status}`,
            ),
          })}
          hideTitle
        />
      </Stack>
    );
  }

  if (!!remarketingVehicle.error) {
    return (
      <Stack gap={10}>
        <ErrorState />
      </Stack>
    );
  }

  return (
    <div>
      <Box
        bgcolor="background.paper"
        py={{
          xs: 2,
          sm: 5,
        }}
      >
        <Container maxWidth="xl">
          <Box maxWidth={900}>
            <ProgressBar activeStep={activeStep} steps={steps} />
          </Box>
        </Container>
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          px: {
            sm: 2,
            xs: 0,
          },
        }}
      >
        <Stack
          display={{
            xs: 'none',
            sm: 'flex',
          }}
          py={{
            xs: 4,
            sm: 3,
          }}
          spacing={3}
        >
          <Typography fontSize={24} variant="title">
            {t('pages.relistRemarketingVehicle.title')}
          </Typography>
        </Stack>
        {relistState.currentRemarketingVehicle && <Outlet />}
      </Container>
    </div>
  );
};

export default RelistRemarketingVehicle;
