import { AvailableBrands } from './AvailableBrands.js';

const mapAvailableBrandsByIds: { [key: number]: AvailableBrands } = {
  2: AvailableBrands.AstonMartin,
  6: AvailableBrands.Bentley,
  19: AvailableBrands.Kia,
  20: AvailableBrands.Lamborghini,
  34: AvailableBrands.RollsRoyce,
  262: AvailableBrands.Genesis,
};

export const getAvailableBrandFromId = (brandId: number): AvailableBrands =>
  mapAvailableBrandsByIds[brandId];

export const getBrandIdFromAvailableBrand = (
  brand: AvailableBrands,
): number => {
  for (const key in mapAvailableBrandsByIds) {
    if (mapAvailableBrandsByIds[key] === brand) {
      return parseInt(key);
    }
  }
  throw Error('Not a valid brand');
};
