import {
  enumValue,
  maybe,
  normaliseNumber,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { OdometerUnit } from '../../../../../../api/util/OdometerUnit.js';
import { SyndicationType } from '../../../../../../api/util/SyndicationType.js';
import { UserUnassignedRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import sectionStyles from '../../../../../common-ui/components/sectionStyle.js';
import {
  CellSelectInput,
  CellTextInput,
  Form,
} from '../../../../../common-ui/index.js';
import { useCreateForm } from '../../../../../hooks/useCreateForm.js';
import { useRemarketingConfig } from '../../../../../hooks/useRemarketingConfig.js';
import { VehicleListingFields } from '../../../../../util/VehicleListingFields.js';
import { formatPrice } from '../../../../../util/formatters.js';

type UnassignedDetailsProps = {
  vehicle: UserUnassignedRemarketingVehicle;
};

const UnassignedDetails: FunctionComponent<UnassignedDetailsProps> = ({
  vehicle,
}) => {
  const { brandSyndicationTypes, showVehicleListingField } =
    useRemarketingConfig();
  const form = useCreateForm(
    {
      odometer: maybe(normaliseNumber),
      shortVin: maybe(text),
      registrationPlate: maybe(text),
      recommendedRetailPrice: maybe(strToNum),
      odometerUnit: maybe(enumValue(OdometerUnit)),
      syndicationType: enumValue(SyndicationType),
    },
    () => void 0,
  );

  const [, formBind] = form;

  const resetForm = useCallback(() => {
    formBind.reset({
      odometer: vehicle.odometer?.value || '',
      odometerUnit: vehicle.odometer?.units || '',
      registrationPlate: vehicle.registrationPlate || '',
      shortVin: vehicle.shortVin || '',
      recommendedRetailPrice:
        vehicle.vehicle.rrp?.value && vehicle.currency
          ? formatPrice(vehicle.vehicle.rrp.value, vehicle.currency, {
              maximumFractionDigits: 2,
            })
          : '',
      syndicationType: vehicle.syndicationType,
    });
  }, [formBind, vehicle]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Form form={form} translations="pages.remarketingVehiclePage.auction.form">
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            {showVehicleListingField(VehicleListingFields.SyndicationType) && (
              <CellSelectInput
                label="syndicationType"
                name="syndicationType"
                options={brandSyndicationTypes || []}
              />
            )}
            <CellTextInput label="shortVin" name="shortVin" />
            <CellTextInput
              label="recommendedRetailPrice"
              name="recommendedRetailPrice"
            />
          </Stack>
          <Stack flex={1}>
            <CellTextInput label="odometer" name="odometer" />
            <CellTextInput label="odometerUnit" name="odometerUnit" />
            <CellTextInput label="regPlate" name="registrationPlate" />
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
};

export default UnassignedDetails;
