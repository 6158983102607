import { Upload } from '@mui/icons-material';
import { Box, Button, IconButton, styled, SxProps, Theme } from '@mui/material';
import { uniqueId } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../hooks/useConst.js';
import { isAcceptable } from '../../util/isAcceptable.js';

const HiddenInput = styled('input')({ display: 'none' });

const SquareButton = styled(IconButton)({
  boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)',
  backgroundColor: 'rgba(255, 255, 255, 0.4)',
  borderRadius: 5,
  ':hover': {
    backgroundColor: 'rgba(255, 255, 255, 1)',
  },
});

enum DropMode {
  Ready,
  Active,
  Unacceptable,
}

export interface FilePickerChildProps {
  active?: boolean;
  selectedFiles: File[] | null;
  unacceptable?: boolean;
  multiple?: boolean;
}

export interface FilePickerProps {
  accept?: string;
  onFileChange?: (files: File[]) => void;
  disabled?: boolean;
  multiple?: boolean;
  size?: 'small' | 'medium' | 'large';
  sx?: SxProps<Theme>;
  children?: (props: FilePickerChildProps) => React.ReactNode;
  selectedFiles?: File[] | null;
}

export function FilePicker({
  accept,
  size,
  onFileChange,
  multiple = false,
  disabled,
  sx,
  children,
  selectedFiles: externalValue,
}: FilePickerProps) {
  const isControlled = externalValue !== undefined;
  const inputId = useConst(() => uniqueId('id'));
  const [mode, setMode] = useState(DropMode.Ready);
  const [internalValue, setInternalValue] = useState<File[] | null>(null);
  const { t } = useTranslation();

  const inputRef = useRef<HTMLInputElement>(null);

  const triggerInputClick = () => {
    inputRef.current?.click();
  };

  const selectedFiles =
    externalValue === undefined ? internalValue : externalValue;

  const setSelectedFiles = useCallback(
    (files: File[]): void => {
      if (onFileChange) {
        onFileChange(files);
      }
      if (!isControlled) {
        setInternalValue(files);
      }
    },
    [isControlled, onFileChange],
  );

  const onDrag = useCallback(
    (e: React.DragEvent<HTMLDivElement>) => {
      e.preventDefault();
      const active = e.type === 'dragenter' || e.type === 'dragover';

      if (e.type === 'drop' && e.dataTransfer && e.dataTransfer.files.length) {
        const files = Array.from(e.dataTransfer.files);
        if (
          !accept ||
          (files.every((file) => isAcceptable(file, accept)) &&
            (multiple || files.length === 1))
        ) {
          setSelectedFiles(files);
          setMode(DropMode.Ready);
        } else {
          setMode(DropMode.Unacceptable);
        }
      } else {
        setMode(active ? DropMode.Active : DropMode.Ready);
      }
    },
    [accept, setSelectedFiles, multiple],
  );

  const handleFileInputChange = useCallback(
    (e: React.ChangeEvent<HTMLInputElement>) => {
      if (e.target?.files) {
        const files = Array.from(e.target.files);
        if (!accept || files.every((file) => isAcceptable(file, accept))) {
          setSelectedFiles(files);
        }
      } else {
        setSelectedFiles([]);
      }
    },
    [accept, setSelectedFiles],
  );

  useEffect(() => {
    setMode(DropMode.Ready);
  }, [externalValue]);

  return (
    <div
      onDragEnter={onDrag}
      onDragLeave={onDrag}
      onDragOver={onDrag}
      onDrop={onDrag}
    >
      <label htmlFor={inputId}>
        {size === 'small' ? (
          <Box sx={sx}>
            <SquareButton onClick={triggerInputClick} size="small">
              <Upload fontSize="small" />
            </SquareButton>
          </Box>
        ) : children ? (
          children({
            active: mode === DropMode.Active,
            selectedFiles,
            unacceptable: mode === DropMode.Unacceptable,
            multiple,
          })
        ) : (
          <Box sx={sx}>
            <Button
              component="span"
              disabled={disabled}
              size="small"
              variant="contained"
            >
              {t('upload')}
            </Button>
          </Box>
        )}
      </label>
      <HiddenInput
        accept={accept}
        disabled={disabled}
        id={inputId}
        multiple={multiple}
        onChange={handleFileInputChange}
        ref={inputRef}
        type="file"
      />
    </div>
  );
}
