import { enumValue, maybe, number, object, text } from '@fmtk/decoders';

export enum ArchiveVehicleListingProcessStatus {
  Creating = 'Creating',
  Error = 'Error',
  Complete = 'Complete',
}

export interface ArchiveVehicleListingProcess {
  vehicleListingId: string;
  principalId: string;
  status: ArchiveVehicleListingProcessStatus;
  createdAt: number;
  updatedAt?: number;
  _expireAt?: number; // in seconds
}

export const decodeArchiveVehicleListingProcess =
  object<ArchiveVehicleListingProcess>({
    vehicleListingId: text,
    principalId: text,
    status: enumValue(ArchiveVehicleListingProcessStatus),
    createdAt: number,
    updatedAt: maybe(number),
    _expireAt: maybe(number),
  });
