import { Divider, Stack, Typography } from '@mui/material';
import { ReactNode } from 'react';
import { Link } from 'react-router-dom';
import { useMobile } from '../../../hooks/useMobile.js';

interface DetailRowProps {
  value: ReactNode;
  label: string;
  path?: string;
}

const Details = ({ items }: { items: DetailRowProps[] }) => {
  const isMobile = useMobile('md');
  return (
    <Stack
      direction={{
        xs: 'column',
        md: 'row',
      }}
      divider={
        <Divider flexItem orientation={isMobile ? 'horizontal' : 'vertical'} />
      }
      flexWrap="wrap"
      spacing={1}
    >
      {items.map((item) => (
        <DetailItem key={item.label} {...item} />
      ))}
    </Stack>
  );
};

export const DetailItem = ({ value, label, path }: DetailRowProps) => {
  return (
    <Stack
      alignContent="center"
      direction="row"
      justifyContent="space-between"
      spacing={0.2}
    >
      <Typography
        color="grey.600"
        fontWeight="fontWeightMedium"
        variant="body2"
      >
        {`${label}: `}
      </Typography>
      {['string', 'number'].includes(typeof value) ? (
        <Typography
          color="grey.600"
          component={path ? Link : 'span'}
          fontWeight="fontWeightMedium"
          to={path}
          variant="body2"
        >
          {value}
        </Typography>
      ) : (
        value
      )}
    </Stack>
  );
};

export default Details;
