import { array, number, object } from '@fmtk/decoders';
import {
  decodeRemarketingVehicle,
  RemarketingVehicle,
} from '../../util/RemarketingVehicle.js';

export interface AdminListRemarketingVehiclesResponse {
  items: RemarketingVehicle[];
  count: number;
}

export const decodeAdminListRemarketingVehiclesResponse =
  object<AdminListRemarketingVehiclesResponse>({
    items: array(decodeRemarketingVehicle),
    count: number,
  });
