import _ from 'lodash';

export function dashIfUndefined<T>(
  value?: T,
  replaceIfUndefined = '-',
): string | T {
  return !value || (_.isArray<T>(value) && !value.length)
    ? replaceIfUndefined
    : value;
}
