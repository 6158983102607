import { ReservePriceStatus } from '../../api/util/UserRemarketingVehicle.js';

export function getReservePriceStatus(
  startPrice: number,
  reservePrice: number,
  currentPrice: number,
): ReservePriceStatus {
  return startPrice === reservePrice
    ? ReservePriceStatus.NoReserve
    : currentPrice >= reservePrice
    ? ReservePriceStatus.Met
    : ReservePriceStatus.NotMet;
}
