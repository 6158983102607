import { array, boolean, maybe, number, object, text } from '@fmtk/decoders';

export interface AdminBiddingHistory {
  id: string;
  timestamp: number;
  userEmail: string;
  dealerName: string;
  bid: number;
  leading: boolean;
}

export interface AdminListBiddingHistoryResponse {
  items: AdminBiddingHistory[];
  nextToken?: string;
}

export const decodeAdminBiddingHistory = object<AdminBiddingHistory>({
  id: text,
  bid: number,
  timestamp: number,
  userEmail: text,
  dealerName: text,
  leading: boolean,
});

export const decodeAdminListBiddingHistoryResponse =
  object<AdminListBiddingHistoryResponse>({
    items: array(decodeAdminBiddingHistory),
    nextToken: maybe(text),
  });
