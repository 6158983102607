import {
  Document,
  Font,
  Image,
  Page,
  StyleSheet,
  Text,
  View,
} from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { PrintableVehicle } from '../../../../../api/util/PrintableVehicle.js';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { vehiclePhotoUrls } from '../../../../../util/vehicleMediaHelper.js';
import { DealerSelectionType } from '../../../../hooks/useDealer.js';
import { dashIfUndefined } from '../../../../util/dashIfUndefined.js';
import { formatDate } from '../../../../util/dates.js';
import {
  formatMaxSpeed,
  formatPrice,
  fromMpgToLitresPer100,
} from '../../../../util/formatters.js';
import { VehicleHandDrive } from '../../../../util/HandDrive.js';
import { formatNumber } from '../../../../util/numbers.js';
import { PdfConfigType } from '../../../../util/pdfConfig.js';
import { ReportTypeBase } from '../makePDF.js';
import TruncatedText from '../TruncatedText.js';
import { Bentley } from './fonts.js';
import PdfHeader from './PdfHeader.js';

export interface OptionsLandscapePdfProps extends ReportTypeBase {
  vehicle: PrintableVehicle;
  pdfConfig: PdfConfigType;
  dealer?: DealerSelectionType;
}
Font.register(Bentley);

const styles = StyleSheet.create({
  page: {
    paddingHorizontal: 45,
    paddingVertical: 10,
    fontFamily: 'Bentley',
    fontSize: 12,
    color: '#000000',
  },
  header: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  name: {
    fontSize: 18,
    flexDirection: 'row',
    textTransform: 'uppercase',
    justifyContent: 'space-between',
    fontWeight: 'light',
    paddingTop: 14,
    paddingBottom: 5,
  },
  detailsContainer: {
    fontSize: 9,
    marginBottom: 10,
    fontWeight: 'light',
  },
  marginRight10: {
    marginRight: 10,
  },
  section: {
    flexDirection: 'row',
  },
  fifty: {
    width: '50%',
  },
  column: {
    flexDirection: 'column',
    flexGrow: 1,
  },
  flexRow: {
    flex: 1,
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  flexColumn: {
    flexGrow: 1,
    flexDirection: 'column',
  },
  padding: {
    padding: 4,
  },
  sectionItem: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    fontWeight: 'light',
  },
  sectionTitle: {
    color: '#000000',
    letterSpacing: 0.5,
    fontWeight: 400,
    fontSize: 15,
    marginBottom: 8,
  },
  sectionItemsContainer: {
    flexDirection: 'row',
    flexWrap: 'wrap',
  },
  sectionItemsLeftColumn: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
  },
  sectionItemsMiddleColumn: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
    padding: '0 30px',
  },
  sectionItemsRightColumn: {
    flexDirection: 'column',
    flexWrap: 'wrap',
    width: '33%',
  },
  lightDivider: {
    width: '100%',
    marginVertical: 16,
    borderBottom: '1px solid #cecece',
  },
  features: {
    flexDirection: 'column',
    width: '33%',
  },
  featureItem: {
    marginRight: 10,
    paddingVertical: 2,
    fontWeight: 'light',
  },
  certifiedImage: {
    height: 30,
    width: 'auto',
  },
  imageContainer: {
    width: '25%',
  },
  image: {
    height: 130,
    width: 'auto',
  },
  sectionFifty: {
    width: '50%',
  },
  legend: {
    fontSize: 15,
    marginBottom: 10,
    fontWeight: 'light',
  },
  note: {
    fontSize: 9,
    color: '#222222',
  },
  dealerComments: {
    color: '#222222',
    fontWeight: 'light',
    fontSize: 9,
    marginRight: 10,
  },
  summaryPriceContainer: {
    flexDirection: 'column',
    flexWrap: 'wrap',
  },
  namePriceRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  nameColumn: {
    flexDirection: 'column',
    width: '80%',
  },
  priceColumn: {
    flexDirection: 'column',
    alignItems: 'flex-end',
  },
});

interface SectionItemProps {
  label: string;
  value?: string;
}

const SectionItem = ({ label, value = '-' }: SectionItemProps): JSX.Element => (
  <View style={styles.sectionItem}>
    <Text>{label}:</Text>
    <Text>{value}</Text>
  </View>
);

interface SectionProps {
  children: React.ReactElement;
  title: string;
}

const Section = ({ children, title }: SectionProps): JSX.Element => (
  <View style={styles.detailsContainer}>
    <Text style={styles.sectionTitle}>{title}</Text>
    {children}
  </View>
);

const FeatureColumn = ({ features }: { features: string[] }): JSX.Element => (
  <View style={styles.features}>
    {features.map((feature, i) => (
      <View key={i} style={styles.featureItem}>
        <Text>{feature}</Text>
      </View>
    ))}
  </View>
);

export const OptionsLandscapePdf: FunctionComponent<
  OptionsLandscapePdfProps
> = ({ vehicle, pdfConfig, dealer }): JSX.Element => {
  const { noVehicleImage } = pdfConfig;
  const vehicleMedia = vehiclePhotoUrls(
    vehicle.vehicleMedia,
    't_base_vehicle_transformation/f_jpg',
  );

  const [f1, f2, f3, f4, f5, f6, f7, f8, f9] = Object.values(
    vehicle.vehicle.features || Array(9).fill(''),
  ).flat();

  return (
    <Document>
      <Page orientation="landscape" size="A4" style={styles.page}>
        <PdfHeader dealer={dealer} />

        {/* VEHICLE IMAGES */}
        <View style={styles.section}>
          <View style={{ maxHeight: 130, flexDirection: 'row' }}>
            <View style={styles.imageContainer}>
              <Image
                src={vehicleMedia[0] || noVehicleImage}
                style={styles.image}
              />
            </View>
            <View style={styles.imageContainer}>
              <Image
                src={vehicleMedia[1] || noVehicleImage}
                style={styles.image}
              />
            </View>
            <View style={styles.imageContainer}>
              <Image
                src={vehicleMedia[2] || noVehicleImage}
                style={styles.image}
              />
            </View>
            <View style={styles.imageContainer}>
              <Image
                src={vehicleMedia[3] || noVehicleImage}
                style={styles.image}
              />
            </View>
          </View>
        </View>
        <View style={styles.summaryPriceContainer}>
          <View style={styles.namePriceRow}>
            <View style={styles.nameColumn}>
              {/* VEHICLE NAME  */}
              <Text
                style={styles.name}
              >{`${vehicle.vehicle.modelYear} ${vehicle.vehicle.model.name}`}</Text>
              {/* SUMMARY INFO */}
              <Section title="Summary Info">
                <View style={styles.sectionItemsContainer}>
                  <View style={styles.sectionItemsLeftColumn}>
                    {[
                      {
                        label: 'Exterior Paint',
                        value: dashIfUndefined(
                          vehicle.vehicle.appearanceOptions?.exteriorColour,
                        ),
                      },
                      {
                        label: 'Veneer',
                        value: dashIfUndefined(
                          vehicle.vehicle.appearanceOptions?.veneer,
                        ),
                      },
                      {
                        label: 'Engine',
                        value: dashIfUndefined(
                          vehicle.vehicle.engine?.cylinders,
                        ),
                      },
                      {
                        label: 'Drive Position',
                        value: dashIfUndefined(
                          vehicle.vehicle.handDrive
                            ? VehicleHandDrive[vehicle.vehicle.handDrive]
                            : undefined,
                        ),
                      },
                    ].map((item) => (
                      <SectionItem
                        key={item.label}
                        label={item.label}
                        value={item.value.toString()}
                      />
                    ))}
                  </View>
                  <View style={styles.sectionItemsMiddleColumn}>
                    {[
                      {
                        label: 'Main Hide',
                        value: dashIfUndefined(
                          vehicle.vehicle.appearanceOptions?.interiorColour,
                        ),
                      },
                      {
                        label: 'Power',
                        value: dashIfUndefined(vehicle.vehicle.engine?.bhp),
                      },
                      {
                        label: 'Odometer',
                        value: `${dashIfUndefined(
                          vehicle.odometer?.value
                            ? formatNumber(vehicle.odometer.value)
                            : undefined,
                        )} ${vehicle.odometer?.units}`,
                      },
                      {
                        label: 'Registration Date',
                        value: dashIfUndefined(
                          vehicle.vehicle.registrationDate
                            ? formatDate(vehicle.vehicle.registrationDate)
                            : undefined,
                        ),
                      },
                    ].map((item) => (
                      <SectionItem
                        key={item.label}
                        label={item.label}
                        value={String(item.value)}
                      />
                    ))}
                  </View>
                  <View style={styles.sectionItemsRightColumn}>
                    {[
                      {
                        label: 'Secondary Hide',
                        value: dashIfUndefined(
                          vehicle.vehicle.appearanceOptions
                            ?.secondaryInteriorColour,
                        ),
                      },
                      {
                        label: 'Maximum Speed',
                        value: formatMaxSpeed(
                          vehicle.vehicle.techSpecs?.maxSpeedMph,
                          vehicle.vehicle.techSpecs?.maxSpeedKph,
                          {
                            style: 'MPH(KPH)',
                          },
                        ),
                      },
                      {
                        label: 'Body Style',
                        value: dashIfUndefined(vehicle.vehicle.bodyStyle?.name),
                      },
                      { label: 'VIN', value: vehicle.vehicle.vin },
                    ].map((item) => (
                      <SectionItem
                        key={item.label}
                        label={item.label}
                        value={item.value}
                      />
                    ))}
                  </View>
                </View>
              </Section>
            </View>
            <View style={styles.priceColumn}>
              <Text style={styles.name}>
                {formatPrice(vehicle.price?.retail, vehicle.currency?.code)}
              </Text>
              {pdfConfig.certifiedLogo &&
                vehicle.syndicationType === SyndicationType.Certified && (
                  <Image
                    src={pdfConfig.certifiedLogo}
                    style={styles.certifiedImage}
                  />
                )}
            </View>
          </View>
        </View>
        {/* ADDITIONAL FEATURES AND CONSUMPTION */}
        <View style={styles.flexRow}>
          <View style={{ width: '70%' }}>
            <Section title="Additional Features">
              <View style={{ flexGrow: 1, flexDirection: 'row' }}>
                <FeatureColumn features={[f1, f2, f3]} />
                <FeatureColumn features={[f4, f5, f6]} />
                <FeatureColumn features={[f7, f8, f9 ? 'others...' : '']} />
              </View>
            </Section>
          </View>
          <View style={{ width: '30%' }}>
            <Section title="Consumption">
              <View style={styles.flexColumn}>
                {[
                  {
                    label: 'Fuel consumption',
                    value: `${fromMpgToLitresPer100(
                      Number(
                        vehicle.vehicle.wltpTest?.consumptionCombined || 0,
                      ),
                    )} 1/100km (WLTP)`,
                  },
                  {
                    label: 'CO2 emissions',
                    value: `${
                      vehicle.vehicle.wltpTest?.co2Emissions || 0
                    } g/km (WLTP)`,
                  },
                ].map((item) => (
                  <SectionItem
                    key={item.label}
                    label={item.label}
                    value={item.value}
                  />
                ))}
              </View>
            </Section>
          </View>
        </View>
        {/* FOOTER */}
        <View style={{ flexDirection: 'row' }}>
          <View style={styles.sectionFifty}>
            {vehicle.publicComments && (
              <Section title="Dealer Comments">
                <TruncatedText
                  maxLength={350}
                  style={styles.dealerComments}
                  text={vehicle.publicComments}
                />
              </Section>
            )}
          </View>
          <View style={styles.sectionFifty}>
            <View style={styles.legend}>
              <Text>
                We obsess over every detail. Because we know you will too.
              </Text>
            </View>
            <View style={styles.note}>
              <Text>
                Only your Bentley Pre-Owned Retailer can offer you the
                confidence that your car is exactly as was intended. Inspected
                and tested by Bentley trained technicians, we’ll provide you
                with a fully validated service history and check it against the
                original build specification to ensure authenticity.
              </Text>
            </View>
          </View>
        </View>
      </Page>
    </Document>
  );
};

export default OptionsLandscapePdf;
