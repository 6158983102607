import { Decoder, invalid, ok } from '@fmtk/decoders';
import { DateTime } from 'luxon';

const InvalidDate = 'INVALID_DATE';

export function dateToNumber(options?: {
  format?: string;
  locale?: string;
}): Decoder<number> {
  return (value) => {
    let date: DateTime;

    if (typeof value === 'string') {
      date = DateTime.fromFormat(value, options?.format || 'yyyy-MM-dd', {
        locale: options?.locale || navigator.language,
      });
    } else if (typeof value === 'number') {
      date = DateTime.fromMillis(value);
    } else if (value instanceof Date) {
      date = DateTime.fromJSDate(value);
    } else {
      return invalid(InvalidDate, 'Invalid date');
    }
    return date.isValid
      ? ok(date.toMillis())
      : invalid(InvalidDate, 'Invalid date');
  };
}
