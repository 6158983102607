import React, { PropsWithChildren, useEffect, useState } from 'react';
import { matchPath, useLocation } from 'react-router-dom';
import { GetBrandConfigResponse } from '../../api/BrandService/getBrandConfig/GetBrandConfigResponse.js';
import {
  getAvailableBrandFromId,
  getBrandIdFromAvailableBrand,
} from '../../api/util/getAvailableBrandFromId.js';
import { AvailableBrands } from '../../api/util/index.js';
import { BrandConfig } from '../util/index.js';
import { useApiClient } from './useApiClient.js';
import { useAsyncState } from './useAsyncState.js';
import { useContextWithError } from './useContextWithError.js';
import { useCurrentUser } from './useCurrentUser.js';

export interface BrandContext {
  userAvailableBrands: AvailableBrands[];
  brandConfig?: BrandConfig;
  currentBrand?: AvailableBrands;
  updateCurrentBrand: (brand?: AvailableBrands) => void;
  isEditor?: boolean;
  isLoading?: boolean;
  isViewer?: boolean;
  error?: unknown;
  currentBrandId?: number;
}

const brandContext = React.createContext<BrandContext | undefined>(undefined);

export const BrandProvider = ({ children }: PropsWithChildren): JSX.Element => {
  const [currentBrand, setCurrentBrand] = useState<
    AvailableBrands | undefined
  >();
  const [brandCfg, setBrandCfg] = useAsyncState<GetBrandConfigResponse>();
  const { value: user, hasPermission, loading, isSupport } = useCurrentUser();
  const [error, setError] = useState<unknown>();
  const api = useApiClient();
  const location = useLocation();

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setBrandCfg(
      async () => await api.brand.getBrandConfig({ brand: currentBrand }),
    );
  }, [api, currentBrand]);

  const currentPath = matchPath(
    { path: '/:brand/*', end: false },
    location.pathname,
  );

  const brandId = currentPath && currentPath.params.brand;

  useEffect(() => {
    if (!user) {
      return;
    }

    const brands = user.policy.Brand as AvailableBrands[];
    const availableBrand =
      (brandId && getAvailableBrandFromId(Number(brandId))) || undefined;

    if (brands) {
      setCurrentBrand(
        (state) => availableBrand || state || (brands[0] as AvailableBrands),
      );
    }
  }, [user, brandId]);

  return React.createElement(brandContext.Provider, {
    children,
    value: {
      userAvailableBrands: isSupport
        ? Object.values(AvailableBrands)
        : (user?.policy.Brand as AvailableBrands[]) || [],
      brandConfig: brandCfg.value?.length ? brandCfg.value[0] : undefined,
      currentBrand,
      currentBrandId: currentBrand
        ? getBrandIdFromAvailableBrand(currentBrand)
        : undefined,
      updateCurrentBrand: (brand?: AvailableBrands) => {
        if (
          brand &&
          !(user?.policy.Brand as AvailableBrands[]).includes(brand) &&
          !isSupport
        ) {
          setCurrentBrand(undefined);
          setError(Error('Not a valid brand'));
        }

        setCurrentBrand(brand);
      },
      isEditor:
        isSupport ||
        (currentBrand ? hasPermission('VehicleEditor', currentBrand) : false),
      isLoading: loading || brandCfg.loading,
      isViewer:
        isSupport ||
        (currentBrand ? hasPermission('VehicleViewer', currentBrand) : false),
      error: error || brandCfg.error,
    },
  });
};

export const useBrand = (): BrandContext => {
  return useContextWithError(brandContext, 'brand');
};
