import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query';
import { GetCountVehicleListingRequest } from '../../../../api/VehicleListingService/getVehicleListingCounts/GetCountVehicleListingRequest.js';
import { GetCountVehicleListingResponse } from '../../../../api/VehicleListingService/getVehicleListingCounts/GetCountVehicleListingResponse.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { IndexName } from '../../../../core/SearchIndexService/indexes.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { QueryKeys } from './QueryKeys.js';

export const makeCountVehicleListingQueryKey = ({
  brand,
  ...req
}: GetCountVehicleListingRequest): QueryKey => [
  QueryKeys.CountVehicleListing,
  brand,
  req,
];

export interface UseCountVehicleListingInput {
  enabled?: boolean;
  dealerId?: number;
  isLive?: boolean;
  indexName?: IndexName.ArchivedVehicleListings | IndexName.VehicleListings;
}

export const useCountVehicleListing = (
  input: UseCountVehicleListingInput,
): UseQueryResult<GetCountVehicleListingResponse> => {
  const { enabled = true, ...req } = input || {};
  const api = useApiClient();
  const { currentBrand } = useBrand();

  return useQuery({
    queryKey: makeCountVehicleListingQueryKey({
      brand: currentBrand as AvailableBrands,
      ...input,
    }),
    queryFn: async () =>
      await api.vehicleListing.getVehicleListingsCounts({
        brand: currentBrand as AvailableBrands,
        dealer: req.dealerId,
        options: {
          isLive: req.isLive,
          indexName: req.indexName,
        },
      }),
    enabled: !!enabled && !!currentBrand,
    placeholderData: (prev) => prev,
    refetchOnMount: false,
  });
};
