import {
  ExtraFields,
  UndefinedFields,
  boolean,
  maybe,
  object,
} from '@fmtk/decoders';

export interface Auth0ImsAppMetadata {
  has_ims_policy?: boolean;
}

export const decodeAuth0ImsAppMetadata = object(
  {
    has_ims_policy: maybe(boolean),
  },
  { extraFields: ExtraFields.Ignore, undefinedFields: UndefinedFields.Strip },
);
