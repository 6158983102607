import { Container, Skeleton, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

const HeaderSkeleton: FunctionComponent = () => {
  return (
    <Container maxWidth="xl">
      <Stack
        direction={{
          xs: 'column-reverse',
          sm: 'row',
        }}
        justifyContent="space-between"
        mt={4}
        spacing={2}
      >
        <Stack flex={1} justifyContent="space-between" spacing={1}>
          <Skeleton height={40} width="80%" />
          <Skeleton height={20} />
          <Skeleton height={20} />
          <Stack direction="row" spacing={4}>
            <Skeleton height={60} width={120} />
            <Skeleton height={60} width={120} />
          </Stack>
        </Stack>
        <Stack alignItems="flex-end" flex={1}>
          <Skeleton
            sx={{
              width: { xs: '100%', sm: 200 },
              minHeight: { xs: 150, sm: '100%' },
            }}
            variant="rounded"
          />
        </Stack>
      </Stack>
    </Container>
  );
};

export default HeaderSkeleton;
