import { uniqueId } from 'lodash-es';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../../hooks/useConst.js';
import { Cell, CellInput, CellProps, hasError, useForm } from '../../index.js';

type CellMoneyInputProps = Pick<
  CellProps,
  'name' | 'label' | 'readonly' | 'formattedValue' | 'required'
> & {
  currency?: string;
  disabled?: boolean;
  maxLength?: number;
};

export const CellMoneyInput: FunctionComponent<CellMoneyInputProps> = ({
  name,
  label,
  readonly,
  currency,
  disabled,
  formattedValue,
  maxLength = 15,
  required,
}) => {
  const [{ values, errors }, formBind, { translations }] = useForm();
  const ref = useConst(() => uniqueId('id'));
  const { t } = useTranslation();

  return (
    <Cell
      formattedValue={
        formattedValue ||
        (!hasError(errors[name]) && values[name]
          ? t('terms.money', {
              currency: currency,
              value: values[name],
              minimumFractionDigits: 0,
            })
          : undefined)
      }
      label={
        translations
          ? t([`${translations}.labels.${name}`, label], {
              defaultValue: label,
            })
          : label
      }
      name={name}
      readonly={readonly}
      required={required}
    >
      <CellInput
        disabled={disabled}
        id={ref}
        inputProps={{
          maxLength: maxLength,
        }}
        onChange={(e) => formBind.setValue(name, e.target.value)}
        value={values[name]}
      />
    </Cell>
  );
};

export default CellMoneyInput;
