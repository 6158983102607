import {
  Backdrop,
  Button,
  ClickAwayListener,
  Link,
  Paper,
  Popper,
  Skeleton,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, ReactNode, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobile } from '../../../hooks/useMobile.js';
import { sx } from '../../../util/sx.js';

interface FiltersPanelProps {
  children: ReactNode;
  onReset?: () => void;
  onClose?: () => void;
  filterCounts?: number;
  isLoading?: boolean;
}

const styles = sx({
  container: {
    p: 2,
    mx: {
      xs: 4,
      sm: 0,
    },
    minWidth: {
      xs: 'auto',
      sm: 300,
    },
    maxWidth: '100%',
  },
  modal: (theme) => ({
    zIndex: theme.zIndex.appBar + 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
  backdrop: {
    zIndex: (theme) => theme.zIndex.appBar,
    backgroundColor: '#D9D9D980',
    opacity: 0.5,

    '&.MuiBackdrop-root': {
      marginLeft: '0px',
    },
  },
});

const FiltersPanel: FunctionComponent<FiltersPanelProps> = ({
  children,
  onReset,
  onClose,
  filterCounts,
  isLoading,
}) => {
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleClose = () => {
    setAnchorEl(null);
    onClose && onClose();
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleReset = () => {
    onReset && onReset();
  };

  return (
    <ClickAwayListener onClickAway={handleClose}>
      <Stack direction="row" spacing={1}>
        {isLoading ? (
          <Skeleton variant="text" width={70} height={30} />
        ) : (
          <Link
            component="button"
            fontWeight="fontWeightMedium"
            onClick={onClick}
            underline="always"
            variant="subtitle2"
          >
            {t('filters', { count: filterCounts })}
          </Link>
        )}
        <Popper
          anchorEl={anchorEl}
          open={!!anchorEl}
          placement="bottom-start"
          sx={styles.modal}
        >
          <Paper elevation={3} sx={styles.container}>
            <Stack spacing={2}>
              <Stack py="5px">
                <Typography fontSize={18} lineHeight={1.2} variant="subtitle1">
                  {t('filters')}
                </Typography>
              </Stack>
              <Stack spacing={1.75}>{children}</Stack>
              <Stack direction="row" justifyContent="flex-end" spacing={1.25}>
                <Button onClick={handleReset} variant="outlined">
                  {t('reset')}
                </Button>
                <Button onClick={handleClose} variant="contained">
                  {t('done')}
                </Button>
              </Stack>
            </Stack>
          </Paper>
        </Popper>
        {isMobile && (
          <Backdrop
            onClick={handleClose}
            open={!!anchorEl}
            sx={styles.backdrop}
          />
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default FiltersPanel;
