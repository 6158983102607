import { InputBaseProps } from '@mui/material';
import { uniqueId } from 'lodash-es';
import { DateTime } from 'luxon';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../../hooks/useConst.js';
import { dateTimeFormat } from '../../../ims/components/RemarketingVehicle/types.js';
import { formatDateTime } from '../../../util/dates.js';
import { Cell, CellInput, useForm } from '../../index.js';

type CellDateTimeInputProps = {
  label: string;
  name: string;
  readonly?: boolean;
} & Omit<InputBaseProps, 'type' | 'value' | 'onChange' | 'id'>;

export const CellDateTimeInput: FunctionComponent<CellDateTimeInputProps> = ({
  name,
  label,
  readonly,
  ...inputProps
}) => {
  const [{ values }, formBind] = useForm();
  const { i18n } = useTranslation();
  const ref = useConst(() => uniqueId('id'));
  const value: DateTime | undefined = !values[name]
    ? undefined
    : typeof values[name] === 'number'
    ? DateTime.fromMillis(values[name])
    : DateTime.fromISO(values[name]);

  const formattedValue = useMemo(() => {
    if (!value || !value.isValid) {
      return;
    }

    return formatDateTime(value.toJSDate(), i18n.language || 'en-GB');
  }, [value, i18n.language]);

  return (
    <Cell
      formattedValue={formattedValue}
      htmlFor={ref}
      label={label}
      name={name}
      readonly={readonly}
    >
      <CellInput
        id={ref}
        onChange={(e) => formBind.setValue(name, e.target.value)}
        type="datetime-local"
        value={value?.toFormat(dateTimeFormat)}
        {...inputProps}
      />
    </Cell>
  );
};

export default CellDateTimeInput;
