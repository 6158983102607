import { Info } from '@mui/icons-material';
import {
  Box,
  Card,
  CardContent,
  CardHeader,
  FormControl,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
} from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  LineElement,
  PointElement,
  Title,
  Tooltip,
} from 'chart.js';
import { groupBy, prop, sortBy } from 'ramda';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Line } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ListModelPricingTrendsResponse } from '../../../../api/ReportingService/listModelPricingTrends/ListModelPricingTrendsResponse.js';
import { ListRegionsResponse } from '../../../../api/ReportingService/listRegions/ListRegionsResponse.js';
import { DownloadCsvResponse } from '../../../../api/util/index.js';
import { EmptyState, GridToolbar } from '../../../common-ui/index.js';
import { useApiClient, useAsyncState, useBrand } from '../../../hooks/index.js';
import { downloadAsCsv, labelColor } from '../../../util/index.js';
import { ChartDatasetType } from '../ChartReports.js';
import MainDrawer from '../MainDrawer.js';
import ReportCardWrapper from '../ReportCardWrapper.js';
import { getLabels, options } from './chartConfig.js';
import { columns } from './dataGridConfig.js';

const allFilterOptionValue = 'all';
const ageLimit = 10;

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
);

interface PreownedInventoryPricingProps {
  regions: ListRegionsResponse;
}

const PreownedInventoryPricingReport: FunctionComponent<
  PreownedInventoryPricingProps
> = ({ regions }) => {
  const api = useApiClient();
  const { brandConfig, currentBrand } = useBrand();
  const [open, setOpen] = useState(false);
  const [region, setRegion] = useState(allFilterOptionValue);
  const { t } = useTranslation();
  const [inventoryPricingList, setInventoryPricingList] =
    useAsyncState<ListModelPricingTrendsResponse>();
  const [downloadState, setDownloadState] =
    useAsyncState<DownloadCsvResponse>();

  const reportTitle = t('reports.preownedInventory.title');

  const data = useMemo(
    () => inventoryPricingList.value || [],
    [inventoryPricingList.value],
  );

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  useEffect(() => {
    if (!currentBrand) {
      return;
    }
    setInventoryPricingList(
      async () =>
        await api.reporting.listModelPricingTrends({
          brand: currentBrand,
          ageLimit: ageLimit - 1,
          currency: brandConfig?.currency ?? 'EUR',
          region: region === allFilterOptionValue ? undefined : region,
        }),
    );
  }, [api, currentBrand, brandConfig, region, setInventoryPricingList]);

  const handleChange = (event: SelectChangeEvent) => {
    setRegion(event.target.value as string);
  };

  /**
   * To make this chart more effective, we are going to display vehicles that meet following criteria:
   * - Has at least to years
   */
  const chartData: ChartDatasetType[] = useMemo(() => {
    // Grouping by model name
    const dataSet = groupBy((v) => v.model, data);

    const result = Object.entries(dataSet).map((grouped) => {
      // Fill missing values with NaN for Chart.
      let points = grouped[1];
      if (points.length < ageLimit) {
        const currencySymbol = points[0].currency;
        for (let i = 0; i < ageLimit; i++) {
          const find = points.find((point) => point.age === i);
          if (!find) {
            points = [
              ...points,
              {
                rowId: '', // rowId is used by datagrid only
                model: grouped[0], // model name
                modelYear: 'NaN',
                age: i,
                price: 'NaN', // This value is MANDATORY.
                currency: currencySymbol,
                count: 0,
              },
            ];
          }
        }
      }

      // Sorting by Age
      points = sortBy(prop('age'), points);

      // Gets a color for each model
      const color = labelColor(grouped[0]);
      return {
        label: grouped[0], // Contains model name
        data: points.map((item) => item.price), // All prices across years
        borderColor: color,
        backgroundColor: color,
        currency: points[0].currency, // Currency is the same for all price values
      };
    });

    // Only use models that has more than 1 year of data.
    return result.filter((obj) => obj.data.length > 1);
  }, [data]);

  /**
   * Calculates max value on Chart's Y axis.
   */
  const chartMaxYear =
    chartData.length > 0
      ? chartData.reduce((acc, curr) =>
          acc.data.length > curr.data.length ? acc : curr,
        ).data.length
      : 0;

  const handleDownload = useCallback(() => {
    setDownloadState(async () => {
      if (!currentBrand || !brandConfig) {
        return;
      }
      const csvResponse = await api.reporting.downloadInventoryPricingCsv({
        brand: currentBrand,
        ageLimit: ageLimit - 1,
        currency: brandConfig.currency ?? 'EUR',
        region: region === allFilterOptionValue ? undefined : region,
      });

      downloadAsCsv(csvResponse.content, csvResponse.fileName);
      return csvResponse;
    });
  }, [api.reporting, currentBrand, brandConfig, region, setDownloadState]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleOpen}>
              <Info />
            </IconButton>
          }
          title={reportTitle}
        />
        <CardContent>
          <Grid
            alignItems="center"
            container
            direction="row"
            justifyContent="flex-end"
            spacing={2}
          >
            <Grid item lg={3} md={3} xl={2} xs={12}>
              <FormControl fullWidth>
                <InputLabel id="region-label">
                  {t('reports.preownedInventory.region')}
                </InputLabel>
                <Select
                  disabled={regions.length === 0}
                  fullWidth
                  label="Region"
                  labelId="region-label"
                  onChange={handleChange}
                  value={region}
                >
                  <MenuItem
                    key={allFilterOptionValue}
                    value={allFilterOptionValue}
                  >
                    {t('reports.preownedInventory.all')}
                  </MenuItem>
                  {regions.map((region) => (
                    <MenuItem key={region.zone} value={region.zone}>
                      {region.zone}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12}>
              <ReportCardWrapper
                error={
                  inventoryPricingList.error
                    ? 'Sorry, an error occurred while loading this report.'
                    : undefined
                }
              >
                {inventoryPricingList.loading ? (
                  <Skeleton height={300} variant="rounded" width="100%" />
                ) : (
                  <>
                    {data.length > 1 ? (
                      <Line
                        data={{
                          labels: getLabels(chartMaxYear),
                          datasets: chartData,
                        }}
                        options={options}
                      />
                    ) : (
                      <Box sx={{ marginTop: '20px' }}>
                        <EmptyState />
                      </Box>
                    )}
                  </>
                )}
              </ReportCardWrapper>
            </Grid>
          </Grid>
        </CardContent>
      </Card>
      <MainDrawer onClose={handleClose} open={open} title={reportTitle}>
        <DataGrid
          autoHeight
          columns={columns}
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              downloadOptions: {
                onClickDownload: handleDownload,
                downloading: downloadState.loading,
                error: !!downloadState.error,
              },
            },
          }}
          disableSelectionOnClick
          getRowId={(row) => row.rowId}
          pageSize={10}
          rows={data}
          rowsPerPageOptions={[10]}
        />
      </MainDrawer>
    </>
  );
};

export default PreownedInventoryPricingReport;
