import { enumValue, number, object, optional, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface ListModelPricingTrendsRequest {
  brand: AvailableBrands;
  ageLimit: number;
  currency: string;
  region?: string;
}

export const decodeListModelPricingTrendsRequest =
  object<ListModelPricingTrendsRequest>({
    brand: enumValue(AvailableBrands),
    region: optional(string),
    ageLimit: number,
    currency: string,
  });
