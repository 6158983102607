import { Button, ButtonProps, CircularProgress } from '@mui/material';
import React from 'react';
import { sx } from '../../util/index.js';

const styles = sx({
  busy: {
    display: 'flex',
    justifyContent: 'center',
    gap: 1,
  },
});

export interface ActionButtonProps extends ButtonProps {
  busy?: boolean;
  component?: React.ElementType;
}

export function ActionButton({
  busy,
  ...rest
}: ActionButtonProps): JSX.Element {
  return busy ? (
    <Button sx={styles.busy} disabled {...rest}>
      {rest.children}
      <CircularProgress size={20} />
    </Button>
  ) : (
    <Button {...rest} />
  );
}
