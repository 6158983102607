import { Close } from '@mui/icons-material';
import {
  Box,
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  IconButton,
  Paper,
  Stack,
} from '@mui/material';
import QRCode from 'qrcode';
import { FunctionComponent, useEffect, useMemo } from 'react';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { PrintableVehicle } from '../../../../api/util/PrintableVehicle.js';
import {
  useAsyncState,
  useBrand,
  useDealer,
  useMobile,
} from '../../../hooks/index.js';
import { getLocatorVPDUrl } from '../../../util/getLocatorUrl.js';
import { PDFTypes, downloadFile, filterFalsey } from '../../../util/index.js';
import PrintButton from './PrintButton.js';
import ReportSkeleton from './ReportSkeleton.js';

interface PrintModalProps {
  open: boolean;
  onClose: () => void;
  vehicleListing: PrintableVehicle;
  excludeReports?: PDFTypes[];
}

const PrintModal: FunctionComponent<PrintModalProps> = ({
  open,
  onClose,
  vehicleListing,
  excludeReports: restrictedReports = [],
}) => {
  const { currentBrand, brandConfig } = useBrand();
  const { currentDealer } = useDealer();
  const isMobile = useMobile();
  const [DealerQrCode, setDealerQrCode] = useAsyncState<string>();

  const vdpUrl =
    brandConfig &&
    currentBrand &&
    vehicleListing?.dealer &&
    getLocatorVPDUrl(brandConfig, currentBrand, {
      id: String(vehicleListing.id),
      dealer: vehicleListing.dealer,
      syndicationType: vehicleListing?.syndicationType,
    });

  const reportList = useMemo(() => {
    if (!brandConfig) {
      return [];
    }

    return filterFalsey(
      Object.entries(brandConfig.availablePdfReports || []).map(([k, v]) =>
        !restrictedReports.includes(k as PDFTypes)
          ? {
              type: k,
              config: v,
            }
          : undefined,
      ),
    );
  }, [brandConfig, restrictedReports]);

  const [requiresQRCode] = useMemo(() => {
    if (!currentBrand) {
      return [];
    }

    return [currentBrand === AvailableBrands.RollsRoyce];
  }, [currentBrand]);

  const handleDownload = (fileName: string, blob?: Blob) => {
    if (!blob) {
      return;
    }
    downloadFile(blob, fileName, 'pdf');
    onClose();
  };

  // get the qr code canvas and convert it into a data uri
  useEffect(() => {
    // we only need to generate the qr code if rolls-royce because the other brands don't use it on the pdf
    if (!vdpUrl || !requiresQRCode) {
      return;
    }

    setDealerQrCode(async () => {
      const qrCodeUrl = await QRCode.toDataURL(vdpUrl, {
        margin: 0,
      });

      return qrCodeUrl;
    });
  }, [currentBrand, requiresQRCode, setDealerQrCode, vdpUrl]);

  return (
    <Dialog fullScreen={isMobile} maxWidth="md" onClose={onClose} open={open}>
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          pr: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <DialogTitle>Select report</DialogTitle>
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent>
        {currentBrand && (
          <Grid container spacing={1}>
            {reportList.map(({ type, config }) => (
              <Grid item key={`grid-${type}`} md="auto" sm={6} xs={12}>
                {vehicleListing &&
                config &&
                (!requiresQRCode || DealerQrCode.value) ? (
                  <PrintButton
                    DealerQrCode={DealerQrCode.value}
                    brand={currentBrand}
                    dealer={currentDealer || undefined}
                    key={`button-${type}-${currentBrand}`}
                    onClick={handleDownload}
                    pdfConfig={config}
                    pdfType={type as PDFTypes}
                    vehicleListing={vehicleListing}
                  />
                ) : (
                  <Paper key={`paper-${type}`} variant="outlined">
                    <Box display="flex" justifyContent="center" m={1}>
                      <ReportSkeleton />
                    </Box>
                  </Paper>
                )}
              </Grid>
            ))}
          </Grid>
        )}
      </DialogContent>
    </Dialog>
  );
};

export default PrintModal;
