import { maybe, text } from '@fmtk/decoders';
import { FunctionComponent, useEffect } from 'react';
import { Form } from '../../../../common-ui/index.js';
import { useCreateForm } from '../../../../hooks/useCreateForm.js';
import Comments from '../../Vehicle/Sections/Comments.js';
import { Section } from '../types.js';

type NotesProps = Section & { readonly?: boolean };

const Notes: FunctionComponent<NotesProps> = ({ vehicle, readonly, title }) => {
  const form = useCreateForm({ notes: maybe(text) }, () => 0);
  const [, formBind] = form;
  useEffect(() => {
    formBind.reset({ notes: vehicle.notes || '' });
  });

  return (
    <Form form={form}>
      <Comments label="" name="notes" readonly={readonly} title={title} />
    </Form>
  );
};

export default Notes;
