import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AdminArchiveRemarketingVehicleRequest } from '../../../../api/RemarketingService/adminArchiveRemarketingVehicle/AdminArchiveRemarketingVehicle.js';
import { AdminGetRemarketingVehicleResponse } from '../../../../api/RemarketingService/adminGetRemarketingVehicle/AdminGetRemarketingVehicleResponse.js';
import { ArchiveOptions } from '../../../../api/util/ArchiveOptions.js';
import { RemarketingVehicle } from '../../../../api/util/RemarketingVehicle.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  ListRemarketingForAdminInput,
  ListRemarketingVehiclesForAdminData,
  makeListRemarketingForAdminQueryKey,
} from '../queries/useListRemarketingVehiclesForAdmin.js';
import { makeRemarketingVehicleAdminQueryKey } from '../queries/useRemarketingVehicleAdmin.js';

export const useArchiveVehicle = (): UseMutationResult<
  RemarketingVehicle,
  unknown,
  AdminArchiveRemarketingVehicleRequest
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ brand, remarketingVehicleId }) => {
      return await api.remarketing.adminArchiveRemarketingVehicle({
        brand,
        remarketingVehicleId,
      });
    },
    onSuccess: (updated, req) => {
      queryClient.setQueriesData<AdminGetRemarketingVehicleResponse>(
        {
          queryKey: makeRemarketingVehicleAdminQueryKey(req),
        },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              ...updated,
            };
          }
        },
      );

      queryClient.setQueriesData<ListRemarketingVehiclesForAdminData>(
        {
          queryKey: makeListRemarketingForAdminQueryKey(req.brand),
          predicate: (query) => {
            return (
              (query.queryKey.at(2) as ListRemarketingForAdminInput | undefined)
                ?.archiveOption === ArchiveOptions.onlyArchived
            );
          },
        },
        (data) => {
          if (data) {
            return {
              ...data,
              pages: data.pages.map((p, i) => {
                return {
                  ...p,
                  items: i === 0 ? [updated, ...p.items] : p.items,
                  count: p.count + 1,
                };
              }),
            };
          }
        },
      );

      queryClient.setQueriesData<ListRemarketingVehiclesForAdminData>(
        {
          queryKey: makeListRemarketingForAdminQueryKey(req.brand),
          predicate: (query) => {
            return (
              (query.queryKey.at(2) as ListRemarketingForAdminInput | undefined)
                ?.archiveOption !== ArchiveOptions.onlyArchived
            );
          },
        },
        (data) => {
          if (data) {
            return {
              ...data,
              pages: data.pages.map((p) => {
                return {
                  ...p,
                  items: p.items.filter((x) => x.id !== updated.id),
                  count: p.count - 1,
                };
              }),
            };
          }
        },
      );
    },
  });
};
