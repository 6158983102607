import { boolean, maybe, number, object, string } from '@fmtk/decoders';
import {
  StringOrTranslation,
  decodeStringOrTranslation,
} from '../../util/StringOrTranslation.js';

export interface InAppNotification {
  id: string;
  userId: string;
  timestamp: number;
  title: StringOrTranslation;
  subtitle: StringOrTranslation;
  body: StringOrTranslation;
  imageSrc: string;
  link?: string;
  read: boolean;
  _expireAt?: number;
}

export const decodeInAppNotification = object<InAppNotification>({
  id: string,
  userId: string,
  timestamp: number,
  title: decodeStringOrTranslation,
  subtitle: decodeStringOrTranslation,
  body: decodeStringOrTranslation,
  imageSrc: string,
  link: maybe(string),
  read: boolean,
  _expireAt: maybe(number),
});
