import { useBrand } from '../../hooks/useBrand.js';
import { useCurrentUser } from '../../hooks/useCurrentUser.js';

export const useRemarketingEditor = (): boolean => {
  const { currentBrand } = useBrand();
  const { hasPermission } = useCurrentUser();

  const isEditor =
    !!currentBrand && hasPermission('RemarketingAdminBrands', currentBrand);

  return isEditor;
};
