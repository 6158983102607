import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import { Outlet } from 'react-router-dom';
import { UserPermissions } from '../../../api/util/UserPermissions.js';
import { ErrorState, PageContainer } from '../../common-ui/index.js';
import { useCurrentUser } from '../../hooks/useCurrentUser.js';

const RemarketingAdminPage: FunctionComponent = () => {
  const { hasAnyPermission } = useCurrentUser();
  const isRemarketingAdmin = hasAnyPermission(
    UserPermissions.RemarketingAdmin as UserPermissions,
  );

  if (!isRemarketingAdmin) {
    return (
      <PageContainer>
        <ErrorState />
      </PageContainer>
    );
  }
  return (
    <Stack>
      <Outlet />
    </Stack>
  );
};

export default RemarketingAdminPage;
