export function isAcceptable(file: File, accept: string): boolean {
  const accepts = accept.split(',');

  for (const accept of accepts) {
    if (accept.startsWith('.')) {
      if (file.name.endsWith(accept)) {
        return true;
      }
    } else if (accept.endsWith('*')) {
      if (file.type.startsWith(accept.slice(0, -1))) {
        return true;
      }
    } else if (file.type === accept) {
      return true;
    }
  }

  return false;
}
