import { useContext } from 'react';

export function useContextWithError<T>(
  ctx: React.Context<T | undefined>,
  name: string,
  providerName?: string,
): T {
  const value = useContext(ctx);
  if (!value) {
    throw new Error(
      `no instance found for ${name} ` +
        `(have you included ${providerName || `${name}Provider`}?)`,
    );
  }
  return value;
}
