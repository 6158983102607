import { array, number, object, text } from '@fmtk/decoders';

export interface Region {
  id: number;
  regionName: string;
}

export const decodeRegion = object<Region>({
  id: number,
  regionName: text,
});

export type ListRegionsResponse = Region[];

export const decodeListRegionsResponse = array(decodeRegion);
