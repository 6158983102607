import {
  array,
  chain,
  DecoderError,
  enumValue,
  error,
  is,
  maybe,
  normaliseNumber,
  ok,
  string,
  text,
} from '@fmtk/decoders';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Box,
  Button,
  MenuItem,
  Select,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import { useAtom, useAtomValue } from 'jotai';
import { RESET } from 'jotai/utils';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { AddRemarketingVehicleRequest } from '../../../../../api/RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import {
  OdometerUnit,
  odometerUnitOptions,
} from '../../../../../api/util/OdometerUnit.js';
import { RemarketingType } from '../../../../../api/util/RemarketingVehicle.js';
import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { dateToNumber } from '../../../../../util/decoders/dateToNumber.js';
import { minNumber } from '../../../../../util/decoders/minNumber.js';
import FormErrorsSummary from '../../../../common-ui/components/FormErrorsSummary.js';
import { FormSelectInput } from '../../../../common-ui/components/FormSelectInput.js';
import LightTooltip from '../../../../common-ui/components/LightTooltip.js';
import RegionSelector, {
  RegionCountries,
} from '../../../../common-ui/components/RegionCountrySelector/RegionSelector.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import TableOfContents, {
  ContentItem,
} from '../../../../common-ui/components/TableOfContents.js';
import {
  ConfirmModal,
  Form,
  FormFieldLabel,
  FormTextField,
  FormValuesInput,
} from '../../../../common-ui/index.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { useCreateForm } from '../../../../hooks/useCreateForm.js';
import { useCurrency } from '../../../../hooks/useCurrency.js';
import { useDealer } from '../../../../hooks/useDealer.js';
import { useRemarketingConfig } from '../../../../hooks/useRemarketingConfig.js';
import { filterFalsey } from '../../../../util/filterFalsey.js';
import { sx } from '../../../../util/index.js';
import { VehicleListingFields } from '../../../../util/VehicleListingFields.js';
import { MAIN_CONTAINER_ID } from '../../../layout/MainLayout.js';
import {
  addRemarketingVehicleAtom,
  regionCountryOptionsAtom,
} from '../../../state/atoms.js';
import RemarketingDocumentsSection from '../../RemarketingVehicle/Sections/RemarketingDocuments.js';
import { dateTimeFormat } from '../../RemarketingVehicle/types.js';
import Section from '../../Section.js';
import Comments from '../../Vehicle/Sections/Comments.js';
import {
  addAuctionFormDecoder,
  AddAuctionFormState,
  addFixedPriceFormDecoder,
  AddFixedPriceFormState,
  addPricedVehicleCrossFieldValidation,
  AddRemarketingVehicleForm,
} from '../AddRemarketingVehicleForm.js';

const baseTranslations = 'pages.addRemarketingVehicle.details';
const dateDecoder = dateToNumber({ format: dateTimeFormat });

const styles = sx({
  fieldContainer: {
    maxWidth: 300,
    flex: 'auto',
  },
});

export interface RemarketingVehicleFormState<T extends RemarketingType> {
  countries: string[];
  notes?: string;
  type: T;
  recommendedRetailPrice?: number;
  shortVin?: string;
  registrationPlate?: string;
  currency?: string;
  odometerValue?: number;
  odometerUnit?: OdometerUnit;
  visibilityDate?: number;
  syndicationType: SyndicationType;
}

type UnassignedFormState =
  RemarketingVehicleFormState<RemarketingType.UNASSIGNED>;

type AuctionFormState = AddAuctionFormState &
  RemarketingVehicleFormState<RemarketingType.AUCTION>;

type FixedPriceFormState = AddFixedPriceFormState &
  RemarketingVehicleFormState<RemarketingType.FIXED_PRICE>;

const types = [
  RemarketingType.UNASSIGNED,
  RemarketingType.AUCTION,
  RemarketingType.FIXED_PRICE,
];

const formatDate = (value: number) =>
  DateTime.fromMillis(value).toFormat(dateTimeFormat);

const DetailsStep: FunctionComponent = () => {
  const [state, setState] = useAtom(addRemarketingVehicleAtom);
  const [resetConfirm, setResetConfirm] = useState(false);
  const regions = useAtomValue(regionCountryOptionsAtom);
  const navigate = useNavigate();
  const { currentDealer } = useDealer();
  const { currentBrand } = useBrand();
  const [type, setType] = useState<RemarketingType>(
    state.currentRemarketingVehicle?.type ?? RemarketingType.UNASSIGNED,
  );
  const { currencyOptions } = useCurrency();
  const { remarketingId } = useParams();
  const { brandSyndicationTypes, showVehicleListingField } =
    useRemarketingConfig();

  const contentItems: ContentItem[] = [
    {
      text: t(`${baseTranslations}.form.labels.regionVisibility`),
      hash: 'regionVisibility',
      sectionElementRef: useRef(null),
    },
    {
      text: t(`details`),
      hash: 'details',
      sectionElementRef: useRef(null),
    },
    {
      text: t(`${baseTranslations}.form.labels.notes`),
      hash: 'notes',
      sectionElementRef: useRef(null),
    },
    {
      text: t(`pages.remarketingVehiclePage.auction.attachments`),
      hash: 'attachments',
      sectionElementRef: useRef(null),
    },
  ];

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const form = useCreateForm<
    UnassignedFormState | AuctionFormState | FixedPriceFormState,
    void
  >(
    () => {
      const baseDecoders = {
        countries: array(string),
        notes: maybe(text),
        recommendedRetailPrice: maybe(
          chain(
            normaliseNumber,
            minNumber({
              min: 1,
            }),
          ),
        ),
        shortVin: maybe(text),
        registrationPlate: maybe(text),
        currency: maybe(text),
        odometerValue: maybe(
          chain(
            normaliseNumber,
            minNumber({
              min: 0,
            }),
          ),
        ),
        odometerUnit: maybe(enumValue(OdometerUnit)),
        visibilityDate: maybe(dateDecoder),
        syndicationType: enumValue(SyndicationType),
      };

      if (type === RemarketingType.UNASSIGNED) {
        return {
          ...baseDecoders,
          type: is(RemarketingType.UNASSIGNED),
        };
      } else if (type === RemarketingType.AUCTION) {
        return {
          ...baseDecoders,
          ...addAuctionFormDecoder,
        };
      } else {
        return {
          ...baseDecoders,
          ...addFixedPriceFormDecoder,
          type: is(RemarketingType.FIXED_PRICE),
        };
      }
    },
    (values) => {
      setState((state) => {
        if (values.type === RemarketingType.UNASSIGNED) {
          return {
            ...state,
            currentRemarketingVehicle: {
              ...state.currentRemarketingVehicle,
              countries: values.countries,
              notes: values.notes,
              currency: values.currency,
              shortVin: values.shortVin,
              registrationPlate: values.registrationPlate,
              syndicationType: values.syndicationType,
              type: values.type,
              odometer: {
                units: values.odometerUnit,
                value: values.odometerValue,
              },
              visibilityDate: values.visibilityDate,
              vehicle: {
                ...state.currentRemarketingVehicle?.vehicle,
                rrp: {
                  ...state.currentRemarketingVehicle?.vehicle.rrp,
                  value: values.recommendedRetailPrice,
                },
              },
            } as AddRemarketingVehicleRequest,
          };
        } else if (values.type === RemarketingType.AUCTION) {
          return {
            ...state,
            currentRemarketingVehicle: {
              ...state.currentRemarketingVehicle,
              type,
              countries: values.countries,
              notes: values.notes,
              odometer: {
                units: values.odometerUnit,
                value: values.odometerValue,
              },
              closingDate: values.closingDate,
              currency: values.currency,
              reservePrice: values.reservePrice,
              startingDate: values.startingDate,
              startPrice: values.startPrice,
              syndicationType: values.syndicationType,
              visibilityDate: values.visibilityDate,
              termsAndConditions: values.termsAndConditions,
              shortVin: values.shortVin,
              registrationPlate: values.registrationPlate,
              vehicle: {
                ...state.currentRemarketingVehicle?.vehicle,
                rrp: {
                  currency: values.currency,
                  value: values.recommendedRetailPrice,
                },
              },
            } as AddRemarketingVehicleRequest,
          };
        } else {
          return {
            ...state,
            currentRemarketingVehicle: {
              ...state.currentRemarketingVehicle,
              type,
              countries: values.countries,
              notes: values.notes,
              odometer: {
                units: values.odometerUnit,
                value: values.odometerValue,
              },
              closingDate: values.closingDate,
              currency: values.currency,
              startingDate: values.startingDate,
              price: values.price,
              visibilityDate: values.visibilityDate,
              syndicationType: values.syndicationType,
              termsAndConditions: values.termsAndConditions,
              shortVin: values.shortVin,
              registrationPlate: values.registrationPlate,
              vehicle: {
                ...state.currentRemarketingVehicle?.vehicle,
                rrp: {
                  currency: values.currency,
                  value: values.recommendedRetailPrice,
                },
              },
              acceptsOffers: values.acceptsOffers,
            } as AddRemarketingVehicleRequest,
          };
        }
      });

      navigate(`../features`, { relative: 'path' });
    },
    (values) => {
      const errors: DecoderError[] = [];

      if (values.countries.length === 0) {
        errors.push({
          id: 'INVALID_COUNTRY_REGIONS',
          text: 'INVALID_COUNTRY_REGIONS',
          field: 'regionVisibility',
        });
      }

      if (errors.length > 0) {
        return error(errors);
      }

      if (
        values.type === RemarketingType.AUCTION ||
        values.type === RemarketingType.FIXED_PRICE
      ) {
        const auctionValidation = addPricedVehicleCrossFieldValidation(values);
        if (!auctionValidation.ok) {
          return auctionValidation;
        }
        return ok({
          ...values,
          ...auctionValidation.value,
        });
      }

      return ok(values);
    },
    [setState, type],
  );

  const [formState, formBind] = form;

  const handleRegionChange = useCallback(
    (regions: RegionCountries) => {
      const selectedCountries = Object.entries(regions).flatMap(
        ([_, countries]) =>
          filterFalsey(
            countries.flatMap((c) => (c.selected ? c.countryCode : undefined)),
          ),
      );

      formBind.setValue('countries', selectedCountries);
    },
    [formBind],
  );

  const handleCancel = useCallback(() => {
    setState(RESET);
    toggleResetConfirm();
    navigate('..');
  }, [navigate, setState]);

  useEffect(() => {
    if (state.currentRemarketingVehicle?.type === RemarketingType.UNASSIGNED) {
      const value: FormValuesInput<UnassignedFormState> = {
        countries: state.currentRemarketingVehicle.countries,
        type: state.currentRemarketingVehicle.type,
        notes: state.currentRemarketingVehicle.notes,
        odometerUnit: state.currentRemarketingVehicle.odometer?.units,
        odometerValue:
          state.currentRemarketingVehicle.odometer?.value?.toString(),
        recommendedRetailPrice:
          state.currentRemarketingVehicle.vehicle.rrp?.value,
        registrationPlate: state.currentRemarketingVehicle.registrationPlate,
        shortVin: state.currentRemarketingVehicle.shortVin,
        syndicationType:
          state.currentRemarketingVehicle?.syndicationType ||
          SyndicationType.Used,
        currency: state.currentRemarketingVehicle.currency,
        visibilityDate: state.currentRemarketingVehicle.visibilityDate
          ? formatDate(state.currentRemarketingVehicle.visibilityDate)
          : undefined,
      };
      formBind.reset(value);
    } else if (
      state.currentRemarketingVehicle?.type === RemarketingType.AUCTION
    ) {
      const value: FormValuesInput<AuctionFormState> = {
        closingDate: formatDate(state.currentRemarketingVehicle.closingDate),
        countries: state.currentRemarketingVehicle.countries,
        currency: state.currentRemarketingVehicle.currency,
        notes: state.currentRemarketingVehicle.notes,
        odometerUnit: state.currentRemarketingVehicle.odometer?.units,
        odometerValue:
          state.currentRemarketingVehicle.odometer?.value?.toString(),
        recommendedRetailPrice:
          state.currentRemarketingVehicle.vehicle.rrp?.value?.toString(),
        reservePrice: state.currentRemarketingVehicle.reservePrice.toString(),
        startingDate: formatDate(state.currentRemarketingVehicle.startingDate),
        startPrice: state.currentRemarketingVehicle.startPrice.toString(),
        syndicationType:
          state.currentRemarketingVehicle?.syndicationType ||
          SyndicationType.Used,
        termsAndConditions: state.currentRemarketingVehicle.termsAndConditions,
        type: state.currentRemarketingVehicle.type,
        visibilityDate: formatDate(
          state.currentRemarketingVehicle.visibilityDate,
        ),
        registrationPlate: state.currentRemarketingVehicle.registrationPlate,
        shortVin: state.currentRemarketingVehicle.shortVin,
      };
      formBind.reset(value);
    } else if (
      state.currentRemarketingVehicle?.type === RemarketingType.FIXED_PRICE
    ) {
      const value: FormValuesInput<FixedPriceFormState> = {
        closingDate:
          state.currentRemarketingVehicle.closingDate &&
          formatDate(state.currentRemarketingVehicle.closingDate),
        countries: state.currentRemarketingVehicle.countries,
        currency: state.currentRemarketingVehicle.currency,
        notes: state.currentRemarketingVehicle.notes,
        odometerUnit: state.currentRemarketingVehicle.odometer?.units,
        odometerValue:
          state.currentRemarketingVehicle.odometer?.value?.toString(),
        recommendedRetailPrice:
          state.currentRemarketingVehicle.vehicle.rrp?.value?.toString(),
        price: state.currentRemarketingVehicle.price.toString(),
        startingDate: formatDate(state.currentRemarketingVehicle.startingDate),
        syndicationType:
          state.currentRemarketingVehicle?.syndicationType ||
          SyndicationType.Used,
        termsAndConditions: state.currentRemarketingVehicle.termsAndConditions,
        type: state.currentRemarketingVehicle.type,
        visibilityDate: formatDate(
          state.currentRemarketingVehicle.visibilityDate,
        ),
        registrationPlate: state.currentRemarketingVehicle.registrationPlate,
        shortVin: state.currentRemarketingVehicle.shortVin,
        acceptsOffers: state.currentRemarketingVehicle.acceptsOffers,
      };
      formBind.reset(value);
    }
  }, [formBind, state.currentRemarketingVehicle, currentDealer]);

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x?.length),
      isDirty,
    ];
  }, [formState.errors, formState.dirty]);

  useEffect(() => {
    if (!state.currentRemarketingVehicle) {
      //clear state and go to step 1
      setState(RESET);
      navigate('../../add');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, state.currentRemarketingVehicle]);

  useEffect(() => {
    formBind.setValue('type', type);
  }, [type, formBind]);

  if (!currentBrand) {
    return <></>;
  }

  return (
    <Form form={form} translations={`${baseTranslations}.form`}>
      <Stack
        direction={{
          sm: 'column-reverse',
          md: 'row',
        }}
        spacing={1}
      >
        <Stack flex={1}>
          <Section title={t('details')}>
            <Stack spacing={3}>
              <Stack maxWidth={300} spacing={1}>
                <FormFieldLabel
                  name="type"
                  text={t(`${baseTranslations}.form.labels.remarketingType`)}
                />
                <Select
                  onChange={(e) => {
                    setType(e.target.value as RemarketingType);
                  }}
                  size="small"
                  value={type}
                >
                  {types.map((type) => (
                    <MenuItem key={type} value={type}>
                      {t(`remarketingTypes.${type}`)}
                    </MenuItem>
                  ))}
                </Select>
              </Stack>
              <div id={contentItems[0].hash}>
                <Stack>
                  {regions && (
                    <Stack spacing={1.2}>
                      <FormFieldLabel
                        name="countries"
                        text={t(
                          `${baseTranslations}.form.labels.regionVisibility`,
                        )}
                      />
                      <RegionSelector
                        countries={formState.values.countries || []}
                        onChange={handleRegionChange}
                        regions={regions}
                      />
                    </Stack>
                  )}
                </Stack>
              </div>
              <div>
                <Stack spacing={3}>
                  <Stack
                    direction={{
                      xs: 'column',
                      sm: 'row',
                    }}
                    spacing={2}
                  >
                    <Stack sx={styles.fieldContainer}>
                      <FormTextField label={t(`shortVin`)} name="shortVin" />
                    </Stack>
                    <Stack sx={styles.fieldContainer}>
                      <FormTextField
                        label={t(`regPlate`)}
                        name="registrationPlate"
                      />
                    </Stack>
                    <Stack sx={styles.fieldContainer}>
                      <FormTextField
                        label={t('recommendedRetailPrice')}
                        min="0"
                        name="recommendedRetailPrice"
                        step="any"
                        type="number"
                      />
                    </Stack>
                    <Stack
                      minWidth={120}
                      spacing={0.5}
                      sx={styles.fieldContainer}
                    >
                      <FormFieldLabel
                        name="currency"
                        text={t('listingCurrency')}
                      />
                      <FormSelectInput name="currency" size="small">
                        {currencyOptions.map(({ text, value }, idx) => (
                          <MenuItem key={`${text}-${idx}`} value={value}>
                            {t(text)}
                          </MenuItem>
                        ))}
                      </FormSelectInput>
                    </Stack>
                  </Stack>

                  <Stack
                    direction={{
                      xs: 'column',
                      sm: 'row',
                    }}
                    spacing={2}
                  >
                    <Stack sx={styles.fieldContainer}>
                      <FormTextField
                        label={t(`${baseTranslations}.form.labels.odometer`)}
                        name="odometerValue"
                        type="number"
                      />
                    </Stack>
                    <Stack maxWidth={300} minWidth={120} spacing={0.5}>
                      <FormFieldLabel
                        name="odometerUnit"
                        text={t(`${baseTranslations}.form.labels.odometerUnit`)}
                      />
                      <FormSelectInput name="odometerUnit" size="small">
                        {odometerUnitOptions.map(({ text, value }, idx) => (
                          <MenuItem key={`${text}-${idx}`} value={value}>
                            {t(text)}
                          </MenuItem>
                        ))}
                      </FormSelectInput>
                    </Stack>
                    {showVehicleListingField(
                      VehicleListingFields.SyndicationType,
                    ) && (
                      <Stack maxWidth={300} minWidth={200} spacing={0.5}>
                        <FormFieldLabel
                          name="syndicationType"
                          text={t(
                            `${baseTranslations}.form.labels.syndicationType`,
                          )}
                        />
                        <FormSelectInput name="syndicationType" size="small">
                          {brandSyndicationTypes.map(({ text, value }, idx) => (
                            <MenuItem key={`${text}-${idx}`} value={value}>
                              {t(text)}
                            </MenuItem>
                          ))}
                        </FormSelectInput>
                      </Stack>
                    )}
                  </Stack>
                  {type === RemarketingType.UNASSIGNED && (
                    <Stack
                      direction={{
                        xs: 'column',
                        sm: 'row',
                      }}
                      spacing={2}
                    >
                      <Stack sx={styles.fieldContainer}>
                        <FormTextField
                          helperText={t(
                            `${baseTranslations}.form.help.visibilityDate`,
                          )}
                          label={t(
                            `${baseTranslations}.form.labels.visibilityDate`,
                          )}
                          name="visibilityDate"
                          type="datetime-local"
                        />
                      </Stack>
                    </Stack>
                  )}
                </Stack>
              </div>
              <div
                id={contentItems[1].hash}
                ref={
                  contentItems[1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                {(type === RemarketingType.AUCTION ||
                  type === RemarketingType.FIXED_PRICE) && (
                  <AddRemarketingVehicleForm />
                )}
              </div>
              {/* Notes */}
              <div
                id={contentItems[2].hash}
                ref={
                  contentItems[2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Comments
                  label=""
                  my={0}
                  name="notes"
                  rows={10}
                  tabIndex={1}
                  title={t('notes')}
                />
              </div>
              <div
                id={contentItems[3].hash}
                ref={
                  contentItems[3]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                {remarketingId ? (
                  <RemarketingDocumentsSection
                    brand={currentBrand}
                    readonly
                    remarketingVehicleId={remarketingId}
                    title={t(
                      'pages.remarketingVehiclePage.auction.attachmentsNote',
                    )}
                  />
                ) : (
                  <SectionItem
                    my={0}
                    title={t(
                      `pages.remarketingVehiclePage.auction.attachments`,
                    )}
                  >
                    <Typography variant="body2">
                      {t(
                        `pages.remarketingVehiclePage.auction.attachmentsNote`,
                      )}
                    </Typography>
                  </SectionItem>
                )}
              </div>

              <Box
                bgcolor="background.paper"
                borderTop="1px solid #C4CDD5"
                bottom={0}
                p={1}
                position="sticky"
              >
                <Stack
                  direction="row"
                  justifyContent="space-between"
                  spacing={1}
                >
                  <Button
                    disabled={formState.busy}
                    onClick={() => {
                      if (isDirty) {
                        toggleResetConfirm();
                        return;
                      }
                      navigate('..');
                    }}
                    startIcon={<NavigateBefore />}
                    variant="outlined"
                  >
                    {t(!remarketingId ? 'vinNumber' : 'cancel')}
                  </Button>
                  <LightTooltip
                    arrow
                    placement="left-start"
                    title={hasErrors ? <FormErrorsSummary /> : ''}
                  >
                    <span>
                      <LoadingButton
                        disabled={hasErrors}
                        endIcon={<NavigateNext />}
                        loading={formState.busy}
                        loadingPosition="end"
                        type="submit"
                        variant="contained"
                      >
                        {t('features')}
                      </LoadingButton>
                    </span>
                  </LightTooltip>
                </Stack>
              </Box>
            </Stack>
          </Section>
        </Stack>
        <div>
          <Section isSticky>
            <Stack>
              <TableOfContents
                items={contentItems}
                offsetPx={-80}
                scrollingElementSelector={MAIN_CONTAINER_ID}
              />
            </Stack>
          </Section>
        </div>
        <ConfirmModal
          onClose={toggleResetConfirm}
          onConfirm={handleCancel}
          open={resetConfirm}
          title={t('attention')}
        >
          <Typography variant="body1">{t('discard')}</Typography>
        </ConfirmModal>
      </Stack>
    </Form>
  );
};

export default DetailsStep;
