export enum VehicleFields {
  //Base
  GroupName = 'groupName',
  Model = 'model',
  BodyStyle = 'bodyStyle',
  HandDrive = 'handDrive',
  RegistrationDate = 'registrationDate',
  ModelYear = 'modelYear',
  Transmission = 'transmission',
  EnvironmentalLabel = 'environmentalLabel',
  EuroEmissionsClass = 'euroEmissionsClass',
  ProductionDate = 'productionDate',
  Variant = 'variant',
  WarrantyExpiryDate = 'warrantyExpiryDate',
  MotLastDate = 'motLastDate',
  MotDueDate = 'motDueDate',
  PreviousOwners = 'previousOwners',
  DamageStatus = 'damageStatus',
  //VehicleAppearance
  CarpetColour = 'carpetColour',
  ExteriorColour = 'exteriorColour',
  ExteriorMetaColour = 'exteriorMetaColour',
  HeadliningColour = 'headliningColour',
  InteriorColour = 'interiorColour',
  InteriorMetaColour = 'interiorMetaColour',
  SeatColour = 'seatColour',
  SecondaryExteriorColour = 'secondaryExteriorColour',
  SecondaryInteriorColour = 'secondaryInteriorColour',
  SteeringWheelColour = 'steeringWheelColour',
  Veneer = 'veneer',
  //Engine
  EngineType = 'engineType', // description
  FuelType = 'fuelType',
  Capacity = 'capacity',
  Bhp = 'bhp',
  Cylinders = 'cylinders',
  PowerKw = 'powerKw',
  TorqueLbft = 'torqueLbft',
  TorqueNm = 'torqueNm',
  //TechSpecs
  MaxSpeedMph = 'maxSpeedMph',
  MaxSpeedKph = 'maxSpeedKph',
  KerbWeight = 'kerbWeight',
  Length = 'length',
  Width = 'width',
  Acceleration0100Kph = 'acceleration0100Kph',
  Acceleration060Mph = 'acceleration060Mph',
  Doors = 'doors',
  //WLTP
  ConsumptionLow = 'consumptionLow',
  ConsumptionMid = 'consumptionMid',
  ConsumptionHigh = 'consumptionHigh',
  ConsumptionExtraHigh = 'consumptionExtraHigh',
  ConsumptionCombined = 'consumptionCombined',
  Co2Emissions = 'co2Emissions',
  WeightedCombined = 'weightedCombined',
  ElectricityConsumption = 'electricityConsumption',
  ElectricRange = 'electricRange',
  ElectricCityRange = 'electricCityRange',
  //NEDC
  NedcUrbanFuelEconomy = 'nedcUrbanFuelEconomy',
  NedcExtraUrbanFuelEconomy = 'nedcExtraUrbanFuelEconomy',
  NedcCombinedFuelEconomy = 'nedcCombinedFuelEconomy',
  NedcCo2Emissions = 'nedcCo2Emissions',
}
