import { Box, Card, CardContent, CardHeader, Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';

const ReportCardSkeleton: FunctionComponent = (): JSX.Element => {
  return (
    <Card>
      <CardHeader
        title={<Skeleton variant="text" width="60%" />}
        subheader={<Skeleton variant="text" />}
        action={
          <Box m={1}>
            <Skeleton variant="circular" width={24} height={24} />
          </Box>
        }
      />
      <CardContent>
        <Skeleton variant="rounded" width="100%" height={300} />
      </CardContent>
    </Card>
  );
};

export default ReportCardSkeleton;
