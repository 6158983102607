import { ListingLiveStatus } from '../../api/util/ListingLiveStatus.js';

const errorStatuses = [
  ListingLiveStatus.DEMONSTRATOR,
  ListingLiveStatus.FACTORY_RECALL,
  ListingLiveStatus.INVALID_VIN,
  ListingLiveStatus.MANUALLY_HIDDEN,
  ListingLiveStatus.MAXIMUM_AGE,
  ListingLiveStatus.MAXIMUM_MILEAGE,
  ListingLiveStatus.MINIMUM_IMAGES,
  ListingLiveStatus.MINIMUM_MILES,
  ListingLiveStatus.MINIMUM_REG_DATE,
  ListingLiveStatus.MISSING_WLTP,
  ListingLiveStatus.NEW_REGYEAR,
  ListingLiveStatus.NOT_CERTIFIED,
  ListingLiveStatus.NO_MODEL_YEAR,
  ListingLiveStatus.NO_PRICE,
  ListingLiveStatus.NO_REGDATE,
  ListingLiveStatus.NO_RULE,
  ListingLiveStatus.NO_USED_VEHICLES,
  ListingLiveStatus.PROHIBITED_MODEL,
  ListingLiveStatus.REQUIRED_IMAGES,
  ListingLiveStatus.SOLD_SUBJECT_TO_FINANCE,
  ListingLiveStatus.UNDER_CONVERSION,
  ListingLiveStatus.UNDER_OFFER,
  ListingLiveStatus.UNDER_REVIEW,
  ListingLiveStatus.VEHICLE_REMOVED,
];

const isStatusError = (status: ListingLiveStatus[]): boolean =>
  status.some((s) => errorStatuses.includes(s));

export { isStatusError };
