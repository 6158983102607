import { Stack } from '@mui/system';
import { FunctionComponent } from 'react';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CellTextInput } from '../../../../common-ui/index.js';
import { Section } from './types.js';

const Consumption: FunctionComponent<Omit<Section, 'vehicleListing'>> = ({
  title,
  sectionItemsProps,
  readonly,
}) => {
  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            <CellTextInput
              label="consumptionLow"
              name="consumptionLow"
              readonly={readonly}
            />
            <CellTextInput
              label="consumptionMid"
              name="consumptionMid"
              readonly={readonly}
            />
            <CellTextInput
              label="consumptionHigh"
              name="consumptionHigh"
              readonly={readonly}
            />
            <CellTextInput
              label="co2Emissions"
              name="co2Emissions"
              readonly={readonly}
            />
            <CellTextInput
              label="weightedCombined"
              name="weightedCombined"
              readonly={readonly}
            />
          </Stack>
          <Stack flex={1}>
            <CellTextInput
              label="consumptionExtraHigh"
              name="consumptionExtraHigh"
              readonly={readonly}
            />
            <CellTextInput
              label="consumptionCombined"
              name="consumptionCombined"
              readonly={readonly}
            />
            <CellTextInput
              label="electricityConsumption"
              name="electricityConsumption"
              readonly={readonly}
            />
            <CellTextInput
              label="electricRange"
              name="electricRange"
              readonly={readonly}
            />
            <CellTextInput
              label="electricCityRange"
              name="electricCityRange"
              readonly={readonly}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Consumption;
