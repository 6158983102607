import { Box, Button, CircularProgress, Stack, styled } from '@mui/material';
import { t } from 'i18next';
import { FunctionComponent, ReactEventHandler, useRef, useState } from 'react';
import { sx } from '../../../util/sx.js';

export const REFRESH = 1000;
export const MAX_ATTEMPTS = 10;
interface ImageProps {
  isProcessing?: boolean;
  src?: string;
  disabled?: boolean;
  onClick?: () => void;
}

export const CustomImage = styled('img')<{
  isProcessing: boolean;
  disabled: boolean;
  error: boolean;
}>`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 4/3;
  ${({ isProcessing }) => (isProcessing ? `pointer-events: none;` : undefined)}
  ${({ error, isProcessing }) =>
    error || isProcessing ? 'visibility: hidden;' : undefined}
  ${({ disabled }) =>
    disabled ? 'opacity: 0.5; pointer-events: none;' : undefined};
`;

const styles = sx({
  root: {
    display: 'flex',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center',
  },
  imageContainer: {
    position: 'absolute',
    padding: '5px; 6px;',
    display: 'flex',
    gap: 1,
    zIndex: 1,
    height: '100%',
    alignItems: 'center',
  },
});

const LoadingImage: FunctionComponent<ImageProps> = ({
  src,
  isProcessing,
  disabled,
  onClick,
}) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [attempts, setAttempts] = useState(1);
  const imgRef = useRef<HTMLImageElement>(null);

  const handleImageError: ReactEventHandler<HTMLImageElement> = (e) => {
    if (!imgRef.current) {
      return;
    }
    setLoading(true);

    if (attempts >= MAX_ATTEMPTS) {
      setError(true);
      setLoading(false);

      return;
    }

    setAttempts((state) => state + 1);

    setTimeout(() => {
      if (!imgRef.current || !src) {
        return;
      }
      imgRef.current.src = src;
    }, REFRESH);
  };

  const refresh = () => {
    if (!src || !imgRef.current) {
      return;
    }
    setAttempts(1);
    setError(false);
    imgRef.current.src = src;
  };

  return (
    <Box position="relative" sx={styles.root}>
      {(loading || error) && (
        <Box sx={styles.imageContainer}>
          {loading && (
            <Stack
              alignContent="center"
              alignItems="center"
              justifyContent="center"
            >
              <CircularProgress color="primary" size={40} />
            </Stack>
          )}
          {error && (
            <Stack spacing={1}>
              <Button onClick={refresh} size="small" variant="text">
                {t('refresh')}
              </Button>
            </Stack>
          )}
        </Box>
      )}
      <CustomImage
        disabled={!!disabled}
        error={error}
        isProcessing={isProcessing || loading}
        onClick={onClick}
        onError={handleImageError}
        onLoad={() => {
          setLoading(false);
        }}
        ref={imgRef}
        role={onClick ? 'button' : 'img'}
        src={src}
      />
    </Box>
  );
};

export default LoadingImage;
