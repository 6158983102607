import { assign, omitBy } from 'lodash-es';
import { CreateConstraint } from './CreateConstraint.js';
import {
  DbIndex,
  DbIndexQuery,
  DbIndexValue,
  DbKeyPair,
  DbKeyValue,
} from './DbIndex.js';
import {
  ExpressionBuilder,
  ExpressionType,
} from './DynamoDBTableClient/builders/ExpressionBuilder.js';
import { UpdateBuilder } from './DynamoDBTableClient/builders/UpdateBuilder.js';
import {
  ConditionCheck,
  Delete,
  Put,
  Update,
} from './DynamoDBTableClient/commands/TransactWriteCommand.js';

export type DbModelKey<T, K extends string = string> = (
  value: T,
  type: K,
) => DbKeyPair;

export type DbModelIndex<T, K extends string = string> = (
  value: T,
  type: K,
) => DbIndexValue | undefined;

export type DbModelTypeOf<T> = T extends { serialize(value: infer M): any } // this pattern match seems to work better than DbModel directly
  ? M
  : never;

export type DbModelQueries<
  K extends string = string,
  Q extends string = string,
> = { [P in Q]: (value: any, type: K) => DbIndexQuery };

export type QueryFactory<T> = T extends DbModelQueries<any, any>
  ? {
      [P in keyof T]: T[P] extends (value: infer M, type: any) => DbIndexQuery
        ? (value: M) => DbIndexQuery
        : never;
    }
  : never;

export class DbModel<
  Type extends string,
  Key,
  Model extends Key,
  IndexKey extends string,
  Query extends DbModelQueries<Type>,
> {
  public static readonly TypeField = '_type';

  public static readonly primaryKey = new DbIndex(undefined, '_pk', '_sk');
  public static readonly gsi1 = new DbIndex('gsi1', '_gsi1pk', '_gsi1sk');
  public static readonly gsi2 = new DbIndex('gsi2', '_gsi2pk', '_gsi2sk');

  private readonly indexValues: ((value: Model) => DbKeyValue | undefined)[];
  public readonly indexes: Record<
    IndexKey,
    (value: Model) => DbIndexValue | undefined
  >;
  public readonly queries: QueryFactory<Query>;

  /**
   * This is a type helper for specifying the model type without having to
   * specify all the other type arguments.
   */
  public static noIndexes<Model>(): Record<never, DbModelIndex<Model, any>> {
    return {};
  }

  constructor(
    public readonly type: Type,
    private readonly keyFactory: DbModelKey<Key, Type>,
    indexes: Record<IndexKey, DbModelIndex<Model, Type>>,
    queries?: Query,
  ) {
    this.indexValues = [];
    this.indexes = {} as any;
    this.queries = {} as any;

    for (const key in indexes) {
      this.indexValues.push((value) =>
        indexes[key](value, this.type)?.serialize(),
      );
      this.indexes[key] = (value) => indexes[key](value, this.type);
    }

    if (queries) {
      for (const key in queries) {
        this.queries[key] = ((value: any) =>
          queries[key](value, this.type)) as any;
      }
    }
  }

  public conditionCheck(key: Key): ExpressionBuilder<ConditionCheck> {
    return new ExpressionBuilder<ConditionCheck>(
      {
        Key: this.key(key),
      },
      ExpressionType.ConditionExpression,
    );
  }

  public delete(key: Key): Delete {
    return {
      Key: this.key(key),
    };
  }

  public deserialize(value: Model): Model {
    return omitBy(value as any, (_, key) => key.startsWith('_')) as any;
  }

  public doesNotExist(key: Key): ConditionCheck {
    return this.exists(key, CreateConstraint.MustNotExist);
  }

  public exists(
    key: Key,
    condition = CreateConstraint.MustExist,
  ): ConditionCheck {
    const builder = this.conditionCheck(key);
    if (condition === CreateConstraint.MustExist) {
      builder.attributeExists(DbModel.primaryKey.pkField);
    } else if (condition === CreateConstraint.MustNotExist) {
      builder.attributeNotExists(DbModel.primaryKey.skField);
    }
    return builder.buildExpression();
  }

  public key(value: Key): DbKeyValue {
    return DbModel.primaryKey.serialize(this.keyFactory(value, this.type));
  }

  public put(value: Model, create: CreateConstraint): Put {
    const builder = new ExpressionBuilder<Put>({
      Item: this.serialize(value),
    });
    switch (create) {
      case CreateConstraint.MustCreate:
        builder.attributeNotExists(DbModel.primaryKey.pkField);
        break;

      case CreateConstraint.MustUpdate:
        builder.attributeExists(DbModel.primaryKey.pkField);
        break;
    }
    return builder.buildExpression();
  }

  public serialize(value: Model): any {
    return assign(
      {},
      value,
      ...this.indexValues.map((x) => x(value)),
      this.key(value),
      {
        [DbModel.TypeField]: this.type,
      },
    );
  }

  public update(key: Key, upsert = false): UpdateBuilder<Update> {
    const builder = new UpdateBuilder<Update>({
      Key: this.key(key),
    });
    if (!upsert) {
      builder.attributeExists(DbModel.primaryKey.pkField);
    } else {
      builder.setValue(DbModel.TypeField, this.type);
    }
    return builder;
  }
}
