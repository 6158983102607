import { boolean, maybe, object, string } from '@fmtk/decoders';

export interface ListUserNotificationsRequest {
  unreadOnly?: boolean;
  nextToken?: string;
}

export const decodeListUserNotificationsRequest =
  object<ListUserNotificationsRequest>({
    unreadOnly: maybe(boolean),
    nextToken: maybe(string),
  });
