import {
  array,
  boolean,
  choose,
  enumValue,
  maybe,
  number,
  object,
  optional,
  string,
} from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface ListVehicleListingRequest {
  brand: AvailableBrands | AvailableBrands[];
  dealer?: number | number[];
  vin?: string;
  freeTextSearch?: string;
  dealerFeatures?: number[];
  options?: {
    limit?: number;
    page?: number;
    ignoreCache?: boolean;
    onlyWithPendingMedia?: boolean;
    onlyLiveVehicles?: boolean;
    onlyNonLiveVehicles?: boolean;
    onlyCertifiedVehicles?: boolean;
    onlyStockOver90Days?: boolean;
  };
}

export const decodeListVehicleListingRequest =
  object<ListVehicleListingRequest>({
    brand: choose(
      array(enumValue(AvailableBrands)),
      enumValue(AvailableBrands),
    ),
    dealer: maybe(choose(number, array(number))),
    vin: optional(string),
    freeTextSearch: optional(string),
    dealerFeatures: optional(array(number)),
    options: optional(
      object({
        limit: optional(number),
        page: optional(number),
        ignoreCache: optional(boolean),
        onlyWithPendingMedia: optional(boolean),
        onlyLiveVehicles: optional(boolean),
        onlyNonLiveVehicles: optional(boolean),
        onlyCertifiedVehicles: optional(boolean),
        onlyStockOver90Days: optional(boolean),
      }),
    ),
  });
