import { array, enumValue, maybe, number, object, text } from '@fmtk/decoders';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../util/VehicleMedia.js';

export interface UpdateTransformationsRequest {
  vinMd5Hash: string;
  position: number;
  status: VehicleMediaStatus;
  vehicleMediaType: VehicleMediaType[];
  transformations?: string[];
}

export const decodeUpdateTransformationsRequest =
  object<UpdateTransformationsRequest>({
    position: number,
    status: enumValue(VehicleMediaStatus),
    transformations: maybe(array(text)),
    vehicleMediaType: array(enumValue(VehicleMediaType)),
    vinMd5Hash: text,
  });
