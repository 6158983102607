import { useQueryClient } from '@tanstack/react-query';
import { UserRemarketingVehicleEvent } from '../../../../api/util/UserRemarketingVehicleEvent.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useDealer } from '../../../hooks/useDealer.js';
import { makeCountRemarketingForUserQueryKey } from './useCountRemarketingVehiclesForUser.js';
import {
  ListRemarketingVehiclesForUserData,
  makeListRemarketingForUserQueryKey,
} from './useListRemarketingVehiclesForUser.js';

export interface UseUpdateRemarketingUserListInput {
  newEventIncoming: UserRemarketingVehicleEvent;
}

export const useUpdateRemarketingUserListItem = (): ((
  input: UseUpdateRemarketingUserListInput,
) => void) => {
  const { currentBrand } = useBrand();
  const { currentDealer } = useDealer();
  const api = useApiClient();
  const queryClient = useQueryClient();

  const updateFunction = ({
    newEventIncoming,
  }: UseUpdateRemarketingUserListInput): void => {
    if (currentBrand && currentDealer) {
      api.remarketing
        .userGetRemarketingVehicle({
          brand: currentBrand,
          dealerId: Number(currentDealer.id),
          id: newEventIncoming.id,
        })
        .then(
          (vehicle): void => {
            queryClient.setQueriesData<ListRemarketingVehiclesForUserData>(
              {
                queryKey: makeListRemarketingForUserQueryKey(
                  currentBrand,
                  currentDealer.id,
                ),
              },
              (data) => {
                if (data) {
                  return {
                    ...data,
                    pages: data.pages.map((p) => ({
                      ...p,
                      items: p.items.map((v) => {
                        if (v.id === vehicle.id) {
                          if (v.status !== vehicle.status) {
                            void queryClient.refetchQueries({
                              queryKey: makeCountRemarketingForUserQueryKey(
                                currentBrand,
                                currentDealer.id,
                              ),
                            });
                          }
                          return {
                            ...vehicle,
                            updated: Date.now(),
                            changes: newEventIncoming.changes,
                          };
                        }
                        return v;
                      }),
                    })),
                  };
                }
              },
            );
          },
          () => {
            return;
          },
        );
    }
  };
  return updateFunction;
};
