/* eslint-disable react/jsx-sort-props */
import { enumValue, maybe, normaliseNumber, text } from '@fmtk/decoders';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { HandDrive } from '../../../../api/util/HandDrive.js';
import { RemarketingVehicle } from '../../../../api/util/RemarketingVehicle.js';
import { UserAuctionRemarketingVehicle } from '../../../../api/util/UserRemarketingVehicle.js';
import { normaliseDate } from '../../../../util/decoders/normaliseDate.js';
import FormErrorsSummary from '../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  ConfirmModal,
  Form,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import Colour from '../Vehicle/Sections/Colour.js';
import Consumption from '../Vehicle/Sections/Cosumption.js';
import Emission from '../Vehicle/Sections/Emission.js';
import Engine from '../Vehicle/Sections/Engine.js';
import Environmental from '../Vehicle/Sections/Environmental.js';
import KeyInformation from '../Vehicle/Sections/KeyInformation.js';
import TechSpec from '../Vehicle/Sections/TechSpec.js';
import { ActionType } from '../Vehicle/Sections/types.js';

interface VehicleDetailsProps {
  remarketingVehicle: RemarketingVehicle | UserAuctionRemarketingVehicle;
  readonly?: boolean;
  onSave?: (
    remarketingVehicle: RemarketingVehicle | UserAuctionRemarketingVehicle,
  ) => void;
}

const VehicleDetails: FunctionComponent<VehicleDetailsProps> = ({
  remarketingVehicle,
  readonly,
  onSave,
}): JSX.Element => {
  const { t } = useTranslation();
  const { brandConfig } = useBrand();
  const navigate = useNavigate();
  const { showMessage } = useMessageStore();
  const [resetConfirm, setResetConfirm] = useState(false);

  const contentItems: ContentItem[] = [
    {
      text: t('pages.vehiclePage.details.keyInformation'),
      hash: 'keyInformation',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.engine'),
      hash: 'engine',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.colour'),
      hash: 'colour',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.techSpec'),
      hash: 'techSpec',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.emissions'),
      hash: 'emissions',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.consumptions'),
      hash: 'consumptions',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.environmental'),
      hash: 'environmental',
      sectionElementRef: useRef(null),
    },
  ];

  const { showCollection } = useMemo(() => {
    if (!brandConfig) {
      return {};
    }

    return {
      showCollection:
        remarketingVehicle.vehicle.brand === AvailableBrands.RollsRoyce,
    };
  }, [brandConfig, remarketingVehicle.vehicle.brand]);

  const form = useCreateForm(
    {
      //Key Information
      vin: text,
      model: text,
      groupName: text,
      variant: maybe(text),
      bodyStyle: text,
      drive: maybe(enumValue(HandDrive)),
      registrationDate: maybe(normaliseDate()),
      modelYear: maybe(normaliseNumber),
      productionDate: maybe(normaliseDate()),
      registrationMonth: maybe(text),
      registrationYear: maybe(normaliseNumber),

      // engine
      engineType: maybe(text),
      fuelType: text,
      capacity: normaliseNumber,
      bhp: maybe(text),
      cylinders: maybe(text),
      powerKw: maybe(text),
      torqueLbft: maybe(text),
      torqueNm: maybe(text),
      transmission: text,

      // environmental
      environmentalLabel: maybe(text),
      euroEmissionsClass: maybe(text),

      // nedcTest
      nedcUrbanFuelEconomy: maybe(normaliseNumber),
      nedcExtraUrbanFuelEconomy: maybe(normaliseNumber),
      nedcCombinedFuelEconomy: maybe(normaliseNumber),
      nedcCo2Emissions: maybe(normaliseNumber),

      // wltpTest
      batteryRange: maybe(normaliseNumber),
      batteryRangeCity: maybe(normaliseNumber),
      co2ClassDischargedBattery: maybe(text),
      co2ClassWeightedCombined: maybe(text),
      co2Emissions: maybe(normaliseNumber),
      comsumptionCombinedDischargedBattery: maybe(normaliseNumber),
      consumptionCombined: maybe(normaliseNumber),
      consumptionCombinedWeighted: maybe(normaliseNumber),
      consumptionExtraHigh: maybe(normaliseNumber),
      consumptionHigh: maybe(normaliseNumber),
      consumptionLow: maybe(normaliseNumber),
      consumptionMid: maybe(normaliseNumber),
      powerConsumptionCombined: maybe(normaliseNumber),
      powerConsumptionCombinedWeighted: maybe(normaliseNumber),
      weightedCombined: maybe(normaliseNumber),

      // techSpecs
      maxSpeedMph: maybe(text),
      maxSpeedKph: maybe(text),
      kerbWeight: maybe(text),
      length: maybe(text),
      width: maybe(text),
      acceleration0100Kph: maybe(text),
      acceleration060Mph: maybe(text),

      // appearanceOptions Colours
      exteriorColour: maybe(text),
      interiorColour: maybe(text),
      secondaryExteriorColour: maybe(text),
      secondaryInteriorColour: maybe(text),
      carpetColour: maybe(text),
      headliningColour: maybe(text),
      seatColour: maybe(text),
      steeringWheelColour: maybe(text),
      veneer: maybe(text),
    },
    async () => {
      if (!remarketingVehicle.id) {
        return;
      }
      showMessage({
        text: 'Vehicle updated successfully',
        severity: 'success',
        dismissible: true,
        duration: 3000,
      });

      onSave && onSave(remarketingVehicle);
    },
    undefined,
    [remarketingVehicle, brandConfig],
  );

  const [formState, formBind] = form;

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x.length),
      isDirty,
    ];
  }, [formState]);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const resetForm = useCallback(() => {
    const { vehicle } = remarketingVehicle || {};

    formBind.reset({
      //Key Information
      vin: vehicle?.vin,
      model: vehicle?.model.name || '',
      groupName: vehicle?.model.groupName || '',
      variant: vehicle?.variant || '',
      bodyStyle: vehicle.bodyStyle?.name,
      drive: vehicle?.handDrive || '',
      registrationDate: vehicle?.registrationDate
        ? DateTime.fromJSDate(vehicle.registrationDate).toISODate()
        : '',
      registrationMonth: vehicle.registrationMonth || '',
      registrationYear: vehicle.registrationYear,
      modelYear: vehicle?.modelYear || '',
      productionDate: vehicle?.productionDate
        ? DateTime.fromJSDate(vehicle.productionDate).toISODate()
        : '',

      // engine
      fuelType: vehicle.engine?.fuelType || '',
      bhp: vehicle?.engine?.bhp || '',
      capacity: vehicle?.engine?.capacity || '',
      cylinders: vehicle?.engine?.cylinders || '',
      engineType: vehicle?.engine?.description || '',
      powerKw: vehicle?.engine?.powerKw || '',
      torqueLbft: vehicle?.engine?.torqueLbft || '',
      torqueNm: vehicle?.engine?.torqueNm || '',
      transmission: vehicle.transmission?.name || '',

      // environmental
      environmentalLabel: vehicle?.environmentalLabel || '',
      euroEmissionsClass: vehicle?.euroEmissionsClass || '',

      // nedcTest
      nedcCo2Emissions: vehicle?.nedcTest?.nedcCo2Emissions || '',
      nedcCombinedFuelEconomy: vehicle?.nedcTest?.nedcCombinedFuelEconomy || '',
      nedcExtraUrbanFuelEconomy:
        vehicle?.nedcTest?.nedcExtraUrbanFuelEconomy || '',
      nedcUrbanFuelEconomy: vehicle?.nedcTest?.nedcUrbanFuelEconomy || '',

      // wltpTest
      batteryRange: vehicle?.wltpTest?.batteryRange || '',
      batteryRangeCity: vehicle?.wltpTest?.batteryRangeCity || '',
      co2ClassDischargedBattery:
        vehicle?.wltpTest?.co2ClassDischargedBattery || '',
      co2ClassWeightedCombined:
        vehicle?.wltpTest?.co2ClassWeightedCombined || '',
      co2Emissions: vehicle?.wltpTest?.co2Emissions || '',
      comsumptionCombinedDischargedBattery:
        vehicle?.wltpTest?.comsumptionCombinedDischargedBattery || '',
      consumptionCombined: vehicle?.wltpTest?.consumptionCombined || '',
      consumptionCombinedWeighted:
        vehicle?.wltpTest?.consumptionCombinedWeighted || '',
      consumptionExtraHigh: vehicle?.wltpTest?.consumptionExtraHigh || '',
      consumptionHigh: vehicle?.wltpTest?.consumptionHigh || '',
      consumptionLow: vehicle?.wltpTest?.consumptionLow || '',
      consumptionMid: vehicle?.wltpTest?.consumptionMid || '',
      powerConsumptionCombined:
        vehicle?.wltpTest?.powerConsumptionCombined || '',
      powerConsumptionCombinedWeighted:
        vehicle?.wltpTest?.powerConsumptionCombinedWeighted || '',
      weightedCombined: vehicle?.wltpTest?.weightedCombined || '',

      // techSpecs
      maxSpeedKph: vehicle?.techSpecs?.maxSpeedKph || '',
      maxSpeedMph: vehicle?.techSpecs?.maxSpeedMph || '',
      kerbWeight: vehicle?.techSpecs?.kerbWeight || '',
      length: vehicle?.techSpecs?.length || '',
      width: vehicle?.techSpecs?.width || '',
      acceleration0100Kph: vehicle?.techSpecs?.acceleration0100Kph || '',
      acceleration060Mph: vehicle?.techSpecs?.acceleration060Mph || '',

      // appearanceOptions Colours
      exteriorColour: vehicle?.appearanceOptions?.exteriorColour || '',
      interiorColour: vehicle?.appearanceOptions?.interiorColour || '',
      secondaryExteriorColour:
        vehicle?.appearanceOptions?.secondaryExteriorColour || '',
      secondaryInteriorColour:
        vehicle?.appearanceOptions?.secondaryInteriorColour || '',
      carpetColour: vehicle?.appearanceOptions?.carpetColour || '',
      headliningColour: vehicle?.appearanceOptions?.headliningColour || '',
      seatColour: vehicle?.appearanceOptions?.seatColour || '',
      steeringWheelColour:
        vehicle?.appearanceOptions?.steeringWheelColour || '',
      veneer: vehicle?.appearanceOptions?.veneer || '',
    });
  }, [formBind, remarketingVehicle]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  useEffect(() => {
    if (formState.submitError) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [formState.submitError, showMessage, t]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section title={t('details')}>
          <Stack spacing={4}>
            <Form form={form} translations="pages.vehiclePage.details.form">
              {/* Key Information */}
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <KeyInformation
                  title={t('pages.vehiclePage.details.keyInformation')}
                  showCollection={showCollection}
                  readonly={readonly}
                />
              </div>
              {/* Colour */}
              <div
                id={contentItems[1].hash}
                ref={
                  contentItems[1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Colour
                  title={t('pages.vehiclePage.details.colour')}
                  readonly={readonly}
                />
              </div>

              {/* Engine and comments */}
              <div
                id={contentItems[2].hash}
                ref={
                  contentItems[2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Engine title={t('pages.vehiclePage.details.engine')} />
              </div>
              {/* TechSpec */}
              <div
                id={contentItems[3].hash}
                ref={
                  contentItems[3]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <TechSpec
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.techSpec')}
                />
              </div>
              {/* Emissions */}
              <div
                id={contentItems[4].hash}
                ref={
                  contentItems[4]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Emission
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.emissions')}
                />
              </div>
              {/* Consumption */}
              <div
                id={contentItems[5].hash}
                ref={
                  contentItems[5]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Consumption
                  actionType={ActionType.ADD}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.consumptions')}
                />
              </div>
              {/* Environmental */}
              <div
                id={contentItems[6].hash}
                ref={
                  contentItems[6]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Environmental
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.environmental')}
                />
              </div>
              {!readonly && (
                <Box m={1} marginLeft="auto">
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <Button
                      tabIndex={1}
                      disabled={!isDirty || formState.busy}
                      onClick={() => {
                        if (!isDirty) {
                          return;
                        }
                        toggleResetConfirm();
                      }}
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LightTooltip
                      title={hasErrors ? <FormErrorsSummary /> : ''}
                    >
                      <span>
                        <LoadingButton
                          disabled={hasErrors}
                          endIcon={<Save />}
                          loading={formState.busy}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                          tabIndex={1}
                        >
                          {t('save')}
                        </LoadingButton>
                      </span>
                    </LightTooltip>
                  </Stack>
                </Box>
              )}
            </Form>
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={() => {
          resetForm();
          toggleResetConfirm();
          navigate(`..`);
        }}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default VehicleDetails;
