import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserFixedPriceRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { useBrand } from '../../../../../hooks/useBrand.js';
import { useDealer } from '../../../../../hooks/useDealer.js';
import { sx } from '../../../../../util/sx.js';
import SubmitOfferModal from './SubmitOfferModal.js';

export interface SubmitOfferButtonProps {
  isLoading: boolean;
  onChange: () => void;
  vehicle: UserFixedPriceRemarketingVehicle;
  link?: boolean;
}

const styles = sx({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export const SubmitOfferButton = ({
  isLoading,
  onChange,
  vehicle,
  link,
}: SubmitOfferButtonProps) => {
  const { currentBrand } = useBrand();
  const { currentDealer } = useDealer();
  const { t } = useTranslation();
  const [openOfferModal, setOpenOfferModal] = useState(false);

  const onClick = () => {
    onChange();
    setOpenOfferModal(true);
  };

  if (!currentBrand || !currentDealer) {
    return null;
  }

  return (
    <>
      {link ? (
        <Typography
          color="#44546F"
          component="span"
          onClick={onClick}
          sx={styles.link}
          variant="subtitle2"
        >
          {t(`pages.remarketingVehiclePage.fixedPrice.submitOffer`)}
        </Typography>
      ) : (
        <Button onClick={onClick} variant="contained">
          {t('pages.remarketingVehiclePage.fixedPrice.submitOffer')}
        </Button>
      )}
      {openOfferModal && (
        <SubmitOfferModal
          brand={currentBrand}
          dealerId={Number(currentDealer.id)}
          isLoading={isLoading}
          onClose={() => setOpenOfferModal(false)}
          onSubmit={() => onChange()}
          open={openOfferModal}
          remarketingVehicle={vehicle}
        />
      )}
    </>
  );
};
