import { array, object, optional, string } from '@fmtk/decoders';
import {
  RemarketingAction,
  decodeRemarketingAction,
} from '../../util/RemarketingAction.js';

export interface UserListRemarketingActionsResponse {
  items: RemarketingAction[];
  nextToken?: string;
}

export const decodeUserListRemarketingActionsResponse =
  object<UserListRemarketingActionsResponse>({
    items: array(decodeRemarketingAction),
    nextToken: optional(string),
  });
