import { MappingPropertyBase } from '@opensearch-project/opensearch/api/types';
import { getDealerIndexMapping } from './getDealerIndexMapping.js';
import { getVehicleListingViewIndexMapping } from './getVehicleListingViewIndexMapping.js';

export const getLeadLogMapping = (): MappingPropertyBase => {
  const dealerMapping = getDealerIndexMapping();
  const vehicleListingMapping = getVehicleListingViewIndexMapping();

  return {
    properties: {
      id: {
        type: 'keyword',
      },
      brand: {
        type: 'keyword',
      },
      dealer: {
        type: 'object',
        ...dealerMapping,
      },
      ipLocation: {
        type: 'geo_point',
      },
      leadOriginUrl: {
        type: 'text',
        fields: {
          keyword: {
            type: 'keyword',
            ignore_above: 256,
          },
        },
      },
      detail: {
        type: 'flat_object',
      },
      timestamp: {
        type: 'date',
      },
      vehicleListingViewSummary: {
        type: 'object',
        ...vehicleListingMapping,
      },
      success: {
        type: 'boolean',
      },
    },
  };
};
