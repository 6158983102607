import { Skeleton, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

const VehicleCardSkeleton: FunctionComponent = () => {
  return (
    <Stack spacing={4}>
      <Skeleton height={80} variant="text" width="40%" />
      {Array(3)
        .fill(0)
        .map((_, i) => (
          <Stack alignItems="center" direction="row" key={i} spacing={2}>
            <Skeleton height={100} variant="rectangular" width={150} />
            <Stack spacing={1} width="100%">
              <Stack direction="row" spacing={2}>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Stack>
              <Stack direction="row" spacing={2}>
                <Skeleton width="100%" />
                <Skeleton width="100%" />
              </Stack>
            </Stack>
          </Stack>
        ))}
    </Stack>
  );
};

export default VehicleCardSkeleton;
