import PDFJS from 'pdfjs-dist';
import React, { useCallback, useEffect } from 'react';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import ReportSkeleton from './ReportSkeleton.js';

interface PdfThumbnailProps {
  file: string | Blob;
}

PDFJS.GlobalWorkerOptions.workerSrc =
  'https://cdn.jsdelivr.net/npm/pdfjs-dist@3.3.122/build/pdf.worker.min.js';

const PdfThumbnail: React.FC<PdfThumbnailProps> = ({ file }) => {
  const [thumbnail, setThumbnail] = useAsyncState<string | null>();

  const fetchData = useCallback(async () => {
    try {
      let pdf;
      if (typeof file === 'string') {
        pdf = await PDFJS.getDocument(file).promise;
      } else {
        const arrayBuffer = await (file as Blob).arrayBuffer();
        if (!arrayBuffer) {
          console.error('Error getting blob file');
        }
        pdf = await PDFJS.getDocument(arrayBuffer).promise;
      }

      const page = await pdf.getPage(1);
      const viewport = page.getViewport({ scale: 1 });
      const canvas = document.createElement('canvas');
      const ctx = canvas.getContext('2d');
      canvas.height = viewport.height;
      canvas.width = viewport.width;
      if (!ctx) {
        return;
      }
      const renderContext = {
        canvasContext: ctx,
        viewport: viewport,
      };
      await page.render(renderContext).promise;

      return canvas.toDataURL();
    } catch (error) {
      console.error(error);
      return '';
    }
  }, [file]);

  useEffect(() => {
    setThumbnail(fetchData);
  }, [fetchData, setThumbnail]);

  return (
    <div>
      {thumbnail.value ? (
        <img
          alt="pdf thumbnail"
          src={thumbnail.value}
          style={{
            width: '100%',
            aspectRatio: '1/1',
            objectFit: 'contain',
          }}
        />
      ) : (
        <ReportSkeleton />
      )}
    </div>
  );
};
export default PdfThumbnail;
