import { object, string } from '@fmtk/decoders';

export interface GetVehicleListingByVinRequest {
  vin: string;
}

export const decodeGetVehicleListingByVinRequest =
  object<GetVehicleListingByVinRequest>({
    vin: string,
  });
