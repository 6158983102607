import { NavigateBefore, Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { SimpleImage } from '../../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import {
  VehicleMedia,
  VehicleMediaType,
} from '../../../../../api/util/VehicleMedia.js';
import { ConfirmModal, useMessageStore } from '../../../../common-ui/index.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { OnboardingSteps, filterFalsey } from '../../../../util/index.js';
import { sx } from '../../../../util/sx.js';
import { useAddVehicleListing } from '../../../hooks/mutations/useAddVehicleListing.js';
import { addVehicleStepperAtom } from '../../../state/atoms.js';
import VehicleMediaSection from '../../Vehicle/VehicleMedia.js';

const styles = sx({
  containerStyle: {
    justifyContent: 'space-between',
    bgcolor: 'background.paper',
    borderTop: '1px solid #C4CDD5',
    bottom: 0,
    padding: 1,
    position: 'sticky',
    zIndex: 'mobileStepper',
  },
});

function MediaStep() {
  const [stepperState, setStepperState] = useAtom(addVehicleStepperAtom);
  const { showMessage } = useMessageStore();
  const navigate = useNavigate();
  const [resetConfirm, setResetConfirm] = useState(false);
  const { brandConfig } = useBrand();
  const [youTubeUrlError, setYouTubeUrlError] = useState<boolean>();
  const { mutateAsync: addVehicleListing, isPending } = useAddVehicleListing();

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const prevStep = useMemo(() => {
    return (
      (brandConfig?.addListing &&
        brandConfig.addListing.steps.find(
          (x) => x === OnboardingSteps.Features,
        )) ||
      'details'
    );
  }, [brandConfig]);

  const handleCancel = useCallback(() => {
    navigate(`../${prevStep}`);
  }, [navigate, prevStep]);

  const handleChangeImages = useCallback(
    (simpleImages?: SimpleImage[]) => {
      setStepperState((state) => {
        if (!state.currentListing || !state.currentListing.dealer.id) {
          return state;
        }

        const vehicleMedia: VehicleMedia[] | undefined =
          state.currentListing.vehicleMedia?.filter(
            (x) =>
              ![VehicleMediaType.Image, VehicleMediaType.Legacy].includes(
                x.type,
              ),
          ) || [];

        if (simpleImages) {
          const newMedia: VehicleMedia[] = filterFalsey(
            simpleImages.map((x, index) => {
              if (x.isPlaceholder || !x.media) {
                return undefined;
              }

              return {
                ...x.media,
                position: index + 1,
              } as VehicleMedia;
            }),
          );

          vehicleMedia.push(...newMedia);
        }

        return {
          ...state,
          currentListing: {
            ...state.currentListing,
            vehicleMedia,
          },
        };
      });
    },
    [setStepperState],
  );

  const handleChangeVinImage = useCallback(
    (vinImage?: VehicleMedia) => {
      setStepperState((state) => {
        if (!state.currentListing || !state.currentListing.dealer.id) {
          return state;
        }

        const currentVehicleMedia = state.currentListing.vehicleMedia || [];

        const vinImageIndex = currentVehicleMedia.findIndex(
          (media) => media.type === VehicleMediaType.Vin,
        );

        // found the an existing vin image and there is new image
        if (vinImageIndex > -1 && vinImage) {
          currentVehicleMedia[vinImageIndex] = vinImage;
        }
        // there is no existing vin image and there is new image
        else if (vinImageIndex === -1 && vinImage) {
          currentVehicleMedia.push(vinImage);
        }
        // there is existing vin image and there is no new image
        else if (vinImageIndex > -1 && !vinImage) {
          currentVehicleMedia.splice(vinImageIndex, 1);
        }
        // there is no existing vin image and there is no new image we do nothing
        else {
          return state;
        }

        return {
          ...state,
          currentListing: {
            ...state.currentListing,
            vehicleMedia: currentVehicleMedia,
          },
        };
      });
    },
    [setStepperState],
  );

  const handleChangeYouTubeUrl = useCallback(
    (youTubeUrl?: string, hasError?: boolean) => {
      setYouTubeUrlError(hasError);
      setStepperState((state) => {
        if (
          !state.currentListing ||
          !state.currentListing.dealer.id ||
          !!hasError
        ) {
          return state;
        }
        return {
          ...state,
          currentListing: {
            ...state.currentListing,
            youTubeLink: youTubeUrl,
          },
        };
      });
    },
    [setStepperState],
  );

  const handleSave = useCallback(async () => {
    if (
      !stepperState.currentListing ||
      !stepperState.currentListing.dealer.id
    ) {
      return;
    }

    if (youTubeUrlError) {
      showMessage({
        severity: 'error',
        text: t('pages.vehiclePage.media.invalidYouTubeUrl'),
        dismissible: true,
      });
      return;
    }

    await addVehicleListing(stepperState.currentListing);

    setStepperState(RESET);
    navigate('../../dashboard');
  }, [
    stepperState.currentListing,
    youTubeUrlError,
    addVehicleListing,
    showMessage,
    setStepperState,
    navigate,
  ]);

  useEffect(() => {
    if (!stepperState.currentListing) {
      //clear state and go to step 1
      setStepperState(RESET);
      navigate('..', { replace: true });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [navigate, stepperState.currentListing]);

  return (
    <Stack spacing={3}>
      {stepperState.currentListing && (
        <VehicleMediaSection
          actions={
            <Box sx={styles.containerStyle}>
              <Stack direction="row" justifyContent="space-between" m={1}>
                <Button
                  disabled={isPending}
                  onClick={() => {
                    navigate(`../${prevStep}`);
                  }}
                  startIcon={<NavigateBefore />}
                  variant="outlined"
                >
                  {t(prevStep)}
                </Button>
                <LoadingButton
                  endIcon={<Save />}
                  loading={isPending}
                  loadingPosition="end"
                  onClick={() => void handleSave()}
                  variant="contained"
                >
                  {t('saveAndFinish')}
                </LoadingButton>
              </Stack>
            </Box>
          }
          hideSaveButton
          onChangeImages={handleChangeImages}
          onChangeVinImage={handleChangeVinImage}
          onChangeYouTubeUrl={handleChangeYouTubeUrl}
          options={{ expandedGroup: [VehicleMediaType.Image] }}
          vehicleListing={stepperState.currentListing}
        />
      )}
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={handleCancel}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
}

export default MediaStep;
