import { Box } from '@mui/material';
import { FunctionComponent, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  VehicleFeatures,
  VehicleListing,
} from '../../../../api/util/Vehicle.js';
import { useUpdateVehicleListing } from '../../hooks/mutations/useUpdateVehicleListing.js';
import VehicleFeaturesEditor from './VehicleFeaturesEditor.js';

interface ListingFeaturesProps {
  vehicleListing: VehicleListing;
  readonly?: boolean;
  onSave?: (vehicleListing: VehicleListing) => void;
}

const ListingFeatures: FunctionComponent<ListingFeaturesProps> = ({
  vehicleListing,
  onSave,
  readonly,
}) => {
  const { brand, dealer } = useParams();
  const navigate = useNavigate();
  const { mutate: updateVehicleListing, isPending } = useUpdateVehicleListing({
    onSettled: (data, error) => {
      if (!data || !!error) {
        return;
      }
      onSave && onSave(data);
    },
  });

  const handleCancel = useCallback(() => {
    navigate(`/${brand}/dealers/${dealer}/dashboard`);
  }, [brand, dealer, navigate]);

  const handleSave = (features?: VehicleFeatures) => {
    if (!vehicleListing) {
      return;
    }
    updateVehicleListing({
      id: vehicleListing.id,
      brand: vehicleListing.vehicle.brand,
      features: features,
    });

    return;
  };

  return (
    <Box>
      <VehicleFeaturesEditor
        features={vehicleListing.vehicle.features}
        loading={isPending}
        onCancel={handleCancel}
        onSave={handleSave}
        options={{ disabledIsDirty: true }}
        readonly={readonly}
      />
    </Box>
  );
};

export default ListingFeatures;
