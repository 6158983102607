import { enumValue, maybe, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from './AvailableBrands.js';

export enum InventoryReportProcessStatus {
  Creating = 'Creating',
  Error = 'Error',
  Complete = 'Complete',
}

export interface InventoryReportProcess {
  brand: AvailableBrands;
  userId: string;
  status: InventoryReportProcessStatus;
  createdAt: number;
  updatedAt?: number;
  _expireAt?: number; // in seconds
  reportLink?: string;
}

export const decodeInventoryReportProcess = object<InventoryReportProcess>({
  brand: enumValue(AvailableBrands),
  userId: text,
  status: enumValue(InventoryReportProcessStatus),
  reportLink: maybe(text),
  createdAt: number,
  updatedAt: maybe(number),
  _expireAt: maybe(number),
});
