/* eslint-disable react/jsx-sort-props */
import {
  array,
  chain,
  DecoderError,
  enumValue,
  error,
  jsonDate,
  maybe,
  normaliseNumber,
  number,
  ok,
  PropDecoders,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Save } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { OdometerUnit } from '../../../../api/util/OdometerUnit.js';
import {
  RemarketingType,
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import { SyndicationType } from '../../../../api/util/SyndicationType.js';
import { decodeUserDocument } from '../../../../api/util/UserDocument.js';
import { minNumber } from '../../../../util/decoders/minNumber.js';
import FormErrorsSummary from '../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import { RegionCountries } from '../../../common-ui/components/RegionCountrySelector/RegionSelector.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import { ConfirmModal, Form } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { formatPrice } from '../../../util/formatters.js';
import { useRemarketingEditor } from '../../hooks/useRemarketingEditor.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import Comments from '../Vehicle/Sections/Comments.js';
import { YesNo } from '../Vehicle/Sections/types.js';
import AdminBiddingHistory from './Sections/AdminBiddingHistory.js';
import AdminOffersHistory from './Sections/AdminOffersHistory.js';
import BidSection from './Sections/Bid.js';
import CancelRemarketingSection from './Sections/CancelRemarketing.js';
import Regions from './Sections/Regions.js';
import RemarketingDocumentsSection from './Sections/RemarketingDocuments.js';
import TermsAndConditionsSection from './Sections/TermsAndConditions.js';
import { dateTimeFormat, RemarketingVehicleType } from './types.js';

interface AuctionProps {
  remarketingVehicle: RemarketingVehicle;
  onUpdate: (remarketingVehicle: RemarketingVehicleType) => void;
  readonly?: boolean;
  type: RemarketingType.AUCTION | RemarketingType.FIXED_PRICE;
}

const commonDecoders = {
  currency: text,
  visibilityDate: jsonDate,
  startingDate: jsonDate,
  leadingBid: maybe(strToNum),
  maxBid: maybe(strToNum),
  bidders: maybe(number),
  odometer: chain(
    normaliseNumber,
    minNumber({
      min: 0,
    }),
  ),
  odometerUnit: enumValue(OdometerUnit),
  notes: maybe(text),
  termsAndConditions: maybe(decodeUserDocument),
  countries: array(text),
  shortVin: maybe(text),
  registrationPlate: maybe(text),
  syndicationType: enumValue(SyndicationType),
  recommendedRetailPrice: maybe(
    chain(
      strToNum,
      minNumber({
        min: 1,
        error: `pages.addRemarketingVehicle.details.form.errors.INVALID_PRICE`,
      }),
    ),
  ),
};

const auctionDecoders = {
  ...commonDecoders,
  closingDate: jsonDate,
  reservePrice: chain(
    strToNum,
    minNumber({
      min: 1,
      error: `pages.addRemarketingVehicle.details.form.errors.INVALID_PRICE`,
    }),
  ),
  startPrice: chain(
    strToNum,
    minNumber({
      min: 1,
      error: `pages.addRemarketingVehicle.details.form.errors.INVALID_PRICE`,
    }),
  ),
};

const fixedPriceDecoders = {
  ...commonDecoders,
  closingDate: maybe(jsonDate),
  price: chain(
    strToNum,
    minNumber({
      min: 1,
      error: `pages.addRemarketingVehicle.details.form.errors.INVALID_PRICE`,
    }),
  ),
  acceptsOffers: enumValue(YesNo),
};

type FormStateOf<T> = T extends PropDecoders<infer X> ? X : never;

type AuctionDecoder = FormStateOf<typeof auctionDecoders>;
type FixedPriceDecoder = FormStateOf<typeof fixedPriceDecoders>;

const Auction: FunctionComponent<AuctionProps> = ({
  remarketingVehicle,
  onUpdate,
  readonly,
  type,
}): JSX.Element => {
  const { t } = useTranslation();
  const [resetConfirm, setResetConfirm] = useState(false);
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const { brand } = useParams();
  const navigate = useNavigate();
  const isEditor = useRemarketingEditor();

  const contentItems: ContentItem[] = [
    {
      text: t('bids'),
      hash: 'bids',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.auction.auctionDetails'),
      hash: 'details',
      sectionElementRef: useRef(null),
    },
    {
      text: t('notes'),
      hash: 'notes',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.auction.termsAndConditions'),
      hash: 'terms',
      sectionElementRef: useRef(null),
    },
    {
      text:
        remarketingVehicle.type === RemarketingType.AUCTION
          ? t('pages.remarketingVehiclePage.auction.biddingHistory.title')
          : t('pages.remarketingVehiclePage.fixedPrice.offersHistory.title'),
      hash: 'history',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.auction.attachments'),
      hash: 'attachments',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.cancel.cancelSectionTitle'),
      hash: 'cancel',
      sectionElementRef: useRef(null),
    },
  ];

  const form = useCreateForm<
    AuctionDecoder | FixedPriceDecoder,
    RemarketingVehicle | undefined
  >(
    () => {
      return type === RemarketingType.AUCTION
        ? auctionDecoders
        : fixedPriceDecoders;
    },
    async (values) => {
      if (!remarketingVehicle.id || !currentBrand) {
        return;
      }

      let newVehicle: RemarketingVehicle | undefined = undefined;

      if (!('price' in values)) {
        newVehicle = {
          ...remarketingVehicle,
          type: RemarketingType.AUCTION,
          currency: values.currency,
          visibilityDate: DateTime.fromJSDate(values.visibilityDate).toMillis(),
          startingDate: DateTime.fromJSDate(values.startingDate).toMillis(),
          closingDate: DateTime.fromJSDate(values.closingDate).toMillis(),
          reservePrice: values.reservePrice,
          startPrice: values.startPrice,
          odometer: {
            value: values.odometer,
            units: values.odometerUnit,
          },
          notes: values.notes,
          countries: values.countries,
          termsAndConditions: values.termsAndConditions,
          shortVin: values.shortVin,
          registrationPlate: values.registrationPlate,
          vehicle: {
            ...remarketingVehicle.vehicle,
            rrp: {
              currency: values.currency,
              value: values.recommendedRetailPrice,
            },
          },
          syndicationType: values.syndicationType,
        };
      } else {
        newVehicle = {
          ...remarketingVehicle,
          type: RemarketingType.FIXED_PRICE,
          currency: values.currency,
          visibilityDate: DateTime.fromJSDate(values.visibilityDate).toMillis(),
          startingDate: DateTime.fromJSDate(values.startingDate).toMillis(),
          closingDate: values.closingDate
            ? DateTime.fromJSDate(values.closingDate).toMillis()
            : undefined,
          odometer: {
            value: values.odometer,
            units: values.odometerUnit,
          },
          notes: values.notes,
          countries: values.countries,
          termsAndConditions: values.termsAndConditions,
          shortVin: values.shortVin,
          registrationPlate: values.registrationPlate,
          vehicle: {
            ...remarketingVehicle.vehicle,
            rrp: {
              currency: values.currency,
              value: values.recommendedRetailPrice,
            },
          },
          price: values.price,
          acceptsOffers: values.acceptsOffers === YesNo.Yes,
          syndicationType: values.syndicationType,
        };
      }

      const updated = await api.remarketing.adminUpdateRemarketingVehicle({
        remarketingVehicle: newVehicle,
        brand: currentBrand,
      });

      onUpdate && onUpdate(updated);

      return updated;
    },
    (values) => {
      const visibilityDate = DateTime.fromJSDate(values.visibilityDate);
      const startingDate = DateTime.fromJSDate(values.startingDate);
      const closingDate = values.closingDate
        ? DateTime.fromJSDate(values.closingDate)
        : undefined;
      const errors: DecoderError[] = [];

      if (values.countries.length === 0) {
        errors.push({
          id: 'INVALID_COUNTRY_REGIONS',
          text: 'INVALID_COUNTRY_REGIONS',
          field: 'regionVisibility',
        });
      }

      if (visibilityDate > startingDate) {
        errors.push({
          id: 'INVALID_VISIBILITY_DATE',
          text: 'visibilityDate',
          field: 'visibilityDate',
        });
      }

      if (closingDate && startingDate >= closingDate) {
        errors.push(
          {
            id: 'INVALID_STARTING_DATE',
            text: 'startingDate',
            field: 'startingDate',
          },
          {
            id: 'INVALID_CLOSING_DATE',
            text: 'closingDate',
            field: 'closingDate',
          },
        );
      }

      if ('reservePrice' in values && values.reservePrice < values.startPrice) {
        errors.push({
          id: 'INVALID_RESERVE_PRICE',
          text: 'reservePrice',
          field: 'reservePrice',
        });
      }

      if (errors.length > 0) {
        return error(errors);
      }

      return ok(values);
    },
    [type],
  );

  const [formState, formBind] = form;

  const readonlyDepStatus = useMemo(() => {
    const bidders =
      remarketingVehicle.type === RemarketingType.AUCTION &&
      'bidders' in remarketingVehicle
        ? remarketingVehicle.bidders || []
        : [];

    if (
      remarketingVehicle.type === RemarketingType.AUCTION &&
      remarketingVehicle.status === RemarketingVehicleStatus.ACTIVE
    ) {
      return bidders.length > 0;
    }
    return readonly;
  }, [readonly, remarketingVehicle]);

  const resetForm = useCallback(() => {
    formBind.reset({
      currency: remarketingVehicle.currency,
      visibilityDate:
        'visibilityDate' in remarketingVehicle &&
        remarketingVehicle.visibilityDate
          ? DateTime.fromMillis(remarketingVehicle.visibilityDate).toFormat(
              dateTimeFormat,
            )
          : '',
      startingDate:
        'startingDate' in remarketingVehicle
          ? DateTime.fromMillis(remarketingVehicle.startingDate).toFormat(
              dateTimeFormat,
            )
          : '',
      reservePrice:
        'reservePrice' in remarketingVehicle
          ? String(remarketingVehicle.reservePrice || '')
          : '',
      startPrice:
        'startPrice' in remarketingVehicle
          ? String(remarketingVehicle.startPrice || '')
          : '',
      leadingBid:
        'currentBid' in remarketingVehicle && remarketingVehicle.currentBid
          ? formatPrice(
              remarketingVehicle.currentBid.currentPrice,
              remarketingVehicle.currency,
            )
          : '',
      maxBid:
        'currentBid' in remarketingVehicle && remarketingVehicle.currentBid
          ? formatPrice(
              remarketingVehicle.currentBid.maxBid,
              remarketingVehicle.currency,
            )
          : '',
      closingDate:
        'closingDate' in remarketingVehicle && remarketingVehicle.closingDate
          ? DateTime.fromMillis(remarketingVehicle.closingDate).toFormat(
              dateTimeFormat,
            )
          : '',
      bidders:
        ('bidders' in remarketingVehicle &&
          remarketingVehicle.bidders?.length) ||
        0,
      odometer:
        'odometer' in remarketingVehicle
          ? remarketingVehicle.odometer?.value
          : '',
      odometerUnit:
        'odometer' in remarketingVehicle
          ? remarketingVehicle.odometer?.units
          : '',
      shortVin: remarketingVehicle.shortVin || '',
      registrationPlate: remarketingVehicle.registrationPlate || '',
      recommendedRetailPrice: String(
        remarketingVehicle.vehicle?.rrp?.value || '',
      ),
      notes: remarketingVehicle.notes || '',
      termsAndConditions:
        'termsAndConditions' in remarketingVehicle
          ? remarketingVehicle.termsAndConditions
          : '',
      countries: remarketingVehicle.countries || '',
      price:
        'price' in remarketingVehicle ? String(remarketingVehicle.price) : '',
      acceptsOffers:
        'acceptsOffers' in remarketingVehicle &&
        remarketingVehicle.acceptsOffers
          ? YesNo.Yes
          : YesNo.No,
      syndicationType: remarketingVehicle.syndicationType,
    });
  }, [formBind, remarketingVehicle]);

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x.length),
      isDirty,
    ];
  }, [formState]);

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const handleRegionChange = useCallback(
    (regions: RegionCountries) => {
      const selectedCountries = Object.entries(regions).flatMap(
        ([_, countries]) =>
          filterFalsey(
            countries.flatMap((c) => (c.selected ? c.countryCode : undefined)),
          ),
      );

      formBind.setValue('countries', selectedCountries);
    },
    [formBind],
  );

  useEffect(() => {
    resetForm();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [type, remarketingVehicle]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section>
          <Stack spacing={4}>
            <Form
              form={form}
              translations="pages.remarketingVehiclePage.auction.form"
            >
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <BidSection
                  title={t('details')}
                  vehicle={remarketingVehicle}
                  readonly={readonly}
                  readonlyDepStatus={readonlyDepStatus}
                  type={type}
                />
              </div>
              <div
                id={contentItems[1].hash}
                ref={
                  contentItems[1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Regions
                  title={t(
                    'pages.addRemarketingVehicle.details.form.labels.regionVisibility',
                  )}
                  vehicle={remarketingVehicle}
                  readonly={readonly}
                  onChange={handleRegionChange}
                />
              </div>
              <div
                id={contentItems[2].hash}
                ref={
                  contentItems[2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Comments
                  title={t('notes')}
                  label=""
                  name="notes"
                  readonly={readonly}
                />
              </div>
              <div
                id={contentItems[3].hash}
                ref={
                  contentItems[3]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                {remarketingVehicle.type === RemarketingType.AUCTION && (
                  <AdminBiddingHistory
                    currency={remarketingVehicle.currency}
                    remarketingVehicle={remarketingVehicle}
                    title={t(
                      'pages.remarketingVehiclePage.auction.biddingHistory.title',
                    )}
                  />
                )}
                {remarketingVehicle.type === RemarketingType.FIXED_PRICE && (
                  <AdminOffersHistory
                    remarketingVehicle={remarketingVehicle}
                    title={t(
                      'pages.remarketingVehiclePage.fixedPrice.offersHistory.title',
                    )}
                    onUpdate={onUpdate}
                  />
                )}
              </div>
              <div
                id={contentItems[4].hash}
                ref={
                  contentItems[4]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <TermsAndConditionsSection
                  title={t(
                    'pages.remarketingVehiclePage.auction.termsAndConditions',
                  )}
                  brand={remarketingVehicle.vehicle.brand}
                  onSelectionChange={(doc) => {
                    formBind.setValue('termsAndConditions', doc);
                  }}
                  value={formState.values.termsAndConditions}
                  readonly={readonly}
                />
              </div>

              <div
                id={contentItems[contentItems.length - 2].hash}
                ref={
                  contentItems[contentItems.length - 2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <RemarketingDocumentsSection
                  title={t('pages.remarketingVehiclePage.auction.attachments')}
                  brand={remarketingVehicle.vehicle.brand}
                  remarketingVehicleId={remarketingVehicle.id}
                  readonly={
                    !isEditor ||
                    [
                      RemarketingVehicleStatus.TRANSFERRED,
                      RemarketingVehicleStatus.CLOSED,
                      RemarketingVehicleStatus.CANCELLED,
                    ].includes(remarketingVehicle.status)
                  }
                />
              </div>
              {!readonlyDepStatus && (
                <Box m={1} marginLeft="auto">
                  <Stack direction="row" justifyContent="end" spacing={1}>
                    <Button
                      tabIndex={1}
                      disabled={!isDirty || formState.busy}
                      onClick={() => {
                        if (!isDirty) {
                          return;
                        }
                        toggleResetConfirm();
                      }}
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LightTooltip
                      title={hasErrors ? <FormErrorsSummary /> : ''}
                    >
                      <span>
                        <LoadingButton
                          disabled={hasErrors}
                          endIcon={<Save />}
                          loading={formState.busy}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                          tabIndex={1}
                        >
                          {t('save')}
                        </LoadingButton>
                      </span>
                    </LightTooltip>
                  </Stack>
                </Box>
              )}
            </Form>
            {isEditor && (
              <div
                id={contentItems[contentItems.length - 1].hash}
                ref={
                  contentItems[contentItems.length - 1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <CancelRemarketingSection
                  title={t(
                    'pages.remarketingVehiclePage.cancel.cancelSectionTitle',
                  )}
                  remarketingVehicleId={remarketingVehicle.id}
                  readonly={
                    remarketingVehicle.status ===
                    RemarketingVehicleStatus.CANCELLED
                  }
                />
              </div>
            )}
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={() => {
          resetForm();
          toggleResetConfirm();
          navigate(`/${brand}/remarketing/management`);
        }}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default Auction;
