import {
  Link,
  MenuItem,
  Select,
  SelectChangeEvent,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ListUserDocumentsByReferenceResponse } from '../../../../../api/DocumentService/listUserDocumentsByReference/ListUserDocumentsByReferenceResponse.js';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';

import {
  UserDocument,
  UserDocumentScope,
  UserDocumentSource,
} from '../../../../../api/util/UserDocument.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { useMessageStore } from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useDownloadDocument } from '../../../../hooks/useDownloadDocument.js';
import { Section } from '../types.js';

type TermsAndConditionsSectionProps = Omit<Section, 'vehicle'> & {
  readonly?: boolean;
  brand: AvailableBrands;
  onSelectionChange: (doc?: UserDocument) => void;
  value?: UserDocument;
};

const TermsAndConditionsSection: FunctionComponent<
  TermsAndConditionsSectionProps
> = ({
  title,
  sectionItemsProps,
  brand,
  isLoading,
  value: selectedDoc,
  onSelectionChange,
  readonly,
}) => {
  const api = useApiClient();
  const { dealer } = useParams();
  const [, downloadDocument] = useDownloadDocument();
  const [listDocuments, setListDocuments] =
    useAsyncState<ListUserDocumentsByReferenceResponse>();

  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  const loadTerms = useCallback(() => {
    setListDocuments(async () => {
      try {
        const response = await api.document.listUserDocumentsByReference({
          brand,
          reference: brand,
          scope: UserDocumentScope.RemarketingTNCs,
          dealerId: Number(dealer) || undefined,
        });
        return response;
      } catch (error) {
        showMessage({
          severity: 'error',
          duration: 3000,
          text: t('documents.loadError'),
        });
      }
    });
  }, [setListDocuments, api.document, brand, dealer, showMessage, t]);

  const selectedValue = useMemo(() => {
    if (selectedDoc) {
      return selectedDoc;
    }

    if (listDocuments.value?.items.length) {
      // Try to return a document with name 'default'
      const defaultDoc = listDocuments.value.items.find(
        (d) => d.name.toLowerCase() === 'default',
      );
      return defaultDoc || listDocuments.value.items[0];
    }
    return;
  }, [listDocuments.value?.items, selectedDoc]);

  const handleChange = (event: SelectChangeEvent) => {
    const doc = listDocuments.value?.items.find(
      (d) => d.id === event.target.value,
    );
    onSelectionChange(doc);
  };

  useEffect(() => {
    loadTerms();
  }, [loadTerms]);

  useEffect(() => {
    onSelectionChange(selectedValue);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedValue]);

  return (
    <>
      <SectionItem title={`${title}`} {...sectionItemsProps}>
        <Stack spacing={4}>
          <Typography variant="body2">
            {t(
              `pages.addRemarketingVehicle.details.form.labels.termsAndConditions`,
            )}
          </Typography>
          <Stack sx={sectionStyles.sectionContent}>
            <Stack spacing={1}>
              {listDocuments.loading || isLoading ? (
                <>
                  <Skeleton height={40} variant="rectangular" width={200} />
                  <Skeleton variant="text" width={40} />
                </>
              ) : (
                <>
                  <Select
                    onChange={(e) => handleChange(e)}
                    readOnly={readonly}
                    sx={{ minWidth: 200 }}
                    value={selectedValue?.id || ''}
                  >
                    {!isLoading &&
                      listDocuments.value?.items.map((doc) => (
                        <MenuItem key={doc.id} value={doc.id}>
                          {`${doc.name} (${
                            doc.source === UserDocumentSource.Link
                              ? t('documents.link')
                              : t(`documents.PDF`)
                          })`}
                        </MenuItem>
                      ))}
                  </Select>
                  <Link
                    color="primary"
                    onClick={() => {
                      if (selectedValue) {
                        downloadDocument(selectedValue);
                      }
                    }}
                    underline="hover"
                    variant="button"
                  >
                    <Typography variant="body2">
                      {t('documents.view')}
                    </Typography>
                  </Link>
                </>
              )}
            </Stack>
          </Stack>
        </Stack>
      </SectionItem>
    </>
  );
};

export default TermsAndConditionsSection;
