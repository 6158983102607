import md5 from 'md5';
import { AvailableBrands } from '../../../api/util/AvailableBrands.js';
import { TranslationLocale } from '../../../api/util/TranslationLocale.js';
import {
  normaliseBaseText,
  Translation,
} from '../../../api/util/Translations.js';
import { DbModel } from '../DbModel.js';
import { DbModelType, ModelKey } from './types.js';

export interface TranslationKey {
  brand: AvailableBrands;
  locale: TranslationLocale;
  baseText: string;
}

export type TranslationDbModel = Translation;

export type TranslationsByBrandLocaleQuery = {
  brand: AvailableBrands;
  locale: TranslationLocale;
};

export const TranslationModel = new DbModel(
  DbModelType.Translation,
  // primary key
  (value: TranslationKey, type) => [
    [ModelKey.Brand, value.brand, ModelKey.Locale, value.locale],
    [type, md5(normaliseBaseText(value.baseText))],
  ],
  // indexes
  DbModel.noIndexes(),
  // queries
  {
    byBrandLocale: (query: TranslationsByBrandLocaleQuery, type) =>
      DbModel.primaryKey.query([
        [ModelKey.Brand, query.brand, ModelKey.Locale, query.locale],
        [type, undefined],
      ]),
  },
);
