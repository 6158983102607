import { enumValue, maybe, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetVehicleByVinRequest {
  vin: string;
  brand: AvailableBrands;
  country?: string;
}

export const decodeGetVehicleByVinRequest = object<GetVehicleByVinRequest>({
  vin: string,
  brand: enumValue(AvailableBrands),
  country: maybe(string),
});
