import { Clear } from '@mui/icons-material';
import {
  Chip,
  ClickAwayListener,
  InputBase,
  Link,
  Stack,
  Tooltip,
  Typography,
} from '@mui/material';
import { uniqueId } from 'lodash-es';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useDrag, useDrop } from 'react-dnd';
import { useTranslation } from 'react-i18next';
import { sx } from '../../util/sx.js';
import { CellInput } from './Cell/CellInput.js';

const ITEM_TYPE = 'chip';

const styles = sx({
  chipItem: {
    '&.MuiChip-root': {
      borderRadius: 0.5,
      color: '#637381',
      fontSize: 12,
      bgcolor: '#E8E8E8',
    },
  },
  container: {
    border: 2,
    borderRadius: 0.5,
    py: '10px',
    px: 1,
    borderColor: '#BDC7D3',
  },
  deleteIcon: {
    width: '14px',
    height: '14px',
    fill: '#637381',
    opacity: 1,
    ':hover': {
      opacity: 0.8,
    },
  },
  input: {
    fontSize: 14,
    color: '#637381',
    '&.MuiInputBase-root': {
      mt: 0,
      p: 0.5,
    },
  },
  list: {
    gap: 1,
  },
  hover: {
    ':hover': {
      cursor: 'pointer',
      textDecoration: 'underline',
    },
  },
  category: {
    '& .MuiInputBase-input': {
      padding: '4px 8px',
      color: '#172B4D',
      fontSize: 14,
      fontWeight: 700,
      lineHeight: '17px',
    },
  },
});

export interface ChipData {
  id: string;
  label: string;
}

interface ChipListProps {
  groupKey: string;
  canRemoveCategory?: boolean;
  onAddItem?: (category: string, item: string) => void;
  onOrderChange?: (category: string, values: string[]) => void;
  onRemoveCategory?: (category: string) => void;
  onRemoveItem?: (category: string, item: string) => void;
  onCategoryNameChange?: (category: string, originalCategory: string) => void;
  readonly?: boolean;
  title?: string;
  values?: string[];
  addFeaturesPlaceholder?: string;
}

const ChipList: React.FC<ChipListProps> = ({
  groupKey,
  canRemoveCategory,
  onAddItem,
  onOrderChange,
  onRemoveCategory,
  onRemoveItem,
  onCategoryNameChange,
  readonly,
  title,
  values,
  addFeaturesPlaceholder,
}): JSX.Element => {
  const [isEditing, setIsEditing] = useState(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const [chips, setChips] = useState<ChipData[]>([]);
  const [exitsItem, setExists] = useState<ChipData>();
  const { t } = useTranslation();
  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
    }),
  });

  const addChip = (label: string) => {
    const newChip: ChipData = {
      id: uniqueId(),
      label: label.trim(),
    };

    if (newChip.label !== '') {
      setChips((state) =>
        state.some((s) => s.label === newChip.label)
          ? state
          : [...new Set([...state, newChip])],
      );
    }
  };

  const deleteChip = readonly
    ? undefined
    : (chipId: string) => {
        const item = chips.find((chip) => chip.id === chipId);
        setExists((state) => (state?.id === chipId ? undefined : state));
        setChips(chips.filter((chip) => chip.id !== chipId));
        if (item) {
          onRemoveItem && onRemoveItem(groupKey, item.label);
        }
      };

  const moveChip = (dragIndex: number, hoverIndex: number) => {
    const draggedChip = chips[dragIndex];
    const newChips = [...chips];
    newChips.splice(dragIndex, 1);
    newChips.splice(hoverIndex, 0, draggedChip);
    setChips(newChips);
    onOrderChange &&
      onOrderChange(
        groupKey,
        newChips.map((i) => i.label),
      );
  };

  const handleChange = () => {
    setExists(undefined);
  };

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      const target = event.target as HTMLInputElement;
      const value = target.value.trim();
      const item = chips.find((chip) => chip.label === value);
      setExists(item);

      if (!item && value !== '') {
        addChip(value);
        onAddItem && onAddItem(groupKey, value);
        target.value = '';
      }
    }
  };

  const handleRemove = useCallback(() => {
    onRemoveCategory && onRemoveCategory(groupKey);
  }, [groupKey, onRemoveCategory]);

  const handleCategoryNameChange = useCallback(
    (value?: string) => {
      setIsEditing(false);
      if (!value || value === groupKey) {
        return;
      }

      onCategoryNameChange && onCategoryNameChange(value, groupKey);
    },
    [groupKey, onCategoryNameChange],
  );

  const handleCategoryOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleCategoryNameChange(inputRef.current?.value);
    }
  };

  const handleCategoryClick = useCallback(() => {
    if (readonly || !canRemoveCategory) {
      return;
    }
    setIsEditing(true);
  }, [canRemoveCategory, readonly]);

  useEffect(() => {
    if (!values) {
      return;
    }

    values.map((value) => addChip(value));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values]);

  return (
    <Stack spacing={1}>
      <Stack direction="row" justifyContent="space-between">
        {title && (
          <ClickAwayListener
            onClickAway={() => {
              handleCategoryNameChange(inputRef.current?.value);
            }}
          >
            <Stack flex={1}>
              {!isEditing ? (
                <Tooltip
                  arrow
                  placement="left"
                  title={readonly || !canRemoveCategory ? '' : t('clickToEdit')}
                >
                  <Typography
                    color="#637381"
                    fontWeight="medium"
                    onClick={handleCategoryClick}
                    sx={
                      readonly || !canRemoveCategory ? undefined : styles.hover
                    }
                    variant="subtitle2"
                  >
                    {title}
                  </Typography>
                </Tooltip>
              ) : (
                <Stack maxWidth={300}>
                  <CellInput
                    defaultValue={title}
                    inputProps={{
                      color: '#637381',
                      fontWeight: 'medium',
                      variant: 'subtitle2',
                    }}
                    inputRef={inputRef}
                    onKeyDown={handleCategoryOnKeyDown}
                    sx={styles.category}
                  />
                </Stack>
              )}
            </Stack>
          </ClickAwayListener>
        )}
        {!readonly && canRemoveCategory && (
          <Link
            color="#0C264E"
            marginLeft="auto"
            onClick={handleRemove}
            underline="always"
            variant="button"
          >
            {t('remove')}
          </Link>
        )}
      </Stack>
      <Stack spacing={2} sx={styles.container}>
        <Stack direction="row" flexWrap="wrap" ref={drop} sx={styles.list}>
          {chips.map((chip, index) => {
            return (
              <ChipItem
                chip={chip}
                deleteChip={deleteChip}
                highlighted={exitsItem?.id === chip.id}
                index={index}
                key={chip.id}
                moveChip={moveChip}
              />
            );
          })}
        </Stack>
        {!readonly && (
          <InputBase
            disabled={readonly}
            inputProps={{
              sx: {
                color: !!exitsItem ? 'error.main' : 'inherits',
                '&::placeholder': {
                  opacity: 1,
                  color: '#637381',
                },
              },
            }}
            onChange={handleChange}
            onKeyDown={handleOnKeyDown}
            placeholder={addFeaturesPlaceholder}
            sx={styles.input}
          />
        )}
      </Stack>
    </Stack>
  );
};

type ChipItemProps = {
  chip: ChipData;
  index: number;
  moveChip?: (dragIndex: number, hoverIndex: number) => void;
  deleteChip?: (chipId: string) => void;
  highlighted?: boolean;
};

const ChipItem: React.FC<ChipItemProps> = ({
  chip,
  index,
  moveChip,
  deleteChip,
  highlighted,
}) => {
  const [{ isDragging }, drag] = useDrag({
    type: ITEM_TYPE,
    item: { index },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });

  const [, drop] = useDrop({
    accept: ITEM_TYPE,
    drop: (item: { index: number }, monitor) => {
      const dragIndex = item.index;
      const hoverIndex = index;

      if (!monitor.isOver() || !monitor.canDrop() || dragIndex === hoverIndex) {
        return;
      }

      moveChip && moveChip(dragIndex, hoverIndex);
      item.index = hoverIndex;
    },
  });

  const handleDelete = deleteChip
    ? () => {
        deleteChip(chip.id);
      }
    : undefined;

  return (
    <div ref={drop}>
      <Chip
        deleteIcon={<Clear sx={styles.deleteIcon} />}
        label={chip.label}
        onDelete={handleDelete}
        ref={moveChip ? drag : undefined}
        style={{
          opacity: isDragging ? 0.5 : 1,
          border: `1px solid ${highlighted ? '#637381' : 'transparent'}`,
        }}
        sx={styles.chipItem}
      />
    </div>
  );
};

export default ChipList;
