import { Provider } from 'jotai';
import { RouteObject } from 'react-router-dom';
import { CurrencyProvider } from '../hooks/useCurrency.js';
import { default as RemarketingDetailsStep } from './components/AddRemarketingVehicle/Steps/DetailsStep.js';
import { default as RemarketingFeaturesStep } from './components/AddRemarketingVehicle/Steps/FeaturesStep.js';
import { default as RemarketingMediaStep } from './components/AddRemarketingVehicle/Steps/MediaStep.js';
import { default as RemarketingVinStep } from './components/AddRemarketingVehicle/Steps/VinStep.js';
import AddManuallyStep from './components/AddVehicle/Steps/AddManuallyStep.js';
import DetailsStep from './components/AddVehicle/Steps/DetailsStep.js';
import FeaturesStep from './components/AddVehicle/Steps/FeaturesStep.js';
import MediaStep from './components/AddVehicle/Steps/MediaStep.js';
import VinStep from './components/AddVehicle/Steps/VinStep.js';
import { DealerChecker } from './components/DealerChecker.js';
import MainLayout from './layout/MainLayout.js';
import AddRemarketingVehicle from './pages/AddRemarketingVehicle.js';
import AddVehiclePage from './pages/AddVehiclePage.js';
import DashboardPage from './pages/Dashboard.js';
import DashboardDealerPage from './pages/DashboardDealer.js';
import ErrorPage from './pages/ErrorPage.js';
import Home from './pages/Home.js';
import LocatorAnalyticsPage from './pages/LocatorAnalytics.js';
import RelistRemarketingVehicle from './pages/RelistRemarketingVehicle.js';
import RemarketingAdminPage from './pages/RemarketingAdminPage.js';
import RemarketingDashboard from './pages/RemarketingDashboard.js';
import RemarketingPage from './pages/RemarketingPage.js';
import RemarketingVehiclePage from './pages/RemarketingVehiclePage.js';
import UserDealer from './pages/UserDealer.js';
import UserRemarketingVehiclePage from './pages/UserRemarketingVehiclePage.js';
import VehiclePage from './pages/VehiclePage.js';

export const routes: RouteObject[] = [
  {
    path: '/',
    element: (
      <CurrencyProvider>
        <MainLayout />
      </CurrencyProvider>
    ),
    errorElement: <ErrorPage />,
    children: [
      {
        index: true,
        element: <Home />,
      },
      {
        path: '/:brand',
        element: <Home />,
      },
      {
        path: '/:brand/global/dashboard',
        element: <DashboardPage />,
      },
      {
        path: '/:brand/global/locator-analytics',
        element: <LocatorAnalyticsPage />,
      },
      {
        path: '/:brand/dealers/:dealer',
        element: <DealerChecker />,
        errorElement: <ErrorPage />,
        children: [
          {
            index: true,
            path: 'dashboard',
            element: <DashboardDealerPage />,
          },
          {
            path: 'dashboard/archived',
            element: <DashboardDealerPage archivedMode />,
          },
          {
            path: 'dashboard/archived/vehicles/:vehicleId/:tab',
            element: <VehiclePage archivedMode />,
          },
          {
            path: 'dashboard/archived/vehicles/:vehicleId',
            element: <VehiclePage archivedMode />,
          },
          {
            path: 'users',
            element: <UserDealer />,
          },
          {
            path: 'remarketing',
            element: <RemarketingPage />,
          },
          {
            path: 'remarketing/:remarketingId',
            element: <UserRemarketingVehiclePage />,
          },
          {
            path: 'remarketing/:remarketingId/:tab',
            element: <UserRemarketingVehiclePage />,
          },
          {
            path: 'vehicles/:vehicleId/:tab',
            element: <VehiclePage />,
          },
          {
            path: 'vehicles/:vehicleId',
            element: <VehiclePage />,
          },
          {
            path: 'vehicles/add',
            element: <AddVehiclePage />,
            children: [
              {
                index: true,
                element: <VinStep />,
              },
              {
                path: 'vin',
                element: <VinStep />,
              },
              {
                path: 'manually',
                element: <AddManuallyStep />,
              },
              {
                path: 'details',
                element: <DetailsStep />,
              },
              {
                path: 'features',
                element: <FeaturesStep />,
              },
              {
                path: 'media',
                element: <MediaStep />,
              },
            ],
          },
        ],
      },
      {
        path: '/:brand/remarketing',
        children: [
          {
            path: 'management',
            element: <RemarketingAdminPage />,
            children: [
              {
                index: true,
                element: <RemarketingDashboard />,
              },
              {
                path: 'add',
                element: <AddRemarketingVehicle />,
                children: [
                  {
                    index: true,
                    element: <RemarketingVinStep />,
                  },
                  {
                    path: 'details',
                    element: <RemarketingDetailsStep />,
                  },
                  {
                    path: 'features',
                    element: <RemarketingFeaturesStep />,
                  },
                  {
                    path: 'media',
                    element: <RemarketingMediaStep />,
                  },
                ],
              },
              {
                path: 'relist/:remarketingId',
                element: (
                  <Provider>
                    <RelistRemarketingVehicle />
                  </Provider>
                ),
                children: [
                  {
                    index: true,
                    path: 'details',
                    element: <RemarketingDetailsStep />,
                  },
                  {
                    path: 'features',
                    element: <RemarketingFeaturesStep />,
                  },
                  {
                    path: 'media',
                    element: <RemarketingMediaStep />,
                  },
                ],
              },
              {
                path: 'details/:remarketingId',
                element: <RemarketingVehiclePage />,
              },
              {
                path: 'details/:remarketingId/:tab',
                element: <RemarketingVehiclePage />,
              },
              {
                path: 'archived',
                element: <RemarketingDashboard archivedMode />,
              },
              {
                path: 'archived/details/:remarketingId',
                element: <RemarketingVehiclePage />,
              },
              {
                path: 'archived/details/:remarketingId/:tab',
                element: <RemarketingVehiclePage />,
              },
            ],
          },
        ],
      },
    ],
  },
];
