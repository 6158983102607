export function prefixKeyValue(
  values: (string | undefined)[],
  allowEmpty?: boolean,
): string {
  let active = true;

  if (!values.length) {
    if (!allowEmpty) {
      throw new Error(`empty key components not allowed`);
    }
    return '';
  }
  if (
    !values.every(
      (x) => typeof x === 'string' || (allowEmpty && typeof x === 'undefined'),
    )
  ) {
    throw new Error(
      `key components must be string, or undefined if allowEmpty is true`,
    );
  }

  const filtered: string[] = [];

  for (const value of values) {
    const valid = validKey(value);

    if (valid) {
      if (!active) {
        throw new Error(
          `non-empty key components cannot follow empty components`,
        );
      }

      filtered.push(value as string);
    } else if (!allowEmpty) {
      throw new Error(`empty key components not allowed`);
    }

    active = valid;
  }

  if (!filtered.length) {
    return '';
  }

  const key = filtered.join(':');

  if (filtered.length < values.length) {
    return key + ':';
  }
  return key;
}

function validKey(value: unknown): boolean {
  return typeof value !== 'undefined' && value !== null && value !== '';
}
