import { object, optional, string } from '@fmtk/decoders';

export interface ListUsersRequest {
  principalId?: string;
  email?: string;
}

export const decodeListUsersRequest = object<ListUsersRequest>({
  principalId: optional(string),
  email: optional(string),
});
