import { enumValue, jsonDate, maybe, object } from '@fmtk/decoders';
import { decodeVehicle, Vehicle } from '../../util/Vehicle.js';

export enum GetVehicleForRemarketingCode {
  NotFound = 'notFound',
  ListedForSale = 'listedForSale',
  Added = 'added',
}

export type GetVehicleForRemarketingResponse = {
  vehicle?: Vehicle;
  code?: GetVehicleForRemarketingCode;
};

export const decodeGetVehicleForRemarketingResponse =
  object<GetVehicleForRemarketingResponse>({
    code: maybe(enumValue(GetVehicleForRemarketingCode)),
    vehicle: maybe(decodeVehicle(jsonDate)),
  });
