import { Paper, Skeleton } from '@mui/material';
import { FunctionComponent } from 'react';
import { sx } from '../../util/sx.js';

export const styles = sx({
  summaryCard: {
    cursor: 'pointer',
    height: '100%',
  },
  totalCard: {
    boxShadow: 0,
  },
  summaryPaper: {
    p: 1,
    textAlign: 'center',
    flex: 1,
  },
  carsCount: {
    color: 'text.secondary',
  },
  dataGrid: {
    bgcolor: 'background.paper',
  },
});

const SummaryModelCardSkeleton: FunctionComponent = (): JSX.Element => {
  return (
    <Paper variant="outlined" sx={styles.summaryPaper}>
      <Skeleton variant="text" />
      <Skeleton variant="text" />
    </Paper>
  );
};

export default SummaryModelCardSkeleton;
