import { styled } from '@mui/material';
import { t } from 'i18next';

const ResponsiveIframeWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  overflow: 'hidden',
  width: '100%',
  paddingTop: '100%',
  [theme.breakpoints.up('lg')]: {
    paddingTop: '90%',
  },
  [theme.breakpoints.up('xl')]: {
    paddingTop: '80%',
  },
}));

const ResponsiveIframe = styled('iframe')({
  position: 'absolute',
  top: '0',
  left: '0',
  bottom: '0',
  right: '0',
  width: '100%',
  height: '100%',
});

type ResponsiveIframeProps = {
  frameSrc: string;
};

export default function Iframe({ frameSrc }: ResponsiveIframeProps) {
  return (
    <>
      <ResponsiveIframeWrapper>
        <ResponsiveIframe
          allowFullScreen
          frameBorder="0"
          src={frameSrc}
          title={t('pages.analytics.title')}
        ></ResponsiveIframe>
      </ResponsiveIframeWrapper>
    </>
  );
}
