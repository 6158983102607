import { Stack } from '@mui/material';
import { FunctionComponent, useEffect, useMemo } from 'react';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CellTextInput } from '../../../../common-ui/index.js';
import { useVehicle } from '../../../../hooks/useVehicle.js';
import { VehicleFields } from '../../../../util/VehicleFields.js';
import { ActionType, Section } from './types.js';

const sectionsFields = [
  VehicleFields.MaxSpeedMph,
  VehicleFields.MaxSpeedKph,
  VehicleFields.KerbWeight,
  VehicleFields.Length,
  VehicleFields.Width,
  VehicleFields.Acceleration0100Kph,
  VehicleFields.Acceleration060Mph,
  VehicleFields.Doors,
];

const TechSpec: FunctionComponent<Omit<Section, 'vehicleListing'>> = ({
  title,
  sectionItemsProps,
  readonly,
  actionType = ActionType.EDIT,
  isVisible,
}) => {
  const { fieldsConfig, resolveReadonly } = useVehicle();

  const showSection = useMemo(() => {
    return sectionsFields.some((k) => fieldsConfig[k]?.visible);
  }, [fieldsConfig]);

  useEffect(() => {
    isVisible && isVisible(showSection);
  }, [isVisible, showSection]);

  if (!showSection) {
    return <></>;
  }

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            {fieldsConfig.maxSpeedMph?.visible && (
              <CellTextInput
                label="maxSpeedMph"
                name="maxSpeedMph"
                readonly={resolveReadonly(
                  VehicleFields.MaxSpeedMph,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.maxSpeedKph?.visible && (
              <CellTextInput
                label="maxSpeedKph"
                name="maxSpeedKph"
                readonly={resolveReadonly(
                  VehicleFields.MaxSpeedKph,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.kerbWeight?.visible && (
              <CellTextInput
                label="kerbWeight"
                name="kerbWeight"
                readonly={resolveReadonly(
                  VehicleFields.KerbWeight,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.length?.visible && (
              <CellTextInput
                label="length"
                name="length"
                readonly={resolveReadonly(
                  VehicleFields.Length,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.doors?.visible && (
              <CellTextInput
                label="doors"
                name="doors"
                readonly={resolveReadonly(
                  VehicleFields.Doors,
                  actionType,
                  readonly,
                )}
              />
            )}
          </Stack>
          <Stack flex={1}>
            {fieldsConfig.width?.visible && (
              <CellTextInput
                label="width"
                name="width"
                readonly={resolveReadonly(
                  VehicleFields.Width,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.acceleration060Mph?.visible && (
              <CellTextInput
                label="acceleration060Mph"
                name="acceleration060Mph"
                readonly={resolveReadonly(
                  VehicleFields.Acceleration060Mph,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.acceleration0100Kph?.visible && (
              <CellTextInput
                label="acceleration0100Kph"
                name="acceleration0100Kph"
                readonly={resolveReadonly(
                  VehicleFields.Acceleration0100Kph,
                  actionType,
                  readonly,
                )}
              />
            )}
          </Stack>
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default TechSpec;
