import { Menu } from '@mui/icons-material';
import { alpha, AppBar, IconButton, styled, Toolbar } from '@mui/material';
import NotificationsContainer from '../components/InAppNotifications/NotificationsContainer.js';

const DRAWER_WIDTH = 280;
const APPBAR_MOBILE = 64;
const APPBAR_DESKTOP = 92;

const RootStyle = styled(AppBar)(({ theme }) => ({
  boxShadow: 'none',
  backdropFilter: 'blur(6px)',
  WebkitBackdropFilter: 'blur(6px)', // Fix on Mobile
  backgroundColor: alpha(theme.palette.background.default, 0.72),
  [theme.breakpoints.up('lg')]: {
    width: `calc(100% - ${DRAWER_WIDTH + 1}px)`,
  },
}));

const ToolbarStyle = styled(Toolbar)(({ theme }) => ({
  minHeight: APPBAR_MOBILE,
  [theme.breakpoints.up('lg')]: {
    minHeight: APPBAR_DESKTOP,
    padding: theme.spacing(0, 5),
  },
  justifyContent: 'space-between',
}));

interface DashboardNavbarProps {
  onOpenSidebar: () => void;
}

export default function DashboardNavbar({
  onOpenSidebar,
}: DashboardNavbarProps) {
  return (
    <RootStyle>
      <ToolbarStyle>
        <IconButton
          onClick={onOpenSidebar}
          sx={{ mr: 1, color: 'text.primary', display: { lg: 'none' } }}
        >
          <Menu />
        </IconButton>
        <NotificationsContainer />
      </ToolbarStyle>
    </RootStyle>
  );
}
