import {
  array,
  boolean,
  choose,
  Decoder,
  enumValue,
  is,
  maybe,
  number,
  object,
  ObjectDecoder,
  string,
  text,
} from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { RemarketingType } from '../../util/RemarketingVehicle.js';
import { SyndicationType } from '../../util/SyndicationType.js';
import { decodeUserDocument, UserDocument } from '../../util/UserDocument.js';
import {
  decodeVehicle,
  decodeVehicleOdometer,
  Vehicle,
  VehicleOdometer,
} from '../../util/Vehicle.js';
import { decodeVehicleMedia, VehicleMedia } from '../../util/VehicleMedia.js';

export interface AddRemarketingVehicleBase<D extends Date | number = Date> {
  brand: AvailableBrands;
  notes?: string;
  countries: string[];
  vehicle: Vehicle<D>;
  vehicleMedia: VehicleMedia<D>[];
  shortVin?: string;
  registrationPlate?: string;
  currency?: string;
  odometer?: VehicleOdometer;
  visibilityDate?: number;
  syndicationType: SyndicationType;
}

export interface AddUnassignedVehicleRequest<D extends Date | number = Date>
  extends AddRemarketingVehicleBase<D> {
  type: RemarketingType.UNASSIGNED;
}

export const decodeAddUnassignedVehicleRequest = <
  D extends Date | number = Date,
>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<AddUnassignedVehicleRequest<D>> =>
  object<AddUnassignedVehicleRequest<D>>({
    brand: enumValue(AvailableBrands),
    countries: array(string),
    notes: maybe(text),
    type: is(RemarketingType.UNASSIGNED),
    vehicle: decodeVehicle(dateDecoder),
    vehicleMedia: array(decodeVehicleMedia(dateDecoder)),
    shortVin: maybe(text),
    registrationPlate: maybe(text),
    currency: maybe(text),
    odometer: maybe(decodeVehicleOdometer),
    visibilityDate: maybe(number),
    syndicationType: enumValue(SyndicationType),
  });

export interface AddAuctionVehicleRequest<D extends Date | number = Date>
  extends AddRemarketingVehicleBase<D> {
  type: RemarketingType.AUCTION;
  startPrice: number;
  reservePrice: number;
  visibilityDate: number;
  startingDate: number;
  closingDate: number;
  termsAndConditions?: UserDocument;
  currency: string;
  odometer: VehicleOdometer;
}

export const decodeAddAuctionVehicleRequest = <D extends Date | number>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<AddAuctionVehicleRequest<D>> =>
  object<AddAuctionVehicleRequest<D>>({
    brand: enumValue(AvailableBrands),
    countries: array(string),
    notes: maybe(text),
    vehicle: decodeVehicle(dateDecoder),
    vehicleMedia: array(decodeVehicleMedia(dateDecoder)),
    startPrice: number,
    reservePrice: number,
    type: is(RemarketingType.AUCTION),
    termsAndConditions: maybe(decodeUserDocument),
    odometer: decodeVehicleOdometer,
    visibilityDate: number,
    startingDate: number,
    closingDate: number,
    shortVin: maybe(text),
    currency: text,
    registrationPlate: maybe(text),
    syndicationType: enumValue(SyndicationType),
  });

export interface AddFixedPriceVehicleRequest<D extends Date | number = Date>
  extends AddRemarketingVehicleBase<D> {
  type: RemarketingType.FIXED_PRICE;
  price: number;
  visibilityDate: number;
  startingDate: number;
  closingDate?: number;
  termsAndConditions?: UserDocument;
  currency: string;
  acceptsOffers: boolean;
  odometer: VehicleOdometer;
}

export const decodeAddFixedPriceVehicleRequest = <D extends Date | number>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<AddFixedPriceVehicleRequest<D>> =>
  object<AddFixedPriceVehicleRequest<D>>({
    brand: enumValue(AvailableBrands),
    countries: array(string),
    notes: maybe(text),
    vehicle: decodeVehicle(dateDecoder),
    vehicleMedia: array(decodeVehicleMedia(dateDecoder)),
    type: is(RemarketingType.FIXED_PRICE),
    termsAndConditions: maybe(decodeUserDocument),
    odometer: decodeVehicleOdometer,
    visibilityDate: number,
    startingDate: number,
    closingDate: maybe(number),
    shortVin: maybe(text),
    currency: text,
    registrationPlate: maybe(text),
    price: number,
    acceptsOffers: boolean,
    syndicationType: enumValue(SyndicationType),
  });

export type AddRemarketingVehicleRequest<D extends Date | number = Date> =
  | AddUnassignedVehicleRequest<D>
  | AddAuctionVehicleRequest<D>
  | AddFixedPriceVehicleRequest<D>;

export const decodeAddRemarketingVehicleRequest = <D extends Date | number>(
  dateDecoder: Decoder<D>,
): Decoder<AddRemarketingVehicleRequest<D>> =>
  choose(
    decodeAddUnassignedVehicleRequest(dateDecoder),
    decodeAddAuctionVehicleRequest(dateDecoder),
    decodeAddFixedPriceVehicleRequest(dateDecoder),
  );
