import { method, service } from '../util/ServiceDef.js';
import { none } from '../util/none.js';
import { decodeGetEventCountRequest } from './getEventCount/GetEventCountRequest.js';
import { decodeGetEventCountResponse } from './getEventCount/GetEventCountResponse.js';
import { decodeRecordEventRequest } from './recordEvent/RecordEventRequest.js';

export const AnalyticsServiceDef = service({
  getEventCount: method(
    decodeGetEventCountRequest,
    decodeGetEventCountResponse,
    false,
  ),
  recordEvent: method(decodeRecordEventRequest, none, false),
});

export type AnalyticsServiceDef = typeof AnalyticsServiceDef;
