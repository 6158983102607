import { AvailableBrands } from '../../../api/util/AvailableBrands.js';
import { BrandConfig } from '../../../apps/util/BrandsConfig.js';
import { DbModel } from '../DbModel.js';
import { DbModelType } from './types.js';

export interface BrandConfigKey {
  brand: AvailableBrands;
}

export type BrandConfigDbModel = BrandConfig;

export const BrandConfigModel = new DbModel(
  DbModelType.BrandConfig,
  //primary key
  (value: BrandConfigKey, type) => [[type, value.brand], type],
  DbModel.noIndexes<BrandConfig>(),
  // queries
  {},
);
