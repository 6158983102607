import {
  array,
  boolean,
  choose,
  enumValue,
  jsonDate,
  number,
  object,
  optional,
  string,
} from '@fmtk/decoders';
import { ListingLiveStatus } from '../../util/index.js';

export interface LiveStatusChange {
  vin: string;
  visible: boolean;
  statusChangedDate: Date;
  modelName: string;
  modelCode?: string;
  dealerName: string;
  price: string;
  currency: string;
  reason?: ListingLiveStatus | string;
  totalRows: number;
  odometerValue?: number;
  odometerUnits?: string;
}

export type ListLiveStatusChangesResponse = LiveStatusChange[];

export const decodeListLiveStatusChangesResponse = array(
  object<LiveStatusChange>({
    vin: string,
    visible: boolean,
    statusChangedDate: jsonDate,
    modelName: string,
    modelCode: optional(string),
    dealerName: string,
    price: string,
    currency: string,
    reason: optional(choose(enumValue(ListingLiveStatus), string)),
    totalRows: number,
    odometerValue: optional(number),
    odometerUnits: optional(string),
  }),
);
