import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { AdminListRemarketingVehiclesResponse } from '../../../../api/RemarketingService/adminListRemarketingVehicles/AdminListRemarketingVehiclesResponse.js';
import { RemarketingVehicleSort } from '../../../../api/RemarketingService/common.js';
import { ArchiveOptions } from '../../../../api/util/ArchiveOptions.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import {
  RemarketingVehicle,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import { RemarketingVehicleEvent } from '../../../../api/util/RemarketingVehicleEvent.js';
import {
  WsChannelType,
  WsRemarketingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { QueryKeys } from './QueryKeys.js';
import { useUpdateRemarketingAdminListItem } from './updateRemarketingAdminListItem.js';

export interface ListRemarketingForAdminInput {
  order: 'asc' | 'desc';
  searchTerm: string;
  sort: RemarketingVehicleSort;
  archiveOption?: ArchiveOptions;
  status?: RemarketingVehicleStatus;
}

export type ListRemarketingAdminItemType = RemarketingVehicle & {
  updated?: number;
  changes?: string[];
};

export type ListRemarketingVehiclesForAdminData = InfiniteData<
  Omit<AdminListRemarketingVehiclesResponse, 'items'> & {
    items: ListRemarketingAdminItemType[];
  }
>;

export const makeListRemarketingForAdminQueryKey = (
  brand: AvailableBrands,
  input?: ListRemarketingForAdminInput,
): QueryKey => filterFalsey([QueryKeys.ListRemarketingForAdmin, brand, input]);

export const useListRemarketingVehiclesForAdmin = (
  input: ListRemarketingForAdminInput,
): UseInfiniteQueryResult<ListRemarketingVehiclesForAdminData> => {
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const updateState = useUpdateRemarketingAdminListItem();

  const newEventIncoming = useWsChannelEvent<RemarketingVehicleEvent>(
    WsRemarketingEvent.remarketingChange,
    WsChannelType.RemarketingAdmin,
  );

  useEffect(() => {
    if (newEventIncoming) {
      updateState({ newEventIncoming });
    }
  }, [newEventIncoming]);

  return useInfiniteQuery({
    queryKey: makeListRemarketingForAdminQueryKey(
      currentBrand as AvailableBrands,
      input,
    ),
    enabled: !!currentBrand,
    queryFn: async ({ pageParam }) => {
      return await api.remarketing.listRemarketingVehiclesForAdmin({
        brand: currentBrand as AvailableBrands,
        order: input.order,
        pageNumber: pageParam ?? 0,
        pageSize: 25,
        searchTerm: input.searchTerm,
        sort: input.sort,
        archiveOption: input.archiveOption,
        status: input.status,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastResponse, __, lastPageParam) =>
      lastResponse.items.length ? (lastPageParam || 0) + 1 : undefined,
    refetchOnMount: false,
  });
};
