export interface Middleware<T> {
  (input: T): T;
}

export function composeMiddleware<T>(
  middleware: Middleware<T>[],
): Middleware<T> {
  return (input) =>
    middleware.reduceRight(
      (client: T, mw: Middleware<T>): T => mw(client),
      input,
    );
}
