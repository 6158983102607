import { MenuItem, Select, SelectChangeEvent } from '@mui/material';
import { uniqueId } from 'lodash-es';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useConst } from '../../../hooks/useConst.js';
import { Option } from '../../../util/Option.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { Cell, CellInput, CellProps, useForm } from '../../index.js';

type CellSelectInputProps = Pick<
  CellProps,
  'name' | 'label' | 'readonly' | 'formattedValue' | 'required'
> & {
  options: Option[];
  onChange?: (selectedValue: Option | undefined) => void;
  noneText?: string;
};

export const CellSelectInput: FunctionComponent<CellSelectInputProps> = ({
  name,
  label,
  readonly,
  options,
  onChange,
  formattedValue,
  required,
  noneText,
}) => {
  const [{ values }, formBind, { translations }] = useForm();
  const ref = useConst(() => uniqueId('id'));
  const { t } = useTranslation();

  const value = useMemo(() => {
    return options.find((o) => String(o.value) === String(values[name]));
  }, [name, options, values]);

  const handleChange = useCallback(
    (e: SelectChangeEvent) => {
      formBind.setValue(name, e.target.value);
      onChange &&
        onChange(
          options.find((o) => String(o.value) === String(e.target.value)),
        );
    },
    [formBind, name, onChange, options],
  );

  const translatedValue = useMemo(() => {
    return !!value?.text
      ? t([value.text, `${name}.${value.text}`], { defaultValue: value.text })
      : undefined;
  }, [name, t, value]);

  return (
    <Cell
      formattedValue={formattedValue || translatedValue}
      htmlFor={ref}
      label={
        translations
          ? t(`${translations}.labels.${name}`, {
              defaultValue: label,
            })
          : label
      }
      name={name}
      readonly={readonly}
      required={required}
    >
      <Select
        MenuProps={{
          disablePortal: true,
          PaperProps: {
            style: {
              maxHeight: 300,
            },
          },
        }}
        input={<CellInput fullWidth />}
        onChange={handleChange}
        value={String(value?.value) || ''}
      >
        {noneText && <MenuItem value="">{noneText}</MenuItem>}
        {options.map((option, idx) => (
          <MenuItem key={`${idx}-${option.value}`} value={option.value}>
            {t(
              filterFalsey([
                translations
                  ? `${translations}.${name}.${option.value}`
                  : undefined,
                `${name}.${option.text}`,
                option.text,
              ]),
              {
                defaultValue: option.text,
              },
            )}
          </MenuItem>
        ))}
      </Select>
    </Cell>
  );
};

export default CellSelectInput;
