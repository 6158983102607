import { boolean, maybe, optional } from '@fmtk/decoders';
import { decodeDealersRegion } from '../ReportingService/listDealersByRegion/ListDealersByRegionResponse.js';
import { decodeListDealersByRegionRequest } from '../ReportingService/listDealersByRegion/ListRegionDealersRequest.js';
import { decodeUserSettings } from '../util/UserSettings.js';
import {
  MethodScope,
  UserPermissions,
  method,
  none,
  service,
} from '../util/index.js';
import { decodeAccessRequestRequest } from './accessRequest/AccessRequestRequest.js';
import { decodeGetCurrentIdentityResponse } from './getCurrentIdentity/GetCurrentIdentity.js';
import { decodeGetDealerUserByIdRequest } from './getDealerUserById/GetUserByIdRequest.js';
import { decodeListUnregisteredUsersResponse } from './listUnregisteredUsers/ListUnregisteredUsersResponse.js';
import { decodeListUsersRequest } from './listUsers/ListUsersRequest.js';
import { decodeListUsersResponse } from './listUsers/ListUsersResponse.js';
import { decodeListDealerUsersRequest } from './listUsersByDealer/ListDealerUsersRequest.js';
import {
  decodeDealerUser,
  decodeListUsersByDealerResponse,
} from './listUsersByDealer/ListDealerUsersResponse.js';
import { decodeRegisterUserRequest } from './registerUser/RegisterUserRequest.js';
import { decodeRegisterUserResponse } from './registerUser/RegisterUserResponse.js';
import { decodeRemoveDealerAccessRequest } from './removeDealerAccess/RemoveDealerAccessRequest.js';
import { decodeRemoveUserRequest } from './removeUser/RemoveUserRequest.js';
import { decodeRemoveUserBrandPolicyRequest } from './removeUserBrandPolicy/RemoveUserBrandPolicyRequest.js';
import { decodeResetPasswordRequest } from './resetPassword/ResetPasswordRequest.js';
import { decodeSendInviteRequest } from './sendInvite/SendInviteRequest.js';
import { decodeUpdateDealerUserRequest } from './updateDealerUser/UpdateDealerUserRequest.js';
import { decodeUpdateUserRequest } from './updateUser/UpdateUserRequest.js';
import { decodeUpdateUserBrandPolicyRequest } from './updateUserBrandPolicy/UpdateUserPolicyRequest.js';
import { decodeUpsertUserSettingsRequest } from './upsertUserSettings/UpsertUserSettingsRequest.js';

export const UserServiceDef = service({
  accessRequest: method(decodeAccessRequestRequest, none, MethodScope.Any),
  listUsers: method(decodeListUsersRequest, decodeListUsersResponse, [
    UserPermissions.Support,
  ]),
  updateUser: method(decodeUpdateUserRequest, none, [UserPermissions.Support]),
  updateUserBrandPolicy: method(decodeUpdateUserBrandPolicyRequest, none, [
    UserPermissions.Support,
  ]),
  registerUser: method(decodeRegisterUserRequest, decodeRegisterUserResponse, [
    UserPermissions.Basic,
  ]),
  getCurrentIdentity: method(
    none,
    decodeGetCurrentIdentityResponse,
    MethodScope.Any,
  ),
  listDealersByRegion: method(
    decodeListDealersByRegionRequest,
    decodeDealersRegion,
    [UserPermissions.Support],
  ),
  removeBrandPolice: method(decodeRemoveUserBrandPolicyRequest, none, [
    UserPermissions.Support,
  ]),
  listUsersByDealer: method(
    decodeListDealerUsersRequest,
    decodeListUsersByDealerResponse,
    [UserPermissions.Basic],
  ),
  listUnregisteredUsers: method(none, decodeListUnregisteredUsersResponse, [
    UserPermissions.Support,
  ]),
  removeAccessToDealer: method(decodeRemoveDealerAccessRequest, none, [
    UserPermissions.Basic,
  ]),
  removeUser: method(decodeRemoveUserRequest, none, [UserPermissions.Support]),
  getDealerUserById: method(decodeGetDealerUserByIdRequest, decodeDealerUser, [
    UserPermissions.Basic,
  ]),
  updateDealerUser: method(decodeUpdateDealerUserRequest, decodeDealerUser, [
    UserPermissions.Basic,
  ]),
  sendEmailVerification: method(none, none, [UserPermissions.Basic]),
  resetPassword: method(decodeResetPasswordRequest, optional(boolean), [
    UserPermissions.Basic,
  ]),
  upsertUserSettings: method(
    decodeUpsertUserSettingsRequest,
    maybe(decodeUserSettings),
    [UserPermissions.Basic],
  ),
  getUserSettings: method(none, maybe(decodeUserSettings), [
    UserPermissions.Basic,
  ]),
  sendInvite: method(decodeSendInviteRequest, none, [UserPermissions.Basic]),
});

export type UserServiceDef = typeof UserServiceDef;
