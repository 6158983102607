import { enumValue, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetVehicleForRemarketingRequest {
  brand: AvailableBrands;
  vin: string;
}

export const decodeGetVehicleForRemarketingRequest =
  object<GetVehicleForRemarketingRequest>({
    brand: enumValue(AvailableBrands),
    vin: text,
  });
