import { Stack, Typography, styled } from '@mui/material';
import { useTranslation } from 'react-i18next';

const CustomUl = styled('ul')(({ theme }) => ({
  display: 'grid',
  gridTemplateColumns: '1fr',
  padding: '0 16px',
  margin: '8px 0',
  color: '#44546F',
  [theme.breakpoints.up('md')]: {
    gridTemplateColumns: 'repeat(2, 1fr)',
  },
}));

interface BoxListCardProps {
  archiveMode?: boolean;
  items: string[];
  isError?: boolean;
  title?: string;
}

const BoxListCard = ({
  archiveMode,
  items,
  isError,
  title,
}: BoxListCardProps): JSX.Element => {
  const { t } = useTranslation();
  return (
    <Stack
      bgcolor={isError && !archiveMode ? 'error.lighter' : 'background.paper'}
      border={2}
      borderColor={isError && !archiveMode ? 'error.main' : '#BDC7D3'}
      borderRadius={0.5}
      flex={1}
      maxWidth={800}
      mt={{
        xs: 0,
        md: 1,
      }}
      px={2}
      py={0.5}
    >
      {title && (
        <Typography color="#44546F" variant="subtitle2">
          {title}
        </Typography>
      )}
      {items.length === 0 ? (
        <Typography
          color="#44546F"
          fontWeight="fontWeightMedium"
          variant="body2"
        >
          {t('noAnalyticsEvent')}
        </Typography>
      ) : (
        <CustomUl>
          {items.map((item) => (
            <Typography
              color="#44546F"
              component="li"
              fontWeight="fontWeightMedium"
              key={item}
              textTransform="capitalize"
              variant="body2"
            >
              {item}
            </Typography>
          ))}
        </CustomUl>
      )}
    </Stack>
  );
};

export default BoxListCard;
