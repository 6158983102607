import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { decodeListTaxonomyOptionsRequest } from './listTaxonomyOptions/ListTaxonomyOptionsRequest.js';
import { decodeListTaxonomyOptionsResponse } from './listTaxonomyOptions/ListTaxonomyOptionsResponse.js';

export const VehicleServiceDef = service({
  listTaxonomyOptions: method(
    decodeListTaxonomyOptionsRequest,
    decodeListTaxonomyOptionsResponse,
    [UserPermissions.Basic],
  ),
});

export type VehicleServiceDef = typeof VehicleServiceDef;
