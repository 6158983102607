import { jsonDate, number, object } from '@fmtk/decoders';

export interface FeedSyncStatusResponse {
  batchId: number;
  processStartedAt: Date;
}

export const decodeFeedSyncStatusResponse = object<FeedSyncStatusResponse>({
  batchId: number,
  processStartedAt: jsonDate,
});
