import {
  ListRegionStockStatsResponse,
  RegionStockStats,
} from '../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import CertifiedVehiclesReport from './CertifiedVehicles/CertifiedVehiclesReport.js';
import DealersCarsReport from './DealersCarsReport/DealersCarsReport.js';
import DealerVehicleMovementsReport from './DealerVehicleMovements/DealerVehicleMovementsReport.js';
import ImageryReport from './ImageryReport/ImageryReport.js';
import PreownedInventoryPricingReport from './PreownedInventoryPricing/PreownedInventoryPricingReport.js';
import StockOver90DaysReport from './StockOver90Days/StockOver90DaysReport.js';
import StockVisibleReport from './StockVisible/StockVisibleReport.js';

export {
  PreownedInventoryPricingReport,
  DealersCarsReport,
  StockVisibleReport,
  CertifiedVehiclesReport,
  DealerVehicleMovementsReport,
  StockOver90DaysReport,
  ImageryReport,
};

export type ChartDatasetType = {
  label: string;
  data: string[] | number[];
  borderColor?: string;
  backgroundColor: string;
  stack?: string;
};

export type ChartDataType = {
  labels: string[];
  datasets: ChartDatasetType[];
};

export interface IGridRow {
  title: string;
  [x: string]: string | number;
  total: number | string;
}

/**
 * Builds an entire row that contains first cell with Title, and the rest with dynamic Regions values.
 * @param key This is the cell name to be displayed.
 * @param propertyName The property we want to get the value from.
 * @param regions Region list to populate each column with its own value.
 * @param data Dataset that contains the information to be populated.
 * @param operation Indicates what operation must be done on Total column. Default is SUM.
 * @returns object {title: string, Europe: number, "The Americas": number, etc..., total: number}
 */
export const buildGridRowWithTotal = (
  key: string,
  propertyName: keyof RegionStockStats,
  regions: string[],
  data: ListRegionStockStatsResponse,
  operation: 'sum' | 'avg' = 'sum',
): IGridRow => {
  const obj: IGridRow = { title: key, total: 0 };
  let total = 0;

  // Builds an object with region names as properties.
  for (const region of regions) {
    const objFound = data.find((item) => item.regionName === region);

    // Creates an object with all region names as property, and assign to each
    // the property value we want.
    if (objFound) {
      const propertyValue = Number(objFound[propertyName]);
      obj[region] = propertyValue;
      total += propertyValue;
    }
  }

  let calculationResult = 0;
  switch (operation) {
    case 'sum':
      calculationResult = total;
      break;
    case 'avg':
      calculationResult = Math.round(total / regions.length);
      break;
  }

  obj.total = calculationResult;

  return {
    ...obj,
  };
};
