export enum ServiceErrorCode {
  NotImplemented = 'NOT_IMPLEMENTED',
  VumoIssue = 'VUMO_ISSUE',
  FixerIssue = 'FIXER_ISSUE',
}

export class ServiceError extends Error {
  constructor(
    public readonly service: string,
    public readonly error: string,
    message: string,
    public readonly details?: Record<string, unknown>,
    public readonly tags?: Record<string, unknown>,
  ) {
    super(message);
    this.name = `${service}:${error}`;
  }
}
