import {
  Alert,
  AlertColor,
  AlertProps,
  Snackbar,
  SnackbarOrigin,
  Stack,
} from '@mui/material';
import { ContainerProps } from '@mui/system';
import React, { useEffect, useState } from 'react';
import { useContextWithError, useToggle } from '../../hooks/index.js';
import { sx } from '../../util/sx.js';

export interface Message {
  dismissible?: boolean;
  duration?: number;
  severity: AlertColor;
  text: string | string[];
  alertProps?: Pick<AlertProps, 'icon'>;
  anchorOrigin?: SnackbarOrigin;
}

export interface MessageStore {
  message?: Message;
  showMessage: (message?: Message) => void;
}

const MessageStoreContext = React.createContext<MessageStore | undefined>(
  undefined,
);

const styles = sx({
  snack: {
    transform: 'translateY(32px)',
  },
});

export function MessageStoreProvider({
  children,
}: ContainerProps): JSX.Element {
  const [message, setMessage] = useState<Message>();
  const [open, toggleOpen] = useToggle();

  const value = { message, showMessage: setMessage };

  const handleClose = () => {
    setMessage(undefined);
    toggleOpen(false);
  };

  useEffect(() => {
    toggleOpen(!!message);
  }, [message, toggleOpen]);

  return (
    <MessageStoreContext.Provider value={value}>
      {message && (
        <Snackbar
          anchorOrigin={
            message.anchorOrigin || { vertical: 'top', horizontal: 'right' }
          }
          autoHideDuration={message.duration}
          data-test="messageContext.snackbar.message"
          onClose={handleClose}
          open={open}
          sx={styles.snack}
        >
          <Alert
            onClose={message.dismissible ? handleClose : undefined}
            severity={message.severity}
            variant="filled"
            {...message.alertProps}
          >
            {typeof message.text === 'string' ? (
              message.text
            ) : (
              <Stack spacing={1}>
                {message.text.map((text, i) => (
                  <span key={i}>{text}</span>
                ))}
              </Stack>
            )}
          </Alert>
        </Snackbar>
      )}
      {children}
    </MessageStoreContext.Provider>
  );
}

export function useMessageStore(): MessageStore {
  return useContextWithError(MessageStoreContext, 'MessageStore');
}
