import { optional, string, text } from '@fmtk/decoders';
import { Button, Dialog, DialogContent, DialogTitle } from '@mui/material';
import { Stack } from '@mui/system';
import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { AddUserDocumentRequest } from '../../../../api/DocumentService/addUserDocument/AddUserDocumentRequest.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { FileTooBigError } from '../../../../api/util/FileTooBigError.js';
import {
  UserDocumentScope,
  UserDocumentSource,
} from '../../../../api/util/UserDocument.js';
import { assertCondition } from '../../../../util/assertCondition.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import {
  ActionButton,
  Body,
  Form,
  FormTextField,
  useMessageStore,
} from '../../index.js';
import { validUrl } from '../../validation/validUrl.js';

export interface UserDocumentItem {
  source: UserDocumentSource;
  name: string;
  size?: number;
  url?: string;
  contentType?: string;
}

export interface CreateUserDocumentModalProps {
  brand: AvailableBrands;
  file?: File | null;
  onClose: () => void;
  reference: string;
  scope: UserDocumentScope;
  source: UserDocumentSource;
}

export function CreateUserDocumentModal({
  brand,
  file,
  onClose,
  reference,
  scope,
  source,
}: CreateUserDocumentModalProps) {
  const { t } = useTranslation();
  const api = useApiClient();
  const { showMessage } = useMessageStore();

  const [uploadDocument, setUploadDocument] = useAsyncState<void>();

  const onUpload = ({
    source,
    name,
    url,
    contentType,
    size,
  }: UserDocumentItem) => {
    setUploadDocument(async () => {
      let docUrl: string;

      try {
        if (source === UserDocumentSource.S3) {
          assertCondition(file, 'File must be defined');

          const urlResponse = await api.document.getUploadDocumentUrl({
            brand,
            contentLength: file.size,
            contentType: file.type,
            fileName: file.name,
            scope,
            reference,
          });

          assertCondition(urlResponse, 'Could not get upload url');

          docUrl = urlResponse.key;

          const uploadResponse = await fetch(urlResponse.url, {
            body: file,
            headers: urlResponse.headers,
            method: 'PUT',
          });
          if (!uploadResponse.ok) {
            throw new Error(
              `unexpected response code ${uploadResponse.status}`,
            );
          }
        } else {
          assertCondition(url, 'expected Link document to have url');
          docUrl = url;
        }

        const userDocument: AddUserDocumentRequest = {
          brand,
          reference,
          scope,
          source,
          name,
          url: docUrl,
          contentType,
          size,
        };

        await api.document.addUserDocument(userDocument);
        onClose();
        return;
      } catch (err) {
        if (FileTooBigError.is(err) && err.details) {
          showMessage({
            severity: 'error',
            dismissible: true,
            text: t(`validation.FileTooBig`, {
              maxLength: err.details!.maxLength / 1024 / 1024,
            }),
          });
          onClose();
        } else {
          console.error(err);
          showMessage({
            severity: 'error',
            dismissible: true,
            text: t(`documents.submitError`),
          });
        }
      }
    });
  };

  const form = useCreateForm(
    {
      name: text,
      url: source === UserDocumentSource.Link ? validUrl : optional(string),
    },
    (values) => {
      const document = {
        source,
        name: values.name,
        size: file?.size,
        url: values.url,
        contentType: file?.type,
        file,
      };

      onUpload(document);
    },
    undefined,
    [source],
  );

  const [, formBind] = form;

  useEffect(() => {
    formBind.reset({
      name: file?.name,
      url: '',
    });
  }, [formBind, file]);

  return (
    <Dialog fullWidth maxWidth="xs" open>
      <DialogTitle>{t('documents.dialogTitle')}</DialogTitle>
      <DialogContent>
        <Form form={form}>
          <Stack spacing={3}>
            <Stack>
              <FormTextField
                autoFocus
                label={t('documents.form.labels.name')}
                name="name"
              />
              {source === UserDocumentSource.Link && (
                <FormTextField
                  label={t('documents.form.labels.url')}
                  name="url"
                />
              )}
            </Stack>
            {!!uploadDocument.error && (
              <Body color="error">{t('error.unknown')}</Body>
            )}
            <Stack direction="row" justifyContent="end" spacing={1}>
              <Button
                color="inherit"
                disabled={uploadDocument.loading}
                onClick={onClose}
                size="small"
              >
                {t('action.cancel')}
              </Button>
              <ActionButton
                busy={uploadDocument.loading}
                data-test="addDocument.button.add"
                size="small"
                type="submit"
                variant="contained"
              >
                {t('documents.form.submit')}
              </ActionButton>
            </Stack>
          </Stack>
        </Form>
      </DialogContent>
    </Dialog>
  );
}
