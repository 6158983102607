import {
  array,
  choose,
  date,
  enumValue,
  maybe,
  number,
  object,
  record,
  string,
  text,
} from '@fmtk/decoders';
import { SyndicationType } from '../../util/SyndicationType.js';

export enum BentleyUsageCategoryType {
  Approved = 'Approved',
}

export type BentleyUsageCategory = SyndicationType | BentleyUsageCategoryType;

export interface BentleyDealerStock {
  vin: string;
  modelYear?: number;
  model: string;
  modelVersion: string;
  bodyStyle?: string;
  primaryColour?: string;
  interiorColour?: string;
  fuelType?: string;
  transmission?: string;
  usageCategory?: BentleyUsageCategory;
  freeTextColour?: string;
  paintType?: string;
  odometer?: number;
  odometerUnit?: string;
  engineOutput?: string;
  engineCapacity?: string;
  firstRegistrationDate?: Date;
  price?: number;
  currency?: string;
  features?: Record<string, string[] | undefined>;
  description?: string;
  pollutantClass?: string;
  co2Class?: string;
  co2ClassDischargedBattery?: string;
  co2ClassWeightedCombined?: string;
  nedcConsumptionUrban?: string;
  nedcConsumptionExtraUrban?: string;
  nedcConsumptionCombined?: string;
  nedcCo2Emission?: string;
  wltpConsumptionLow?: string;
  wltpConsumptionMid?: string;
  wltpConsumptionHigh?: string;
  wltpConsumptionExtraHigh?: string;
  wltpConsumptionCombined?: string;
  wltpEmissionsLow?: string;
  wltpEmissionsMid?: string;
  wltpEmissionsHigh?: string;
  wltpEmissionsExtraHigh?: string;
  wltpEmissionsCombined?: string;
  environmentalClass?: string;
  urlVehicleDetailPage?: string;
  originalImages: string[];
}

export type ListBentleyDealerStockAPIResponse = {
  stock: BentleyDealerStock[];
  dealerStockURL: string;
};

export const decodeBentleyDealerStock = object<BentleyDealerStock>({
  vin: text,
  modelYear: maybe(number),
  model: text,
  modelVersion: string,
  bodyStyle: maybe(text),
  primaryColour: maybe(text),
  interiorColour: maybe(text),
  fuelType: maybe(text),
  transmission: maybe(text),
  usageCategory: maybe(
    choose(enumValue(SyndicationType), enumValue(BentleyUsageCategoryType)),
  ),
  freeTextColour: maybe(text),
  paintType: maybe(text),
  odometer: maybe(number),
  odometerUnit: maybe(text),
  engineOutput: maybe(text),
  engineCapacity: maybe(text),
  firstRegistrationDate: maybe(date),
  price: maybe(number),
  currency: maybe(text),
  features: maybe(record(string, maybe(array(string)))),
  description: maybe(text),
  pollutantClass: maybe(text),
  co2Class: maybe(text),
  co2ClassDischargedBattery: maybe(text),
  co2ClassWeightedCombined: maybe(text),
  nedcConsumptionUrban: maybe(text),
  nedcConsumptionExtraUrban: maybe(text),
  nedcConsumptionCombined: maybe(text),
  nedcCo2Emission: maybe(text),
  wltpConsumptionLow: maybe(text),
  wltpConsumptionMid: maybe(text),
  wltpConsumptionHigh: maybe(text),
  wltpConsumptionExtraHigh: maybe(text),
  wltpConsumptionCombined: maybe(text),
  wltpEmissionsLow: maybe(text),
  wltpEmissionsMid: maybe(text),
  wltpEmissionsHigh: maybe(text),
  wltpEmissionsExtraHigh: maybe(text),
  wltpEmissionsCombined: maybe(text),
  environmentalClass: maybe(text),
  urlVehicleDetailPage: maybe(text),
  originalImages: array(string),
});

export const decodeListBentleyDealerStockAPIResponse =
  object<ListBentleyDealerStockAPIResponse>({
    dealerStockURL: string,
    stock: array(decodeBentleyDealerStock),
  });
