import { ListingStatus } from '../../api/util/Vehicle.js';

export const statusOptions = [
  {
    value: ListingStatus.ForSale,
    text: ListingStatus.ForSale,
  },
  {
    value: ListingStatus.Sold,
    text: ListingStatus.Sold,
  },
  {
    value: ListingStatus.SoldSubjectToFinance,
    text: ListingStatus.SoldSubjectToFinance,
  },
  {
    value: ListingStatus.UnderOffer,
    text: ListingStatus.UnderOffer,
  },
  {
    value: ListingStatus.UnderConversion,
    text: ListingStatus.UnderConversion,
  },
];
