import { array } from '@fmtk/decoders';
import {
  VumoConfiguration,
  decodeVumoConfiguration,
} from '../../../core/VumoService/VumoConfiguration.js';

export type ListBgReplacementConfigsResponse = VumoConfiguration[];

export const decodeListBgReplacementConfigsResponse = array(
  decodeVumoConfiguration,
);
