import { maybe } from '@fmtk/decoders';
import { none } from '../util/none.js';
import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { decodeAddUserDocumentRequest } from './addUserDocument/AddUserDocumentRequest.js';
import { decodeDeleteUserDocumentRequest } from './deleteUserDocument/deleteUserDocumentRequest.js';
import { decodeGetDownloadDocumentUrlRequest } from './getDownloadDocumentUrl/GetDownloadDocumentUrlRequest.js';
import { decodeGetDownloadDocumentUrlResponse } from './getDownloadDocumentUrl/GetDownloadDocumentUrlResponse.js';
import { decodeGetDocumentUploadUrlRequest } from './getUploadDocumentUrl/GetDocumentUploadUrlRequest.js';
import { decodeGetDocumentUploadUrlResponse } from './getUploadDocumentUrl/GetDocumentUploadUrlResponse.js';
import { decodeGetUserDocumentRequest } from './getUserDocument/getUserDocumentRequest.js';
import { decodeListUserDocumentsByReferenceRequest } from './listUserDocumentsByReference/ListUserDocumentsByReferenceRequest.js';
import { decodeListUserDocumentsByReferenceResponse } from './listUserDocumentsByReference/ListUserDocumentsByReferenceResponse.js';

export const DocumentServiceDef = service({
  addUserDocument: method(decodeAddUserDocumentRequest, none, [
    UserPermissions.Basic,
  ]),
  deleteUserDocument: method(decodeDeleteUserDocumentRequest, none, [
    UserPermissions.Basic,
  ]),
  getUploadDocumentUrl: method(
    decodeGetDocumentUploadUrlRequest,
    maybe(decodeGetDocumentUploadUrlResponse),
    [UserPermissions.Basic],
  ),
  getDownloadDocumentUrl: method(
    decodeGetDownloadDocumentUrlRequest,
    decodeGetDownloadDocumentUrlResponse,
    [UserPermissions.Basic],
  ),
  getUserDocument: method(
    decodeGetUserDocumentRequest,
    maybe(decodeGetDownloadDocumentUrlResponse),
    [UserPermissions.Basic],
  ),
  listUserDocumentsByReference: method(
    decodeListUserDocumentsByReferenceRequest,
    decodeListUserDocumentsByReferenceResponse,
    [UserPermissions.Basic],
  ),
});

export type DocumentServiceDef = typeof DocumentServiceDef;
