import { object, text } from '@fmtk/decoders';

export interface MarkNotificationAsReadRequest {
  notificationId: string;
}

export const decodeMarkNotificationAsReadRequest =
  object<MarkNotificationAsReadRequest>({
    notificationId: text,
  });
