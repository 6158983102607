import { t } from 'i18next';

export const chartOptions = {
  responsive: true,
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.region'),
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.noOfVehicles'),
      },
    },
  },
};
