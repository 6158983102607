import { array, enumValue, object, optional, text } from '@fmtk/decoders';
import { AvailableBrands, Policy } from '../../util/index.js';

export type BrandPolicy = Pick<
  Policy,
  | 'Dealer'
  | 'API'
  | 'DealerReporting'
  | 'GlobalReporting'
  | 'Admin'
  | 'VehicleEditor'
  | 'VehicleViewer'
  | 'RemarketingAdmin'
  | 'RemarketingAdminBrands'
  | 'RemarketingReadOnlyBrands'
  | 'RemarketingDealer'
>;

export type UpdateUserBrandPolicyRequest = {
  brand: AvailableBrands;
  policy: BrandPolicy;
  principalId: string;
};

export const decodeBrandPolicy = object<BrandPolicy>({
  Dealer: optional(array(text)),
  Admin: optional(array(text)),
  API: optional(array(text)),
  DealerReporting: optional(array(text)),
  GlobalReporting: optional(array(text)),
  VehicleEditor: optional(array(text)),
  VehicleViewer: optional(array(text)),
  RemarketingAdmin: optional(array(text)),
  RemarketingAdminBrands: optional(array(text)),
  RemarketingReadOnlyBrands: optional(array(text)),
  RemarketingDealer: optional(array(text)),
});

export const decodeUpdateUserBrandPolicyRequest =
  object<UpdateUserBrandPolicyRequest>({
    brand: enumValue(AvailableBrands),
    policy: decodeBrandPolicy,
    principalId: text,
  });
