import { enumValue, integer, object, optional, string } from '@fmtk/decoders';
import { AvailableBrands, SyndicationType } from '../../util/index.js';

export interface DownloadVehicleMovementCsvRequest {
  brand: AvailableBrands;
  region?: string;
  dealer?: string;
  monthInterval?: number;
  syndicationType?: SyndicationType;
}

export const decodeDownloadVehicleMovementCsvRequest =
  object<DownloadVehicleMovementCsvRequest>({
    brand: enumValue(AvailableBrands),
    region: optional(string),
    dealer: optional(string),
    monthInterval: optional(integer),
    syndicationType: optional(enumValue(SyndicationType)),
  });
