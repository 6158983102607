import { Stack, StackProps } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import { CellLabel, TextInput, useForm } from '../../../../common-ui/index.js';

export interface CommentsProps extends StackProps {
  title?: string;
  readonly?: boolean;
  name: string;
  label: string;
  rows?: number;
  tabIndex?: number;
  maxLength?: number;
}

const Comments: FunctionComponent<CommentsProps> = ({
  title,
  readonly,
  name,
  label,
  rows,
  tabIndex,
  maxLength,
  ...stackProps
}) => {
  const [formState, formBind, { translations }] = useForm();
  const { t } = useTranslation();

  return (
    <SectionItem title={title} {...stackProps}>
      <Stack flex={1}>
        <CellLabel
          htmlFor={name}
          label={t(`${translations}.labels.${label}`, {
            defaultValue: label,
          })}
        />
        <TextInput
          id={name}
          maxLength={maxLength}
          multiline
          onValueChange={(value) => formBind.setValue(name, value)}
          readOnly={readonly}
          rows={rows || 6}
          tabIndex={tabIndex}
          value={formState.values[name]}
        />
      </Stack>
    </SectionItem>
  );
};

export default Comments;
