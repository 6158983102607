import { Add, PersonAdd, Search } from '@mui/icons-material';
import { Box, Button, IconButton, Stack, TextField } from '@mui/material';
import { t } from 'i18next';
import { ChangeEvent, FunctionComponent } from 'react';
import { useMobile } from '../../hooks/useMobile.js';

interface SearchToolBarProps {
  inputValue: string;
  onInputChange: (event: ChangeEvent<HTMLInputElement>) => void;
  onButtonClick?: (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>,
  ) => void;
}

const SearchToolBar: FunctionComponent<SearchToolBarProps> = ({
  inputValue,
  onInputChange,
  onButtonClick,
}) => {
  const isMobile = useMobile('sm');
  return (
    <Stack
      alignItems="center"
      direction="row"
      justifyContent="space-between"
      mt={2}
      p={2}
      spacing={1}
    >
      <Box width={{ xs: '100%', sm: '60%' }}>
        <TextField
          InputProps={{
            startAdornment: <Search color="disabled" sx={{ mr: 1 }} />,
            autoComplete: 'off',
          }}
          fullWidth
          id="search-input"
          onChange={onInputChange}
          placeholder={t('pages.users.searchPlaceholder')}
          size="small"
          value={inputValue}
        />
      </Box>
      {onButtonClick && (
        <Box justifyContent="flex-end">
          {isMobile ? (
            <IconButton color="primary" onClick={onButtonClick} size="large">
              <PersonAdd />
            </IconButton>
          ) : (
            <Button
              onClick={onButtonClick}
              startIcon={<Add />}
              variant="contained"
            >
              {t('pages.users.new')}
            </Button>
          )}
        </Box>
      )}
    </Stack>
  );
};

export default SearchToolBar;
