import { ArrowUpward } from '@mui/icons-material';
import {
  Backdrop,
  ClickAwayListener,
  IconButton,
  Link,
  MenuItem,
  Paper,
  Popper,
  Stack,
} from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleListingSort } from '../../../../api/VehicleListingService/common.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { sx } from '../../../util/sx.js';

export interface Sort {
  sort: VehicleListingSort;
  order: 'asc' | 'desc';
}

const sortTranslations: Record<VehicleListingSort, string> = {
  [VehicleListingSort.price]: 'price',
  [VehicleListingSort.createdDate]: 'added',
  [VehicleListingSort.timestamp]: 'archived',
};

const styles = sx({
  container: {
    p: 2,
    mr: 6,
    ml: 4,
    minWidth: 300,
    maxWidth: '100%',
  },
  modal: (theme) => ({
    zIndex: theme.zIndex.appBar + 1,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
      left: '50%',
      transform: 'translateX(-50%)',
    },
  }),
  menuItem: {
    color: 'text.secondary',
    fontWeight: 'fontWeightMedium',
    borderRadius: 0.5,
    '&.Mui-selected': {
      bgcolor: 'grey.100',
      color: 'primary.main',
    },
  },
  backdrop: {
    zIndex: (theme) => theme.zIndex.appBar,
    backgroundColor: '#D9D9D980',
    opacity: 0.5,
  },
});

export interface SortPanelProps {
  value: Sort;
  onChange?: (sortOptions: Sort) => void;
  archiveMode?: boolean;
}

const SortPanel: FunctionComponent<SortPanelProps> = ({
  value,
  onChange,
  archiveMode,
}) => {
  const [sortOption, setSortOptions] = useState(value);
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const onClickAway = () => {
    setAnchorEl(null);
  };

  const onClick = (e: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(anchorEl ? null : e.currentTarget);
  };

  const handleChangeOrder = useCallback(() => {
    setSortOptions((prev) => {
      const newState: Sort = {
        ...prev,
        order: prev.order === 'asc' ? 'desc' : 'asc',
      };
      onChange && onChange(newState);
      return newState;
    });
    onClickAway();
  }, [onChange]);

  const handleChangeSort = useCallback(
    (val: VehicleListingSort) => {
      setSortOptions((prev) => {
        const newState: Sort = {
          ...prev,
          sort: val,
        };
        onChange && onChange(newState);
        return newState;
      });
      onClickAway();
    },
    [onChange],
  );

  useEffect(() => {
    setSortOptions(value);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [value]);

  return (
    <ClickAwayListener onClickAway={onClickAway}>
      <Stack direction="row" spacing={1}>
        <Stack direction="row" flexWrap="nowrap">
          <IconButton
            color="inherit"
            disableRipple
            onClick={handleChangeOrder}
            size="small"
            sx={{
              transform: `rotate(${sortOption.order === 'asc' ? 0 : 180}deg)`,
              transition: 'transform 300ms',
            }}
          >
            <ArrowUpward fontSize="small" />
          </IconButton>
          <Link
            component="button"
            fontWeight="fontWeightMedium"
            onClick={onClick}
            underline="always"
            variant="subtitle2"
          >
            {`${t('pages.remarketingPage.sort.title')}: ${t(
              sortTranslations[sortOption.sort],
            )}`}
          </Link>
        </Stack>
        <Popper anchorEl={anchorEl} open={!!anchorEl} sx={styles.modal}>
          <Paper elevation={3} sx={styles.container}>
            {Object.values(VehicleListingSort)
              .filter((x) => archiveMode || x !== VehicleListingSort.timestamp)
              .map((val) => {
                return (
                  <MenuItem
                    key={val}
                    onClick={() => {
                      handleChangeSort(val);
                    }}
                    selected={val === sortOption.sort}
                    sx={styles.menuItem}
                    value={val}
                  >
                    {t(sortTranslations[val])}
                  </MenuItem>
                );
              })}
          </Paper>
        </Popper>
        {isMobile && (
          <Backdrop
            onClick={onClickAway}
            open={!!anchorEl}
            sx={styles.backdrop}
          />
        )}
      </Stack>
    </ClickAwayListener>
  );
};

export default SortPanel;
