import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { SaveVehicleRuleAstonMartin } from '../../../../util/SaveVehicleRule.js';
import { AddVehicleFormState } from '../../AddVehicle/Steps/DetailsStep.js';
import { EditVehicleFormState } from '../VehicleDetails.js';

/**
 * We should not allow vehicles that are older than 10 years old to be saved as Certified Pre-Owned.
 * @param {{
 *   syndicationType: string,
 *   registrationDate: Date,
 * }} vehicleData - The vehicle data to be validated.
 * @param {SaveVehicleRuleAstonMartin} config - Configuration rules for validating Aston Martin vehicles.
 * @returns {boolean} - True if the vehicle can be saved, false otherwise.
 */
export function validateAstonMartinRule(
  { syndicationType, ...rest }: AddVehicleFormState | EditVehicleFormState,
  config: SaveVehicleRuleAstonMartin,
): boolean {
  const { maxYearRegistration, syndicationTypeToCheck } = config;

  const registrationDate =
    'vehicle' in rest ? rest.vehicle?.registrationDate : rest.registrationDate;

  if (
    syndicationTypeToCheck.includes(syndicationType as SyndicationType) &&
    registrationDate
  ) {
    const currentDate = new Date();

    const yearsDifference =
      currentDate.getFullYear() - registrationDate.getFullYear();

    if (yearsDifference > maxYearRegistration) {
      return false;
    }
  }

  return true;
}
