import { chain, object } from '@fmtk/decoders';
import { maxNumber } from '../../util/decoders/maxNumber.js';
import { minNumber } from '../../util/decoders/minNumber.js';

export interface GeoLocation {
  lon: number;
  lat: number;
}

export const decodeGeoLocation = object<GeoLocation>({
  lon: chain(minNumber({ min: -180 }), maxNumber({ max: 180 })),
  lat: chain(minNumber({ min: -90 }), maxNumber({ max: 90 })),
});
