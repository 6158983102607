import { QueryKey, useQuery, UseQueryResult } from '@tanstack/react-query';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { GetArchiveVehicleListingProcessRequest } from '../../../../api/VehicleListingService/getArchiveVehicleListingProcess/GetArchiveVehicleListingProcessRequest.js';
import { GetArchiveVehicleListingProcessResponse } from '../../../../api/VehicleListingService/getArchiveVehicleListingProcess/GetArchiveVehicleListingProcessResponse.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useDealer } from '../../../hooks/useDealer.js';
import { QueryKeys } from './QueryKeys.js';

export const makeGetArchiveVehicleListingProcess = (
  req: GetArchiveVehicleListingProcessRequest,
): QueryKey => [QueryKeys.ArchiveVehicleListingProcess, req];

export interface UseGetArchiveVehicleListingProcessInput {
  vehicleListingId: string;
  enabled?: boolean;
}

export const useGetArchiveVehicleListingProcess = ({
  vehicleListingId,
  enabled,
}: UseGetArchiveVehicleListingProcessInput): UseQueryResult<GetArchiveVehicleListingProcessResponse | null> => {
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const { currentDealer } = useDealer();

  return useQuery({
    queryKey: makeGetArchiveVehicleListingProcess({
      brand: currentBrand as AvailableBrands,
      dealerId: currentDealer?.id as number,
      vehicleListingId,
    }),
    queryFn: async () => {
      const process = await api.vehicleListing.getArchiveVehicleListingProcess({
        brand: currentBrand as AvailableBrands,
        dealerId: currentDealer?.id as number,
        vehicleListingId,
      });
      return process || null;
    },
    enabled: enabled && !!currentBrand && !!currentDealer?.id,
  });
};
