import { enumValue, object, optional, text } from '@fmtk/decoders';

export enum AstonMartinAPILanguages {
  EnglishGreatBritain = 'en_gb',
  EnglishUnitedStates = 'en_us',
  GermanGermany = 'de_de',
  SimplifiedChinese = 'zh_cn',
  FrenchFrance = 'fr_fr',
  JapaneseJapan = 'ja_jp',
}

export interface ListAstonMartinDealerStockAPIRequest {
  dealerId: string;
  language?: AstonMartinAPILanguages;
}

export const decodeListAstonMartinDealerStockAPIRequest =
  object<ListAstonMartinDealerStockAPIRequest>({
    dealerId: text,
    language: optional(enumValue(AstonMartinAPILanguages)),
  });
