import { enumValue, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface UserGetRemarketingActionsRequest {
  id: string;
  remarketingVehicleId: string;
  brand: AvailableBrands;
}

export const decodeUserGetRemarketingActionsRequest =
  object<UserGetRemarketingActionsRequest>({
    id: text,
    remarketingVehicleId: text,
    brand: enumValue(AvailableBrands),
  });
