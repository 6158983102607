import { array, number, object, string } from '@fmtk/decoders';

export interface RegionStockStats {
  amountOfDealersInZone: number;
  amountOfDealersWithCars: number;
  amountOfDealersWithLiveCars: number;
  amountOfVehicles: number;
  amountOfVehiclesCertified: number;
  amountOfVehiclesCertifiedLive: number;
  amountOfVehiclesFilterNotMet: number;
  amountOfVehiclesHidden: number;
  amountOfVehiclesLive: number;
  amountOfVehiclesMore90Days: number;
  amountOfVehiclesNew: number;
  amountOfVehiclesNewLive: number;
  averageDaysInStock: number;
  averagePhotosPerLiveListing: number;
  regionName: string;
  totalPhotosOnLiveListings: number;
}

export type ListRegionStockStatsResponse = RegionStockStats[];

export const decodeListRegionStockStatsResponse = array(
  object<RegionStockStats>({
    amountOfDealersInZone: number,
    amountOfDealersWithCars: number,
    amountOfDealersWithLiveCars: number,
    amountOfVehicles: number,
    amountOfVehiclesCertified: number,
    amountOfVehiclesCertifiedLive: number,
    amountOfVehiclesFilterNotMet: number,
    amountOfVehiclesHidden: number,
    amountOfVehiclesLive: number,
    amountOfVehiclesMore90Days: number,
    amountOfVehiclesNew: number,
    amountOfVehiclesNewLive: number,
    averageDaysInStock: number,
    averagePhotosPerLiveListing: number,
    regionName: string,
    totalPhotosOnLiveListings: number,
  }),
);
