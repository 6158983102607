import {
  Cached,
  Check,
  CheckBoxOutlineBlank,
  Delete,
  Download,
  ErrorRounded,
  Wallpaper,
} from '@mui/icons-material';
import {
  Box,
  Checkbox,
  CircularProgress,
  IconButton,
  Stack,
  Tooltip,
  Typography,
  styled,
} from '@mui/material';
import { UploadApiResponse } from 'cloudinary';
import {
  ChangeEvent,
  FunctionComponent,
  ReactNode,
  useCallback,
  useEffect,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  ImageTags,
  MediaSeo,
  VehicleMediaStatus,
} from '../../../../api/util/VehicleMedia.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { sx } from '../../../util/index.js';
import { useMessageStore } from '../../context/MessageContext.js';
import OpenFullIcon from '../OpenFullIcon.js';
import UploadWidget from '../UploadWidget/UploadWidget.js';
import LabelStatus from './LableStatus.js';

const styles = sx({
  checkbox: {
    color: '#EDEFF1',
    bgcolor: '#2065D1',
    fontSize: 18,
    border: '2px solid #BDC7D3',
    borderRadius: 0.5,
  },
  mainContainer: {
    padding: 1,
    bgcolor: '#EDEFF1',
  },
  statusContainer: {
    position: 'absolute',
    margin: '5px; 6px;',
    display: 'flex',
    gap: 1,
    zIndex: 1,
  },
  outlineCheckbox: {
    color: '#BDC7D3',
    fontSize: 18,
  },
  fullIcon: {
    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 0.5,
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
});

const Image = styled('img')<{ isLoading?: boolean }>`
  object-fit: cover;
  width: 100%;
  aspect-ratio: 4/3;
  cursor: pointer;
  ${({ isLoading }) => (isLoading ? 'filter: blur(4px);' : undefined)}
`;

interface VehicleImageProps {
  error?: boolean;
  footerLabel?: string | ReactNode;
  bgReplacement?: boolean;
  hideLabelStatus?: boolean;
  hideUploadButton?: boolean;
  isRequired?: boolean;
  label?: string | ReactNode;
  loading?: boolean;
  onCheckBoxChange?: (
    event: ChangeEvent<HTMLInputElement>,
    checked: boolean,
  ) => void;
  onFileChange?: (cloudinaryApiResponse: UploadApiResponse) => void;
  onDownloadButtonClick?: () => void;
  onFullViewButtonClick?: () => void;
  onImageClick?: () => void;
  onRemoveClick?: () => void;
  selected?: boolean;
  seo: MediaSeo;
  src?: string;
  status?: VehicleMediaStatus;
  tags?: ImageTags[];
}

const VehicleImage: FunctionComponent<VehicleImageProps> = ({
  error,
  footerLabel,
  hideLabelStatus,
  hideUploadButton,
  isRequired,
  label,
  loading,
  onCheckBoxChange,
  onFileChange,
  onDownloadButtonClick,
  onFullViewButtonClick,
  onImageClick,
  onRemoveClick,
  selected,
  seo,
  src,
  status,
  bgReplacement,
}) => {
  const { t } = useTranslation();
  const [isHover, setIsHover] = useState<boolean>(false);
  const isMobile = useMobile();
  const [isLoading, setIsLoading] = useState<boolean | undefined>(loading);
  const { showMessage } = useMessageStore();

  const handleImageClick = useCallback(() => {
    onImageClick && onImageClick();
  }, [onImageClick]);

  useEffect(() => {
    setIsLoading(status === VehicleMediaStatus.Processing || loading);
  }, [status, loading]);

  return (
    <Box
      component="div"
      onMouseEnter={() => setIsHover(true)}
      onMouseLeave={() => setIsHover(false)}
      sx={styles.mainContainer}
    >
      <Stack direction="row" spacing={0.5}>
        {error && isHover && <ErrorRounded color="error" />}
        {label && (
          <Box maxWidth={error ? 150 : 180} pb={1}>
            {typeof label === 'string' ? (
              <Tooltip arrow placement="top" title={label}>
                <Typography
                  color="#0C264E"
                  noWrap
                  textOverflow="ellipsis"
                  variant="subtitle2"
                >
                  {label}
                </Typography>
              </Tooltip>
            ) : (
              label
            )}
          </Box>
        )}
      </Stack>

      <Box position="relative" width={180}>
        <Box sx={styles.statusContainer}>
          <Stack alignItems="center" direction="row" spacing={1}>
            {isLoading && <CircularProgress size={20} />}
            {onCheckBoxChange && !isLoading && (
              <Checkbox
                checked={selected}
                checkedIcon={<Check sx={styles.checkbox} />}
                disableRipple
                icon={<CheckBoxOutlineBlank sx={styles.outlineCheckbox} />}
                onChange={onCheckBoxChange}
                sx={{
                  p: 0,
                }}
              />
            )}
            {status && !hideLabelStatus && (
              <LabelStatus isRequired={isRequired} status={status}>
                {t(status)}
              </LabelStatus>
            )}
          </Stack>
        </Box>
        <Box
          bottom={10}
          display="flex"
          justifyContent="space-between"
          marginLeft={1}
          position="absolute"
          width="90%"
        >
          {(isHover || isMobile) &&
            !isLoading &&
            onFullViewButtonClick &&
            status && (
              <Box>
                <IconButton
                  onClick={onFullViewButtonClick}
                  onMouseDown={(e) => {
                    e.stopPropagation();
                  }}
                  size="small"
                  sx={styles.fullIcon}
                >
                  <OpenFullIcon fontSize="small" />
                </IconButton>
              </Box>
            )}
          {(isHover || isMobile) && !isLoading && onDownloadButtonClick && (
            <Box>
              <IconButton
                onClick={onDownloadButtonClick}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                size="small"
                sx={styles.fullIcon}
              >
                <Download fontSize="small" />
              </IconButton>
            </Box>
          )}
          {(isHover || isMobile) && !isLoading && onRemoveClick && status && (
            <Box>
              <IconButton
                onClick={onRemoveClick}
                onMouseDown={(e) => {
                  e.stopPropagation();
                }}
                size="small"
                sx={styles.fullIcon}
              >
                <Delete fontSize="small" />
              </IconButton>
            </Box>
          )}
          {!isLoading && !hideUploadButton && !!onFileChange && (
            <Box visibility={isHover || isMobile ? 'visible' : 'hidden'}>
              <UploadWidget
                onError={(error) => {
                  showMessage({
                    text: error.message,
                    severity: 'error',
                  });
                }}
                onSuccess={onFileChange}
                seo={seo}
                size="small"
                tags={[ImageTags.Draft]}
              />
            </Box>
          )}
        </Box>
        <Image isLoading={isLoading} onClick={handleImageClick} src={src} />
      </Box>
      {footerLabel && (
        <Stack direction="row" spacing={0.5} width={180}>
          <Cached htmlColor="#44546F" />
          {typeof footerLabel === 'string' ? (
            <Typography color="grey.600" variant="caption">
              {footerLabel}
            </Typography>
          ) : (
            footerLabel
          )}
        </Stack>
      )}
      {bgReplacement && (
        <Stack direction="row" spacing={0.5} width={180}>
          <Wallpaper htmlColor="#44546F" />
          <Typography alignSelf="center" color="grey.600" variant="caption">
            {t('bgReplacementText')}
          </Typography>
        </Stack>
      )}
    </Box>
  );
};

export default VehicleImage;
