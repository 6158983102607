import { GridColDef, GridValueFormatterParams } from '@mui/x-data-grid';
import { formatNumber } from '../../../util/numbers.js';

export const columns: GridColDef[] = [
  { field: 'model', headerName: 'Model', flex: 1 },
  { field: 'modelYear', headerName: 'Year' },
  {
    field: 'currency',
    headerName: 'Currency',
    type: 'number',
  },
  {
    field: 'price',
    headerName: 'Price',
    type: 'number',
    valueFormatter: (param: GridValueFormatterParams<number>) => {
      return formatNumber(param.value);
    },
  },

  {
    field: 'count',
    headerName: 'Count',
    type: 'number',
  },
];
