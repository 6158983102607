import { ArrowBack } from '@mui/icons-material';
import {
  Button,
  CircularProgress,
  Divider,
  styled,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useAtomValue } from 'jotai';
import { DateTime } from 'luxon';
import { FunctionComponent, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { RemarketingType } from '../../../../api/util/RemarketingVehicle.js';
import {
  BidStatus,
  UserRemarketingVehicle,
} from '../../../../api/util/UserRemarketingVehicle.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import { getRelevantMediaFromVehicleMedia } from '../../../../util/vehicleMediaHelper.js';
import { HighlightWrapper, Label } from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import useCarouselMedia from '../../../hooks/useCarouselMedia.js';
import useCountdown from '../../../hooks/useCountDown.js';
import { remarketingStatusColours } from '../../../util/colors.js';
import { formatDateTime } from '../../../util/dates.js';
import { formatPrice } from '../../../util/formatters.js';
import { remarketingFiltersAtom } from '../../state/atoms.js';
import ImageCarousel from '../VehicleMedia/ImageCarousel.js';

interface UserRemarketingVehicleHeaderProps {
  remarketingVehicle: UserRemarketingVehicle;
  isLoading?: boolean;
}

const Img = styled('img')(({ theme }) => ({
  aspectRatio: '4/3',
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: 'contain',
  [theme.breakpoints.up('sm')]: {
    minWidth: 138,
    width: 240,
  },
}));

const UserRemarketingVehicleHeader: FunctionComponent<
  UserRemarketingVehicleHeaderProps
> = ({ remarketingVehicle, isLoading }) => {
  const { t } = useTranslation();
  const { brandConfig } = useBrand();
  const { i18n } = useTranslation();
  const [showCarousel, setShowCarousel] = useState(false);
  const navigate = useNavigate();
  const searchParams = useAtomValue(remarketingFiltersAtom);
  const { getFromVehicleMedia } = useCarouselMedia();
  const closingDate =
    'closingDate' in remarketingVehicle && remarketingVehicle.closingDate
      ? DateTime.fromMillis(remarketingVehicle.closingDate, {
          locale: i18n.language,
        })
      : undefined;

  const { inThePast, timeLeft } =
    useCountdown(closingDate, {
      stopOnZero: true,
      toRelativeOptions: {
        locale: i18n.language,
        unit: ['years', 'months', 'days', 'hours', 'minutes'],
      },
    }) || {};

  const {
    image,
    title,
    leadingBid,
    price,
    reservePriceStatus,
    maxBid,
    carouselMedia,
  } = useMemo(() => {
    const { vehicleMedia } = remarketingVehicle;
    const media = getRelevantMediaFromVehicleMedia({
      vehicleMedia: vehicleMedia || [],
      options: {
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved, VehicleMediaStatus.Pending],
        transformation: 't_vehicle_thumbnail',
      },
    });

    const maxBid =
      'bidStatus' in remarketingVehicle &&
      remarketingVehicle.bidStatus === BidStatus.Leading &&
      !!remarketingVehicle.currentBid
        ? formatPrice(
            remarketingVehicle.currentBid.maxBid,
            remarketingVehicle.currency,
          )
        : undefined;

    const carouselMedia = getFromVehicleMedia(vehicleMedia);

    return {
      image: media?.mediaUrl || brandConfig?.noVehicleImage,
      title: `${
        remarketingVehicle.vehicle.modelYear ||
        remarketingVehicle.vehicle.registrationYear
      } ${remarketingVehicle.vehicle.model.name || ''}`,
      maxBid,
      leadingBid:
        'leadingBid' in remarketingVehicle && remarketingVehicle.leadingBid
          ? formatPrice(
              remarketingVehicle.leadingBid,
              remarketingVehicle.currency,
            )
          : undefined,
      reservePriceStatus:
        remarketingVehicle.type === RemarketingType.AUCTION
          ? remarketingVehicle.reservePriceStatus
          : undefined,
      price:
        remarketingVehicle.type === RemarketingType.AUCTION
          ? `${t(
              'pages.addRemarketingVehicle.details.form.labels.startPrice',
            )} ${formatPrice(
              remarketingVehicle.startPrice,
              remarketingVehicle.currency,
            )}`
          : remarketingVehicle.type === RemarketingType.FIXED_PRICE
          ? `${t('price')} ${formatPrice(
              remarketingVehicle.price,
              remarketingVehicle.currency,
            )}`
          : undefined,
      carouselMedia,
    };
  }, [brandConfig?.noVehicleImage, remarketingVehicle, t, getFromVehicleMedia]);

  return (
    <Stack>
      <div>
        <Button
          onClick={() => {
            navigate(
              {
                pathname: '../remarketing',
                search: searchParams.searchParams?.toString(),
              },
              {
                state: { remarketingId: remarketingVehicle.id },
              },
            );
          }}
          size="small"
          startIcon={<ArrowBack />}
          variant="text"
        >
          {t('back')}
        </Button>
      </div>
      <Stack
        direction={{
          xs: 'column-reverse',
          sm: 'row',
        }}
        justifyContent="space-between"
        spacing={2}
      >
        <Stack justifyContent="space-between" spacing={2}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography variant="title">{title}</Typography>
            <Label
              bgcolor={
                remarketingStatusColours[remarketingVehicle.status].background
              }
            >
              <Typography
                color={
                  remarketingStatusColours[remarketingVehicle.status].color
                }
                fontWeight="fontWeightBold"
                textTransform="uppercase"
                variant="caption"
              >
                {t(`remarketingVehicleStatus.${remarketingVehicle.status}`)}
              </Typography>
            </Label>
          </Stack>
          <Stack>
            <Typography variant="subtitle3">{price}</Typography>
            {maxBid && (
              <HighlightWrapper highlightChanges value={maxBid}>
                <Stack alignItems="center" direction="row" spacing={1}>
                  <Typography
                    color="grey.800"
                    fontWeight="fontWeightBold"
                    variant="subtitle3"
                  >
                    {t('pages.remarketingAdmin.maxBid')}
                  </Typography>
                  <Typography
                    color="grey.800"
                    fontWeight="fontWeightBold"
                    variant="subtitle3"
                  >
                    {maxBid}
                  </Typography>
                  {isLoading && <CircularProgress size={16} />}
                </Stack>
              </HighlightWrapper>
            )}
            <HighlightWrapper
              highlightChanges
              value={`${leadingBid}-${reservePriceStatus}`}
            >
              <Stack
                direction={{
                  xs: 'column',
                  md: 'row',
                }}
                divider={
                  <Divider
                    color="#BDC7D3"
                    flexItem
                    sx={{
                      width: 2,
                    }}
                  />
                }
                spacing={{ md: 1 }}
              >
                {leadingBid && (
                  <>
                    <Typography
                      color="grey.800"
                      fontWeight="fontWeightBold"
                      variant="subtitle3"
                    >
                      {t('pages.remarketingAdmin.leadingBid')}
                    </Typography>
                    <Typography
                      color="grey.800"
                      fontWeight="fontWeightBold"
                      variant="subtitle3"
                    >
                      {leadingBid}
                    </Typography>
                  </>
                )}
                {reservePriceStatus && (
                  <Typography
                    color="grey.800"
                    fontWeight="fontWeightBold"
                    variant="subtitle3"
                  >
                    {t(`reservePriceStatus.${reservePriceStatus}`)}
                  </Typography>
                )}
              </Stack>
            </HighlightWrapper>
          </Stack>
          <Stack
            direction="row"
            divider={
              <Divider
                color="#BDC7D3"
                flexItem
                orientation="vertical"
                sx={{
                  width: 2,
                }}
              />
            }
            spacing={1}
          >
            {timeLeft && closingDate && (
              <>
                <Typography
                  color="grey.600"
                  fontWeight="fontWeightMedium"
                  variant="body2"
                >
                  {inThePast ? t('endDate') : t('timeLeft')}
                </Typography>
                <Typography
                  color={inThePast ? 'grey.800' : 'error.dark'}
                  fontWeight="fontWeightMedium"
                  variant="body2"
                >
                  {inThePast
                    ? formatDateTime(closingDate.toJSDate())
                    : `${timeLeft} | ${formatDateTime(closingDate.toJSDate())}`}
                </Typography>
              </>
            )}
          </Stack>
        </Stack>
        <Img
          onClick={() => setShowCarousel(true)}
          src={image}
          sx={{
            cursor: carouselMedia.length ? 'pointer' : 'default',
          }}
        />
        {!!carouselMedia.length && (
          <ImageCarousel
            media={carouselMedia}
            onClose={() => setShowCarousel(false)}
            open={showCarousel}
          />
        )}
      </Stack>
    </Stack>
  );
};

export default UserRemarketingVehicleHeader;
