import { StyleSheet, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import Logo from './Logo.js';

const styles = StyleSheet.create({
  logo: {
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HeaderLogo: FunctionComponent = () => {
  return (
    <View style={styles.logo}>
      <Logo />
    </View>
  );
};

export default HeaderLogo;
