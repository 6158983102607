import {
  array,
  boolean,
  number,
  object,
  optional,
  string,
} from '@fmtk/decoders';

export interface GetKioskVehicleRequest {
  brandId: number;
  dealerId?: string;
  language?: string;
  vin?: string;
  regionsId?: number[];
  onlyCertifiedVehicles?: boolean;
  onlyForSaleVehicles?: boolean;
  onlyLiveVehicles?: boolean;
  index?: number;
}

export const decodeGetKioskVehicleRequest = object<GetKioskVehicleRequest>({
  brandId: number,
  dealerId: optional(string),
  language: optional(string),
  vin: optional(string),
  regionsId: optional(array(number)),
  onlyCertifiedVehicles: optional(boolean),
  onlyForSaleVehicles: optional(boolean),
  onlyLiveVehicles: optional(boolean),
  index: optional(number),
});
