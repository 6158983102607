import { enumValue, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetInventoryProcessRequest {
  brand: AvailableBrands;
  userId: string;
}

export const decodeGetInventoryProcessRequest =
  object<GetInventoryProcessRequest>({
    brand: enumValue(AvailableBrands),
    userId: text,
  });
