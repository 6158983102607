import { CheckCircleRounded, ErrorRounded } from '@mui/icons-material';
import { Box, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import LabelStatus from '../../../common-ui/components/VehicleImage/LableStatus.js';
import { useMobile } from '../../../hooks/useMobile.js';

interface AccordionHeaderProps {
  title: string;
  isRequired?: boolean;
  isComplete?: boolean;
  hasError?: boolean;
  loading?: boolean;
}

interface IconStatusProps {
  completed?: boolean;
  error?: boolean;
}

const IconStatus = ({ completed, error }: IconStatusProps): JSX.Element => {
  return error ? (
    <ErrorRounded color="error" />
  ) : completed ? (
    <CheckCircleRounded color="success" />
  ) : (
    <Box
      border={2}
      borderColor="#E7EAEE"
      borderRadius="50%"
      height={21}
      width={21}
    />
  );
};

const AccordionHeader: FunctionComponent<AccordionHeaderProps> = ({
  title,
  isRequired,
  isComplete,
  hasError,
  loading,
}: AccordionHeaderProps) => {
  const { t } = useTranslation();
  const isMobile = useMobile();

  return (
    <Stack direction="row" py={1} spacing={1.5} alignItems="center">
      <IconStatus completed={isComplete} error={hasError} />
      <Typography
        color="text.secondary"
        sx={{ fontSize: isMobile ? '12px' : 'inherit' }}
        variant="subtitle1"
      >
        {title}
      </Typography>
      {isRequired && (
        <LabelStatus isRequired loading={loading}>
          {t('required')}
        </LabelStatus>
      )}
    </Stack>
  );
};

export default AccordionHeader;
