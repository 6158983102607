import { boolean, maybe, object, string } from '@fmtk/decoders';

export type DeleteVehicleListingRequest = {
  id: string;
  options?: { async?: boolean };
};

export const decodeDeleteVehicleListingRequest =
  object<DeleteVehicleListingRequest>({
    id: string,
    options: maybe(
      object({
        async: maybe(boolean),
      }),
    ),
  });
