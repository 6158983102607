import { Alert, AlertColor } from '@mui/material';
import { FunctionComponent, PropsWithChildren } from 'react';

export interface EmptyStateProps extends PropsWithChildren {
  severity?: AlertColor;
}

export const EmptyState: FunctionComponent<EmptyStateProps> = ({
  severity = 'info',
  children,
}): JSX.Element => {
  return <Alert severity={severity}>{children || 'No results found'}</Alert>;
};

export default EmptyState;
