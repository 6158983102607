import { Stack } from '@mui/system';
import { useAtomValue } from 'jotai';
import { FunctionComponent } from 'react';
import RegionSelector, {
  RegionCountries,
} from '../../../../common-ui/components/RegionCountrySelector/RegionSelector.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import { FormFieldLabel } from '../../../../common-ui/index.js';
import { regionCountryOptionsAtom } from '../../../state/atoms.js';
import { Section } from '../types.js';

type RegionsProps = Section & { onChange?: (regions: RegionCountries) => void };

const Regions: FunctionComponent<RegionsProps> = ({
  vehicle,
  title,
  sectionItemsProps,
  readonly,
  onChange,
}) => {
  const regions = useAtomValue(regionCountryOptionsAtom);

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        {regions && (
          <Stack spacing={1.2}>
            <FormFieldLabel name="countries" text="" />
            <RegionSelector
              countries={vehicle.countries || []}
              disabled={readonly}
              onChange={onChange}
              regions={regions}
            />
          </Stack>
        )}
      </Stack>
    </SectionItem>
  );
};

export default Regions;
