import { array, boolean, enumValue, maybe, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface ImageQcPresenceAuthRequest {
  assign: boolean;
  socketId: string;
  skip?: string[];
  brand?: AvailableBrands;
  vin?: string;
}

export const decodeImageQcPresenceAuthRequest =
  object<ImageQcPresenceAuthRequest>({
    assign: boolean,
    socketId: text,
    skip: maybe(array(text)),
    brand: maybe(enumValue(AvailableBrands)),
    vin: maybe(text),
  });
