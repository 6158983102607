import { date, enumValue, maybe, number, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { UserDocumentScope } from '../../util/UserDocument.js';

export interface GetDocumentUploadUrlRequest {
  brand: AvailableBrands;
  contentLength: number;
  contentType: string;
  expires?: Date;
  fileName: string;
  reference: string;
  scope: UserDocumentScope;
}

export const decodeGetDocumentUploadUrlRequest =
  object<GetDocumentUploadUrlRequest>({
    brand: enumValue(AvailableBrands),
    contentLength: number,
    contentType: string,
    expires: maybe(date),
    fileName: string,
    reference: string,
    scope: enumValue(UserDocumentScope),
  });
