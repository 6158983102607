import { ListingVisibility } from '../../api/util/Vehicle.js';

export const visibilityOptions = [
  {
    text: ListingVisibility.Visible,
    value: ListingVisibility.Visible,
  },
  {
    text: ListingVisibility.Hidden,
    value: ListingVisibility.Hidden,
  },
];
