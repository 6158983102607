import { Box, Paper, Skeleton } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent } from 'react';
import { styles } from './VehicleDetailsItem.js';

const VehicleDetailsItemSkeleton: FunctionComponent = () => {
  return (
    <Paper sx={styles.container}>
      <Stack direction="row" spacing={3}>
        <Stack direction="column" flex={1} spacing={2}>
          <Skeleton height={20} variant="rectangular" />
          <Skeleton height={15} variant="rectangular" />
          <Stack direction="row" spacing={2}>
            <Stack flex={1} spacing={2}>
              <Skeleton height={10} variant="rectangular" />
              <Skeleton height={10} variant="rectangular" />
              <Skeleton height={10} variant="rectangular" />
            </Stack>
            <Stack flex={1} spacing={2}>
              <Skeleton height={10} variant="rectangular" />
              <Skeleton height={10} variant="rectangular" />
              <Skeleton height={10} variant="rectangular" />
            </Stack>
          </Stack>
        </Stack>
        <Box
          alignSelf="flex-end"
          display="flex"
          flex={1}
          justifyContent="flex-end"
        >
          <Skeleton height={40} variant="rounded" width={120} />
        </Box>
      </Stack>
    </Paper>
  );
};

export default VehicleDetailsItemSkeleton;
