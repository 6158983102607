import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query';
import { AdminCountRemarketingVehiclesResponse } from '../../../../api/RemarketingService/adminCountRemarketingVehicles/AdminCountRemarketingVehiclesResponse.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { QueryKeys } from './QueryKeys.js';

export const makeCountRemarketingForAdminQueryKey = (
  brand: AvailableBrands,
): QueryKey => [QueryKeys.CountRemarketingForAdmin, brand];

export const useCountRemarketingVehiclesForAdmin =
  (): UseQueryResult<AdminCountRemarketingVehiclesResponse> => {
    const api = useApiClient();
    const { currentBrand } = useBrand();

    return useQuery({
      queryKey: makeCountRemarketingForAdminQueryKey(
        currentBrand as AvailableBrands,
      ),
      queryFn: async () =>
        await api.remarketing.adminCountRemarketingVehicles({
          brand: currentBrand as AvailableBrands,
        }),
      enabled: !!currentBrand,
    });
  };
