import { number, object, string } from '@fmtk/decoders';

export interface RemoveDealerAccessRequest {
  dealerId: number;
  principalId: string;
  email: string;
}

export const decodeRemoveDealerAccessRequest =
  object<RemoveDealerAccessRequest>({
    dealerId: number,
    principalId: string,
    email: string,
  });
