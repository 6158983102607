import { enumValue, maybe, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface UserListBiddingHistoryRequest {
  brand: AvailableBrands;
  dealerId: number;
  remarketingVehicleId: string;
  nextToken?: string;
}

export const decodeUserListBiddingHistoryRequest =
  object<UserListBiddingHistoryRequest>({
    brand: enumValue(AvailableBrands),
    dealerId: number,
    nextToken: maybe(text),
    remarketingVehicleId: text,
  });
