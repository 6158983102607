import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query';
import { UserCountRemarketingVehiclesResponse } from '../../../../api/RemarketingService/userCountRemarketingVehicles/UserCountRemarketingVehiclesResponse.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { useApiClient, useBrand, useDealer } from '../../../hooks/index.js';
import { QueryKeys } from './QueryKeys.js';

export const makeCountRemarketingForUserQueryKey = (
  brand: AvailableBrands,
  dealerId: number,
): QueryKey => [QueryKeys.CountRemarketingForUser, brand, dealerId];

export const useCountRemarketingVehiclesForUser =
  (): UseQueryResult<UserCountRemarketingVehiclesResponse> => {
    const api = useApiClient();
    const { currentBrand } = useBrand();
    const { currentDealer } = useDealer();

    return useQuery({
      queryKey: makeCountRemarketingForUserQueryKey(
        currentBrand as AvailableBrands,
        currentDealer?.id as number,
      ),
      queryFn: async () =>
        await api.remarketing.userCountRemarketingVehicles({
          brand: currentBrand as AvailableBrands,
          // assuming this defined because the condition in 'enabled' property
          countryCode: currentDealer?.countryCode as string,
          dealerId: Number(currentDealer?.id),
        }),
      enabled: !!currentBrand && !!currentDealer?.countryCode,
    });
  };
