import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { RemarketingVehicleSort } from '../../../../api/RemarketingService/common.js';
import { UserListRemarketingVehiclesResponse } from '../../../../api/RemarketingService/userListRemarketingVehicles/UserListRemarketingVehiclesResponse.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { RemarketingVehicleStatus } from '../../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicle } from '../../../../api/util/UserRemarketingVehicle.js';
import { UserRemarketingVehicleEvent } from '../../../../api/util/UserRemarketingVehicleEvent.js';
import {
  WsChannelType,
  WsRemarketingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient, useBrand, useDealer } from '../../../hooks/index.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { QueryKeys } from './QueryKeys.js';
import { useUpdateRemarketingUserListItem } from './updateRemarketingUserListItem.js';

export interface ListRemarketingForUserInput {
  order: 'asc' | 'desc';
  searchTerm: string;
  sort: RemarketingVehicleSort;
  status: RemarketingVehicleStatus;
}

export type ListRemarketingUserItemType = UserRemarketingVehicle & {
  updated?: number;
  changes?: string[];
};

export type ListRemarketingVehiclesForUserData = InfiniteData<
  Omit<UserListRemarketingVehiclesResponse, 'items'> & {
    items: ListRemarketingUserItemType[];
  }
>;

export const makeListRemarketingForUserQueryKey = (
  brand: AvailableBrands,
  dealerId: number,
  input?: ListRemarketingForUserInput,
): QueryKey =>
  filterFalsey([QueryKeys.ListRemarketingForUser, brand, dealerId, input]);

export const useListRemarketingVehiclesForUser = (
  input: ListRemarketingForUserInput,
): UseInfiniteQueryResult<ListRemarketingVehiclesForUserData> => {
  const api = useApiClient();
  const { currentBrand } = useBrand();
  const { currentDealer } = useDealer();
  const updateState = useUpdateRemarketingUserListItem();

  const newEventIncoming = useWsChannelEvent<UserRemarketingVehicleEvent>(
    WsRemarketingEvent.remarketingChange,
    WsChannelType.RemarketingUser,
  );

  useEffect(() => {
    if (!newEventIncoming || !currentBrand || !currentDealer) {
      return;
    }
    updateState({ newEventIncoming });
  }, [newEventIncoming, currentBrand, currentDealer]);

  return useInfiniteQuery({
    queryKey: makeListRemarketingForUserQueryKey(
      currentBrand as AvailableBrands,
      currentDealer?.id as number,
      input,
    ),
    enabled: !!currentBrand && !!currentDealer,
    queryFn: async ({ pageParam }) => {
      return await api.remarketing.userListRemarketingVehicles({
        brand: currentBrand as AvailableBrands,
        order: input.order,
        pageNumber: pageParam ?? 0,
        pageSize: 25,
        searchTerm: input.searchTerm,
        sort: input.sort,
        status: input.status,
        dealerId: currentDealer?.id as number,
        countryCode: currentDealer?.countryCode as string,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastResponse, __, lastPageParam) =>
      lastResponse.items.length ? (lastPageParam || 0) + 1 : undefined,
    refetchOnMount: false,
  });
};
