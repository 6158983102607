import { Image, StyleSheet, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';

interface HeaderLogoProps {
  logo: string;
}

const styles = StyleSheet.create({
  headerImage: {
    marginTop: 34,
    height: 37,
    width: 27,
  },
  logo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
});

const HeaderLogo: FunctionComponent<HeaderLogoProps> = ({ logo }) => (
  <View style={styles.logo}>
    <Image src={logo} style={styles.headerImage} />
  </View>
);

export default HeaderLogo;
