import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import {
  useApiClient,
  useAsyncState,
  useBrand,
  useDealer,
} from '../../../../../hooks/index.js';

export interface WatchlistButtonProps
  extends Omit<LoadingButtonProps, 'onChange'> {
  remarketingVehicleId: string;
  inWatchList?: boolean;
  onChange: (newValue: boolean) => void;
}

export const WatchlistButton = ({
  inWatchList,
  remarketingVehicleId,
  onChange,
  ...rest
}: WatchlistButtonProps) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const [addRemoveWatchList, setAddRemoveWatchList] = useAsyncState<void>();
  const { currentDealer } = useDealer();
  const { currentBrand } = useBrand();

  const handleWatchList = useCallback(() => {
    if (addRemoveWatchList.loading || !currentDealer?.id || !currentBrand) {
      return;
    }
    setAddRemoveWatchList(async () => {
      if (inWatchList) {
        await api.remarketing.userDeleteFromWatchlist({
          brand: currentBrand,
          dealerId: Number(currentDealer.id),
          remarketingVehicleId,
        });
        onChange(false);
      } else {
        await api.remarketing.userAddToWatchlist({
          brand: currentBrand,
          dealerId: Number(currentDealer.id),
          remarketingVehicleId,
        });
        onChange(true);
      }
    });
  }, [
    addRemoveWatchList.loading,
    currentDealer,
    currentBrand,
    setAddRemoveWatchList,
    inWatchList,
    api.remarketing,
    remarketingVehicleId,
    onChange,
  ]);

  return (
    <LoadingButton
      loading={addRemoveWatchList.loading}
      onClick={handleWatchList}
      variant="outlined"
      {...rest}
    >
      {t(
        `pages.remarketingVehiclePage.auction.${
          inWatchList ? 'removeWatchList' : 'addWatchList'
        }`,
      )}
    </LoadingButton>
  );
};
