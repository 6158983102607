import { array, object, text } from '@fmtk/decoders';

export interface ListVehicleListingsByVinsRequest {
  vins: string[];
}

export const decodeListVehicleListingsByVinsRequest =
  object<ListVehicleListingsByVinsRequest>({
    vins: array(text),
  });
