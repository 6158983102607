import { Info } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ListRegionStockStatsResponse } from '../../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import { EmptyState } from '../../../common-ui/index.js';
import { formatPercentage, sequenceColour } from '../../../util/index.js';
import {
  buildGridRowWithTotal,
  ChartDataType,
  IGridRow,
} from '../ChartReports.js';
import MainDrawer from '../MainDrawer.js';
import { options } from './chartConfig.js';
import { buildColumns } from './dataGridConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export interface StockVisibleReportProps {
  data: ListRegionStockStatsResponse;
}

const StockVisibleReport: FunctionComponent<StockVisibleReportProps> = ({
  data,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const reportTitle = t('reports.stockVisible.title');

  const chartData: ChartDataType = useMemo(() => {
    return {
      labels: data.map((item) => item.regionName),
      datasets: [
        {
          label: t('reports.stockVisible.hidden'),
          data: data.map((region) => region.amountOfVehiclesHidden),
          backgroundColor: sequenceColour(0),
        },
        {
          label: t('reports.stockVisible.live'),
          data: data.map((region) => region.amountOfVehiclesLive),
          backgroundColor: sequenceColour(1),
        },
        {
          label: t('reports.stockVisible.rejected'),
          data: data.map((region) => region.amountOfVehiclesFilterNotMet),
          backgroundColor: sequenceColour(2),
        },
      ],
    };
  }, [data, t]);

  const gridRows = useMemo(() => {
    const regions: string[] = data.map((item) => item.regionName);

    const live = buildGridRowWithTotal(
      t('reports.stockVisible.totalLive'),
      'amountOfVehiclesLive',
      regions,
      data,
    );
    const hidden = buildGridRowWithTotal(
      t('reports.stockVisible.totalHidden'),
      'amountOfVehiclesHidden',
      regions,
      data,
    );
    const rejected = buildGridRowWithTotal(
      t('reports.stockVisible.totalRejected'),
      'amountOfVehiclesFilterNotMet',
      regions,
      data,
    );

    const resp = [live, hidden, rejected];

    /**
     * Calculates percentage of stock visible per region.
     * @returns A datagrid row with all calculated percentages.
     */
    const calculatedPercentageRow = (): IGridRow => {
      const obj: IGridRow = {
        title: t('reports.stockVisible.percentage'),
        total: '',
      };

      // Access to each region value, and do the math.
      for (const region in regions) {
        const regionName = regions[region];
        const liveData = Number(resp[0][regionName]);
        const hiddenData = Number(resp[1][regionName]);
        const rejectedData = Number(resp[2][regionName]);
        const total = liveData + hiddenData + rejectedData;
        const avgValue = formatPercentage(liveData / total);
        obj[regionName] = avgValue;
      }

      return obj;
    };

    resp.push(calculatedPercentageRow());
    return resp;
  }, [data, t]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleOpen}>
              <Info />
            </IconButton>
          }
          title={reportTitle}
        />
        <CardContent>
          {data.length === 0 ? (
            <EmptyState />
          ) : (
            <Bar data={chartData} options={options} />
          )}
        </CardContent>
      </Card>
      <MainDrawer onClose={handleClose} open={open} title={reportTitle}>
        <DataGrid
          autoHeight
          columns={buildColumns(data)}
          getRowId={(r) => r.title}
          hideFooter
          rows={gridRows}
        />
      </MainDrawer>
    </>
  );
};

export default React.memo(StockVisibleReport);
