import { enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface CountDealersByRegionRequest {
  brand: AvailableBrands;
}

export const decoderCountDealersByRegionRequest =
  object<CountDealersByRegionRequest>({
    brand: enumValue(AvailableBrands),
  });
