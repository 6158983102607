import { enumValue, number, object, optional } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface ListDealerUsersRequest {
  dealerId: number;
  brand?: AvailableBrands;
}

export const decodeListDealerUsersRequest = object<ListDealerUsersRequest>({
  dealerId: number,
  brand: optional(enumValue(AvailableBrands)),
});
