import { VisibilityOutlined } from '@mui/icons-material';
import {
  Box,
  Button,
  Grid,
  ListItemText,
  MenuItem,
  Stack,
  styled,
  Tooltip,
  Typography,
} from '@mui/material';
import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { AnalyticsEventType } from '../../../../api/util/AnalyticsEventType.js';
import { SyndicationType } from '../../../../api/util/SyndicationType.js';
import { PriceType, VehicleListing } from '../../../../api/util/Vehicle.js';
import {
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import {
  amountOfApprovedImages,
  getRelevantMediaFromVehicleMedia,
} from '../../../../util/vehicleMediaHelper.js';
import DropDownButton from '../../../common-ui/components/DropDownButton.js';
import { Label } from '../../../common-ui/index.js';
import { useBrand, useMobile } from '../../../hooks/index.js';
import { usePrice } from '../../../hooks/usePrice.js';
import { getLocatorVPDUrl } from '../../../util/getLocatorUrl.js';
import {
  archivedColour,
  filterFalsey,
  listingLiveStatusLabelColoursMap,
  sx,
  vehicleTypeColors,
} from '../../../util/index.js';
import { formatMoney } from '../../../util/money.js';
import { mapBrandCertifiedDisplayName } from '../../../util/vehicleTypes.js';
import PrintModal from '../Reports/PrintModal.js';
import BoxListCard from './BoxListCard.js';
import Details from './Details.js';
import Price from './Price.js';

type VehicleTableProps = {
  vehicle: VehicleListing;
  archiveMode?: boolean;
};

const styles = sx({
  card: {
    padding: {
      xs: 0,
      md: 3,
    },
    backgroundColor: {
      xs: 'background.paper',
      md: 'background.default',
    },
    borderRadius: '4px',
    gap: 2,
  },
  body: {
    bgcolor: 'background.default',
    p: {
      xs: 2,
      md: 0,
    },
  },
  priceContainer: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: 'flex-end',
    display: {
      xs: 'flex',
      md: 'none',
    },
  },
  dropDownButton: {
    bgcolor: 'background.paper',
  },
  viewsContainer: {
    position: 'absolute',
    margin: '5px; 6px;',
    display: 'flex',
    gap: 1,
    zIndex: 1,
  },
  printButton: {
    borderColor: 'grey.600',
    border: '2px solid #919EAB',
    bgcolor: '#FFF',
    color: 'grey.600',
  },
});

const Img = styled('img')<{ archived?: boolean }>(({ archived, theme }) => ({
  width: '100%',
  aspectRatio: '4/3',
  borderRadius: '4px',
  objectFit: 'contain',
  [theme.breakpoints.up('md')]: {
    height: 150,
    width: 'auto',
  },
  filter: archived ? 'grayscale(1)' : 'none',
  ':hover': {
    filter: 'none',
  },
}));

const priceKeys = [
  PriceType.LessTaxes,
  PriceType.NetPrice,
  PriceType.Retail,
  PriceType.RetailPlusTax,
  PriceType.StandInValue,
  PriceType.Trade,
  PriceType.BasePrice,
] as const;

export default function VehicleCard({
  vehicle,
  archiveMode,
}: VehicleTableProps) {
  const { brandConfig, currentBrand, isViewer, isEditor } = useBrand();
  const { t } = useTranslation();
  const isMobile = useMobile('md');
  const navigate = useNavigate();
  const location = useLocation();
  const priceVisibility = usePrice();
  const [openPrintModal, setOpenPrintModal] = useState(false);
  const container = useRef<HTMLDivElement | null>(null);
  const price = vehicle.price?.retail
    ? formatMoney(vehicle.price?.retail)
    : vehicle.listingStatus;

  const {
    imgSrc,
    boxItems,
    daysInStock,
    nonLive,
    detailsItem,
    vehicleTitle,
    syndicationTypePillLabel,
  } = useMemo(() => {
    const { vehicleMedia, analyticsEvent, liveStatus, isLive } = vehicle;

    const vehicleTitle = `${
      vehicle.vehicle.modelYear ||
      (vehicle.vehicle.registrationYear != 9999 &&
        vehicle.vehicle.registrationYear) ||
      ''
    } ${vehicle.vehicle.model.name}`;

    const media = getRelevantMediaFromVehicleMedia({
      vehicleMedia: vehicleMedia || [],
      options: {
        mediaType: [VehicleMediaType.Image, VehicleMediaType.Legacy],
        mediaStatus: [VehicleMediaStatus.Approved, VehicleMediaStatus.Pending],
        transformation: 't_vehicle_thumbnail',
      },
    });

    const imgSrc = media?.mediaUrl || brandConfig?.noVehicleImage;

    const boxItems = isLive
      ? filterFalsey(
          Object.entries(analyticsEvent || {}).map(([analytic, value]) => {
            return analytic !== AnalyticsEventType.View
              ? `${value} ${t(`analyticsEvent.${analytic}`)}`
              : undefined;
          }),
        )
      : liveStatus.map((status) => t(`vehicleListingLiveStatus.${status}`));

    const listingDate = new Date(vehicle.stockDate);
    const today = new Date();
    const diff = Math.abs(today.getTime() - listingDate.getTime());
    const days = Math.ceil(diff / (1000 * 3600 * 24));
    const daysInStock = !vehicle.stockDate ? 0 : days;

    const nonLive = !vehicle.isLive;

    const syndicationTypePillLabel =
      vehicle.syndicationType === SyndicationType.Certified
        ? mapBrandCertifiedDisplayName[vehicle.vehicle.brand]
        : vehicle.syndicationType
        ? t(vehicle.syndicationType?.toLowerCase())
        : vehicle.syndicationType;

    const priceItems =
      isMobile && priceVisibility
        ? filterFalsey(
            Object.entries(priceVisibility).map(([k, v]) =>
              v &&
              priceKeys.includes(k as PriceType) &&
              vehicle.price &&
              !!vehicle.price[k as PriceType]
                ? {
                    label: t(k),
                    value: formatMoney(vehicle.price[k as PriceType]!),
                  }
                : undefined,
            ),
          )
        : [];

    const detailsItem = [
      { label: t('vin'), value: vehicle.vehicle.vin.toUpperCase() },
      {
        label: t('odometer'),
        value: `${vehicle.odometer?.value} ${vehicle.odometer?.units}`,
      },
      {
        label: t('images'),
        value: amountOfApprovedImages(vehicle.vehicleMedia),
      },
      ...priceItems,
    ];

    return {
      imgSrc,
      boxItems,
      daysInStock,
      nonLive,
      detailsItem,
      vehicleTitle,
      syndicationTypePillLabel,
    };
  }, [brandConfig?.noVehicleImage, isMobile, priceVisibility, t, vehicle]);

  const togglePrintModal = () => {
    setOpenPrintModal((state) => !state);
  };

  const canViewOrEdit = currentBrand && (isEditor || isViewer);

  const handleViewVehicle = useCallback(() => {
    if (canViewOrEdit) {
      navigate(`vehicles/${vehicle.id}/details`);
    }
  }, [canViewOrEdit, navigate, vehicle.id]);

  const onDetailsClick = useCallback(() => {
    if (!brandConfig || !currentBrand || !vehicle.id) {
      return;
    }

    const vdpUrl = getLocatorVPDUrl(brandConfig, currentBrand, {
      id: String(vehicle.id),
      dealer: vehicle.dealer,
      syndicationType: vehicle.syndicationType,
    });
    window.open(vdpUrl, '_blank');
  }, [brandConfig, currentBrand, vehicle]);

  const menuViewOptions = useMemo(() => {
    return [
      {
        label: t('pages.dealerDashboard.liveListing'),
        onClick: onDetailsClick,
        disabled: nonLive || archiveMode,
      },
      {
        label: t('viewDetails'),
        onClick: handleViewVehicle,
        disabled: !canViewOrEdit,
      },
    ];
  }, [canViewOrEdit, handleViewVehicle, nonLive, onDetailsClick, t]);

  useEffect(() => {
    if (location.state?.vehicleId === vehicle.id) {
      container.current?.scrollIntoView({ block: 'center' });
    }
  }, [location.state?.vehicleId, vehicle.id]);

  return (
    <Grid container ref={container} sx={styles.card}>
      <Grid container item md="auto" position="relative">
        <Box sx={styles.viewsContainer}>
          <Label bgcolor={archiveMode ? archivedColour.background : '#172B4D'}>
            <Stack direction="row" spacing={1}>
              <VisibilityOutlined fontSize="medium" htmlColor="#FFF" />
              <Typography
                color="#FFF"
                fontWeight="fontWeightMedium"
                textTransform="capitalize"
                variant="body2"
              >
                {t('terms.views', {
                  value: vehicle.analyticsEvent?.View || 0,
                })}
              </Typography>
            </Stack>
          </Label>
        </Box>
        <Img
          alt="vehicle img"
          archived={archiveMode}
          onError={(event: React.SyntheticEvent<HTMLImageElement, Event>) => {
            event.currentTarget.src = brandConfig?.noVehicleImage || '';
          }}
          src={imgSrc}
        />
      </Grid>
      <Grid
        container
        flexDirection="column"
        item
        justifyContent="space-between"
        xs
      >
        <Grid container direction="column" item rowGap={{ xs: 2, md: 0 }}>
          <Grid
            columnGap={3}
            container
            flexDirection={{
              xs: 'column',
              md: 'row',
            }}
            item
            rowGap={1}
          >
            <Grid item>
              <Typography
                color={canViewOrEdit && !archiveMode ? 'primary' : 'grey.700'}
                component={canViewOrEdit ? Link : 'span'}
                sx={{
                  textDecoration: canViewOrEdit ? 'underline' : 'inherit',
                }}
                to={
                  canViewOrEdit ? `vehicles/${vehicle.id}/details` : undefined
                }
                variant="h4"
              >
                {vehicleTitle}
              </Typography>
            </Grid>
            <Grid alignContent="center" columnGap={1} container item xs="auto">
              <Label
                bgcolor={
                  archiveMode
                    ? archivedColour.background
                    : nonLive
                    ? listingLiveStatusLabelColoursMap.nonLive.background
                    : listingLiveStatusLabelColoursMap.LIVE.background
                }
              >
                <Typography
                  color={
                    archiveMode
                      ? archivedColour.color
                      : nonLive
                      ? listingLiveStatusLabelColoursMap.nonLive.color
                      : listingLiveStatusLabelColoursMap.LIVE.color
                  }
                  variant="body2"
                >
                  {t(archiveMode ? 'archived' : nonLive ? 'nonLive' : 'live')}
                </Typography>
              </Label>
              <Label
                bgcolor={
                  archiveMode
                    ? archivedColour.background
                    : vehicleTypeColors[
                        vehicle.syndicationType as SyndicationType
                      ]
                }
              >
                <Typography
                  color={
                    archiveMode
                      ? archivedColour.color
                      : vehicle.syndicationType === SyndicationType.Certified
                      ? '#219a15'
                      : 'common.white'
                  }
                  variant="body2"
                >
                  {syndicationTypePillLabel}
                </Typography>
              </Label>
              {daysInStock > 180 && (
                <Label bgcolor="grey.500" component="span">
                  <Tooltip
                    arrow
                    title={t('pages.dealerDashboard.daysInStock', {
                      value: daysInStock,
                    })}
                  >
                    <Typography
                      color="grey.100"
                      fontWeight="fontWeightMedium"
                      variant="body2"
                    >
                      {t('pages.dealerDashboard.daysInStockLabel', {
                        value: 180,
                      })}
                    </Typography>
                  </Tooltip>
                </Label>
              )}
              <Typography
                color="grey.600"
                fontWeight="fontWeightMedium"
                variant="body2"
              >
                {t('pages.dealerDashboard.dateAdded', {
                  value: vehicle.stockDate.toLocaleDateString(),
                })}
              </Typography>
            </Grid>
          </Grid>
          <Grid sx={styles.priceContainer}>
            <Typography
              color="grey.600"
              fontWeight="fontWeightMedium"
              gutterBottom
              m={0}
              variant="body2"
            >
              {t('retailPrice')}
            </Typography>
            <Typography variant="h5">
              {vehicle.currency?.code} {price}
            </Typography>
          </Grid>
          <Grid container flexDirection="column" item sx={styles.body}>
            <Details items={detailsItem} />
          </Grid>
        </Grid>
        <Grid container item sx={styles.body} xs>
          <BoxListCard
            archiveMode={archiveMode}
            isError={nonLive}
            items={boxItems}
            title={nonLive ? t('reasonRejection') : undefined}
          />
        </Grid>
      </Grid>
      <Grid container item justifyContent="flex-end" lg={3} md={4} xs={12}>
        <Grid
          container
          flexDirection="column"
          item
          justifyContent="space-between"
        >
          <Grid
            container
            direction="column"
            display={{
              xs: 'none',
              md: 'flex',
            }}
            item
          >
            <Price vehicle={vehicle} />
          </Grid>
          <Grid container gap={1} item justifyContent="flex-end">
            <DropDownButton
              defaultValue=""
              multiple={false}
              renderValue={() => t('view') || ''}
              sx={styles.dropDownButton}
            >
              {menuViewOptions.map(({ label, onClick, disabled }) => {
                return (
                  <MenuItem
                    dense
                    disabled={disabled}
                    key={label}
                    onClick={onClick}
                    value={label}
                  >
                    <ListItemText
                      primary={label}
                      primaryTypographyProps={{
                        variant: 'body2',
                        fontWeight: 'medium',
                      }}
                    />
                  </MenuItem>
                );
              })}
            </DropDownButton>
            {brandConfig?.availablePdfReports && (
              <Button
                color="inherit"
                onClick={togglePrintModal}
                size="small"
                sx={styles.printButton}
                variant="outlined"
              >
                {t('print')}
              </Button>
            )}
          </Grid>
        </Grid>
      </Grid>
      {openPrintModal && (
        <PrintModal
          onClose={togglePrintModal}
          open={openPrintModal}
          vehicleListing={vehicle}
        />
      )}
    </Grid>
  );
}
