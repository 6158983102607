import { Font } from '../../ims/components/Reports/Font.js';

export const AstonMartinSans: Font = {
  family: 'AstonMartinSans',
  fonts: [
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/aston-martin/AstonMartinSans_Rg.ttf',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/aston-martin/AstonMartinSans_Md.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/aston-martin/AstonMartinSans_Lt.ttf',
      fontWeight: 'light',
    },
  ],
};

export const AstonMartinFlare: Font = {
  family: 'AstonMartinFlare',
  fonts: [
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/aston-martin/AstonMartinFlare_Md.ttf',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/aston-martin/AstonMartinFlare_XBd.ttf',
      fontWeight: 'bold',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/aston-martin/AstonMartinFlare_Rg.ttf',
      fontWeight: 'light',
    },
  ],
};
