import { array, number, object, string } from '@fmtk/decoders';

export interface AvailableDealerFeatures {
  id: number;
  name: string;
}

export const decodeAvailableDealerFeatures = object<AvailableDealerFeatures>({
  name: string,
  id: number,
});

export type GetDealerFeaturesResponse = AvailableDealerFeatures[];

export const decodeGetDealerFeaturesResponse = array(
  decodeAvailableDealerFeatures,
);
