import { merge } from 'lodash-es';
import { getCloudinaryUrl } from '../../core/TransitionsService/util/vehicleMediaHelper.js';

export interface CloudinaryConfig {
  cloudName: string;
  defaultUploadPreset: string;
  baseUrl: string;
  apiUrl: string;
}

const config = merge(
  {
    cloudinary: {
      cloudName: process.env.REACT_APP_CONFIG_CLOUDINARY_CLOUD_NAME,
      defaultUploadPreset:
        process.env.REACT_APP_CONFIG_CLOUDINARY_DEFAULT_UPLOAD_PRESET,
      baseUrl: process.env.REACT_APP_CONFIG_CLOUDINARY_BASE_URL,
      apiUrl: process.env.REACT_APP_CONFIG_CLOUDINARY_API_URL,
    },
  },
  (window as any).app_config,
);

export type Cloudinary = {
  config?: CloudinaryConfig;
  getCloudinaryUrl: (
    cloudinaryId?: string,
    transformations?: string | string[],
  ) => string | undefined;
};

export function useCloudinary(): Cloudinary {
  const resolveCloudinaryUrl = (
    cloudinaryId?: string,
    transformations?: string[] | string,
  ): string | undefined => {
    if (!config?.cloudinary || !cloudinaryId) {
      return;
    }
    const { cloudName, baseUrl } = config.cloudinary as CloudinaryConfig;

    return getCloudinaryUrl(cloudinaryId, transformations, {
      cloudName,
      baseUrl,
    });
  };

  return {
    config: config?.cloudinary,
    getCloudinaryUrl: resolveCloudinaryUrl,
  };
}
