import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CellSelectInput, CellTextInput } from '../../../../common-ui/index.js';
import { useVehicle } from '../../../../hooks/useVehicle.js';
import { Option } from '../../../../util/Option.js';
import { VehicleFields } from '../../../../util/VehicleFields.js';
import { ActionType, Section } from './types.js';

type EngineSectionProps = Omit<Section, 'vehicleListing'> & {
  engineOptions?: Option[];
  transmissionOptions?: Option[];
};

const Engine: FunctionComponent<EngineSectionProps> = ({
  title,
  sectionItemsProps,
  readonly,
  engineOptions,
  transmissionOptions,
  actionType = ActionType.EDIT,
}) => {
  const { fieldsConfig, resolveReadonly } = useVehicle();

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            {fieldsConfig.engineType?.visible && (
              <CellTextInput
                label="engineType"
                name="engineType"
                readonly={resolveReadonly(
                  VehicleFields.EngineType,
                  actionType,
                  readonly,
                )}
              />
            )}
            <CellSelectInput
              label="fuelType"
              name="fuelType"
              options={engineOptions || []}
              readonly={readonly}
            />
            <CellTextInput
              label="capacity"
              name="capacity"
              readonly={readonly}
            />
            {fieldsConfig.bhp?.visible && (
              <CellTextInput
                label="bhp"
                name="bhp"
                readonly={resolveReadonly(
                  VehicleFields.Bhp,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.cylinders?.visible && (
              <CellTextInput
                label="cylinders"
                name="cylinders"
                readonly={resolveReadonly(
                  VehicleFields.Cylinders,
                  actionType,
                  readonly,
                )}
              />
            )}
          </Stack>
          <Stack flex={1}>
            {fieldsConfig.powerKw?.visible && (
              <CellTextInput
                label="powerKw"
                name="powerKw"
                readonly={resolveReadonly(
                  VehicleFields.PowerKw,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.torqueLbft?.visible && (
              <CellTextInput
                label="torqueLbft"
                name="torqueLbft"
                readonly={resolveReadonly(
                  VehicleFields.TorqueLbft,
                  actionType,
                  readonly,
                )}
              />
            )}
            {fieldsConfig.torqueNm?.visible && (
              <CellTextInput
                label="torqueNm"
                name="torqueNm"
                readonly={resolveReadonly(
                  VehicleFields.TorqueNm,
                  actionType,
                  readonly,
                )}
              />
            )}
            <CellSelectInput
              label="transmission"
              name="transmission"
              options={transmissionOptions || []}
              readonly={readonly}
            />
          </Stack>
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Engine;
