import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
} from '@tanstack/react-query';
import { VehicleListingSort } from '../../../../api/VehicleListingService/common.js';
import { SearchVehicleListingResponse } from '../../../../api/VehicleListingService/searchVehicleListings/SearchVehicleListingResponse.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { SyndicationType } from '../../../../api/util/SyndicationType.js';
import { IndexName } from '../../../../core/SearchIndexService/indexes.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { QueryKeys } from './QueryKeys.js';

export interface SearchVehicleListingsInput {
  dealerId: number;
  searchTerm: string;
  onlyCertifiedVehicles?: boolean;
  isLive?: boolean;
  onlyStockOver90Days?: boolean;
  syndicationTypes?: SyndicationType[];
  sort?: VehicleListingSort;
  order?: 'asc' | 'desc';
  indexName?: IndexName.VehicleListings | IndexName.ArchivedVehicleListings;
}

export type SearchVehicleListingsData =
  InfiniteData<SearchVehicleListingResponse>;

export const makeSearchVehicleListingsQueryKey = (
  brand: AvailableBrands,
  input?: SearchVehicleListingsInput,
): QueryKey => filterFalsey([QueryKeys.SearchVehicleListings, brand, input]);

export const useSearchVehicleListings = (
  input: SearchVehicleListingsInput,
): UseInfiniteQueryResult<SearchVehicleListingsData> => {
  const api = useApiClient();
  const { currentBrand } = useBrand();

  return useInfiniteQuery({
    queryKey: makeSearchVehicleListingsQueryKey(
      currentBrand as AvailableBrands,
      input,
    ),
    enabled: !!currentBrand && !!input.dealerId,
    queryFn: async ({ pageParam }) => {
      return await api.vehicleListing.searchVehicleListings({
        brand: currentBrand as AvailableBrands,
        dealer: input.dealerId,
        options: {
          page: pageParam || 0,
          onlyCertifiedVehicles: input.onlyCertifiedVehicles,
          isLive: input.isLive,
          onlyStockOver90Days: input.onlyStockOver90Days,
          syndicationTypes: input.syndicationTypes,
          sort: input.sort,
          order: input.order,
          indexName: input.indexName,
        },
        freeTextSearch: input.searchTerm,
      });
    },
    initialPageParam: 0,
    getNextPageParam: (lastResponse, __, lastPageParam) =>
      lastResponse.items.length ? (lastPageParam || 0) + 1 : undefined,
    refetchOnMount: false,
    placeholderData: (prev) => prev,
  });
};
