import { SyndicationType } from '../../api/util/SyndicationType.js';
import { Option } from '../util/Option.js';
import { syndicationTypeOptions } from '../util/vehicleTypes.js';
import { useBrand } from './useBrand.js';

export const useSyndicationTypeOptions = (options?: {
  remarketing?: boolean;
}): Option[] => {
  const { brandConfig, currentBrand } = useBrand();
  if (!brandConfig || !currentBrand) {
    return [];
  }
  return options?.remarketing
    ? [
        {
          text: 'New',
          value: SyndicationType.New,
        },
        {
          text: 'Pre-owned',
          value: SyndicationType.Used,
        },
      ]
    : syndicationTypeOptions(currentBrand).filter(
        (opt) =>
          !brandConfig.disabledOptions?.SyndicationTypes?.includes(
            SyndicationType[opt.value as SyndicationType],
          ),
      );
};
