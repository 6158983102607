import { maybe } from '@fmtk/decoders';
import {
  decodeInventoryReportProcess,
  InventoryReportProcess,
} from '../../util/InventoryReportProcess.js';

export type GetInventoryProcessResponse = InventoryReportProcess;

export const decodeGetInventoryProcessResponse = maybe(
  decodeInventoryReportProcess,
);
