import { enumValue, is, number, object, string, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { RemarketingVehicleStatus } from '../../util/RemarketingVehicle.js';
import { RemarketingVehicleSort } from '../common.js';

export interface UserListRemarketingVehiclesRequest {
  brand: AvailableBrands;
  countryCode: string;
  dealerId: number;
  status: RemarketingVehicleStatus;
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
  sort: RemarketingVehicleSort;
  order: 'asc' | 'desc';
}

export const decodeUserListRemarketingVehiclesRequest =
  object<UserListRemarketingVehiclesRequest>({
    brand: enumValue(AvailableBrands),
    countryCode: text,
    dealerId: number,
    status: is(
      RemarketingVehicleStatus.ACTIVE,
      RemarketingVehicleStatus.CLOSED,
      RemarketingVehicleStatus.VISIBLE,
    ),
    pageNumber: number,
    pageSize: number,
    searchTerm: string,
    sort: enumValue(RemarketingVehicleSort),
    order: is('asc', 'desc'),
  });
