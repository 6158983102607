import { Box, CircularProgress, SxProps, Theme } from '@mui/material';
import { UploadApiErrorResponse, UploadApiResponse } from 'cloudinary';
import { MouseEventHandler, useCallback } from 'react';
import { v4 as uuid } from 'uuid';
import { ImageTags, MediaSeo } from '../../../../api/util/VehicleMedia.js';
import { generateCloudinaryPrefix } from '../../../../util/generateCloudinaryPrefix.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useCloudinary } from '../../../hooks/useCloudinary.js';
import { FilePicker } from '../FilePicker.js';

interface UploadImageWidgetOptions {
  onSuccess?: (cloudinaryApiResponse: UploadApiResponse) => void;
  onError?: (error: { message: string; fileName: string }) => void;
  disabled?: boolean;
  seo: MediaSeo;
  size?: 'small' | 'medium' | 'large';
  multiple?: boolean;
  tags?: ImageTags[];
  sx?: SxProps<Theme>;
}

const UploadImageWidget = (props: UploadImageWidgetOptions) => {
  const [uploadResult, setUploadResult] = useAsyncState<void>();
  const { config } = useCloudinary();
  const { onSuccess, onError, disabled, seo, multiple, tags, size } = props;

  const handleMouseDown: MouseEventHandler<HTMLDivElement> = useCallback(
    (e) => {
      e.stopPropagation();
    },
    [],
  );

  const handleFileChange = useCallback(
    (files: File[]) => {
      setUploadResult(async () => {
        const sliceSize = 6000000; // 6MB
        if (!config) {
          console.error('Cloudinary config not found');
          return;
        }

        for (let i = 0; i < files.length; i++) {
          try {
            let data: UploadApiResponse | undefined | UploadApiErrorResponse;
            const file = files[i];
            const size = file.size;
            let start = 0;
            const XUniqueUploadId = uuid();

            const formData = new FormData();

            formData.append('cloud_name', config.cloudName || '');
            formData.append('upload_preset', config.defaultUploadPreset || '');
            formData.append('public_id_prefix', generateCloudinaryPrefix(seo));
            formData.append(
              'tags',
              [ImageTags.Ims2, ...(tags || [])].join(','),
            );
            formData.append('filename_override', file.name);

            while (start < size) {
              let end = start + sliceSize;
              if (end > size) {
                end = size;
              }

              const piece = file.slice(start, end);
              formData.append('file', piece);

              const response = await fetch(config.apiUrl, {
                method: 'POST',
                headers: {
                  'X-Unique-Upload-Id': XUniqueUploadId,
                  'Content-Range': `bytes ${start}-${end - 1}/${size}`,
                },
                body: formData,
              });

              data = await response.json();

              if (response.status >= 400 || data?.error) {
                onError &&
                  onError({
                    message: data?.error.message || 'UNKNOWN_ERROR',
                    fileName: files[i].name,
                  });
              }

              start += sliceSize;
            }
            onSuccess && onSuccess(data as UploadApiResponse);
          } catch (error: unknown) {
            if (error instanceof Error) {
              onError &&
                onError({
                  message: error.message || 'UNKNOWN_ERROR',
                  fileName: files[i].name,
                });
            } else {
              console.error(error);
            }
          }
        }
        return;
      });
    },
    [config, onError, onSuccess, seo, setUploadResult, tags],
  );

  return (
    <Box onMouseDown={handleMouseDown}>
      {uploadResult.loading ? (
        <Box
          sx={{
            boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)',
            backgroundColor: 'rgba(255, 255, 255, 0.9)',
            borderRadius: 0.5,
            width: 30,
            height: 30,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <CircularProgress size={20} />
        </Box>
      ) : (
        <FilePicker
          accept="image/*"
          disabled={disabled}
          multiple={multiple}
          onFileChange={handleFileChange}
          size={size}
        />
      )}
    </Box>
  );
};

export default UploadImageWidget;
