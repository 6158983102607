import { Box, CircularProgress } from '@mui/material';
import { FunctionComponent } from 'react';

const LoadingPage: FunctionComponent = (): JSX.Element => {
  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      <CircularProgress size={60} />
    </Box>
  );
};

export default LoadingPage;
