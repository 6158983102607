import { ChartOptions } from 'chart.js';
import { t } from 'i18next';
import { formatPercentage } from '../../../util/index.js';

export const options: ChartOptions<'bar'> = {
  responsive: true,
  interaction: {
    intersect: false,
    mode: 'index',
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.region'),
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.noOfVehicles'),
      },
    },
  },
  plugins: {
    tooltip: {
      callbacks: {
        footer: (tooltipItems): string | string[] => {
          const used = tooltipItems[0].parsed.y;
          const newVehicles = tooltipItems[1].parsed.y;
          const certified = tooltipItems[2].parsed.y;
          const allVehicles = used + certified + newVehicles;
          return [
            `Total vehicles: ${allVehicles}`,
            `Certified: ${
              !isNaN(certified / (used + certified))
                ? formatPercentage(certified / (used + certified))
                : '-'
            }`,
          ];
        },
        label: (context: any): string | string[] =>
          `${context.dataset.label}: ${context.formattedValue}`,
      },
    },
  },
};
