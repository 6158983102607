import { FormHelperText } from '@mui/material';
import { TFunction } from 'i18next';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { filterFalsey } from '../../util/filterFalsey.js';

export const FieldErrorDefaultTranslationBase = 'common:validation';

export interface ErrorInfo {
  id: string;
  field?: string;
  text: string;
  details?: any;
}

export interface FieldErrorProps {
  children?: React.ReactNode;
  className?: string;
  error?: string | ErrorInfo | ErrorInfo[];
  translationBase?: string;
}

export function FieldError({
  className,
  error,
  children,
  translationBase,
}: FieldErrorProps): JSX.Element {
  const { t } = useTranslation();
  return (
    <FormHelperText className={className} error>
      {children ?? (error && errorText(t, error, translationBase))}
    </FormHelperText>
  );
}

export function hasError(
  error: string | ErrorInfo | ErrorInfo[] | undefined,
): boolean {
  return !!error && (!Array.isArray(error) || error.length > 0);
}

export function errorText(
  t: TFunction,
  error: string | ErrorInfo | ErrorInfo[],
  translationBase = 'validation',
): string {
  if (typeof error === 'string') {
    return t(error);
  }
  if (Array.isArray(error)) {
    return [...new Map(error.map((item) => [item.id, item])).values()]
      .map((x) => errorText(t, x, translationBase))
      .join(', ');
  }

  return t(
    filterFalsey([
      translationBase && `${translationBase}.${error.field}`,
      translationBase && `${translationBase}.${error.id}`,
      `${FieldErrorDefaultTranslationBase}.${error.field}`,
      `${FieldErrorDefaultTranslationBase}.${error.field}`,
      error.text,
    ]),
    error.details,
    {
      defaultValue: error.text,
      returnEmptyString: true,
    },
  );
}
