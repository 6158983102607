export enum ListingLiveStatus {
  // should display as red in the UI
  DEMONSTRATOR = 'DEMONSTRATOR',
  FACTORY_RECALL = 'FACTORY_RECALL',
  INVALID_VIN = 'INVALID_VIN',
  MANUALLY_HIDDEN = 'MANUALLY_HIDDEN',
  MAXIMUM_AGE = 'MAXIMUM_AGE',
  MAXIMUM_MILEAGE = 'MAXIMUM_MILEAGE',
  MINIMUM_IMAGES = 'MINIMUM_IMAGES',
  MINIMUM_MILES = 'MINIMUM_MILES',
  MINIMUM_REG_DATE = 'MINIMUM_REG_DATE',
  MISSING_WLTP = 'MISSING_WLTP',
  NEW_REGYEAR = 'NEW_REGYEAR',
  NOT_CERTIFIED = 'NOT_CERTIFIED',
  NO_MODEL_YEAR = 'NO_MODEL_YEAR',
  NO_PRICE = 'NO_PRICE',
  NO_REGDATE = 'NO_REGDATE',
  NO_RULE = 'NO_RULE',
  NO_USED_VEHICLES = 'NO_USED_VEHICLES',
  PROHIBITED_MODEL = 'PROHIBITED_MODEL',
  REQUIRED_IMAGES = 'REQUIRED_IMAGES',
  SOLD_SUBJECT_TO_FINANCE = 'SOLD_SUBJECT_TO_FINANCE',
  UNDER_CONVERSION = 'UNDER_CONVERSION',
  UNDER_OFFER = 'UNDER_OFFER',
  UNDER_REVIEW = 'UNDER_REVIEW',
  VEHICLE_REMOVED = 'VEHICLE_REMOVED',
  // should display as blue in the UI
  LIVE = 'LIVE',
  SOLD = 'SOLD',
}
