import { object, string } from '@fmtk/decoders';

export interface GetDownloadDocumentUrlResponse {
  url: string;
}

export const decodeGetDownloadDocumentUrlResponse =
  object<GetDownloadDocumentUrlResponse>({
    url: string,
  });
