import { AppBar, Box, styled } from '@mui/material';
import { useEffect, useState } from 'react';
import { Outlet, useParams } from 'react-router-dom';
import { getAvailableBrandFromId } from '../../../api/util/getAvailableBrandFromId.js';
import { ErrorState } from '../../common-ui/index.js';
import { useBrand, useMobile } from '../../hooks/index.js';
import NotificationsContainer from '../components/InAppNotifications/NotificationsContainer.js';
import DashboardNavbar from './DashboardNavbar.js';
import DashboardSidebar from './DashboardSidebar.js';

export const APP_BAR_MOBILE = 88;
export const MAIN_CONTAINER_ID = 'main-container';

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden',
});

const MainStyle = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflow: 'auto',
  minHeight: '100%',
  scrollBehavior: 'smooth',
  [theme.breakpoints.down('lg')]: {
    paddingTop: APP_BAR_MOBILE,
  },
}));

const TopAppBar = styled(AppBar)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  borderBottom: `1px dashed ${theme.palette.divider}`,
}));

const MainLayout = (): JSX.Element => {
  const [open, setOpen] = useState(false);
  const isMobile = useMobile('lg');
  const { brand } = useParams();
  const { updateCurrentBrand, error } = useBrand();

  useEffect(() => {
    if (error) {
      return;
    }
    if (brand) {
      const availableBrand = getAvailableBrandFromId(Number(brand));
      updateCurrentBrand(availableBrand);
    }
  }, [brand, error, updateCurrentBrand]);

  if (error) {
    return (
      <Box
        sx={{
          height: '100vh',
          width: '100%',
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <ErrorState
          alertTitle="Contact Support"
          error="You don't have a brand associated to your user, please contact support"
        />
      </Box>
    );
  }

  return (
    <RootStyle>
      {isMobile && <DashboardNavbar onOpenSidebar={() => setOpen(true)} />}
      <DashboardSidebar
        isOpenSidebar={open}
        onCloseSidebar={() => setOpen(false)}
      />
      <MainStyle id={MAIN_CONTAINER_ID}>
        {!isMobile && (
          <TopAppBar elevation={0} position="sticky">
            <NotificationsContainer />
          </TopAppBar>
        )}
        <Outlet />
      </MainStyle>
    </RootStyle>
  );
};

export default MainLayout;
