import { ContactSupport } from '@mui/icons-material';
import { Link, Tooltip } from '@mui/material';
import { useTranslation } from 'react-i18next';

export interface LanguageOption {
  value: string;
  label: string;
  icon: string;
}

export default function ContactSupportButton() {
  const { t } = useTranslation();
  return (
    <Link
      color="primary.main"
      href="mailto:support@connect.auto"
      underline="none"
    >
      <Tooltip title={t('contactSupport')}>
        <ContactSupport sx={{ verticalAlign: 'middle' }} />
      </Tooltip>
    </Link>
  );
}
