import { ThemeOptions } from '@mui/material';

const astonMartinTheme: ThemeOptions = {
  palette: {
    primary: {
      main: '#00665E',
    },
    secondary: {
      main: '#1F1F1F',
    },
    background: {
      default: '#F4F4F2',
    },
    text: {
      secondary: '#004F5F',
    },
  },
};

export default astonMartinTheme;
