import { object, string } from '@fmtk/decoders';

export interface AccessRequestRequest {
  givenName: string;
  familyName: string;
  dealerName: string;
  jobRole: string;
}

export const decodeAccessRequestRequest = object<AccessRequestRequest>({
  givenName: string,
  familyName: string,
  dealerName: string,
  jobRole: string,
});
