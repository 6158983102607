import { array, choose, enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface GetBrandConfigRequest {
  brand: AvailableBrands | AvailableBrands[];
}

export const decodeGetBrandConfigRequest = object<GetBrandConfigRequest>({
  brand: choose(array(enumValue(AvailableBrands)), enumValue(AvailableBrands)),
});
