import { atom } from 'jotai';
import { atomWithReset } from 'jotai/utils';
import { ListRegionCountryResponse } from '../../../api/BrandService/listRegionCountry/ListRegionCountryResponse.js';
import { AddRemarketingVehicleRequest } from '../../../api/RemarketingService/addRemarketingVehicle/AddRemarketingVehicleRequest.js';
import { CreateVehicleListingRequest } from '../../../api/VehicleListingService/createVehicleListing/CreateVehicleListingRequest.js';
import { ListTaxonomyOptionsResponse } from '../../../api/VehicleService/listTaxonomyOptions/ListTaxonomyOptionsResponse.js';
import { AddVehicleFormState } from '../components/AddVehicle/Steps/DetailsStep.js';

export interface AddVehicleStepperAtomType {
  temporaryVin?: string;
  detailsFormState?: AddVehicleFormState;
  currentListing?: CreateVehicleListingRequest;
}

export interface AddRemarketingVehicleStepperAtomType {
  currentRemarketingVehicle?: AddRemarketingVehicleRequest;
  disableReservePrice?: boolean;
}

export interface RemarketingFilters {
  searchParams?: URLSearchParams;
}

export interface VehicleListingFilters {
  searchParams?: URLSearchParams;
}

const addVehicleStepperValue: AddVehicleStepperAtomType = {
  temporaryVin: undefined,
  detailsFormState: undefined,
  currentListing: undefined,
};

const addRemarketingVehicleState: AddRemarketingVehicleStepperAtomType = {
  currentRemarketingVehicle: undefined,
  disableReservePrice: undefined,
};

export const addVehicleStepperAtom = atomWithReset<AddVehicleStepperAtomType>(
  addVehicleStepperValue,
);

export const taxonomyOptionAtom = atom<ListTaxonomyOptionsResponse | undefined>(
  undefined,
);

export const addRemarketingVehicleAtom = atomWithReset(
  addRemarketingVehicleState,
);

export const regionCountryOptionsAtom = atom<
  ListRegionCountryResponse | undefined
>(undefined);

export const remarketingFiltersAtom = atomWithReset<RemarketingFilters>({
  searchParams: undefined,
});

export const vehicleListingFiltersAtom = atomWithReset<VehicleListingFilters>({
  searchParams: undefined,
});
