import { Info } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  registerables,
  Title,
  Tooltip,
} from 'chart.js';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ListRegionStockStatsResponse } from '../../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import { EmptyState } from '../../../common-ui/index.js';
import {
  getPercentageRoundingDown,
  sequenceColour,
} from '../../../util/index.js';
import {
  buildGridRowWithTotal,
  ChartDataType,
  IGridRow,
} from '../ChartReports.js';
import MainDrawer from '../MainDrawer.js';
import { options } from './chartConfig.js';
import { buildColumns } from './dataGridConfig.js';

ChartJS.register(
  ...registerables,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

interface StockOver90DaysReportProps {
  data: ListRegionStockStatsResponse;
}

const StockOver90DaysReport: FunctionComponent<StockOver90DaysReportProps> = ({
  data,
}) => {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const reportTitle = t('reports.stockOver90Days.title');

  const chartData: ChartDataType = useMemo(() => {
    return {
      labels: data.map((item) => item.regionName),
      datasets: [
        {
          label: t('reports.stockOver90Days.over90'),
          data: data.map((region) => region.amountOfVehiclesMore90Days),
          backgroundColor: sequenceColour(0),
        },
        {
          label: t('reports.stockOver90Days.under90'),
          data: data.map(
            (region) =>
              region.amountOfVehicles - region.amountOfVehiclesMore90Days,
          ),
          backgroundColor: sequenceColour(1),
        },
      ],
    };
  }, [data, t]);

  const gridRows = useMemo(() => {
    const regions: string[] = data.map((item) => item.regionName);

    const totalVehicles = buildGridRowWithTotal(
      t('reports.stockOver90Days.total'),
      'amountOfVehicles',
      regions,
      data,
    );
    const over90Days = buildGridRowWithTotal(
      t('reports.stockOver90Days.over90'),
      'amountOfVehiclesMore90Days',
      regions,
      data,
    );
    const avgDaysInStock = buildGridRowWithTotal(
      t('reports.stockOver90Days.average'),
      'averageDaysInStock',
      regions,
      data,
      'avg',
    );

    const resp = [totalVehicles, over90Days, avgDaysInStock];

    /**
     * Calculates percentage of vehicles over 90 days over total stock.
     * @returns A datagrid row with all calculated percentages.
     */
    const calculatedPercentageRow = (): IGridRow => {
      const obj: IGridRow = {
        title: t('reports.stockOver90Days.percentage'),
        total: '',
      };

      // Access to each region value, and do the math.
      for (const region in regions) {
        const regionName = regions[region];
        const totalVehiclesData = Number(resp[0][regionName]);
        const over90DaysData = Number(resp[1][regionName]);
        const avgValue = getPercentageRoundingDown(
          totalVehiclesData,
          over90DaysData,
        );
        obj[regionName] = avgValue || '-';
      }

      return obj;
    };

    resp.push(calculatedPercentageRow());
    return resp;
  }, [data, t]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleOpen}>
              <Info />
            </IconButton>
          }
          title={reportTitle}
        />
        <CardContent>
          {data.length === 0 ? (
            <EmptyState />
          ) : (
            <Bar data={chartData} options={options} />
          )}
        </CardContent>
      </Card>
      <MainDrawer onClose={handleClose} open={open} title={reportTitle}>
        <DataGrid
          autoHeight
          columns={buildColumns(data)}
          getRowId={(r) => r.title}
          hideFooter
          rows={gridRows}
        />
      </MainDrawer>
    </>
  );
};

export default React.memo(StockOver90DaysReport);
