import { ArrowBack } from '@mui/icons-material';
import {
  Box,
  Button,
  Divider,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { useAtomValue } from 'jotai';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate, useParams } from 'react-router-dom';
import { GetEventCountResponse } from '../../../../api/AnalyticsService/getEventCount/GetEventCountResponse.js';
import { AnalyticsEventType } from '../../../../api/util/AnalyticsEventType.js';
import { ListingLiveStatus } from '../../../../api/util/ListingLiveStatus.js';
import {
  ConfirmModal,
  ErrorState,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useToggle } from '../../../hooks/useToggle.js';
import { useArchiveVehicleListing } from '../../hooks/mutations/useArchiveVehicleListing.js';
import { useDeleteVehicleListing } from '../../hooks/mutations/useDeleteVehicleListing.js';
import { vehicleListingFiltersAtom } from '../../state/atoms.js';
import ReasonLabel from '../ReasonLabel.js';
import VehicleStatusLabel from '../VehicleStatusLabel.js';
import RelistButton from './RelistButton.js';

interface HeaderProps {
  title: string;
  subtitle: string;
  retailPrice: string;
  image?: string;
  isLive?: boolean;
  isError?: boolean;
  reason?: ListingLiveStatus[];
  otherPrices?: string[];
  readonly?: boolean;
  vehicleId: string;
  onArchive?: (vehicleListingId: string) => void;
  archivedMode?: boolean;
}

const Img = styled('img')<{ archived?: boolean }>(({ archived, theme }) => ({
  aspectRatio: '4/3',
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: 'contain',
  [theme.breakpoints.up('sm')]: {
    minWidth: 138,
    width: 240,
  },
  filter: archived ? 'grayscale(1)' : 'none',
  ':hover': {
    filter: 'none',
  },
}));

const ANALYTICS_EVENTS_TYPES = Object.values(AnalyticsEventType);

const Header: FunctionComponent<HeaderProps> = ({
  isLive,
  reason,
  subtitle,
  title,
  image,
  readonly,
  retailPrice,
  otherPrices,
  vehicleId,
  isError,
  onArchive,
  archivedMode,
}) => {
  const { t } = useTranslation();
  const { showMessage } = useMessageStore();
  const [isConfirmOpen, toggleConfirmOpen] = useToggle();
  const [isArchiveConfirmOpen, toggleArchiveConfirmOpen] = useToggle();
  const navigate = useNavigate();
  const searchParams = useAtomValue(vehicleListingFiltersAtom);
  const [analyticsState, setAnalyticsState] =
    useAsyncState<GetEventCountResponse>();
  const api = useApiClient();
  const { brand, dealer } = useParams();
  const { currentBrand } = useBrand();
  const {
    mutateAsync: deleteVehicleListing,
    isPending,
    isError: deleteError,
  } = useDeleteVehicleListing();
  const { mutateAsync: archiveListing, isPending: archiving } =
    useArchiveVehicleListing();

  const getAnalytics = useCallback(() => {
    setAnalyticsState(async () => {
      const analytics = await api.analytics.getEventCount({
        vehicleId,
      });
      return analytics;
    });
  }, [vehicleId]);

  useEffect(() => {
    if (deleteError) {
      toggleConfirmOpen(false);
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [deleteError, showMessage, t, toggleConfirmOpen]);

  useEffect(() => {
    getAnalytics();
  }, [getAnalytics, vehicleId]);

  const removeVehicleListing = useCallback(async () => {
    await deleteVehicleListing({ vehicleId });

    toggleConfirmOpen(false);
    showMessage({
      text: t('pages.vehiclePage.header.removeSuccess'),
      severity: 'success',
      dismissible: true,
      duration: 3000,
    });
    navigate(`/${brand}/dealers/${dealer}/dashboard`);
  }, [
    brand,
    dealer,
    deleteVehicleListing,
    navigate,
    showMessage,
    t,
    toggleConfirmOpen,
    vehicleId,
  ]);

  const showRemoveModal = useCallback(
    () => toggleConfirmOpen(true),
    [toggleConfirmOpen],
  );

  const showArchiveModal = useCallback(
    () => toggleArchiveConfirmOpen(true),
    [toggleArchiveConfirmOpen],
  );

  const archiveVehicleListing = useCallback(async () => {
    if (!currentBrand || !dealer) {
      return;
    }
    await archiveListing({
      id: vehicleId,
      brand: currentBrand,
      dealerId: parseInt(dealer),
    });
    onArchive && onArchive(vehicleId);
    toggleArchiveConfirmOpen();
  }, [
    currentBrand,
    dealer,
    archiveListing,
    vehicleId,
    onArchive,
    toggleArchiveConfirmOpen,
  ]);

  return (
    <Stack>
      <div>
        <Button
          onClick={() => {
            navigate(
              {
                pathname: `../dashboard${archivedMode ? '/archived' : ''}`,
                search: searchParams.searchParams?.toString(),
              },
              { state: { vehicleId: vehicleId } },
            );
          }}
          size="small"
          startIcon={<ArrowBack />}
          variant="text"
        >
          {t('back')}
        </Button>
      </div>
      <Stack
        direction={{
          xs: 'column-reverse',
          sm: 'row',
        }}
        justifyContent="space-between"
        spacing={2}
      >
        <Stack justifyContent="space-between" spacing={2}>
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography variant="title">{title}</Typography>
            {archivedMode ? (
              <ReasonLabel isError={isError} reason={t('archived')} />
            ) : isLive ? (
              <VehicleStatusLabel visible />
            ) : (
              reason?.map((r) => <ReasonLabel isError={isError} reason={r} />)
            )}
          </Stack>
          <Stack>
            <Typography variant="subtitle3">{subtitle}</Typography>
            <Typography variant="subtitle3">{retailPrice}</Typography>
            <Typography color="grey.600" variant="body2">{`${(
              otherPrices || []
            ).join(' | ')}`}</Typography>

            <Box display="flex" justifyContent="left" mt={2}>
              {!!analyticsState.error ? (
                <ErrorState />
              ) : analyticsState.loading ? (
                <Skeleton height={30} variant="rounded" width="100%" />
              ) : (
                <Stack
                  alignItems="center"
                  direction="row"
                  divider={
                    <Divider
                      color="#BBCAD6"
                      flexItem
                      orientation="vertical"
                      sx={{
                        borderWidth: 1,
                      }}
                      variant="middle"
                    />
                  }
                  spacing={1}
                >
                  {ANALYTICS_EVENTS_TYPES.map(
                    (event) =>
                      analyticsState.value &&
                      analyticsState.value[event] && (
                        <Typography
                          color="#4B5768"
                          fontWeight="Bold"
                          key={event}
                          variant="cell"
                        >
                          {t(`pages.vehiclePage.header.${event}`, {
                            count: analyticsState.value[event],
                          })}
                        </Typography>
                      ),
                  )}
                </Stack>
              )}
            </Box>
          </Stack>
          <Stack
            direction={{
              xs: 'column',
              sm: 'row',
            }}
            minHeight={40}
            spacing={2}
          >
            {!readonly && (
              <Button
                color="primary"
                onClick={showRemoveModal}
                size="small"
                type="button"
                variant="outlined"
              >
                {t('pages.vehiclePage.header.removeVehicle')}
              </Button>
            )}
            {!readonly && (
              <Button
                color="primary"
                onClick={showArchiveModal}
                size="small"
                type="button"
                variant="outlined"
              >
                {t('pages.remarketingVehiclePage.header.archiveVehicle')}
              </Button>
            )}
            {archivedMode && <RelistButton vehicleListingId={vehicleId} />}
          </Stack>
        </Stack>
        <Img src={image} archived={archivedMode} />
        <ConfirmModal
          busy={isPending}
          fullWidth
          maxWidth="xs"
          onClose={toggleConfirmOpen}
          onConfirm={removeVehicleListing}
          open={isConfirmOpen}
          title={t('pages.vehiclePage.header.removeVehicle')}
        >
          <Typography variant="body2">
            {t('pages.vehiclePage.header.removeWarning')}
          </Typography>
        </ConfirmModal>
        <ConfirmModal
          busy={archiving}
          cancelText={t('close')}
          fullWidth
          maxWidth="xs"
          onClose={toggleArchiveConfirmOpen}
          onConfirm={archiveVehicleListing}
          open={isArchiveConfirmOpen}
          title={t('pages.remarketingVehiclePage.header.archiveVehicle')}
        >
          <Typography variant="body2">
            {t('pages.remarketingVehiclePage.header.archiveWarning')}
          </Typography>
        </ConfirmModal>
      </Stack>
    </Stack>
  );
};

export default Header;
