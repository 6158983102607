import { Box, MenuItem, Select, Skeleton } from '@mui/material';
import i18next, { t } from 'i18next';
import { useEffect, useState } from 'react';
import { useBrand } from '../../hooks/useBrand.js';
import { Language, sx } from '../../util/index.js';

export const styles = sx({
  container: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    gap: 1,
  },
  select: {
    width: '100%',
    minWidth: '120px',
  },
});

const LanguageSelector = () => {
  const [language, setLanguage] = useState(i18next.language);
  const { brandConfig, isLoading } = useBrand();

  useEffect(() => {
    i18next.changeLanguage(language).catch((e) => {
      console.error(e);
    });
  }, [language]);

  return (
    <Box sx={styles.container}>
      {isLoading ? (
        <Skeleton sx={styles.select} />
      ) : (
        (brandConfig?.availableLanguages || []).length > 0 && (
          <Select
            onChange={(e) => setLanguage(e.target.value as string)}
            sx={styles.select}
            value={language}
            variant="standard"
          >
            <MenuItem value={Language.en_gb}>{t(`languages.en_gb`)}</MenuItem>
            {brandConfig?.availableLanguages!.map((language) => (
              <MenuItem key={language} value={language}>
                {t(`languages.${language.toLowerCase().replace('-', '_')}`)}
              </MenuItem>
            ))}
          </Select>
        )
      )}
    </Box>
  );
};

export default LanguageSelector;
