import { useQueryClient } from '@tanstack/react-query';
import { RemarketingVehicleEvent } from '../../../../api/util/RemarketingVehicleEvent.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { makeCountRemarketingForAdminQueryKey } from './useCountRemarketingVehiclesForAdmin.js';
import {
  ListRemarketingVehiclesForAdminData,
  makeListRemarketingForAdminQueryKey,
} from './useListRemarketingVehiclesForAdmin.js';

export interface UseUpdateRemarketingUserListInput {
  newEventIncoming: RemarketingVehicleEvent;
}

export const useUpdateRemarketingAdminListItem = (): ((
  input: UseUpdateRemarketingUserListInput,
) => void) => {
  const queryClient = useQueryClient();
  const { currentBrand } = useBrand();

  const updateFunction = ({
    newEventIncoming,
  }: UseUpdateRemarketingUserListInput): void => {
    if (!currentBrand) {
      return;
    }

    queryClient.setQueriesData<ListRemarketingVehiclesForAdminData>(
      { queryKey: makeListRemarketingForAdminQueryKey(currentBrand) },
      (data) => {
        if (data) {
          return {
            ...data,
            pages: data.pages.map((p) => ({
              ...p,
              items: p.items.map((i) => {
                if (i.id === newEventIncoming.id) {
                  if (
                    i.status !== newEventIncoming.status ||
                    i.archived !== newEventIncoming.archived
                  ) {
                    void queryClient.refetchQueries({
                      queryKey:
                        makeCountRemarketingForAdminQueryKey(currentBrand),
                    });
                  }

                  return {
                    ...i,
                    ...newEventIncoming,
                    updated: Date.now(),
                  };
                }
                return i;
              }),
            })),
          };
        }
      },
    );
  };

  return updateFunction;
};
