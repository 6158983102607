import { object, text } from '@fmtk/decoders';

export interface UserAuthResponse {
  auth: string;
  userData: string;
}

export const decodeUserAuthResponse = object<UserAuthResponse>({
  auth: text,
  userData: text,
});
