import React, { PropsWithChildren, useEffect, useMemo } from 'react';
import { ListCurrenciesResponse } from '../../api/VehicleListingService/listCurrencies/ListCurrenciesResponse.js';
import { Currency } from '../../api/util/Currency.js';
import { Option } from '../util/Option.js';
import { useApiClient } from './useApiClient.js';
import { useAsyncState } from './useAsyncState.js';
import { useContextWithError } from './useContextWithError.js';

export interface CurrencyContext {
  currencyOptions: Option[];
  value: Currency[];
  loading?: boolean;
  error?: unknown;
}

const currencyContext = React.createContext<CurrencyContext | undefined>(
  undefined,
);

export const CurrencyProvider = ({
  children,
}: PropsWithChildren): JSX.Element => {
  const [currencyCodes, setCurrencyCodes] =
    useAsyncState<ListCurrenciesResponse>();
  const api = useApiClient();

  const currencyOptions = useMemo(() => {
    if (!currencyCodes.value) {
      return [];
    }
    return currencyCodes.value.map((c) => ({ text: c.code, value: c.code }));
  }, [currencyCodes]);

  useEffect(() => {
    setCurrencyCodes(async () => await api.vehicleListing.listCurrencies());
  }, [api.vehicleListing, setCurrencyCodes]);

  return React.createElement(currencyContext.Provider, {
    children,
    value: {
      currencyOptions,
      value: currencyCodes.value || [],
      loading: currencyCodes.loading,
      error: currencyCodes.error,
    },
  });
};

export const useCurrency = (): CurrencyContext => {
  return useContextWithError(currencyContext, 'currency');
};
