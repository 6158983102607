import { array, boolean, maybe, number, object, text } from '@fmtk/decoders';

export interface UserBiddingHistory {
  id: string;
  timestamp: number;
  userName: string;
  sameUser: boolean;
  sameDealer: boolean;
  dealerName: string;
  bid: number;
  leading: boolean;
}

export interface UserListBiddingHistoryResponse {
  items: UserBiddingHistory[];
  nextToken?: string;
}

export const decodeUserBiddingHistory = object<UserBiddingHistory>({
  id: text,
  bid: number,
  sameDealer: boolean,
  sameUser: boolean,
  timestamp: number,
  userName: text,
  dealerName: text,
  leading: boolean,
});

export const decodeUserListBiddingHistoryResponse =
  object<UserListBiddingHistoryResponse>({
    items: array(decodeUserBiddingHistory),
    nextToken: maybe(text),
  });
