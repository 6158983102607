import { prefixKeyValue } from './util/prefixKeyValue.js';

export type DbKeyValue = Record<string, string>;

export type DbKeyPair = [string | string[], string | string[]];
export type DbKeyPairDeserialized = [string[], string[]];

export type DbQueryPair =
  | [string | string[], undefined | string | (string | undefined)[]]
  | [string | string[]];

export interface RangeQuery {
  pk: string | string[];
  fromSk?: string | string[];
  toSk?: string | string[];
}

export class DbIndexValue {
  constructor(
    public readonly index: DbIndex,
    public readonly value: DbKeyPair,
  ) {}

  public serialize(): DbKeyValue {
    const [pk, sk] = makeKey(this.value);
    return {
      [this.index.pkField]: pk,
      [this.index.skField]: sk,
    };
  }
}

export class DbIndexQuery {
  constructor(
    public readonly index: DbIndex,
    public readonly value: DbQueryPair,
  ) {}
}

export class DbIndex {
  public constructor(
    public readonly name: string | undefined,
    public readonly pkField: string,
    public readonly skField: string,
  ) {}

  public deserialize(doc: DbKeyValue): DbKeyPairDeserialized | undefined {
    const pk = doc[this.pkField];
    const sk = doc[this.skField];

    if (pk === undefined || sk === undefined) {
      return;
    }
    return [pk.split(':'), sk.split(':')];
  }

  public query(pair: DbQueryPair): DbIndexQuery {
    return new DbIndexQuery(this, pair);
  }

  public serialize(pair: DbKeyPair): DbKeyValue {
    return this.value(pair).serialize();
  }

  public value(pair: DbKeyPair): DbIndexValue {
    return new DbIndexValue(this, pair);
  }
}

export function makeKey([pk, sk]: DbKeyPair): [string, string] {
  return [normaliseKey(pk), normaliseKey(sk)];
}

export function makeQueryKey([pk, sk]: DbQueryPair): [
  string,
  string | undefined,
] {
  return [normaliseKey(pk), normaliseKey(sk, true)];
}

export function normaliseKey(value: string | string[]): string;
export function normaliseKey(
  value: string | undefined | (string | undefined)[],
  allowEmpty?: boolean,
): string | undefined;
export function normaliseKey(
  value: string | undefined | (string | undefined)[],
  allowEmpty?: boolean,
): string | undefined {
  if (!value) {
    if (!allowEmpty) {
      throw new Error('key must have value');
    }
    return;
  }
  return typeof value === 'string' ? value : prefixKeyValue(value, allowEmpty);
}
