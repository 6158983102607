import { alpha, InputBase, InputProps, styled } from '@mui/material';
import React, { useCallback } from 'react';

export interface TextInputProps
  extends React.InputHTMLAttributes<HTMLInputElement> {
  error?: boolean;
  onValueChange?: (value: string) => void;
  password?: boolean;
  endAdornment?: InputProps['endAdornment'];
  startAdornment?: InputProps['startAdornment'];
  fullWidth?: InputProps['fullWidth'];
  multiline?: InputProps['multiline'];
  rows?: InputProps['rows'];
}

const CustomInput = styled(InputBase)(({ theme }) => ({
  border: '2px solid #BDC7D3',
  borderRadius: 4,
  'label + &': {
    marginTop: theme.spacing(0.5),
    transition: theme.transitions.create([
      'border-color',
      'background-color',
      'box-shadow',
    ]),
    '&.Mui-error': {
      border: `2px solid ${theme.palette.error.main}`,
      borderRadius: 4,
      '&.Mui-focused': {
        boxShadow: `${alpha(theme.palette.error.main, 0.25)} 0 0 0 0.1rem`,
        borderColor: theme.palette.error.main,
      },
    },
    '&.Mui-focused': {
      borderRadius: 4,
      borderColor: theme.palette.primary.main,
    },
  },
  '& .MuiInputBase-input': {
    position: 'relative',
    backgroundColor:
      theme.palette.mode === 'light' ? '#FFFFFF' : theme.palette.grey[700],
    fontSize: theme.typography.pxToRem(14),
    lineHeight: theme.typography.pxToRem(22),
    padding: '8px 10px',
    color:
      theme.palette.mode === 'light'
        ? '#637381'
        : theme.palette.getContrastText(theme.palette.grey[700]),
    '&::placeholder': {
      opacity: 0.8, // otherwise firefox shows a lighter color
    },
  },
  '&.MuiInputBase-adornedStart': {
    paddingLeft: '12px',
  },
  '&.MuiInputBase-adornedEnd': {
    paddingRight: '12px',
  },
}));

export const TextInput = React.forwardRef<HTMLInputElement, TextInputProps>(
  (
    {
      className,
      error,
      onValueChange,
      password,
      type,
      endAdornment,
      startAdornment,
      fullWidth,
      multiline,
      rows,
      disabled,
      ...rest
    }: TextInputProps,
    ref,
  ) => {
    const handleChange = useCallback(
      (e: React.ChangeEvent<HTMLInputElement>) => {
        if (onValueChange) {
          onValueChange(e.target.value);
        }
      },
      [onValueChange],
    );

    return (
      <CustomInput
        disabled={disabled}
        endAdornment={endAdornment}
        error={error}
        fullWidth={fullWidth}
        id="outlined-required"
        inputProps={rest}
        inputRef={ref}
        multiline={multiline}
        onChange={handleChange}
        rows={rows}
        startAdornment={startAdornment}
        type={type ?? (password ? 'password' : 'text')}
      />
    );
  },
);
