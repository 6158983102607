import { Close } from '@mui/icons-material';
import {
  Button,
  Container,
  Dialog,
  Slide,
  Stack,
  Typography,
} from '@mui/material';
import { t } from 'i18next';
import React, { FunctionComponent, PropsWithChildren, ReactNode } from 'react';
import { TransitionProps } from 'react-transition-group/Transition.js';

export interface ImageDialogProps extends PropsWithChildren {
  open?: boolean;
  onClose?: () => void;
  label?: string | ReactNode;
}

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & {
    children: React.ReactElement;
  },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />;
});

const ImageDialog: FunctionComponent<ImageDialogProps> = ({
  open,
  onClose,

  label,
  children,
}) => {
  return (
    <Dialog
      TransitionComponent={Transition}
      fullScreen
      onClose={onClose}
      open={!!open}
    >
      <Container maxWidth="xl">
        <Stack
          mt={{
            xs: 4,
            sm: 6,
          }}
          mx={{
            xs: 0,
            sm: 3,
          }}
          spacing={2}
        >
          <Stack direction="row" justifyContent="space-around" spacing={2}>
            <Stack alignItems="center" direction="row" flex={1} spacing={3}>
              {(label && typeof label === 'string' && (
                <Typography variant="h4">{label}</Typography>
              )) ||
                label}
            </Stack>
            <Button
              endIcon={<Close />}
              onClick={onClose}
              size="large"
              variant="text"
            >
              {t('close')}
            </Button>
          </Stack>
          {children}
        </Stack>
      </Container>
    </Dialog>
  );
};

export default ImageDialog;
