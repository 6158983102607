import {
  ExtraFields,
  enumValue,
  maybe,
  number,
  object,
  record,
  string,
} from '@fmtk/decoders';

export enum VumoConfigurationType {
  Interior = 'INTERIOR',
  Exterior = 'EXTERIOR',
}

export interface VumoNameDescription {
  description?: string;
  name?: string;
}

export const decodeVumoNameDescription = object<VumoNameDescription>(
  {
    description: maybe(string),
    name: maybe(string),
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);

export interface UIConfiguration {
  nameDescriptions?: Record<string, VumoNameDescription>;
  thumbnailUrl?: string;
  thumbnailFullPath?: string;
}

export const decodeUIConfiguration = object<UIConfiguration>(
  {
    nameDescriptions: maybe(record(string, decodeVumoNameDescription)),
    thumbnailUrl: maybe(string),
    thumbnailFullPath: maybe(string),
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);

export interface VumoConfiguration {
  cost?: number;
  company?: string;
  configurationType?: VumoConfigurationType;
  createdBy?: string;
  createdDate?: number;
  lastModifiedBy?: string;
  lastModifiedDate?: number;
  name: string;
  uiConfiguration?: UIConfiguration;
}

export const decodeVumoConfiguration = object<VumoConfiguration>(
  {
    cost: maybe(number),
    company: maybe(string),
    configurationType: maybe(enumValue(VumoConfigurationType)),
    createdBy: maybe(string),
    createdDate: maybe(number),
    lastModifiedBy: maybe(string),
    lastModifiedDate: maybe(number),
    name: string,
    uiConfiguration: maybe(decodeUIConfiguration),
  },
  {
    extraFields: ExtraFields.Ignore,
  },
);
