import { array, number, object, text } from '@fmtk/decoders';

export interface RegionCountry {
  regionId: number;
  regionName: string;
  countryCode: string;
  countryName: string;
}

export const decodeLRegionCountry = object<RegionCountry>({
  regionId: number,
  regionName: text,
  countryCode: text,
  countryName: text,
});

export type ListRegionCountryResponse = RegionCountry[];

export const decodeListRegionCountryResponse = array(decodeLRegionCountry);
