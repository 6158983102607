import { array, object, string } from '@fmtk/decoders';

export type ListImageQcListingsRequest = {
  vehicleListingIds: string[];
};

export const decodeListImageQcListingsRequest =
  object<ListImageQcListingsRequest>({
    vehicleListingIds: array(string),
  });
