import { array, maybe } from '@fmtk/decoders';
import { Save } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import {
  Button,
  ButtonProps,
  Dialog,
  DialogContent,
  DialogTitle,
  Link,
  Stack,
  SxProps,
  Typography,
} from '@mui/material';
import { isEmpty } from 'lodash-es';
import {
  FunctionComponent,
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleFeatures } from '../../../../api/util/Vehicle.js';
import FormErrorsSummary from '../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../common-ui/components/LightTooltip.js';
import ChipList from '../../../common-ui/components/ListInput.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import {
  CellLabel,
  ConfirmModal,
  Form,
  TextInput,
  useMessageStore,
} from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCreateForm } from '../../../hooks/useCreateForm.js';
import { FeatureFlags } from '../../../util/BrandsConfig.js';
import {
  Feature,
  decodeFeature,
  listToVehicleFeatures,
} from '../../../util/vehicleTypes.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';

interface VehicleFeaturesEditorProps {
  readonly?: boolean;
  features?: VehicleFeatures;
  onSave?: (features?: VehicleFeatures) => void;
  onChange?: (features?: VehicleFeatures) => void;
  loading?: boolean;
  onCancel?: () => void;
  options?: {
    disabledIsDirty?: boolean;
    buttons?: {
      containerStyle?: SxProps;
      cancelButtonProps?: ButtonProps & { label?: string };
      acceptButtonProps?: LoadingButtonProps & { label?: string };
    };
  };
}

const VehicleFeaturesEditor: FunctionComponent<VehicleFeaturesEditorProps> = ({
  onSave,
  readonly,
  features,
  loading,
  options,
  onChange,
  onCancel,
}) => {
  const { t } = useTranslation();
  const { brandConfig } = useBrand();
  const [openNew, setOpenNew] = useState<boolean>(false);
  const [newCategoryName, setCategoryName] = useState<string>('');
  const { showMessage } = useMessageStore();
  const [resetConfirm, setResetConfirm] = useState(false);

  const form = useCreateForm(
    {
      features: maybe(array(decodeFeature)),
    },
    (values) => {
      if (!values.features?.length) {
        return;
      }
      const formattedFeatures = listToVehicleFeatures(values.features);

      onSave && onSave(formattedFeatures);
    },
    undefined,
    [features],
  );

  const defaultFeatures = useMemo(
    () =>
      brandConfig?.defaultFeatures?.reduce(
        (obj, str) => ({ ...obj, [str]: [] as string[] }),
        {} as Record<string, string[]>,
      ),
    [brandConfig],
  );

  const [formState, formBind] = form;

  const handleAdd = useCallback(() => {
    if (!newCategoryName) {
      return;
    }
    setCategoryName('');
    setOpenNew(false);

    formBind.setValue('features', (features: Feature[]) => {
      const newFeatures = [
        ...features,
        {
          category: newCategoryName,
          features: [],
        },
      ];

      onChange && onChange(listToVehicleFeatures(newFeatures));

      return newFeatures;
    });
  }, [formBind, newCategoryName, onChange]);

  const handleRemoveCategory = (category: string) => {
    removeCategory(category);
  };

  const removeCategory = useCallback(
    (category: string) => {
      formBind.setValue('features', (features: Feature[]) => {
        const newFeatures = features.filter((f) => f.category !== category);
        onChange && onChange(listToVehicleFeatures(newFeatures));
        return newFeatures;
      });
    },
    [formBind, onChange],
  );

  const handleCategoryNameChange = useCallback(
    (category: string, originalCategory: string) => {
      if (category === originalCategory) {
        return;
      }

      formBind.setValue('features', (features: Feature[] = []) => {
        const newFeatures = features.map((f) => {
          return {
            category: f.category === originalCategory ? category : f.category,
            features: f.features,
          };
        });
        onChange && onChange(listToVehicleFeatures(newFeatures));

        return newFeatures;
      });
    },
    [formBind, onChange],
  );

  const handleRemoveItem = useCallback(
    (category: string, value: string) => {
      formBind.setValue('features', (features: Feature[]) => {
        const newFeatures = features.map((f) =>
          f.category === category
            ? {
                ...f,
                features: f.features?.filter((v) => v !== value),
              }
            : f,
        );

        onChange && onChange(listToVehicleFeatures(newFeatures));

        return newFeatures;
      });
    },
    [formBind, onChange],
  );

  const handleCategoryName = useCallback((value: string) => {
    setCategoryName(value);
  }, []);

  const handleCancel = useCallback(() => {
    setOpenNew(false);
  }, []);

  const handleOnKeyDown = (
    event: React.KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>,
  ) => {
    if (event.key === 'Enter' && !!newCategoryName) {
      handleAdd();
    }
  };

  const handleChangeOrder = useCallback(
    (category: string, values: string[]) => {
      formBind.setValue('features', (features: Feature[]) => {
        const newFeatures = features.map((f) =>
          f.category === category
            ? { category: f.category, features: values }
            : f,
        );

        onChange && onChange(listToVehicleFeatures(newFeatures));

        return newFeatures;
      });
    },
    [formBind, onChange],
  );

  const handleAddItem = useCallback(
    (category: string, value: string) => {
      formBind.setValue('features', (features: Feature[] = []) => {
        const newFeatures = features.map((f) =>
          f.category === category
            ? { category: f.category, features: [...(f.features || []), value] }
            : f,
        );

        onChange && onChange(listToVehicleFeatures(newFeatures));

        return newFeatures;
      });
    },
    [formBind],
  );

  const handleResetConfirm = () => {
    resetForm();
    toggleResetConfirm();
    onCancel && onCancel();
  };

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const resetForm = useCallback(() => {
    const formatted = Object.entries(
      (isEmpty(features)
        ? defaultFeatures
        : features) as unknown as VehicleFeatures,
    ).map(([k, v]) => {
      return { category: k, features: v || [] };
    });

    formBind.reset({
      features: formatted,
    });
  }, [formBind, features, defaultFeatures]);

  const [hasErrors, isDirty] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;
    return [
      isDirty && Object.values(formState.errors).some((x) => !!x.length),
      isDirty,
    ];
  }, [formState]);

  const contentItems: ContentItem[] = useMemo(() => {
    const { features } = formState.values;

    if (!features) {
      return [];
    }

    return features.map(({ category }) => {
      const sectionElementRef = createRef<HTMLElement>();
      return {
        text: category,
        hash: category,
        sectionElementRef: sectionElementRef,
      };
    });
  }, [formState.values]);

  const showAddRemoveCategory = useMemo(() => {
    return brandConfig?.featureFlags?.includes(FeatureFlags.AddFeatureCategory);
  }, [brandConfig?.featureFlags]);

  useEffect(() => {
    if (formState.submitError) {
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    }
  }, [formState.submitError, showMessage, t]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      spacing={2}
    >
      <Stack flex={1}>
        <Form form={form}>
          <Section title={t('features')}>
            <Stack my={3} spacing={4}>
              {formState.values.features &&
                formState.values.features.map(({ category, features }, i) => {
                  return (
                    <div
                      id={contentItems[i].hash}
                      key={category}
                      ref={
                        contentItems[i]
                          .sectionElementRef as React.RefObject<HTMLDivElement>
                      }
                    >
                      <ChipList
                        addFeaturesPlaceholder={t(
                          'pages.vehiclePage.features.addFeaturesPlaceholder',
                        )}
                        canRemoveCategory={showAddRemoveCategory}
                        groupKey={category}
                        onAddItem={handleAddItem}
                        onCategoryNameChange={handleCategoryNameChange}
                        onOrderChange={handleChangeOrder}
                        onRemoveCategory={handleRemoveCategory}
                        onRemoveItem={handleRemoveItem}
                        readonly={readonly}
                        title={category}
                        values={features}
                      />
                    </div>
                  );
                })}
            </Stack>
            {!readonly && showAddRemoveCategory && (
              <div>
                <Link
                  color="primary.main"
                  onClick={() => setOpenNew(true)}
                  textTransform="none"
                  underline="always"
                  variant="button"
                >
                  {t('pages.vehiclePage.features.addCategory')}
                </Link>
              </div>
            )}
            {!readonly && (
              <Stack
                direction="row"
                justifyContent="end"
                p={1}
                spacing={1}
                sx={options?.buttons?.containerStyle}
              >
                <Button
                  disabled={
                    formState.busy ||
                    loading ||
                    (!isDirty && options?.disabledIsDirty)
                  }
                  onClick={() => {
                    if (isDirty) {
                      toggleResetConfirm();
                      return;
                    }
                    onCancel && onCancel();
                  }}
                  variant="outlined"
                  {...options?.buttons?.cancelButtonProps}
                >
                  {options?.buttons?.cancelButtonProps?.label
                    ? options?.buttons?.cancelButtonProps?.label
                    : t('cancel')}
                </Button>
                <LightTooltip title={hasErrors ? <FormErrorsSummary /> : ''}>
                  <span>
                    <LoadingButton
                      disabled={hasErrors}
                      endIcon={<Save />}
                      loading={formState.busy || loading}
                      loadingPosition="end"
                      type="submit"
                      variant="contained"
                      {...options?.buttons?.acceptButtonProps}
                    >
                      {options?.buttons?.acceptButtonProps?.label
                        ? options.buttons.acceptButtonProps.label
                        : t('save')}
                    </LoadingButton>
                  </span>
                </LightTooltip>
              </Stack>
            )}
          </Section>
        </Form>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-200}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <Dialog
        fullWidth
        maxWidth="sm"
        onAbort={handleCancel}
        onClose={handleAdd}
        open={openNew}
      >
        <DialogTitle>
          {t('pages.vehiclePage.features.addCategoryNameTitle')}
        </DialogTitle>
        <DialogContent>
          <Stack flex={1}>
            <CellLabel
              htmlFor="new-category-name"
              label={t('pages.vehiclePage.features.categoryName')}
            />
            <TextInput
              autoFocus
              fullWidth
              id="new-category-name"
              onKeyDown={handleOnKeyDown}
              onValueChange={handleCategoryName}
              placeholder={t(
                'pages.vehiclePage.features.categoryNamePlaceholder',
              )}
              readOnly={readonly}
              value={newCategoryName}
            />
          </Stack>
        </DialogContent>
        <Stack
          direction={{
            xs: 'column',
            sm: 'row-reverse',
          }}
          m={3}
          spacing={2}
        >
          <Button fullWidth onClick={handleAdd} variant="contained">
            {t('ok')}
          </Button>
          <Button
            color="inherit"
            fullWidth
            onClick={handleCancel}
            variant="outlined"
          >
            {t('cancel')}
          </Button>
        </Stack>
      </Dialog>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={handleResetConfirm}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default VehicleFeaturesEditor;
