import { DateTime, Interval } from 'luxon';

export const monthNames = [
  'Jan',
  'Feb',
  'Mar',
  'Apr',
  'May',
  'Jun',
  'Jul',
  'Aug',
  'Sep',
  'Oct',
  'Nov',
  'Dec',
];

/**
 * Converts date object into ISO date (without time)
 * E.g. YYYY-MM-DD
 * @param date (optional)
 * @returns Human-friendly string
 */
export const formatDateString = (date?: Date): string =>
  date ? DateTime.fromJSDate(date).toISODate() ?? '' : '';

export function splitDate(date: Date | undefined): {
  year?: number;
  month?: string;
  day?: number;
} {
  if (!date) {
    return {
      year: undefined,
      month: undefined,
      day: undefined,
    };
  }

  return {
    year: date.getFullYear(),
    month: monthNames[date.getMonth()],
    day: date.getDate(),
  };
}

export const daysSinceDate = (date?: Date): number | undefined => {
  if (!date) {
    return;
  }
  const interval = Interval.fromDateTimes(
    DateTime.fromJSDate(date),
    DateTime.now(),
  );
  const days = interval.length('days');
  return days && Math.trunc(days);
};

// Number of days since a date in milliseconds
export const daysSinceMillis = (millisecondsDate: number): number =>
  Math.floor(
    Math.abs(DateTime.fromMillis(millisecondsDate).diffNow(['days']).days),
  );
