import { assert, object, string } from '@fmtk/decoders';

export interface CloudinaryConfig {
  apiKey: string;
  apiSecret: string;
  cloudinaryBaseUrl: string;
  cloudinaryUrl: string;
  cloudName: string;
  defaultUploadPreset: string;
}

export const decodeCloudinaryConfig = object<CloudinaryConfig>({
  apiKey: string,
  apiSecret: string,
  cloudinaryBaseUrl: string,
  cloudinaryUrl: string,
  cloudName: string,
  defaultUploadPreset: string,
});

export const CloudinaryConfigVars = {
  apiKey: 'CLOUDINARY_API_KEY',
  apiUrl: 'CLOUDINARY_API_URL',
  apiSecret: 'CLOUDINARY_API_SECRET',
  cloudinaryBaseUrl: 'CLOUDINARY_BASE_URL',
  cloudinaryUrl: 'CLOUDINARY_CLOUDINARY_URL',
  cloudName: 'CLOUDINARY_CLOUD_NAME',
  defaultUploadPreset: 'CLOUDINARY_DEFAULT_UPLOAD_PRESET',
};

export function loadCloudinaryConfig(): CloudinaryConfig {
  // no detailed error as we don't want to risk logging client secret
  return assert(decodeCloudinaryConfig, {
    apiKey: process.env[CloudinaryConfigVars.apiKey],
    apiUrl: process.env[CloudinaryConfigVars.apiUrl],
    apiSecret: process.env[CloudinaryConfigVars.apiSecret],
    cloudinaryBaseUrl: process.env[CloudinaryConfigVars.cloudinaryBaseUrl],
    cloudinaryUrl: process.env[CloudinaryConfigVars.cloudinaryUrl],
    cloudName: process.env[CloudinaryConfigVars.cloudName],
    defaultUploadPreset: process.env[CloudinaryConfigVars.defaultUploadPreset],
  });
}
