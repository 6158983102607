import { decodeInAppNotification } from '../util/InAppNotification.js';
import { method, service } from '../util/ServiceDef.js';
import { UserPermissions } from '../util/UserPermissions.js';
import { none } from '../util/index.js';
import { decodeDeleteUserNotificationRequest } from './deleteUserNotification/DeleteUserNotificationRequest.js';
import { decodeListUserNotificationsRequest } from './listUserNotifications/ListUserNotificationsRequest.js';
import { decodeListUserNotificationsResponse } from './listUserNotifications/ListUserNotificationsResponse.js';
import { decodeMarkNotificationAsReadRequest } from './markNotificationAsRead/MarkNotificationAsReadRequest.js';

export const InAppNotificationsServiceDef = service({
  deleteAllUserNotifications: method(none, none, [UserPermissions.Basic]),
  deleteUserNotification: method(
    decodeDeleteUserNotificationRequest,
    decodeInAppNotification,
    [UserPermissions.Basic],
  ),
  listUserNotifications: method(
    decodeListUserNotificationsRequest,
    decodeListUserNotificationsResponse,
    [UserPermissions.Basic],
  ),
  markAllNotificationsAsRead: method(none, none, [UserPermissions.Basic]),
  markNotificationAsRead: method(
    decodeMarkNotificationAsReadRequest,
    decodeInAppNotification,
    [UserPermissions.Basic],
  ),
});

export type InAppNotificationsServiceDef = typeof InAppNotificationsServiceDef;
