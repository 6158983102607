import { LoadingButton } from '@mui/lab';
import {
  Button,
  Checkbox,
  Divider,
  FormControlLabel,
  Skeleton,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { NotificationSettings as NotificationSettingsType } from '../../../../api/util/UserSettings.js';
import { EmptyState, ErrorState } from '../../../common-ui/index.js';
import { useUpdateUserSettings } from '../../hooks/mutations/useUpdateUserSettings.js';
import { useUserSettings } from '../../hooks/queries/useUserSettings.js';

interface NotificationSettingsProps {
  onCancel: () => void;
}

const NotificationSettings: FunctionComponent<NotificationSettingsProps> = ({
  onCancel,
}): JSX.Element => {
  const { t } = useTranslation();
  const { data: userSettings, isLoading, isError } = useUserSettings();
  const [settings, setSettings] = useState<NotificationSettingsType>();
  const { mutateAsync: update, isPending } = useUpdateUserSettings();

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement>,
    key: keyof NotificationSettingsType,
  ) => {
    setSettings((state) => {
      return {
        ...state,
        [key]: !event.target.checked,
      };
    });
  };

  const handleUpdate = async () => {
    if (!userSettings) {
      return;
    }

    await update({
      userId: userSettings?.userId,
      notifications: settings,
    });
    onCancel();
  };

  useEffect(() => {
    setSettings(userSettings?.notifications);
  }, [userSettings]);

  if (isError) {
    return (
      <Stack pl={2} pr={5}>
        <ErrorState />
      </Stack>
    );
  }

  return (
    <Stack pl={2} pr={5}>
      <Stack direction="row" justifyContent="space-between">
        <Stack spacing={0.5}>
          <Typography variant="title">{t('notifications.settings')}</Typography>
          <Typography color="grey.600" fontWeight="fontWeightRegular">
            {t('notifications.subtitle')}
          </Typography>
        </Stack>
      </Stack>
      <Stack pt={2}>
        <Divider />
        <Stack divider={<Divider />}>
          {isLoading ? (
            Array(2)
              .fill(0)
              .map((_, i) => <Skeleton height={38} key={i} />)
          ) : !!settings ? (
            Object.entries(settings).map(([k, v], idx) => (
              <Stack key={idx}>
                <FormControlLabel
                  control={
                    <Checkbox
                      checked={!v}
                      onChange={(e) => {
                        handleChange(e, k as keyof NotificationSettingsType);
                      }}
                      size="small"
                    />
                  }
                  label={t(`notifications.${k}`)}
                  slotProps={{
                    typography: {
                      color: 'grey.600',
                      fontWeight: 'fontWeightMedium',
                    },
                  }}
                />
              </Stack>
            ))
          ) : (
            <EmptyState />
          )}
        </Stack>
      </Stack>
      <Divider />
      <Stack direction="row" justifyContent="end" pt={2} spacing={2}>
        <Button onClick={onCancel} variant="outlined">
          {t('cancel')}
        </Button>
        <LoadingButton
          loading={isPending}
          onClick={() => void handleUpdate()}
          variant="contained"
        >
          {t('confirm')}
        </LoadingButton>
      </Stack>
    </Stack>
  );
};

export default NotificationSettings;
