import { HttpClientMiddleware } from './HttpClient.js';
import { HttpHeaders } from './HttpHeaders.js';
import { composeMiddleware } from './Middleware.js';

export function jsonRequest(): HttpClientMiddleware {
  return (client) => async (req) => {
    if (req.body !== undefined && typeof req.body !== 'string') {
      const headers = HttpHeaders.wrap(req.headers ?? {});
      const contentType = headers.parseContentType()?.type;
      if (!contentType || contentType === 'application/json') {
        req = {
          ...req,
          body: JSON.stringify(req.body),
          headers:
            contentType === 'application/json'
              ? headers
              : new HttpHeaders(
                  { 'content-type': 'application/json' },
                  headers,
                ),
        };
      }
    }
    return await client(req);
  };
}

export function jsonResponse(): HttpClientMiddleware {
  return (client) => async (req) => {
    const response = await client({
      ...req,
      headers: new HttpHeaders(
        {
          accept: 'application/json',
        },
        req.headers,
      ),
    });

    if (!response.body || typeof response.body !== 'string') {
      return response;
    }
    return {
      ...response,
      body: JSON.parse(response.body),
    };
  };
}

export function jsonClient(): HttpClientMiddleware {
  return composeMiddleware([jsonRequest(), jsonResponse()]);
}
