import { Skeleton, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

export interface SectionSkeletonProps {
  rows?: number;
  columns?: number;
}

const SectionSkeleton: FunctionComponent<SectionSkeletonProps> = ({
  rows = 4,
  columns = 2,
}) => {
  return (
    <Stack
      direction={{
        xs: 'column-reverse',
        sm: 'row',
      }}
      justifyContent="space-between"
      mt={4}
      spacing={2}
    >
      <Stack
        bgcolor="background.paper"
        borderRadius={1}
        flex={1}
        p={3}
        spacing={4}
      >
        <Skeleton height={40} variant="text" width={200} />
        <Stack direction="row" flex={1} spacing={4}>
          {Array(columns)
            .fill(0)
            .map((_, i) => (
              <Stack flex={1} key={i}>
                {Array(rows)
                  .fill(0)
                  .map((_, r) => (
                    <Skeleton height={60} key={r} width="100%" />
                  ))}
              </Stack>
            ))}
        </Stack>
      </Stack>
    </Stack>
  );
};

export default SectionSkeleton;
