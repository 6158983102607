import { KeyboardArrowDown } from '@mui/icons-material';
import {
  InputBase,
  Select,
  SelectProps,
  selectClasses,
  styled,
} from '@mui/material';

const ITEM_HEIGHT = 33;
const ITEM_PADDING_TOP = 8;
const DefaultMenuProps = {
  PaperProps: {
    sx: {
      border: ` 2px solid #BDC7D3`,
      maxHeight: ITEM_HEIGHT * 6.5 + ITEM_PADDING_TOP,
      borderRadius: 0.5,
      boxShadow: `0px 8px 12px 0px rgba(0, 0, 0, 0.03)`,
    },
  },
};

const CustomInput = styled(InputBase)<SelectProps>(({ theme, color }) => ({
  borderRadius: '4px',
  padding: `4px 12px`,
  p: 0,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '14px',
  fontStyle: 'normal',
  fontWeight: 700,
  border: `2px solid ${
    color ? theme.palette[color].main : theme.palette.grey[500]
  }`,
  color: color ? theme.palette[color].main : theme.palette.grey[600],
}));

const CustomSelect = styled(Select)<SelectProps>(({ theme, color }) => ({
  padding: `6px 12px`,
  [`& .${selectClasses.select}`]: {
    padding: 0,
  },
  [`& .${selectClasses.icon}`]: {
    color: color === 'primary' ? theme.palette.primary.main : 'inherits',
  },
}));

export default function DropDownButton({ size, ...props }: SelectProps) {
  return (
    <CustomSelect
      IconComponent={(iconProps) => {
        return <KeyboardArrowDown {...iconProps} />;
      }}
      MenuProps={DefaultMenuProps}
      displayEmpty
      input={<CustomInput size={size} />}
      multiple
      size={size}
      {...props}
    />
  );
}
