import { Button, Typography } from '@mui/material';
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { UserAuctionRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { useBrand } from '../../../../../hooks/useBrand.js';
import { useDealer } from '../../../../../hooks/useDealer.js';
import { sx } from '../../../../../util/sx.js';
import BidModal from './BidModal.js';

export interface SubmitBidButtonProps {
  isLoading: boolean;
  onChange: () => void;
  vehicle: UserAuctionRemarketingVehicle;
  link?: boolean;
}

const styles = sx({
  link: {
    textDecoration: 'underline',
    cursor: 'pointer',
  },
});

export const SubmitBidButton = ({
  isLoading,
  onChange,
  vehicle,
  link,
}: SubmitBidButtonProps) => {
  const { currentBrand } = useBrand();
  const { currentDealer } = useDealer();
  const { t } = useTranslation();
  const [openBidModal, setOpenBidModal] = useState(false);

  const onClick = () => {
    onChange();
    setOpenBidModal(true);
  };

  if (!currentBrand || !currentDealer) {
    return null;
  }

  return (
    <>
      {link ? (
        <Typography
          color="#44546F"
          component="span"
          onClick={onClick}
          sx={styles.link}
          variant="subtitle2"
        >
          {t(`currentBidStatus.link.${vehicle.bidStatus}`)}
        </Typography>
      ) : (
        <Button onClick={onClick} variant="contained">
          {t('pages.remarketingVehiclePage.auction.form.actions.submitBid')}
        </Button>
      )}
      {openBidModal && (
        <BidModal
          brand={currentBrand}
          dealerId={Number(currentDealer.id)}
          isLoading={isLoading}
          onClose={() => setOpenBidModal(false)}
          onSubmit={() => onChange()}
          open={openBidModal}
          remarketingVehicle={vehicle}
        />
      )}
    </>
  );
};
