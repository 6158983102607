import { Avatar } from '@mui/material';
import { GridRenderCellParams } from '@mui/x-data-grid';
import { FunctionComponent } from 'react';
import { labelColor } from '../../util/colors.js';
import { initials } from '../../util/initials.js';

export const AvatarColumn: FunctionComponent<GridRenderCellParams<string>> = ({
  value,
  row,
}) => {
  const { fullName, givenName, familyName } = row;
  const fixedName = fullName || `${givenName} ${familyName}`;
  return (
    <Avatar
      alt={fixedName}
      src={value}
      sx={{
        bgcolor: labelColor(fixedName, 'avatar'),
        width: 36,
        height: 36,
        fontSize: '0.85rem',
        textDecoration: 'none',
      }}
    >
      {value || initials(fixedName)}
    </Avatar>
  );
};

export default AvatarColumn;
