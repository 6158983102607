import { enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import {
  decodeRemarketingVehicle,
  RemarketingVehicle,
} from '../../util/RemarketingVehicle.js';

export type UpdateRemarketingVehicleRequest = {
  brand: AvailableBrands;
  remarketingVehicle: RemarketingVehicle;
};

export const decodeUpdateRemarketingVehicleRequest =
  object<UpdateRemarketingVehicleRequest>({
    brand: enumValue(AvailableBrands),
    remarketingVehicle: decodeRemarketingVehicle,
  });
