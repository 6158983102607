import { maybe, object, string } from '@fmtk/decoders';

export interface Currency {
  code: string;
  name?: string;
  symbol?: string;
}
export const decodeCurrency = object<Currency>({
  code: string,
  name: maybe(string),
  symbol: maybe(string),
});
