import { enumValue, maybe, number, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { UserDocumentScope } from '../../util/UserDocument.js';

export interface ListUserDocumentsByReferenceRequest {
  brand: AvailableBrands;
  scope: UserDocumentScope;
  reference: string;
  dealerId?: number;
  nextToken?: string;
}

export const decodeListUserDocumentsByReferenceRequest =
  object<ListUserDocumentsByReferenceRequest>({
    brand: enumValue(AvailableBrands),
    scope: enumValue(UserDocumentScope),
    reference: string,
    dealerId: maybe(number),
    nextToken: maybe(string),
  });
