import { StyleSheet, Image } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';

const styles = StyleSheet.create({
  logoImg: {
    height: 33,
    width: 56,
  },
});

const Logo: FunctionComponent = () => {
  return (
    <Image src="https://res.cloudinary.com/motortrak/ims2/icons/bentley-ims-pdf-logo.jpg" style={styles.logoImg}/>
  );
};

export default Logo;
