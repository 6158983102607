import { FileDownload } from '@mui/icons-material';
import { CircularProgress, IconButton } from '@mui/material';
import { t } from 'i18next';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useInterval } from 'react-use';
import { AvailableBrands } from '../../../../api/util/index.js';
import {
  InventoryReportProcess,
  InventoryReportProcessStatus,
} from '../../../../api/util/InventoryReportProcess.js';
import {
  useApiClient,
  useAsyncState,
  useCurrentUser,
} from '../../../hooks/index.js';
import { downloadFromUrl } from '../../../util/downloadFile.js';
import SummaryCard from '../SummaryCard.js';

export interface RegionSummaryDownloadCardProps {
  brand: AvailableBrands;
}

const RegionSummaryDownloadCard: FunctionComponent<
  RegionSummaryDownloadCardProps
> = ({ brand }) => {
  const api = useApiClient();
  const user = useCurrentUser();
  const [downloadState, setDownloadState] = useAsyncState<void>();
  const [report, setReport] = useAsyncState<InventoryReportProcess>();
  const [processing, setProcessing] = useState<boolean>(false);
  const loading = downloadState.loading || report.loading;
  const error = downloadState.error || report.error;

  const handleDownload = useCallback(() => {
    if (loading || processing) {
      return;
    }

    if (
      report.value?.status === InventoryReportProcessStatus.Complete &&
      !!report.value.reportLink
    ) {
      setDownloadState(
        downloadFromUrl(
          report.value.reportLink,
          t('pages.dealerDashboard.stockList'),
          'csv',
        ),
      );
    } else {
      setDownloadState(async () => {
        await api.reporting.downloadInventoryRegionCsv({
          brand,
        });
        setProcessing(true);

        return;
      });
    }
  }, [api.reporting, brand, loading, processing, setDownloadState, report]);

  const loadInventoryReportProcess = useCallback(async () => {
    if (!brand || !user.value?.principalId) {
      return;
    }
    return await api.reporting.getInventoryReportProcess({
      brand,
      userId: user.value?.principalId,
    });
  }, [api.reporting, brand, user.value?.principalId]);

  useEffect(() => {
    setReport(loadInventoryReportProcess);
  }, [api.reporting, brand, loadInventoryReportProcess, setReport]);

  useEffect(() => {
    setProcessing(
      report.value?.status === InventoryReportProcessStatus.Creating,
    );
  }, [report.value?.status, setProcessing]);

  useInterval(
    () => {
      setReport(loadInventoryReportProcess);
    },
    processing ? 5000 : null,
  );

  return (
    <SummaryCard
      color="primary"
      error={!!error}
      icon={
        loading || processing ? (
          <CircularProgress size={40} />
        ) : (
          <IconButton color="primary" size="large">
            <FileDownload />
          </IconButton>
        )
      }
      onClick={handleDownload}
      subtitle={t('pages.dealerDashboard.globalInventory')}
      sx={{ cursor: 'pointer' }}
      title={t(
        processing
          ? 'pages.dealerDashboard.processing'
          : report.value?.status === InventoryReportProcessStatus.Complete &&
            report.value.reportLink
          ? 'download'
          : 'pages.dealerDashboard.stockList',
      )}
    />
  );
};

export default RegionSummaryDownloadCard;
