import { CompareArrows } from '@mui/icons-material';
import {
  alpha,
  Box,
  IconButton,
  MenuItem,
  Popover,
  Skeleton,
  styled,
} from '@mui/material';
import i18next from 'i18next';
import { FunctionComponent, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getBrandIdFromAvailableBrand } from '../../../../api/util/getAvailableBrandFromId.js';
import {
  AvailableBrands,
  getBrandDisplayName,
} from '../../../../api/util/index.js';
import { useBrand } from '../../../hooks/index.js';

const LogoStyle = styled('img')(() => ({
  height: 80,
}));

const ArrowStyle = styled('span')(({ theme }) => ({
  [theme.breakpoints.up('sm')]: {
    top: '17px',
    left: '-5px',
    zIndex: 1,
    width: 12,
    right: 20,
    height: 12,
    content: "''",
    position: 'absolute',
    borderRadius: '0 0 4px 0',
    transform: 'rotate(-135deg)',
    background: theme.palette.background.paper,
    borderRight: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
    borderTop: `solid 1px ${alpha(theme.palette.grey[500], 0.12)}`,
  },
}));

export const BrandSelector: FunctionComponent<{ root?: string }> = ({
  root = '',
}) => {
  const {
    currentBrand,
    userAvailableBrands,
    updateCurrentBrand,
    isLoading,
    brandConfig,
  } = useBrand();
  const navigate = useNavigate();
  const anchorRef = useRef(null);
  const [open, setOpen] = useState(false);

  const toggleMenu = () => {
    setOpen((state) => !state);
  };

  const handleChange = (brand: AvailableBrands) => {
    updateCurrentBrand!(brand);
    i18next.changeLanguage('en-GB').catch((e) => {
      console.error(e);
    });
    const brandId = getBrandIdFromAvailableBrand(brand);
    navigate(`/${brandId}${root && `/${root}`}`);
    setOpen(false);
  };

  return (
    <Box
      sx={{
        px: 2.5,
        py: 3,
        display: 'inline-flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        gap: 1,
      }}
    >
      {isLoading ? (
        <Box alignItems="center" display="flex" height={80} width={80}>
          <Skeleton height={70} variant="rounded" width={80} />
        </Box>
      ) : (
        <LogoStyle alt="LOGO" src={brandConfig?.logoUrl || '/logo.png'} />
      )}
      {userAvailableBrands.length > 1 && (
        <div>
          <div>
            <IconButton
              onClick={toggleMenu}
              ref={anchorRef}
              sx={{
                padding: 0,
                width: 44,
                height: 44,
                ...(open && {
                  bgcolor: (theme) =>
                    alpha(
                      theme.palette.primary.main,
                      theme.palette.action.focusOpacity,
                    ),
                }),
              }}
            >
              <CompareArrows />
            </IconButton>
            <Popover
              PaperProps={{
                sx: {
                  px: 1.5,
                  py: 0.5,
                  minWidth: 200,
                  overflow: 'inherit',
                  mx: 1,
                },
              }}
              anchorEl={anchorRef.current}
              anchorOrigin={{ horizontal: 'right', vertical: 'top' }}
              onClose={toggleMenu}
              open={open}
              transformOrigin={{ horizontal: 'left', vertical: 'top' }}
            >
              <ArrowStyle className="arrow" />
              {userAvailableBrands?.map((brand) => (
                <MenuItem
                  key={brand}
                  onClick={() => handleChange(brand)}
                  selected={currentBrand === brand}
                  sx={{
                    my: 0.5,
                    p: 1,
                    typography: 'body2',
                    borderRadius: 0.75,
                  }}
                >
                  {getBrandDisplayName(brand)}
                </MenuItem>
              ))}
            </Popover>
          </div>
        </div>
      )}
    </Box>
  );
};

export default BrandSelector;
