import { useAuth0 } from '@auth0/auth0-react';
import { Avatar, Box, Drawer, styled, Typography } from '@mui/material';
import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { Account, BrandSelector, Logout } from '../../common-ui/index.js';
import { useCurrentUser, useMobile } from '../../hooks/index.js';
import LanguageSelector from '../components/LanguageSelector.js';
import useNavConfig from '../hooks/useNavConfig.js';
import ContactSupportButton from './ContactSupport.js';
import NavSection from './NavSection.js';

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

interface DashboardSidebarProps {
  isOpenSidebar: boolean;
  onCloseSidebar: () => void;
}

export default function DashboardSidebar({
  isOpenSidebar,
  onCloseSidebar,
}: DashboardSidebarProps) {
  const { pathname } = useLocation();
  const isMobile = useMobile('lg');
  const { user } = useAuth0();
  const { navConfig } = useNavConfig();
  const { value } = useCurrentUser();

  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const account = {
    displayName: `${value?.givenName || ''} ${value?.familyName || ''}`,
    photoUrl: value?.avatarUrl || user?.picture,
  };

  const renderContent = (
    <>
      <BrandSelector />
      <Box sx={{ mb: 5, mx: 1 }}>
        <Account>
          <Avatar alt="photoURL" src={account.photoUrl} />
          <Box sx={{ ml: 2, width: 140 }}>
            <Typography
              sx={{
                color: 'text.primary',
              }}
              variant="subtitle2"
            >
              {account.displayName}
            </Typography>
          </Box>
          <Box sx={{ marginLeft: 'auto' }}>
            <Logout />
          </Box>
        </Account>
      </Box>
      <NavSection navConfig={navConfig} />
      <Box
        alignItems="center"
        display="flex"
        justifyContent="space-between"
        marginTop="auto"
        padding="8px"
        width="100%"
      >
        <LanguageSelector />
        <ContactSupportButton />
      </Box>
    </>
  );

  return (
    <RootStyle>
      {isMobile && (
        <Drawer
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
          onClose={onCloseSidebar}
          open={isOpenSidebar}
        >
          {renderContent}
        </Drawer>
      )}

      {!isMobile && (
        <Drawer
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: 'background.default',
              borderRightStyle: 'dashed',
            },
          }}
          open
          variant="persistent"
        >
          {renderContent}
        </Drawer>
      )}
    </RootStyle>
  );
}
