import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Outlet, matchPath, useLocation, useParams } from 'react-router-dom';
import { Dealer } from '../../../api/util/Dealer.js';
import { UserPermissions } from '../../../api/util/UserPermissions.js';
import { getAvailableBrandFromId } from '../../../api/util/getAvailableBrandFromId.js';
import { ErrorState, PageContainer } from '../../common-ui/index.js';
import { useApiClient } from '../../hooks/useApiClient.js';
import { useAsyncState } from '../../hooks/useAsyncState.js';
import { useCurrentUser } from '../../hooks/useCurrentUser.js';
import { useDealer } from '../../hooks/useDealer.js';
import ErrorPage from '../pages/ErrorPage.js';
import LoadingPage from './LoadingPage.js';

export const DealerChecker = (): JSX.Element => {
  const { dealer, brand } = useParams();
  const { updateCurrentDealer } = useDealer();
  const [wrongBrand, setWrongBrand] = useState<boolean>(false);
  const api = useApiClient();
  const { hasPermission, loading, error } = useCurrentUser();
  const { t } = useTranslation();
  const availableBrand = getAvailableBrandFromId(Number(brand));
  const hasBrand = hasPermission('Brand', availableBrand);
  const hasDealer = hasPermission('Dealer', dealer);
  const hasRemarketingDealer = hasPermission(
    UserPermissions.RemarketingDealer as UserPermissions,
    dealer,
  );
  const location = useLocation();
  const dealerRemarketingRoute = matchPath(
    { path: '/:brand/dealers/:dealer/remarketing/*', end: false },
    location.pathname,
  );
  const [dealers, setDealers] = useAsyncState<Dealer[]>();

  const loadDealer = useCallback(async () => {
    if (hasBrand && hasDealer) {
      const { data: response } = await api.dealer.listDealers({
        brand: availableBrand,
        dealerIds: [Number(dealer)],
        regionIds: [],
      });
      if (response.length === 1) {
        updateCurrentDealer(response[0]);
      } else {
        setWrongBrand(true);
        updateCurrentDealer(null);
      }
      return response;
    }
  }, [api, availableBrand, dealer, hasBrand, hasDealer, updateCurrentDealer]);

  useEffect(() => {
    setDealers(loadDealer);
  }, [loadDealer, setDealers]);

  if (loading || dealers.loading) {
    return <LoadingPage />;
  }

  if (error || dealers.error) {
    return <ErrorPage />;
  }

  if (
    wrongBrand ||
    !hasBrand ||
    !hasDealer ||
    (!!dealerRemarketingRoute && !hasRemarketingDealer)
  ) {
    return (
      <PageContainer>
        <ErrorState
          alertTitle={t('accessDenied')}
          error={t('accessDeniedMessage')}
        />
      </PageContainer>
    );
  }

  return <Outlet />;
};

export default DealerChecker;
