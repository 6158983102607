import { Image, StyleSheet, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';

interface HeaderLogoProps {
  logo: string;
  brand: AvailableBrands;
}

const styles = StyleSheet.create({
  headerImage: {
    height: 20,
    width: 85,
  },
  logo: {
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  brand: {
    marginTop: 4,
    textAlign: 'center',
    fontSize: 16,
    textTransform: 'uppercase',
    fontWeight: 'light',
  },
});

const HeaderLogo: FunctionComponent<HeaderLogoProps> = ({ logo, brand }) => {
  return (
    <View style={styles.logo}>
      <Image src={logo} style={styles.headerImage} />
    </View>
  );
};

export default HeaderLogo;
