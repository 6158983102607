import {
  closestCorners,
  DndContext,
  DragEndEvent,
  DragOverlay,
  DragStartEvent,
  KeyboardSensor,
  MouseSensor,
  TouchSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
} from '@dnd-kit/sortable';
import {
  Container,
  Grid,
  Link,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { Stack } from '@mui/system';
import { UploadApiResponse } from 'cloudinary';
import { uniqueId } from 'lodash';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleImage } from '../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import {
  MediaSeo,
  VehicleMediaStatus,
} from '../../../../api/util/VehicleMedia.js';
import VehicleImage from '../../../common-ui/components/VehicleImage/VehicleImage.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useCloudinary } from '../../../hooks/useCloudinary.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { Droppable } from './Droppable.js';
import { SortableItem } from './SortableItem.js';

const ReorderImagesSkeleton = (): JSX.Element => {
  return (
    <Stack spacing={2}>
      <Stack
        direction={{ xs: 'column', sm: 'row' }}
        justifyContent="space-between"
        spacing={1}
      >
        <Stack direction="row" spacing={1}>
          <Skeleton height={40} variant="rounded" width={100} />
          <Skeleton height={40} variant="rounded" width={100} />
          <Skeleton height={40} variant="rounded" width={100} />
        </Stack>
        <Stack direction="row" spacing={1}>
          <Skeleton height={40} variant="rounded" width={100} />
          <Skeleton height={40} variant="rounded" width={60} />
        </Stack>
      </Stack>
      <Grid container>
        {Array(12)
          .fill(0)
          .map((x, idx) => (
            <Grid item key={idx} md={2} sm={3} xs={6}>
              <Skeleton height={160} sx={{ m: 1 }} variant="rectangular" />
            </Grid>
          ))}
      </Grid>
      <Stack flexDirection="row-reverse">
        <Skeleton variant="text" width={160} />
      </Stack>
    </Stack>
  );
};

const ShotContainer = styled('div')`
  margin: 5px;
  cursor: grab;
  display: flex;
`;

const OverlayImage = styled('img')(({ theme }) => ({
  aspectRatio: '4/3',
  cursor: 'grabbing',
  borderRadius: theme.spacing(1),
  border: `1px solid ${theme.palette.grey[300]}`,
  objectFit: 'contain',
  opacity: 0.8,
  width: 180,
}));

const ReorderImages = ({
  maxPrimaryPosition,
  onChange,
  simplifiedMedia,
  onExpandImage,
  onFileChange,
  readonly,
  seo,
}: {
  maxPrimaryPosition: number;
  onChange: (updatedMedia: SimpleImage[]) => void;
  simplifiedMedia: SimpleImage[];
  onExpandImage?: (position: number) => void;
  onFileChange?: (file: UploadApiResponse, position: number) => void;
  readonly?: boolean;
  seo: MediaSeo;
}) => {
  const { brandConfig } = useBrand();
  const { getCloudinaryUrl } = useCloudinary();
  const { t } = useTranslation();
  const isMobile = useMobile();
  const [selectedImage, setSelectedImage] = useState<SimpleImage>();
  const [vehicleImages, setVehicleImages] = useState<SimpleImage[]>();
  const [clonedItems, setClonedItems] = useState<SimpleImage[]>();

  const sensors = useSensors(
    useSensor(MouseSensor, { activationConstraint: { distance: 5 } }),
    useSensor(TouchSensor, {
      activationConstraint: { delay: 50, tolerance: 10 },
    }),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    }),
  );

  const isPrimary = useCallback(
    (index: number) => {
      return index < maxPrimaryPosition;
    },
    [maxPrimaryPosition],
  );

  const handleDragStart = useCallback(
    (event: DragStartEvent) => {
      const { active } = event;

      if (!vehicleImages) {
        return;
      }

      setClonedItems(vehicleImages);

      const activeImage = vehicleImages?.find((x) => active?.id === x.id);
      const activeIndex = vehicleImages?.findIndex((x) => active?.id === x.id);

      if (!activeImage || activeIndex === -1) {
        return;
      }

      // if dragging a primary image we need to replace it with the ghost image
      if (isPrimary(activeIndex)) {
        const activeIndex = vehicleImages?.findIndex((x) => x.id === active.id);

        const ghostImage = brandConfig?.vehicleMedia?.primaryImages?.find(
          (x) => x.position === activeIndex + 1,
        );

        if (ghostImage && brandConfig?.noVehicleImage) {
          setVehicleImages((state) => {
            if (!state) {
              return state;
            }

            const newImages = [...state];
            newImages[activeIndex] = {
              ...activeImage,
              src:
                getCloudinaryUrl(ghostImage.placeHolderUrl) ||
                brandConfig.noVehicleImage ||
                '',
              isPlaceholder: true,
            };
            return newImages;
          });
        }
      }

      setSelectedImage({ ...activeImage });
    },
    [
      brandConfig?.noVehicleImage,
      brandConfig?.vehicleMedia?.primaryImages,
      getCloudinaryUrl,
      isPrimary,
      vehicleImages,
    ],
  );

  const handleDragEnd = useCallback(
    (event: DragEndEvent) => {
      const { over, active } = event;

      if (!vehicleImages || !selectedImage || !clonedItems) {
        return;
      }

      const updatedImages = [...vehicleImages];

      // Find the new and old positions based on the id
      const toIndex = vehicleImages.findIndex((x) => x.id === over?.id);
      const fromIndex = vehicleImages.findIndex((x) => x.id === active?.id);

      if (toIndex === -1 || fromIndex === -1) {
        return;
      }

      // if there was no change we need to replace the ghostImage with the original image
      if (toIndex === fromIndex) {
        updatedImages[toIndex] = selectedImage;
      } else if (
        //from primary to primary
        isPrimary(toIndex) &&
        isPrimary(fromIndex)
      ) {
        // there was an image in the new position so we swap the images
        if (!vehicleImages[toIndex].isPlaceholder) {
          updatedImages[fromIndex] = {
            ...clonedItems[toIndex],
            //lets keep the label
            label: clonedItems[fromIndex]?.label,
            status: VehicleMediaStatus.Draft,
            hasApproved: false,
            hasPending: false,
            hasRejected: false,
            hasError: false,
            hasProcessing: false,
          };
        } else {
          updatedImages[fromIndex] = {
            id: uniqueId(),
            isPlaceholder: true,
            media: undefined,
            label: clonedItems[fromIndex]?.label,
            src: getCloudinaryUrl(
              brandConfig?.vehicleMedia?.primaryImages?.find(
                (x) => x.position === fromIndex + 1,
              )?.placeHolderUrl,
            ),
            hasApproved: false,
            hasPending: false,
            hasRejected: false,
            hasError: false,
            hasProcessing: false,
          };
        }

        // we now place the image in the new position and set placeholder to false
        updatedImages[toIndex] = {
          ...clonedItems[fromIndex],
          //lets keep the label
          label: clonedItems[toIndex]?.label,
          isPlaceholder: false,
          status: VehicleMediaStatus.Draft,
          hasApproved: false,
          hasPending: false,
          hasRejected: false,
          hasError: false,
          hasProcessing: false,
        };
      } else if (
        // image moving from secondary to primary
        isPrimary(toIndex) &&
        !isPrimary(fromIndex)
      ) {
        // there was an image in the new position so we swap the images
        if (!vehicleImages[toIndex].isPlaceholder) {
          updatedImages[fromIndex] = {
            ...vehicleImages[toIndex],
            label: undefined,
            status: VehicleMediaStatus.Draft,
            hasApproved: false,
            hasPending: false,
            hasRejected: false,
            hasError: false,
            hasProcessing: false,
          };
        } else {
          updatedImages.splice(fromIndex, 1);
        }

        // we now place the image in the new position and set placeholder to false
        updatedImages[toIndex] = {
          ...vehicleImages[fromIndex],
          //keep the label
          label: clonedItems[toIndex]?.label,
          isPlaceholder: false,
          status: VehicleMediaStatus.Draft,
          hasApproved: false,
          hasPending: false,
          hasRejected: false,
          hasError: false,
          hasProcessing: false,
        };
      } else if (
        // image moving from secondary to secondary
        !isPrimary(toIndex) &&
        !isPrimary(fromIndex)
      ) {
        // Remove the dragged item from its original position
        const [movedItem] = updatedImages.splice(fromIndex, 1);
        // Insert the dragged item into the new position
        updatedImages.splice(toIndex, 0, {
          ...movedItem,
          status: VehicleMediaStatus.Draft,
          hasApproved: false,
          hasPending: false,
          hasRejected: false,
          hasError: false,
          hasProcessing: false,
        });
      } else if (
        //moving from primary to secondary
        !isPrimary(toIndex) &&
        isPrimary(fromIndex)
      ) {
        const ghostImage = brandConfig?.vehicleMedia?.primaryImages?.find(
          (x) => x.position === fromIndex + 1,
        );
        // remove the image from the primary position and replace it with a placeholder
        updatedImages.splice(fromIndex, 1, {
          id: uniqueId(),
          isPlaceholder: true,
          media: undefined,
          label: ghostImage?.label,
          src:
            getCloudinaryUrl(ghostImage?.placeHolderUrl) ||
            brandConfig?.noVehicleImage ||
            '',
        });

        // Insert the dragged item after the new position
        updatedImages.splice(toIndex + 1, 0, {
          ...clonedItems[fromIndex],
          label: undefined,
          isPlaceholder: false,
          status: VehicleMediaStatus.Draft,
          hasApproved: false,
          hasPending: false,
          hasRejected: false,
          hasError: false,
          hasProcessing: false,
        });
      }
      setSelectedImage(undefined);
      onChange(updatedImages);
    },
    [
      vehicleImages,
      selectedImage,
      clonedItems,
      isPrimary,
      onChange,
      getCloudinaryUrl,
      brandConfig?.vehicleMedia?.primaryImages,
      brandConfig?.noVehicleImage,
    ],
  );

  const handleDragCancel = useCallback(() => {
    if (clonedItems) {
      setVehicleImages(clonedItems);
    }

    setSelectedImage(undefined);
    setClonedItems(undefined);
  }, [clonedItems]);

  useEffect(() => {
    if (simplifiedMedia) {
      setVehicleImages(simplifiedMedia);
    }
  }, [simplifiedMedia]);

  const secondaryImages = useMemo(
    () => vehicleImages?.slice(maxPrimaryPosition),
    [vehicleImages, maxPrimaryPosition],
  );

  const primaryImages = useMemo(
    () => vehicleImages?.slice(0, maxPrimaryPosition),
    [vehicleImages, maxPrimaryPosition],
  );

  if (!vehicleImages) {
    return <ReorderImagesSkeleton />;
  }

  return (
    <Container maxWidth="xl">
      <DndContext
        collisionDetection={closestCorners}
        onDragCancel={handleDragCancel}
        onDragEnd={handleDragEnd}
        onDragStart={handleDragStart}
        sensors={sensors}
      >
        <Stack marginBottom={3} marginTop={3}>
          <Typography color="#4B5768" variant="subtitle1">
            {t('pages.vehiclePage.media.primaryShots')}
          </Typography>
          <Typography color="#5d6f8e" variant="body2">
            {t('pages.vehiclePage.media.primaryShotsSubtitle1')}
          </Typography>
        </Stack>
        <Droppable disabled={readonly} id="primary">
          <Stack flexDirection="row" flexWrap="wrap" mb={5}>
            {primaryImages?.map((value, index) => {
              return (
                <ShotContainer key={`div-primary-image-${value.id}`}>
                  <SortableItem
                    disabled={
                      readonly ||
                      value.isPlaceholder ||
                      value.status === VehicleMediaStatus.Processing ||
                      isMobile
                    }
                    id={value.id}
                    isPrimary={true}
                    key={value.id}
                  >
                    <VehicleImage
                      footerLabel={
                        value.hasPending &&
                        value.status !== VehicleMediaStatus.Pending
                          ? t('replacementImagePending')
                          : value.hasRejected && value.hasApproved
                          ? t('replacementImageRejected')
                          : value.hasApproved && value.hasError
                          ? t('replacementImageError')
                          : undefined
                      }
                      hideLabelStatus={
                        value.status === VehicleMediaStatus.Approved
                      }
                      key={`vehicle-image-${value.id}`}
                      label={
                        value.label
                          ? t(`pages.vehiclePage.media.${value.label}`)
                          : `${index}. `
                      }
                      onFileChange={
                        !readonly
                          ? (file) => {
                              const imageIndex = vehicleImages.findIndex(
                                (x) => x.id === value.id,
                              );

                              if (imageIndex === -1) {
                                return;
                              }
                              onFileChange &&
                                onFileChange(file, imageIndex + 1);
                            }
                          : undefined
                      }
                      onFullViewButtonClick={() => {
                        onExpandImage && onExpandImage(index + 1);
                      }}
                      onRemoveClick={
                        !readonly
                          ? () => {
                              const imageIndex = vehicleImages.findIndex(
                                (x) => x.id === value.id,
                              );

                              if (imageIndex === -1) {
                                return;
                              }

                              const ghostImage =
                                brandConfig?.vehicleMedia?.primaryImages?.find(
                                  (x) => x.position === imageIndex + 1,
                                );

                              const updatedImages = [...vehicleImages];
                              // remove the image from the primary position and replace it with a placeholder
                              updatedImages.splice(imageIndex, 1, {
                                id: uniqueId(),
                                isPlaceholder: true,
                                media: undefined,
                                label: ghostImage?.label,
                                src:
                                  getCloudinaryUrl(
                                    ghostImage?.placeHolderUrl,
                                  ) ||
                                  brandConfig?.noVehicleImage ||
                                  '',
                              });
                              onChange(updatedImages);
                            }
                          : undefined
                      }
                      seo={seo}
                      src={value.src}
                      status={value.status}
                    />
                  </SortableItem>
                </ShotContainer>
              );
            })}
          </Stack>
        </Droppable>
        <Droppable disabled={readonly} id="secondary">
          <SortableContext
            disabled={readonly}
            items={vehicleImages.slice(maxPrimaryPosition)}
          >
            <Stack marginBottom={3} spacing={0.5}>
              <Typography color="#4B5768" variant="subtitle1">
                {t('pages.vehiclePage.media.secondaryShots')}
              </Typography>
              {!!brandConfig?.vehicleMedia?.minimumSecondaryShots ? (
                <Typography color="#5d6f8e" variant="body2">
                  {t('pages.vehiclePage.media.secondaryShotsSubtitle', {
                    count: brandConfig?.vehicleMedia?.minimumSecondaryShots,
                  })}
                </Typography>
              ) : (
                <Typography color="#5d6f8e" variant="body2">
                  {t('pages.vehiclePage.media.noSecondaryShotsSubtitle')}
                </Typography>
              )}
              {brandConfig?.vehicleMedia?.photographyGuidelines && (
                <Link
                  color="#0C264E"
                  href={brandConfig?.vehicleMedia?.photographyGuidelines}
                  rel="noopener noreferrer"
                  target="_blank"
                  underline="always"
                  variant="body2"
                >
                  {t('requirements')}
                </Link>
              )}
            </Stack>
            <Stack flexDirection="row" flexWrap="wrap">
              {secondaryImages?.map((value) => {
                return (
                  <ShotContainer key={`div-primary-image-${value.id}`}>
                    <SortableItem
                      disabled={
                        readonly ||
                        value.isPlaceholder ||
                        value.status === VehicleMediaStatus.Processing ||
                        isMobile
                      }
                      id={value.id}
                      key={value.id}
                    >
                      <VehicleImage
                        footerLabel={
                          value.hasPending &&
                          value.status !== VehicleMediaStatus.Pending
                            ? t('replacementImagePending')
                            : value.hasRejected && value.hasApproved
                            ? t('replacementImageRejected')
                            : value.hasError && value.hasApproved
                            ? t('replacementImageError')
                            : undefined
                        }
                        hideLabelStatus={
                          value.status === VehicleMediaStatus.Approved
                        }
                        key={`vehicle-image-${value.id}`}
                        onFileChange={
                          !readonly
                            ? (file) => {
                                const imageIndex = vehicleImages.findIndex(
                                  (x) => x.id === value.id,
                                );

                                if (imageIndex === -1) {
                                  return;
                                }
                                onFileChange &&
                                  onFileChange(file, imageIndex + 1);
                              }
                            : undefined
                        }
                        onFullViewButtonClick={() => {
                          const imageIndex = vehicleImages.findIndex(
                            (x) => x.id === value.id,
                          );

                          if (imageIndex === -1 || !value.media) {
                            return;
                          }

                          onExpandImage && onExpandImage(imageIndex + 1);
                        }}
                        onRemoveClick={
                          !readonly
                            ? () => {
                                const updatedImages = vehicleImages.filter(
                                  (x) => x.id !== value.id,
                                );
                                onChange(updatedImages);
                              }
                            : undefined
                        }
                        seo={seo}
                        src={value.src}
                        status={value.status}
                      />
                    </SortableItem>
                  </ShotContainer>
                );
              })}
            </Stack>
          </SortableContext>
        </Droppable>
        <DragOverlay>
          <OverlayImage src={selectedImage?.src}></OverlayImage>
        </DragOverlay>
      </DndContext>
    </Container>
  );
};

export default ReorderImages;
