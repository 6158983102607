export enum DealerZoneCategory {
  AstonMartinZone = 'Aston Martin Region',
  BentleyZone = 'Bentley Region',
  GenesisZone = 'Genesis Region',
  KiaZone = 'KIA',
  LamborghiniZone = 'Lamborghini Region',
  RollsRoyceZone = 'Rolls-Royce Region',
}

export enum DealerSubregion {
  AstonMartinSubregion = 'Aston Martin Sub-Region',
  BentleySubregion = 'Bentley Sub-Region',
  GenesisSubregion = 'Genesis Sub-Region',
  KiaSubregion = 'KIA',
  LamborghiniSubregion = 'Lamborghini Sub-Region',
  RollsRoyceSubregion = 'Rolls-Royce Sub-Region',
}
