export function initials(name: string): string {
  const terms = name.split(' ');
  if (!terms) {
    return '';
  }
  if (terms.length > 1) {
    return `${terms[0][0]}${terms[1][0]}`.toUpperCase();
  } else {
    return name.substring(0, 2).toUpperCase();
  }
}
