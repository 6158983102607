import { Box } from '@mui/material';
import { FunctionComponent, useCallback } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { RemarketingVehicle } from '../../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicle } from '../../../../api/util/UserRemarketingVehicle.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import VehicleFeaturesEditor from '../Vehicle/VehicleFeaturesEditor.js';

interface RemarketingFeaturesProps {
  remarketingVehicle: UserRemarketingVehicle | RemarketingVehicle;
  readonly?: boolean;
  onSave?: (
    remarketingVehicle: UserRemarketingVehicle | RemarketingVehicle,
  ) => void;
}

const RemarketingFeatures: FunctionComponent<RemarketingFeaturesProps> = ({
  remarketingVehicle,
  onSave,
  readonly,
}) => {
  const api = useApiClient();
  const [saveState, setSaveState] = useAsyncState<
    UserRemarketingVehicle | RemarketingVehicle
  >();
  const { brand } = useParams();
  const navigate = useNavigate();
  const handleCancel = useCallback(() => {
    navigate(`/${brand}/remarketing/management`);
  }, [brand, navigate]);

  return (
    <Box>
      <VehicleFeaturesEditor
        features={remarketingVehicle.vehicle.features}
        loading={saveState.loading}
        onCancel={handleCancel}
        onSave={(features) => {
          setSaveState(async () => {
            if (!remarketingVehicle) {
              return;
            }

            const updated = await api.remarketing.adminUpdateRemarketingVehicle(
              {
                brand: remarketingVehicle.vehicle.brand,
                remarketingVehicle: {
                  ...remarketingVehicle,
                  vehicle: {
                    ...remarketingVehicle.vehicle,
                    features: features,
                  },
                } as RemarketingVehicle,
              },
            );

            onSave && onSave(updated);

            return updated;
          });
        }}
        options={{ disabledIsDirty: true }}
        readonly={readonly}
      />
    </Box>
  );
};

export default RemarketingFeatures;
