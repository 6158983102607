import { array, object, string } from '@fmtk/decoders';
import { MediaTarget, decodeMediaTarget } from '../../util/VehicleMedia.js';

export interface ApproveMediaRequest {
  vinMd5Hash: string;
  targets: MediaTarget[];
}

export const decodeApproveMediaRequest = object<ApproveMediaRequest>({
  vinMd5Hash: string,
  targets: array(decodeMediaTarget),
});
