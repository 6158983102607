import { useAuth0 } from '@auth0/auth0-react';
import { Logout as LogoutIcon } from '@mui/icons-material';
import { IconButton } from '@mui/material';

export function Logout() {
  const { logout } = useAuth0();

  return (
    <IconButton
      onClick={() => logout()}
      sx={{
        padding: 0,
        width: 44,
        height: 44,
        marginTop: 'auto',
      }}
    >
      <LogoutIcon />
    </IconButton>
  );
}
