import { Container, Skeleton, Stack } from '@mui/material';
import { FunctionComponent } from 'react';

const DetailsSkeleton: FunctionComponent = () => {
  return (
    <Container maxWidth="xl">
      <Stack
        direction={{
          xs: 'column-reverse',
          sm: 'row',
        }}
        justifyContent="space-between"
        mt={4}
        spacing={2}
      >
        <Stack
          bgcolor="background.paper"
          borderRadius={1}
          flex={1}
          p={3}
          spacing={4}
        >
          <Skeleton height={40} variant="text" width={200} />
          <Stack direction="row" flex={1} spacing={4}>
            <Stack flex={1}>
              <Skeleton height={60} width="100%" />
              <Skeleton height={60} width="100%" />
              <Skeleton height={60} width="100%" />
              <Skeleton height={60} width="100%" />
            </Stack>
            <Stack flex={1}>
              <Skeleton height={60} width="100%" />
              <Skeleton height={60} width="100%" />
              <Skeleton height={60} width="100%" />
              <Skeleton height={60} width="100%" />
            </Stack>
          </Stack>
          <Stack direction="row-reverse" spacing={4}>
            <Skeleton height={60} width={120} />
            <Skeleton height={60} width={120} />
          </Stack>
        </Stack>
        <Stack bgcolor="background.paper" borderRadius={1} p={3} spacing={5}>
          <Skeleton height={40} variant="text" width={180} />

          <Stack flex={1} spacing={2}>
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
          </Stack>
        </Stack>
      </Stack>
    </Container>
  );
};

export default DetailsSkeleton;
