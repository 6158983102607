import { Decoder, invalid, ok } from '@fmtk/decoders';
import { DateTime } from 'luxon';

const InvalidDate = 'INVALID_DATE';

export function normaliseDate(options?: {
  format?: string;
  locale?: string;
}): Decoder<Date> {
  return (value) => {
    let date: DateTime;

    if (typeof value === 'string') {
      const hasTime = /T\d{2}:\d{2}(:\d{2})?/.test(value);

      if (hasTime) {
        date = DateTime.fromISO(value, {
          locale: options?.locale || navigator.language,
        });
      } else {
        date = DateTime.fromFormat(value, options?.format || 'yyyy-MM-dd', {
          locale: options?.locale || navigator.language,
          zone: 'utc',
        });
      }
    } else if (typeof value === 'number') {
      date = DateTime.fromMillis(value, {
        locale: options?.locale || navigator.language,
      });
    } else {
      return invalid(InvalidDate, 'Invalid date');
    }

    return date.isValid
      ? ok(date.toJSDate())
      : invalid(InvalidDate, 'Invalid date');
  };
}
