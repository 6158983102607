import { array, number, object, text } from '@fmtk/decoders';

export interface Country {
  id: number;
  countryName: string;
  a2: string;
  a3: string;
}

export const decodeCountry = object<Country>({
  a2: text,
  a3: text,
  countryName: text,
  id: number,
});

export const decodeListCountryResponse = array(decodeCountry);
