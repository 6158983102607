import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  ListInAppNotificationQueryData,
  makeListInAppNotificationsQueryKey,
} from '../queries/useListInappNotifications.js';

export const useMarkAllNotificationsAsRead = (): UseMutationResult => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async () => {
      await api.inAppNotifications.markAllNotificationsAsRead();
    },
    onMutate: async () => {
      await queryClient.setQueriesData<ListInAppNotificationQueryData>(
        {
          queryKey: makeListInAppNotificationsQueryKey(),
        },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.map((i) => ({
                  ...i,
                  read: true,
                })),
                unreadCount: 0,
              })),
            };
          }
        },
      );
    },
  });
};
