import { Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { VehicleListing } from '../../../../api/util/Vehicle.js';
import NoData from '../../../common-ui/components/NoData.js';
import { ErrorState } from '../../../common-ui/index.js';
import VehicleCard from './VehicleCard.js';
import VehicleCardSkeleton from './VehicleCardSkeleton.js';

type VehicleTableProps = {
  vehicles: VehicleListing[];
  loading?: boolean;
  error?: unknown;
  archiveMode?: boolean;
};

export default function VehicleTable({
  vehicles,
  loading,
  error,
  archiveMode,
}: VehicleTableProps) {
  const { t } = useTranslation();
  if (error) {
    return <ErrorState />;
  }

  return (
    <Stack spacing={2}>
      {loading ? (
        <VehicleCardSkeleton />
      ) : (
        <>
          <Stack spacing={{ xs: 3, sm: 2 }}>
            {vehicles.map((v: VehicleListing) => (
              <VehicleCard archiveMode={archiveMode} key={v.id} vehicle={v} />
            ))}
            {!loading && !vehicles.length && <NoData text={t('noData')} />}
          </Stack>
        </>
      )}
    </Stack>
  );
}
