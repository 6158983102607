import { useCallback, useMemo } from 'react';
import { VehicleFields } from '../util/VehicleFields.js';
import { useBrand } from './useBrand.js';
import { useDealer } from './useDealer.js';

export type FieldConfig = {
  visible?: boolean;
  allowAdd?: boolean;
  allowEdit?: boolean;
};

export type VehicleHook = {
  getFieldOptions: (field: VehicleFields) => FieldConfig;
  fieldsConfig: {
    [Key in keyof typeof VehicleFields as VehicleFields]?: FieldConfig;
  };
  resolveReadonly: (
    field: VehicleFields,
    actionType: 'edit' | 'add',
    readonly?: boolean,
  ) => boolean;
};

export const useVehicle = (): VehicleHook => {
  const { brandConfig } = useBrand();
  const { currentDealer } = useDealer();

  const getFieldOptions = useCallback(
    (field: VehicleFields): FieldConfig => {
      if (!brandConfig) {
        return {
          visible: undefined,
          allowAdd: undefined,
          allowEdit: undefined,
        };
      }

      return {
        visible: Array.isArray(brandConfig.vehicleFields)
          ? brandConfig.vehicleFields?.includes(field)
          : currentDealer?.markets?.some(
              (market) =>
                brandConfig.vehicleFields &&
                (brandConfig.vehicleFields as Record<string, VehicleFields[]>)[
                  market
                ]?.includes(field),
            ) || false,
        allowAdd: brandConfig.addListing?.vehicleFields?.includes(field),
        allowEdit: brandConfig.editableVehicleFields?.includes(field),
      };
    },
    [brandConfig, currentDealer],
  );

  const resolveReadonly = useCallback(
    (field: VehicleFields, actionType: 'edit' | 'add', readonly?: boolean) => {
      if (readonly) {
        return readonly;
      }
      return actionType === 'edit'
        ? !getFieldOptions(field)?.allowEdit
        : !getFieldOptions(field)?.allowAdd;
    },
    [],
  );

  const fields = useMemo(() => {
    return Object.values(VehicleFields).reduce((fields, field) => {
      return {
        ...fields,
        [field as VehicleFields]: getFieldOptions(field as VehicleFields),
      };
    }, {});
  }, [brandConfig, getFieldOptions]);

  return {
    getFieldOptions: getFieldOptions,
    fieldsConfig: fields,
    resolveReadonly: resolveReadonly,
  };
};
