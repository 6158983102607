import { array, maybe, object, string } from '@fmtk/decoders';

export interface DownloadMediaRequest {
  cloudinaryIds: string[];
  fileName?: string;
}

export const decodeDownloadZipUrl = object<DownloadMediaRequest>({
  cloudinaryIds: array(string),
  fileName: maybe(string),
});
