import { object, text } from '@fmtk/decoders';

export interface DeleteUserNotificationRequest {
  notificationId: string;
}

export const decodeDeleteUserNotificationRequest =
  object<DeleteUserNotificationRequest>({
    notificationId: text,
  });
