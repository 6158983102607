import { boolean, maybe, object, string } from '@fmtk/decoders';

export interface GetVehicleListingByIdRequest {
  id: string;
  archived?: boolean;
}

export const decodeGetVehicleListingByIdRequest =
  object<GetVehicleListingByIdRequest>({
    id: string,
    archived: maybe(boolean),
  });
