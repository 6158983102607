import { FileUploadStatus } from '../../api/util/FileUploadStatus.js';
import {
  AvailableBrands,
  ListingLiveStatus,
  SyndicationType,
} from '../../api/util/index.js';
import {
  BidActionResult,
  OfferActionStatus,
} from '../../api/util/RemarketingAction.js';
import {
  RemarketingActionType,
  RemarketingType,
  RemarketingVehicleStatus,
} from '../../api/util/RemarketingVehicle.js';
import { UserDocumentSource } from '../../api/util/UserDocument.js';
import { VehicleMediaStatus } from '../../api/util/VehicleMedia.js';
import { initials } from './initials.js';

// https://www.learnui.design/tools/data-color-picker.html#palette
export const chartCategoricalPalette = [
  '#096CB4',
  '#00AEEF',
  '#f53855',
  '#456FBF',
  '#446176',
  '#096CB4',
  '#928B89',
  '#0E2B40',
];

export const chartSequentialPalette = [
  '#004c6d',
  '#255e7e',
  '#3d708f',
  '#5383a1',
  '#6996b3',
  '#7faac6',
  '#94bed9',
  '#abd2ec',
  '#c1e7ff',
];

export const chartDivergingPalette = [
  '#00876c',
  '#439981',
  '#6aaa96',
  '#8cbcac',
  '#aecdc2',
  '#cfdfd9',
  '#f1f1f1',
  '#f1d4d4',
  '#f0b8b8',
  '#ec9c9d',
  '#e67f83',
  '#de6069',
  '#d43d51',
];

export const avatarColors = [
  '#1D4E88',
  '#6B1616',
  '#D12F89',
  '#2F681F',
  '#927027',
  '#5243AA',
  '#FF8B00',
];

export const chartPalettes = {
  chartCategoricalPalette,
  chartSequentialPalette,
  chartDivergingPalette,
};

export const vehicleTypeColors: Record<SyndicationType, string> = {
  Certified: '#d9f7cb',
  New: '#1D4E88',
  Used: '#927027',
  Demo: '#928B89',
};

export type styleOptions =
  | 'chartCategoricalPalette'
  | 'chartSequentialPalette'
  | 'chartDivergingPalette'
  | 'avatar';

// helper for getting a consistent color based on a name
export function labelColor(
  name: string,
  style: styleOptions = 'chartCategoricalPalette',
): string {
  const shortName = initials(name);
  let colorIndex = 0;
  for (let index = 0; index < shortName.length; index++) {
    colorIndex += shortName.charCodeAt(index);
  }

  const colors = style === 'avatar' ? avatarColors : chartPalettes[style];

  return colors[colorIndex % chartPalettes.chartCategoricalPalette.length];
}

export function sequenceColour(sequence: number): string {
  return chartPalettes.chartCategoricalPalette[
    sequence % chartPalettes.chartCategoricalPalette.length
  ];
}

export interface LabelColor {
  color: string;
  background: string;
}

export const BrandColors: Record<AvailableBrands, LabelColor> = {
  [AvailableBrands.AstonMartin]: {
    color: '#FFF',
    background: '#05665e',
  },
  [AvailableBrands.Bentley]: {
    color: '#FFF',
    background: '#335b4c',
  },
  [AvailableBrands.Genesis]: {
    color: '#FFF',
    background: '#000',
  },
  [AvailableBrands.Kia]: {
    color: '#FFF',
    background: '#000',
  },
  [AvailableBrands.Lamborghini]: {
    color: '#ddae42',
    background: '#181818',
  },
  [AvailableBrands.RollsRoyce]: {
    color: '#FFF',
    background: '#281332',
  },
};

export const VerifiedStatusColors: Record<
  'verified' | 'notverified',
  LabelColor
> = {
  verified: {
    background: '#e2f2ea',
    color: '#19806a',
  },
  notverified: {
    background: '#ffe5df',
    color: '#b71c19',
  },
};

export const UserStatusColors: Record<'banned' | 'active', LabelColor> = {
  active: {
    background: '#e2f2ea',
    color: '#19806a',
  },
  banned: {
    background: '#ffe5df',
    color: '#b71c19',
  },
};

export const statusLabelColoursMap: Partial<
  Record<VehicleMediaStatus | 'Required', LabelColor>
> = {
  [VehicleMediaStatus.Pending]: { background: '#E2B203', color: '#0C264E' },
  [VehicleMediaStatus.Rejected]: { background: '#CA3521', color: '#FFF' },
  [VehicleMediaStatus.Error]: { background: '#CA3521', color: '#FFF' },
  [VehicleMediaStatus.Approved]: { background: '#519d67', color: '#FFF' },
  [VehicleMediaStatus.Processing]: { background: 'grey.600', color: '#FFF' },
  [VehicleMediaStatus.Archived]: { background: '#096CB4', color: '#FFF' },
  [VehicleMediaStatus.Draft]: { background: '#096CB4', color: '#FFF' },
  Required: { background: '#CA3521', color: '#FFF' },
} as const;

export const listingLiveStatusLabelColoursMap: Record<
  ListingLiveStatus.LIVE | 'nonLive',
  LabelColor
> = {
  [ListingLiveStatus.LIVE]: { background: '#377448', color: '#FFF' },
  nonLive: { background: '#44546F', color: '#FFF' },
} as const;

export const remarketingStatusColours: Record<
  RemarketingVehicleStatus,
  LabelColor
> = {
  [RemarketingVehicleStatus.ACTIVE]: {
    background: '#377448',
    color: '#FFF',
  },
  [RemarketingVehicleStatus.CANCELLED]: {
    background: '#44546F',
    color: '#FFF',
  },
  [RemarketingVehicleStatus.TRANSFERRED]: {
    background: '#44546F',
    color: '#FFF',
  },
  [RemarketingVehicleStatus.CLOSED]: {
    background: '#E2B203',
    color: '#FFF',
  },
  [RemarketingVehicleStatus.VISIBLE]: {
    background: '#096CB4',
    color: '#FFF',
  },
  [RemarketingVehicleStatus.PIPELINE]: {
    background: '#e2f2ea',
    color: '#19806a',
  },
};

export const remarketingTypeColours: Record<RemarketingType, LabelColor> = {
  [RemarketingType.AUCTION]: {
    background: '#096CB4',
    color: '#FFF',
  },
  [RemarketingType.FIXED_PRICE]: {
    background: '#44546F',
    color: '#FFF',
  },
  [RemarketingType.UNASSIGNED]: {
    background: 'grey.600',
    color: '#FFF',
  },
};
export const bidActionResultColours: Record<
  BidActionResult | 'Processing',
  LabelColor
> = {
  [BidActionResult.WON]: {
    background: '#4B5768',
    color: '#FFF',
  },
  [BidActionResult.LOST]: {
    background: '#CA3521',
    color: '#FFF',
  },
  Processing: {
    background: '#E2B203',
    color: '#FFF',
  },
};

export const offerStatusColours: Record<OfferActionStatus, LabelColor> = {
  [OfferActionStatus.ACCEPTED]: {
    background: '#377448',
    color: '#FFF',
  },
  [OfferActionStatus.REJECTED]: {
    background: '#CA3521',
    color: '#FFF',
  },
};

export const remarketingActionTypeColours = {
  [RemarketingActionType.BUY_NOW]: {
    background: '#4B5768',
    color: '#FFF',
  },
  [RemarketingActionType.OFFER]: {
    background: '#E2B203',
    color: '#4B5768',
  },
};

export const userDocumentSourceColours: Record<
  UserDocumentSource | UserDocumentSource.S3,
  LabelColor
> = {
  [UserDocumentSource.Link]: {
    background: '#4B5768',
    color: '#FFF',
  },
  [UserDocumentSource.S3]: {
    background: '#CA3521',
    color: '#FFF',
  },
};

export const userDocumentSelectionStatusColours: Record<
  'selected',
  LabelColor
> = {
  selected: {
    background: '#e2f2ea',
    color: '#19806a',
  },
};

export const archivedColour = {
  background: '#44546F',
  color: '#FFF',
};

export const fileUploadStatusColours: Record<FileUploadStatus, LabelColor> = {
  [FileUploadStatus.Processed]: {
    background: '#377448',
    color: '#FFF',
  },
  [FileUploadStatus.Failed]: {
    background: '#CA3521',
    color: '#FFF',
  },
  [FileUploadStatus.Pending]: {
    background: '#E2B203',
    color: '#4B5768',
  },
};
