import { method, service } from '../util/index.js';
import { decodeBentleyVinLookupRequest } from './bentleyVinLookup/BentleyVinLookupRequest.js';
import { decodeBentleyVinLookupResponse } from './bentleyVinLookup/BentleyVinLookupResponse.js';
import { decodeExternalApiResponse } from './common.js';
import { decodeListAstonMartinDealerStockAPIRequest } from './listAstonMartinDealerStockAPI/ListAstonMartinDealerStockAPIRequest.js';
import { decodeListAstonMartinDealerStockAPIResponse } from './listAstonMartinDealerStockAPI/ListAstonMartinDealerStockAPIResponse.js';
import { decodeListBentleyDealerStockAPIRequest } from './listBentleyDealerStockAPI/ListBentleyDealerStockAPIRequest.js';
import { decodeListBentleyDealerStockAPIResponse } from './listBentleyDealerStockAPI/ListBentleyDealerStockAPIResponse.js';

export const ExternalServiceDef = service({
  bentleyVinLookup: method(
    decodeBentleyVinLookupRequest,
    decodeExternalApiResponse(decodeBentleyVinLookupResponse),
  ),
  listAstonMartinDealerStock: method(
    decodeListAstonMartinDealerStockAPIRequest,
    decodeExternalApiResponse(decodeListAstonMartinDealerStockAPIResponse),
  ),
  listBentleyDealerStock: method(
    decodeListBentleyDealerStockAPIRequest,
    decodeExternalApiResponse(decodeListBentleyDealerStockAPIResponse),
  ),
});

export type ExternalServiceDef = typeof ExternalServiceDef;
