import { ExpressionBuilder, ExpressionInput } from './ExpressionBuilder.js';

export type UpdateExpressionInput = ExpressionInput & {
  UpdateExpression?: string;
};

export class UpdateBuilder<
  Input extends UpdateExpressionInput,
> extends ExpressionBuilder<Input> {
  private readonly adds: string[] = [];
  private readonly deletes: string[] = [];
  private readonly removes: string[] = [];
  private readonly sets: string[] = [];

  constructor(input: Omit<Input, 'UpdateExpression'>) {
    super(input as Input);
  }

  public override buildExpression(): Input {
    let updateExpr = '';

    if (this.sets.length) {
      updateExpr = `SET ` + this.sets.join(', ');
    }
    if (this.removes.length) {
      updateExpr += ` REMOVE ` + this.removes.join(', ');
    }
    if (this.adds.length) {
      updateExpr += ` ADD ` + this.adds.join(', ');
    }
    if (this.deletes.length) {
      updateExpr += ` DELETE ` + this.deletes.join(', ');
    }

    return { ...super.buildExpression(), UpdateExpression: updateExpr };
  }

  public addToSet(
    attribute: string | string[],
    values: Iterable<unknown>,
  ): this {
    this.adds.push(
      `${this.withName(attribute)} ${this.withValue(new Set(values))}`,
    );
    return this;
  }

  public deleteFromSet(
    attribute: string | string[],
    values: Iterable<unknown>,
  ): this {
    this.deletes.push(
      `${this.withName(attribute)} ${this.withValue(new Set(values))}`,
    );
    return this;
  }

  public decrement(attribute: string | string[], dec = 1): this {
    this.increment(attribute, -dec);
    return this;
  }

  public increment(attribute: string | string[], inc = 1): this {
    this.adds.push(`${this.withName(attribute)} ${this.withValue(inc)}`);
    return this;
  }

  public removeAttribute(attribute: string | string[]): this {
    this.removes.push(this.withName(attribute));
    return this;
  }

  public setExpression(attribute: string | string[], expr: string): this {
    this.sets.push(`${this.withName(attribute)} = ${expr}`);
    return this;
  }

  public setIfNotExists(
    attribute: string | string[],
    value: unknown,
    testAttribute = attribute,
  ): this {
    this.sets.push(
      `${this.withName(attribute)} = ` +
        `if_not_exists(${this.withName(testAttribute)}, ` +
        `${this.withValue(value)})`,
    );
    return this;
  }

  public setValue(attribute: string | string[], value: unknown): this {
    this.sets.push(`${this.withName(attribute)} = ${this.withValue(value)}`);
    return this;
  }

  public setValues<K extends string>(
    update: Partial<Record<K, unknown>>,
    removeUndefined = false,
    allowedKeys?: readonly K[],
  ): this {
    for (const [k, v] of Object.entries(update)) {
      if (allowedKeys && !allowedKeys.includes(k as K)) {
        throw new Error(`attempt to update non-allowed key ${k}`);
      }
      if (v !== undefined) {
        this.setValue(k, v);
      } else if (removeUndefined) {
        this.removeAttribute(k);
      }
    }
    return this;
  }
}
