import { array, jsonDate, object, string } from '@fmtk/decoders';
import { VehicleMedia, decodeVehicleMedia } from '../../util/VehicleMedia.js';

export interface UpdateVehicleMediaRequest {
  vehicleMedia: VehicleMedia[];
  vehicleId: string;
}

export const decodeUpdateVehicleMediaRequest =
  object<UpdateVehicleMediaRequest>({
    vehicleMedia: array(decodeVehicleMedia(jsonDate)),
    vehicleId: string,
  });
