import { object, string } from '@fmtk/decoders';

export interface GetDownloadDocumentUrlRequest {
  key: string;
}

export const decodeGetDownloadDocumentUrlRequest =
  object<GetDownloadDocumentUrlRequest>({
    key: string,
  });
