import { createSvgIcon } from '@mui/material';

const OpenFullIcon = createSvgIcon(
  <svg
    fill="none"
    height="24"
    viewBox="0 0 25 24"
    width="25"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.9028 3H21.9028V9"
      stroke="#0C264E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M9.90283 21H3.90283V15"
      stroke="#0C264E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M21.9028 3L14.9028 10"
      stroke="#0C264E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
    <path
      d="M3.90283 21L10.9028 14"
      stroke="#0C264E"
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth="2"
    />
  </svg>,
  'OpenFull',
);

export default OpenFullIcon;
