import dayjs, { Dayjs } from 'dayjs';
import utc from 'dayjs/plugin/utc.js'; // dependent on utc plugin
import { DateTime, DateTimeOptions, ToRelativeOptions } from 'luxon';
dayjs.extend(utc);

export function formatDate(
  date: Date,
  locale = navigator.language,
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
  },
): string {
  return new Intl.DateTimeFormat(locale, options).format(date);
}

export function formatDateTime(
  date: Date | number | undefined,
  locale = navigator.language,
  options: Intl.DateTimeFormatOptions = {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hourCycle: 'h24',
  },
): string | undefined {
  if (!date) {
    return;
  }
  return new Intl.DateTimeFormat(locale, options).format(date);
}

/** Removes time part from a date.
 * @param date Date to format.
 * @returns UTC formatted date without time.
 */
export const removeTimeFromDate = (date: Dayjs): Date => {
  return dayjs(date.format('MM/DD/YYYY')).utc().toDate();
};

export function relativeTime(
  value: string | number | Date | undefined,
  relativeOptions?: ToRelativeOptions,
  options?: DateTimeOptions,
  defaultValue = '',
): string {
  let result: DateTime | undefined = undefined;
  if (typeof value === 'number') {
    result = DateTime.fromMillis(value, options);
  } else if (typeof value === 'string') {
    result = DateTime.fromFormat(
      value,
      'yyyy-MM-dd',
      options || {
        locale: relativeOptions?.locale || navigator.language,
      },
    );
  } else if (value instanceof Date) {
    const date = DateTime.fromJSDate(value, options);
    result = date.isValid ? date : undefined;
  }
  return result?.isValid
    ? result.toRelative(relativeOptions) || defaultValue
    : defaultValue;
}
