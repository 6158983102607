import { Box, BoxProps } from '@mui/material';
import { sx } from '../../util/sx.js';

const styles = sx({
  rootStyle: {
    borderRadius: '4px',
    padding: '0px 4px',
    width: 'fit-content',
    alignItems: 'center',
    display: 'flex',
  },
});

export const Label = ({ sx, ...props }: BoxProps): JSX.Element => (
  <Box sx={{ ...styles.rootStyle, ...sx }} {...props} />
);
