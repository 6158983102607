import { array, boolean, jsonDate, maybe, object } from '@fmtk/decoders';
import {
  UploadMediaRequest,
  decodeUploadMediaRequest,
} from '../../MediaService/uploadMedia/UploadMediaRequest.js';
import {
  VehicleListingForm,
  decodeVehicleListingFormProps,
} from '../../util/Vehicle.js';

export type CreateVehicleListingRequest = VehicleListingForm & {
  uploadMedia?: UploadMediaRequest[];
  options?: {
    async?: boolean;
  };
};

export const decodeCreateVehicleListingRequest =
  object<CreateVehicleListingRequest>({
    ...decodeVehicleListingFormProps(jsonDate),
    uploadMedia: maybe(array(decodeUploadMediaRequest)),
    options: maybe(
      object({
        async: maybe(boolean),
      }),
    ),
  });
