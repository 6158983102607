import { array, jsonDate, number, object } from '@fmtk/decoders';
import { VehicleListing, decodeVehicleListing } from '../../util/index.js';

export type SearchVehicleListingResponse = {
  items: VehicleListing[];
  count: number;
};

export const decodeSearchVehicleListingResponse =
  object<SearchVehicleListingResponse>({
    items: array(decodeVehicleListing(jsonDate)),
    count: number,
  });
