import { FileDownload } from '@mui/icons-material';
import { LoadingButton, LoadingButtonProps } from '@mui/lab';
import { FunctionComponent } from 'react';

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface DownloadingButtonProps extends LoadingButtonProps {}

export const DownloadingButton: FunctionComponent<DownloadingButtonProps> = ({
  loadingPosition = 'end',
  variant = 'outlined',
  ...props
}) => {
  return (
    <LoadingButton
      endIcon={<FileDownload />}
      loadingPosition={loadingPosition}
      variant={variant}
      {...props}
    >
      Download
    </LoadingButton>
  );
};

export default DownloadingButton;
