import { Stack, Typography } from '@mui/material';
import { FunctionComponent, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { PriceType, VehicleListing } from '../../../../api/util/Vehicle.js';
import { usePrice } from '../../../hooks/usePrice.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { formatMoney } from '../../../util/money.js';
import { sx } from '../../../util/sx.js';

interface PriceProps {
  vehicle: VehicleListing;
}

const styles = sx({
  container: {
    flexDirection: 'row',
    alignItems: 'center',
    gap: 1,
    justifyContent: {
      xs: 'flex-start',
      sm: 'flex-end',
    },
  },
  textAlign: {
    textAlign: {
      xs: 'left',
      lg: 'right',
    },
  },
});

const priceKeys = [
  PriceType.LessTaxes,
  PriceType.NetPrice,
  PriceType.Retail,
  PriceType.RetailPlusTax,
  PriceType.StandInValue,
  PriceType.Trade,
  PriceType.BasePrice,
] as const;

const Price: FunctionComponent<PriceProps> = ({ vehicle }) => {
  const priceVisibility = usePrice();
  const { t } = useTranslation();
  const price = vehicle.price?.retail
    ? formatMoney(vehicle.price?.retail)
    : vehicle.listingStatus;

  const otherPrices = useMemo(() => {
    if (!priceVisibility) {
      return;
    }
    return filterFalsey(
      Object.entries(priceVisibility).map(([k, v]) => {
        return v && priceKeys.includes(k as PriceType) ? k : undefined;
      }),
    );
  }, [priceVisibility]);

  return (
    <>
      <Stack sx={styles.container}>
        <Typography
          color="grey.600"
          fontWeight="fontWeightMedium"
          gutterBottom
          m={0}
          sx={styles.textAlign}
          variant="body2"
        >
          {t('retailPrice')}
        </Typography>
        <Typography sx={styles.textAlign} variant="h5">
          {vehicle.currency?.code} {price}
        </Typography>
      </Stack>
      {/* Other Prices */}
      {otherPrices?.map((p) => {
        const formatted =
          vehicle.price && !!vehicle.price[p as PriceType]
            ? formatMoney(vehicle.price[p as PriceType]!)
            : undefined;

        return (
          formatted && (
            <Stack key={p} sx={styles.container}>
              <Typography
                color="grey.600"
                fontWeight="fontWeightMedium"
                gutterBottom
                mb="2px"
                sx={styles.textAlign}
                variant="body2"
              >
                {t(p)}
              </Typography>
              <Typography sx={styles.textAlign} variant="body2">
                {vehicle.currency?.code} {formatted}
              </Typography>
            </Stack>
          )
        );
      })}
    </>
  );
};

export default Price;
