import { chain, maybe, strToNum, text } from '@fmtk/decoders';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Link,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { SubmitOfferErrors } from '../../../../../../api/RemarketingService/common.js';
import { AvailableBrands } from '../../../../../../api/util/AvailableBrands.js';
import { OfferRemarketingAction } from '../../../../../../api/util/RemarketingAction.js';
import { UserFixedPriceRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { HttpServiceTransportError } from '../../../../../../api/util/index.js';
import { maxNumber } from '../../../../../../util/decoders/maxNumber.js';
import {
  Form,
  FormTextField,
  useMessageStore,
} from '../../../../../common-ui/index.js';
import { useApiClient } from '../../../../../hooks/useApiClient.js';
import { useCreateForm } from '../../../../../hooks/useCreateForm.js';
import { useDownloadDocument } from '../../../../../hooks/useDownloadDocument.js';
import { getCurrencySymbol } from '../../../../../util/numbers.js';
import Comments from '../../../Vehicle/Sections/Comments.js';

export const REFRESH = 1000;
export const MAX_ATTEMPTS = 10;

interface SubmitOfferModalProps {
  open: boolean;
  remarketingVehicle: UserFixedPriceRemarketingVehicle;
  dealerId: number;
  brand: AvailableBrands;
  onClose: () => void;
  onSubmit?: (action: OfferRemarketingAction) => void;
  fullScreen?: boolean;
  isLoading: boolean;
}

const SubmitOfferModal: FunctionComponent<SubmitOfferModalProps> = ({
  open,
  remarketingVehicle,
  dealerId,
  brand,
  onClose,
  onSubmit,
  fullScreen,
  isLoading,
}) => {
  const { t } = useTranslation();
  const api = useApiClient();
  const [, downloadDocument] = useDownloadDocument();
  const { showMessage } = useMessageStore();

  const maxOffer = Math.round(remarketingVehicle.price - 1);

  const form = useCreateForm(
    {
      offer: chain(strToNum, maxNumber({ max: maxOffer })),
      comments: maybe(text),
    },
    async (values) => {
      try {
        const response = await api.remarketing.userSubmitOffer({
          value: values.offer,
          brand,
          dealerId,
          remarketingVehicleId: remarketingVehicle.id,
          comments: values.comments,
        });

        //show success
        onSubmit && onSubmit(response);
        onClose();
      } catch (error) {
        if (
          HttpServiceTransportError.is(
            error,
            SubmitOfferErrors.INVALID_OFFER,
          ) ||
          HttpServiceTransportError.is(error, SubmitOfferErrors.NOT_ACTIVE) ||
          HttpServiceTransportError.is(error, SubmitOfferErrors.NO_OFFERS)
        ) {
          showMessage({
            severity: 'error',
            text: t(
              `pages.remarketingVehiclePage.fixedPrice.errors.${error.code}`,
            ),
            dismissible: true,
          });
        } else {
          showMessage({
            severity: 'error',
            text: t(`pages.remarketingVehiclePage.fixedPrice.errors.unhandled`),
            dismissible: true,
          });
        }
      }
    },
    undefined,
    [remarketingVehicle, brand, dealerId, maxOffer],
  );

  const [formState] = form;

  const loading = isLoading || formState.busy;

  return (
    <Dialog
      disableEnforceFocus
      disableEscapeKeyDown
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          pr: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <DialogTitle>
          {t('pages.remarketingVehiclePage.fixedPrice.submitOffer')}
        </DialogTitle>
        <IconButton
          aria-label="close"
          disabled={loading}
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent>
        <Form form={form}>
          <Stack spacing={3}>
            {remarketingVehicle.termsAndConditions && (
              <Typography variant="body2">
                <Trans
                  i18nKey="pages.remarketingVehiclePage.auction.form.userTermsAndConditions"
                  t={t}
                >
                  x
                  <Link
                    color="primary"
                    onClick={() => {
                      if (!remarketingVehicle.termsAndConditions) {
                        return;
                      }
                      downloadDocument(remarketingVehicle.termsAndConditions);
                    }}
                    underline="hover"
                    variant="button"
                  />
                </Trans>
              </Typography>
            )}
            <Stack spacing={1}>
              <FormTextField
                autoComplete="off"
                disabled={loading}
                fullWidth
                label={t(`pages.remarketingVehiclePage.fixedPrice.offer`)}
                max={maxOffer}
                name="offer"
                startAdornment={getCurrencySymbol(remarketingVehicle.currency)}
              />
              <Comments
                label={t(
                  'pages.remarketingVehiclePage.fixedPrice.offerComments',
                )}
                maxLength={2000}
                name="comments"
              />
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row-reverse',
              }}
              spacing={2}
            >
              <LoadingButton
                fullWidth
                loading={loading}
                type="submit"
                variant="contained"
              >
                {t('submit')}
              </LoadingButton>
              <Button
                color="inherit"
                disabled={loading}
                fullWidth
                onClick={onClose}
                variant="outlined"
              >
                {t('cancel')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default SubmitOfferModal;
