import {
  Decoder,
  ObjectDecoder,
  array,
  chain,
  maybe,
  object,
  string,
} from '@fmtk/decoders';
import { hasMaxLength } from '../../../util/decoders/hasMaxLength.js';
import {
  VehicleListingFormWithAttachedMedia,
  decodeVehicleListingFormWithAttachedMedia,
} from '../../util/Vehicle.js';

export interface BatchUploadListingRequest<D extends number | Date = Date> {
  inboundFeedId?: string;
  data: VehicleListingFormWithAttachedMedia<D>[];
  options?: {
    // If true and the action is an update, only the fields specified in the array will be updated.
    // it can accept path to nested fields, e.g. ['vehicle.make', 'vehicle.*']
    editableFieldsOnly?: string[];
  };
}

export const decodeBatchUploadListingRequest = <D extends Date | number = Date>(
  dateDecoder: Decoder<D>,
): ObjectDecoder<BatchUploadListingRequest<D>> => {
  return object<BatchUploadListingRequest<D>>({
    inboundFeedId: maybe(string),
    data: chain(
      array(decodeVehicleListingFormWithAttachedMedia(dateDecoder)),
      hasMaxLength(100),
    ),
    options: maybe(
      object({
        editableFieldsOnly: maybe(array(string)),
      }),
    ),
  });
};
