import { enumValue, is, maybe, number, object, string } from '@fmtk/decoders';
import { ArchiveOptions } from '../../util/ArchiveOptions.js';
import { RemarketingVehicleStatus } from '../../util/RemarketingVehicle.js';
import { AvailableBrands } from '../../util/index.js';
import { RemarketingVehicleSort } from '../common.js';

export interface AdminListRemarketingVehiclesRequest {
  brand: AvailableBrands;
  status?: RemarketingVehicleStatus;
  pageNumber: number;
  pageSize: number;
  searchTerm: string;
  sort: RemarketingVehicleSort;
  order: 'asc' | 'desc';
  archiveOption?: ArchiveOptions;
}

export const decodeAdminListRemarketingVehiclesRequest =
  object<AdminListRemarketingVehiclesRequest>({
    brand: enumValue(AvailableBrands),
    status: maybe(enumValue(RemarketingVehicleStatus)),
    pageNumber: number,
    pageSize: number,
    searchTerm: string,
    sort: enumValue(RemarketingVehicleSort),
    order: is('asc', 'desc'),
    archiveOption: maybe(enumValue(ArchiveOptions)),
  });
