import { array, enumValue, number, object, string } from '@fmtk/decoders';
import { AuditLogType, SyndicationType } from '../../util/index.js';

export interface DealerVehicleMovement {
  addedRemoved: AuditLogType;
  count: number;
  dealerName: string;
  newUsed: SyndicationType;
  regionName: string;
}

export const decodeDealerVehicleMovement = object<DealerVehicleMovement>({
  addedRemoved: enumValue(AuditLogType),
  count: number,
  dealerName: string,
  newUsed: enumValue(SyndicationType),
  regionName: string,
});

export const decodeListDealerVehicleMovementsResponse = array(
  decodeDealerVehicleMovement,
);
