import { QueryKey, UseQueryResult, useQuery } from '@tanstack/react-query';
import { User } from '../../../../api/util/User.js';
import { UserSettings } from '../../../../api/util/UserSettings.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import { QueryKeys } from './QueryKeys.js';

export const makeUserSettingsQueryKeys = (
  user?: Pick<User, 'principalId'>,
): QueryKey =>
  !user?.principalId ? [] : [QueryKeys.UserSettings, user.principalId];

export function useUserSettings(): UseQueryResult<UserSettings> {
  const api = useApiClient();
  const { isRemarketingAdmin, value: user } = useCurrentUser();

  return useQuery({
    queryKey: makeUserSettingsQueryKeys(user),
    queryFn: async () => {
      if (!user?.principalId) {
        return;
      }
      const response = await api.user.getUserSettings();
      return (
        response ?? {
          userId: user.principalId,
          notifications: {
            disableEmail: false,
            disableInApp: false,
          },
        }
      );
    },
    enabled: isRemarketingAdmin && !!user?.principalId,
  });
}
