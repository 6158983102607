import { Info } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ListRegionStockStatsResponse } from '../../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import { EmptyState } from '../../../common-ui/index.js';
import { formatPercentage, sequenceColour } from '../../../util/index.js';
import MainDrawer from '../MainDrawer.js';
import { chartOptions } from './chartConfig.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface DealersCarsReportProps {
  data: ListRegionStockStatsResponse;
}

type DataItem = {
  withCars: number;
  withoutCars: number;
  percentage: number;
};

type RegionData = Record<string, DataItem>;

type GroupedData = {
  regionData: RegionData;
  totalDealersWithCars: number;
  totalDealers: number;
};

type RowType = {
  header: string;
  id: number;
  total: string | number;
};

const DealersCarsReport: FunctionComponent<DealersCarsReportProps> = ({
  data,
}): JSX.Element => {
  const [openDrawer, setOpenDrawer] = useState(false);
  const { t } = useTranslation();
  const handleClose = useCallback(() => {
    setOpenDrawer(false);
  }, []);
  const title = t('reports.dealerCars.title');
  const { chartData, columns, rows } = useMemo(() => {
    //Transform data by region
    const dataByRegion: GroupedData = data.reduce(
      (dataItem, d) => {
        return {
          regionData: {
            ...dataItem.regionData,
            [d.regionName]: {
              withCars: d.amountOfDealersWithCars || 0,
              withoutCars:
                (d?.amountOfDealersInZone || 0) -
                (d?.amountOfDealersWithCars || 0),
              percentage: formatPercentage(
                d.amountOfDealersWithCars / (d.amountOfDealersInZone || 1),
              ),
            },
          },
          totalDealersWithCars:
            (dataItem.totalDealersWithCars || 0) + d.amountOfDealersWithCars,
          totalDealers: (dataItem.totalDealers || 0) + d.amountOfDealersInZone,
        };
      },
      { regionData: {}, totalDealersWithCars: 0, totalDealers: 0 },
    );

    const regions = Object.keys(dataByRegion.regionData);

    const datasets = [
      {
        label: t('reports.dealerCars.withCars'),
        data: regions.map((r) => dataByRegion.regionData[r].withCars),
        backgroundColor: sequenceColour(0),
      },
      {
        label: t('reports.dealerCars.withoutCars'),
        data: regions.map((r) => dataByRegion.regionData[r].withoutCars),
        backgroundColor: sequenceColour(1),
      },
    ];

    //Table data
    const firstRow: RowType = {
      header: t('reports.dealerCars.dealersWithUpload'),
      id: 0,
      //Transform regions to props for Dealers with cars
      ...regions.reduce((rr, r) => {
        return {
          ...rr,
          [r]: dataByRegion.regionData[r].withCars,
        };
      }, {}),
      total: dataByRegion.totalDealersWithCars,
    };

    const secondRow: RowType = {
      header: t('reports.dealerCars.percentage'),
      id: 1,
      //Transform regions to props for percentage of dealers with cars
      ...regions.reduce((rr, r) => {
        return {
          ...rr,
          [r]: dataByRegion.regionData[r].percentage,
        };
      }, {}),
      total: formatPercentage(
        dataByRegion.totalDealersWithCars / (dataByRegion.totalDealers || 1),
      ),
    };

    const columns: GridColDef[] = [
      { field: 'header', flex: 2, headerName: '' },
      ...regions.map((c) => ({ field: c, flex: 1 })),
      { field: 'total', flex: 1, headerName: t('reports.dealerCars.total') },
    ];

    return {
      chartData: {
        labels: regions,
        datasets,
      },
      rows: [firstRow, secondRow],
      columns,
    };
  }, [data, t]);

  const handleClick = useCallback(() => {
    setOpenDrawer(true);
  }, []);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="details" onClick={handleClick}>
              <Info />
            </IconButton>
          }
          subheader={t('reports.dealerCars.subtitle')}
          title={title}
        />
        <CardContent>
          {data.length === 0 ? (
            <EmptyState />
          ) : (
            <Bar data={chartData} options={chartOptions} />
          )}
        </CardContent>
      </Card>
      <MainDrawer onClose={handleClose} open={openDrawer} title={title}>
        <DataGrid autoHeight columns={columns} hideFooter rows={rows} />
      </MainDrawer>
    </>
  );
};

export default DealersCarsReport;
