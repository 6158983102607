import { ClientError } from './ClientError.js';

export class ForbiddenError extends ClientError {
  constructor(
    service: string,
    public readonly method: string,
    public readonly principal: string,
    public readonly requiredScope: string,
  ) {
    super(
      service,
      'Forbidden',
      `principal ${principal} does not have access to ${service}:${method} (need ${requiredScope})`,
      undefined,
      403,
    );
  }
}
