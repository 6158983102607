import { enumValue, integer, maybe, object, string } from '@fmtk/decoders';
import { VehicleMediaType } from '../../util/VehicleMedia.js';

export interface DeleteMediaRequest {
  vinMd5Hash: string;
  position?: number;
  cloudinaryId?: string;
  type: VehicleMediaType;
}

export const decodeDeleteMediaRequest = object<DeleteMediaRequest>({
  vinMd5Hash: string,
  position: maybe(integer),
  cloudinaryId: maybe(string),
  type: enumValue(VehicleMediaType),
});
