import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { RegionCountry } from '../../../../api/BrandService/listRegionCountry/ListRegionCountryResponse.js';
import RegionAccordion from './RegionAccordion.js';

export interface Country {
  countryCode: string;
  countryName: string;
  selected?: boolean;
}
export type RegionCountries = Record<string, Country[]>;

interface RegionSelectorProps {
  regions: RegionCountry[];
  countries: string[];
  disabled?: boolean;
  onChange?: (regions: RegionCountries) => void;
}

const RegionSelector: FunctionComponent<RegionSelectorProps> = ({
  regions,
  countries,
  disabled,
  onChange,
}) => {
  const [regionCountries, setRegionCountries] = useState<
    RegionCountries | undefined
  >();

  const handleChange = useCallback(
    (region: string, select?: boolean, country?: Country) => {
      const currentRegion = regionCountries ? regionCountries[region] : [];
      const updatedRegion = currentRegion.map((c) => {
        if (country) {
          return c.countryCode === country.countryCode
            ? { ...c, selected: !c.selected }
            : c;
        } else {
          return { ...c, selected: select };
        }
      });

      const newRegionCountriesState = {
        ...regionCountries,
        [region]: updatedRegion,
      };

      setRegionCountries(newRegionCountriesState);

      onChange && onChange(newRegionCountriesState);
    },
    [onChange, regionCountries],
  );

  useEffect(() => {
    setRegionCountries(
      regions.reduce(
        (
          regionCountries: RegionCountries,
          { regionName, countryCode, countryName },
        ) => {
          const currentRegion = regionCountries[regionName] || [];
          return {
            ...regionCountries,
            [regionName]: [
              ...new Set([
                ...currentRegion,
                {
                  countryCode,
                  countryName,
                  selected: countries.includes(countryCode),
                },
              ]),
            ],
          };
        },
        {},
      ),
    );
  }, [countries, regions]);

  return (
    <div>
      {Object.entries(regionCountries || {}).map(([region, countries]) => (
        <RegionAccordion
          deselectedCountries={countries.filter((c) => !c.selected)}
          disabled={disabled}
          key={region}
          onChange={(_, selected) => {
            handleChange(region, selected);
          }}
          onCountryChipDelete={(country) =>
            handleChange(region, undefined, country)
          }
          region={region}
          selectedCountries={countries.filter((c) => c.selected)}
        />
      ))}
    </div>
  );
};

export default RegionSelector;
