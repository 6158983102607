import { PublishedWithChanges } from '@mui/icons-material';
import { Grid, Stack, TextField } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridValueFormatterParams,
} from '@mui/x-data-grid';
import dayjs, { Dayjs } from 'dayjs';
import { t } from 'i18next';
import { uniqueId } from 'lodash-es';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { LiveStatusChange } from '../../../../api/ReportingService/listLiveStatusChanges/ListLiveStatusChangesResponse.js';
import { RegionModelStats } from '../../../../api/ReportingService/listRegionModelStats/ListRegionModelStatsResponse.js';
import { DownloadCsvResponse } from '../../../../api/util/DownloadCsvResponse.js';
import ErrorState from '../../../common-ui/components/ErrorState.js';
import { GridToolbar } from '../../../common-ui/index.js';
import {
  useApiClient,
  useAsyncState,
  useBrand,
  useDebounce,
} from '../../../hooks/index.js';
import { formatDate, removeTimeFromDate } from '../../../util/dates.js';
import { downloadAsCsv, formatNumber, sx } from '../../../util/index.js';
import { formatMoney } from '../../../util/money.js';
import DateField from '../Forms/DateField.js';
import MainDrawer from '../MainDrawer.js';
import ReasonLabel from '../ReasonLabel.js';
import SummaryCard from '../SummaryCard.js';
import VehicleStatusLabel from '../VehicleStatusLabel.js';

export interface LiveStatusChangesCardProps {
  data: RegionModelStats[];
  loading?: boolean;
}

export const styles = sx({
  summaryCard: {
    cursor: 'pointer',
  },
  totalCard: {
    boxShadow: 0,
  },
  summaryPaper: {
    p: 1,
    textAlign: 'center',
    flex: 1,
  },
  carsCount: {
    color: 'text.secondary',
  },
  dataGrid: {
    bgcolor: 'background.paper',
  },
});

const PAGE_SIZE = 50;
const PAGE_NUMBER = 0;
``;
const FILTER_DEBOUNCE_TIME = 1500;

const LiveStatusChangesCard: FunctionComponent = () => {
  const title = t('pages.dealerDashboard.liveStatusChanges');
  const [open, setOpen] = useState(false);
  const { currentBrand } = useBrand();
  const api = useApiClient();

  // Filters states
  const [startDate, setStartDate] = useState<Dayjs | null>(null);
  const [endDate, setEndDate] = useState<Dayjs | null>(dayjs(null));
  const [validationError, setValidationError] = useState<string | null>(null);
  const [vin, setVin] = useState<string>('');
  const debouncedStartDate = useDebounce<Dayjs | null>(
    startDate,
    FILTER_DEBOUNCE_TIME,
  );
  const debouncedEndDate = useDebounce<Dayjs | null>(
    endDate,
    FILTER_DEBOUNCE_TIME,
  );
  const debouncedVin = useDebounce<string>(vin, FILTER_DEBOUNCE_TIME);

  // DataGrid states
  const [liveStatusChangesData, setLiveStatusChangesData] =
    useAsyncState<LiveStatusChange[]>();
  const [page, setPage] = useState(PAGE_NUMBER);
  const [pageSize, setPageSize] = useState<number>(PAGE_SIZE);

  const [downloadState, setDownloadState] =
    useAsyncState<DownloadCsvResponse>();

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const listVehicles = useCallback(async () => {
    if (!currentBrand) {
      return;
    }

    setValidationError(null);
    if (debouncedStartDate && debouncedEndDate) {
      if (debouncedStartDate?.isAfter(debouncedEndDate)) {
        setValidationError('The end date must be after the start date.');
        return;
      }

      if (debouncedEndDate?.diff(debouncedStartDate, 'month') > 3) {
        setValidationError('Please select a range smaller than 3 months.');
        return;
      }

      return await api.reporting.listLiveStatusChanges({
        brand: currentBrand!,
        startDate: removeTimeFromDate(debouncedStartDate),
        endDate: removeTimeFromDate(debouncedEndDate),
        vin: debouncedVin,
        amountOfRows: pageSize,
        pageNumber: page,
      });
    }
  }, [
    api.reporting,
    currentBrand,
    debouncedStartDate,
    debouncedEndDate,
    debouncedVin,
    pageSize,
    page,
  ]);

  const onCardClick = () => {
    setValidationError(null);
    setStartDate(dayjs(new Date()).subtract(90, 'days'));
    setEndDate(dayjs(new Date()));
    setPageSize(PAGE_SIZE);
    setPage(PAGE_NUMBER);
    handleOpen();
  };

  const handleChangePage = (newPage: number) => {
    setPage(newPage);
  };

  const buildGridColumns = (): GridColDef[] => {
    return [
      {
        field: 'vin',
        headerName: t('pages.dealerDashboard.vin'),
        width: 180,
        sortable: false,
      },
      {
        field: 'visible',
        headerName: t('pages.dealerDashboard.status'),
        minWidth: 300,
        sortable: false,
        renderCell: ({ value, row }: GridRenderCellParams<boolean>) => {
          const { reason } = row;

          return value ? (
            <VehicleStatusLabel visible />
          ) : (
            <ReasonLabel isError reason={reason || ''} />
          );
        },
      },
      {
        field: 'statusChangedDate',
        headerName: t('pages.dealerDashboard.date'),
        width: 120,
        sortable: false,
        valueFormatter: (params: GridValueFormatterParams<string>) =>
          formatDate(new Date(params.value)),
      },
      {
        field: 'modelName',
        headerName: t('pages.dealerDashboard.title'),
        width: 250,
        sortable: false,
      },
      {
        field: 'modelCode',
        headerName: t('pages.dealerDashboard.modelCode'),
        sortable: false,
      },
      {
        field: 'dealerName',
        headerName: t('pages.dealerDashboard.dealerName'),
        width: 350,
        sortable: false,
      },
      {
        field: 'price',
        headerName: t('pages.dealerDashboard.price'),
        width: 130,
        sortable: false,
        align: 'right',
        valueFormatter: (params: GridValueFormatterParams<string>) => {
          if (isNaN(parseFloat(params.value))) {
            return params.value;
          }
          return formatMoney(Number(params.value));
        },
      },
      {
        field: 'currency',
        headerName: t('pages.dealerDashboard.currency'),
        width: 80,
        sortable: false,
      },
      {
        field: 'odometerValue',
        headerName: t('pages.dealerDashboard.odometerReading'),
        width: 80,
        sortable: false,
        align: 'right',
        valueFormatter: (params: GridValueFormatterParams<string>) => {
          if (isNaN(Number(params.value))) {
            return params.value;
          }
          return formatNumber(Number(params.value));
        },
      },
      {
        field: 'odometerUnits',
        headerName: t('pages.dealerDashboard.odometerUnit'),
        width: 80,
        sortable: false,
      },
    ];
  };

  const handleDownload = useCallback(() => {
    setDownloadState(async () => {
      if (!currentBrand || !debouncedStartDate || !debouncedEndDate) {
        return;
      }
      const csvResponse = await api.reporting.downloadLiveStatusChangesCsv({
        brand: currentBrand,
        startDate: removeTimeFromDate(debouncedStartDate),
        endDate: removeTimeFromDate(debouncedEndDate),
        vin: debouncedVin,
        locale: navigator.language,
      });

      downloadAsCsv(csvResponse.content, csvResponse.fileName);
      return csvResponse;
    });
  }, [
    api.reporting,
    currentBrand,
    setDownloadState,
    debouncedEndDate,
    debouncedStartDate,
    debouncedVin,
  ]);

  const NoRowsFound = () => (
    <Stack alignItems="center" height="100%" justifyContent="center">
      No data found
    </Stack>
  );

  useEffect(() => {
    setPageSize(PAGE_SIZE);
    setPage(PAGE_NUMBER);
  }, [debouncedStartDate, debouncedEndDate, debouncedVin]);

  // Initial data load
  useEffect(() => {
    setLiveStatusChangesData(listVehicles());
  }, [setLiveStatusChangesData, listVehicles]);

  return (
    <>
      <SummaryCard
        color="secondary"
        icon={<PublishedWithChanges />}
        onClick={onCardClick}
        subtitle={title}
        sx={styles.summaryCard}
        title={t('pages.dealerDashboard.auditLog')}
      />
      <MainDrawer onClose={handleClose} open={open} title={title}>
        <Stack spacing={4}>
          <Grid container spacing={2}>
            <Grid item lg={3} md={4} sm={6} xs={6}>
              <DateField
                label="Start"
                onChange={setStartDate}
                value={startDate}
              />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={6}>
              <DateField label="End" onChange={setEndDate} value={endDate} />
            </Grid>
            <Grid item lg={3} md={4} sm={6} xs={12}>
              <TextField
                label="VIN"
                onChange={(e) => {
                  setVin(e.target.value);
                }}
                value={vin}
              />
            </Grid>
          </Grid>
          {validationError && <ErrorState error={validationError} hideTitle />}
          <DataGrid
            autoHeight
            columns={buildGridColumns()}
            components={{
              NoRowsOverlay: () => <NoRowsFound />,
              NoResultsOverlay: () => <NoRowsFound />,
              Toolbar: GridToolbar,
            }}
            componentsProps={{
              toolbar: {
                downloadOptions: {
                  onClickDownload: handleDownload,
                  downloading: downloadState.loading,
                  error: !!downloadState.error,
                },
              },
            }}
            disableColumnFilter
            disableColumnMenu
            disableColumnSelector
            disableSelectionOnClick
            getRowId={(r) => uniqueId('id')}
            loading={liveStatusChangesData.loading}
            onPageChange={handleChangePage}
            pageSize={pageSize}
            paginationMode="server"
            rowCount={
              liveStatusChangesData.value && liveStatusChangesData.value[0]
                ? liveStatusChangesData.value[0].totalRows
                : 0
            }
            rows={liveStatusChangesData.value || []}
            rowsPerPageOptions={[PAGE_SIZE]}
            sx={styles.dataGrid}
          />
        </Stack>
      </MainDrawer>
    </>
  );
};

export default LiveStatusChangesCard;
