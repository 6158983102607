export enum QueryKeys {
  InAppNotifications = 'InAppNotifications',
  RemarketingOfferAdmin = 'RemarketingOfferAdmin',
  RemarketingOfferUser = 'RemarketingOfferUser',
  RemarketingVehiclesAdmin = 'RemarketingVehiclesAdmin',
  RemarketingVehiclesUser = 'RemarketingVehiclesUser',
  UserSettings = 'UserSettings',
  ListRemarketingForAdmin = 'ListRemarketingForAdmin',
  ListRemarketingForUser = 'ListRemarketingForUser',
  CountRemarketingForAdmin = 'CountRemarketingForAdmin',
  CountRemarketingForUser = 'CountRemarketingForUser',
  SearchVehicleListings = 'SearchVehicleListings',
  CountVehicleListing = 'CountVehicleListing',
  ArchiveVehicleListingProcess = 'ArchiveVehicleListingProcess',
}
