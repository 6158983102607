import { Select, SelectProps, Stack } from '@mui/material';
import { FormFieldError, hasError, useForm } from '../index.js';

export type FormSelectInputProps = SelectProps & {
  name: string;
};

export const FormSelectInput = ({ name, ...props }: FormSelectInputProps) => {
  const [formState, formBind] = useForm();
  return (
    <Stack>
      <Select
        onChange={(e) => {
          formBind.setValue(name, e.target.value);
        }}
        value={formState.values[name]}
        {...props}
      />
      {hasError(name) && <FormFieldError name={name} />}
    </Stack>
  );
};
