import { CircularProgress, Link, Stack, Typography } from '@mui/material';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { VehicleMediaStatus } from '../../../../api/util/VehicleMedia.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';

interface DownloadImagesButtonProps {
  cloudinaryIds: string[];
  fileName?: string;
}

const DownloadImagesButton: FunctionComponent<DownloadImagesButtonProps> = ({
  cloudinaryIds,
  fileName,
}) => {
  const { showMessage } = useMessageStore();
  const api = useApiClient();
  const { t } = useTranslation();
  const [downloadZipUrlState, setDownloadZipUrlState] = useAsyncState<string>();

  const handleDownload = useCallback(
    (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
      e.stopPropagation();
      setDownloadZipUrlState(async () => {
        const response = await api.media.downloadZipUrl({
          cloudinaryIds: cloudinaryIds,
          fileName: fileName,
        });
        window.open(response, '_blank', 'noreferrer,noopener');
        return response;
      });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [api, cloudinaryIds],
  );

  useEffect(() => {
    if (!!downloadZipUrlState.error) {
      showMessage({
        severity: 'error',
        dismissible: true,
        text: t('errorOccurredMessage'),
      });
    }
  }, [downloadZipUrlState.error, showMessage, t]);

  return (
    <Stack alignItems="center" direction="row" spacing={0.5}>
      <Typography component="div" variant="body2">
        <Link
          color="primary"
          component="button"
          disabled={downloadZipUrlState.loading}
          onClick={handleDownload}
          textTransform="capitalize"
          variant="button"
        >
          {t('terms.downloadImages', {
            count: cloudinaryIds.length,
            status: t(`vehicleMediaStatus.${VehicleMediaStatus.Approved}`),
          })}
        </Link>
      </Typography>
      {downloadZipUrlState.loading && <CircularProgress size={14} />}
    </Stack>
  );
};

export default DownloadImagesButton;
