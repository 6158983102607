import { LoadingButton } from '@mui/lab';
import { Typography } from '@mui/material';
import { FunctionComponent, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ConfirmModal } from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { useToggle } from '../../../hooks/useToggle.js';
import { useRelistVehicleListing } from '../../hooks/mutations/useRelistVehicleListing.js';

interface RelistButtonProps {
  vehicleListingId: string;
}

const RelistButton: FunctionComponent<RelistButtonProps> = ({
  vehicleListingId,
}) => {
  const { t } = useTranslation();
  const [isConfirmOpen, toggleConfirmOpen] = useToggle();
  const { isEditor } = useBrand();
  const { mutateAsync: relist, isPending } = useRelistVehicleListing();
  const [disabled, setDisabled] = useState(false);

  const handleRelist = useCallback(async () => {
    await relist({
      id: vehicleListingId,
    });
    toggleConfirmOpen();
    setDisabled(true);
  }, [relist, toggleConfirmOpen, vehicleListingId]);

  return (
    <>
      {isEditor ? (
        <LoadingButton
          color="primary"
          disabled={disabled}
          loading={isPending}
          onClick={toggleConfirmOpen}
          size="small"
          type="button"
          variant="contained"
        >
          {t('pages.remarketingVehiclePage.header.relistVehicle')}
        </LoadingButton>
      ) : null}
      <ConfirmModal
        cancelText={t('back')}
        confirmText={t('pages.remarketingVehiclePage.header.relistVehicle')}
        fullWidth
        maxWidth="xs"
        onClose={toggleConfirmOpen}
        onConfirm={handleRelist}
        open={isConfirmOpen}
        title={t('pages.remarketingVehiclePage.header.relistVehicle')}
      >
        <Typography variant="body2">
          {t('pages.vehiclePage.header.confirmRelistText')}
        </Typography>
      </ConfirmModal>
    </>
  );
};

export default RelistButton;
