import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { UserSettings } from '../../../../api/util/UserSettings.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { makeUserSettingsQueryKeys } from '../queries/useUserSettings.js';

export const useUpdateUserSettings = (): UseMutationResult<
  UserSettings | undefined,
  unknown,
  UserSettings
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  return useMutation({
    mutationFn: async (req) => {
      return await api.user.upsertUserSettings(req);
    },
    onSuccess: (userSettings, req) => {
      queryClient.setQueriesData<UserSettings>(
        {
          queryKey: makeUserSettingsQueryKeys({ principalId: req.userId }),
        },
        (prev) => {
          if (prev) {
            return { ...prev, ...userSettings };
          }
        },
      );
    },
    onError: () => {
      showMessage({
        severity: 'error',
        dismissible: true,
        text: t(`errorOccurredMessage`),
      });
    },
  });
};
