import { Box, Divider, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { sx } from '../../../../util/sx.js';
import { Section } from './types.js';

type FeaturesProps = Omit<
  Section,
  'vehicleListing' | 'readonly' | 'actionType'
> & {
  features?: VehicleFeatures;
};

const localStyles = sx({
  features: {
    columnCount: {
      xs: 1,
      sm: 2,
      md: 3,
    },
  },
});

const Features: FunctionComponent<FeaturesProps> = ({
  title,
  sectionItemsProps,
  features,
}) => {
  if (!features) {
    return null;
  }

  //This condition is defined by business rule in ticket MT-2091
  //If a feature category title is === ‘default’ we should not show a title.
  const isDefault = (category: string) => {
    return category === 'default';
  };

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack sx={sectionStyles.sectionContent}>
        <Stack flex={1} mt={1} spacing={2}>
          {Object.entries(features).map(([k, values], index) => (
            <Stack
              bgcolor="background.default"
              divider={<Divider color="#e4eaf1" />}
              flex={1}
              key={index}
              p={2}
              spacing={0.5}
              sx={{
                borderBottom: 1,
                borderTop: 1,
                borderColor: '#e4eaf1',
              }}
            >
              {!isDefault(k) && (
                <Typography color="#637281" variant="subtitle2">
                  {k}
                </Typography>
              )}
              <Box columnGap={2} sx={localStyles.features}>
                {values?.map((v, idx) => (
                  <div key={idx}>
                    <Typography color="#626F86" variant="cell">
                      {v}
                    </Typography>
                  </div>
                ))}
              </Box>
            </Stack>
          ))}
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Features;
