import { Checkbox, FormControlLabel, Stack, Typography } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Option } from '../../../util/Option.js';

interface FilterMultiselectProps<T> {
  options: Option[];
  selected: Set<T> | T;
  onChange: (value: T) => void;
  title: string;
  hideCounts?: boolean;
  counts?: Record<string, number | undefined>;
  disableEmpty?: boolean;
}

const FilterMultiselect = <T extends string | number = string>({
  options,
  counts,
  selected,
  onChange,
  title,
  hideCounts,
  disableEmpty = false,
}: FilterMultiselectProps<T>) => {
  const { t } = useTranslation();
  return (
    <Stack>
      <Typography
        color="grey.600"
        fontWeight="fontWeightMedium"
        lineHeight={1.2}
        p={1}
      >
        {title}:
      </Typography>
      <Stack>
        {options.map(({ text, value }) => {
          const checked =
            typeof selected === 'object'
              ? selected.has(value as T)
              : selected === value;
          const itemCounts = (counts && counts[value]) || 0;
          const disabled = disableEmpty && itemCounts === 0;
          return (
            <Stack
              alignItems="center"
              bgcolor={checked ? 'grey.100' : undefined}
              borderRadius={0.5}
              direction="row"
              justifyContent="space-between"
              key={value}
              px={1}
            >
              <FormControlLabel
                control={
                  <Checkbox
                    checked={checked}
                    disableRipple
                    disabled={disabled}
                    onChange={() => onChange(value as T)}
                    sx={{
                      padding: '8px',
                    }}
                  />
                }
                disabled={disabled}
                label={t(text, { defaultValue: text })}
                slotProps={{
                  typography: {
                    color: 'text.secondary',
                    fontWeight: 'fontWeightMedium',
                    lineHeight: 1.2,
                    fontSize: 14,
                    sx: checked ? { color: 'primary.main' } : undefined,
                  },
                }}
              />
              {!hideCounts && (
                <Typography
                  color={
                    disabled
                      ? 'text.disabled'
                      : checked
                      ? 'primary.main'
                      : 'text.secondary'
                  }
                  fontSize={14}
                  fontWeight="fontWeightMedium"
                  lineHeight={1.2}
                >
                  {itemCounts}
                </Typography>
              )}
            </Stack>
          );
        })}
      </Stack>
    </Stack>
  );
};

export default FilterMultiselect;
