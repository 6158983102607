import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { None } from '../../../../api/util/none.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useBrand } from '../../../hooks/useBrand.js';
import {
  SearchVehicleListingsData,
  makeSearchVehicleListingsQueryKey,
} from '../queries/useSearchVehicleListings.js';

export const useDeleteVehicleListing = (): UseMutationResult<
  None,
  unknown,
  { vehicleId: string }
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const { currentBrand } = useBrand();

  return useMutation({
    mutationFn: async ({ vehicleId }) => {
      return await api.vehicleListing.deleteVehicleListing({ id: vehicleId });
    },
    onSuccess: (_, { vehicleId }) => {
      queryClient.setQueriesData<SearchVehicleListingsData>(
        {
          queryKey: makeSearchVehicleListingsQueryKey(
            currentBrand as AvailableBrands,
          ),
        },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => {
                return {
                  ...p,
                  items: p.items.filter((x) => x.id !== vehicleId),
                  count: Math.max(p.count - 1, 0),
                };
              }),
            };
          }
        },
      );
    },
  });
};
