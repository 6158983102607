import { ChartOptions } from 'chart.js';

export const getLabels = (years: number) =>
  [...Array(years)].map((_, i) => `Year ${i + 1 * 1}`);

export const options: ChartOptions<'line'> = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        title: (tooltipItems: any): string | string[] =>
          tooltipItems[0].dataset.label,
        label: (context: any): string | string[] => {
          let price = '';
          if (context.parsed.y !== null) {
            price += new Intl.NumberFormat('en-GB', {
              style: 'currency',
              currency: context.dataset.currency,
              maximumFractionDigits: 0,
            }).format(context.parsed.y);
          }

          return `Age: ${context.parsed.x + 1}, avg price: ${price}`;
        },
      },
    },
  },
  scales: {
    x: {
      title: {
        display: true,
        text: 'Vehicle age',
      },
    },
    y: {
      title: {
        display: true,
        text: 'Average vehicle price',
      },
    },
  },
};
