import { AnalyticsServiceDef } from './AnalyticsService/AnalyticsServiceDef.js';
import { BrandServiceDef } from './BrandService/BrandServiceDef.js';
import { CallbackServiceDef } from './CallbackService/CallbackServiceDef.js';
import { DealerServiceDef } from './DealerService/DealerServiceDef.js';
import { DocumentServiceDef } from './DocumentService/DocumentServiceDef.js';
import { ExternalServiceDef } from './ExternalService/ExternalServiceDef.js';
import { MediaServiceDef } from './MediaService/MediaServiceDef.js';
import { InAppNotificationsServiceDef } from './NotificationsService/InAppNotificationsServiceDef.js';
import { RealTimeServiceDef } from './RealTimeService/RealTimeServiceDef.js';
import { RemarketingServiceDef } from './RemarketingService/RemarketingServiceDef.js';
import { ReportingServiceDef } from './ReportingService/ReportingServiceDef.js';
import { UserServiceDef } from './UserService/UserServiceDef.js';
import { VehicleListingServiceDef } from './VehicleListingService/VehicleListingServiceDef.js';
import { VehicleServiceDef } from './VehicleService/VehicleServiceDef.js';

export const ApiDef = {
  analytics: AnalyticsServiceDef,
  brand: BrandServiceDef,
  callback: CallbackServiceDef,
  dealer: DealerServiceDef,
  document: DocumentServiceDef,
  external: ExternalServiceDef,
  remarketing: RemarketingServiceDef,
  reporting: ReportingServiceDef,
  user: UserServiceDef,
  vehicleListing: VehicleListingServiceDef,
  media: MediaServiceDef,
  realTime: RealTimeServiceDef,
  vehicle: VehicleServiceDef,
  inAppNotifications: InAppNotificationsServiceDef,
};

export type ApiDef = typeof ApiDef;
