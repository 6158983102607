import {
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Skeleton,
  Stack,
} from '@mui/material';

export const UserDealerFromSkeleton = (): JSX.Element => (
  <Stack spacing={3}>
    <Card>
      <CardHeader title={<Skeleton variant="text" />} />
      <CardContent>
        <Stack spacing={1}>
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
          <Skeleton variant="rectangular" />
        </Stack>
      </CardContent>
      <CardActions>
        <Stack direction="row-reverse" spacing={2} width="100%">
          <Skeleton height={30} variant="rounded" width={80} />
          <Skeleton height={30} variant="rounded" width={80} />
        </Stack>
      </CardActions>
    </Card>
  </Stack>
);
