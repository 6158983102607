import { Request } from 'express';
import { Logger, pino } from 'pino';
import { URLSearchParams } from 'url';
import { getAppEnvironmentConfig } from '../../config/AppEnvironmentConfig.js';
import { LoggingConfigVars } from '../../config/LoggingConfig.js';
import { Auth0ImsAppMetadata } from './Auth0ImsAppMetadata.js';
import { ForbiddenError } from './ForbiddenError.js';
import { PolicyDocument, PolicyPermissionLike } from './PolicyDocument.js';
import { ServiceError } from './ServiceError.js';
import { UnauthorizedError } from './UnuthorizedError.js';

const up = new Date();

export interface RequestAuthContext {
  appMetadata?: Auth0ImsAppMetadata;
  clientId: string;
  contractkey?: string;
  email?: string;
  emailVerified?: boolean;
  familyName?: string;
  givenName?: string;
  issuer: string;
  organization?: string;
  policy: PolicyDocument;
  sub: string;
  userId?: string;
}

export class RequestContext {
  private static _root: RequestContext | undefined;

  public static get root(): RequestContext {
    if (!this._root) {
      this._root = new RequestContext();
    }
    return this._root;
  }
  public static set root(value: RequestContext) {
    this._root = value;
  }

  public readonly appName: string;
  public readonly auth: RequestAuthContext | undefined;
  public readonly environment: string;

  private readonly _request?: Request;
  private readonly logger: Logger;
  private readonly traceOn: boolean;
  private readonly isProd: boolean;

  public get request(): Request {
    if (!this._request) {
      throw new Error(
        'there is no request associated with this request context - did you forget the `services` middleware?',
      );
    }
    return this._request;
  }

  public get queryParams(): URLSearchParams {
    if (!this.request.fullUrl) {
      throw new Error(
        'there is no `fullUrl` property on the request - did you forget the `fullUrl` middleware?',
      );
    }
    return this.request.fullUrl.searchParams;
  }

  constructor(opts?: {
    appName?: string;
    auth?: RequestAuthContext;
    environment?: string;
    logger?: Logger;
    meta?: Record<string, unknown>;
    request?: Request;
  }) {
    const appConfig = getAppEnvironmentConfig();

    this.appName = opts?.appName ?? appConfig.name;
    this.auth = opts?.auth;
    this.environment = opts?.environment ?? appConfig.environment;
    this.isProd = this.environment === 'prod';
    this._request = opts?.request;

    const meta = {
      ...opts?.meta,
      appVersion: appConfig.version,
      up,
    };

    if (opts?.logger) {
      this.logger = opts.logger.child(meta);
    } else {
      this.logger = pino({
        base: meta,
        level: process.env[LoggingConfigVars.LogLevel] ?? 'silent',
      });
    }

    this.traceOn = this.logger.isLevelEnabled('trace');
  }

  public endAuthorization(service: string, methodName: string): void {
    const partial = this.auth?.policy.getPartialMatches();
    if (!partial?.length) {
      return;
    }
    throw new ServiceError(
      service,
      'PartialAuth',
      'some scopes were only partially checked',
      {
        methodName,
        partialMatches: partial,
      },
    );
  }

  public logError(
    service: string,
    event: string,
    details?: Record<string, unknown>,
  ): void {
    this.logger.error({ service, event, ...details });
  }

  public logFatal(
    service: string,
    event: string,
    details?: Record<string, unknown>,
  ): void {
    this.logger.fatal({ service, event, ...details });
  }

  public logInfo(
    service: string,
    event: string,
    details?: Record<string, unknown>,
  ): void {
    this.logger.info({ service, event, ...details });
  }

  public logTrace(
    service: string,
    event: string,
    details?: Record<string, unknown>,
  ): void {
    this.logger.trace({ service, event, ...details });
  }

  /**
   * Logs a trace message, including the service name, event, and optional details,
   * but only when the environment is not in production.
   *
   * @param {string} service - The name of the service or component.
   * @param {string} event - The event or action being logged.
   * @param {Record<string, unknown>} [details] - Optional additional information related to the event.
   * @returns {void} This function does not return a value.
   *
   * @example
   * logProtectedTrace('userService', 'userLogin', { userId: 1234 });
   * // Logs a trace if not in production environment.
   */
  public logProtectedTrace(
    service: string,
    event: string,
    details?: Record<string, unknown>,
  ): void {
    if (!this.isProd) {
      this.logger.trace({ service, event, ...details });
    }
  }

  public logWarn(
    service: string,
    event: string,
    details: Record<string, unknown>,
  ): void {
    this.logger.warn({ service, event, ...details });
  }

  public requireAnySetOfScopes(
    serviceName: string,
    method: string,
    ...scopes: PolicyPermissionLike[][]
  ): void {
    const auth = this.auth;
    if (!auth) {
      this.logError(serviceName, 'Unauthorized', { method, scopes });
      throw new UnauthorizedError(serviceName, method);
    }
    if (!scopes.length) {
      return;
    }

    for (const setScope of scopes) {
      let authorized = true;
      for (const scope of setScope) {
        const name = typeof scope === 'string' ? scope : scope.name;
        const resource = typeof scope === 'string' ? undefined : scope.resource;
        authorized = authorized && auth.policy.hasPermission(name, resource);
      }

      if (authorized) {
        return;
      }
    }

    this.logError(serviceName, 'Forbidden', {
      auth: this.auth,
      method,
      scopes,
    });
    throw new ForbiddenError(
      serviceName,
      method,
      auth.sub,
      scopes
        .map((z) =>
          z
            .map((x) =>
              typeof x === 'string'
                ? x
                : x.resource
                ? `${x.name}:${x.resource}`
                : x.name,
            )
            .join('|'),
        )
        .join(' or '),
    );
  }

  public requireAnyScope(
    serviceName: string,
    method: string,
    ...scopes: PolicyPermissionLike[]
  ): void {
    const auth = this.auth;
    if (!auth) {
      this.logError(serviceName, 'Unauthorized', { method, scopes });
      throw new UnauthorizedError(serviceName, method);
    }
    if (!scopes.length) {
      return;
    }
    if (!auth.policy.hasAnyPermission(...scopes)) {
      this.logError(serviceName, 'Forbidden', {
        auth: this.auth,
        method,
        scopes,
      });
      throw new ForbiddenError(
        serviceName,
        method,
        auth.sub,
        scopes
          .map((x) =>
            typeof x === 'string'
              ? x
              : x.resource
              ? `${x.name}:${x.resource}`
              : x.name,
          )
          .join(' | '),
      );
    }
  }

  public requireScope(
    serviceName: string,
    method: string,
    scope: string,
    resource?: string,
  ): void {
    if (!this.auth) {
      this.logError(serviceName, 'Unauthorized', { method, scope });
      throw new UnauthorizedError(serviceName, method);
    }
    if (!this.auth.policy.hasPermission(scope, resource)) {
      this.logError(serviceName, 'Forbidden', {
        auth: this.auth,
        method,
        scope,
      });
      throw new ForbiddenError(
        serviceName,
        method,
        this.auth.sub,
        resource ? `${scope} on ${resource}` : scope,
      );
    }
  }

  public traceEvent(
    service: string,
    event: string,
    trace?: unknown,
    details?: Record<string, unknown>,
  ): void {
    if (this.traceOn) {
      this.logTrace(service, event, { ...details, trace });
    } else {
      this.logInfo(service, event, details);
    }
  }

  public withAuth(auth: RequestAuthContext): RequestContext {
    return new RequestContext({
      appName: this.appName,
      auth,
      environment: this.environment,
      logger: this.logger,
      meta: {
        authClientId: auth.clientId,
        authIssuer: auth.issuer,
        authSub: auth.sub,
      },
      request: this._request,
    });
  }

  public withMeta(meta: Record<string, unknown>): RequestContext {
    return new RequestContext({
      appName: this.appName,
      auth: this.auth,
      environment: this.environment,
      logger: this.logger,
      meta,
      request: this._request,
    });
  }

  public withRequest(request: Request): RequestContext {
    return new RequestContext({
      appName: this.appName,
      auth: this.auth,
      environment: this.environment,
      logger: this.logger,
      request,
    });
  }
}
