import { enumValue, jsonDate, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface DownloadLiveStatusChangesCsvRequest {
  brand: AvailableBrands;
  startDate: Date;
  endDate: Date;
  vin: string;
  locale: string;
}

export const decodeDownloadLiveStatusChangesCsvRequest =
  object<DownloadLiveStatusChangesCsvRequest>({
    brand: enumValue(AvailableBrands),
    vin: string,
    startDate: jsonDate,
    endDate: jsonDate,
    locale: string,
  });
