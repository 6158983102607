import { object, string } from '@fmtk/decoders';

export interface DownloadCsvResponse {
  content: string;
  fileName: string;
}

export const decodeDownloadCsvResponse = object<DownloadCsvResponse>({
  content: string,
  fileName: string,
});
