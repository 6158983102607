import { array, maybe, number, object, string } from '@fmtk/decoders';
import {
  InAppNotification,
  decodeInAppNotification,
} from '../../util/InAppNotification.js';

export interface ListUserNotificationsResponse {
  items: InAppNotification[];
  unreadCount: number;
  total: number;
  nextToken?: string;
}

export const decodeListUserNotificationsResponse =
  object<ListUserNotificationsResponse>({
    items: array(decodeInAppNotification),
    unreadCount: number,
    total: number,
    nextToken: maybe(string),
  });
