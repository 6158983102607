export enum AnalyticsEventType {
  View = 'View',
  Enquire = 'Enquire',
  Print = 'Print',
  Compare = 'Compare',
  Shortlist = 'Shortlist',
}

export const analyticsEventTypeId: Record<AnalyticsEventType, number> = {
  [AnalyticsEventType.View]: 1,
  [AnalyticsEventType.Enquire]: 2,
  [AnalyticsEventType.Print]: 3,
  [AnalyticsEventType.Compare]: 4,
  [AnalyticsEventType.Shortlist]: 5,
};
