import {
  array,
  boolean,
  jsonDate,
  maybe,
  object,
  optional,
  string,
} from '@fmtk/decoders';
import { User } from '../../util/User.js';

export type DealerUser = Pick<
  User,
  | 'avatarUrl'
  | 'email'
  | 'familyName'
  | 'givenName'
  | 'principalId'
  | 'createDate'
  | 'lastLogin'
> & { fullName?: string; isAdmin?: boolean; hasRemarketing?: boolean };

export const decodeDealerUser = object<DealerUser>({
  avatarUrl: optional(string),
  email: string,
  familyName: string,
  givenName: string,
  principalId: string,
  fullName: optional(string),
  isAdmin: optional(boolean),
  hasRemarketing: optional(boolean),
  createDate: maybe(jsonDate),
  lastLogin: maybe(jsonDate),
});

export const decodeListUsersByDealerResponse = array(decodeDealerUser);
