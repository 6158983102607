import { chain, Decoder, invalid, ok, text } from '@fmtk/decoders';

export const InvalidUrl = 'INVALID_URL';

export const isValidUrl: Decoder<string, string> = (value) => {
  try {
    new URL(value);
    return ok(value);
  } catch {
    return invalid(InvalidUrl, 'expected a valid URL');
  }
};

export const validUrl = chain(text, isValidUrl);
