import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { AdminAcceptRejectOfferRequest } from '../../../../api/RemarketingService/adminAcceptRejectOffer/adminAcceptOfferRequest.js';
import { OfferActionStatus } from '../../../../api/util/RemarketingAction.js';
import { RemarketingActionType } from '../../../../api/util/RemarketingVehicle.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  ListAdminOfferQueryData,
  makeListAdminOffersQueryKey,
} from '../queries/useListAdminOffers.js';

export const useAcceptRejectOffer = (): UseMutationResult<
  void,
  unknown,
  AdminAcceptRejectOfferRequest
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async (values) => {
      await api.remarketing.adminAcceptRejectOffer({
        acceptOffer: values.acceptOffer,
        actionId: values.actionId,
        brand: values.brand,
        remarketingVehicleId: values.remarketingVehicleId,
        comment: values.comment,
      });
    },
    onSuccess: (_, req) => {
      queryClient.setQueriesData<ListAdminOfferQueryData>(
        {
          queryKey: makeListAdminOffersQueryKey(req),
        },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.map((offer) => {
                  if (offer.actionType !== RemarketingActionType.OFFER) {
                    return offer;
                  }

                  if (offer.id === req.actionId) {
                    return {
                      ...offer,
                      status: req.acceptOffer
                        ? OfferActionStatus.ACCEPTED
                        : OfferActionStatus.REJECTED,
                    };
                  }

                  if (req.acceptOffer && !offer.status) {
                    return {
                      ...offer,
                      status: OfferActionStatus.REJECTED,
                    };
                  }

                  return offer;
                }),
              })),
            };
          }
        },
      );
    },
  });
};
