import { Close, Download } from '@mui/icons-material';
import {
  Button,
  Dialog,
  DialogContent,
  IconButton,
  Stack,
  styled,
} from '@mui/material';
import { useTranslation } from 'react-i18next';
import { Carousel } from 'react-responsive-carousel';
import { downloadAsImg } from '../../../util/downloadFile.js';
import { sx } from '../../../util/sx.js';

const styles = sx({
  downloadButton: {
    position: 'absolute',
    zIndex: 2,
    right: 30,
    top: 2,

    boxShadow: '0px 0px 2px rgba(0, 0, 0, 0.8)',
    backgroundColor: 'rgba(255, 255, 255, 0.4)',
    borderRadius: 0.5,
    ':hover': {
      backgroundColor: 'rgba(255, 255, 255, 1)',
    },
  },
});

const StyledCarousel = styled(Carousel)(() => ({
  '& .carousel .thumbs-wrapper': {
    margin: 0,
    textAlign: 'center',
    '& ul': {
      padding: 0,
      marginBottom: 0,
    },
  },
  '& .carousel-slider': {
    flex: 1,
  },
  '& .slider-wrapper, .slider': {
    height: '100%',
  },
  '& .slide img': {
    width: 'auto',
    maxWidth: '100%',
    height: '100%',
    objectFit: 'contain',
  },
  '& .slide div': {
    height: '100%',
  },
  '& .control-dots .dot': {
    border: '1px solid',
    boxShadow: 'none',
  },
  display: 'flex',
  flexDirection: 'column',
  height: '80vh',
}));

export interface CarouselMedia {
  src: string;
  description: string;
  id: string;
}

export interface ImageCarouselProps {
  open: boolean;
  onClose: () => void;
  media: CarouselMedia[];
  selectedItem?: number;
}

export default function ImageCarousel({
  media,
  onClose,
  open,
  selectedItem,
}: ImageCarouselProps) {
  const { t } = useTranslation();
  const handleDownload = (media: CarouselMedia) => {
    downloadAsImg(media.src, media.description);
  };
  return (
    <Dialog fullWidth maxWidth="xl" onClose={onClose} open={open}>
      <Stack direction="row" justifyContent="end">
        <IconButton onClick={onClose}>
          <Close />
        </IconButton>
      </Stack>
      <DialogContent>
        <StyledCarousel
          autoFocus
          emulateTouch
          selectedItem={selectedItem}
          showStatus={false}
          useKeyboardArrows
        >
          {media.map((m) => (
            <div key={m.id}>
              <img alt={m.description} src={m.src} />
              <Button
                onClick={() => {
                  handleDownload(m);
                }}
                size="small"
                startIcon={<Download fontSize="small" />}
                sx={styles.downloadButton}
                variant="text"
              >
                {t('download')}
              </Button>
            </div>
          ))}
        </StyledCarousel>
      </DialogContent>
    </Dialog>
  );
}
