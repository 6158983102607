import { boolean, maybe, object, text } from '@fmtk/decoders';

export interface NotificationSettings {
  disableEmail?: boolean;
  disableInApp?: boolean;
}

export interface UserSettings {
  userId: string;
  notifications?: NotificationSettings;
}

export const decodeNotifications = object<NotificationSettings>({
  disableEmail: maybe(boolean),
  disableInApp: maybe(boolean),
});

export const decodeUserSettings = object<UserSettings>({
  userId: text,
  notifications: maybe(decodeNotifications),
});
