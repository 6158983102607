import {
  Decoder,
  DecoderError,
  DecoderOptions,
  DecodingAssertError,
} from '@fmtk/decoders';

export class DecodingAssertDetailError extends DecodingAssertError {
  constructor(errors: DecoderError[], public readonly inputValue: unknown) {
    super(errors);
  }

  public toJSON(): unknown {
    return {
      errors: this.errors,
      inputValue: this.inputValue,
      message: this.message,
      name: this.name,
      stack: this.stack,
    };
  }
}

export function assertWithDetailedError<T>(
  decoder: Decoder<T>,
  value: unknown,
  opts?: DecoderOptions,
): T {
  const result = decoder(value, opts);
  if (!result.ok) {
    throw new DecodingAssertDetailError(result.error, value);
  }
  return result.value;
}
