import { Stack } from '@mui/material';
import { FunctionComponent } from 'react';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CellTextInput } from '../../../../common-ui/index.js';
import { useVehicle } from '../../../../hooks/useVehicle.js';
import { VehicleFields } from '../../../../util/VehicleFields.js';
import { ActionType, Section } from './types.js';

type ColourSectionProps = Omit<Section, 'vehicleListing'>;

const Colour: FunctionComponent<ColourSectionProps> = ({
  title,
  sectionItemsProps,
  readonly,
  actionType = ActionType.EDIT,
}) => {
  const { fieldsConfig, resolveReadonly } = useVehicle();

  return (
    <SectionItem title={title} {...sectionItemsProps}>
      <Stack sx={sectionStyles.sectionContent}>
        <Stack flex={1}>
          {fieldsConfig.exteriorColour?.visible && (
            <CellTextInput
              label="exteriorColour"
              maxLength={100}
              name="exteriorColour"
              readonly={resolveReadonly(
                VehicleFields.ExteriorColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.secondaryExteriorColour?.visible && (
            <CellTextInput
              label="secondaryExteriorColour"
              maxLength={100}
              name="secondaryExteriorColour"
              readonly={resolveReadonly(
                VehicleFields.SecondaryExteriorColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.carpetColour?.visible && (
            <CellTextInput
              label="carpetColour"
              name="carpetColour"
              readonly={resolveReadonly(
                VehicleFields.CarpetColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.headliningColour?.visible && (
            <CellTextInput
              label="headliningColour"
              name="headliningColour"
              readonly={resolveReadonly(
                VehicleFields.HeadliningColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.seatColour?.visible && (
            <CellTextInput
              label="seatColour"
              name="seatColour"
              readonly={resolveReadonly(
                VehicleFields.SeatColour,
                actionType,
                readonly,
              )}
            />
          )}
        </Stack>
        <Stack flex={1}>
          {fieldsConfig.interiorColour?.visible && (
            <CellTextInput
              label="interiorColour"
              maxLength={100}
              name="interiorColour"
              readonly={resolveReadonly(
                VehicleFields.InteriorColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.secondaryInteriorColour?.visible && (
            <CellTextInput
              label="secondaryInteriorColour"
              maxLength={100}
              name="secondaryInteriorColour"
              readonly={resolveReadonly(
                VehicleFields.SecondaryExteriorColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.steeringWheelColour?.visible && (
            <CellTextInput
              label="steeringWheelColour"
              name="steeringWheelColour"
              readonly={resolveReadonly(
                VehicleFields.SteeringWheelColour,
                actionType,
                readonly,
              )}
            />
          )}
          {fieldsConfig.veneer?.visible && (
            <CellTextInput
              label="veneer"
              name="veneer"
              readonly={resolveReadonly(
                VehicleFields.Veneer,
                actionType,
                readonly,
              )}
            />
          )}
        </Stack>
      </Stack>
    </SectionItem>
  );
};

export default Colour;
