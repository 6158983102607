import { enumValue, maybe, normaliseNumber, text } from '@fmtk/decoders';
import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import { Box, Button, Stack, Typography } from '@mui/material';
import { t } from 'i18next';
import { useAtom } from 'jotai';
import { RESET } from 'jotai/utils';
import { DateTime } from 'luxon';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ListTaxonomyOptionsResponse } from '../../../../../api/VehicleService/listTaxonomyOptions/ListTaxonomyOptionsResponse.js';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import { HandDrive } from '../../../../../api/util/HandDrive.js';
import {
  FuelType,
  ListingStatus,
  Vehicle,
  getFuelTypeDisplayName,
  getFuelTypeId,
} from '../../../../../api/util/Vehicle.js';
import { normaliseDate } from '../../../../../util/decoders/normaliseDate.js';
import DetailsSkeleton from '../../../../common-ui/components/DetailsSkeleton.js';
import FormErrorsSummary from '../../../../common-ui/components/FormErrorsSummary.js';
import LightTooltip from '../../../../common-ui/components/LightTooltip.js';
import TableOfContents, {
  ContentItem,
} from '../../../../common-ui/components/TableOfContents.js';
import { ConfirmModal, ErrorState, Form } from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useBrand } from '../../../../hooks/useBrand.js';
import { useCreateForm } from '../../../../hooks/useCreateForm.js';
import { useDealer } from '../../../../hooks/useDealer.js';
import { filterFalsey } from '../../../../util/filterFalsey.js';
import { MAIN_CONTAINER_ID } from '../../../layout/MainLayout.js';
import {
  addVehicleStepperAtom,
  taxonomyOptionAtom,
} from '../../../state/atoms.js';
import Section from '../../Section.js';
import Colour from '../../Vehicle/Sections/Colour.js';
import Consumption from '../../Vehicle/Sections/Cosumption.js';
import Emission from '../../Vehicle/Sections/Emission.js';
import Engine from '../../Vehicle/Sections/Engine.js';
import Environmental from '../../Vehicle/Sections/Environmental.js';
import KeyInformation from '../../Vehicle/Sections/KeyInformation.js';
import TechSpec from '../../Vehicle/Sections/TechSpec.js';
import { ActionType } from '../../Vehicle/Sections/types.js';
const AddManuallyStep: FunctionComponent = () => {
  const api = useApiClient();
  const [optionsState, setOptions] =
    useAsyncState<ListTaxonomyOptionsResponse>();
  const [options, setTaxonomyOptions] = useAtom(taxonomyOptionAtom);
  const { currentBrand, isEditor, brandConfig, isViewer } = useBrand();
  const readonly = isViewer && !isEditor;
  const [addState, setAddState] = useAtom(addVehicleStepperAtom);
  const navigate = useNavigate();
  const { currentDealer } = useDealer();
  const [resetConfirm, setResetConfirm] = useState(false);
  const { brand, dealer } = useParams();

  const contentItems: ContentItem[] = [
    {
      text: t('pages.vehiclePage.details.keyInformation'),
      hash: 'keyInformation',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.colour'),
      hash: 'colour',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.engine'),
      hash: 'engine',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.techSpec'),
      hash: 'techSpec',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.emissions'),
      hash: 'emissions',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.consumptions'),
      hash: 'consumptions',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.details.environmental'),
      hash: 'environmental',
      sectionElementRef: useRef(null),
    },
  ];

  const toggleResetConfirm = () => {
    setResetConfirm((state) => !state);
  };

  const form = useCreateForm(
    {
      //Key Information
      vin: text,
      model: text,
      groupName: text,
      variant: maybe(text),
      bodyStyle: text,
      drive: maybe(enumValue(HandDrive)),
      registrationDate: maybe(normaliseDate()),
      modelYear: maybe(normaliseNumber),
      productionDate: maybe(normaliseDate()),
      capCode: maybe(text),
      capId: maybe(normaliseNumber),

      // appearanceOptions Colours
      exteriorColour: maybe(text),
      interiorColour: maybe(text),
      secondaryExteriorColour: maybe(text),
      secondaryInteriorColour: maybe(text),
      carpetColour: maybe(text),
      headliningColour: maybe(text),
      seatColour: maybe(text),
      steeringWheelColour: maybe(text),
      veneer: maybe(text),

      // engine
      engineType: maybe(text),
      fuelType: text,
      capacity: normaliseNumber,
      bhp: maybe(text),
      cylinders: maybe(text),
      powerKw: maybe(text),
      torqueLbft: maybe(text),
      torqueNm: maybe(text),
      transmission: text,

      // environmental
      environmentalLabel: maybe(text),
      euroEmissionsClass: maybe(text),

      // nedcTest
      nedcUrbanFuelEconomy: maybe(normaliseNumber),
      nedcExtraUrbanFuelEconomy: maybe(normaliseNumber),
      nedcCombinedFuelEconomy: maybe(normaliseNumber),
      nedcCo2Emissions: maybe(normaliseNumber),

      // wltpTest
      batteryRange: maybe(normaliseNumber),
      batteryRangeCity: maybe(normaliseNumber),
      co2ClassDischargedBattery: maybe(text),
      co2ClassWeightedCombined: maybe(text),
      co2Emissions: maybe(normaliseNumber),
      comsumptionCombinedDischargedBattery: maybe(normaliseNumber),
      consumptionCombined: maybe(normaliseNumber),
      consumptionCombinedWeighted: maybe(normaliseNumber),
      consumptionExtraHigh: maybe(normaliseNumber),
      consumptionHigh: maybe(normaliseNumber),
      consumptionLow: maybe(normaliseNumber),
      consumptionMid: maybe(normaliseNumber),
      powerConsumptionCombined: maybe(normaliseNumber),
      powerConsumptionCombinedWeighted: maybe(normaliseNumber),
      weightedCombined: maybe(normaliseNumber),

      // techSpecs
      maxSpeedMph: maybe(text),
      maxSpeedKph: maybe(text),
      kerbWeight: maybe(text),
      length: maybe(text),
      width: maybe(text),
      acceleration0100Kph: maybe(text),
      acceleration060Mph: maybe(text),

      // Brand-specific
      collection: maybe(text),
      lfsModelCode: maybe(text),
    },
    async (values) => {
      if (!currentDealer || !currentBrand) {
        return;
      }

      const vehicle: Vehicle = {
        vin: values.vin,
        appearanceOptions: {
          carpetColour: values.carpetColour,
          exteriorColour: values.exteriorColour,
          headliningColour: values.headliningColour,
          interiorColour: values.interiorColour,
          seatColour: values.seatColour,
          secondaryExteriorColour: values.secondaryExteriorColour,
          secondaryInteriorColour: values.secondaryInteriorColour,
          steeringWheelColour: values.steeringWheelColour,
          veneer: values.veneer,
        },
        engine: {
          id: Number(values.fuelType),
          bhp: values.bhp,
          capacity: values.capacity,
          cylinders: values.cylinders,
          description: values.engineType,
          powerKw: values.powerKw,
          torqueLbft: values.torqueLbft,
          torqueNm: values.torqueNm,
        },
        environmentalLabel: values.environmentalLabel,
        euroEmissionsClass: values.euroEmissionsClass,
        handDrive: values.drive,
        modelYear: values.modelYear,
        nedcTest: {
          nedcCo2Emissions: values.nedcCo2Emissions,
          nedcCombinedFuelEconomy: values.nedcCombinedFuelEconomy,
          nedcExtraUrbanFuelEconomy: values.nedcExtraUrbanFuelEconomy,
          nedcUrbanFuelEconomy: values.nedcUrbanFuelEconomy,
        },
        productionDate: values.productionDate,
        registrationDate: values.registrationDate,
        techSpecs: {
          acceleration0100Kph: values.acceleration0100Kph,
          acceleration060Mph: values.acceleration060Mph,
          kerbWeight: values.kerbWeight,
          length: values.length,
          maxSpeedKph: values.maxSpeedKph,
          maxSpeedMph: values.maxSpeedMph,
          width: values.width,
        },
        variant: values.variant,
        wltpTest: {
          batteryRange: values.batteryRange,
          batteryRangeCity: values.batteryRangeCity,
          co2ClassDischargedBattery: values.co2ClassDischargedBattery,
          co2ClassWeightedCombined: values.co2ClassWeightedCombined,
          co2Emissions: values.co2Emissions,
          comsumptionCombinedDischargedBattery:
            values.comsumptionCombinedDischargedBattery,
          consumptionCombined: values.consumptionCombined,
          consumptionCombinedWeighted: values.consumptionCombinedWeighted,
          consumptionExtraHigh: values.consumptionExtraHigh,
          consumptionHigh: values.consumptionHigh,
          consumptionLow: values.consumptionLow,
          consumptionMid: values.consumptionMid,
          powerConsumptionCombined: values.powerConsumptionCombined,
          powerConsumptionCombinedWeighted:
            values.powerConsumptionCombinedWeighted,
          weightedCombined: values.weightedCombined,
        },
        brand: currentBrand,
        bodyStyle: {
          id: Number(values.bodyStyle),
        },
        model: {
          id: Number(values.model),
          groupName: values.groupName,
        },
        transmission: { id: Number(values.transmission) },
      };

      setAddState((state) => ({
        ...state,
        currentListing: {
          ...state.currentListing,
          dealer: { id: Number(currentDealer.id) },
          stockDate: new Date(),
          listingStatus: ListingStatus.ForSale,
          vehicle: {
            ...state.currentListing?.vehicle,
            ...vehicle,
          },
        },
      }));
      navigate(`../details`);
    },
    undefined,
    [brandConfig],
  );

  const [formState, formBind] = form;

  useEffect(() => {
    if (!currentBrand || !!options) {
      return;
    }
    setOptions(async () => {
      const response = await api.vehicle.listTaxonomyOptions({
        brand: currentBrand,
      });
      setTaxonomyOptions(response);
      return response;
    });
  }, [api, currentBrand, options, setOptions, setTaxonomyOptions]);

  useEffect(() => {
    const { vehicle } = addState.currentListing || {};

    formBind.reset({
      vin: vehicle?.vin || addState.temporaryVin,
      model: String(vehicle?.model.id || ''),
      groupName: vehicle?.model.groupName || '',
      carpetColour: vehicle?.appearanceOptions?.carpetColour || '',
      exteriorColour: vehicle?.appearanceOptions?.exteriorColour || '',
      headliningColour: vehicle?.appearanceOptions?.headliningColour || '',
      interiorColour: vehicle?.appearanceOptions?.interiorColour || '',
      seatColour: vehicle?.appearanceOptions?.seatColour || '',
      secondaryExteriorColour:
        vehicle?.appearanceOptions?.secondaryExteriorColour || '',
      secondaryInteriorColour:
        vehicle?.appearanceOptions?.secondaryInteriorColour || '',
      steeringWheelColour:
        vehicle?.appearanceOptions?.steeringWheelColour || '',
      veneer: vehicle?.appearanceOptions?.veneer || '',
      fuelType: String(vehicle?.engine?.id || ''),
      bhp: vehicle?.engine?.bhp || '',
      capacity: vehicle?.engine?.capacity || '',
      cylinders: vehicle?.engine?.cylinders || '',
      engineType: vehicle?.engine?.description || '',
      powerKw: vehicle?.engine?.powerKw || '',
      torqueLbft: vehicle?.engine?.torqueLbft || '',
      torqueNm: vehicle?.engine?.torqueNm || '',
      environmentalLabel: vehicle?.environmentalLabel || '',
      euroEmissionsClass: vehicle?.euroEmissionsClass || '',
      drive: vehicle?.handDrive || '',
      modelYear: vehicle?.modelYear || '',
      nedcCo2Emissions: vehicle?.nedcTest?.nedcCo2Emissions || '',
      nedcCombinedFuelEconomy: vehicle?.nedcTest?.nedcCombinedFuelEconomy || '',
      nedcExtraUrbanFuelEconomy:
        vehicle?.nedcTest?.nedcExtraUrbanFuelEconomy || '',
      nedcUrbanFuelEconomy: vehicle?.nedcTest?.nedcUrbanFuelEconomy || '',
      productionDate: vehicle?.productionDate
        ? DateTime.fromJSDate(vehicle.productionDate).toISODate()
        : '',
      registrationDate: vehicle?.registrationDate
        ? DateTime.fromJSDate(vehicle.registrationDate).toISODate()
        : '',
      acceleration0100Kph: vehicle?.techSpecs?.acceleration0100Kph || '',
      acceleration060Mph: vehicle?.techSpecs?.acceleration060Mph || '',
      kerbWeight: vehicle?.techSpecs?.kerbWeight || '',
      length: vehicle?.techSpecs?.length || '',
      maxSpeedKph: vehicle?.techSpecs?.maxSpeedKph || '',
      maxSpeedMph: vehicle?.techSpecs?.maxSpeedMph || '',
      width: vehicle?.techSpecs?.width || '',
      variant: vehicle?.variant || '',
      capCode: vehicle?.model.capCode,
      capId: vehicle?.model.capId,
      bodyStyle: String(vehicle?.bodyStyle?.id || ''),
      transmission: String(vehicle?.transmission?.id || ''),
      collection:
        vehicle?.brandSpecific?.[AvailableBrands.RollsRoyce]?.collection || '',
      lfsModelCode:
        vehicle?.brandSpecific?.[AvailableBrands.Lamborghini]?.lfsModelCode ||
        '',
      // wltpTest
      batteryRange: vehicle?.wltpTest?.batteryRange || '',
      batteryRangeCity: vehicle?.wltpTest?.batteryRangeCity || '',
      co2ClassDischargedBattery:
        vehicle?.wltpTest?.co2ClassDischargedBattery || '',
      co2ClassWeightedCombined:
        vehicle?.wltpTest?.co2ClassWeightedCombined || '',
      co2Emissions: vehicle?.wltpTest?.co2Emissions || '',
      comsumptionCombinedDischargedBattery:
        vehicle?.wltpTest?.comsumptionCombinedDischargedBattery || '',
      consumptionCombined: vehicle?.wltpTest?.consumptionCombined || '',
      consumptionCombinedWeighted:
        vehicle?.wltpTest?.consumptionCombinedWeighted || '',
      consumptionExtraHigh: vehicle?.wltpTest?.consumptionExtraHigh || '',
      consumptionHigh: vehicle?.wltpTest?.consumptionHigh || '',
      consumptionLow: vehicle?.wltpTest?.consumptionLow || '',
      consumptionMid: vehicle?.wltpTest?.consumptionMid || '',
      powerConsumptionCombined:
        vehicle?.wltpTest?.powerConsumptionCombined || '',
      powerConsumptionCombinedWeighted:
        vehicle?.wltpTest?.powerConsumptionCombinedWeighted || '',
      weightedCombined: vehicle?.wltpTest?.weightedCombined || '',
    });
  }, [formBind, addState]);

  const [hasErrors] = useMemo(() => {
    const isDirty = Object.keys(formState.dirty).length !== 0;

    return [
      isDirty && Object.values(formState.errors).some((x) => !!x?.length),
    ];
  }, [formState]);

  const modelGroups = useMemo(() => {
    return filterFalsey([...new Set(options?.models?.map((x) => x.groupName))]);
  }, [options]);

  const models = useMemo(() => {
    if (!formState.values?.groupName) {
      return [];
    }

    return (
      options?.models
        ?.filter((m) => m.groupName === formState.values.groupName)
        .map((x) => ({
          text: x.name,
          value: String(x.id),
        })) || []
    );
  }, [formState, options]);

  const handleCancel = useCallback(() => {
    setAddState(RESET);
    toggleResetConfirm();

    navigate(`/${brand}/dealers/${dealer}/vehicles/add`);
  }, [brand, dealer, navigate, setAddState]);

  if (optionsState.error) {
    return <ErrorState />;
  }

  if (optionsState.loading) {
    return (
      <Stack>
        <DetailsSkeleton />
      </Stack>
    );
  }

  return (
    <Stack
      direction={{
        sm: 'column-reverse',
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section>
          <Stack spacing={4}>
            <Form form={form} translations="pages.vehiclePage.details.form">
              {/* Key Information */}
              <div
                id={contentItems[0].hash}
                ref={
                  contentItems[0]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <KeyInformation
                  actionType={ActionType.ADD}
                  allowChanges
                  bodyStyleOptions={(options?.bodyStyleOptions || []).map(
                    (bodyStyle) => ({
                      text: String(bodyStyle.name),
                      value: String(bodyStyle.id),
                    }),
                  )}
                  driveOptions={Object.entries(HandDrive).map(([k, v]) => ({
                    text: k,
                    value: v,
                  }))}
                  modelGroupOptions={modelGroups.map((mg) => ({
                    text: mg,
                    value: mg,
                  }))}
                  modelOptions={models}
                  onChangeModelGroup={() => formBind.setValue('model', '')}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.keyInformation')}
                />
              </div>
              {/* Colour */}
              <div
                id={contentItems[1].hash}
                ref={
                  contentItems[1]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Colour
                  actionType={ActionType.ADD}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.colour')}
                />
              </div>
              {/* Engine */}
              <div
                id={contentItems[2].hash}
                ref={
                  contentItems[2]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Engine
                  actionType={ActionType.ADD}
                  engineOptions={Object.entries(FuelType).map(([k, v]) => ({
                    text: getFuelTypeDisplayName(v as FuelType),
                    value: String(getFuelTypeId(v as FuelType)),
                  }))}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.engine')}
                  transmissionOptions={(options?.transmissionOptions || []).map(
                    (transmission) => ({
                      text: transmission.name,
                      value: String(transmission.id),
                    }),
                  )}
                />
              </div>
              {/* TechSpec */}
              <div
                id={contentItems[3].hash}
                ref={
                  contentItems[3]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <TechSpec
                  actionType={ActionType.ADD}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.techSpec')}
                />
              </div>
              {/* Emissions */}
              <div
                id={contentItems[4].hash}
                ref={
                  contentItems[4]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Emission
                  actionType={ActionType.ADD}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.emissions')}
                />
              </div>
              {/* Consumption */}
              <div
                id={contentItems[5].hash}
                ref={
                  contentItems[5]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Consumption
                  actionType={ActionType.ADD}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.consumptions')}
                />
              </div>
              {/* Environmental */}
              <div
                id={contentItems[6].hash}
                ref={
                  contentItems[6]
                    .sectionElementRef as React.RefObject<HTMLDivElement>
                }
              >
                <Environmental
                  actionType={ActionType.ADD}
                  readonly={readonly}
                  title={t('pages.vehiclePage.details.environmental')}
                />
              </div>
              {!readonly && (
                <Box
                  bgcolor="background.paper"
                  borderTop="1px solid #C4CDD5"
                  bottom={0}
                  p={1}
                  position="sticky"
                >
                  <Stack
                    direction="row"
                    justifyContent="space-between"
                    spacing={1}
                  >
                    <Button
                      disabled={formState.busy}
                      onClick={() => {
                        toggleResetConfirm();
                      }}
                      startIcon={<NavigateBefore />}
                      variant="outlined"
                    >
                      {t('cancel')}
                    </Button>
                    <LightTooltip
                      title={hasErrors ? <FormErrorsSummary /> : ''}
                    >
                      <span>
                        <LoadingButton
                          disabled={hasErrors}
                          endIcon={<NavigateNext />}
                          loading={formState.busy}
                          loadingPosition="end"
                          type="submit"
                          variant="contained"
                        >
                          {t('next')}
                          {/* {t(nextStep)} */}
                        </LoadingButton>
                      </span>
                    </LightTooltip>
                  </Stack>
                </Box>
              )}
            </Form>
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
      <ConfirmModal
        onClose={toggleResetConfirm}
        onConfirm={handleCancel}
        open={resetConfirm}
        title={t('attention')}
      >
        <Typography variant="body1">{t('discard')}</Typography>
      </ConfirmModal>
    </Stack>
  );
};

export default AddManuallyStep;
