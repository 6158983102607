import { maybe } from '@fmtk/decoders';
import {
  ArchiveVehicleListingProcess,
  decodeArchiveVehicleListingProcess,
} from '../../util/ArchiveVehicleListingProcess.js';

export type GetArchiveVehicleListingProcessResponse =
  ArchiveVehicleListingProcess;

export const decodeGetArchiveVehicleListingProcessResponse = maybe(
  decodeArchiveVehicleListingProcess,
);
