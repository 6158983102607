import { PriceType, VehiclePrice } from '../../api/util/Vehicle.js';
import { useBrand } from './useBrand.js';

export type PriceVisibility = {
  [Key in keyof typeof PriceType as PriceType]?: boolean;
} & Pick<
  VehiclePrice,
  'priceOnApplication' | 'priceExcludesVat' | 'vatQualifying'
>;

export const usePrice = (): PriceVisibility | undefined => {
  const { brandConfig } = useBrand();
  if (!brandConfig) {
    return;
  }

  return {
    [PriceType.StandInValue]: !brandConfig.disablePrices?.includes(
      PriceType.StandInValue,
    ),
    [PriceType.Trade]: brandConfig.disablePrices?.includes(PriceType.Trade),
    [PriceType.LessTaxes]: !brandConfig.disablePrices?.includes(
      PriceType.LessTaxes,
    ),
    [PriceType.RetailPlusTax]: !brandConfig.disablePrices?.includes(
      PriceType.RetailPlusTax,
    ),
    [PriceType.NetPrice]: !brandConfig.disablePrices?.includes(
      PriceType.NetPrice,
    ),
    [PriceType.BasePrice]: !brandConfig.disablePrices?.includes(
      PriceType.BasePrice,
    ),
    priceOnApplication: !brandConfig.disabledPriceOnApplication,
    priceExcludesVat: Boolean(brandConfig.showPriceExcludesVat),
    vatQualifying: Boolean(brandConfig.showVatQualifying),
  };
};
