import { Box, Stack, Typography } from '@mui/material';
import { Container } from '@mui/system';
import { Provider } from 'jotai';
import { FunctionComponent, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { matchPath, Outlet, useLocation } from 'react-router-dom';
import { useBeforeUnload } from 'react-use';
import ProgressBar, { Step } from '../components/ProgressBar.js';

const AddRemarketingVehicle: FunctionComponent = () => {
  const { t } = useTranslation();
  const [activeStep, setActiveStep] = useState(0);
  const location = useLocation();
  useBeforeUnload(true, t('discard'));

  const steps: Step[] = useMemo(() => {
    const baseRoute = '/:brand/remarketing/management/add';

    return [
      { label: t('vinNumber'), to: baseRoute },
      {
        label: t('additionalDetails'),
        to: `${baseRoute}/details`,
      },
      {
        label: t('features'),
        to: `${baseRoute}/features`,
      },
      {
        label: t('media'),
        to: `${baseRoute}/media`,
      },
    ];
  }, [t]);

  useEffect(() => {
    const selected = steps.find((s) => {
      return matchPath({ path: s.to, end: true }, location.pathname);
    });

    if (selected) {
      setActiveStep(steps.indexOf(selected));
    }
  }, [location, steps]);

  return (
    <div>
      <Box
        bgcolor="background.paper"
        py={{
          xs: 2,
          sm: 5,
        }}
      >
        <Container maxWidth="xl">
          <Box maxWidth={900}>
            <ProgressBar activeStep={activeStep} steps={steps} />
          </Box>
        </Container>
      </Box>
      <Container
        maxWidth="xl"
        sx={{
          px: {
            sm: 2,
            xs: 0,
          },
        }}
      >
        <Stack
          display={{
            xs: 'none',
            sm: 'flex',
          }}
          py={{
            xs: 4,
            sm: 3,
          }}
          spacing={3}
        >
          <Typography fontSize={24} variant="title">
            {t('pages.addRemarketingVehicle.title')}
          </Typography>
        </Stack>
        <Provider>
          <Outlet />
        </Provider>
      </Container>
    </div>
  );
};

export default AddRemarketingVehicle;
