import { Skeleton, Typography, TypographyProps } from '@mui/material';
import { Box } from '@mui/system';
import { FunctionComponent, PropsWithChildren, useMemo } from 'react';
import { VehicleMediaStatus } from '../../../../api/util/VehicleMedia.js';
import { useMobile } from '../../../hooks/useMobile.js';
import { statusLabelColoursMap } from '../../../util/colors.js';

interface LabelStatusProps extends PropsWithChildren {
  status?: VehicleMediaStatus;
  isRequired?: boolean;
  loading?: boolean;
  typographyProps?: TypographyProps;
}

const LabelStatus: FunctionComponent<LabelStatusProps> = ({
  status,
  loading,
  isRequired,
  children,
  typographyProps,
}) => {
  const isMobile = useMobile();
  const color = useMemo(() => {
    const index = status ? status : isRequired ? 'Required' : undefined;
    if (!index) {
      return;
    }
    return statusLabelColoursMap[index];
  }, [status, isRequired]);
  return (
    <Box
      alignItems="center"
      bgcolor={color?.background}
      borderRadius="3px"
      display="flex"
      px={0.5}
      sx={{
        height: 'fit-content',
      }}
    >
      {typeof children === 'string' ? (
        <Typography
          color={color?.color}
          sx={{ fontSize: isMobile ? '9px' : '11px' }}
          variant="status"
          {...typographyProps}
        >
          {loading ? <Skeleton variant="text" width={40} /> : children}
        </Typography>
      ) : (
        children
      )}
    </Box>
  );
};

export default LabelStatus;
