import { enumValue, number, object, optional, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface ListDealerVehicleMovementsRequest {
  brand: AvailableBrands;
  region?: string;
  dealerId?: number;
}

export const decodeListDealerVehicleMovementsRequest =
  object<ListDealerVehicleMovementsRequest>({
    brand: enumValue(AvailableBrands),
    region: optional(string),
    dealerId: optional(number),
  });
