import { Box, colors } from '@mui/material';
import { PropsWithChildren } from 'react';
import { CSSTransition, SwitchTransition } from 'react-transition-group';
import { sx } from '../../util/sx.js';

export const TIMEOUT = 5000;

const styles = sx({
  highlight: {
    '@keyframes blink': {
      '0%': {
        bgcolor: colors.yellow[50],
      },
      '50%': {
        bgcolor: colors.yellow[400],
      },
      '100%': {
        bgcolor: colors.yellow[50],
      },
    },

    '&.highlight-enter *': {
      animation: 'blink 1s infinite',
      fontWeight: 'fontWeightBold',
    },
  },
});

export const HighlightWrapper = ({
  value,
  highlightChanges,
  children,
}: PropsWithChildren<{
  value: unknown;
  highlightChanges?: boolean;
}>) => {
  return highlightChanges && !!value ? (
    <SwitchTransition mode="out-in">
      <CSSTransition
        appear={false}
        classNames="highlight"
        enter
        exit={false}
        in={false}
        key={String(value)}
        timeout={TIMEOUT}
      >
        <Box sx={styles.highlight}>{children}</Box>
      </CSSTransition>
    </SwitchTransition>
  ) : (
    children
  );
};

export default HighlightWrapper;
