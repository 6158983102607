import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { useTranslation } from 'react-i18next';
import { ArchiveVehicleListingRequest } from '../../../../api/VehicleListingService/archiveVehicleListing/ArchiveVehicleListingRequest.js';
import { VehicleListingErrorCode } from '../../../../api/VehicleListingService/common.js';
import { ArchiveVehicleListingProcess } from '../../../../api/util/ArchiveVehicleListingProcess.js';
import { AvailableBrands } from '../../../../api/util/AvailableBrands.js';
import { HttpServiceTransportError } from '../../../../api/util/HttpServiceTransportError.js';
import { useMessageStore } from '../../../common-ui/index.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { makeGetArchiveVehicleListingProcess } from '../queries/useGetArchiveVehicleListingProcess.js';

export const useArchiveVehicleListing = (): UseMutationResult<
  ArchiveVehicleListingProcess,
  unknown,
  ArchiveVehicleListingRequest & {
    brand: AvailableBrands;
    dealerId: number;
  }
> => {
  const api = useApiClient();
  const { showMessage } = useMessageStore();
  const { t } = useTranslation();
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: async ({ id }) => {
      return await api.vehicleListing.archiveVehicleListing({
        id,
      });
    },
    onSuccess: (data, { brand, dealerId }) => {
      showMessage({
        text: t('pages.vehiclePage.header.archiveSuccess'),
        severity: 'success',
        dismissible: true,
      });

      queryClient.setQueryData(
        makeGetArchiveVehicleListingProcess({
          brand,
          dealerId,
          vehicleListingId: data.vehicleListingId,
        }),
        data,
      );
    },
    onError: (error) => {
      if (
        HttpServiceTransportError.is(error, VehicleListingErrorCode.Archived)
      ) {
        showMessage({
          text: t(`VehicleListingErrorCode.${error.code}`),
          severity: 'error',
          dismissible: true,
        });
        return;
      }
      showMessage({
        severity: 'error',
        text: t('errorOccurredMessage'),
        dismissible: true,
      });
    },
  });
};
