import {
  array,
  boolean,
  choose,
  enumValue,
  is,
  maybe,
  number,
  object,
  optional,
  string,
} from '@fmtk/decoders';
import { IndexName } from '../../../core/SearchIndexService/indexes.js';
import { AvailableBrands, SyndicationType } from '../../util/index.js';
import { VehicleListingSort } from '../common.js';

export interface SearchVehicleListingRequest {
  brand: AvailableBrands | AvailableBrands[];
  dealer?: number | number[];
  vin?: string;
  freeTextSearch?: string;
  options?: {
    limit?: number;
    page?: number;
    isLive?: boolean;
    onlyCertifiedVehicles?: boolean;
    onlyStockOver90Days?: boolean;
    syndicationTypes?: SyndicationType[];
    indexName?: IndexName.VehicleListings | IndexName.ArchivedVehicleListings;
    sort?: VehicleListingSort;
    order?: 'asc' | 'desc';
  };
}

export const decodeSearchVehicleListingRequest =
  object<SearchVehicleListingRequest>({
    brand: choose(
      array(enumValue(AvailableBrands)),
      enumValue(AvailableBrands),
    ),
    dealer: maybe(choose(number, array(number))),
    vin: optional(string),
    freeTextSearch: optional(string),
    options: optional(
      object({
        limit: optional(number),
        page: optional(number),
        isLive: optional(boolean),
        onlyCertifiedVehicles: optional(boolean),
        onlyStockOver90Days: optional(boolean),
        syndicationTypes: optional(array(enumValue(SyndicationType))),
        indexName: maybe(
          is(IndexName.ArchivedVehicleListings, IndexName.VehicleListings),
        ),
        sort: maybe(enumValue(VehicleListingSort)),
        order: optional(is('asc', 'desc')),
      }),
    ),
  });
