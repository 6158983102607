import { t } from 'i18next';
export const options = {
  responsive: true,
  plugins: {
    tooltip: {
      callbacks: {
        label: (context: any): string | string[] =>
          `${context.dataset.label}: ${context.formattedValue}`,
      },
    },
  },
  scales: {
    x: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.region'),
      },
    },
    y: {
      stacked: true,
      title: {
        display: true,
        text: t('reports.noOfImages'),
      },
    },
  },
};
