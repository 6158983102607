import {
  decodeDownloadCsvResponse,
  method,
  none,
  service,
  UserPermissions,
} from '../util/index.js';
import { decodeDownloadInventoryPricingCsvRequest } from './downloadInventoryPricingCsv/DownloadInventoryPricingCsvRequest.js';
import { decodeDownloadInventoryRegionCsvRequest } from './downloadInventoryRegionCsv/DownloadInventoryRegionCsvRequest.js';
import { decodeDownloadLiveStatusChangesCsvRequest } from './downloadLiveStatusChangesCsv/DownloadLiveStatusChangesCsvRequest.js';
import { decodeDownloadVehicleMovementCsvRequest } from './downloadVehicleMovementCsv/DownloadVehicleMovementCsvRequest.js';
import { decodeGetInventoryProcessRequest } from './getInventoryReportProcess/GetInventoryProccessRquest.js';
import { decodeGetInventoryProcessResponse } from './getInventoryReportProcess/GetInventoryProcessResponse.js';
import { decodeListDealerVehicleMovementsRequest } from './listDealerVehicleMovements/ListDealerVehicleMovementsRequest.js';
import { decodeListDealerVehicleMovementsResponse } from './listDealerVehicleMovements/ListDealerVehicleMovementsResponse.js';
import { decodeListLiveStatusChangesRequest } from './listLiveStatusChanges/ListLiveStatusChangesRequest.js';
import { decodeListLiveStatusChangesResponse } from './listLiveStatusChanges/ListLiveStatusChangesResponse.js';
import { decodeListModelPricingTrendsRequest } from './listModelPricingTrends/ListModelPricingTrendsRequest.js';
import { decodeListModelPricingTrendsResponse } from './listModelPricingTrends/ListModelPricingTrendsResponse.js';
import { decodeListRegionModelStatsRequest } from './listRegionModelStats/ListRegionModelStatsRequest.js';
import { decodeListRegionModelStatsResponse } from './listRegionModelStats/ListRegionModelStatsResponse.js';
import { decodeListRegionsRequest } from './listRegions/ListRegionsRequest.js';
import { decodeListRegionsResponse } from './listRegions/ListRegionsResponse.js';
import { decodeListRegionStockStatsRequest } from './listRegionStockStats/ListRegionStockStatsRequest.js';
import { decodeListRegionStockStatsResponse } from './listRegionStockStats/ListRegionStockStatsResponse.js';
import { decodeListVehicleFeaturesRequest } from './listVehicleFeatures/ListVehicleFeatureRequest.js';
import { decodeListVehicleFeaturesResponse } from './listVehicleFeatures/ListVehicleFeaturesResponse.js';

export const ReportingServiceDef = service({
  listRegionStockStats: method(
    decodeListRegionStockStatsRequest,
    decodeListRegionStockStatsResponse,
    [UserPermissions.Basic],
  ),
  listRegionModelStats: method(
    decodeListRegionModelStatsRequest,
    decodeListRegionModelStatsResponse,
    [UserPermissions.Basic],
  ),
  listDealerVehicleMovements: method(
    decodeListDealerVehicleMovementsRequest,
    decodeListDealerVehicleMovementsResponse,
    [UserPermissions.Basic],
  ),
  downloadInventoryRegionCsv: method(
    decodeDownloadInventoryRegionCsvRequest,
    none,
    [UserPermissions.Basic],
  ),
  downloadVehicleMovementCsv: method(
    decodeDownloadVehicleMovementCsvRequest,
    decodeDownloadCsvResponse,
    [UserPermissions.Basic],
  ),
  listModelPricingTrends: method(
    decodeListModelPricingTrendsRequest,
    decodeListModelPricingTrendsResponse,
    [UserPermissions.Basic],
  ),
  downloadInventoryPricingCsv: method(
    decodeDownloadInventoryPricingCsvRequest,
    decodeDownloadCsvResponse,
    [UserPermissions.Basic],
  ),
  listRegions: method(decodeListRegionsRequest, decodeListRegionsResponse, [
    UserPermissions.Basic,
  ]),
  listVehicleFeatures: method(
    decodeListVehicleFeaturesRequest,
    decodeListVehicleFeaturesResponse,
    [UserPermissions.Basic],
  ),
  listLiveStatusChanges: method(
    decodeListLiveStatusChangesRequest,
    decodeListLiveStatusChangesResponse,
    [UserPermissions.Basic],
  ),
  downloadLiveStatusChangesCsv: method(
    decodeDownloadLiveStatusChangesCsvRequest,
    decodeDownloadCsvResponse,
    [UserPermissions.Basic],
  ),
  getInventoryReportProcess: method(
    decodeGetInventoryProcessRequest,
    decodeGetInventoryProcessResponse,
    [UserPermissions.Basic],
  ),
});

export type ReportingServiceDef = typeof ReportingServiceDef;
