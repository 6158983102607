import { Box, Button, Paper, Slide, Typography } from '@mui/material';
import { Document, Page, Text, usePDF, View } from '@react-pdf/renderer';
import { TFunction } from 'i18next';
import { FunctionComponent, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableBrands } from '../../../../api/util/index.js';
import { PrintableVehicle } from '../../../../api/util/PrintableVehicle.js';
import { DealerSelectionType } from '../../../hooks/useDealer.js';
import { PDFTypes } from '../../../util/index.js';
import { PdfConfigType } from '../../../util/pdfConfig.js';
import { makePdf } from './makePDF.js';
import PdfThumbnail from './PdfThumbnail.js';
import ReportSkeleton from './ReportSkeleton.js';

interface PrintButtonProps {
  pdfType: PDFTypes;
  pdfConfig: PdfConfigType;
  vehicleListing: PrintableVehicle;
  dealer?: DealerSelectionType;
  brand: AvailableBrands;
  onClick: (fileName: string, blob?: Blob) => void;
  DealerQrCode?: string;
}

const EmptyPDF = (
  <Document>
    <Page>
      <View>
        <Text>Empty file</Text>
      </View>
    </Page>
  </Document>
);

const PrintButton: FunctionComponent<PrintButtonProps> = ({
  pdfType,
  vehicleListing: vehicle,
  brand,
  pdfConfig,
  onClick,
  dealer,
  DealerQrCode,
}: PrintButtonProps): JSX.Element => {
  const [showTitle, setShowTitle] = useState(false);
  const { t } = useTranslation();
  const containerRef = useRef(null);
  const document = useMemo(() => {
    return makePdf<{
      vehicle: PrintableVehicle;
      pdfConfig: PdfConfigType;
      dealer?: DealerSelectionType;
      DealerQrCode?: string;
      t: TFunction;
    }>(brand, pdfType, {
      vehicle: vehicle,
      pdfConfig: pdfConfig,
      dealer: dealer,
      DealerQrCode: DealerQrCode,
      t,
    });
  }, [brand, pdfType, vehicle, pdfConfig, dealer, t]);

  const [instance] = usePDF({
    document: document || EmptyPDF,
  });

  const handlePDF = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault();

    if (instance.loading || instance.error || !instance.blob) {
      return;
    }

    onClick(pdfConfig.fileName, instance.blob);
  };

  return (
    <Paper
      onMouseEnter={() => setShowTitle(true)}
      onMouseLeave={() => setShowTitle(false)}
      variant="outlined"
    >
      <Box
        m={1}
        maxWidth={{
          xs: '100%',
          sm: 260,
        }}
        overflow="hidden"
        position="relative"
        ref={containerRef}
      >
        <Button fullWidth onClick={handlePDF}>
          {instance.url && instance.blob && !instance.loading ? (
            <PdfThumbnail file={instance.blob} />
          ) : (
            <ReportSkeleton />
          )}
        </Button>
        <Slide container={containerRef.current} direction="up" in={showTitle}>
          <Box
            alignItems="center"
            bgcolor="background.default"
            bottom={0}
            display="flex"
            height={40}
            justifyContent="center"
            position="absolute"
            width="100%"
          >
            <Typography textAlign="center">{pdfConfig.title}</Typography>
          </Box>
        </Slide>
      </Box>
    </Paper>
  );
};

export default PrintButton;
