import { Info } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Tooltip,
} from 'chart.js';
import { groupBy } from 'ramda';
import { FunctionComponent, useCallback, useMemo, useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { DealerVehicleMovement } from '../../../../api/ReportingService/listDealerVehicleMovements/ListDealerVehicleMovementsResponse.js';
import {
  AuditLogType,
  AvailableBrands,
  DownloadCsvResponse,
  SyndicationType,
} from '../../../../api/util/index.js';
import { EmptyState, GridToolbar } from '../../../common-ui/index.js';
import { useApiClient, useAsyncState, useBrand } from '../../../hooks/index.js';
import { downloadAsCsv, labelColor } from '../../../util/index.js';
import MainDrawer from '../MainDrawer.js';
import { options } from './chartConfig.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Tooltip, Legend);

interface DealerVehicleMovementsReportProps {
  condition: SyndicationType;
  data: DealerVehicleMovement[];
  title: string;
  subtitle?: string;
  brand: AvailableBrands;
}

type Row = {
  title: string;
  [x: string]: number | string;
  total: number;
};

const DealerVehicleMovementsReport: FunctionComponent<
  DealerVehicleMovementsReportProps
> = ({ data, condition, title, subtitle, brand }) => {
  const api = useApiClient();
  const [open, setOpen] = useState(false);
  const { t } = useTranslation();
  const { brandConfig } = useBrand();
  const [downloadState, setDownloadState] =
    useAsyncState<DownloadCsvResponse>();

  const handleDownload = useCallback(() => {
    setDownloadState(async () => {
      const csvResponse = await api.reporting.downloadVehicleMovementCsv({
        brand,
        syndicationType: condition,
        monthInterval: brandConfig?.carMovementReportMonthInterval,
      });

      downloadAsCsv(csvResponse.content, csvResponse.fileName);
      return csvResponse;
    });
  }, [
    brand,
    condition,
    api.reporting,
    setDownloadState,
    brandConfig?.carMovementReportMonthInterval,
  ]);

  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);

  const [chartData, rows, columns] = useMemo(() => {
    const regionData = groupBy(
      (region) => region.regionName,
      data.filter((d) => d.newUsed === condition),
    );
    const regions = Object.keys(regionData);

    const chartDatasets = regions.flatMap((region, index) => {
      return regionData[region]
        .sort((a, b) => b.count - a.count)
        .map((dealer) => {
          const values: number[] = Array(regions.length).fill(0);
          values[index] = dealer.count;
          return {
            label: `${dealer.dealerName} ${dealer.addedRemoved}`,
            data: values,
            backgroundColor: labelColor(dealer.addedRemoved),
            stack: t(
              `reports.vehicleMovement.${dealer.addedRemoved.toLowerCase()}`,
            ),
          };
        });
    });

    function buildRow(
      title: string,
      type: AuditLogType,
      condition: SyndicationType,
    ): Row {
      const row: Row = { title: title, total: 0 };
      for (const region of regions) {
        const r = regionData[region];
        const additions = r.filter(
          (a) => a.addedRemoved === type && a.newUsed === condition,
        );
        const addedSummary = additions.reduce((acc, e) => {
          return acc + e.count;
        }, 0);

        row[region] = addedSummary;
        row.total += addedSummary;
      }
      return { ...row };
    }

    const rows: Row[] = [
      buildRow(`${condition} vehicles added`, AuditLogType.Added, condition),
      buildRow(
        `${condition} vehicles removed`,
        AuditLogType.Removed,
        condition,
      ),
    ];

    const columns: GridColDef[] = [
      { field: 'title', flex: 2, headerName: '' },
      ...regions.map((c) => ({ field: c, flex: 1 })),
      { field: 'total', flex: 1, headerName: 'Total' },
    ];

    return [
      { labels: regions, datasets: Object.values(chartDatasets) },
      rows,
      columns,
    ];
  }, [data, condition, t]);

  return (
    <>
      <Card>
        <CardHeader
          title={title}
          subheader={subtitle}
          action={
            <IconButton aria-label="vehicles-movements" onClick={handleOpen}>
              <Info />
            </IconButton>
          }
        />
        <CardContent>
          {chartData.datasets.length === 0 ? (
            <EmptyState />
          ) : (
            <Bar data={chartData} options={options} />
          )}
        </CardContent>
      </Card>
      <MainDrawer open={open} onClose={handleClose} title={title}>
        <DataGrid
          autoHeight
          columns={columns}
          rows={rows}
          getRowId={(r) => r.title}
          hideFooter
          components={{ Toolbar: GridToolbar }}
          componentsProps={{
            toolbar: {
              downloadOptions: {
                onClickDownload: handleDownload,
                downloading: downloadState.loading,
                error: !!downloadState.error,
              },
            },
          }}
        />
      </MainDrawer>
    </>
  );
};

export default DealerVehicleMovementsReport;
