import { MediaSeo } from '../api/util/VehicleMedia.js';
import { normalisePath } from '../api/util/normalisePath.js';

export function generateCloudinaryPrefix(seo: MediaSeo): string {
  const { brand, modelName, modelYear, vinMd5Hash, vin, registrationYear } =
    seo;

  const path: string[] = ['ims2', 'vehicle-media'];

  path.push(brand || 'brand');
  // Replace all non-alphanumeric characters with a dash, we keep other non-latin characters as well
  path.push(modelName?.replace(/[^\p{L}\p{N}]/gu, '-') || 'model');
  path.push(modelYear?.toString() || registrationYear?.toString() || 'year');
  path.push(vinMd5Hash || vin || 'vin');

  return normalisePath(true, path);
}
