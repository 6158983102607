import { Image, Page, StyleSheet, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';

interface MosaicGalleryProps {
  images: string[];
}

const ROWS_PER_PAGE = 4;
const IMAGES_PER_ROW = 5;

const styles = StyleSheet.create({
  page: {
    flexDirection: 'column',
    paddingVertical: 48,
    paddingHorizontal: 16,
  },
  rowContainer: {
    width: '100%',
  },
  imageContainer: {
    borderWidth: 4,
    borderColor: '#FFF',
    backgroundColor: '#FFF',
  },
  largeImageContainer: {
    width: '248px',
    height: '186px',
    borderWidth: 4,
    borderColor: '#FFF',
    backgroundColor: '#FFF',
  },
  smallImageContainer: {
    width: '124px',
    height: '93px',
    borderWidth: 4,
    borderColor: '#FFF',
    backgroundColor: '#FFF',
  },
  smallImageRow: {
    flexDirection: 'row',
    justifyContent: 'space-between',
  },
  image: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
  },
});

const Row: FunctionComponent<{ images: string[]; index: number }> = ({
  images,
  index,
}) => {
  if (images.length === 0) {
    return null;
  }

  const [largeImage, ...smallImages] = images;

  return (
    <View style={styles.rowContainer}>
      <View
        style={{
          flexDirection: index % 2 === 0 ? 'row' : 'row-reverse',
          justifyContent: 'flex-start',
          paddingHorizontal: 35,
        }}
      >
        <View style={styles.largeImageContainer}>
          <Image src={largeImage} style={styles.image} />
        </View>
        <View style={{ flexDirection: 'column' }}>
          <View style={styles.smallImageRow}>
            {smallImages.slice(0, 2).map((url, index) => (
              <View key={index} style={styles.smallImageContainer}>
                <Image src={url} style={styles.image} />
              </View>
            ))}
          </View>
          <View style={styles.smallImageRow}>
            {smallImages.slice(2, 4).map((url, index) => (
              <View key={index} style={styles.smallImageContainer}>
                <Image src={url} style={styles.image} />
              </View>
            ))}
          </View>
        </View>
      </View>
    </View>
  );
};

const MosaicGallery: FunctionComponent<MosaicGalleryProps> = ({ images }) => {
  const pages = [];
  for (let i = 0; i < images.length; i += ROWS_PER_PAGE * IMAGES_PER_ROW) {
    const pageImages = images.slice(i, i + ROWS_PER_PAGE * IMAGES_PER_ROW);
    pages.push(
      <Page key={i} size="A4" style={styles.page}>
        {Array.from({ length: ROWS_PER_PAGE }).map((_, rowIndex) => {
          const rowImages = pageImages.slice(
            rowIndex * IMAGES_PER_ROW,
            (rowIndex + 1) * IMAGES_PER_ROW,
          );
          return <Row images={rowImages} index={rowIndex} key={rowIndex} />;
        })}
      </Page>,
    );
  }

  return <>{pages}</>;
};

export default MosaicGallery;
