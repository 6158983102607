import {
  array,
  number,
  object,
  optional,
  record,
  string,
} from '@fmtk/decoders';

export interface Dealer {
  dealerName: string;
  dealerId: string;
  regionId: number;
  address?: string;
  theirId?: string;
}

export type DealersRegion = Record<string, Dealer[]>;

export const decodeDealer = object<Dealer>({
  dealerName: string,
  dealerId: string,
  regionId: number,
  address: optional(string),
  theirId: optional(string),
});

export const decodeDealersRegion = record(string, array(decodeDealer));
