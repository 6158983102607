import { array, number, object } from '@fmtk/decoders';
import {
  UserRemarketingVehicle,
  decodeUserRemarketingVehicle,
} from '../../util/UserRemarketingVehicle.js';

export interface UserListRemarketingVehiclesResponse {
  items: UserRemarketingVehicle[];
  count: number;
}

export const decodeUserListRemarketingVehiclesResponse =
  object<UserListRemarketingVehiclesResponse>({
    items: array(decodeUserRemarketingVehicle),
    count: number,
  });
