import {
  boolean,
  enumValue,
  jsonDate,
  maybe,
  number,
  object,
  optional,
  PropDecoders,
  text,
} from '@fmtk/decoders';
import { AvailableBrands } from './AvailableBrands.js';
import { decodePolicy, Policy } from './Policy.js';

export enum Auth0Organisation {
  Bentley = 'Bentley',
}

export interface User {
  principalId: string;
  avatarUrl?: string;
  email: string;
  familyName: string;
  givenName: string;
  policy: Policy;
  createDate?: Date;
  lastLogin?: Date;
  banned?: boolean;
  organisation?: Auth0Organisation;
}

export const decodeUserProps: PropDecoders<User> = {
  principalId: text,
  avatarUrl: maybe(text),
  email: text,
  familyName: text,
  givenName: text,
  policy: decodePolicy,
  createDate: maybe(jsonDate),
  lastLogin: maybe(jsonDate),
  banned: optional(boolean),
  organisation: maybe(enumValue(Auth0Organisation)),
};

export const decodeUser = object<User>(decodeUserProps);

export type UserForm = Omit<User, 'principalId' | 'organisation'> & {
  principalId?: string;
  dealerId?: number;
  brand?: AvailableBrands;
};

export const decodeUserForm = object<UserForm>({
  ...decodeUserProps,
  principalId: maybe(text),
  dealerId: maybe(number),
  brand: maybe(enumValue(AvailableBrands)),
});
