import { Typography } from '@mui/material';
import { FunctionComponent } from 'react';

interface CellLabelProps extends React.LabelHTMLAttributes<HTMLLabelElement> {
  label: string;
}

export const CellLabel: FunctionComponent<CellLabelProps> = ({
  label,
  ...props
}) => {
  return (
    <Typography color="#637381" component="label" variant="cell" {...props}>
      {label}
    </Typography>
  );
};

export default CellLabel;
