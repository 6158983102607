import {
  alpha,
  Box,
  Card,
  CardProps,
  Skeleton,
  styled,
  Typography,
} from '@mui/material';
import { ReactElement } from 'react';
import { PaletteKey } from '../theme/default.js';

const IconWrapperStyle = styled('div')(({ theme }) => ({
  margin: 'auto',
  display: 'flex',
  borderRadius: '50%',
  alignItems: 'center',
  width: theme.spacing(8),
  height: theme.spacing(8),
  justifyContent: 'center',
  marginBottom: theme.spacing(3),
}));

type SummaryCardProps = {
  color?: PaletteKey;
  icon?: ReactElement;
  subtitle: string;
  title: number | string;
  loading?: boolean;
  error?: boolean;
  disabled?: boolean;
} & CardProps;

export default function SummaryCard({
  subtitle: title,
  title: total,
  icon,
  color,
  sx,
  loading,
  error,
  disabled,
  ...other
}: SummaryCardProps) {
  return (
    <>
      <Card
        sx={{
          py: 5,
          boxShadow: 0,
          textAlign: 'center',
          color: (theme) =>
            disabled
              ? theme.palette.text.disabled
              : color
              ? alpha(theme.palette[color].dark, 0.8)
              : undefined,
          bgcolor: (theme) =>
            disabled
              ? theme.palette.action.disabled
              : color
              ? alpha(theme.palette[color].light, 0.4)
              : undefined,
          ':hover': {
            bgcolor: (theme) =>
              color ? alpha(theme.palette[color].light, 0.6) : undefined,
            color: (theme) =>
              color ? alpha(theme.palette[color].dark, 0.8) : undefined,
          },
          ...sx,
        }}
        {...other}
      >
        {icon && (
          <IconWrapperStyle
            sx={{
              color: (theme) =>
                disabled
                  ? theme.palette.text.disabled
                  : color
                  ? theme.palette[color || 'primary'].dark
                  : undefined,
              backgroundImage: (theme) =>
                disabled
                  ? theme.palette.action.disabled
                  : color
                  ? `linear-gradient(135deg, ${alpha(
                      theme.palette[color || 'primary'].dark,
                      0,
                    )} 0%, ${alpha(
                      theme.palette[color || 'primary'].dark,
                      0.24,
                    )} 100%)`
                  : undefined,
            }}
          >
            {icon && icon}
          </IconWrapperStyle>
        )}
        <Typography variant="h4">
          {loading ? (
            <Box display="flex" justifyContent="center">
              <Skeleton variant="text" width="30%" />
            </Box>
          ) : (
            total
          )}
        </Typography>
        <Typography
          color={error ? 'error' : 'inherit'}
          sx={{ opacity: 0.72 }}
          variant="subtitle2"
        >
          {error ? 'An unknown error occurred' : title}
        </Typography>
      </Card>
    </>
  );
}
