import { alpha, Typography } from '@mui/material';
import React from 'react';
import { Label } from '../../common-ui/index.js';

type Props = {
  visible: boolean;
};

const VehicleStatusLabel = ({ visible }: Props) => {
  return (
    <Label
      sx={{
        bgcolor: (theme) =>
          alpha(
            visible ? theme.palette.primary.light : theme.palette.error.light,
            0.4,
          ),
      }}
    >
      <Typography
        color={visible ? 'primary.main' : 'error.main'}
        variant="body2"
      >
        {visible ? 'Live' : 'Not Live'}
      </Typography>
    </Label>
  );
};

export default React.memo(VehicleStatusLabel);
