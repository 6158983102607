import { Alert, AlertColor, AlertTitle } from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';

export interface ErrorStateProps {
  error?: string;
  hideTitle?: boolean;
  alertTitle?: string;
  severity?: AlertColor;
}

export const ErrorState: FunctionComponent<ErrorStateProps> = ({
  error,
  hideTitle,
  alertTitle = 'Error',
  severity = 'error',
}): JSX.Element => {
  const { t } = useTranslation();

  return (
    <Alert severity={severity}>
      {!hideTitle && <AlertTitle>{alertTitle}</AlertTitle>}
      {error || t('error')}
    </Alert>
  );
};

export default ErrorState;
