import { enumValue, object } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export type ListTaxonomyOptionsRequest = {
  brand: AvailableBrands;
};

export const decodeListTaxonomyOptionsRequest =
  object<ListTaxonomyOptionsRequest>({
    brand: enumValue(AvailableBrands),
  });
