import { maybe, number, object } from '@fmtk/decoders';
import { AnalyticsEventType } from '../../util/index.js';

export type GetEventCountResponse = Partial<
  Record<AnalyticsEventType, number | undefined>
>;

export const decodeGetEventCountResponse = object<GetEventCountResponse>({
  [AnalyticsEventType.View]: maybe(number),
  [AnalyticsEventType.Enquire]: maybe(number),
  [AnalyticsEventType.Print]: maybe(number),
  [AnalyticsEventType.Compare]: maybe(number),
  [AnalyticsEventType.Shortlist]: maybe(number),
});
