import { DecodingAssertError } from '@fmtk/decoders';
import fetch from 'cross-fetch';
import debug from 'debug';
import { isRecord } from '../../util/index.js';
import { CredentialProvider } from './CredentialProvider.js';
import {
  HttpClient,
  HttpClientOptions,
  HttpClientResponse,
  makeHttpClient,
} from './HttpClient.js';
import { HttpServiceTransportError } from './HttpServiceTransportError.js';
import { joinUrl } from './joinUrl.js';
import { jsonClient } from './jsonClient.js';
import { MethodScope, ServiceMethod, ServiceMethodDef } from './ServiceDef.js';

const trace = debug('http:transport');

export interface HttpServiceTransportOptions {
  credentials?: CredentialProvider;
  endpoint: string;
  httpClient?: HttpClient;
  httpClientOptions?: HttpClientOptions;
}

export class HttpServiceTransport {
  private readonly endpoint: string;
  private readonly httpClient: HttpClient;

  public credentials: CredentialProvider | undefined;

  constructor(opts: HttpServiceTransportOptions) {
    this.credentials = opts.credentials;
    this.endpoint = opts.endpoint;
    this.httpClient =
      opts.httpClient ??
      makeHttpClient(fetch, [jsonClient()], opts.httpClientOptions);
  }

  public method<Req, Res>(
    service: string,
    method: string,
    methodDef: ServiceMethodDef<Req, Res>,
  ): ServiceMethod<Req, Res> {
    return (req) => this.callMethod(service, method, methodDef, req);
  }

  public async callMethod<Req, Res>(
    service: string,
    method: string,
    methodDef: ServiceMethodDef<Req, Res>,
    request: Req,
  ): Promise<Res> {
    const headers: Record<string, string> = {};
    if (methodDef.scopes !== MethodScope.NoAuth && this.credentials) {
      const token = await this.credentials.getAccessToken();
      if (token) {
        headers.Authorization = `Bearer ${token}`;
      }
    }

    const bodyResult = methodDef.request(request);
    if (!bodyResult.ok) {
      trace(`request validation error %O`, {
        errors: bodyResult.error,
        request: request,
      });
      throw new DecodingAssertError(bodyResult.error);
    }

    trace(`request %s:%s %O`, service, method, bodyResult.value);

    let response: HttpClientResponse;
    try {
      response = await this.httpClient({
        body: bodyResult.value,
        headers,
        method: 'POST',
        url: joinUrl(this.endpoint, service, method),
      });
    } catch (err) {
      throw new HttpServiceTransportError(
        0,
        HttpServiceTransportError.Network,
        err,
      );
    }

    if (response.status >= 400 && response.status < 500) {
      trace(`response %s:%s %d`, service, method, response.status);
      if (
        isRecord(response.body, 'error') &&
        typeof response.body.error === 'string'
      ) {
        throw new HttpServiceTransportError(
          response.status,
          response.body.error,
          (response.body as Record<string, unknown>).details,
        );
      }
    }
    if (response.status < 200 || response.status >= 300) {
      trace(`response %s:%s %d`, service, method, response.status);
      throw new HttpServiceTransportError(
        response.status,
        HttpServiceTransportError.Unknown,
      );
    }

    const responseResult = methodDef.response(response.body);
    if (!responseResult.ok) {
      trace(
        `response validation error %s:%s %d %O`,
        service,
        method,
        response.status,
        {
          errors: responseResult.error,
          response: response.body,
        },
      );
      throw new DecodingAssertError(responseResult.error);
    }

    trace(
      `response %s:%s %d %O`,
      service,
      method,
      response.status,
      responseResult.value,
    );
    return responseResult.value;
  }
}
