import { Font } from '../Font.js';

export const Bentley: Font = {
  family: 'Bentley',
  fonts: [
    {
      src: 'https://connectauto-avl-fonts.s3.eu-west-1.amazonaws.com/bentley/Bentley-Regular.otf',
    },
    {
      src: 'https://connectauto-avl-fonts.s3.eu-west-1.amazonaws.com/bentley/Bentley-SemiBold.otf',
      fontWeight: 400,
    },
    {
      src: 'https://connectauto-avl-fonts.s3.eu-west-1.amazonaws.com/bentley/Bentley-Bold.otf',
      fontWeight: 'bold',
    },
    {
      src: 'https://connectauto-avl-fonts.s3.eu-west-1.amazonaws.com/bentley/Bentley-light.otf',
      fontWeight: 'light',
    },
  ],
};
