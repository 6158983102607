import { Link, Skeleton, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import { FunctionComponent, ReactNode } from 'react';

interface SummaryCardProps {
  label: ReactNode;
  value?: string | number;
  linkText?: string;
  selected?: boolean;
  loading?: boolean;
  onLinkClick?: () => void;
  onClick?: () => void;
}

const Container = styled('div')<{ selected?: boolean }>(
  ({ theme, selected }) => ({
    borderRadius: '4px',
    background: '#F7F8F9',
    padding: '8px 16px',
    cursor: 'pointer',
    border: selected ? '2px solid #2065D1' : '2px solid transparent',
    [theme.breakpoints.up('sm')]: {
      padding: '24px',
    },
  }),
);

const SummaryCard: FunctionComponent<SummaryCardProps> = ({
  label,
  value,
  linkText,
  selected,
  loading,
  onLinkClick,
  onClick,
}) => {
  return (
    <Container
      onClick={(e) => {
        e.preventDefault();
        onClick && onClick();
      }}
      selected={selected}
    >
      <Stack
        alignItems={{
          xs: 'center',
          sm: 'inherit',
        }}
        direction={{
          xs: 'row',
          sm: 'column',
        }}
        justifyContent="space-between"
        spacing={{
          xs: 0,
          sm: 1,
        }}
      >
        {typeof label === 'string' ? (
          <Typography
            color="#637381"
            textTransform="uppercase"
            variant="subtitle2"
          >
            {loading ? <Skeleton variant="text" width="50%" /> : label}
          </Typography>
        ) : (
          label
        )}
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={1}
        >
          <Typography color="#172B4D" variant="h4">
            {loading ? (
              <Skeleton height={20} variant="text" width={30} />
            ) : (
              value
            )}
          </Typography>
          {loading ? (
            <Skeleton variant="text" width={30} />
          ) : (
            <Link
              color={selected ? 'primary' : 'inherit'}
              onClick={onLinkClick}
              underline="always"
              variant="button"
            >
              {linkText}
            </Link>
          )}
        </Stack>
      </Stack>
    </Container>
  );
};

export default SummaryCard;
