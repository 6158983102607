import {
  LoadingButton,
  Timeline,
  TimelineConnector,
  timelineConnectorClasses,
  TimelineContent,
  TimelineDot,
  TimelineItem,
  timelineItemClasses,
  TimelineSeparator,
} from '@mui/lab';
import { Box, Button, Divider, styled, Typography } from '@mui/material';
import { Stack } from '@mui/system';
import React, {
  FunctionComponent,
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import {
  VehicleMedia,
  VehicleMediaStatus,
  VehicleMediaType,
} from '../../../../api/util/VehicleMedia.js';
import { getRelevantMediaFromVehicleMedia } from '../../../../util/vehicleMediaHelper.js';
import { useDeleteMedia } from '../../../hooks/mutations/useDeleteMedia.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useCloudinary } from '../../../hooks/useCloudinary.js';
import { useCurrentUser } from '../../../hooks/useCurrentUser.js';
import ReasonLabel from '../../../ims/components/ReasonLabel.js';
import { useUploadMedia } from '../../../support/hooks/mutations/useUploadMedia.js';
import { formatDateTime } from '../../../util/dates.js';
import { TransformationImage } from '../../../util/index.js';
import { useMessageStore } from '../../context/MessageContext.js';
import { ConfirmModal } from '../ConfirmModal.js';
import LoadingImage from './Image.js';
import ImageDialog from './ImageDialog.js';
import LabelStatus from './LableStatus.js';

const BASE_VEHICLE_TRANSFORMATION = 't_base_vehicle_transformation';

interface ImageDetailsProps {
  vehicleMedia: VehicleMedia[];
  vehicleMediaType?: VehicleMediaType[];
  open?: boolean;
  onClose?: () => void;
  onSubmit?: (
    position: number,
    transformationImage?: TransformationImage,
  ) => void;
  onRestore?: (media: VehicleMedia) => void;
  onDelete?: (media: VehicleMedia) => void;
  translationBase?: string;
  transformations?: TransformationImage[];
  label?: string;
  vinMd5Hash?: string;
  showRestoreButton?: boolean;
}

const Image = styled('img')<{ isProcessing?: boolean; disabled?: boolean }>`
  object-fit: cover;
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  ${({ isProcessing }) => (isProcessing ? 'filter: blur(4px);' : undefined)};
  ${({ disabled }) =>
    disabled ? 'opacity: 0.5; pointer-events: none;' : undefined};
`;

const ImageContainer = styled(Box)<{ selected?: boolean }>`
  cursor: pointer;
  width: 80px;
  height: 60px;
  overflow: hidden;
  border: 2.5px solid #c4cdd5;
  border-radius: 4px;
  ${({ selected }) => (selected ? `border: 2.5px solid #2065d1;` : undefined)};
`;
interface DetailItemProps {
  label: string;
  value?: string | ReactNode;
}

const DetailItem = ({ label, value }: DetailItemProps): JSX.Element => {
  return (
    <Stack alignItems="center" direction="row" my={1.5} spacing={0.5}>
      <Box flex={1} minWidth={80}>
        <Typography color="#6B778C" variant="cell">
          {`${label}`}
        </Typography>
      </Box>
      <Box display="flex" flex={1}>
        {typeof value === 'string' ? (
          <Typography color="#0C264E" variant="subtitle2">
            {value || '-'}
          </Typography>
        ) : (
          value || '-'
        )}
      </Box>
    </Stack>
  );
};

const ImagesTimeline = ({ children }: PropsWithChildren): JSX.Element => {
  return (
    <Timeline
      sx={{
        [`& .${timelineItemClasses.root}:before`]: {
          flex: 0,
          padding: 0,
        },
      }}
    >
      {Array.isArray(children) &&
        children.map((item, idx) => (
          <TimelineItem key={idx}>
            <TimelineSeparator>
              <TimelineDot
                sx={{
                  borderColor: '#637381',
                  borderWidth: 3,
                  width: 18,
                  height: 18,
                  my: 1.5,
                }}
                variant="outlined"
              />
              <TimelineConnector
                sx={{
                  [`&.${timelineConnectorClasses.root}`]: {
                    border: '2px dashed #637381;',
                    backgroundColor: 'transparent',
                  },
                }}
              />
            </TimelineSeparator>
            <TimelineContent>{item}</TimelineContent>
          </TimelineItem>
        ))}
    </Timeline>
  );
};
export interface ImageItemProp {
  title?: React.ReactNode | string;
  isLoading?: boolean;
  isDeleting?: boolean;
  onClose?: () => void;
  onDelete?: (media: VehicleMedia) => void | Promise<void>;
  onSubmit?: (
    media?: VehicleMedia,
    transformedImage?: TransformationImage,
  ) => void;
  data?: VehicleMedia;
  transformations?: TransformationImage[];
  translationBase?: string;
  submitButtonText?: string;
}

interface TransformedImage {
  transformation: TransformationImage;
  src: string | undefined;
  showLabel: boolean;
  disabled?: boolean;
}

const ImageItem = ({
  title,
  data,
  isLoading,
  transformations,
  translationBase,
  onDelete,
  onSubmit,
  onClose,
  isDeleting,
  submitButtonText,
}: ImageItemProp): JSX.Element => {
  const { t } = useTranslation();
  const { getCloudinaryUrl } = useCloudinary();
  const [selectedTransformation, setSelectedTransformation] =
    useState<TransformedImage>();
  const [deleteConfirm, setDeleteConfirm] = useState(false);

  const internalStatus =
    data?.status === VehicleMediaStatus.Archived
      ? data.previousStatus
      : data?.status;

  const { src, transformedImages } = useMemo(() => {
    if (!data || !data) {
      return {};
    }

    const src = data.cloudinaryId
      ? getCloudinaryUrl(data.cloudinaryId, [
          ...(data.transformations || []),
          BASE_VEHICLE_TRANSFORMATION,
        ])
      : data?.legacyUrl;

    const transformedImages: TransformedImage[] | undefined =
      transformations?.map((transformation) => ({
        transformation,
        src: data.cloudinaryId
          ? getCloudinaryUrl(data.cloudinaryId, [
              transformation.name,
              BASE_VEHICLE_TRANSFORMATION,
            ])
          : data?.legacyUrl,
        showLabel: transformation.name === BASE_VEHICLE_TRANSFORMATION,
        disabled: !data.cloudinaryId,
      }));

    return { src: src, transformedImages: transformedImages || [] };
  }, [data, getCloudinaryUrl, transformations]);

  const handleChange = useCallback((image: TransformedImage | undefined) => {
    if (image?.disabled) {
      return;
    }
    setSelectedTransformation(image);
  }, []);

  const handleSubmit = useCallback(() => {
    onSubmit && onSubmit(data, selectedTransformation?.transformation);
  }, [data, onSubmit, selectedTransformation?.transformation]);

  const handleDelete = useCallback(() => {
    data && onDelete && void onDelete(data);
    setDeleteConfirm(false);
  }, [data, onDelete]);

  const toggleDeleteConfirm = () => {
    setDeleteConfirm((state) => !state);
  };

  useEffect(() => {
    setSelectedTransformation(
      transformedImages?.find((x) =>
        data?.transformations?.includes(x.transformation.name),
      ),
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <Stack flex={1} spacing={3}>
      {title}
      <Stack direction="row" spacing={3}></Stack>
      <Stack
        alignItems={{ xs: 'inherit', md: 'flex-start' }}
        direction={{
          xs: 'column',
          md: 'row',
        }}
        gap={{
          xs: 2,
          sm: 4,
        }}
        justifyContent="space-between"
      >
        <Box display="flex" flex={1.4} gap={2}>
          <Stack spacing={1}>
            {transformedImages?.map((x) => (
              <Stack key={x.transformation.name} spacing={0.5}>
                <ImageContainer
                  onClick={(e) => {
                    e.preventDefault();
                  }}
                  role="button"
                  selected={
                    x.transformation.name ===
                    selectedTransformation?.transformation.name
                  }
                >
                  <LoadingImage
                    disabled={x.disabled}
                    onClick={() => {
                      handleChange(x);
                    }}
                    src={x.src}
                  />
                </ImageContainer>
                {x.showLabel && (
                  <Typography
                    color="primary"
                    textTransform="capitalize"
                    variant="subtitle2"
                  >
                    {(translationBase &&
                      t(`${translationBase}.${x.transformation.label}`)) ||
                      x.transformation.label}
                  </Typography>
                )}
              </Stack>
            ))}
          </Stack>
          <Box width="100%">
            <Image src={selectedTransformation?.src || src} />
          </Box>
        </Box>
        <Stack flex={1} spacing={2}>
          {/* Status */}
          <Stack alignItems="center" direction="row" spacing={2}>
            <Typography color="#2F3B4F" variant="subtitle2">
              {t('media')}
            </Typography>
            {data?.status !== internalStatus && (
              <div>
                <LabelStatus loading={isLoading} status={data?.status}>
                  {data?.status ? t(data?.status) : '-'}
                </LabelStatus>
              </div>
            )}
          </Stack>
          {/* Details section */}
          <Stack
            border="1px solid #e4eaf1; border-width: 1px 0;"
            divider={
              <Divider
                orientation="horizontal"
                sx={{
                  borderColor: '#e4eaf1',
                }}
              />
            }
          >
            <DetailItem
              label={t('uploadedBy')}
              value={data?.uploaderEmail || t('feed')}
            />
            <DetailItem label={t('type')} value={VehicleMediaType.Image} />
            <DetailItem
              label={t('created')}
              value={
                data?.uploadDate ? formatDateTime(data?.uploadDate) : undefined
              }
            />
            {data && 'rejectionReason' in data && (
              <DetailItem
                label={t('rejectionReason')}
                value={
                  <ReasonLabel
                    isError
                    reason={data?.rejectionReason || ''}
                    translations="rejectionReasons"
                  />
                }
              />
            )}
            {data && 'error' in data && (
              <DetailItem
                label={t('uploadError')}
                value={
                  <ReasonLabel
                    isError
                    reason={(data?.error as string) || ''}
                    translations="error"
                  />
                }
              />
            )}
            <DetailItem
              label={t('status')}
              value={
                <LabelStatus status={internalStatus}>
                  {internalStatus && t(internalStatus)}
                </LabelStatus>
              }
            />
          </Stack>

          <Stack direction="row" spacing={2}>
            {onSubmit && (
              <>
                <LoadingButton
                  loading={isLoading}
                  onClick={handleSubmit}
                  variant="contained"
                >
                  {submitButtonText || t('submit')}
                </LoadingButton>
                {onClose && (
                  <Button onClick={onClose} variant="outlined">
                    {t('cancel')}
                  </Button>
                )}
              </>
            )}
            {onDelete && (
              <LoadingButton
                color="error"
                loading={isDeleting}
                onClick={toggleDeleteConfirm}
                variant="contained"
              >
                {t('delete')}
              </LoadingButton>
            )}
          </Stack>
        </Stack>
      </Stack>
      <ConfirmModal
        busy={isDeleting}
        loadingIndicator={`${t('removing')}...`}
        onClose={toggleDeleteConfirm}
        onConfirm={handleDelete}
        open={deleteConfirm}
        title={t('delete')}
      >
        <Typography variant="body1">
          {t('pages.vehiclePage.media.removeWarning')}
        </Typography>
      </ConfirmModal>
    </Stack>
  );
};

const ImageDetails: FunctionComponent<ImageDetailsProps> = ({
  vehicleMedia,
  vehicleMediaType,
  translationBase,
  onClose,
  onSubmit,
  onRestore,
  onDelete,
  open,
  label,
  transformations,
  vinMd5Hash,
  showRestoreButton,
}) => {
  const [mainImage, setMainImage] = useState<VehicleMedia>();
  const [others, setOthers] = useState<VehicleMedia[]>();
  const { t } = useTranslation();
  const [updateTransformations, setUpdateTransformations] = useAsyncState();
  const { showMessage } = useMessageStore();
  const api = useApiClient();
  const { getCloudinaryUrl } = useCloudinary();
  const { value: currentUser } = useCurrentUser();
  const [restoringMedia, setRestoringMedia] = useState<Set<string>>(new Set());
  const { mutate: restoreVehicleMedia } = useUploadMedia({
    onMutate: ({ file }) => {
      if (file) {
        setRestoringMedia((state) => {
          const newState = new Set(state);
          newState.add(file);
          return newState;
        });
      }
    },
    onSettled: (_, errors, { file }) => {
      if (file) {
        setRestoringMedia((state) => {
          const newState = new Set(state);
          newState.delete(file);
          return newState;
        });
      }
    },
  });

  const { mutateAsync: deleteMedia, isPending: isDeleting } = useDeleteMedia();

  useEffect(() => {
    if (!vehicleMedia) {
      return;
    }

    const relevantImage = getRelevantMediaFromVehicleMedia({
      vehicleMedia,
      options: {
        mediaStatus: [
          VehicleMediaStatus.Approved,
          VehicleMediaStatus.Draft,
          VehicleMediaStatus.Error,
          VehicleMediaStatus.Pending,
          VehicleMediaStatus.Rejected,
        ],
        mediaType: vehicleMediaType || [
          VehicleMediaType.Image,
          VehicleMediaType.Legacy,
        ],
        prioritizeState: [
          VehicleMediaStatus.Approved,
          VehicleMediaStatus.Draft,
          VehicleMediaStatus.Error,
          VehicleMediaStatus.Pending,
          VehicleMediaStatus.Rejected,
        ],
      },
    });

    const main = relevantImage?.media;

    if (!main || !main.status) {
      return;
    }

    const otherMedia = vehicleMedia
      .filter((x) => x.status !== main.status)
      .sort((a, b) => ((a.uploadDate ?? 0) > (b.uploadDate ?? 0) ? -1 : 1));

    setMainImage(main);
    setOthers(otherMedia);
  }, [vehicleMedia]);

  const handleSubmit = useCallback(
    (media?: VehicleMedia, transformedImage?: TransformationImage) => {
      if (!mainImage || !vinMd5Hash) {
        return;
      }

      setUpdateTransformations(async () => {
        const response = await api.media.updateTransformations({
          vinMd5Hash,
          position: mainImage.position,
          vehicleMediaType: [mainImage.type],
          status: mainImage.status,
          transformations:
            (transformedImage && [transformedImage?.name]) || undefined,
        });

        if (!!response?.error) {
          showMessage({
            severity: 'error',
            text: t('errorOccurredMessage'),
            dismissible: true,
          });
        }
        onSubmit && onSubmit(mainImage.position, transformedImage);
        onClose && onClose();
        return response;
      });
    },
    [
      mainImage,
      vinMd5Hash,
      setUpdateTransformations,
      api.media,
      onSubmit,
      onClose,
      showMessage,
      t,
    ],
  );

  const handleRestore = useCallback(
    (media: VehicleMedia) => {
      if (
        media.status === VehicleMediaStatus.Archived &&
        media.previousStatus !== VehicleMediaStatus.Error &&
        vinMd5Hash
      ) {
        restoreVehicleMedia({
          position: media.position,
          vehicleMediaType: media.type,
          vinMd5Hash: vinMd5Hash,
          userEmail: currentUser?.email,
          file: getCloudinaryUrl(media.cloudinaryId),
        });

        onRestore && onRestore(media);
      }
    },
    [currentUser, getCloudinaryUrl, onRestore, restoreVehicleMedia, vinMd5Hash],
  );

  const handleDelete = useCallback(
    async (media: VehicleMedia) => {
      if (!vinMd5Hash) {
        return;
      }

      await deleteMedia({
        type: media.type,
        vinMd5Hash: vinMd5Hash,
        cloudinaryId: media.cloudinaryId,
        position: media.position,
      });

      setOthers((state) =>
        state?.filter((x) => x.cloudinaryId !== media.cloudinaryId),
      );

      onDelete && onDelete(media);
    },
    [deleteMedia, onDelete, vinMd5Hash],
  );

  if (!mainImage) {
    return <></>;
  }

  return (
    <ImageDialog label={label} onClose={onClose} open={!!open}>
      <Stack spacing={3}>
        <ImageItem
          data={mainImage}
          onClose={onClose}
          onSubmit={onSubmit && handleSubmit}
          transformations={transformations}
          translationBase={translationBase}
        />

        <Stack spacing={2}>
          <ImagesTimeline>
            {others?.map((item, idx) => {
              const file = getCloudinaryUrl(item.cloudinaryId);
              return (
                <Box display="flex" flex={1} key={idx}>
                  <ImageItem
                    data={item}
                    isDeleting={isDeleting}
                    isLoading={
                      updateTransformations?.loading ||
                      (!!file && restoringMedia.has(file)) ||
                      isDeleting
                    }
                    onDelete={onDelete && handleDelete}
                    onSubmit={
                      showRestoreButton &&
                      item.status === VehicleMediaStatus.Archived
                        ? (media) => media && handleRestore(media)
                        : undefined
                    }
                    submitButtonText={t('restore')}
                    title={
                      item.uploadDate && (
                        <Typography
                          color="#0C264E"
                          component="span"
                          variant="h6"
                        >
                          {formatDateTime(item.uploadDate)}
                        </Typography>
                      )
                    }
                  />
                </Box>
              );
            })}
          </ImagesTimeline>
        </Stack>
      </Stack>
    </ImageDialog>
  );
};

export default ImageDetails;
