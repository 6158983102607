import { Add, Minimize } from '@mui/icons-material';
import { Box } from '@mui/material';
import { FunctionComponent } from 'react';
import { sx } from '../../util/sx.js';

const styles = sx({
  customExpandIcon: {
    '.Mui-expanded & > .collapseIconWrapper': {
      display: 'none',
    },
    '.expandIconWrapper': {
      display: 'none',
    },
    '.Mui-expanded & > .expandIconWrapper': {
      display: 'block',
    },
  },
});

const AccordionExpandIcon: FunctionComponent = () => {
  return (
    <Box sx={styles.customExpandIcon}>
      <div className="expandIconWrapper">
        <Minimize />
      </div>
      <div className="collapseIconWrapper">
        <Add />
      </div>
    </Box>
  );
};

export default AccordionExpandIcon;
