import { number, object, string } from '@fmtk/decoders';

export interface GetDealerUserByIdRequest {
  principalId: string;
  dealerId: number;
}

export const decodeGetDealerUserByIdRequest = object<GetDealerUserByIdRequest>({
  principalId: string,
  dealerId: number,
});
