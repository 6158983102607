import { StackProps } from '@mui/material';
import { RemarketingVehicle } from '../../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicle } from '../../../../api/util/UserRemarketingVehicle.js';

export type RemarketingVehicleType =
  | RemarketingVehicle
  | UserRemarketingVehicle;

export type Section = {
  title: string;
  vehicle: RemarketingVehicleType;
  sectionItemsProps?: StackProps;
  isLoading?: boolean;
  readonly?: boolean;
  readonlyDepStatus?: boolean;
};

export const INTERVAL = 30000;
export const START_POLING = 3 * 60000 + INTERVAL;
export const FULL_DATE_TIME = "d'd' h'h' m'm' s's'";
export const DATE_TIME = "d'd' h'h'";
export const TIME = "h'h' m'm 's's";
export const dateTimeFormat = "yyyy-MM-dd'T'HH:mm";
