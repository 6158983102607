import { StackProps } from '@mui/material';
import { VehicleListing } from '../../../../../api/util/Vehicle.js';

export enum ActionType {
  ADD = 'add',
  EDIT = 'edit',
}

export type Section = {
  title: string;
  vehicleListing: VehicleListing;
  readonly?: boolean;
  sectionItemsProps?: StackProps;
  actionType?: ActionType;
  isVisible?: (isVisible: boolean) => void;
};

export enum YesNo {
  Yes = 'Yes',
  No = 'No',
}

export const YesNoOptions = [
  { text: YesNo.Yes, value: YesNo.Yes },
  { text: YesNo.No, value: YesNo.No },
];
