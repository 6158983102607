import csCZ from './cs-CZ/index.js';
import deAT from './de-AT/index.js';
import deDE from './de-DE/index.js';
import enGB from './en-GB/index.js';
import esES from './es-ES/index.js';
import frBE from './fr-BE/index.js';
import frFR from './fr-FR/index.js';
import itIT from './it-IT/index.js';
import jaJP from './ja-JP/index.js';
import koKR from './ko-KR/index.js';
import nlBE from './nl-BE/index.js';
import nlNL from './nl-NL/index.js';
import plPL from './pl-PL/index.js';
import ptPT from './pt-PT/index.js';
import ruRU from './ru-RU/index.js';
import skSK from './sk-SK/index.js';
import zhCN from './zh-CN/index.js';
import zhTW from './zh-TW/index.js';

export const imsAppTranslations = {
  'en-GB': enGB,
  'cs-CZ': csCZ,
  'de-AT': deAT,
  'de-DE': deDE,
  'es-ES': esES,
  'fr-BE': frBE,
  'fr-FR': frFR,
  'it-IT': itIT,
  'ja-JP': jaJP,
  'ko-KR': koKR,
  'nl-BE': nlBE,
  'nl-NL': nlNL,
  'pl-PL': plPL,
  'pt-PT': ptPT,
  'ru-RU': ruRU,
  'sk-SK': skSK,
  'zh-CN': zhCN,
  'zh-TW': zhTW,
};
