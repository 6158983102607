import { useAuth0 } from '@auth0/auth0-react';
import { Box, Button, Typography } from '@mui/material';
import { Container, Stack } from '@mui/system';
import { FunctionComponent } from 'react';

const FirewallBlockPage: FunctionComponent = (): JSX.Element => {
  const { logout } = useAuth0();
  return (
    <Stack
      alignContent="center"
      bgcolor="#effaff"
      direction="column"
      display="flex"
      height="100vh"
      justifyContent="center"
      p={{ xs: 2, sm: 5 }}
      textAlign="center"
    >
      <Box alignContent="center" display="flex" justifyContent="center">
        <img
          alt="logo"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/connect-auto-branding/logo"
          style={{ marginRight: 20 }}
          width={50}
        />
        <img
          alt="logo"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/connect-auto-branding/full-logo"
          width={400}
        />
      </Box>
      <Typography color="#333" mt={5} variant="h4">
        It looks like there is a firewall blocking your access to IMS
      </Typography>
      <Box sx={{ display: 'flex', justifyContent: 'center' }}>
        <img
          alt="unauthorized"
          src="https://res.cloudinary.com/motortrak/image/upload/ims2/icons/website-content-sections.svg"
          width={250}
        />
      </Box>

      <Container maxWidth="sm">
        <Stack
          alignContent="start"
          bgcolor="#effaff"
          direction="column"
          display="flex"
          justifyContent="start"
          mt={5}
          textAlign="left"
        >
          <Stack>
            <Typography color="#333" variant="h5">
              What should you do now?
            </Typography>
          </Stack>
          <Typography color="#333" variant="body1">
            <ol type="1">
              <li>
                Visit this link and check if you can see the Connect Auto
                website:&nbsp;
                <a href="https://api.mtkconnect.io">
                  https://api.mtkconnect.io
                </a>
              </li>
              <li>
                If you can't see the website, please speak to your IT department
                and make sure the URL (https://api.mtkconnect.io) is whitelisted
                in your firewall settings.
              </li>
              <li>
                If you can see the Connect Auto website, there is another issue.
                Please contact&nbsp;
                <a href="mailto:support@connect.auto">support@connect.auto</a>
                &nbsp;for assistance.
              </li>
            </ol>
          </Typography>
        </Stack>
      </Container>
      <Box sx={{ display: 'flex', justifyContent: 'center', margin: 5 }}>
        <Button
          onClick={() => logout()}
          sx={{ width: 100 }}
          variant="contained"
        >
          Log out
        </Button>
      </Box>
    </Stack>
  );
};
export default FirewallBlockPage;
