import { DateTime, Duration, ToRelativeOptions } from 'luxon';
import { useState } from 'react';
import { useInterval } from 'react-use';

type CountdownOptions = {
  toRelativeOptions?: ToRelativeOptions;
  stopOnZero?: boolean;
};

type CountdownResult = {
  timeRemaining: number;
  relativeTime: string | null;
  duration: Duration;
  timeLeft?: string;
  inThePast?: boolean;
};

type CountdownReturnType<T extends DateTime | undefined> = T extends DateTime
  ? CountdownResult
  : undefined;

const formatTimeLeft = (diff: Duration): string =>
  diff.as('days') > 1
    ? diff.toFormat("d'd' h'h'")
    : diff.as('hours') > 1
    ? diff.toFormat("hh'h' mm'm 's'")
    : diff.toFormat("mm'm 's'");

const useCountdown = <D extends DateTime | undefined>(
  targetDate: D,
  options?: CountdownOptions,
): CountdownReturnType<D> => {
  const [state, setState] = useState<CountdownReturnType<D>>(() => {
    if (!targetDate) {
      return undefined as CountdownReturnType<D>;
    }
    const initialState: CountdownReturnType<DateTime> = {
      duration: targetDate.diffNow(),
      relativeTime: targetDate.toRelative(options?.toRelativeOptions),
      timeRemaining: 0,
      inThePast: targetDate.diffNow().as('milliseconds') < 0,
      timeLeft: formatTimeLeft(targetDate.diffNow()),
    };
    return initialState as CountdownReturnType<D>;
  });

  useInterval(
    () => {
      if (!targetDate) {
        return;
      }
      const diff = targetDate.diffNow();
      setState((prev: CountdownReturnType<D>) => {
        const newState: CountdownReturnType<DateTime> = {
          timeRemaining: prev?.timeRemaining || 0,
          duration: diff,
          relativeTime: targetDate.toRelative(options?.toRelativeOptions),
          inThePast: diff.as('milliseconds') < 0,
          timeLeft: formatTimeLeft(diff),
        };
        return newState as CountdownReturnType<D>;
      });
    },
    !targetDate ||
      !state ||
      (options?.stopOnZero && state.duration.as('milliseconds') <= 0)
      ? null
      : 1000,
  );

  return state;
};

export default useCountdown;
