import { SyndicationType } from '../../../../../api/util/SyndicationType.js';
import { SaveVehicleRuleKia } from '../../../../util/SaveVehicleRule.js';
import { AddVehicleFormState } from '../../AddVehicle/Steps/DetailsStep.js';
import { EditVehicleFormState } from '../VehicleDetails.js';

/**
 * To certify that a vehicle belongs to a dealer in Slovakia, the vehicle must comply with the following rule:
 * The odometer reading must be less than 120,000 miles or the registration date must be less than 5 years old.
 * @param {{
 *   odometer: number,
 *   syndicationType: string,
 *   registrationDate: Date,
 * }} vehicleData - The vehicle data to be validated.
 * @param {SaveVehicleRuleKia} config - Configuration rules for validating Kia vehicles.
 * @returns {boolean} - True if the Kia vehicle can be saved, false otherwise.
 */
export function validateKiaRule(
  {
    odometer,
    syndicationType,
    odometerUnit,
    ...rest
  }: AddVehicleFormState | EditVehicleFormState,
  config: SaveVehicleRuleKia,
): boolean {
  const {
    maxOdometer,
    maxYearRegistration,
    syndicationTypeToCheck,
    dealerCountryCode: dealerCountriesCodeConfig,
  } = config;

  const registrationDate =
    'vehicle' in rest ? rest.vehicle?.registrationDate : rest.registrationDate;
  const dealerCountryCode =
    'dealerCountry' in rest ? rest.dealerCountry : undefined;
  const vehicleListing =
    'vehicleListing' in rest ? rest.vehicleListing : undefined;

  const dealerCountry = dealerCountryCode || vehicleListing?.dealer.countryCode;

  if (
    syndicationTypeToCheck.includes(syndicationType as SyndicationType) &&
    dealerCountry &&
    dealerCountriesCodeConfig.includes(dealerCountry) &&
    registrationDate
  ) {
    const currentDate = new Date();

    const yearsDifference =
      currentDate.getFullYear() - registrationDate.getFullYear();

    if (
      (odometer && odometer > maxOdometer) ||
      yearsDifference > maxYearRegistration
    ) {
      return false;
    }
  }

  return true;
}
