import { useCallback } from 'react';
import { VehicleMedia } from '../../api/util/VehicleMedia.js';
import { CarouselMedia } from '../ims/components/VehicleMedia/ImageCarousel.js';
import { filterFalsey } from '../util/filterFalsey.js';
import { useCloudinary } from './useCloudinary.js';

export interface UseCarouselMedia {
  getFromVehicleMedia: (vehicleMedia?: VehicleMedia[]) => CarouselMedia[];
}

export function useCarouselMedia(): UseCarouselMedia {
  const { getCloudinaryUrl } = useCloudinary();

  const getFromVehicleMedia = useCallback(
    (vehicleMedia?: VehicleMedia[]): CarouselMedia[] => {
      if (!vehicleMedia) {
        return [];
      }
      const carouselMedia: CarouselMedia[] = filterFalsey(
        vehicleMedia.map((m) => {
          const src = getCloudinaryUrl(m.cloudinaryId, 'q_auto');
          if (!src || !m.cloudinaryId) {
            return undefined;
          }

          return {
            description: m.description || 'Vehicle media',
            id: m.cloudinaryId,
            src,
          };
        }),
      );

      return carouselMedia;
    },
    [],
  );

  return {
    getFromVehicleMedia,
  };
}

export default useCarouselMedia;
