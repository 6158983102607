import { Box, Button, Stack, Typography } from '@mui/material';
import { FunctionComponent } from 'react';
import noData from '../../../../src/assets/icons/noData.svg';

interface NoDataProps {
  text?: string;
  onButtonClick?: () => void;
  buttonText?: string;
}

const NoData: FunctionComponent<NoDataProps> = ({
  text,
  onButtonClick,
  buttonText,
}) => {
  return (
    <Box
      alignContent="center"
      alignItems="center"
      bgcolor="#F7F8F9"
      display="flex"
      justifyContent="center"
      py={6}
      width="100%"
    >
      <Stack spacing={3} alignItems="center">
        <div>
          <img alt="noData" src={noData} />
        </div>
        {text && (
          <Typography color="#4B5768" textAlign="center" variant="subtitle1">
            {text}
          </Typography>
        )}
        {onButtonClick && buttonText && (
          <Button onClick={onButtonClick} variant="outlined" color="inherit">
            {buttonText}
          </Button>
        )}
      </Stack>
    </Box>
  );
};

export default NoData;
