import { PartialByKey } from '../../../../util/PartialBy.js';

export enum ExpressionType {
  ConditionExpression = 'ConditionExpression',
  KeyConditionExpression = 'KeyConditionExpression',
  FilterExpression = 'FilterExpression',
}

export type ExpressionInput = {
  [K in ExpressionType]?: string;
} & {
  ExpressionAttributeNames?: Record<string, string>;
  ExpressionAttributeValues?: Record<string, unknown>;
};

class ExpressionCollection<Value> {
  private readonly prefix: string;
  private readonly values = new Map<string, Value>();

  constructor(prefix: string, values?: Iterable<[string, Value]>) {
    this.prefix = prefix;
    this.values = new Map<string, Value>(values ?? []);
  }

  public add(value: Value): string {
    let key: string | undefined;
    for (const [k, v] of this.values.entries()) {
      if (v === value) {
        key = k;
        break;
      }
    }
    if (!key) {
      key = `${this.prefix}f${this.values.size}`;
    }
    this.values.set(key, value);
    return key;
  }

  public toJSON(): Record<string, Value> | undefined {
    if (!this.values.size) {
      return;
    }
    return Object.fromEntries(this.values);
  }
}

class ExpressionNameCollection extends ExpressionCollection<string> {
  constructor(values?: Iterable<[string, string]>) {
    super('#', values);
  }

  public addName(value: string | string[]): string {
    return (Array.isArray(value) ? value : [value])
      .map((x) => this.add(x))
      .join('.');
  }
}

class ExpressionValueCollection extends ExpressionCollection<unknown> {
  constructor(values?: Iterable<[string, unknown]>) {
    super(':', values);
  }
}

export class ExpressionBuilder<
  Input extends ExpressionInput = ExpressionInput,
> {
  private readonly _clauses = new Map<ExpressionType, string[]>();
  private names: ExpressionNameCollection;
  private values: ExpressionValueCollection;

  constructor(
    public input: PartialByKey<Input, keyof typeof ExpressionType>,
    private mode: ExpressionType = ExpressionType.ConditionExpression,
  ) {
    this.names = new ExpressionNameCollection(
      Object.entries(input.ExpressionAttributeNames ?? {}),
    );
    this.values = new ExpressionValueCollection(
      Object.entries(input.ExpressionAttributeValues ?? {}),
    );

    const keys = Object.keys(ExpressionType) as ExpressionType[];
    for (const key of keys) {
      const expr = (input as any)[key];
      if (expr) {
        this._clauses.set(key, [expr]);
      }
    }
  }

  public addClause(clause: string, type: ExpressionType): this {
    const existing = this._clauses.get(type);
    if (existing) {
      existing.push(clause);
    } else {
      this._clauses.set(type, [clause]);
    }
    return this;
  }

  public attributeExists(name: string | string[], type = this.mode): this {
    return this.addClause(`attribute_exists(${this.withName(name)})`, type);
  }

  public attributeNotExists(name: string | string[], type = this.mode): this {
    return this.addClause(`attribute_not_exists(${this.withName(name)})`, type);
  }

  public beginsWith(
    name: string | string[],
    value: string,
    type = this.mode,
  ): this {
    return this.addClause(
      `begins_with(${this.withName(name)}, ${this.withValue(value)})`,
      type,
    );
  }

  public buildExpression(): Input {
    const out: ExpressionInput = {};
    const names = this.names.toJSON();
    const values = this.values.toJSON();

    if (names) {
      out.ExpressionAttributeNames = names;
    }
    if (values) {
      out.ExpressionAttributeValues = values;
    }

    this._clauses.forEach((clauses, key) => {
      out[key] = clauses.join(' AND ');
    });

    return { ...this.input, ...out } as Input;
  }

  public between(
    name: string | string[],
    lowerValue: string,
    upperValue: string,
    type = this.mode,
  ): this {
    return this.addClause(
      `${this.withName(name)} BETWEEN ${this.withValue(
        lowerValue,
      )} AND ${this.withValue(upperValue)}`,
      type,
    );
  }

  public equals(
    name: string | string[],
    value: unknown,
    type = this.mode,
  ): this {
    return this.withCompareCondition(name, value, '=', type);
  }

  public greaterThan(
    name: string | string[],
    value: unknown,
    type = this.mode,
  ): this {
    return this.withCompareCondition(name, value, '>', type);
  }

  public greaterOrEqual(
    name: string | string[],
    value: unknown,
    type = this.mode,
  ): this {
    return this.withCompareCondition(name, value, '>=', type);
  }

  public lesserThan(
    name: string | string[],
    value: unknown,
    type = this.mode,
  ): this {
    return this.withCompareCondition(name, value, '<', type);
  }

  public lesserOrEqual(
    name: string | string[],
    value: unknown,
    type = this.mode,
  ): this {
    return this.withCompareCondition(name, value, '<', type);
  }

  public setMode(mode: ExpressionType): this {
    this.mode = mode;
    return this;
  }

  public withCompareCondition(
    name: string | string[],
    value: unknown,
    op = '=',
    type = this.mode,
  ): this {
    return this.addClause(
      `${this.withName(name)} ${op} ${this.withValue(value)}`,
      type,
    );
  }

  public withCondition(condition: string, type = this.mode): this {
    return this.addClause(condition, type);
  }

  public withName(name: string | string[]): string {
    return this.names.addName(name);
  }

  public withValue(value: unknown): string {
    return this.values.add(value);
  }
}
