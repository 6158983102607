import { array, enumValue, maybe, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface ListRegionCountryRequest {
  brand: AvailableBrands;
  regions?: string[];
  countries?: string[];
}

export const decodeListRegionCountryRequest = object<ListRegionCountryRequest>({
  brand: enumValue(AvailableBrands),
  regions: maybe(array(text)),
  countries: maybe(array(text)),
});
