import { enumValue, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export interface UserCountRemarketingVehiclesRequest {
  brand: AvailableBrands;
  countryCode: string;
  dealerId: number;
}

export const decodeUserCountRemarketingVehiclesRequest =
  object<UserCountRemarketingVehiclesRequest>({
    brand: enumValue(AvailableBrands),
    countryCode: text,
    dealerId: number,
  });
