import { parse as parseContentType, ParsedMediaType } from 'content-type';

export type HttpHeadersInit =
  | HttpHeaders
  | Record<string, string[] | string | undefined>;

export class HttpHeaders implements Headers {
  public static fromWebApi(
    init: HeadersInit,
    merge?: HttpHeadersInit,
  ): HttpHeaders {
    const headers = {} as Record<string, string>;

    new Headers(init).forEach((value, key) => {
      headers[key] = value;
    });
    return new HttpHeaders(headers, merge);
  }

  public static wrap(init: HttpHeadersInit): HttpHeaders;
  public static wrap(
    init: HttpHeadersInit | undefined,
  ): HttpHeaders | undefined;
  public static wrap(
    init: HttpHeadersInit | undefined,
  ): HttpHeaders | undefined {
    if (init instanceof HttpHeaders) {
      return init;
    }
    return new HttpHeaders(init);
  }

  private readonly values: Record<string, string[] | string>;

  constructor(init?: HttpHeadersInit, merge?: HttpHeadersInit) {
    if (init instanceof HttpHeaders) {
      this.values = cloneRecord(init.values);
    } else if (init) {
      this.values = cloneRecord(init);
    } else {
      this.values = {};
    }
    if (merge) {
      this.merge(merge);
    }
  }

  public append(key: string, value: string): void {
    key = this.find(key) ?? key;
    const values = this.values[this.find(key) ?? key];

    if (typeof values === 'string') {
      this.values[key] = [values, value];
    } else if (Array.isArray(values)) {
      values.push(value);
    } else {
      this.values[key] = value;
    }
  }

  public all(key: string): string[] {
    const value = this.values[this.find(key) ?? key];
    return Array.isArray(value) ? value : value ? [value] : [];
  }

  public default(key: string, value: string): void {
    const found = this.find(key);
    if (!found) {
      this.values[key] = [value];
    }
  }

  public delete(key: string): void {
    const found = this.find(key);
    if (found) {
      delete this.values[found];
    }
  }

  public getSetCookie(): string[] {
    return this.all('Set-Cookie');
  }

  public find(key: string): string | undefined {
    const search = key.toLowerCase();

    for (const k of Object.keys(this.values)) {
      if (k.toLowerCase() === search) {
        return k;
      }
    }
  }

  public forEach(
    fn: (value: string, key: string, parent: Headers) => void,
    thisArg?: unknown,
  ): void {
    for (const [key, value] of Object.entries(this.values)) {
      const single = Array.isArray(value)
        ? value.filter(Boolean).join(', ')
        : value;

      if (single) {
        fn.apply(thisArg, [single, key, this]);
      }
    }
  }

  public get(key: string): string | null {
    const found = this.find(key);
    const value = found && this.values[found];
    return Array.isArray(value) ? value[0] : value ?? null;
  }

  public has(key: string): boolean {
    return !!this.find(key);
  }

  public merge(values: HttpHeadersInit): this {
    HttpHeaders.wrap(values).forEach((value, key) => this.set(key, value));
    return this;
  }

  public parseContentType(): ParsedMediaType | undefined {
    const header = this.get('Content-Type');
    if (!header) {
      return;
    }
    try {
      return parseContentType(header);
    } catch {
      // don't fail if content-type is badly formed
    }
  }

  public set(key: string, value: string): void {
    if (!value) {
      this.delete(key);
    } else {
      this.values[this.find(key) ?? key] = [value];
    }
  }

  public toJSON(): Record<string, string | string[]> {
    return this.toRecord();
  }

  public toRecord(): Record<string, string | string[]> {
    return this.values;
  }

  public toRecordSingleValues(): Record<string, string> {
    const values: Record<string, string> = {};
    this.forEach((v, k) => (values[k] = v));
    return values;
  }

  public toRecordMultipleValues(): Record<string, string[]> {
    const values: Record<string, string[]> = {};
    for (const [k, v] of Object.entries(this.values)) {
      const value = Array.isArray(v) ? v : v ? [v] : undefined;
      if (value) {
        values[k] = value;
      }
    }
    return values;
  }
}

function cloneRecord(
  values: Record<string, string[] | string | undefined>,
): Record<string, string[] | string> {
  const cloned: Record<string, string[] | string> = {};

  for (const [key, value] of Object.entries(values)) {
    const coerced = Array.isArray(value) ? value.slice() : value;
    if (coerced) {
      cloned[key] = coerced;
    }
  }
  return cloned;
}
