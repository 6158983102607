import ReactPDF, { StyleSheet } from '@react-pdf/renderer';
import { Dealer } from '../../../../../api/util/Dealer.js';

const getStyles = (dealer?: Pick<Dealer, 'countryCode'>): ReactPDF.Styles => {
  const fontFamily = dealer?.countryCode === 'CN' ? 'ZCool' : 'RivieraNights';

  return StyleSheet.create({
    page: {
      padding: 15,
      fontFamily: 'RivieraNights',
      fontSize: '11.5px',
    },
    main: {
      display: 'flex',
      flexDirection: 'column',
      padding: '20px 40px 10px',
    },
    header: {
      flexDirection: 'row',
      justifyContent: 'center',
      alignItems: 'center',
    },
    priceImageContainer: {
      display: 'flex',
      flexDirection: 'row',
      padding: 10,
      justifyContent: 'space-between',
    },
    priceContainer: {
      width: '50%',
    },
    price: {
      border: '1px solid #333',
      padding: '5px',
      color: '#000',
      fontSize: '10px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: '0.23px',
      height: '97px',
    },
    imagesContainer: {
      display: 'flex',
      flexDirection: 'row',
      width: '50%',
      justifyContent: 'flex-end',
      alignItems: 'flex-end',
    },
    mainImage: { height: '123px', width: '165px' },
    smallerImagesContainer: { display: 'flex', flexDirection: 'column' },
    smallerImages: { height: '41px', width: '53px' },
    headingThree: {
      padding: '5px',
      borderBottom: '0.5px solid #333',
      color: '#333',
      fontSize: '11.5px',
      letterSpacing: '0.23px',
      marginBottom: '10px',
      marginTop: '0',
      width: '100%',
      fontFamily: 'RivieraNights',
      fontWeight: 'light',
    },
    listContainer: {
      width: '100%',
      display: 'flex',
      flexDirection: 'row',
      border: '1px solid #333',
    },
    list: {
      display: 'flex',
      flexDirection: 'column',
      lineHeight: '15px',
      width: '33%',
      maxHeight: '80px',
      fontSize: '8px',
      padding: '8px 4px',
    },
    listItem: {
      color: '#000',
      fontSize: '8px',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      width: '100%',
      marginRight: '15px',
      fontFamily: 'RivieraNights',
      fontWeight: 'light',
      flexWrap: 'wrap',
    },
    designSectionContainer: {
      padding: '10px',
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    designSectionColumn: {
      display: 'flex',
      flexDirection: 'column',
      width: '142px',
    },
    designDetails: {
      height: '30px',
      padding: '5px',
      border: '1px solid #333',
      color: '#000',
      fontSize: '8px',
      fontStyle: 'normal',
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      fontFamily: 'RivieraNights',
      fontWeight: 'light',
    },
    highlightedFeaturesContainer: { padding: '10px', width: '100%' },
    highlightedFeatures: {
      display: 'flex',
      border: '1px solid #333',
      color: '#000',
      fontSize: '8px',
      fontStyle: 'normal',
      fontWeight: 300,
      lineHeight: 'normal',
      letterSpacing: '0.16px',
      padding: '10px 5px',
      width: '100%',
    },
    aside: { display: 'flex', padding: '26px 0', backgroundColor: '#ededed' },
    specsContainer: { width: '50%' },
    dealerInfoContainer: { width: '50%' },
    specDetails: {
      color: '#333',
      fontSize: '6.5px',
      fontStyle: 'normal',
      fontWeight: 300,
      letterSpacing: '0.13px',
    },
    specsSpacing: {
      borderBottom: '0.9px dotted #333',
      flexGrow: 1,
      marginBottom: '2px',
    },
    specsRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      lineHeight: '10px',
    },
    dealerDetails: {
      flexWrap: 'wrap',
      width: '80%',
      fontFamily: 'RivieraNights',
    },
    retailerRow: { lineHeight: '10px' },
    footer: {
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      height: '160px',
    },
    footerText: {
      display: 'flex',
      justifyContent: 'center',
      fontSize: '7px',
      fontWeight: 400,
      lineHeight: 'normal',
    },
    footerTextParagraph: {
      width: '397px',
      height: '41px',
      textAlign: 'center',
    },
    specsAndDealerContainer: {
      backgroundColor: '#ededed',
      padding: '20px 40px',
    },
    specAndDealerContent: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
    },
    specAndDealerCol: {
      display: 'flex',
      flexDirection: 'column',
      width: '50%',
    },
    specItemMedium: {
      color: '#333',
      fontFamily: 'RivieraNights',
      fontSize: '6.5px',
      fontWeight: 'medium',
      lineHeight: '0%',
      letterSpacing: '0.13px',
    },
    specItemLight: {
      color: '#333',
      fontFamily: 'RivieraNights',
      fontSize: '6.5px',
      lineHeight: '0%',
      letterSpacing: '0.13px',
    },
    firstChild: { marginRight: '20px' },
    provenanceContainer: {
      display: 'flex',
      flexDirection: 'column',
      fontWeight: 'light',
      justifyContent: 'center',
      alignItems: 'center',
      padding: '30px 20px 0 20px',
    },
    provenanceLogo: {
      height: '42px',
      paddingBottom: '17px',
    },
    provenance: {
      color: '#000',
      textAlign: 'center',
      fontFamily: 'RivieraNights',
      fontSize: '7px',
      maxWidth: '397px',
    },
    dealerRow: {
      display: 'flex',
      flexDirection: 'row',
      justifyContent: 'space-between',
      alignItems: 'flex-end',
    },
    fallBackFontFamily: {
      fontFamily,
    },
  });
};

export default getStyles;
