import { Dispatch, SetStateAction, useState } from 'react';
import { useConst } from './useConst.js';

export interface AsyncState<T> {
  error?: unknown;
  loading?: boolean;
  value?: T;
}

export type AsyncHook<T> = [
  AsyncState<T>,
  Dispatch<SetStateAction<PromiseLike<T | undefined>>>,
];

export function makeAsyncStateDispatch<T>(
  setState: Dispatch<SetStateAction<AsyncState<T>>>,
): [
  Dispatch<SetStateAction<PromiseLike<T | undefined>>>,
  () => PromiseLike<T | undefined>,
  () => T | undefined,
] {
  let busy = 0;
  let value = Promise.resolve(undefined) as PromiseLike<T | undefined>;
  let resolved: T | undefined;

  return [
    (next: SetStateAction<PromiseLike<T | undefined>>): void => {
      void (async () => {
        try {
          if (++busy === 1) {
            setState((x) => ({ ...x, loading: true }));
          }
          if (typeof next === 'function') {
            next = next(value);
          }
          value = next;
          resolved = await next;
          setState((x) => ({ ...x, value: resolved, error: undefined }));
        } catch (error) {
          setState((x) => ({ ...x, value: undefined, error }));
        } finally {
          if (--busy === 0) {
            setState((x) => ({ ...x, loading: false }));
          }
        }
      })();
    },
    () => value,
    () => resolved,
  ];
}

export function useAsyncState<T>(): AsyncHook<T> {
  const [state, setState] = useState<AsyncState<T>>({});
  const dispatch = useConst(() => makeAsyncStateDispatch(setState)[0]);
  return [state, dispatch];
}
