import { maybe, number, object, record, string } from '@fmtk/decoders';
import { SyndicationType } from '../../util/SyndicationType.js';

export type SyndicationTypeCounts = {
  [key in SyndicationType]?: number;
};

export type IsLiveCounts = {
  live?: number;
  nonLive?: number;
};

export type ListingsCounts = {
  syndicationTypeCounts?: SyndicationTypeCounts;
  isLiveCounts?: IsLiveCounts;
  totalCounts?: number;
};

export type GetCountVehicleListingResponse = {
  dealer: ListingsCounts;
  liveStatus: ListingsCounts;
};

export const decodeListingCounts = object<ListingsCounts>({
  isLiveCounts: maybe(record(string, number)),
  syndicationTypeCounts: maybe(record(string, number)),
  totalCounts: maybe(number),
});

export const decodeCountGetVehicleListingResponse =
  object<GetCountVehicleListingResponse>({
    dealer: decodeListingCounts,
    liveStatus: decodeListingCounts,
  });
