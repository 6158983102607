import { Stack } from '@mui/system';
import {
  FunctionComponent,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { useTranslation } from 'react-i18next';
import { SimpleImage } from '../../../../api/MediaService/reorderMedia/ReorderMediaRequest.js';
import { RemarketingVehicle } from '../../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicle } from '../../../../api/util/UserRemarketingVehicle.js';
import { ImageTags, VehicleMedia } from '../../../../api/util/VehicleMedia.js';
import { ContentItem } from '../../../common-ui/components/TableOfContents.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../hooks/useAsyncState.js';
import { useBrand } from '../../../hooks/useBrand.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { sx } from '../../../util/sx.js';
import Section from '../Section.js';
import ImageAccordion from '../VehicleMedia/ImageAccordion.js';

interface VehicleMediaSectionProps {
  remarketingVehicle: RemarketingVehicle | UserRemarketingVehicle;
  onSave?: (
    remarketingVehicle: RemarketingVehicle | UserRemarketingVehicle,
  ) => void;
  readonly?: boolean;
}

const styles = sx({
  accordionSummary: {
    padding: 0,
  },
  accordionContent: {
    px: 0,
  },
  accordionContainer: {
    border: 1,
    borderWidth: '1px 0',
    borderColor: '#E7EAEE',
    my: 3,
  },
  vehicleImageContainer: {
    alignItems: 'flex-start',
    flexDirection: 'row',
    flexWrap: 'wrap',
    gap: 1,
    justifyContent: 'flex-start',
  },
});

const VehicleMediaSection: FunctionComponent<VehicleMediaSectionProps> = ({
  remarketingVehicle,
  readonly,
  onSave,
}) => {
  const { t } = useTranslation();
  const { isLoading } = useBrand();
  const [saveState, setSaveState] = useAsyncState<
    UserRemarketingVehicle | RemarketingVehicle
  >();
  const [vehicleMedia, setVehicleMedia] = useState<VehicleMedia[]>([]);
  const api = useApiClient();

  const contentItems: ContentItem[] = [
    {
      text: t('photos'),
      hash: 'photos',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.vehiclePage.media.video'),
      hash: 'video',
      sectionElementRef: useRef(null),
    },
  ];

  const handleMediaSave = useCallback(() => {
    setSaveState(async () => {
      const updated = await api.remarketing.adminUpdateRemarketingVehicle({
        brand: remarketingVehicle.vehicle.brand,
        remarketingVehicle: {
          ...remarketingVehicle,
          vehicleMedia: vehicleMedia,
        } as RemarketingVehicle,
      });
      onSave && onSave(updated);
      return updated;
    });
  }, [api.remarketing, onSave, remarketingVehicle, setSaveState, vehicleMedia]);

  const handleChange = useCallback(
    (simpleImages?: SimpleImage[] | undefined) => {
      const newMedia = filterFalsey(
        (simpleImages || []).map((simple, index) => {
          if (!simple.media) {
            return null;
          }
          return {
            ...simple.media,
            position: index + 1,
            status: simple.status,
          } as VehicleMedia;
        }),
      );
      setVehicleMedia(newMedia);
    },
    [],
  );

  const initMedia = useCallback(() => {
    setVehicleMedia(remarketingVehicle.vehicleMedia || []);
  }, [remarketingVehicle.vehicleMedia]);

  useEffect(() => {
    initMedia();
  }, [initMedia]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Section title={t('media')}>
        <Stack sx={styles.accordionContainer}>
          <div
            id={contentItems[0].hash}
            ref={
              contentItems[0]
                .sectionElementRef as React.RefObject<HTMLDivElement>
            }
          >
            {vehicleMedia && !isLoading && (
              <ImageAccordion
                carousel
                defaultExpanded
                imageTags={[ImageTags.Remarketing]}
                loading={saveState.loading}
                onCancel={initMedia}
                onChange={handleChange}
                onMediaSave={handleMediaSave}
                readonly={readonly}
                vehicle={remarketingVehicle.vehicle}
                vehicleMedia={vehicleMedia}
              />
            )}
          </div>
        </Stack>
      </Section>
    </Stack>
  );
};

export default VehicleMediaSection;
