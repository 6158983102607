import {
  enumValue,
  maybe,
  normaliseNumber,
  strToNum,
  text,
} from '@fmtk/decoders';
import { Stack } from '@mui/material';
import { DateTime } from 'luxon';
import { FunctionComponent, useCallback, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { OdometerUnit } from '../../../../../../api/util/OdometerUnit.js';
import { SyndicationType } from '../../../../../../api/util/SyndicationType.js';
import { UserRemarketingVehicle } from '../../../../../../api/util/UserRemarketingVehicle.js';
import { normaliseDate } from '../../../../../../util/decoders/normaliseDate.js';
import sectionStyles from '../../../../../common-ui/components/sectionStyle.js';
import {
  CellSelectInput,
  CellTextInput,
  Form,
} from '../../../../../common-ui/index.js';
import useCountdown from '../../../../../hooks/useCountDown.js';
import { useCreateForm } from '../../../../../hooks/useCreateForm.js';
import { useRemarketingConfig } from '../../../../../hooks/useRemarketingConfig.js';
import { VehicleListingFields } from '../../../../../util/VehicleListingFields.js';
import { formatDateTime } from '../../../../../util/dates.js';
import { formatPrice } from '../../../../../util/formatters.js';
import { sx } from '../../../../../util/sx.js';

const styles = sx({
  bid: {
    fontWeight: 'fontWeightBold',
    fontSize: 16,
  },
});

type AuctionSectionProps = {
  vehicle: UserRemarketingVehicle;
};

const AuctionDetails: FunctionComponent<AuctionSectionProps> = ({
  vehicle,
}) => {
  const { t, i18n } = useTranslation();
  const { brandSyndicationTypes, showVehicleListingField } =
    useRemarketingConfig();

  const closingDate =
    'closingDate' in vehicle && vehicle.closingDate
      ? DateTime.fromMillis(vehicle.closingDate, {
          locale: i18n.language,
        })
      : undefined;

  const { inThePast, timeLeft } =
    useCountdown(closingDate, {
      stopOnZero: true,
      toRelativeOptions: {
        locale: i18n.language,
        unit: ['years', 'months', 'days', 'hours', 'minutes'],
      },
    }) || {};

  const form = useCreateForm(
    {
      maxBid: maybe(strToNum),
      leadingBid: maybe(strToNum),
      timeLeft: maybe(text),
      endDate: maybe(normaliseDate()),
      odometer: maybe(normaliseNumber),
      shortVin: maybe(text),
      registrationPlate: maybe(text),
      recommendedRetailPrice: maybe(strToNum),
      odometerUnit: maybe(enumValue(OdometerUnit)),
      syndicationType: enumValue(SyndicationType),
    },
    () => void 0,
  );

  const [, formBind] = form;

  const resetForm = useCallback(() => {
    formBind.reset({
      maxBid:
        'currentBid' in vehicle && vehicle.currentBid?.maxBid
          ? formatPrice(vehicle.currentBid.maxBid, vehicle.currency)
          : '',
      leadingBid:
        'leadingBid' in vehicle && vehicle.leadingBid
          ? formatPrice(vehicle.leadingBid, vehicle.currency)
          : '',
      timeLeft: timeLeft,
      endDate:
        'closingDate' in vehicle && vehicle.closingDate
          ? formatDateTime(DateTime.fromMillis(vehicle.closingDate).toJSDate())
          : '',
      odometer: vehicle.odometer?.value || '',
      odometerUnit: vehicle.odometer?.units || '',
      registrationPlate: vehicle.registrationPlate || '',
      shortVin: vehicle.shortVin || '',
      recommendedRetailPrice: vehicle.vehicle.rrp?.value
        ? formatPrice(vehicle.vehicle.rrp.value, vehicle.currency, {
            maximumFractionDigits: 2,
          })
        : '',
      syndicationType: vehicle.syndicationType,
    });
  }, [timeLeft, formBind, vehicle]);

  useEffect(() => {
    resetForm();
  }, [resetForm]);

  return (
    <Form form={form} translations="pages.remarketingVehiclePage.auction.form">
      <Stack spacing={4}>
        <Stack sx={sectionStyles.sectionContent}>
          <Stack flex={1}>
            {!inThePast && (
              <CellTextInput
                cellStyleProps={styles.bid}
                label={t('pages.remarketingAdmin.maxBid')}
                name="maxBid"
              />
            )}
            <CellTextInput
              cellStyleProps={styles.bid}
              highlightChanges
              label={t('pages.remarketingAdmin.leadingBid')}
              name="leadingBid"
            />
            <CellTextInput label="endDate" name="endDate" />
            {showVehicleListingField(VehicleListingFields.SyndicationType) && (
              <CellSelectInput
                label="syndicationType"
                name="syndicationType"
                options={brandSyndicationTypes || []}
              />
            )}
            <CellTextInput label="shortVin" name="shortVin" />
            <CellTextInput
              label="recommendedRetailPrice"
              name="recommendedRetailPrice"
            />
          </Stack>
          <Stack flex={1}>
            {!inThePast && (
              <CellTextInput
                highlightChanges={
                  closingDate && closingDate.diffNow().as('minutes') <= 5
                }
                label="timeLeft"
                name="timeLeft"
              />
            )}
            <CellTextInput label="odometer" name="odometer" />
            <CellTextInput label="odometerUnit" name="odometerUnit" />
            <CellTextInput label="regPlate" name="registrationPlate" />
          </Stack>
        </Stack>
      </Stack>
    </Form>
  );
};

export default AuctionDetails;
