interface Groups {
  [groupName: string]: string[] | undefined;
}

/**
 * Calculates a balanced distribution of groups based on a maximum space.
 * @param groups The groups object containing groupName -> string[] | undefined mappings.
 * @param maxSpace The maximum space available for the distribution.
 * @param othersLabel The label for the "Others" category (optional).
 * @returns The trimmed groups object with balanced allocation, including a label for the trimmed groups if applicable.
 */
export function calculateBalancedDistribution(
  groups: Groups,
  maxSpace: number,
  othersLabel?: string,
): Groups {
  // Get the group names
  const groupNames = Object.keys(groups);

  // Filter out any undefined groups
  const validGroups = groupNames.filter(
    (groupName) => groups[groupName] !== undefined,
  );

  // Calculate the total size of all groups
  const totalSize = validGroups.reduce(
    (total, groupName) => total + groups[groupName]!.length,
    0,
  );

  // Calculate the proportions for each group
  const proportions: Record<string, number> = {};
  validGroups.forEach((groupName) => {
    const groupSize = groups[groupName]!.length;
    proportions[groupName] = groupSize / totalSize;
  });

  // Calculate the allocations for each group based on the proportions and max space
  const allocations: Record<string, number> = {};
  validGroups.forEach((groupName) => {
    const proportion = proportions[groupName];
    allocations[groupName] = Math.round(proportion * maxSpace);
  });

  // Create the trimmed groups based on the allocations
  const trimmedGroups: Groups = {};
  validGroups.forEach((groupName) => {
    // Slice the original array to get the desired number of elements based on allocation
    const trimmedItems = groups[groupName]!.slice(0, allocations[groupName]);
    trimmedGroups[groupName] = trimmedItems;

    // Check if there are remaining items in the group
    const remainingItems = groups[groupName]!.slice(trimmedItems.length);
    if (remainingItems.length > 0 && othersLabel) {
      trimmedGroups[groupName] = [...trimmedItems, othersLabel];
    }
  });

  return trimmedGroups;
}
