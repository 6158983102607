import { number, object, optional, string } from '@fmtk/decoders';

export interface ResetPasswordRequest {
  principalId: string;
  email: string;
  dealerId?: number;
}

export const decodeResetPasswordRequest = object<ResetPasswordRequest>({
  principalId: string,
  email: string,
  dealerId: optional(number),
});
