import { choose, enumValue, is, number, record } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export type CountImageQcResponse = {
  all: number;
} & {
  [brand in AvailableBrands]?: number;
};

export const decodeCountImageQcResponse = record(
  choose(is('all'), enumValue(AvailableBrands)),
  number,
);
