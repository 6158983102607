import { enumValue, object, string } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';
import { UserDocumentScope } from '../../util/UserDocument.js';

export interface DeleteUserDocumentRequest {
  brand: AvailableBrands;
  scope: UserDocumentScope;
  id: string;
}

export const decodeDeleteUserDocumentRequest =
  object<DeleteUserDocumentRequest>({
    brand: enumValue(AvailableBrands),
    scope: enumValue(UserDocumentScope),
    id: string,
  });
