import { object, optional, text } from '@fmtk/decoders';

export interface ChannelAuthResponse {
  auth: string;
  channelData?: string;
  sharedSecret?: string;
}

export const decodeChannelAuthResponse = object<ChannelAuthResponse>({
  auth: text,
  channelData: optional(text),
  sharedSecret: optional(text),
});
