import { alpha, Typography } from '@mui/material';
import React from 'react';
import { useTranslation } from 'react-i18next';
import { ListingLiveStatus } from '../../../api/util/ListingLiveStatus.js';
import { getRejectionReasonByValue } from '../../../api/util/RejectionReason.js';
import { Label } from '../../common-ui/index.js';

type Props = {
  isError?: boolean;
  reason: ListingLiveStatus | string;
  translations?: string;
};

const ReasonLabel = ({
  isError = false,
  reason,
  translations = 'vehicleListingLiveStatus',
}: Props) => {
  const { t } = useTranslation();

  const reasonParsed = t(
    `${translations}.${getRejectionReasonByValue(reason) || reason}`,
    {
      defaultValue: reason,
    },
  );

  if (!reasonParsed && isError) {
    return null;
  }

  return (
    <Label
      sx={{
        bgcolor: (theme) =>
          alpha(
            isError ? theme.palette.error.light : theme.palette.primary.light,
            0.4,
          ),
      }}
    >
      <Typography
        color={isError ? 'error.main' : 'primary.main'}
        variant="body2"
      >
        {reasonParsed}
      </Typography>
    </Label>
  );
};

export default React.memo(ReasonLabel);
