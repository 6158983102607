import { Info } from '@mui/icons-material';
import { Card, CardContent, CardHeader, IconButton } from '@mui/material';
import { DataGrid } from '@mui/x-data-grid';
import {
  BarElement,
  CategoryScale,
  Chart as ChartJS,
  Legend,
  LinearScale,
  Title,
  Tooltip,
} from 'chart.js';
import React, {
  FunctionComponent,
  useCallback,
  useMemo,
  useState,
} from 'react';
import { Bar } from 'react-chartjs-2';
import { useTranslation } from 'react-i18next';
import { ListRegionStockStatsResponse } from '../../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import { EmptyState } from '../../../common-ui/index.js';
import { useBrand } from '../../../hooks/index.js';
import { formatPercentage, sequenceColour } from '../../../util/index.js';
import { mapBrandCertifiedDisplayName } from '../../../util/vehicleTypes.js';
import { ChartDataType, IGridRow } from '../ChartReports.js';
import MainDrawer from '../MainDrawer.js';
import { options } from './chartConfig.js';
import { buildColumns } from './dataGridConfig.js';

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
);

export interface CertifiedVehiclesReportProps {
  data: ListRegionStockStatsResponse;
}

const CertifiedVehiclesReport: FunctionComponent<
  CertifiedVehiclesReportProps
> = ({ data }) => {
  const [open, setOpen] = useState(false);
  const { currentBrand } = useBrand();
  const { t } = useTranslation();
  const handleOpen = useCallback(() => {
    setOpen(true);
  }, []);
  const handleClose = useCallback(() => {
    setOpen(false);
  }, []);
  const certifiedBrandName =
    currentBrand && mapBrandCertifiedDisplayName[currentBrand];
  const reportTitle = certifiedBrandName || t('reports.certified.title');
  const chartData: ChartDataType = useMemo(() => {
    return {
      labels: data.map((item) => item.regionName),
      datasets: [
        {
          label: certifiedBrandName || t('certified'),
          data: data.map((region) => region.amountOfVehiclesCertified),
          backgroundColor: sequenceColour(2),
        },
        {
          label: t('used'),
          data: data.map(
            (region) =>
              region.amountOfVehicles -
              region.amountOfVehiclesCertified -
              region.amountOfVehiclesNew,
          ),
          backgroundColor: sequenceColour(0),
        },
        {
          label: t('new'),
          data: data.map((region) => region.amountOfVehiclesNew),
          backgroundColor: sequenceColour(1),
        },
      ],
    };
  }, [data, t]);

  const gridRows = useMemo(() => {
    /**
     * Format the data by generating three rows:
     * 'Vehicles cataloged as certified', 'Used vehicles', 'Total'
     * @returns A datagrid rows with all calculated values.
     */
    const rows = data.reduce(
      (acc: IGridRow[], region) => {
        //certified vehicles
        const certifiedItem = acc[0];
        //used vehicles
        const usedItem = acc[1];
        //new vehicles
        const newItem = acc[2];
        //total vehicles
        const allItem = acc[3];

        //certified vehicles by region
        const certifiedValue: number =
          Number(certifiedItem[region.regionName] || 0) +
          region.amountOfVehiclesCertified;
        //sum of certified vehicles
        const certifiedTotalValue = Number(acc[0].total || 0) + certifiedValue;

        //new vehicles by region
        const newValue: number =
          Number(newItem[region.regionName] || 0) + region.amountOfVehiclesNew;
        //sum of new vehicles
        const newTotalValue = Number(acc[2].total || 0) + newValue;

        //all vehicles by region
        const allValue: number =
          Number(allItem[region.regionName] || 0) + region.amountOfVehicles;
        //sum of all vehicles
        const allTotalValue = Number(acc[3].total || 0) + allValue;

        //used vehicles by region
        const usedValue: number =
          Number(usedItem[region.regionName] || 0) +
          (region.amountOfVehicles -
            region.amountOfVehiclesCertified -
            region.amountOfVehiclesNew);
        //sum of used vehicles
        const usedTotalValue = Number(usedItem.total || 0) + usedValue;

        //percentage by region
        const percentageValue: string = formatPercentage(
          certifiedValue / (usedValue + certifiedValue),
        );

        return [
          {
            ...acc[0],
            ...certifiedItem,
            [region.regionName]: certifiedValue,
            total: certifiedTotalValue,
          },
          {
            ...acc[1],
            ...usedItem,
            [region.regionName]: usedValue,
            total: usedTotalValue,
          },
          {
            ...acc[2],
            [region.regionName]: newValue,
            total: newTotalValue,
          },
          {
            ...acc[3],
            [region.regionName]: allValue,
            total: allTotalValue,
          },
          {
            ...acc[4],
            [region.regionName]: percentageValue,
            total: formatPercentage(
              certifiedTotalValue / (certifiedTotalValue + usedTotalValue),
            ),
          },
        ];
      },
      [
        {
          title: certifiedBrandName || t('reports.certified.title'),
          total: 0,
        },
        {
          title: t('reports.certified.used'),
          total: 0,
        },
        {
          title: t('reports.certified.new'),
          total: 0,
        },
        {
          title: t('total'),
          total: 0,
        },
        {
          title: (certifiedBrandName || t('certified')) + ' (%)',
          total: 0,
        },
      ],
    );

    return rows;
  }, [data, t]);

  return (
    <>
      <Card>
        <CardHeader
          action={
            <IconButton aria-label="settings" onClick={handleOpen}>
              <Info />
            </IconButton>
          }
          title={reportTitle}
        />
        <CardContent>
          {data.length === 0 ? (
            <EmptyState />
          ) : (
            <Bar data={chartData} options={options} />
          )}
        </CardContent>
      </Card>
      <MainDrawer onClose={handleClose} open={open} title={reportTitle}>
        <DataGrid
          autoHeight
          columns={buildColumns(data)}
          getRowId={(r) => r.title}
          hideFooter
          rows={gridRows}
        />
      </MainDrawer>
    </>
  );
};

export default React.memo(CertifiedVehiclesReport);
