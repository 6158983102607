import { maybe, number, object } from '@fmtk/decoders';
import { RemarketingVehicleStatus } from '../../util/RemarketingVehicle.js';

export type UserCountRemarketingVehiclesResponse = Partial<
  Record<RemarketingVehicleStatus, number>
>;

export const decodeUserCountRemarketingVehiclesResponse = object<
  Pick<
    UserCountRemarketingVehiclesResponse,
    | RemarketingVehicleStatus.ACTIVE
    | RemarketingVehicleStatus.CLOSED
    | RemarketingVehicleStatus.VISIBLE
  >
>({
  [RemarketingVehicleStatus.ACTIVE]: maybe(number),
  [RemarketingVehicleStatus.CLOSED]: maybe(number),
  [RemarketingVehicleStatus.VISIBLE]: maybe(number),
});
