import { object, text } from '@fmtk/decoders';
import { assertWithDetailedError } from '../util/index.js';
import { AppDefaults } from './AppDefaults.js';

export interface AppEnvironmentConfig {
  environment: string;
  name: string;
  version: string;
}

export const decodeAppEnvironmentConfig = object<AppEnvironmentConfig>({
  environment: text,
  name: text,
  version: text,
});

export const AppEnvironmentConfigVars = {
  AppName: 'APP_NAME',
  Environment: 'APP_ENV',
  Version: 'APP_VERSION',
};

let env: AppEnvironmentConfig | undefined;

export function getAppEnvironmentConfig(): AppEnvironmentConfig {
  return env ?? loadAppEnvironmentConfigFromEnv();
}

export function setAppEnvironmentConfig(config: AppEnvironmentConfig): void {
  env = assertWithDetailedError(decodeAppEnvironmentConfig, config);
}

function loadAppEnvironmentConfigFromEnv(): AppEnvironmentConfig {
  return assertWithDetailedError(decodeAppEnvironmentConfig, {
    environment: process.env[AppEnvironmentConfigVars.Environment],
    name: process.env[AppEnvironmentConfigVars.AppName] || AppDefaults.AppName,
    version: process.env[AppEnvironmentConfigVars.Version],
  });
}
