import { AttributeValue } from '@aws-sdk/client-dynamodb';
import { AvailableBrands } from './AvailableBrands.js';

export enum VehicleListingLogType {
  Created = 'ListingCreated',
  Updated = 'ListingUpdated',
  Deleted = 'ListingDeleted',
}

export interface VehicleListingLogBase {
  _expireAt?: number;
  brand: AvailableBrands;
  changes?: Record<string, AttributeValue>;
  id: string;
  logType: VehicleListingLogType;
  timestamp: number;
  userId: string;
  vehicleListingId: string;
  vin: string;
}

export interface VehicleListingLogCreated extends VehicleListingLogBase {
  logType: VehicleListingLogType.Created;
}

export interface VehicleListingLogUpdated extends VehicleListingLogBase {
  logType: VehicleListingLogType.Updated;
}

export interface VehicleListingLogDeleted extends VehicleListingLogBase {
  logType: VehicleListingLogType.Deleted;
}

export type VehicleListingLog =
  | VehicleListingLogCreated
  | VehicleListingLogUpdated
  | VehicleListingLogDeleted;
