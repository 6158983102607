import { Stack } from '@mui/material';
import { FunctionComponent, useMemo, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import {
  RemarketingType,
  RemarketingVehicleStatus,
} from '../../../../api/util/RemarketingVehicle.js';
import {
  BidStatus,
  UserRemarketingVehicle,
} from '../../../../api/util/UserRemarketingVehicle.js';
import SectionItem from '../../../common-ui/components/SectionItem.js';
import TableOfContents, {
  ContentItem,
} from '../../../common-ui/components/TableOfContents.js';
import { filterFalsey } from '../../../util/filterFalsey.js';
import { MAIN_CONTAINER_ID } from '../../layout/MainLayout.js';
import Section from '../Section.js';
import BiddingHistory from './Sections/BiddingHistory.js';
import AuctionDetails from './Sections/Details/AuctionDetails.js';
import { BuyNowButton } from './Sections/Details/BuyNowButton.js';
import FixedPriceDetails from './Sections/Details/FixedPriceDetails.js';
import { SubmitBidButton } from './Sections/Details/SubmitBidButton.js';
import { SubmitOfferButton } from './Sections/Details/SubmitOfferButton.js';
import UnassignedDetails from './Sections/Details/UnassignedDetails.js';
import { WatchlistButton } from './Sections/Details/WatchlistButton.js';
import Notes from './Sections/Notes.js';
import OffersHistory from './Sections/OffersHistory.js';
import RemarketingDocumentsSection from './Sections/RemarketingDocuments.js';
import StatusBoard from './Sections/StatusBoard.js';

interface UserRemarketingDetailsProps {
  remarketingVehicle: UserRemarketingVehicle;
  isLoading: boolean;
  onUpdateData: (newData?: UserRemarketingVehicle) => void;
}

const UserRemarketingDetails: FunctionComponent<
  UserRemarketingDetailsProps
> = ({ remarketingVehicle, isLoading, onUpdateData }): JSX.Element => {
  const { t } = useTranslation();

  const biddingHistoryItem = {
    text: t('pages.remarketingVehiclePage.auction.biddingHistory.title'),
    hash: 'history',
    sectionElementRef: useRef(null),
  };

  const contentItems: ContentItem[] = filterFalsey([
    {
      text: t('pages.remarketingVehiclePage.sections.details'),
      hash: 'auction',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.sections.statusBoard'),
      hash: 'status',
      sectionElementRef: useRef(null),
    },
    {
      text: t('pages.remarketingVehiclePage.auction.attachments'),
      hash: 'status',
      sectionElementRef: useRef(null),
    },
    {
      text: t('notes'),
      hash: 'notes',
      sectionElementRef: useRef(null),
    },
    remarketingVehicle.type === RemarketingType.AUCTION
      ? biddingHistoryItem
      : undefined,
  ]);

  const statusItems = useMemo(() => {
    return filterFalsey([
      remarketingVehicle.type === RemarketingType.AUCTION && {
        label: t(`currentBidStatus.${remarketingVehicle.bidStatus}`),
        value: () =>
          remarketingVehicle.bidStatus === BidStatus.Leading ||
          remarketingVehicle.status !==
            RemarketingVehicleStatus.ACTIVE ? null : (
            <SubmitBidButton
              isLoading={isLoading}
              link
              onChange={onUpdateData}
              vehicle={remarketingVehicle}
            />
          ),
      },
      remarketingVehicle.type === RemarketingType.AUCTION && {
        label: t(`reservePriceStatus.${remarketingVehicle.reservePriceStatus}`),
      },
      remarketingVehicle.type === RemarketingType.FIXED_PRICE && {
        label:
          remarketingVehicle.status !== RemarketingVehicleStatus.CLOSED
            ? t('pages.remarketingVehiclePage.fixedPrice.noOffers')
            : t('pages.remarketingVehiclePage.fixedPrice.closed'),
        value: () => {
          return remarketingVehicle.status ===
            RemarketingVehicleStatus.ACTIVE ? (
            <BuyNowButton
              isLoading={isLoading}
              link
              onChange={onUpdateData}
              vehicle={remarketingVehicle}
            />
          ) : null;
        },
      },
    ]);
  }, [isLoading, onUpdateData, remarketingVehicle, t]);

  return (
    <Stack
      direction={{
        md: 'row',
      }}
      spacing={1}
    >
      <Stack flex={1}>
        <Section>
          <Stack>
            <div
              id={contentItems[0].hash}
              ref={
                contentItems[0]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <SectionItem
                title={t('pages.remarketingVehiclePage.sections.details')}
              >
                {remarketingVehicle.type === RemarketingType.AUCTION && (
                  <AuctionDetails vehicle={remarketingVehicle} />
                )}
                {remarketingVehicle.type === RemarketingType.FIXED_PRICE && (
                  <FixedPriceDetails vehicle={remarketingVehicle} />
                )}
                {remarketingVehicle.type === RemarketingType.UNASSIGNED && (
                  <UnassignedDetails vehicle={remarketingVehicle} />
                )}
              </SectionItem>
              <Stack direction="row" justifyContent="end" spacing={2}>
                {remarketingVehicle.status !==
                  RemarketingVehicleStatus.CLOSED && (
                  <WatchlistButton
                    inWatchList={!!remarketingVehicle.inWatchList}
                    onChange={(inWatchList) =>
                      onUpdateData({
                        ...remarketingVehicle,
                        inWatchList,
                      })
                    }
                    remarketingVehicleId={remarketingVehicle.id}
                  />
                )}
                {remarketingVehicle.type === RemarketingType.AUCTION &&
                  remarketingVehicle.status ===
                    RemarketingVehicleStatus.ACTIVE && (
                    <SubmitBidButton
                      isLoading={isLoading}
                      onChange={onUpdateData}
                      vehicle={remarketingVehicle}
                    />
                  )}
                {remarketingVehicle.type === RemarketingType.FIXED_PRICE &&
                  remarketingVehicle.status ===
                    RemarketingVehicleStatus.ACTIVE && (
                    <>
                      <BuyNowButton
                        isLoading={isLoading}
                        onChange={onUpdateData}
                        vehicle={remarketingVehicle}
                      />
                      {remarketingVehicle.acceptsOffers && (
                        <SubmitOfferButton
                          isLoading={isLoading}
                          onChange={onUpdateData}
                          vehicle={remarketingVehicle}
                        />
                      )}
                    </>
                  )}
              </Stack>
            </div>
            <div
              id={contentItems[1].hash}
              ref={
                contentItems[1]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              {!!statusItems.length && (
                <StatusBoard
                  isLoading={isLoading}
                  items={statusItems}
                  title={t('pages.remarketingVehiclePage.sections.statusBoard')}
                />
              )}
            </div>
            <div
              id={contentItems[2].hash}
              ref={
                contentItems[2]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <RemarketingDocumentsSection
                brand={remarketingVehicle.vehicle.brand}
                isLoading={isLoading}
                readonly
                remarketingVehicleId={remarketingVehicle.id}
                title={t('pages.remarketingVehiclePage.sections.attachments')}
              />
            </div>
            <div
              id={contentItems[3].hash}
              ref={
                contentItems[3]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              <Notes readonly title={t('notes')} vehicle={remarketingVehicle} />
            </div>
            <div
              id={contentItems[3].hash}
              ref={
                contentItems[3]
                  .sectionElementRef as React.RefObject<HTMLDivElement>
              }
            >
              {remarketingVehicle.type === RemarketingType.AUCTION && (
                <BiddingHistory
                  currency={remarketingVehicle.currency}
                  remarketingVehicle={remarketingVehicle}
                  title={t(
                    'pages.remarketingVehiclePage.auction.biddingHistory.title',
                  )}
                />
              )}
              {remarketingVehicle.type === RemarketingType.FIXED_PRICE && (
                <OffersHistory
                  remarketingVehicle={remarketingVehicle}
                  title={t(
                    'pages.remarketingVehiclePage.fixedPrice.offersHistory.title',
                  )}
                />
              )}
            </div>
          </Stack>
        </Section>
      </Stack>
      <div>
        <Section isSticky>
          <Stack>
            <TableOfContents
              items={contentItems}
              offsetPx={-80}
              scrollingElementSelector={MAIN_CONTAINER_ID}
            />
          </Stack>
        </Section>
      </div>
    </Stack>
  );
};

export default UserRemarketingDetails;
