import { Decoder, PropDecoders } from '@fmtk/decoders';
import React, {
  createContext,
  createElement,
  useContext,
  useMemo,
} from 'react';
import {
  FormBind,
  FormHook,
  useContextWithError,
  useCreateForm,
} from '../../hooks/index.js';
import { FormState } from '../controllers/FormController.js';

export interface FormOptions {
  translations?: string;
}

export type FormContextType<T = any, Result = any> = [
  FormState<T, Result>,
  FormBind<T>,
  FormOptions,
];

export const FormContext = createContext<FormContextType | undefined>(
  undefined,
);

export function ProvideForm<T, Result>({
  children,
  form: [state, bind],
  ...options
}: {
  children?: React.ReactNode;
  form: FormHook<T, Result>;
} & FormOptions): JSX.Element {
  const value = useMemo(
    () => [state, bind, options ?? {}] as FormContextType<T>,
    [state, bind, options],
  );
  return createElement(FormContext.Provider, {
    children,
    value,
  });
}

export function MakeForm<T, Result>({
  crossValidation,
  fields,
  submit,
  ...rest
}: {
  children?: React.ReactNode;
  crossValidation?: Decoder<T>;
  fields: PropDecoders<T>;
  options?: FormOptions;
  submit: (value: T) => PromiseLike<Result> | Result;
} & FormOptions): JSX.Element {
  const form = useCreateForm(fields, submit, crossValidation);
  return createElement(ProvideForm, {
    form,
    ...rest,
  });
}

export function useForm<T = any>(): FormContextType<T> {
  return useContextWithError(
    FormContext,
    'FormContext',
    'ProvideForm',
  ) as FormContextType<T>;
}

export function useFormIfAvailable<T>(): FormContextType<T> | undefined {
  return useContext(FormContext) as FormContextType<T>;
}
