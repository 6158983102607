import { enumValue, number, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/index.js';

export type UserDeleteFromWatchlistRequest = {
  remarketingVehicleId: string;
  brand: AvailableBrands;
  dealerId: number;
};

export const decodeUserDeleteFromWatchlistRequest =
  object<UserDeleteFromWatchlistRequest>({
    remarketingVehicleId: text,
    brand: enumValue(AvailableBrands),
    dealerId: number,
  });
