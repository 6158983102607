import { TextField } from '@mui/material';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs/index.js';
import { DatePicker } from '@mui/x-date-pickers/DatePicker/index.js';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider/index.js';
import { Dayjs } from 'dayjs';
import React, { FunctionComponent, useState } from 'react';

type Props = {
  label: string;
  value: Dayjs | null;
  required?: boolean;
  onChange: (date2: Dayjs | null) => void;
};

const DateField: FunctionComponent<Props> = ({
  label,
  value,
  required = false,
  onChange,
}) => {
  const [error, setError] = useState<boolean>();

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DatePicker
        inputFormat="DD/MM/YYYY" // TODO, format date should change depending on user's browser?
        label={label}
        onChange={(e) => {
          if (e?.isValid()) {
            setError(false);
            onChange(e);
          } else {
            setError(true);
          }
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            error={error}
            helperText={error ? 'Input is required' : ''}
            required={required}
          />
        )}
        value={value}
      />
    </LocalizationProvider>
  );
};

export default React.memo(DateField);
