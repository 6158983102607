import {
  CircularProgress,
  Grid,
  Step as MuiStep,
  Stack,
  StepLabel,
  Stepper,
  Typography,
  circularProgressClasses,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { useMobile } from '../../hooks/useMobile.js';
import { sx } from '../../util/sx.js';

const styles = sx({
  mobileStepper: {
    height: 70,
    width: 70,
    position: 'relative',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    '&>.indicator': {
      position: 'absolute',
      color: '#519D66',
      '&.bottom': {
        color: '#BDC7D3',
      },
    },
  },
  progress: {
    [`& .${circularProgressClasses.circle}`]: {
      strokeLinecap: 'round',
    },
  },
});

export interface Step {
  completed?: boolean;
  label: string;
  to: string;
}

interface ProgressBarProps {
  steps: Step[];
  activeStep?: number;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({
  steps,
  activeStep = 0,
}) => {
  const mobile = useMobile();
  const { t } = useTranslation();
  const nextIndexStep = activeStep + 1;

  return mobile ? (
    <Stack flexDirection="row" justifyContent="space-between">
      <Grid container item sx={styles.mobileStepper}>
        <CircularProgress
          className="indicator bottom"
          color="inherit"
          size={60}
          value={100}
          variant="determinate"
        />
        <CircularProgress
          className="indicator"
          color="secondary"
          size={60}
          sx={styles.progress}
          value={(nextIndexStep / steps.length) * 100}
          variant="determinate"
        />
        <Typography color="#0C264E" fontWeight={700} variant="caption">
          {t('terms.progress', {
            value: nextIndexStep,
            total: steps.length,
          })}
        </Typography>
      </Grid>
      <Stack
        alignContent="center"
        alignItems="flex-end"
        justifyContent="space-between"
      >
        <Typography color="#0C264E" variant="h5">
          {steps[activeStep].label}
        </Typography>
        <Typography color="#637381" fontWeight={600} variant="body2">
          {t('terms.next', {
            value:
              nextIndexStep === steps.length
                ? t('submit')
                : steps[nextIndexStep].label,
          })}
        </Typography>
      </Stack>
    </Stack>
  ) : (
    <Stepper activeStep={activeStep}>
      {steps.map((step) => (
        <MuiStep completed={step.completed} key={step.label}>
          <StepLabel>{step.label}</StepLabel>
        </MuiStep>
      ))}
    </Stepper>
  );
};

export default ProgressBar;
