import { DecoderError } from '@fmtk/decoders';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';
import {
  SaveVehicleRule,
  SaveVehicleRuleAstonMartin,
} from '../../../../util/SaveVehicleRule.js';
import { AddVehicleFormState } from '../../AddVehicle/Steps/DetailsStep.js';
import { EditVehicleFormState } from '../VehicleDetails.js';
import { validateAstonMartinRule } from './validateAstonMartinRule.js';
import { validateKiaRule } from './validateKiaRule.js';

export const validateSaveVehicleRule = (
  values: AddVehicleFormState | EditVehicleFormState,
  config: SaveVehicleRule,
): DecoderError | undefined => {
  switch (config.type) {
    case AvailableBrands.Kia: {
      const isValid = validateKiaRule(values, config);
      if (!isValid) {
        return {
          id: 'INVALID_SAVE_VEHICLE_RULE.KIA',
          text: 'INVALID_SAVE_VEHICLE_RULE.KIA',
          field: 'saveVehicleRule.KIA',
        };
      }
      break;
    }
    case AvailableBrands.AstonMartin:
      const isValid = validateAstonMartinRule(
        values,
        config as SaveVehicleRuleAstonMartin,
      );
      if (!isValid) {
        return {
          id: 'INVALID_SAVE_VEHICLE_RULE.ASTON_MARTIN',
          text: 'INVALID_SAVE_VEHICLE_RULE.ASTON_MARTIN',
          field: 'saveVehicleRule.ASTON_MARTIN',
        };
      }
      break;

    default:
      break;
  }
  return;
};
