import { choose, maybe, object, record, string, unknown } from '@fmtk/decoders';

export type StringOrTranslation =
  | string
  | {
      key: string;
      values?: Record<string, unknown>;
    };

export const decodeStringOrTranslation = choose(
  string,
  object({
    key: string,
    values: maybe(record(string, unknown)),
  }),
);
