import { NavigateBefore, NavigateNext } from '@mui/icons-material';
import { Box } from '@mui/material';
import { useAtom } from 'jotai';
import { FunctionComponent, useCallback, useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { VehicleFeatures } from '../../../../../api/util/Vehicle.js';
import { addRemarketingVehicleAtom } from '../../../state/atoms.js';
import VehicleFeaturesEditor from '../../Vehicle/VehicleFeaturesEditor.js';

const FeaturesStep: FunctionComponent = () => {
  const navigate = useNavigate();
  const [addState, setAddSate] = useAtom(addRemarketingVehicleAtom);
  const { t } = useTranslation();

  const handleCancel = useCallback(() => {
    navigate('../details');
  }, [navigate]);

  const handleChange = useCallback(
    (features?: VehicleFeatures) => {
      setAddSate((state) => {
        if (!state.currentRemarketingVehicle) {
          return state;
        }

        return {
          ...state,
          currentRemarketingVehicle: {
            ...state.currentRemarketingVehicle,
            vehicle: {
              ...state.currentRemarketingVehicle.vehicle,
              features: features,
            },
          },
        };
      });
    },
    [setAddSate],
  );

  const options = useMemo(() => {
    return {
      buttons: {
        containerStyle: {
          justifyContent: 'space-between',
          bgcolor: 'background.paper',
          borderTop: '1px solid #C4CDD5',
          bottom: 0,
          padding: 1,
          position: 'sticky',
        },

        cancelButtonProps: {
          label: t('additionalDetails'),
          startIcon: <NavigateBefore />,
        },
        acceptButtonProps: {
          label: t('media'),
          endIcon: <NavigateNext />,
        },
      },
    };
  }, [t]);

  return (
    <Box>
      <VehicleFeaturesEditor
        features={addState.currentRemarketingVehicle?.vehicle.features}
        onCancel={handleCancel}
        onChange={handleChange}
        onSave={() => {
          navigate(`../media`);
          return;
        }}
        options={options}
      />
    </Box>
  );
};

export default FeaturesStep;
