import { Link, StyleSheet, Text, View } from '@react-pdf/renderer';
import { FunctionComponent } from 'react';
import { DealerSelectionType } from '../../../../hooks/useDealer.js';
import HeaderLogo from './HeaderLogo.js';

interface PdfHeaderProps {
  dealer?: DealerSelectionType;
}

const styles = StyleSheet.create({
  header: {
    height: 90,
    flexDirection: 'column',
    marginBottom: 5,
  },
  divider: {
    width: '100%',
    marginVertical: 8,
    borderBottom: '1px solid #94C11D',
  },
  dealerSection: {
    flexDirection: 'column',
    alignItems: 'center',
  },
  dealerName: {
    fontSize: 10,
    fontWeight: 400,
  },
  dealerAddress: {
    fontSize: 6,
    fontWeight: 'light',
  },
  dealerPhone: {
    fontSize: 6,
    fontWeight: 'light',
  },
  dealerWeb: {
    fontSize: 6,
    color: '#335B4C',
    fontWeight: 'light',
  },
});

const Dealer = ({ dealer }: { dealer?: DealerSelectionType }): JSX.Element => (
  <View style={styles.dealerSection}>
    <Text style={styles.dealerName}>{dealer?.name}</Text>
    <Text style={styles.dealerAddress}>{dealer?.address || ''}</Text>
    <Text style={styles.dealerPhone}>{dealer?.phone || ''}</Text>
    {dealer?.website && (
      <Text style={styles.dealerWeb}>
        <Link src={dealer.website}>{dealer.website}</Link>
      </Text>
    )}
  </View>
);

const PdfHeader: FunctionComponent<PdfHeaderProps> = ({ dealer }) => {
  return (
    <View style={styles.header}>
      <HeaderLogo />
      <View style={styles.divider} />
      <Dealer dealer={dealer} />
    </View>
  );
};

export default PdfHeader;
