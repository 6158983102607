import {
  QueryKey,
  UseQueryResult,
  useQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { UserGetRemarketingVehicleRequest } from '../../../../api/RemarketingService/userGetRemarketingVehicle/UserGetRemarketingVehicleRequest.js';
import { UserGetRemarketingVehicleResponse } from '../../../../api/RemarketingService/userGetRemarketingVehicle/UserGetRemarketingVehicleResponse.js';
import { RemarketingVehicleStatus } from '../../../../api/util/RemarketingVehicle.js';
import { UserRemarketingVehicleEvent } from '../../../../api/util/UserRemarketingVehicleEvent.js';
import {
  WsChannelType,
  WsRemarketingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { QueryKeys } from './QueryKeys.js';
import { useUpdateRemarketingUserListItem } from './updateRemarketingUserListItem.js';

export const makeRemarketingVehicleUserQueryKey = (
  req?: UserGetRemarketingVehicleRequest,
): QueryKey =>
  !req
    ? []
    : [QueryKeys.RemarketingVehiclesUser, 'dealer', req.dealerId, req.id];

export function useRemarketingVehicleUser(
  req?: UserGetRemarketingVehicleRequest,
  options?: { refreshStatus?: RemarketingVehicleStatus[] },
): UseQueryResult<UserGetRemarketingVehicleResponse> {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const updated = useWsChannelEvent<UserRemarketingVehicleEvent>(
    WsRemarketingEvent.remarketingChange,
    WsChannelType.RemarketingUser,
  );
  const updateState = useUpdateRemarketingUserListItem();

  useEffect(() => {
    if (!updated || !options?.refreshStatus?.includes(updated.status)) {
      return;
    }
    // refetch because the dealerId is needed to calculate the currentBid response in the BackEnd
    void queryClient.refetchQueries({
      queryKey: makeRemarketingVehicleUserQueryKey(req),
      exact: true,
    });

    updateState({ newEventIncoming: updated });
  }, [updated]);

  return useQuery({
    queryKey: makeRemarketingVehicleUserQueryKey(req),
    queryFn: async () => {
      if (!req) {
        return;
      }
      return await api.remarketing.userGetRemarketingVehicle(req);
    },
    enabled: !!req,
    refetchOnWindowFocus: false,
  });
}
