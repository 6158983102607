import { Skeleton, Stack, SxProps, Theme, Typography } from '@mui/material';
import { GridToolbarContainer } from '@mui/x-data-grid';
import { FunctionComponent } from 'react';
import DownloadingButton from './DownloadingButton.js';
import ErrorState from './ErrorState.js';

export interface DownloadButtonProps {
  onClickDownload: () => void;
  downloading?: boolean;
  error?: boolean;
}

export interface TotalRows {
  value: number;
  label: string;
}

export interface GridToolbarProps {
  sx?: SxProps<Theme> | undefined;
  downloadOptions?: DownloadButtonProps;
  totalRows?: TotalRows;
  loading?: boolean;
}

export const GridToolbar: FunctionComponent<GridToolbarProps> = ({
  sx,
  downloadOptions,
  totalRows,
  loading,
}) => {
  return (
    <GridToolbarContainer
      sx={{
        justifyContent: 'space-between',
        flexDirection: 'row-reverse',
        ...sx,
      }}
    >
      {downloadOptions && (
        <Stack spacing={0.5}>
          <>
            <div>
              <DownloadingButton
                disabled={loading}
                loading={downloadOptions.downloading}
                onClick={downloadOptions.onClickDownload}
                size="small"
                variant="text"
              />
            </div>
            {downloadOptions.error && (
              <ErrorState
                error="An error occurred trying to download the file, please try again"
                hideTitle
              />
            )}
          </>
        </Stack>
      )}
      {totalRows && (
        <Stack direction="row" spacing={1}>
          <Typography color="#637381" variant="button">
            {`${totalRows.label}:`}
          </Typography>
          {loading ? (
            <Skeleton variant="text" width={50} />
          ) : (
            <Typography color="#637381" fontWeight="bolder" variant="button">
              {totalRows.value}
            </Typography>
          )}
        </Stack>
      )}
    </GridToolbarContainer>
  );
};

export default GridToolbar;
