import { Font } from '../Font.js';

export const RivieraNights: Font = {
  family: 'RivieraNights',
  fonts: [
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/RivieraNights-Medium.woff',
      fontWeight: 'medium',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/RivieraNights-Regular.woff',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/RivieraNights-Light.woff',
      fontWeight: 'light',
    },
  ],
};

export const Jost: Font = {
  family: 'Jost',
  fonts: [
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/Jost-Regular.woff',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/Jost-Medium.woff',
      fontWeight: 'medium',
    },
    {
      src: 'https://connectauto-avl-fonts.s3-eu-west-1.amazonaws.com/Jost-Light.woff',
      fontWeight: 'light',
    },
  ],
};

//https://fonts.google.com/specimen/ZCOOL+XiaoWei/about
export const ZCool: Font = {
  family: 'ZCool',
  fonts: [
    {
      src: 'https://fonts.gstatic.com/s/zcoolxiaowei/v14/i7dMIFFrTRywPpUVX9_RJyM1YFI.ttf',
    },
  ],
};
