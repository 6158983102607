import { DatabaseClient } from '../../core/DynamoDbService/DynamoDbService.js';
import { TranslationModel } from '../../core/DynamoDbService/model/TranslationModel.js';
import { getBatch } from '../../core/DynamoDbService/util/getBatch.js';
import { AvailableBrands } from './AvailableBrands.js';
import { TranslationLocale } from './TranslationLocale.js';

export type Translation = {
  brand: AvailableBrands;
  locale: TranslationLocale;
  baseText: string;
  baseTextNormalised?: string;
  translation: string;
};

export type TranslationLookup = Record<string, string>;
export type TranslationLookupMultilingual = Partial<
  Record<TranslationLocale, TranslationLookup>
>;

export function normaliseBaseText(baseText: string): string {
  return baseText.trim().toLowerCase().replace(/\W+/g, '_');
}

const KeysToNotTranslate = ['dealer', 'metaName', 'vehicleMedia'];

export function keyRequiresTranslation(key: string): boolean {
  return !key.startsWith('_') && !KeysToNotTranslate.includes(key);
}

// Translate a list of strings to multiple locales
// Returns a map of locales to original strings to translated strings
export async function translateStringsMultiLanguage({
  dep,
  brand,
  locales,
  strings,
}: {
  dep: {
    dynamoDb: DatabaseClient;
  };
  brand: AvailableBrands;
  locales: TranslationLocale[];
  strings: string[];
}): Promise<TranslationLookupMultilingual> {
  // Lookup all strings for all locales (3 strings, in 3 languages = 9 keys)
  const normalisedStrings = Array.from(new Set(strings.map(normaliseBaseText)));
  const dynamoDbKeys = normalisedStrings
    .map((baseText) =>
      locales.map((locale) =>
        TranslationModel.key({
          brand,
          locale,
          baseText,
        }),
      ),
    )
    .flat();

  const translations = await getBatch<Translation>(dep.dynamoDb, dynamoDbKeys);

  // Reformat list of translations to a lookup
  const lookup = locales.reduce((acc1, locale) => {
    acc1[locale] = strings.reduce((acc2, string) => {
      const translation = translations.find(
        (t) =>
          t.locale === locale &&
          t.baseTextNormalised === normaliseBaseText(string),
      );
      if (translation) {
        acc2[string] = translation.translation;
      }
      return acc2;
    }, {} as TranslationLookup);

    return acc1;
  }, {} as TranslationLookupMultilingual);

  return lookup;
}

export async function translateStringsSingleLanguage({
  dep,
  brand,
  locale,
  strings,
}: {
  dep: {
    dynamoDb: DatabaseClient;
  };
  brand: AvailableBrands;
  locale: TranslationLocale;
  strings: string[];
}): Promise<TranslationLookup | undefined> {
  const { [locale]: translations } = await translateStringsMultiLanguage({
    dep,
    brand,
    locales: [locale],
    strings,
  });
  return translations;
}

// Translate any object to multiple languages
// Returns a map of locales to translated objects
export async function translateObject<T extends object>(
  dep: {
    dynamoDb: DatabaseClient;
  },
  brand: AvailableBrands,
  locales: TranslationLocale[],
  input: T,
): Promise<Partial<Record<TranslationLocale, T>>> {
  // Find all the strings in the object
  const strings = findAllStrings(input);

  // Translate all the strings in one go
  const translations = await translateStringsMultiLanguage({
    dep,
    brand,
    locales,
    strings,
  });

  // Apply the translations to the object
  const translated = locales.reduce((acc, locale) => {
    acc[locale] = applyTranslations(input, translations[locale]);
    return acc;
  }, {} as Partial<Record<TranslationLocale, T>>);

  return translated;
}

// Convert the output of `translateObject` to an array of objects
export function translationLookupToArray<T extends object>(
  input: Partial<Record<TranslationLocale, T>>,
): (T & { locale: TranslationLocale })[] {
  return Object.keys(input).map((key) => {
    const locale = key as TranslationLocale;
    const value = input[locale] as T;
    return {
      ...value,
      locale,
    };
  });
}

// Find all string values in an object
export function findAllStrings(input: Record<string, any>): string[] {
  const strings = new Set<string>();

  const traverse = (value: any): void => {
    if (typeof value === 'string') {
      strings.add(value);
    } else if (Array.isArray(value)) {
      value.forEach(traverse);
    } else if (typeof value === 'object' && value !== null) {
      Object.values(value).forEach(traverse);
    }
  };

  traverse(input);

  return [...strings];
}

// Translate any object - Walk the object recursively and translate any strings
function applyTranslations<T extends object>(
  input: T,
  translations?: TranslationLookup,
): T {
  if (!translations || Object.keys(translations).length === 0) {
    return input;
  }

  const transform = (value: any): any => {
    if (!value) {
      return value;
    }
    if (typeof value === 'string') {
      return translations[value] || value;
    }
    if (Array.isArray(value)) {
      return value.map(transform);
    }
    if (typeof value === 'object') {
      return applyTranslations(value, translations);
    }
    return value;
  };

  const result = Array.isArray(input)
    ? input.map(transform)
    : Object.fromEntries(
        Object.entries(input).map(([key, value]) => [
          key,
          keyRequiresTranslation(key) ? transform(value) : value,
        ]),
      );

  return result as T;
}
