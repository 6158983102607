import { damageOptions } from '../util/DamageOptions.js';
import { Option } from '../util/Option.js';
import { useBrand } from './useBrand.js';

export const useDamageStatusOptions = (): Option[] => {
  const { brandConfig, currentBrand } = useBrand();
  if (!brandConfig || !currentBrand) {
    return [];
  }
  return damageOptions.filter((opt) => {
    return !brandConfig.disabledOptions?.DamageStatus?.includes(opt.value);
  });
};
