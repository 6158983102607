import { Stack } from '@mui/material';
import { FunctionComponent, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams } from 'react-router-dom';
import { ListUserDocumentsByReferenceResponse } from '../../../../../api/DocumentService/listUserDocumentsByReference/ListUserDocumentsByReferenceResponse.js';
import { AvailableBrands } from '../../../../../api/util/AvailableBrands.js';

import {
  UserDocument,
  UserDocumentScope,
  UserDocumentSource,
} from '../../../../../api/util/UserDocument.js';
import SectionItem from '../../../../common-ui/components/SectionItem.js';
import sectionStyles from '../../../../common-ui/components/sectionStyle.js';
import { CreateUserDocumentModal } from '../../../../common-ui/components/UserDocuments/CreateUserDocumentModal.js';
import {
  DocumentPicker,
  OnChangeParams,
  OptionType,
} from '../../../../common-ui/components/UserDocuments/DocumentPicker.js';
import {
  DocumentRow,
  DocumentSkeletonRow,
} from '../../../../common-ui/components/UserDocuments/DocumentRow.js';
import {
  Body,
  ConfirmModal,
  useMessageStore,
} from '../../../../common-ui/index.js';
import { useApiClient } from '../../../../hooks/useApiClient.js';
import { useAsyncState } from '../../../../hooks/useAsyncState.js';
import { useDownloadDocument } from '../../../../hooks/useDownloadDocument.js';
import { Section } from '../types.js';

type RemarketingDocumentsSectionProps = Omit<Section, 'vehicle'> & {
  readonly?: boolean;
  brand: AvailableBrands;
  remarketingVehicleId: string;
};

const RemarketingDocumentsSection: FunctionComponent<
  RemarketingDocumentsSectionProps
> = ({
  title,
  sectionItemsProps,
  brand,
  remarketingVehicleId,
  isLoading,
  readonly,
}) => {
  const api = useApiClient();
  const { dealer } = useParams();
  const [newDocument, setNewDocument] = useState<OnChangeParams>();
  const [deletingDoc, setDeletingDoc] = useState<UserDocument>();
  const [, downloadDocument] = useDownloadDocument();
  const [deleteDocument, setDeleteDocument] = useAsyncState();
  const [listDocuments, setListDocuments] =
    useAsyncState<ListUserDocumentsByReferenceResponse>();

  const { showMessage } = useMessageStore();
  const { t } = useTranslation();

  const onDocumentPickerChange = useCallback((params: OnChangeParams) => {
    setNewDocument(params);
  }, []);

  const loadAttachments = useCallback(() => {
    setListDocuments(async () => {
      try {
        const response = await api.document.listUserDocumentsByReference({
          brand,
          reference: remarketingVehicleId,
          scope: UserDocumentScope.RemarketingAttachment,
          dealerId: Number(dealer) || undefined,
        });
        return response;
      } catch (error) {
        showMessage({
          severity: 'error',
          duration: 3000,
          text: t('documents.loadError'),
        });
      }
    });
  }, [
    setListDocuments,
    api.document,
    brand,
    remarketingVehicleId,
    dealer,
    showMessage,
    t,
  ]);

  const onDelete = (doc: UserDocument) => {
    setDeleteDocument(async () => {
      try {
        await api.document.deleteUserDocument({
          brand,
          id: doc.id,
          scope: UserDocumentScope.RemarketingAttachment,
        });
        showMessage({
          severity: 'success',
          duration: 3000,
          text: t('documents.deleteSuccess'),
        });
        setDeletingDoc(undefined);
        loadAttachments();
      } catch (error) {
        showMessage({
          severity: 'error',
          duration: 3000,
          text: t('documents.deleteError'),
        });
        setDeletingDoc(undefined);
      }
    });
  };

  useEffect(() => {
    loadAttachments();
  }, [loadAttachments, newDocument]);

  return (
    <>
      <SectionItem
        title={`${title} (${listDocuments.value?.items.length})`}
        {...sectionItemsProps}
      >
        <Stack spacing={4}>
          <Stack sx={sectionStyles.sectionContent}>
            <Stack flex={1} spacing={1}>
              {!readonly && (
                <DocumentPicker
                  accept="application/pdf"
                  onChange={onDocumentPickerChange}
                  options={[OptionType.PDF, OptionType.Link]}
                />
              )}
              {(listDocuments.loading || isLoading) &&
                Array(3)
                  .fill(0)
                  .map((_, i) => <DocumentSkeletonRow key={i} />)}
              {!isLoading &&
                listDocuments.value?.items.map((doc) => (
                  <DocumentRow
                    busy={deletingDoc === doc && deleteDocument.loading}
                    createdAt={doc.createdAt}
                    document={doc}
                    id={doc.id}
                    key={doc.id}
                    onClick={() => downloadDocument(doc)}
                    onDelete={() => setDeletingDoc(doc)}
                    readonly={readonly}
                  />
                ))}
            </Stack>
          </Stack>
        </Stack>
      </SectionItem>

      {newDocument && (
        <CreateUserDocumentModal
          brand={brand}
          file={newDocument.file}
          onClose={() => setNewDocument(undefined)}
          reference={remarketingVehicleId}
          scope={UserDocumentScope.RemarketingAttachment}
          source={
            newDocument.option === OptionType.PDF
              ? UserDocumentSource.S3
              : UserDocumentSource.Link
          }
        />
      )}
      {deletingDoc && (
        <ConfirmModal
          busy={deleteDocument.loading}
          fullWidth
          maxWidth="xs"
          onClose={() => setDeletingDoc(undefined)}
          onConfirm={() => onDelete(deletingDoc)}
          open
          title={t('document.delete.title')}
        >
          <Body size="small">{t('document.delete.blurb')}</Body>
        </ConfirmModal>
      )}
    </>
  );
};

export default RemarketingDocumentsSection;
