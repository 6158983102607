import {
  UseMutationResult,
  useMutation,
  useQueryClient,
} from '@tanstack/react-query';
import { InAppNotification } from '../../../../api/util/InAppNotification.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import {
  ListInAppNotificationQueryData,
  makeListInAppNotificationsQueryKey,
} from '../queries/useListInappNotifications.js';

export const useMarkInAppNotificationAsRead = (): UseMutationResult<
  InAppNotification,
  unknown,
  {
    notificationId: string;
    currentValue: boolean;
  }
> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  return useMutation({
    mutationFn: async ({ notificationId }) => {
      return await api.inAppNotifications.markNotificationAsRead({
        notificationId,
      });
    },
    onSuccess: (notification, { currentValue }) => {
      queryClient.setQueriesData<ListInAppNotificationQueryData>(
        { queryKey: makeListInAppNotificationsQueryKey() },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.map((n) => {
                  if (n.id === notification.id) {
                    return {
                      ...n,
                      read: true,
                    };
                  }
                  return n;
                }),
                unreadCount: currentValue ? p.unreadCount : p.unreadCount - 1,
              })),
            };
          }
        },
      );
    },
  });
};
