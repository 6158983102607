import { maybe, text } from '@fmtk/decoders';
import { Close } from '@mui/icons-material';
import { LoadingButton } from '@mui/lab';
import {
  Button,
  Dialog,
  DialogContent,
  DialogTitle,
  IconButton,
  Stack,
  Typography,
} from '@mui/material';
import { FunctionComponent } from 'react';
import { useTranslation } from 'react-i18next';
import { AvailableBrands } from '../../../../../../api/util/AvailableBrands.js';
import { FixedPriceRemarketingVehicle } from '../../../../../../api/util/RemarketingVehicle.js';
import { Form, useMessageStore } from '../../../../../common-ui/index.js';
import { useCreateForm } from '../../../../../hooks/useCreateForm.js';
import { useAcceptRejectOffer } from '../../../../hooks/mutations/useAcceptRejectOffer.js';
import Comments from '../../../Vehicle/Sections/Comments.js';

interface AcceptRejectOfferModalProps {
  open: boolean;
  remarketingVehicle: FixedPriceRemarketingVehicle;
  brand: AvailableBrands;
  onClose: () => void;
  onSubmit?: () => void;
  fullScreen?: boolean;
  isLoading: boolean;
  acceptOffer: boolean;
  actionId: string;
}

const AcceptRejectOfferModal: FunctionComponent<
  AcceptRejectOfferModalProps
> = ({
  open,
  remarketingVehicle,
  brand,
  onClose,
  onSubmit,
  fullScreen,
  isLoading,
  acceptOffer,
  actionId,
}) => {
  const { t } = useTranslation();
  const { mutateAsync: acceptRejectOffer } = useAcceptRejectOffer();
  const { showMessage } = useMessageStore();

  const form = useCreateForm(
    {
      comments: maybe(text),
    },
    async (values) => {
      try {
        await acceptRejectOffer({
          acceptOffer: acceptOffer,
          actionId,
          brand,
          remarketingVehicleId: remarketingVehicle.id,
          comment: values.comments,
        });

        onSubmit && onSubmit();
        onClose();
      } catch (error) {
        showMessage({
          severity: 'error',
          text: t(`pages.remarketingVehiclePage.fixedPrice.errors.unhandled`),
          dismissible: true,
        });
      }
    },
    undefined,
    [remarketingVehicle, brand, acceptOffer, actionId],
  );

  const [formState, bindForm] = form;

  const loading = isLoading || formState.busy;

  return (
    <Dialog
      disableEnforceFocus
      disableEscapeKeyDown
      fullScreen={fullScreen}
      maxWidth="md"
      onClose={onClose}
      open={open}
    >
      <Stack
        alignItems="center"
        direction="row"
        justifyContent="space-between"
        sx={{
          pr: 1,
          borderBottom: (theme) => `1px solid ${theme.palette.grey[500]}`,
        }}
      >
        <DialogTitle>
          {acceptOffer
            ? t('pages.remarketingVehiclePage.fixedPrice.acceptOffer')
            : t('pages.remarketingVehiclePage.fixedPrice.rejectOffer')}
        </DialogTitle>
        <IconButton
          aria-label="close"
          disabled={loading}
          onClick={onClose}
          sx={{
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <Close />
        </IconButton>
      </Stack>
      <DialogContent>
        <Form form={form}>
          <Stack spacing={3}>
            <Typography variant="body2">
              {acceptOffer
                ? t(
                    'pages.remarketingVehiclePage.fixedPrice.acceptOfferDisclaimer',
                  )
                : t(
                    'pages.remarketingVehiclePage.fixedPrice.rejectOfferDisclaimer',
                  )}
            </Typography>
            <Stack spacing={1}>
              <Comments
                label={t(
                  'pages.remarketingVehiclePage.fixedPrice.offerComments',
                )}
                maxLength={2000}
                name="comments"
              />
            </Stack>
            <Stack
              direction={{
                xs: 'column',
                sm: 'row-reverse',
              }}
              spacing={2}
            >
              <LoadingButton
                fullWidth
                loading={loading}
                onClick={() => bindForm.submit()}
                variant="contained"
              >
                {acceptOffer ? t('accept') : t('reject')}
              </LoadingButton>
              <Button
                color="inherit"
                disabled={loading}
                fullWidth
                onClick={onClose}
                variant="outlined"
              >
                {t('back')}
              </Button>
            </Stack>
          </Stack>
        </Form>
      </DialogContent>
    </Dialog>
  );
};

export default AcceptRejectOfferModal;
