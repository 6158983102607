import { enumValue, object, text } from '@fmtk/decoders';
import { AvailableBrands } from '../../util/AvailableBrands.js';

export interface RemoveUserBrandPolicyRequest {
  brand: AvailableBrands;
  principalId: string;
}

export const decodeRemoveUserBrandPolicyRequest =
  object<RemoveUserBrandPolicyRequest>({
    brand: enumValue(AvailableBrands),
    principalId: text,
  });
