import { styled, Tooltip, Typography, TypographyProps } from '@mui/material';
import { CommonSize, TextSize } from '../types/Typography.js';

export type BodySize = Extract<
  CommonSize,
  'large' | 'regular' | 'small' | 'xl' | 'xs'
>;

export interface BodyProps
  extends Pick<
    TypographyProps,
    | 'children'
    | 'className'
    | 'color'
    | 'noWrap'
    | 'align'
    | 'gutterBottom'
    | 'sx'
  > {
  size?: BodySize;
  tooltip?: string;
}

const sizes: Record<BodySize, TextSize> = {
  xs: {
    fontSize: 12,
    lineHeight: 16,
  },
  small: {
    fontSize: 14,
    lineHeight: 16,
  },
  regular: {
    fontSize: 16,
    lineHeight: 16,
  },
  large: {
    fontSize: 18,
    lineHeight: 20,
  },
  xl: {
    fontSize: 21,
    lineHeight: 24,
  },
};

const CustomTypography = styled(Typography)<BodyProps>(({ theme, size }) => {
  const { fontSize, lineHeight } = sizes[size || 'regular'];
  return {
    fontSize: fontSize && theme.typography.pxToRem(fontSize),
    fontWeight: 400,
    lineHeight: lineHeight && theme.typography.pxToRem(lineHeight),
  };
});

export const Body = ({
  size,
  tooltip,
  sx,
  ...props
}: BodyProps): JSX.Element => {
  return (
    <Tooltip title={tooltip || ''} arrow>
      <CustomTypography size={size} sx={sx} {...props} />
    </Tooltip>
  );
};
