import { Grid, Typography } from '@mui/material';
import { FunctionComponent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { DealerVehicleMovement } from '../../../api/ReportingService/listDealerVehicleMovements/ListDealerVehicleMovementsResponse.js';
import { RegionModelStats } from '../../../api/ReportingService/listRegionModelStats/ListRegionModelStatsResponse.js';
import { ListRegionStockStatsResponse } from '../../../api/ReportingService/listRegionStockStats/ListRegionStockStatsResponse.js';
import { ListRegionsResponse } from '../../../api/ReportingService/listRegions/ListRegionsResponse.js';
import { SyndicationType } from '../../../api/util/index.js';
import { PageContainer } from '../../common-ui/index.js';
import { useApiClient, useAsyncState, useBrand } from '../../hooks/index.js';
import { Reports } from '../../util/index.js';
import {
  CertifiedVehiclesReport,
  DealersCarsReport,
  ImageryReport,
  PreownedInventoryPricingReport,
  StockOver90DaysReport,
  StockVisibleReport,
} from '../components/ChartReports.js';
import DealerVehicleMovementsReport from '../components/DealerVehicleMovements/DealerVehicleMovementsReport.js';
import LiveStatusChangesCard from '../components/ListLiveStatusChanges/LiveStatusChangesCard.js';
import LiveInventoryCard from '../components/LiveInventoryCard.js';
import RegionSummaryDownloadCard from '../components/RegionSummaryDownloadCard/RegionSummaryDownloadCard.js';
import ReportCardWrapper from '../components/ReportCardWrapper.js';

const DashboardPage: FunctionComponent = (): JSX.Element => {
  const api = useApiClient();
  const { t } = useTranslation();
  const { currentBrand, brandConfig } = useBrand();
  const [region] = useState<string | undefined>();
  const [dealer] = useState<number | undefined>();
  const [regionStatList, setRegionStatList] =
    useAsyncState<ListRegionStockStatsResponse>();
  const [regions, setRegions] = useAsyncState<ListRegionsResponse>();
  const [regionModelStats, setRegionModelStats] =
    useAsyncState<RegionModelStats[]>();
  const [dealerVehicleMovements, setDealerVehicleMovements] =
    useAsyncState<DealerVehicleMovement[]>();

  useEffect(() => {
    setRegionStatList(
      async () =>
        await api.reporting.listRegionStockStats({
          brand: currentBrand!,
          dealerId: dealer,
          region,
        }),
    );
  }, [api, currentBrand, dealer, region, setRegionStatList]);

  useEffect(() => {
    setRegionModelStats(
      async () =>
        await api.reporting.listRegionModelStats({ brand: currentBrand! }),
    );
  }, [api, currentBrand, setRegionModelStats]);

  useEffect(() => {
    setDealerVehicleMovements(
      async () =>
        await api.reporting.listDealerVehicleMovements({
          brand: currentBrand!,
          dealerId: dealer,
          region,
        }),
    );
  }, [api, currentBrand, dealer, region, setDealerVehicleMovements]);

  useEffect(() => {
    setRegions(
      async () => await api.reporting.listRegions({ brand: currentBrand! }),
    );
  }, [api, currentBrand, setRegions]);

  //Depending on the configuration of the brand, shows or not the report of new cars movements.
  //For now only AM has this restriction.
  const showNewCarsReport = !brandConfig?.disabledReports?.includes(
    Reports.AddedRemovedNewCars,
  );
  const showLiveStatusChangesReport = !brandConfig?.disabledReports?.includes(
    Reports.LiveStatusChanges,
  );

  return (
    <PageContainer>
      <Typography sx={{ mb: 5 }} variant="h4">
        {t('pages.globalDashboard.title')}
      </Typography>
      <Grid container spacing={3}>
        <Grid item md={3} sm={6} xs={12}>
          <LiveInventoryCard
            data={regionModelStats.value || []}
            loading={regionModelStats.loading}
          />
        </Grid>
        <Grid item md={3} sm={6} xs={12}>
          <RegionSummaryDownloadCard brand={currentBrand!} />
        </Grid>
        {showLiveStatusChangesReport && (
          <Grid item md={3} sm={6} xs={12}>
            <LiveStatusChangesCard />
          </Grid>
        )}
        <Grid item xs={12}>
          <ReportCardWrapper
            error={
              regionStatList.error
                ? t('pages.globalDashboard.loadingError')
                : undefined
            }
            loading={regionStatList.loading}
          >
            <StockVisibleReport data={regionStatList.value || []} />
          </ReportCardWrapper>
        </Grid>
        <Grid item xs={12}>
          <ReportCardWrapper
            error={
              regionStatList.error
                ? t('pages.globalDashboard.loadingError')
                : undefined
            }
            loading={regionStatList.loading}
          >
            <CertifiedVehiclesReport data={regionStatList.value || []} />
          </ReportCardWrapper>
        </Grid>
        <Grid item xs={12}>
          <ReportCardWrapper
            error={
              regionStatList.error
                ? t('pages.globalDashboard.loadingError')
                : undefined
            }
            loading={regionStatList.loading}
          >
            <DealersCarsReport data={regionStatList.value || []} />
          </ReportCardWrapper>
        </Grid>
        <Grid item xs={12}>
          <ReportCardWrapper
            error={
              regionStatList.error
                ? t('pages.globalDashboard.loadingError')
                : undefined
            }
            loading={regionStatList.loading}
          >
            <ImageryReport data={regionStatList.value || []} />
          </ReportCardWrapper>
        </Grid>
        <Grid item xs={12}>
          <ReportCardWrapper
            error={
              regionStatList.error
                ? t('pages.globalDashboard.loadingError')
                : undefined
            }
            loading={regionStatList.loading}
          >
            <StockOver90DaysReport data={regionStatList.value || []} />
          </ReportCardWrapper>
        </Grid>
        <Grid item xs={12}>
          <PreownedInventoryPricingReport regions={regions.value || []} />
        </Grid>
        <Grid item xs={12}>
          <ReportCardWrapper
            error={
              dealerVehicleMovements.error
                ? t('pages.globalDashboard.loadingError')
                : undefined
            }
            loading={dealerVehicleMovements.loading}
          >
            <DealerVehicleMovementsReport
              brand={currentBrand!}
              condition={SyndicationType.Used}
              data={dealerVehicleMovements.value || []}
              subtitle={t('reports.vehicleMovement.subtitle')}
              title={t('reports.vehicleMovement.title', {
                value: t('used'),
              })}
            />
          </ReportCardWrapper>
        </Grid>
        {showNewCarsReport && (
          <Grid item xs={12}>
            <ReportCardWrapper
              error={
                dealerVehicleMovements.error
                  ? t('pages.globalDashboard.loadingError')
                  : undefined
              }
              loading={dealerVehicleMovements.loading}
            >
              <DealerVehicleMovementsReport
                brand={currentBrand!}
                condition={SyndicationType.New}
                data={dealerVehicleMovements.value || []}
                subtitle={t('reports.vehicleMovement.subtitle')}
                title={t('reports.vehicleMovement.title', {
                  value: t('new'),
                })}
              />
            </ReportCardWrapper>
          </Grid>
        )}
      </Grid>
    </PageContainer>
  );
};
export default DashboardPage;
