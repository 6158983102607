export enum OdometerUnit {
  km = 'km',
  mi = 'mi',
}

export const odometerUnitOptions = [
  {
    value: OdometerUnit.km,
    text: OdometerUnit.km,
  },
  {
    value: OdometerUnit.mi,
    text: OdometerUnit.mi,
  },
];
