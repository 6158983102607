import {
  array,
  enumValue,
  maybe,
  number,
  object,
  string,
} from '@fmtk/decoders';
import { AvailableBrands, DealerTypes } from '../../util/index.js';

export interface ListDealersRequest {
  brand: AvailableBrands;
  dealerIds: number[];
  regionIds: string[];
  freeTextSearch?: string;
  dealerTypes?: DealerTypes[];
  options?: {
    limit?: number;
    page?: number;
  };
}

const decodeOptions = object({
  limit: maybe(number),
  page: maybe(number),
});

export const decodeListDealersRequest = object<ListDealersRequest>({
  brand: enumValue(AvailableBrands),
  dealerIds: array(number),
  regionIds: array(string),
  freeTextSearch: maybe(string),
  dealerTypes: maybe(array(enumValue(DealerTypes))),
  options: maybe(decodeOptions),
});
