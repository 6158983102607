export const UserPermissions = {
  Brand: 'Brand',
  Region: 'Region',
  Dealer: 'Dealer',
  API: 'API',
  GlobalReporting: 'GlobalReporting',
  DealerReporting: 'DealerReporting',
  Support: 'Support',
  Basic: 'Basic',
  User: 'User',
  Admin: 'Admin',
  VehicleEditor: 'VehicleEditor',
  VehicleViewer: 'VehicleViewer',
  RemarketingDealer: 'RemarketingDealer',
  RemarketingAdmin: 'RemarketingAdmin',
  RemarketingAdminBrands: 'RemarketingAdminBrands',
  RemarketingReadOnlyBrands: 'RemarketingReadOnlyBrands',
};

export type UserPermissions = keyof typeof UserPermissions;
