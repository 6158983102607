export function isRecord<K extends string = string>(
  obj: unknown,
  ...keys: K[]
): obj is Record<K, unknown> {
  if (typeof obj !== 'object' || obj === null) {
    return false;
  }
  for (const key of keys) {
    if (!(key in obj)) {
      return false;
    }
  }
  return true;
}
