import {
  InfiniteData,
  QueryKey,
  UseInfiniteQueryResult,
  useInfiniteQuery,
  useQueryClient,
} from '@tanstack/react-query';
import { useEffect } from 'react';
import { UserListRemarketingActionsRequest } from '../../../../api/RemarketingService/userListRemarketingActions/UserListRemarketingActionsRequest.js';
import { UserListRemarketingActionsResponse } from '../../../../api/RemarketingService/userListRemarketingActions/UserListRemarketingActionsResponse.js';
import {
  OfferActionStatus,
  RemarketingAction,
} from '../../../../api/util/RemarketingAction.js';
import { RemarketingActionType } from '../../../../api/util/RemarketingVehicle.js';
import {
  WsChannelType,
  WsRemarketingEvent,
} from '../../../../core/PusherService/WsChannel.js';
import { useApiClient } from '../../../hooks/useApiClient.js';
import { useWsChannelEvent } from '../../../hooks/useWsChannel.js';
import { QueryKeys } from './QueryKeys.js';

export const makeListUserOffersQueryKey = (
  req?: UserListRemarketingActionsRequest,
): QueryKey => {
  return !req
    ? []
    : [
        QueryKeys.RemarketingOfferUser,
        req.brand,
        'dealer',
        req.dealerId,
        req.remarketingVehicleId,
      ];
};

export type ListUserOfferQueryData =
  InfiniteData<UserListRemarketingActionsResponse>;

const updater = (
  prev: ListUserOfferQueryData,
  newOffer: RemarketingAction,
): ListUserOfferQueryData | undefined => {
  return {
    ...prev,
    pages: prev.pages.map((p) => ({
      ...p,
      items: p.items.map((offer) => {
        if (
          offer.id === newOffer.id &&
          offer.actionType === RemarketingActionType.OFFER
        ) {
          return {
            ...offer,
            ...newOffer,
          };
        }

        return offer;
      }),
    })),
  };
};

export const useListUserOffers = (
  req?: UserListRemarketingActionsRequest,
): UseInfiniteQueryResult<ListUserOfferQueryData> => {
  const api = useApiClient();
  const queryClient = useQueryClient();
  const newOfferIncoming = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.newOffer,
    WsChannelType.RemarketingUser,
  );

  const buyNow = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.buyNow,
    WsChannelType.RemarketingUser,
  );

  const offerRejected = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.offerRejected,
    WsChannelType.RemarketingUser,
  );

  const offerAccepted = useWsChannelEvent<RemarketingAction>(
    WsRemarketingEvent.offerAccepted,
    WsChannelType.RemarketingUser,
  );

  useEffect(() => {
    if (
      newOfferIncoming &&
      newOfferIncoming.dealerId === req?.dealerId &&
      newOfferIncoming.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListUserOfferQueryData>(
        { queryKey: makeListUserOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            const [firstPage, ...pages] = prev.pages;
            firstPage.items.unshift(newOfferIncoming);

            return {
              ...prev,
              pages: [firstPage, ...pages],
            };
          }
        },
      );
    }
  }, [newOfferIncoming]);

  useEffect(() => {
    if (
      !!buyNow &&
      buyNow?.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListUserOfferQueryData>(
        { queryKey: makeListUserOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            return {
              ...prev,
              pages: prev.pages.map((p) => ({
                ...p,
                items: p.items.map((offer) => {
                  if (offer.id === buyNow.id) {
                    return offer.actionType === RemarketingActionType.OFFER
                      ? { ...offer, ...buyNow }
                      : offer;
                  }

                  if (
                    offer.actionType === RemarketingActionType.OFFER &&
                    !offer.status
                  ) {
                    return {
                      ...offer,
                      status: OfferActionStatus.REJECTED,
                    };
                  }

                  return offer;
                }),
              })),
            };
          }
        },
      );
    }
  }, [buyNow]);

  useEffect(() => {
    if (
      !!offerRejected &&
      offerRejected?.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListUserOfferQueryData>(
        { queryKey: makeListUserOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            return updater(prev, offerRejected);
          }
        },
      );
    }
  }, [offerRejected]);

  useEffect(() => {
    if (
      !!offerAccepted &&
      offerAccepted?.remarketingVehicleId === req?.remarketingVehicleId
    ) {
      queryClient.setQueriesData<ListUserOfferQueryData>(
        { queryKey: makeListUserOffersQueryKey(req) },
        (prev) => {
          if (prev) {
            return updater(prev, offerAccepted);
          }
        },
      );
    }
  }, [offerAccepted]);

  return useInfiniteQuery({
    queryKey: makeListUserOffersQueryKey(req),
    queryFn: async ({ pageParam }) => {
      if (!req) {
        return;
      }

      return await api.remarketing.userListRemarketingActions({
        ...req,
        nextToken: pageParam,
      });
    },
    getNextPageParam: (lastPage) => lastPage?.nextToken,
    initialPageParam: undefined as string | undefined,
    enabled: !!req,
  });
};
